import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as i, useState as s, useLayoutEffect as F, useEffect as L } from "react";
import { AppContext as E } from "./AppContext.js";
import { F as I } from "./provider-DKLaKIVD.js";
import { LoginContext as y } from "./LoginContext.js";
import { P as m, a as M } from "./index.esm-CqWfms5D.js";
import { r as k } from "./sentry-CstOwvcR.js";
import { i as N } from "./utils-C2gHLhxV.js";
import { i as n } from "./index-CrDCxF0S.js";
import { W as T } from "./Welcome-Bz7XIwKh.js";
import { M as W } from "./MandateListing-DpfjvUeo.js";
import { ErrorPage as z } from "./ErrorPage.js";
import { A as K } from "./Auth-CJPsuV-i.js";
function Q({ mandate_id: f }) {
  const { fpapi: r } = i(I), { loggedInUser: a } = i(y), { AppState: { showWelcomePage: l }, setShowWelcomePage: d } = i(E), [g, c] = s(p), [x, h] = s({}), [j, P] = s({}), [v, o] = s(!1), S = [
    {
      step: 1,
      content: n._({
        id: "PMSqVk"
      })
    },
    {
      step: 2,
      content: n._({
        id: "k8TSk3"
      })
    }
  ];
  F(() => {
    r && a && (o(!0), r.getInvestorProfile().then((e) => {
      e && (h(e), r.fpClient.mf_investment_accounts().fetchAll({
        investor: e.pan
      }).then((_) => {
        const u = _.data.filter((C) => C.primary_investor === a.profile.fp_identifier);
        if (!u.length) {
          o(!1);
          return;
        }
        P(u[0]), o(!1);
      }));
    }).catch((e) => {
      o(!1), k(e);
    }));
  }, []), L(() => {
    c(p());
  }, [
    l
  ]);
  function p() {
    return l ? "welcome" : "mandate";
  }
  function A(e) {
    d(!1), c("mandate");
  }
  function w() {
    return g === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(m, {
          title: n._({
            id: "zNK1+a"
          })
        }),
        /* @__PURE__ */ t.jsx(T, {
          onAck: A,
          welcomeSteps: S
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: v ? /* @__PURE__ */ t.jsx(M, {
        variant: "fullpage"
      }) : a ? N(j) ? /* @__PURE__ */ t.jsx(z, {
        title: "No mandates found",
        description: "You have not setup any mandates",
        showDetails: !1
      }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          " ",
          /* @__PURE__ */ t.jsx(m, {
            title: n._({
              id: "zNK1+a"
            })
          }),
          /* @__PURE__ */ t.jsx(W, {
            investorProfile: x,
            mandate_id: f,
            status: [
              ""
            ],
            limit: null,
            isPartner: !1
          })
        ]
      }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          /* @__PURE__ */ t.jsx(m, {
            title: n._({
              id: "zNK1+a"
            })
          }),
          /* @__PURE__ */ t.jsx(K, {
            redirectURL: `/status/mandate?id=${f}`,
            allowRegistration: !1,
            showTestPan: {
              onboarded: !0
            }
          })
        ]
      })
    });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: w()
  });
}
export {
  Q as MandateStatus
};
