import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as q, useState as w, useEffect as F, Suspense as D, lazy as R, useCallback as V } from "react";
import { a as h, P as de, B as $ } from "./index.esm-CqWfms5D.js";
import { ErrorPage as X } from "./ErrorPage.js";
import { W as fe } from "./Welcome-Bz7XIwKh.js";
import { u as pe, A as ue } from "./Auth-CJPsuV-i.js";
import { AppContext as me } from "./AppContext.js";
import { u as _e } from "./useConfiguration-B-lyaBB9.js";
import { F as Q } from "./provider-DKLaKIVD.js";
import { LoginContext as ye } from "./LoginContext.js";
import { w as H, K as he } from "./utils-C2gHLhxV.js";
import { i as r } from "./index-CrDCxF0S.js";
import { r as S } from "./sentry-CstOwvcR.js";
import { S as J } from "./StatusMessage-BUdCbwjZ.js";
import { N as we } from "./NominationConsent-jU9JIj8S.js";
const ge = /* @__PURE__ */ R(() => import("./KycDetails-C974HCxf.js")), ke = /* @__PURE__ */ R(() => import("./KycFileUploads-CUYxur9c.js")), xe = /* @__PURE__ */ R(() => import("./KycIpv-CA721Fq9.js")), Ce = /* @__PURE__ */ R(() => import("./KycEsign-Bl_gvvkx.js")), je = /* @__PURE__ */ R(() => import("./KycPoa-BsxJvZvk.js")), be = ({ user: d, investor: i, onKycComplete: k }) => {
  const { fpapi: v } = q(Q), [c, K] = w(null), [p, x] = w(null), [E, L] = w(!0), N = (n) => {
    var a, y;
    if (n)
      if ((a = n == null ? void 0 : n.requirements) != null && a.fields_needed.length) {
        const j = (y = n == null ? void 0 : n.requirements) == null ? void 0 : y.fields_needed, I = {
          kyc_details: [
            "aadhaar_number",
            "marital_status",
            "father_name_or_spouse_name",
            "mother_name",
            "gender",
            "occupation_type",
            "residential_status"
          ],
          kyc_poa: [
            "address"
          ],
          kyc_file_uploads: [
            "identity_proof",
            "photo",
            "bank_account",
            "signature"
          ],
          kyc_ipv: [
            "geolocation",
            "ipv_video"
          ]
        };
        for (const [A, P] of Object.entries(I))
          for (const T of P)
            if (j.includes(T))
              return A;
      } else
        return "kyc_esign";
    else return "kyc_details";
  }, g = (n) => {
    var a;
    if (n)
      switch ((a = n == null ? void 0 : n.verification) == null ? void 0 : a.status) {
        case "submitted":
          k(c);
          break;
        case "successful":
          k(c);
          break;
        case "rejected":
          window.debug.error(n == null ? void 0 : n.verification), x("kyc_rejected");
          break;
        default:
          return;
      }
    else
      return;
  };
  F(() => {
    (async () => {
      var n;
      if (i)
        try {
          const a = await v.fpClient.kyc_requests().fetchAll({
            pan: i == null ? void 0 : i.pan
          }), y = (n = a == null ? void 0 : a.data) == null ? void 0 : n[0];
          y && (g(y), K(y));
          const j = N(y);
          x(String(j)), L(!1);
        } catch (a) {
          L(!1), window.debug.error(a), S(a);
        }
      else
        window.debug.error("Investor object not available");
    })();
  }, []), F(() => {
    (async () => {
      if (c && Number(/* @__PURE__ */ new Date()) > Number(new Date(c == null ? void 0 : c.expires_at)))
        try {
          const n = await v.fpClient.kyc_requests().create(c);
          K(n);
        } catch (n) {
          window.debug.error(n), S(n);
        }
    })();
  }, [
    c
  ]);
  const C = (n) => {
    const a = N(n);
    x(String(a)), K(n);
  }, _ = () => {
    switch (p) {
      case "kyc_details":
        return /* @__PURE__ */ e.jsx(D, {
          fallback: /* @__PURE__ */ e.jsx(h, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(ge, {
            investor: i,
            onCompletion: C,
            kycDetails: c
          })
        });
      case "kyc_poa":
        return /* @__PURE__ */ e.jsx(D, {
          fallback: /* @__PURE__ */ e.jsx(h, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(je, {
            investor: i,
            kycDetails: c,
            onCompletion: C
          })
        });
      case "kyc_file_uploads":
        return /* @__PURE__ */ e.jsx(D, {
          fallback: /* @__PURE__ */ e.jsx(h, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(ke, {
            user: d,
            investor: i,
            kycDetails: c,
            onCompletion: C
          })
        });
      case "kyc_ipv":
        return /* @__PURE__ */ e.jsx(D, {
          fallback: /* @__PURE__ */ e.jsx(h, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(xe, {
            kycDetails: c,
            onCompletion: C
          })
        });
      case "kyc_esign":
        return /* @__PURE__ */ e.jsxs(D, {
          fallback: /* @__PURE__ */ e.jsx(h, {
            variant: "fullpage"
          }),
          children: [
            /* @__PURE__ */ e.jsx(Ce, {
              kycDetails: c
            }),
            ";"
          ]
        });
      case "kyc_rejected":
        return /* @__PURE__ */ e.jsx(X, {
          title: "KYC request rejected",
          description: "Your KYC request has been rejected. Kindly retry",
          showAction: !0,
          actionText: "Create a new KYC request",
          actionHandler: () => {
            x("kyc_details");
          }
        });
      default:
        return /* @__PURE__ */ e.jsx(h, {
          variant: "fullpage"
        });
    }
  };
  return /* @__PURE__ */ e.jsx("div", {
    children: E ? /* @__PURE__ */ e.jsx(h, {
      variant: "fullpage"
    }) : _()
  });
}, Ye = () => {
  const { fpapi: d } = q(Q), { tenantConfig: i } = _e(), { loggedInUser: k } = q(ye), { AppState: { showWelcomePage: v, tenant: c }, setShowWelcomePage: K } = q(me), [p, x] = w(null), [E, L] = w(null), [N, g] = w(null), [C, _] = w(null), [n, a] = w(!0), [y, j] = w(O), M = [
    {
      step: 1,
      content: r._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: r._({
        id: "xwuL3C"
      })
    }
  ], { fp_campaign_id: G } = pe(), B = i == null ? void 0 : i.test_isin, U = V(async (t) => {
    var b;
    const o = H(t == null ? void 0 : t.annexure);
    let f = null;
    if (o != null && o.kycCheckId)
      try {
        const s = await d.fpClient.kyc_checks().fetch(o == null ? void 0 : o.kycCheckId);
        if (Math.abs(Number(/* @__PURE__ */ new Date()) - Number(new Date(s.updated_at))) > 24 * 60 * 60 * 1e3)
          try {
            f = {
              ...await d.fpClient.kyc_checks().refetch(o == null ? void 0 : o.kycCheckId)
            };
          } catch (l) {
            throw window.debug.error(l), l;
          }
        else
          f = {
            ...s
          };
      } catch (s) {
        throw window.debug.error(s), s;
      }
    else if (t.id) {
      const s = t.id;
      try {
        f = {
          ...await d.fpClient.kyc_checks().create({
            pan: t != null && t.username ? (b = t == null ? void 0 : t.username) == null ? void 0 : b.toUpperCase() : null
          })
        };
        const l = {
          ...o,
          kycCheckId: f.id
        };
        try {
          const m = await d.updateUser(s, l, null, c);
          if (!m)
            throw window.debug.error("USER UPDATION ERROR"), m;
        } catch (m) {
          throw window.debug.error(m), m;
        }
      } catch (u) {
        throw window.debug.error(u), u;
      }
    }
    return f;
  }, [
    k
  ]), I = V(() => {
    sessionStorage.getItem("nomination_consent") && sessionStorage.removeItem("nomination_consent"), window.location.href = `/checkout/?isin=${B}&mtm_campaign=staging`;
  }, []), A = (t) => {
    if (t.status)
      return "kyc_compliant";
    {
      const o = {
        unavailable: 1,
        rejected: 1,
        legacy: 1,
        unknown: 1
      };
      return t.action === "disallowed" || !o[t == null ? void 0 : t.reason] ? "kyc_disallowed" : "kyc_allowed";
    }
  };
  F(() => {
    (async () => {
      var t;
      if (!k)
        g("login"), a(!1);
      else
        try {
          const o = await d.fetchUser(k.profile.preferred_username, c);
          x(o == null ? void 0 : o[0]);
          try {
            const f = await U(o == null ? void 0 : o[0]);
            switch (A(f)) {
              case "kyc_compliant":
                _(r._({
                  id: "IN6RGB"
                }));
                break;
              case "kyc_disallowed":
                _(r._({
                  id: "ZG6cou"
                }));
                break;
              case "kyc_allowed": {
                const s = H((t = o == null ? void 0 : o[0]) == null ? void 0 : t.annexure);
                if ([
                  "personal_details",
                  "financial_details",
                  "banking_details",
                  "contact_details"
                ].includes(s == null ? void 0 : s.completed_step))
                  _(r._({
                    id: "6P2M5W"
                  }));
                else if ((s == null ? void 0 : s.isonboarded) === "true")
                  I();
                else
                  try {
                    const l = await d.getInvestorProfile();
                    L(l), g("nomination_consent"), _(null);
                  } catch (l) {
                    _(r._({
                      id: "2nIs2h"
                    })), window.debug.error(l), S(l);
                  }
                break;
              }
              default:
                break;
            }
          } catch (f) {
            a(!1), window.debug.error(f), _(r._({
              id: "9LHX94"
            })), S(f);
          }
        } catch (o) {
          _(r._({
            id: "9y3Wx0"
          })), window.debug.error(o), S(o);
        } finally {
          a(!1);
        }
    })();
  }, [
    k,
    U,
    I
  ]);
  const P = async (t) => {
    var o, f, b, s;
    try {
      const u = t.id, l = H(t == null ? void 0 : t.annexure), m = t == null ? void 0 : t.profile, ce = l == null ? void 0 : l.preferred_bank_account, se = [
        d.fpClient.email_addresses().fetchAll({
          profile: m
        }),
        d.fpClient.phone_numbers().fetchAll({
          profile: m
        }),
        d.fpClient.addresses().fetchAll({
          profile: m
        })
      ], [re, W, Y] = await Promise.all(se), Z = (o = i == null ? void 0 : i.groupInvestment) == null ? void 0 : o.allowedEmailDomains, z = Z ? he(re, Z) : null, ie = {
        primary_investor: m,
        holding_pattern: "single",
        folio_defaults: {
          communication_email_address: z == null ? void 0 : z.id,
          communication_mobile_number: (f = W == null ? void 0 : W.data[0]) == null ? void 0 : f.id,
          communication_address: (s = (b = Y == null ? void 0 : Y.data) == null ? void 0 : b[0]) == null ? void 0 : s.id,
          payout_bank_account: ce
        }
      };
      await d.fpClient.mf_investment_accounts().create(ie);
      const le = {
        fp_campaign_id: G,
        isonboarded: !0
      };
      await d.updateUser(u, le, null, c) ? g("mfia_complete") : (window.debug.error("Cannot update the details"), g("mfia_failed"));
    } catch (u) {
      window.debug.error(u), g("mfia_failed"), S(u);
    }
  }, T = () => {
    P(p);
  }, ee = () => {
    P(p);
  }, te = ({ preferred_bank_account: t }) => {
    sessionStorage.setItem("nomination_consent", "true"), p != null && p.annexure && !("preferred_bank_account" in p.annexure) && x({
      ...p,
      annexure: {
        ...p.annexure,
        preferred_bank_account: `${t}`
      }
    }), g("kyc");
  };
  F(() => {
    j(O());
  }, [
    v
  ]);
  function O() {
    return v ? "welcome" : "kyc";
  }
  function ne() {
    K(!1), j("kyc");
  }
  const oe = () => {
    switch (N) {
      case "login":
        return /* @__PURE__ */ e.jsx(ue, {
          redirectURL: window.location.href,
          allowRegistration: !1
        });
      case "nomination_consent":
        return /* @__PURE__ */ e.jsx(we, {
          investorProfile: E,
          onSuccess: te
        });
      case "kyc":
        return /* @__PURE__ */ e.jsx(be, {
          user: p,
          investor: E,
          onKycComplete: T
        });
      case "mfia_complete":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(J, {
              title: r._({
                id: "yyI4HG"
              }),
              description: r._({
                id: "JqaeZ1"
              }),
              iconName: "successInfo"
            }),
            /* @__PURE__ */ e.jsx($, {
              fullwidth: !0,
              onClick: I,
              children: r._({
                id: "1nrhR6"
              })
            })
          ]
        });
      case "mfia_failed":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(J, {
              title: r._({
                id: "5KrDgN"
              }),
              description: r._({
                id: "lb1l0c"
              }),
              iconName: "Error"
            }),
            /* @__PURE__ */ e.jsx($, {
              fullwidth: !0,
              onClick: ee,
              children: r._({
                id: "Oem4yT"
              })
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(h, {
          variant: "fullpage"
        });
    }
  };
  function ae() {
    return y === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(fe, {
        onAck: ne,
        welcomeSteps: M
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: n ? /* @__PURE__ */ e.jsx(h, {
        variant: "fullpage"
      }) : C ? /* @__PURE__ */ e.jsx(X, {
        title: "Error",
        description: C
      }) : oe()
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(de, {
        title: r._({
          id: "kyVIEo"
        })
      }),
      ae()
    ]
  });
};
export {
  Ye as ManageAssistedKyc
};
