import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as y, useContext as ge, useMemo as at, useCallback as qt, useEffect as Se, useLayoutEffect as Vt } from "react";
import { a as qe, e as ne, T as Be, M as Z, R as it, c as ke, D as $e, B as fe, i as Kt, b as Te, k as At, l as nt, q as st, S as yt, r as Ze, P as Yt } from "./index.esm-CqWfms5D.js";
import { ad as zt, i as Y, d as ht, A as Q, z as dt, _ as ct, m as Qe, k as Pe, a1 as Pt, S as Jt, f as Zt, a3 as Et, a2 as ze, $ as rt, ae as Ve, ac as Ke, a4 as Tt, a6 as lt, a5 as Je, af as Ct, a7 as ot, a8 as xt, a9 as Lt, aa as It, a0 as Mt, h as Ut, w as Qt, V as Ot, J as Wt, B as Nt } from "./utils-C2gHLhxV.js";
import { F as Le } from "./provider-DKLaKIVD.js";
import { u as gt, A as Xt } from "./Auth-CJPsuV-i.js";
import { LoginContext as ut } from "./LoginContext.js";
import { u as Ye } from "./useConfiguration-B-lyaBB9.js";
import { r as ue } from "./sentry-CstOwvcR.js";
import { i as t } from "./index-CrDCxF0S.js";
import { c as Gt } from "./checkMigratedUser-D-TLMNz-.js";
import { AppContext as jt } from "./AppContext.js";
import { p as ea } from "./partiallyOnboardMigratedUser-CSHFHhW4.js";
import { ErrorPage as se } from "./ErrorPage.js";
import { K as ta } from "./KycCheckStatus-VYtx9hRY.js";
import { S as aa, a as ia } from "./SwitchPlanConsent-kUlk0ucU.js";
import { O as na } from "./OrderStatus-C3VoFfP1.js";
import { b as sa, P as ra, a as la } from "./PurchasePlanConsent-B_WNjB3i.js";
import { R as Oe, F as he } from "./react-final-form.es-CUo9SHJd.js";
import { u as We } from "./useMigratedUser-RRfxTYvK.js";
import { FolioDetails as Ht } from "./FolioDetails.js";
import { F as $t } from "./FolioListing-DEREzZbs.js";
import { P as oa } from "./PurchaseOrderList-BWv-QB2o.js";
import { f as Ce, r as Me, C as da } from "./validators-BNinzCrT.js";
import { M as ca, m as St } from "./MandateListing-DpfjvUeo.js";
import { H as Bt } from "./Holdings-Bzl8QuFg.js";
import { B as ua } from "./BankAccountListing-CcLcjdff.js";
import { O as fa } from "./Onboard-B4_fp4ld.js";
const Ee = (a, c, k, u) => {
  var m;
  const d = {
    descriptionText: "",
    descriptionButton: !1,
    footerText: ""
  };
  return (m = a == null ? void 0 : a.folio_defaults) != null && m.demat_account ? c ? d.descriptionText = t._({
    id: "SQcv3y",
    values: {
      selectedFolio: c
    }
  }) : (d.descriptionText = t._({
    id: "+vqJrJ"
  }), d.footerText = t._({
    id: "EAV83v",
    values: {
      0: u ? "investor" : "your"
    }
  })) : (d.descriptionButton = !!k.length, c ? d.descriptionText = t._({
    id: "//dlk6",
    values: {
      selectedFolio: c
    }
  }) : (d.descriptionText = t._({
    id: "zygJu8"
  }), d.footerText = t._({
    id: "EAV83v",
    values: {
      0: u ? "investor" : "your"
    }
  }))), d;
};
function _a({ scheme: a, investment_account_id: c, investorProfile: k, onSuccess: u, isGroupInvestment: d, isPartner: m, investment_accounts: j, user: te, holdings: B }) {
  const [S, P] = y(null), [s, H] = y(!1), [z, G] = y(!1), [xe, U] = y(!1), [V, ee] = y(null), [w, $] = y(null), [re, N] = y(null), [x, h] = y([]);
  gt();
  const { fpapi: L } = ge(Le), { tenantConfig: v } = Ye(), { loggedInPartner: O, nonPartnerDashboardUser: q } = ge(ut), [le, C] = y(null), [f, n] = y(!1), [g, R] = y(null), [_e, J] = y(!1), [W, me] = y("initial"), { isMigratedUser: D } = We({
    user: te
  }), [ae, ye] = y(!1), { AppState: { employer: Fe } } = ge(jt), K = at(() => {
    var o, r, p, F, A, X;
    const E = (r = (o = v == null ? void 0 : v.fundList) == null ? void 0 : o.find((oe) => oe.isin === a.isin)) == null ? void 0 : r.nfo;
    return ((p = v == null ? void 0 : v.groupInvestment) == null ? void 0 : p.days) && ((F = v == null ? void 0 : v.groupInvestment) == null ? void 0 : F.cutOffDays) && zt({
      salaryDay: (A = v == null ? void 0 : v.groupInvestment) == null ? void 0 : A.days[0],
      salaryCutoffDay: (X = v == null ? void 0 : v.groupInvestment) == null ? void 0 : X.cutOffDays[0],
      NFO: E,
      purchaseDate: null
    });
  }, [
    a
  ]), be = v == null ? void 0 : v.groupInvestment, Re = qt((E, o) => {
    const r = [];
    return E.data.forEach((p) => {
      p.amc === o && r.push(p);
    }), r;
  }, []);
  Se(() => {
    var E;
    if (!d && g) {
      const o = g.filter((r) => {
        const p = r.scheme === a.isin && !r.plan && (r.state === "pending" || r.state === "failed" && r.failure_code === "payment_failure");
        return V ? p && r.folio_number === V : p;
      });
      n(!!o.length);
    }
    (E = a == null ? void 0 : a.delivery_mode) != null && E.includes(Ae().toUpperCase()) || me("scheme_not_allowed");
  }, [
    V
  ]), Se(() => {
    (async () => {
      U(!0);
      try {
        const E = L.fpClient.mf_folios().fetchAll({
          mf_investment_account: c
        }), o = L.fpClient.master_data().fetchAmcs();
        let r;
        d || (r = L.fpClient.mf_purchases().fetchAll({
          mf_investment_account: c,
          states: "pending,failed"
        }));
        const p = await Promise.all([
          E,
          o,
          r
        ]), F = p[1].amcs.find((X) => X.amc_id === a.amc_id), A = Re(p[0], F.amc_code);
        if (!d) {
          const X = p[2].data.filter((de) => {
            const De = de.scheme === a.isin && !de.plan && (de.state === "pending" || de.state === "failed" && de.failure_code === "payment_failure");
            return A.length ? De && de.folio_number === A[0].number : De;
          }), oe = X.length ? X[0] : null;
          n(!!oe), R(p[2].data);
        }
        h(A), N(F.amc_code), C(F == null ? void 0 : F.amc_id), A.length ? ($(A[0]), ee(A[0].number), T(A[0])) : D && ye(!0);
      } catch (E) {
        ue(E);
      } finally {
        U(!1);
      }
    })();
  }, [
    D,
    B
  ]);
  const Ae = () => {
    var E;
    return !Y(w) && (w != null && w.dp_id) || !ht(j) && ((E = j[0].folio_defaults) != null && E.demat_account) ? "Demat" : "Physical";
  }, T = (E) => {
    var o, r;
    if (D) {
      const p = (o = B == null ? void 0 : B.folios) == null ? void 0 : o.filter((A) => A.folio_number === (E == null ? void 0 : E.number)), F = p.length ? (r = p[0].schemes) == null ? void 0 : r.find((A) => A.isin === a.isin) : [];
      Y(F) ? ye(!0) : ye(!1);
    }
  };
  async function je({ amount: E }) {
    var r, p, F;
    const o = {
      mf_investment_account: c,
      scheme: a == null ? void 0 : a.isin,
      amount: +E,
      folio_number: V,
      order_gateway: "rta"
    };
    d && (K != null && K.scheduled_on) && (o.scheduled_on = Pe(K == null ? void 0 : K.scheduled_on, "yyyy-mm-dd")), m && O && !q && (o.partner = O), d && !Y((r = v == null ? void 0 : v.groupInvestment) == null ? void 0 : r.employerPartner) && ((p = v == null ? void 0 : v.groupInvestment) != null && p.employerPartner[Fe == null ? void 0 : Fe.id]) && (o.partner = (F = v == null ? void 0 : v.groupInvestment) == null ? void 0 : F.employerPartner[Fe == null ? void 0 : Fe.id]), L.fpClient.mf_purchases().create(o).then((A) => {
      u({
        mf_order: A,
        shouldOnboardMigratedUser: ae
      });
    }).catch(function(A) {
      P(t._({
        id: "HbQ3GE"
      })), ue(A);
    });
  }
  function ve() {
    return /* @__PURE__ */ e.jsx(Te, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${t._({
        id: "50eZGd"
      })}`,
      isOpen: z,
      toggleModal: G,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(Ht, {
        investorProfile: k,
        investment_accounts: j
      })
    });
  }
  function Ie() {
    return /* @__PURE__ */ e.jsx(Te, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${t._({
        id: "845eUq"
      })}`,
      isOpen: s,
      toggleModal: H,
      contentHeightMax: !1,
      children: /* @__PURE__ */ e.jsx($t, {
        investorProfile: k,
        amc_code: re,
        amcId: le,
        handleFolioSelection: (E) => {
          T(E), $(E), ee(E.number), H(!s);
        },
        handleFolioCreation: () => {
          D && ye(!0), ee(null), $(null), H(!s);
        },
        scheme_details: void 0,
        isPartner: !1
      })
    });
  }
  function b() {
    return /* @__PURE__ */ e.jsx(Te, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${t._({
        id: "ohPawJ"
      })}`,
      isOpen: _e,
      toggleModal: J,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(oa, {
        isGroupInvestment: d,
        investor: k,
        mfInvestmentAccount: j == null ? void 0 : j[0],
        isPartner: m,
        searchParams: {
          type: "purchase",
          status: "pending,failed",
          folio_number: V,
          isin: a.isin
        }
      })
    });
  }
  const we = () => S ? /* @__PURE__ */ e.jsx(se, {
    title: S,
    description: `${t._({
      id: "I4jL42"
    })}`,
    showDetails: !1,
    showAction: !0,
    actionText: `${t._({
      id: "Oem4yT"
    })}`,
    actionHandler: () => {
      P(null);
    }
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Oe, {
        onSubmit: je,
        validate: (E) => {
          const o = {};
          return E.amount || (o.amount = t._({
            id: "c0D/2W"
          })), E.amount && E.amount < a.min_initial_investment && (o.amount = t._({
            id: "9LeqUy",
            values: {
              0: Q(a.min_initial_investment)
            }
          })), E.amount && E.amount > a.max_initial_investment && (o.amount = t._({
            id: "DJzP4H",
            values: {
              0: Q(a.max_initial_investment)
            }
          })), o;
        },
        children: (E) => /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: E.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "4NN3aK"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  name: "amount",
                  children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Be, {
                        id: o.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: o.input.name,
                        value: dt(o.input.value),
                        onChange: (r) => o.input.onChange(ct(r.target.value)),
                        onInput: (r) => {
                          r.target.value = r.target.value.replace(Qe("0-9"), "");
                        },
                        placeholder: `${t._({
                          id: "cRSGbK"
                        })}`,
                        prefix: "₹",
                        status: o.meta.error && o.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      o.meta.error && o.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Z, {
                          type: "error",
                          variant: "field",
                          title: o.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(Z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "bYw+YE",
                          values: {
                            0: Q(Number(a.min_initial_investment))
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            d && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "5vGD9a"
                  })}`
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "flex items-start",
                  children: [
                    /* @__PURE__ */ e.jsx(it, {
                      variant: "iconBased",
                      name: "paymentMethodRadio",
                      group: [
                        {
                          icon: "Salary",
                          label: "Salary"
                        }
                      ],
                      disabled: []
                    }),
                    /* @__PURE__ */ e.jsx(ke, {
                      border: !0,
                      symbol: (be == null ? void 0 : be.groupPartnerLogo) || (v == null ? void 0 : v.fallbackLogo),
                      label: be == null ? void 0 : be.groupPartnerName,
                      value: `${t._({
                        id: "yFOTgq",
                        values: {
                          0: K != null && K.consideredSalaryDate ? ` in ${new Date(K == null ? void 0 : K.consideredSalaryDate).toLocaleString("default", {
                            month: "long"
                          })} ${new Date(K == null ? void 0 : K.consideredSalaryDate).getFullYear()}` : ""
                        }
                      })}`,
                      customClass: "ml-3 flex-1"
                    })
                  ]
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "pt-8",
              children: [
                /* @__PURE__ */ e.jsx($e, {
                  type: "notes",
                  customClass: "items-baseline",
                  text: Ee(j == null ? void 0 : j[0], V, x, m).descriptionText,
                  footer: Ee(j == null ? void 0 : j[0], V, x, m).footerText ? /* @__PURE__ */ e.jsxs("div", {
                    className: "flex items-baseline justify-between",
                    children: [
                      /* @__PURE__ */ e.jsx("span", {
                        className: "text-size-14 text-neutral-60 dark:text-neutral-40 leading-tight",
                        children: Ee(j == null ? void 0 : j[0], V, x, m).footerText
                      }),
                      /* @__PURE__ */ e.jsx(fe, {
                        variant: "text",
                        onClick: (o) => {
                          o.preventDefault(), G(!z);
                        },
                        customClass: "ml-2",
                        children: `${t._({
                          id: "QVxGwf"
                        })}`
                      })
                    ]
                  }) : null,
                  children: Ee(j == null ? void 0 : j[0], V, x, m).descriptionButton ? /* @__PURE__ */ e.jsx(fe, {
                    variant: "text",
                    type: "button",
                    onClick: (o) => {
                      o.preventDefault(), H(!s);
                    },
                    children: t._({
                      id: "ShFidp"
                    })
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                }),
                f && !D && /* @__PURE__ */ e.jsx(Kt, {
                  variant: "note",
                  message: m ? "The investor have order(s) which are awaiting payment." : "You have order(s) which are awaiting payment.",
                  status: "warning",
                  children: /* @__PURE__ */ e.jsx("div", {
                    className: "text-center",
                    children: /* @__PURE__ */ e.jsx(fe, {
                      type: "button",
                      variant: "text",
                      onClick: (o) => {
                        o.preventDefault(), J(!_e);
                      },
                      children: "View Orders"
                    })
                  })
                }),
                /* @__PURE__ */ e.jsx(fe, {
                  type: "submit",
                  fullwidth: !0,
                  children: !d && !m ? t._({
                    id: "Ffad/b"
                  }) : t._({
                    id: "H/E6fZ"
                  })
                })
              ]
            })
          ]
        })
      }),
      Ie(),
      ve(),
      b()
    ]
  }), M = () => {
    switch (W) {
      case "initial":
        return we();
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "WddszM"
          }),
          description: t._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return we();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: xe ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : M()
  });
}
function ma({ scheme: a, investment_account_id: c, onSuccess: k, investorProfile: u, isGroupInvestment: d, isPartner: m, investment_accounts: j, user: te, holdings: B }) {
  var wt, vt, Dt;
  const { analytics: S } = gt(), { fpapi: P } = ge(Le), { tenantConfig: s, banks: H } = Ye(), z = s == null ? void 0 : s.fallbackLogo, { loggedInPartner: G, nonPartnerDashboardUser: xe } = ge(ut), { AppState: { employer: U } } = ge(jt), [V, ee] = y(""), [w, $] = y(null), [re, N] = y("initial"), [x, h] = y(""), [L, v] = y(!1), [O, q] = y(!1), [le, C] = y(!1), [f, n] = y(""), [g, R] = y(null), [_e, J] = y(null), [W, me] = y(!1), [D, ae] = y([]), [ye, Fe] = y(!1), K = /* @__PURE__ */ new Date(), { isMigratedUser: be } = We({
    user: te
  }), [Re, Ae] = y(!1), T = (wt = s == null ? void 0 : s.app_constants) == null ? void 0 : wt.plans_mode, je = ((vt = s == null ? void 0 : s.app_constants) == null ? void 0 : vt.non_systematic_min_installments) || 3;
  let ve = Pt(a.sip_frequency_specific_data, T);
  const Ie = ((Dt = s == null ? void 0 : s.groupInvestment) == null ? void 0 : Dt.frequencyOptions) || [], b = at(() => {
    var l, i, _, I, ie, ce;
    if (d) {
      const pe = (i = (l = s == null ? void 0 : s.fundList) == null ? void 0 : l.find((Ne) => Ne.isin === a.isin)) == null ? void 0 : i.nfo;
      return ((_ = s == null ? void 0 : s.groupInvestment) == null ? void 0 : _.days) && ((I = s == null ? void 0 : s.groupInvestment) == null ? void 0 : I.cutOffDays) && Jt({
        salaryDay: (ie = s == null ? void 0 : s.groupInvestment) == null ? void 0 : ie.days[0],
        salaryCutoffDay: (ce = s == null ? void 0 : s.groupInvestment) == null ? void 0 : ce.cutOffDays[0],
        NFO: pe,
        purchaseDate: null
      });
    }
  }, [
    a
  ]);
  if (d) {
    const l = [];
    T === "systematic" ? (Object.keys(a == null ? void 0 : a.sip_frequency_specific_data).forEach((i) => {
      var _;
      Ie.includes(i) && l.push({
        label: (_ = Zt(i)) == null ? void 0 : _.replace(/_/g, " "),
        value: i
      });
    }), ve = l) : T === "nonSystematic" && (Object.values(ve).forEach((i) => {
      Ie.includes(i == null ? void 0 : i.value) && l.push(i);
    }), ve = l);
  }
  const we = s == null ? void 0 : s.groupInvestment;
  Se(() => {
    var l;
    if (!((l = a == null ? void 0 : a.delivery_mode) != null && l.includes(Rt().toUpperCase()))) {
      N("scheme_not_allowed");
      return;
    }
    d || oe(), !Y(g) && r(g), X();
  }, [
    be
  ]);
  const [M, E] = y(ve[0].value), o = () => M === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(K.getDate() + 2)) : T === "nonSystematic" ? new Date(K.getFullYear(), K.getMonth() + ze[M] * je, K.getDate() + 1) : new Date(K.getFullYear(), K.getMonth() + ze[M] * (a == null ? void 0 : a.sip_frequency_specific_data[M].min_installments), K.getDate() + 1), r = (l) => {
    var i, _;
    if (be) {
      const I = (i = B == null ? void 0 : B.folios) == null ? void 0 : i.filter((ce) => ce.folio_number === (l == null ? void 0 : l.number)), ie = I.length ? (_ = I[0].schemes) == null ? void 0 : _.find((ce) => ce.isin === a.isin) : [];
      Y(ie) ? Ae(!0) : Ae(!1);
    }
  }, p = at(() => {
    var l;
    return {
      frequency: ve[0],
      sip_start_date: new Date((/* @__PURE__ */ new Date()).setDate(K.getDate() + 1)),
      sip_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(K.getDate() + (((l = s == null ? void 0 : s.app_constants) == null ? void 0 : l.plan_max_start_days) || 0))),
      sip_end_date: o(),
      sip_installment_type: "perpetual",
      sip_max_date: new Date(2099, 11, 31),
      sip_end_max_date: Et(M),
      group_sip_start_date: b == null ? void 0 : b.installment_date,
      group_sip_end_date: T === "nonSystematic" ? new Date(b == null ? void 0 : b.installment_date.getFullYear(), (b == null ? void 0 : b.installment_date.getMonth()) + ze[M] * je, b == null ? void 0 : b.installment_date.getDate()) : new Date(b == null ? void 0 : b.installment_date.getFullYear(), (b == null ? void 0 : b.installment_date.getMonth()) + ze[M] * (a == null ? void 0 : a.sip_frequency_specific_data[M].min_installments), b == null ? void 0 : b.installment_date.getDate())
    };
  }, [
    M
  ]), F = (l) => d ? T === "systematic" ? Je(l, a.sip_frequency_specific_data[M].dates) || Ct(l, b == null ? void 0 : b.installment_date) : Je(l, Ke(M)) || Ct(l, b == null ? void 0 : b.installment_date) : T === "systematic" ? Je(l, a.sip_frequency_specific_data[M].dates, M) : Je(l, Ke(M), M), A = qt((l, i) => {
    const _ = [];
    return l.forEach((I) => {
      I.amc === i && _.push(I);
    }), _;
  }, []), X = async () => {
    try {
      const l = await P.fpClient.mf_folios().fetchAll({
        mf_investment_account: c
      }), _ = (await P.fpClient.master_data().fetchAmcs()).amcs.find((ie) => ie.amc_id === a.amc_id), I = A(l.data, _.amc_code);
      ae(I), J(_.amc_code), I.length ? (R(I[0]), n(I[0].number)) : be && Ae(!0);
    } catch (l) {
      window.debug.log(l), ue(l);
    }
  }, oe = async () => {
    try {
      N("loading");
      const l = await P.fpClient.bank_accounts().fetchAll({
        profile: u == null ? void 0 : u.id
      }), i = l.data.map((ie) => ie.old_id), I = (await P.fpClient.mandates().fetchAll({
        bank_account_id: i.join(",")
      })).mandates;
      if (I.sort((ie, ce) => Number(new Date(ce.created_at)) - Number(new Date(ie.created_at))), I.length > 0) {
        l.data.forEach((pe) => {
          I.forEach((Ne) => {
            Ne.bank_account_id === pe.old_id && (Ne.bank = {
              bank_name: pe.bank_name,
              number: pe.account_number,
              ifsc: pe.ifsc_code
            });
          });
        });
        const ie = [], ce = [];
        I.forEach((pe) => {
          pe.mandate_status === "CREATED" || pe.mandate_status === "SUBMITTED" ? ie.push(pe) : pe.mandate_status === "APPROVED" && ce.push(pe);
        }), ce.length ? $(ce[0]) : ie.length && $(ie[0]), N("initial");
      } else
        N("initial");
    } catch (l) {
      window.debug.error(l), N("initial"), ue(l);
    }
  }, de = (l) => {
    l.stopPropagation(), me(!W);
  }, De = (l) => {
    l.stopPropagation(), Fe(!ye);
  };
  async function Xe({ amount: l, frequency: i, installments: _, sip_start_date: I, sip_instl_type: ie, sip_end_date: ce }) {
    var Ge, et, pt;
    if (!w && !d && !ye) {
      h(t._({
        id: "dsdjm4"
      }));
      return;
    }
    N("loading"), ie === "perpetual" ? _ = 1200 : ie === "fixed" && (_ = ot(I, ce, i.value, T === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[i.value].dates : Ke(i.value), T));
    const pe = xt(I, i.value);
    let Ne;
    pe !== null && (Ne = Pe(pe));
    try {
      const { emailData: kt, phoneData: va } = await P.fetchEmailAndPhone(u), tt = {
        mf_investment_account: c,
        scheme: a.isin,
        frequency: i.value,
        folio_number: f,
        amount: +l,
        installment_day: i.value === "daily" ? null : Lt(I, i.value),
        number_of_installments: +_,
        systematic: T === "systematic",
        payment_method: !d && !ye ? "mandate" : null,
        payment_source: !d && !ye ? w == null ? void 0 : w.id : null,
        purpose: null,
        generate_first_installment_now: W,
        auto_generate_installments: !0,
        activate_after: Ne,
        euin: null,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null,
        source_ref_id: null,
        order_gateway: "rta"
      };
      m && G && !xe && (tt.partner = G), d && !Y((Ge = s == null ? void 0 : s.groupInvestment) == null ? void 0 : Ge.employerPartner) && ((et = s == null ? void 0 : s.groupInvestment) != null && et.employerPartner[U == null ? void 0 : U.id]) && (tt.partner = (pt = s == null ? void 0 : s.groupInvestment) == null ? void 0 : pt.employerPartner[U == null ? void 0 : U.id]), d && (b != null && b.activate_after) && (tt.activate_after = Pe(b == null ? void 0 : b.activate_after, "yyy-mm-dd"));
      const Ft = await P.fpClient.mf_purchase_plans().create(tt);
      S.track("Recurring Order Placed", {
        category: "Recurring Orders",
        revenue: l
      }), W && !d ? (N("initial"), k({
        mf_order: Ft,
        isGenerateFirstInstallment: W,
        shouldOnboardMigratedUser: Re
      })) : (N("initial"), k({
        mf_order: Ft,
        shouldOnboardMigratedUser: Re
      }));
    } catch (kt) {
      N("initial"), ee(t._({
        id: "HbQ3GE"
      })), ue(kt);
    }
  }
  const Ue = (l, i) => {
    const _ = w;
    let I = !1, ie = !1, ce = !1;
    if (d)
      return !1;
    if (l && _) {
      const Ne = /* @__PURE__ */ new Date(), Ge = new Date(l), et = Math.abs(Ne.getTime() - Ge.getTime());
      I = Math.ceil(et / (1e3 * 3600 * 24)) > 21;
    }
    return _ && (ie = (_ == null ? void 0 : _.mandate_status) === "CREATED"), xt(l, i ?? M) === null ? ce = !0 : ce = !1, (I || ie) && ce;
  }, ft = ([l, i], _, { changeValue: I }) => {
    i[0] = i[0] === null ? p.sip_start_date : i[0], i[0] = lt(i[0], i[1], T === "systematic" ? a.sip_frequency_specific_data[i[1]].min_installments : je), I(_, l, () => i[0]);
  }, _t = ([l, i], _, { changeValue: I }) => {
    i[0] !== null && (i[0] = It(i[1], T === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[i[1]].dates : Ke(i[1]))), I(_, l, () => i[0]);
  }, mt = (l, i) => {
    const _ = ot(l || p.sip_start_date, i, M, T === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[M].dates : Ke(M), T);
    return T === "systematic" && _ <= a.sip_frequency_specific_data[M].min_installments ? !0 : T === "nonSystematic" && _ <= je;
  }, He = () => {
    var l, i;
    return d ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(ne, {
            message: "Payment method"
          }),
          /* @__PURE__ */ e.jsx($e, {
            type: "labelDesc",
            text: "Setup automatic payment for installments"
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mt-4 flex items-start",
            children: [
              /* @__PURE__ */ e.jsx(it, {
                variant: "iconBased",
                name: "paymentMethodRadio",
                group: [
                  {
                    icon: "Salary",
                    label: "Salary"
                  }
                ],
                disabled: []
              }),
              /* @__PURE__ */ e.jsx(ke, {
                border: !0,
                symbol: (we == null ? void 0 : we.groupPartnerLogo) || (s == null ? void 0 : s.fallbackLogo),
                label: we == null ? void 0 : we.groupPartnerName,
                value: `Your employer will pay the installment amount from your salary starting from 
            ${b != null && b.consideredSalaryDate ? ` ${new Date(b == null ? void 0 : b.consideredSalaryDate).toLocaleString("default", {
                  month: "long"
                })} ${new Date(b == null ? void 0 : b.consideredSalaryDate).getFullYear()}` : ""}
            `,
                customClass: "ml-3 flex-1"
              })
            ]
          })
        ]
      })
    }) : /* @__PURE__ */ e.jsxs("div", {
      className: "mb-8",
      children: [
        /* @__PURE__ */ e.jsx(ne, {
          message: `${t._({
            id: "5vGD9a"
          })}`
        }),
        /* @__PURE__ */ e.jsx($e, {
          type: "labelDesc",
          text: `${t._({
            id: "m/rm5V"
          })}`
        }),
        w && w.bank ? /* @__PURE__ */ e.jsx(yt, {
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 w-full pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: "Bank",
                  value: w ? `${w.bank.bank_name} - ****${w.bank.number.slice(-4)}` : "-",
                  logo: H[(i = (l = w == null ? void 0 : w.bank) == null ? void 0 : l.ifsc) == null ? void 0 : i.slice(0, 4)] || z
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: "Amount limit",
                  value: w ? `₹${Q(w.mandate_limit)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: "Authorization",
                  value: w ? St[w.mandate_status].value : "-",
                  icon: w ? St[w.mandate_status].icon : "",
                  iconPlacement: "left"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(fe, {
                  variant: "text",
                  onClick: (_) => {
                    _.preventDefault(), _.stopPropagation(), v(!0);
                  },
                  children: `${t._({
                    id: "ShFidp"
                  })}`
                })
              })
            ]
          })
        }) : /* @__PURE__ */ e.jsx($e, {
          type: "borderedNotes",
          text: "Setup mandate",
          children: /* @__PURE__ */ e.jsx(fe, {
            variant: "text",
            onClick: (_) => {
              _.preventDefault(), _.stopPropagation(), v(!0);
            },
            children: `${t._({
              id: "JW7jyx"
            })}`
          })
        })
      ]
    });
  }, Rt = () => {
    var l;
    return !Y(g) && (g != null && g.dp_id) || !ht(j) && ((l = j[0].folio_defaults) != null && l.demat_account) ? "Demat" : "Physical";
  }, bt = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      V ? /* @__PURE__ */ e.jsx(se, {
        title: V,
        description: `${t._({
          id: "mLEhv8"
        })}`,
        showDetails: !1,
        showAction: !0,
        actionText: `${t._({
          id: "RvsoP+"
        })}`,
        actionHandler: () => {
          ee(""), E(ve[0].value);
        }
      }) : /* @__PURE__ */ e.jsx(Oe, {
        onSubmit: Xe,
        mutators: {
          updateSipEndDate_mutator: ft,
          updateSipStartDate_mutator: _t
        },
        validate: (l) => {
          var _;
          const i = {};
          return l.amount || (i.amount = t._({
            id: "c0D/2W"
          })), l.amount && w && !ye && l.amount > w.mandate_limit && (i.amount = t._({
            id: "5VDa6T"
          })), T === "systematic" && l.amount && l.amount < a.sip_frequency_specific_data[M].min_installment_amount && (i.amount = t._({
            id: "9LeqUy",
            values: {
              0: Q(a.sip_frequency_specific_data[M].min_installment_amount)
            }
          })), T === "systematic" && l.amount && l.amount > a.sip_frequency_specific_data[M].max_installment_amount && (i.amount = t._({
            id: "DJzP4H",
            values: {
              0: Q(a.sip_frequency_specific_data[M].max_installment_amount)
            }
          })), T === "systematic" && rt(l.amount, (_ = a == null ? void 0 : a.sip_frequency_specific_data[M]) == null ? void 0 : _.amount_multiples) === !1 && (i.amount = t._({
            id: "O/Jkev",
            values: {
              0: a.sip_frequency_specific_data[M].amount_multiples
            }
          })), T === "systematic" && l.installments && Number(l.installments) < a.sip_frequency_specific_data[M].min_installments && (i.installments = t._({
            id: "O9AVb9",
            values: {
              0: a.sip_frequency_specific_data[M].min_installments
            }
          })), l.installments > 1200 && (i.installments = t._({
            id: "kX+aWa"
          })), l.sip_end_date < l.sip_start_date && (i.sip_end_date = t._({
            id: "/4FybD"
          })), T === "systematic" && d && !(a != null && a.sip_frequency_specific_data[M].dates.includes(b == null ? void 0 : b.installment_date.getDate())) && (i.frequency = t._({
            id: "S+LyGb"
          })), T === "nonSystematic" && l.amount && l.amount < Number(a[Ve(f).minInvestmentAmount]) && (i.amount = t._({
            id: "9LeqUy",
            values: {
              0: Q(Number(a[Ve(f).minInvestmentAmount]))
            }
          })), T === "nonSystematic" && l.amount && l.amount > Number(a[Ve(f).maxInvestmentAmount]) && (i.amount = t._({
            id: "DJzP4H",
            values: {
              0: Q(Number(a[Ve(f).maxInvestmentAmount]))
            }
          })), T === "nonSystematic" && rt(l.amount, Number(a[Ve(f).investmentAmountMultiples])) === !1 && (i.amount = t._({
            id: "O/Jkev",
            values: {
              0: Number(a[Ve(f).investmentAmountMultiples])
            }
          })), T === "nonSystematic" && l.installments && Number(l.installments) < je && (i.installments = t._({
            id: "fY6D71",
            values: {
              nonSipMinimumInstallments: je
            }
          })), T === "nonSystematic" && d && !Ke(M).includes(b == null ? void 0 : b.installment_date.getDate()) && (i.frequency = t._({
            id: "S+LyGb"
          })), i;
        },
        subscription: {
          submitting: !0,
          values: !0
        },
        children: (l) => /* @__PURE__ */ e.jsxs("form", {
          id: "recurringOrderForm",
          onSubmit: l.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "n30BLK"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  name: "frequency",
                  initialValue: p.frequency,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Ce(Me),
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(At, {
                        name: i.input.name,
                        value: i.input.value,
                        onChange: (_) => {
                          const I = [
                            l.values.sip_start_date,
                            _ == null ? void 0 : _.value,
                            l.values.sip_end_date
                          ];
                          d || l.form.mutators.updateSipStartDate_mutator("sip_start_date", I), l.form.mutators.updateSipEndDate_mutator("sip_end_date", I), W && me(Ue(l.values.sip_start_date, _ == null ? void 0 : _.value)), E(_ == null ? void 0 : _.value), i.input.onChange(_);
                        },
                        options: ve
                      }),
                      i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: i.meta.error
                      }),
                      i.input.value.value === "daily" && /* @__PURE__ */ e.jsx(Z, {
                        type: "helper",
                        variant: "field",
                        title: `${t._({
                          id: "EsyhvE"
                        })}`,
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "MznISI"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  name: "amount",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Ce(Me),
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Be, {
                        id: i.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: i.input.name,
                        value: dt(i.input.value),
                        onChange: (_) => i.input.onChange(ct(_.target.value)),
                        onInput: (_) => {
                          _.target.value = _.target.value.replace(Qe("0-9"), "");
                        },
                        placeholder: `${t._({
                          id: "fK0Zpc"
                        })}`,
                        prefix: "₹",
                        status: i.meta.error && i.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Z, {
                          type: "error",
                          variant: "field",
                          title: i.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(Z, {
                        type: "helper",
                        variant: "field",
                        title: `${t._({
                          id: "4NTS7E",
                          values: {
                            0: T === "systematic" ? `₹${Q(Number(a.sip_frequency_specific_data[M].min_installment_amount))}` : `₹${Q(Number(a.min_initial_investment))}`
                          }
                        })}`
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "701So9"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  name: "sip_start_date",
                  initialValue: d ? p.group_sip_start_date : null,
                  validate: Ce(Me),
                  allowNull: !0,
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      !d && /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(nt, {
                            variant: "keyboardInputDOB",
                            onKeyDown: (_) => _.preventDefault(),
                            disableFuture: !1,
                            name: i.input.name,
                            value: i.input.value,
                            format: "dd-MMM-yyyy",
                            minDate: d ? p.group_sip_start_date : p.sip_start_date,
                            maxDate: p.sip_start_max_date,
                            selectedDate: i.input.value || null,
                            error: i.meta.error && i.meta.touched,
                            views: [
                              "date"
                            ],
                            placeholder: "Select start date",
                            labelFunc: (_) => Tt(_, M),
                            shouldDisableDate: F,
                            handleDateChange: (_) => {
                              W && me(Ue(_)), mt(_, l.values.sip_end_date || p.sip_end_date) && l.form.mutators.updateSipEndDate_mutator("sip_end_date", [
                                _,
                                M,
                                l.values.sip_end_date
                              ]), i.input.onChange(_);
                            },
                            disabled: d
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(Z, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      }),
                      i.input.value && /* @__PURE__ */ e.jsx(Z, {
                        type: "helper",
                        variant: "field",
                        title: `${t._({
                          id: "+7SGSQ",
                          values: {
                            0: Pe(i.input.value, "dd-MMM-yyyy")
                          }
                        })}`,
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "a9ECYP"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  type: "radio",
                  name: "sip_instl_type",
                  initialValue: p.sip_installment_type,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Ce(Me),
                  children: (i) => /* @__PURE__ */ e.jsx(e.Fragment, {
                    children: /* @__PURE__ */ e.jsx(it, {
                      name: i.input.name,
                      value: i.input.value,
                      group: [
                        {
                          label: t._({
                            id: "56g9kZ"
                          }),
                          value: "perpetual"
                        },
                        {
                          label: t._({
                            id: "vEYjLl"
                          }),
                          value: "installments"
                        },
                        {
                          label: t._({
                            id: "g9rYAC"
                          }),
                          value: "fixed"
                        }
                      ],
                      size: "sm",
                      onChange: (_, I) => {
                        i.input.onChange(I.value);
                      }
                    })
                  })
                })
              ]
            }),
            l.values.sip_instl_type === "installments" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "qG3axk"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  name: "installments",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Ce(Me),
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Be, {
                        id: i.input.name,
                        autoComplete: "off",
                        name: i.input.name,
                        onChange: i.input.onChange,
                        placeholder: `${t._({
                          id: "LQjwJ/"
                        })}`,
                        status: i.meta.error && i.meta.touched ? "error" : void 0,
                        onInput: (_) => {
                          _.target.value = _.target.value.replace(Qe("0-9"), "");
                        },
                        inputMode: "numeric",
                        autoFocus: !0
                      }),
                      i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Z, {
                          type: "error",
                          variant: "field",
                          title: i.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(Z, {
                        type: "helper",
                        variant: "field",
                        title: `${t._({
                          id: "J0wmPL",
                          values: {
                            0: T === "systematic" ? `${Q(Number(a.sip_frequency_specific_data[M].min_installments))}` : `${Q(Number(je))}`
                          }
                        })}`
                      })
                    ]
                  })
                })
              ]
            }),
            l.values.sip_instl_type === "fixed" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ne, {
                  message: `${t._({
                    id: "iUVPy/"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(he, {
                  name: "sip_end_date",
                  initialValue: d ? p.group_sip_end_date : p.sip_end_date,
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(nt, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (_) => _.preventDefault(),
                        disableFuture: !1,
                        name: i.input.name,
                        value: i.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: lt(l.values.sip_start_date, M, T === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[M].min_installments : je),
                        maxDate: p.sip_end_max_date,
                        selectedDate: i.input.value,
                        error: i.meta.error && i.meta.touched,
                        views: [
                          "date"
                        ],
                        handleDateChange: (_) => {
                          i.input.onChange(_);
                        }
                      }),
                      i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Z, {
                          type: "error",
                          variant: "field",
                          title: i.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(Z, {
                        type: "helper",
                        variant: "field",
                        title: `${t._({
                          id: "J0wmPL",
                          values: {
                            0: T === "systematic" ? `${Q(Number(a.sip_frequency_specific_data[M].min_installments))}` : `${Q(Number(je))}`
                          }
                        })}`
                      })
                    ]
                  })
                })
              ]
            }),
            ye ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : He(),
            !d && /* @__PURE__ */ e.jsx(st, {
              checked: ye,
              onChange: De,
              id: "2",
              customClass: "mb-8",
              children: "Add mandate later"
            }),
            Ue(l.form.getState().values.sip_start_date) && /* @__PURE__ */ e.jsx(st, {
              variant: "cardType",
              description: t._({
                id: "qEgHb4",
                values: {
                  0: m ? "Investor" : "You"
                }
              }),
              id: "1",
              checked: W,
              onChange: de,
              children: /* @__PURE__ */ e.jsx("span", {
                className: "dark:text-primary-DN110",
                children: "Start first installment today"
              })
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "pt-4",
              children: [
                x && /* @__PURE__ */ e.jsx("div", {
                  className: "mb-2 mt-2",
                  children: /* @__PURE__ */ e.jsx(Z, {
                    type: "error",
                    variant: "field",
                    title: x
                  })
                }),
                /* @__PURE__ */ e.jsx($e, {
                  type: "notes",
                  customClass: "items-baseline mb-2",
                  text: Ee(j == null ? void 0 : j[0], f, D, m).descriptionText,
                  footer: Ee(j == null ? void 0 : j[0], f, D, m).footerText ? /* @__PURE__ */ e.jsxs("div", {
                    className: "flex items-baseline justify-between",
                    children: [
                      /* @__PURE__ */ e.jsx("span", {
                        className: "text-size-14 text-neutral-60 dark:text-neutral-40 leading-tight",
                        children: Ee(j == null ? void 0 : j[0], f, D, m).footerText
                      }),
                      /* @__PURE__ */ e.jsx(fe, {
                        variant: "text",
                        onClick: (i) => {
                          i.preventDefault(), C(!le);
                        },
                        customClass: "ml-2",
                        children: "View"
                      })
                    ]
                  }) : null,
                  children: Ee(j == null ? void 0 : j[0], f, D, m).descriptionButton ? /* @__PURE__ */ e.jsx(fe, {
                    type: "button",
                    variant: "text",
                    onClick: (i) => {
                      i.preventDefault(), q(!O);
                    },
                    children: t._({
                      id: "ShFidp"
                    })
                  }) : null
                }),
                /* @__PURE__ */ e.jsx(fe, {
                  variant: ye || d || w ? "primary" : "disabled",
                  type: "submit",
                  fullwidth: !0,
                  children: "Confirm"
                })
              ]
            })
          ]
        })
      }),
      /* @__PURE__ */ e.jsx(Te, {
        variant: "fullPage",
        title: "Select mandate",
        isOpen: L,
        toggleModal: () => {
          v(!1);
        },
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(ca, {
          investorProfile: u,
          isPartner: m,
          handleMandateSelection: (l) => {
            $(l), v(!1);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Te, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: O,
        toggleModal: () => {
          q(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ e.jsx($t, {
          investorProfile: u,
          amc_code: _e,
          handleFolioSelection: (l) => {
            r(l), R(l), n(l.number), q(!O);
          },
          handleFolioCreation: () => {
            be && Ae(!0), n(""), R(null), q(!O);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Te, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: le,
        toggleModal: C,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(Ht, {
          investorProfile: u,
          investment_accounts: j
        })
      })
    ]
  });
  return (() => {
    switch (re) {
      case "initial":
        return bt();
      case "loading":
        return /* @__PURE__ */ e.jsx(qe, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "WddszM"
          }),
          description: t._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return bt();
    }
  })();
}
function pa({ holdings: a, investment_account_id: c, scheme: k, selectedSwitchOutScheme: u, onSuccess: d, user: m }) {
  const [j, te] = y(!1), [B, S] = y("initial"), [P, s] = y(!1), { fpapi: H } = ge(Le);
  Ye();
  const { isMigratedUser: z, migratedMfiaList: G, migratedFolioList: xe } = We({
    user: m
  }), [U, V] = y(!1), ee = (N) => {
    var x, h;
    if (z) {
      const L = (x = a == null ? void 0 : a.folios) == null ? void 0 : x.filter((O) => O.folio_number === N), v = L != null && L.length ? (h = L[0].schemes) == null ? void 0 : h.find((O) => O.isin === k.isin) : [];
      Y(v) ? V(!0) : V(!1);
    }
  };
  Se(() => {
    const N = u ? a[u == null ? void 0 : u.key].folio_number : "";
    ee(N);
  }, [
    u,
    z
  ]);
  async function w({ amount: N }) {
    te(!0);
    try {
      const x = await H.fpClient.mf_switches().create({
        mf_investment_account: c,
        switch_out_scheme: u.isin,
        switch_in_scheme: k.isin,
        amount: P ? null : N,
        folio_number: a[u.key].folio_number,
        order_gateway: "rta"
      });
      te(!1), d({
        mf_order: x,
        shouldOnboardMigratedUser: U
      });
    } catch (x) {
      te(!1), S("error"), ue(x);
    }
  }
  function $() {
    return /* @__PURE__ */ e.jsx(Oe, {
      onSubmit: w,
      validate: (N) => {
        const x = {};
        return P || (N.amount || (x.amount = t._({
          id: "7rkFUv"
        })), N.amount && u && N.amount < u.min_switch_out_amount && (x.amount = t._({
          id: "PuxVdZ",
          values: {
            0: Q(u.min_switch_out_amount, 2)
          }
        })), N.amount && k.min_switch_in_amount && N.amount < k.min_switch_in_amount && (x.amount = t._({
          id: "ucZP5J",
          values: {
            0: Q(k.min_switch_in_amount, 2)
          }
        })), N.amount && a && u && N.amount > a[u.key].market_value.redeemable_amount && (x.amount = t._({
          id: "yxdAlQ",
          values: {
            0: Q(a[u.key].market_value.redeemable_amount)
          }
        })), rt(N.amount, u == null ? void 0 : u.switch_out_amount_multiples) === !1 && (x.amount = t._({
          id: "EjZXF8",
          values: {
            0: Q(u.switch_out_amount_multiples)
          }
        }))), x;
      },
      children: (N) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: N.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "KqeLJ1"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "amount",
                children: (x) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Be, {
                      id: x.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: x.input.name,
                      value: P ? "" : dt(x.input.value),
                      onChange: (h) => x.input.onChange(ct(h.target.value)),
                      onInput: (h) => {
                        h.target.value = h.target.value.replace(Qe("0-9"), "");
                      },
                      placeholder: t._({
                        id: "qUFsyJ"
                      }),
                      prefix: "₹",
                      disabled: P,
                      status: x.meta.error && x.meta.touched ? "error" : void 0,
                      autoFocus: !0
                    }),
                    x.meta.error && x.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: x.meta.error
                      })
                    }) : u && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "BgHHhn",
                        values: {
                          0: Q(Number(a && a[u.key].market_value.redeemable_amount), 2)
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(st, {
            id: "switch-all-id",
            checked: P,
            onChange: (x) => {
              x.stopPropagation(), s(!P);
            },
            children: t._({
              id: "dNBP2o"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(fe, {
              type: "submit",
              variant: (!u || a && a[u.key].market_value.redeemable_amount <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "3+3T+B"
              })
            })
          })
        ]
      })
    });
  }
  function re() {
    switch (B) {
      case "initial":
        return $();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "SOfRak"
          }),
          description: t._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "wy5cp6"
          }),
          actionHandler: (N) => S("initial")
        });
      default:
        return $();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: j ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: re()
    })
  });
}
function xa({ holdings: a, investment_account_id: c, scheme: k, selectedSwitchOutScheme: u, onSuccess: d, user: m }) {
  const [j, te] = y(!1), [B, S] = y("initial"), [P, s] = y(!1), { fpapi: H } = ge(Le);
  Ye();
  const { isMigratedUser: z, migratedMfiaList: G, migratedFolioList: xe } = We({
    user: m
  }), [U, V] = y(!1), ee = (x) => {
    var h, L;
    if (z) {
      const v = (h = a == null ? void 0 : a.folios) == null ? void 0 : h.filter((q) => q.folio_number === x), O = v != null && v.length ? (L = v[0].schemes) == null ? void 0 : L.find((q) => q.isin === k.isin) : [];
      Y(O) ? V(!0) : V(!1);
    }
  };
  Se(() => {
    const x = u ? a[u == null ? void 0 : u.key].folio_number : "";
    ee(x);
  }, [
    u,
    z
  ]);
  async function w({ unit: x }) {
    te(!0);
    try {
      const h = await H.fpClient.mf_switches().create({
        mf_investment_account: c,
        switch_out_scheme: u.isin,
        switch_in_scheme: k.isin,
        units: P ? null : x,
        folio_number: a[u.key].folio_number,
        order_gateway: "rta"
      });
      te(!1), d({
        mf_order: h,
        shouldOnboardMigratedUser: U
      });
    } catch (h) {
      te(!1), S("error"), ue(h);
    }
  }
  const $ = (x, h) => {
    const L = x.target.value;
    Mt(L, k.switch_out_unit_multiples) !== 0 ? x.preventDefault() : h.input.onChange(L);
  };
  function re() {
    return /* @__PURE__ */ e.jsx(Oe, {
      onSubmit: w,
      validate: (x) => {
        const h = {};
        if (P)
          return h;
        if (x.unit || (h.unit = t._({
          id: "7rkFUv"
        })), x.unit && u && (x.unit < u.min_switch_out_units || x.unit * a[u.key].nav.value < u.min_switch_in_amount)) {
          const L = Math.max((u.min_switch_in_amount / a[u.key].nav.value).toFixed(3), u.min_switch_out_units);
          h.unit = t._({
            id: "K4I1VP",
            values: {
              max_units: L
            }
          });
        }
        return x.unit && a && u && x.unit > a[u.key].holdings.redeemable_units && (h.unit = t._({
          id: "4yUAdy",
          values: {
            0: a[u.key].holdings.redeemable_units
          }
        })), x.amount && Mt(x.amount, k.switch_out_unit_multiples) !== 0 && (h.amount = t._({
          id: "HKO+wY",
          values: {
            0: u.switch_out_unit_multiples
          }
        })), h;
      },
      children: (x) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: x.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "6ssn5V"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "unit",
                children: (h) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Be, {
                      id: h.input.name,
                      autoComplete: "off",
                      type: "number",
                      step: "any",
                      name: h.input.name,
                      value: P ? "" : h.input.value,
                      onChange: (L) => $(L, h),
                      placeholder: "Enter units",
                      disabled: P,
                      status: h.meta.error && h.meta.touched ? "error" : void 0,
                      autoFocus: !0
                    }),
                    h.meta.error && h.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: h.meta.error
                      })
                    }) : u && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "vypU3+",
                        values: {
                          0: a && a[u.key].holdings.redeemable_units
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(st, {
            id: "switch-all-id",
            checked: P,
            onChange: (h) => {
              h.stopPropagation(), s(!P);
            },
            children: t._({
              id: "dNBP2o"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(fe, {
              type: "submit",
              variant: (!u || a && a[u.key].holdings.redeemable_units <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "3+3T+B"
              })
            })
          })
        ]
      })
    });
  }
  function N() {
    switch (B) {
      case "initial":
        return re();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "QLKKZs"
          }),
          description: t._({
            id: "fYltlZ"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: "Retry",
          actionHandler: (x) => S("initial")
        });
      default:
        return re();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: j ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: N()
    })
  });
}
function ya({ scheme: a, switch_out: c, investment_account_id: k, investment_accounts: u, onSuccess: d, investorProfile: m, user: j }) {
  const [te, B] = y(!1), [S, P] = y([]), [s, H] = y(!1), [z, G] = y("initial"), [xe, U] = y(""), [V, ee] = y(""), [w, $] = y(null), [re, N] = y([]), { fpapi: x } = ge(Le);
  Se(() => {
    async function C() {
      B(!0);
      try {
        const n = (await x.fpClient.master_data().fetchAmcs()).amcs.find((D) => D.amc_id === a.amc_id), g = await x.fpClient.mf_folios().fetchAll({
          mf_investment_account: k
        }), R = [];
        g.data.forEach((D) => {
          D.amc === n.amc_code && R.push(D.number);
        });
        const _e = await x.fpClient.investor_reports().fetchHoldings({
          investment_account_id: u[0].old_id
        }), J = {}, W = [];
        if (_e.folios.forEach((D) => {
          R.includes(D.folio_number) && D.schemes.forEach((ae) => {
            ae.isin !== a.isin && (W.push(ae.isin), J[D.folio_number + "-" + ae.isin] = {
              ...ae,
              folio_number: D.folio_number
            });
          });
        }), Y(J)) {
          B(!1), G("no_holdings");
          return;
        }
        let me = !1;
        if (Object.values(J).forEach((D) => {
          D.isin === c && (me = !0);
        }), me) {
          const D = await x.fpClient.master_data().fetchScheme(Object.values(J)[0].isin);
          $({
            ...D,
            key: Object.keys(J)[0]
          });
        }
        N(W), P(J), B(!1);
      } catch (f) {
        B(!1), U(t._({
          id: "Gojdwx"
        })), ee(t._({
          id: "rAP48C"
        })), G("error"), ue(f);
      }
    }
    C();
  }, [
    x,
    k,
    u,
    a,
    c
  ]);
  const h = () => /* @__PURE__ */ e.jsx(pa, {
    scheme: a,
    investment_account_id: k,
    holdings: S,
    selectedSwitchOutScheme: w,
    onSuccess: d,
    user: j
  }), L = () => /* @__PURE__ */ e.jsx(xa, {
    scheme: a,
    investment_account_id: k,
    holdings: S,
    selectedSwitchOutScheme: w,
    onSuccess: d,
    user: j
  });
  async function v(C, f) {
    B(!0);
    try {
      const n = await x.fpClient.master_data().fetchScheme(C);
      $({
        ...n,
        key: f.number + "-" + C
      }), B(!1);
    } catch (n) {
      B(!1), U(t._({
        id: "Gojdwx"
      })), ee(t._({
        id: "rAP48C"
      })), G("error"), ue(n);
    }
  }
  function O() {
    const f = window.location.href.replace("withdraw", "checkout");
    window.location.href = f;
  }
  function q() {
    return S && /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ne, {
          message: t._({
            id: "6Xqu8f"
          })
        }),
        w ? /* @__PURE__ */ e.jsx(yt, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "Rtowvt"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: S && w ? S[w.key].folio_number : t._({
                        id: "OM/tRr"
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(fe, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (C) => {
                    C.preventDefault(), H(!s);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-full pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: t._({
                    id: "XTJZw8"
                  }),
                  value: S && w ? Ut(S[w.key].name) : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: t._({
                    id: "S0BC0N"
                  }),
                  value: S && w ? `₹ ${Q(S[w.key].market_value.amount)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: t._({
                    id: "jlk/lh"
                  }),
                  value: S && w ? S[w.key].holdings.units : "-"
                })
              })
            ]
          })
        }) : /* @__PURE__ */ e.jsx($e, {
          type: "borderedNotes",
          text: t._({
            id: "dQnZG2"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(fe, {
            variant: "text",
            onClick: (C) => {
              C.preventDefault(), H(!s);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        /* @__PURE__ */ e.jsx(Ze, {
          group: [
            {
              title: t._({
                id: "Op9PiZ"
              }),
              content: h()
            },
            {
              title: t._({
                id: "q7FP8z"
              }),
              content: L()
            }
          ],
          selected: 0
        }),
        m && /* @__PURE__ */ e.jsx(Te, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "2k3iZo"
          }),
          isOpen: s,
          toggleModal: H,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(Bt, {
            investorProfile: m,
            schemes: re,
            onSchemeAndFolioSelection: (C, f) => {
              H(!s), v(C, f);
            }
          })
        })
      ]
    });
  }
  function le() {
    switch (z) {
      case "initial":
        return q();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: xe,
          description: V
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "QbrtNj"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: "Invest now",
          actionHandler: O
        });
      default:
        return q();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: te ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: le()
    })
  });
}
function ha({ selectedSwitchOutScheme: a, scheme: c, investment_account_id: k, holdings: u, investorProfile: d, onSuccess: m, isPartner: j, user: te }) {
  const { tenantConfig: B } = Ye(), S = /* @__PURE__ */ new Date(), P = Pt(c == null ? void 0 : c.stp_frequency_specific_data), [s, H] = y(P[0].value), [z, G] = y("initial"), [xe, U] = y(!1), { fpapi: V } = ge(Le), { loggedInPartner: ee, nonPartnerDashboardUser: w } = ge(ut), { isMigratedUser: $ } = We({
    user: te
  }), [re, N] = y(!1), x = (f) => {
    var n, g;
    if ($) {
      const R = (n = u == null ? void 0 : u.folios) == null ? void 0 : n.filter((J) => J.folio_number === f), _e = R != null && R.length ? (g = R[0].schemes) == null ? void 0 : g.find((J) => J.isin === c.isin) : [];
      Y(_e) ? N(!0) : N(!1);
    }
  };
  Se(() => {
    const f = a ? u[a == null ? void 0 : a.key].folio_number : "";
    x(f);
  }, [
    a,
    $
  ]);
  const h = at(() => {
    var f;
    return {
      frequency: P[0],
      stp_installment_type: "perpetual",
      stp_start_date: new Date((/* @__PURE__ */ new Date()).setDate(S.getDate() + 1)),
      stp_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(S.getDate() + (((f = B == null ? void 0 : B.app_constants) == null ? void 0 : f.plan_max_start_days) || 0))),
      stp_end_date: s === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(S.getDate() + 2)) : new Date(S.getFullYear(), S.getMonth() + ze[s] * (c == null ? void 0 : c.stp_frequency_specific_data[s].min_installments), S.getDate() + 1),
      stp_end_max_date: Et(s)
    };
  }, [
    s
  ]);
  async function L({ amount: f, frequency: n, stp_start_date: g, stp_instl_type: R, installments: _e, stp_end_date: J }) {
    R === "perpetual" ? _e = 1200 : R === "fixed" && (_e = ot(g, J, n.value, c == null ? void 0 : c.stp_frequency_specific_data[n.value].dates));
    let W = xt(g, n.value);
    W !== null && (W = Pe(W)), await V.fetchEmailAndPhone(d);
    const me = {
      mf_investment_account: k,
      folio_number: u[a == null ? void 0 : a.key].folio_number,
      amount: +f,
      switch_in_scheme: c.isin,
      activate_after: W,
      switch_out_scheme: a == null ? void 0 : a.isin,
      frequency: n.value,
      systematic: !0,
      installment_day: n.value === "daily" ? null : Lt(g, n.value),
      number_of_installments: +_e,
      order_gateway: "rta"
    };
    if (j && ee) {
      U(!0);
      let D = {
        ...me
      };
      D = {
        ...D
      }, w || (D.partner = ee);
      try {
        const ae = await V.fpClient.mf_switch_plans().create(D);
        U(!1), m({
          mf_order: ae,
          shouldOnboardMigratedUser: re
        });
      } catch (ae) {
        window.debug.error(ae), U(!1), G("error"), ue(ae);
      }
    } else {
      U(!0);
      try {
        const D = await V.fpClient.mf_switch_plans().create(me);
        m({
          mf_order: D,
          shouldOnboardMigratedUser: re
        }), U(!1), m({
          mf_order: D,
          shouldOnboardMigratedUser: re
        });
      } catch (D) {
        window.debug.error(D), U(!1), G("error"), U(!1), ue(D);
      }
    }
  }
  const v = ([f, n], g, { changeValue: R }) => {
    n[0] = n[0] === null ? h.stp_start_date : n[0], n[0] = lt(n[0], n[1], c.stp_frequency_specific_data[n[1]].min_installments), R(g, f, () => n[0]);
  }, O = ([f, n], g, { changeValue: R }) => {
    n[0] !== null && (n[0] = It(n[1], c == null ? void 0 : c.stp_frequency_specific_data[n[1]].dates)), R(g, f, () => n[0]);
  }, q = (f, n) => ot(f || h.stp_start_date, n, s, c == null ? void 0 : c.stp_frequency_specific_data[s].dates) <= c.stp_frequency_specific_data[s].min_installments;
  function le() {
    return /* @__PURE__ */ e.jsx(Oe, {
      onSubmit: L,
      mutators: {
        updateStpEndDate_mutator: v,
        updateStpStartDate_mutator: O
      },
      validate: (f) => {
        var g;
        const n = {};
        return rt(f.amount, (g = c == null ? void 0 : c.stp_frequency_specific_data[s]) == null ? void 0 : g.amount_multiples) === !1 && (n.amount = t._({
          id: "EjZXF8",
          values: {
            0: c == null ? void 0 : c.stp_frequency_specific_data[s].amount_multiples
          }
        })), f.amount && c && f.amount < c.stp_frequency_specific_data[s].min_installment_amount && (n.amount = t._({
          id: "IIlbKG",
          values: {
            0: Q(Number(c.stp_frequency_specific_data[s].min_installment_amount))
          }
        })), f.amount && f.amount > c.stp_frequency_specific_data[s].max_installment_amount && (n.amount = t._({
          id: "DoPb3h",
          values: {
            0: Q(Number(c.stp_frequency_specific_data[s].max_installment_amount))
          }
        })), f.installments < (c == null ? void 0 : c.stp_frequency_specific_data[s].min_installments) && (n.installments = t._({
          id: "MpguFb",
          values: {
            0: c == null ? void 0 : c.stp_frequency_specific_data[s].min_installments
          }
        })), f.installments > 1200 && (n.installments = t._({
          id: "j7AzBN"
        })), f.stp_end_date < f.stp_start_date && (n.stp_end_date = t._({
          id: "T31Ev8"
        })), new Date(f.stp_end_date).setHours(0, 0, 0, 0) < new Date(h.stp_end_date).setHours(0, 0, 0, 0) && (n.stp_end_date = t._({
          id: "limWat",
          values: {
            0: Pe(new Date(h.stp_end_date), "dd-MMM-yyyy")
          }
        })), n;
      },
      subscription: {
        submitting: !0,
        values: !0
      },
      children: (f) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: f.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "zUtHgw"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "frequency",
                initialValue: h.frequency,
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: Ce(Me),
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(At, {
                      name: n.input.name,
                      value: n.input.value,
                      onChange: (g) => {
                        const R = [
                          f.values.stp_start_date,
                          g.value,
                          f.values.stp_end_date
                        ];
                        f.form.mutators.updateStpStartDate_mutator("stp_start_date", R), f.form.mutators.updateStpEndDate_mutator("stp_end_date", R), H(g.value), n.input.onChange(g);
                      },
                      options: P
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(Z, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    }),
                    n.input.value.value === "daily" && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "oAMyYj"
                      }),
                      customClass: "sm"
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "/DJBcB"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "amount",
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: Ce(Me),
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Be, {
                      id: n.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: n.input.name,
                      value: dt(n.input.value),
                      onChange: (g) => n.input.onChange(ct(g.target.value)),
                      onInput: (g) => {
                        g.target.value = g.target.value.replace(Qe("0-9"), "");
                      },
                      placeholder: t._({
                        id: "qUFsyJ"
                      }),
                      prefix: "₹",
                      status: n.meta.error && n.meta.touched ? "error" : void 0,
                      autoFocus: !0
                    }),
                    n.meta.error && n.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    }) : a && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "JfMq/g",
                        values: {
                          0: Q(Number(c.stp_frequency_specific_data[s].min_installment_amount), 2)
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "jvN2aB"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "stp_start_date",
                initialValue: null,
                validate: Ce(Me),
                allowNull: !0,
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(nt, {
                      variant: "keyboardInputDOB",
                      onKeyDown: (g) => g.preventDefault(),
                      disableFuture: !1,
                      name: n.input.name,
                      value: n.input.value,
                      format: "dd-MMM-yyyy",
                      minDate: h.stp_start_date,
                      maxDate: h.stp_start_max_date,
                      selectedDate: n.input.value || null,
                      error: n.meta.error && n.meta.touched,
                      views: [
                        "date"
                      ],
                      placeholder: "Select start date",
                      labelFunc: (g) => Tt(g, s),
                      shouldDisableDate: (g) => Je(g, c == null ? void 0 : c.stp_frequency_specific_data[s].dates, s),
                      handleDateChange: (g) => {
                        q(g, f.values.stp_end_date || h.stp_end_date) && f.form.mutators.updateStpEndDate_mutator("stp_end_date", [
                          g,
                          s,
                          f.values.stp_end_date
                        ]), n.input.onChange(g);
                      }
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(Z, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    }),
                    n.input.value && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "jI3CM4",
                        values: {
                          0: Pe(n.input.value, "dd-MMM-yyyy")
                        }
                      }),
                      customClass: "sm"
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "G7dHtg"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                type: "radio",
                name: "stp_instl_type",
                initialValue: h.stp_installment_type,
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: Ce(Me),
                children: (n) => /* @__PURE__ */ e.jsx(e.Fragment, {
                  children: /* @__PURE__ */ e.jsx(it, {
                    name: n.input.name,
                    value: n.input.value,
                    group: [
                      {
                        label: t._({
                          id: "4JqC6N"
                        }),
                        value: "perpetual"
                      },
                      {
                        label: t._({
                          id: "/VDmvv"
                        }),
                        value: "installments"
                      },
                      {
                        label: t._({
                          id: "IUSFui"
                        }),
                        value: "fixed"
                      }
                    ],
                    size: "sm",
                    onChange: (g, R) => {
                      n.input.onChange(R.value);
                    }
                  })
                })
              })
            ]
          }),
          f.values.stp_instl_type === "installments" && /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "ZS3Gyi"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "installments",
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: Ce(Me),
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Be, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      onChange: n.input.onChange,
                      placeholder: "Enter number of installments",
                      status: n.meta.error && n.meta.touched ? "error" : void 0,
                      onKeyPress: (g) => {
                        da(g.key) || g.preventDefault();
                      },
                      inputMode: "numeric",
                      autoFocus: !0
                    }),
                    n.meta.error && n.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    }) : a && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "4xlwZY",
                        values: {
                          0: c == null ? void 0 : c.stp_frequency_specific_data[s].min_installments
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          f.values.stp_instl_type === "fixed" && /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                message: t._({
                  id: "BrsP9r"
                })
              }),
              /* @__PURE__ */ e.jsx(he, {
                name: "stp_end_date",
                initialValue: null,
                allowNull: !0,
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(nt, {
                      variant: "keyboardInputDOB",
                      onKeyDown: (g) => g.preventDefault(),
                      disableFuture: !1,
                      name: n.input.name,
                      value: n.input.value,
                      format: "dd-MMM-yyyy",
                      minDate: lt(f.values.stp_start_date, s, c == null ? void 0 : c.stp_frequency_specific_data[s].min_installments),
                      maxDate: h.stp_end_max_date,
                      selectedDate: n.input.value,
                      labelFunc: (g) => g ? Pe(g, "dd-MMM-yyyy") : "Select end date",
                      error: n.meta.error && n.meta.touched,
                      views: [
                        "date"
                      ],
                      handleDateChange: (g) => {
                        n.input.onChange(g);
                      }
                    }),
                    n.meta.error && n.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    }) : a && /* @__PURE__ */ e.jsx(Z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "VCqIPq",
                        values: {
                          0: c == null ? void 0 : c.stp_frequency_specific_data[s].min_installments
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(fe, {
              type: "submit",
              variant: a ? "primary" : "disabled",
              fullwidth: !0,
              children: t._({
                id: "3+3T+B"
              })
            })
          })
        ]
      })
    });
  }
  function C() {
    switch (z) {
      case "initial":
        return le();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "SOfRak"
          }),
          description: t._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "wy5cp6"
          }),
          actionHandler: (f) => {
            H(P[0].value), G("initial");
          }
        });
      default:
        return le();
    }
  }
  return xe ? /* @__PURE__ */ e.jsx(qe, {
    variant: "fullpage"
  }) : C();
}
function ga({ scheme: a, switch_out: c, investment_account_id: k, investment_accounts: u, onSuccess: d, investorProfile: m, isPartner: j, user: te }) {
  const [B, S] = y(!1), [P, s] = y(!1), [H, z] = y([]), [G, xe] = y("initial"), [U, V] = y(""), [ee, w] = y(""), [$, re] = y(null), [N, x] = y([]), { fpapi: h } = ge(Le);
  Se(() => {
    async function C() {
      S(!0);
      try {
        const n = (await h.fpClient.master_data().fetchAmcs()).amcs.find((D) => D.amc_id === a.amc_id), g = await h.fpClient.mf_folios().fetchAll({
          mf_investment_account: k
        }), R = [];
        g.data.forEach((D) => {
          D.amc === n.amc_code && R.push(D.number);
        });
        const _e = await h.fpClient.investor_reports().fetchHoldings({
          investment_account_id: u[0].old_id
        }), J = {}, W = [];
        if (_e.folios.forEach((D) => {
          R.includes(D.folio_number) && D.schemes.forEach((ae) => {
            ae.isin !== a.isin && (W.push(ae.isin), J[D.folio_number + "-" + ae.isin] = {
              ...ae,
              folio_number: D.folio_number
            });
          });
        }), Y(J)) {
          S(!1), xe("no_holdings");
          return;
        }
        let me = !1;
        if (Object.values(J).forEach((D) => {
          D.isin === c && (me = !0);
        }), me) {
          const D = await h.fpClient.master_data().fetchScheme(Object.values(J)[0].isin);
          re({
            ...D,
            key: Object.keys(J)[0]
          });
        }
        x(W), z(J), S(!1);
      } catch (f) {
        S(!1), V(t._({
          id: "Gojdwx"
        })), w(t._({
          id: "rAP48C"
        })), xe("error"), ue(f);
      }
    }
    C();
  }, [
    h,
    k,
    u,
    a,
    c
  ]);
  function L() {
    const f = window.location.href.replace("withdraw", "checkout");
    window.location.href = f;
  }
  const v = () => /* @__PURE__ */ e.jsx(ha, {
    scheme: a,
    investment_account_id: k,
    holdings: H,
    selectedSwitchOutScheme: $,
    onSuccess: d,
    investorProfile: m,
    isPartner: j,
    user: te
  });
  function O() {
    return H && /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ne, {
          message: t._({
            id: "6Xqu8f"
          })
        }),
        $ ? /* @__PURE__ */ e.jsx(yt, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "OW2zXN"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: H && $ ? H[$.key].folio_number : "Please choose a scheme and folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(fe, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (C) => {
                    C.preventDefault(), s(!P);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-full pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: t._({
                    id: "XTJZw8"
                  }),
                  value: H && $ ? Ut(H[$.key].name) : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: t._({
                    id: "S0BC0N"
                  }),
                  value: H && $ ? `₹ ${Q(H[$.key].market_value.amount)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(ke, {
                  label: t._({
                    id: "jlk/lh"
                  }),
                  value: H && $ ? H[$.key].holdings.units : "-"
                })
              })
            ]
          })
        }) : /* @__PURE__ */ e.jsx($e, {
          type: "borderedNotes",
          text: t._({
            id: "dQnZG2"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(fe, {
            variant: "text",
            onClick: (C) => {
              C.preventDefault(), s(!P);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        /* @__PURE__ */ e.jsx(Ze, {
          group: [
            {
              title: t._({
                id: "ijXM8H"
              }),
              content: v()
            }
          ],
          selected: 0
        }),
        m && /* @__PURE__ */ e.jsx(Te, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "2k3iZo"
          }),
          isOpen: P,
          toggleModal: s,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(Bt, {
            investorProfile: m,
            schemes: N,
            onSchemeAndFolioSelection: (C, f) => {
              s(!P), q(C, f);
            }
          })
        })
      ]
    });
  }
  async function q(C, f) {
    S(!0);
    try {
      const n = await h.fpClient.master_data().fetchScheme(C);
      re({
        ...n,
        key: f.number + "-" + C
      }), S(!1);
    } catch (n) {
      S(!1), V(t._({
        id: "Gojdwx"
      })), w(t._({
        id: "rAP48C"
      })), xe("error"), ue(n);
    }
  }
  function le() {
    switch (G) {
      case "initial":
        return O();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: U,
          description: ee
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "QbrtNj"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "wBAu+p"
          }),
          actionHandler: L
        });
      default:
        return O();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: B ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: le()
    })
  });
}
function ja(a) {
  const c = () => {
    const u = () => /* @__PURE__ */ e.jsx(_a, {
      ...a
    }), d = () => /* @__PURE__ */ e.jsx(ma, {
      ...a
    }), m = () => /* @__PURE__ */ e.jsx(se, {
      title: t._({
        id: "JHDCYv"
      }),
      description: t._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), j = () => /* @__PURE__ */ e.jsx(se, {
      title: t._({
        id: "Rds/a6"
      }),
      description: t._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    });
    return /* @__PURE__ */ e.jsx(Ze, {
      variant: "pills",
      selected: a.scheme.purchase_allowed ? 0 : 1,
      group: [
        {
          title: t._({
            id: "DUs4hi"
          }),
          content: a.scheme.purchase_allowed ? u() : m()
        },
        {
          title: t._({
            id: "v7QEHl"
          }),
          content: a.scheme.sip_allowed ? d() : j()
        }
      ]
    });
  }, k = () => {
    const u = () => /* @__PURE__ */ e.jsx(ya, {
      ...a
    }), d = () => /* @__PURE__ */ e.jsx(ga, {
      ...a
    }), m = () => /* @__PURE__ */ e.jsx(se, {
      title: t._({
        id: "CGb61H"
      }),
      description: t._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    });
    return /* @__PURE__ */ e.jsx(Ze, {
      variant: "pills",
      selected: 0,
      group: [
        {
          title: t._({
            id: "DUs4hi"
          }),
          content: a.scheme.switch_in_allowed ? u() : m()
        },
        {
          title: t._({
            id: "v7QEHl"
          }),
          content: a.scheme.switch_in_allowed ? d() : m()
        }
      ]
    });
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Ze, {
      selected: a.isGroupInvestment || a.defaultInvestmentType === "purchase" ? 0 : 1,
      group: a.isGroupInvestment ? [
        {
          title: t._({
            id: "dmetZq"
          }),
          content: c()
        }
      ] : [
        {
          title: t._({
            id: "dmetZq"
          }),
          content: c()
        },
        {
          title: t._({
            id: "+Q/+Mn"
          }),
          content: k()
        }
      ]
    })
  });
}
function Xa({ scheme: a, switch_out: c, isGroupInvestment: k }) {
  var we, M, E;
  const [u, d] = y("order"), [m, j] = y(null), [te, B] = y(null), [S, P] = y(null), [s, H] = y(null), [z, G] = y(null), [xe, U] = y(!1), [V, ee] = y("purchase"), [w, $] = y(null), [re, N] = y(!1), [x, h] = y(null), { AppState: { employer: L, tenant: v } } = ge(jt), { analytics: O } = gt(), { fpapi: q } = ge(Le), { loggedInUser: le } = ge(ut), { tenantConfig: C } = Ye(), f = (we = C == null ? void 0 : C.groupInvestment) == null ? void 0 : we.allowedEmailDomains, n = ((M = C == null ? void 0 : C.app_constants) == null ? void 0 : M.kyc_refetch_interval_in_months) || 3, g = a != null && a.purchase_allowed ? ((E = C == null ? void 0 : C.app_constants) == null ? void 0 : E.plans_mode) || "" : "systematic";
  Se(() => {
    me();
  }, [
    C,
    le,
    a
  ]), Vt(() => {
    const o = sessionStorage.getItem("product"), r = a == null ? void 0 : a.isin;
    r && (sessionStorage.setItem("product", r), o !== r && K());
  }, []);
  const R = (o) => Y(o) ? {
    success: !1,
    stageToRender: "scheme_not_found"
  } : !(o != null && o.active) || !(o != null && o.purchase_allowed) && !(o != null && o.sip_allowed) ? {
    success: !1,
    stageToRender: "purchase_not_allowed"
  } : {
    success: !0
  }, _e = ({ tenantConfig: o, user: r, investorProfile: p }) => {
    var A;
    return (f == null ? void 0 : f[(A = r == null ? void 0 : r.email_address) == null ? void 0 : A.split("@")[1]]) ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "invalid_email_domain"
    };
  }, J = ({ kycCheckObj: o, user: r, mfia: p }) => {
    var oe, de;
    const F = ((oe = r == null ? void 0 : r.annexure) == null ? void 0 : oe.isonboarded) === "true";
    return !F || !(p != null && p.primary_investor) ? {
      success: !1,
      stageToRender: "onboard"
    } : F && !o.status ? {
      success: !1,
      stageToRender: "kyc_non_compliant"
    } : (de = p == null ? void 0 : p.folio_defaults) != null && de.payout_bank_account ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "bank_verification"
    };
  }, W = async (o) => {
    try {
      const r = await q.fpClient.investor_reports().fetchHoldings({
        investment_account_id: o == null ? void 0 : o.old_id
      });
      h(r);
    } catch (r) {
      window.debug.log(r, "Error");
    }
  }, me = async () => {
    var o, r, p;
    try {
      if (U(!0), !le) {
        d("auth");
        return;
      }
      const F = R(a);
      if (!F.success) {
        d(F.stageToRender);
        return;
      }
      const A = [
        q.fetchUser(le.profile.preferred_username, v),
        q.getInvestorProfile(),
        q.fpClient.mf_investment_accounts().fetchAll({
          investor: (r = (o = le == null ? void 0 : le.profile) == null ? void 0 : o.preferred_username) == null ? void 0 : r.toUpperCase()
        })
      ], [X, oe, de] = await Promise.all(A);
      if (!oe) {
        window.debug.error("Investor profile is not present"), d("error_page");
        return;
      }
      const De = X == null ? void 0 : X[0], Xe = (p = de == null ? void 0 : de.data) == null ? void 0 : p[0];
      if (k) {
        const He = _e({
          tenantConfig: C,
          user: De,
          investorProfile: oe
        });
        if (!He.success) {
          d(He.stageToRender);
          return;
        }
      }
      const [Ue, { isMigratedUser: ft, migratedMfiaList: _t, migratedFolioList: mt }] = await Promise.all([
        Ie(De),
        Gt(De, q)
      ]);
      if (ft)
        W(Xe), await ea(q, De, _t, mt), Ue.status ? d("order") : d("onboard");
      else {
        const He = J({
          kycCheckObj: Ue,
          user: De,
          mfia: Xe
        });
        He.success ? d("order") : d(He.stageToRender);
      }
      B(De), H(oe), G(de == null ? void 0 : de.data), $(Ue), O.identify(oe == null ? void 0 : oe.id);
    } catch (F) {
      d("error_page"), window.debug.error(F), ue(F);
    } finally {
      U(!1);
    }
  };
  async function D(o, r) {
    if (Y(r)) {
      d("finished"), j(o);
      return;
    }
    U(!0);
    try {
      const p = await q.fpClient.mf_purchases().fetch(r == null ? void 0 : r.id);
      j(p);
    } catch (p) {
      window.debug.log(p), ue(p);
    }
    d("payment"), U(!1);
  }
  const ae = (o) => {
    Y(o) || (k ? d("finished") : (m == null ? void 0 : m.object) === "mf_purchase" && (j(m), d("payment")));
  }, ye = async (o, r) => {
    if (o) {
      if (k)
        d("finished");
      else if (m.object === "mf_purchase_plan") {
        if (!Y(r) && g === "nonSystematic") {
          const { emailData: p, phoneData: F } = await q.fetchEmailAndPhone(s);
          await q.fpClient.mf_purchases().update({
            id: r == null ? void 0 : r.id,
            consent: {
              email: p.email,
              isd_code: F.isd,
              mobile: F.number
            }
          });
        }
        D(m, r);
      }
    }
  };
  function Fe(o) {
    const { mf_order: r, isGenerateFirstInstallment: p, shouldOnboardMigratedUser: F } = o;
    if (F) {
      j(r), d("onboard_migrated_user");
      return;
    }
    if ((r == null ? void 0 : r.object) === "mf_purchase") {
      j(r), d("purchase_confirmation");
      return;
    }
    if ((r == null ? void 0 : r.object) === "mf_purchase_plan") {
      j(r), N(p), d("purchase_plan_confirmation");
      return;
    }
    (r == null ? void 0 : r.object) === "mf_switch" && (j(r), d("switch_confirmation")), (r == null ? void 0 : r.object) === "mf_switch_plan" && (j(r), d("switch_plan_confirmation"));
  }
  function K() {
    m && (m.object === "mf_purchase" || m.object === "mf_purchase_plan" ? ee("purchase") : ee("switch")), j({}), d("order");
  }
  function be() {
    k ? d("order") : m.object === "mf_purchase" && q.fpClient.mf_purchases().retry(m == null ? void 0 : m.id).then(() => {
      window.debug.log("Order Retry successful"), d("payment");
    }).catch((o) => {
      K(), ue(o);
    });
  }
  function Re({ success: o, isWindowBlocked: r, paymentId: p }) {
    (async () => {
      if (p)
        try {
          const F = await q.fpClient.payments().fetch(Number(p));
          P(F);
          let A;
          m.plan ? A = await q.fpClient.mf_purchase_plans().fetch(m == null ? void 0 : m.plan) : A = await q.fpClient.mf_purchases().fetch(m.id), j(A);
        } catch (F) {
          window.debug.log(F), ue(F);
        }
      d(o && !r || !o && !r ? "finished" : "finished_error");
    })();
  }
  const Ae = async (o, r, p) => {
    try {
      U(!0);
      const F = await Ie(p), A = J({
        kycCheckObj: F,
        user: p,
        mfia: r == null ? void 0 : r[0]
      });
      A.success ? d("order") : d(A.stageToRender), B(p), $(F), H(o), G(r);
    } catch (F) {
      window.debug.error(F), ue(F), d("error_page");
    } finally {
      U(!1);
    }
  }, T = (o) => {
    j(o), d("finished");
  }, je = () => {
    var o;
    (o = q == null ? void 0 : q.userAuthService) == null || o.initiateLogout(window.location.href).then((r) => {
      window.location.href = r.userState;
    });
  }, ve = async (o) => {
    var r;
    if (z != null && z.length)
      try {
        const p = (r = z == null ? void 0 : z[0]) == null ? void 0 : r.id, F = await q.fpClient.mf_investment_accounts().update({
          id: p,
          folio_defaults: {
            payout_bank_account: o == null ? void 0 : o.id
          }
        }), A = z.map((X) => {
          if (X.id === F.id) {
            const oe = X;
            return oe.folio_defaults.payout_bank_account = F.folio_defaults.payout_bank_account, oe;
          } else
            return X;
        });
        G(A), d("order");
      } catch (p) {
        window.debug.error(p);
      }
  }, Ie = async (o) => {
    const r = Qt(o == null ? void 0 : o.annexure);
    if (r != null && r.kycCheckId) {
      const p = await q.fpClient.kyc_checks().fetch(r == null ? void 0 : r.kycCheckId);
      return p.status && !Ot(p.updated_at, n) ? p : Wt(p.updated_at) ? await q.fpClient.kyc_checks().refetch(r == null ? void 0 : r.kycCheckId) : p;
    } else {
      const p = o == null ? void 0 : o.id, F = await q.fpClient.kyc_checks().create({
        pan: o == null ? void 0 : o.username.toUpperCase()
      }), A = {
        ...r,
        kycCheckId: F.id
      }, X = await q.updateUser(p, A, null, v);
      if (X)
        return F;
      throw X;
    }
  };
  function b() {
    switch (u) {
      default:
        return /* @__PURE__ */ e.jsx(qe, {
          variant: "fullpage"
        });
      case "auth":
        return /* @__PURE__ */ e.jsx(Xt, {
          redirectURL: window.location.href,
          allowRegistration: !0,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: k
        });
      case "onboard":
        return /* @__PURE__ */ e.jsx(fa, {
          user: te,
          isGroupInvestment: k || !1,
          setUser: B,
          onBoardingComplete: Ae,
          isPartner: !1
        });
      case "bank_verification":
        return /* @__PURE__ */ e.jsx(ua, {
          investorProfile: s,
          handleBankSelection: ve,
          customPageSubtitle: t._({
            id: "KM15wf"
          })
        });
      case "order":
        return ht(z) ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx(ja, {
          scheme: a,
          switch_out: c,
          investment_account_id: z[0].id,
          investment_accounts: z,
          defaultInvestmentType: V,
          investorProfile: s,
          isGroupInvestment: k,
          onSuccess: Fe,
          user: te,
          holdings: x
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(ba, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Nt}/onboarding?${k ? `isGroupInvestment=${k}` : ""}${k ? `&employer=${L == null ? void 0 : L.id}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + Nt}/${k ? "group-checkout" : "checkout"}/?isin=${a.isin}${k ? `&employer=${L == null ? void 0 : L.id}` : ""}`)}`;
          }
        });
      case "purchase_confirmation":
        return !Y(s) && !Y(m) ? /* @__PURE__ */ e.jsx(la, {
          investorProfile: s,
          id: m.id,
          showSchemeDetails: !1,
          isGroupInvestment: k,
          onSuccess: ae,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "purchase_plan_confirmation":
        return !Y(s) && !Y(m) ? /* @__PURE__ */ e.jsx(ra, {
          investorProfile: s,
          id: m.id,
          showSchemeDetails: !1,
          isGroupInvestment: k,
          onSuccess: (o, r) => ye(o, r),
          isFirstInstallmentPayment: re,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "payment":
        return !Y(s) && !Y(m) ? /* @__PURE__ */ e.jsx(sa, {
          investorProfile: s,
          mf_purchase: m,
          onCompletion: Re
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished":
        return !Y(a) && !Y(m) ? /* @__PURE__ */ e.jsx(na, {
          investorProfile: s,
          scheme: a,
          mf_order: m,
          payment: S,
          isGroupInvestment: k,
          handleRetry: be,
          handleInvestAgain: K
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished_error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "sS+OUW"
          }),
          showAction: !0,
          actionText: t._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            d("payment");
          }
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "YzYf5v"
          }),
          description: t._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "+PjKpk"
          }),
          description: t._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "switch_confirmation":
        return !Y(a) && !Y(m) ? /* @__PURE__ */ e.jsx(ia, {
          id: m == null ? void 0 : m.id,
          investorProfile: s,
          showSchemeDetails: !1,
          onSuccess: T,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "switch_plan_confirmation":
        return !Y(a) && !Y(m) ? /* @__PURE__ */ e.jsx(aa, {
          id: m.id,
          investorProfile: s,
          onSuccess: T,
          showSchemeDetails: !1,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "invalid_email_domain":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "ZXjii8"
          }),
          description: t._({
            id: "mpazQQ"
          }),
          showDetails: !1,
          actionText: t._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: je
        });
      case "invalid_employer":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "8gLo60"
          }),
          description: t._({
            id: "REewWf"
          }),
          showDetails: !1,
          actionText: t._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: je
        });
      case "kyc_non_compliant":
        return w ? /* @__PURE__ */ e.jsx(ta, {
          kycCheckObject: w,
          customMessage: {
            unverified: t._({
              id: "V2UMyt"
            })
          }
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "error_page":
        return /* @__PURE__ */ e.jsx(se, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return xe ? /* @__PURE__ */ e.jsx(qe, {
    variant: "fullpage"
  }) : b();
}
const ba = ({ onContinue: a }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Yt, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(fe, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => a(),
        children: "Continue"
      })
    })
  ]
});
export {
  Xa as I,
  ja as O
};
