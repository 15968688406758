const ve = Object.prototype.toString;
function Ze(e) {
  switch (ve.call(e)) {
    case "[object Error]":
    case "[object Exception]":
    case "[object DOMException]":
      return !0;
    default:
      return H(e, Error);
  }
}
function A(e, t) {
  return ve.call(e) === `[object ${t}]`;
}
function En(e) {
  return A(e, "ErrorEvent");
}
function xn(e) {
  return A(e, "DOMError");
}
function Tn(e) {
  return A(e, "DOMException");
}
function G(e) {
  return A(e, "String");
}
function qe(e) {
  return typeof e == "object" && e !== null && "__sentry_template_string__" in e && "__sentry_template_values__" in e;
}
function Mn(e) {
  return e === null || qe(e) || typeof e != "object" && typeof e != "function";
}
function re(e) {
  return A(e, "Object");
}
function et(e) {
  return typeof Event < "u" && H(e, Event);
}
function tt(e) {
  return typeof Element < "u" && H(e, Element);
}
function nt(e) {
  return A(e, "RegExp");
}
function se(e) {
  return !!(e && e.then && typeof e.then == "function");
}
function rt(e) {
  return re(e) && "nativeEvent" in e && "preventDefault" in e && "stopPropagation" in e;
}
function st(e) {
  return typeof e == "number" && e !== e;
}
function H(e, t) {
  try {
    return e instanceof t;
  } catch {
    return !1;
  }
}
function Ne(e) {
  return !!(typeof e == "object" && e !== null && (e.__isVue || e._isVue));
}
function P(e, t = 0) {
  return typeof e != "string" || t === 0 || e.length <= t ? e : `${e.slice(0, t)}...`;
}
function In(e, t) {
  if (!Array.isArray(e))
    return "";
  const n = [];
  for (let r = 0; r < e.length; r++) {
    const s = e[r];
    try {
      Ne(s) ? n.push("[VueViewModel]") : n.push(String(s));
    } catch {
      n.push("[value cannot be serialized]");
    }
  }
  return n.join(t);
}
function it(e, t, n = !1) {
  return G(e) ? nt(t) ? t.test(e) : G(t) ? n ? e === t : e.includes(t) : !1 : !1;
}
function vn(e, t = [], n = !1) {
  return t.some((r) => it(e, r, n));
}
function $(e) {
  return e && e.Math == Math ? e : void 0;
}
const _ = typeof globalThis == "object" && $(globalThis) || // eslint-disable-next-line no-restricted-globals
typeof window == "object" && $(window) || typeof self == "object" && $(self) || typeof global == "object" && $(global) || /* @__PURE__ */ function() {
  return this;
}() || {};
function ot() {
  return _;
}
function Oe(e, t, n) {
  const r = n || _, s = r.__SENTRY__ = r.__SENTRY__ || {};
  return s[e] || (s[e] = t());
}
const C = ot(), at = 80;
function ct(e, t = {}) {
  if (!e)
    return "<unknown>";
  try {
    let n = e;
    const r = 5, s = [];
    let i = 0, o = 0;
    const a = " > ", c = a.length;
    let u;
    const l = Array.isArray(t) ? t : t.keyAttrs, f = !Array.isArray(t) && t.maxStringLength || at;
    for (; n && i++ < r && (u = ut(n, l), !(u === "html" || i > 1 && o + s.length * c + u.length >= f)); )
      s.push(u), o += u.length, n = n.parentNode;
    return s.reverse().join(a);
  } catch {
    return "<unknown>";
  }
}
function ut(e, t) {
  const n = e, r = [];
  let s, i, o, a, c;
  if (!n || !n.tagName)
    return "";
  if (C.HTMLElement && n instanceof HTMLElement && n.dataset && n.dataset.sentryComponent)
    return n.dataset.sentryComponent;
  r.push(n.tagName.toLowerCase());
  const u = t && t.length ? t.filter((f) => n.getAttribute(f)).map((f) => [f, n.getAttribute(f)]) : null;
  if (u && u.length)
    u.forEach((f) => {
      r.push(`[${f[0]}="${f[1]}"]`);
    });
  else if (n.id && r.push(`#${n.id}`), s = n.className, s && G(s))
    for (i = s.split(/\s+/), c = 0; c < i.length; c++)
      r.push(`.${i[c]}`);
  const l = ["aria-label", "type", "name", "title", "alt"];
  for (c = 0; c < l.length; c++)
    o = l[c], a = n.getAttribute(o), a && r.push(`[${o}="${a}"]`);
  return r.join("");
}
function Nn() {
  try {
    return C.document.location.href;
  } catch {
    return "";
  }
}
function On(e) {
  return C.document && C.document.querySelector ? C.document.querySelector(e) : null;
}
function Cn(e) {
  if (!C.HTMLElement)
    return null;
  let t = e;
  const n = 5;
  for (let r = 0; r < n; r++) {
    if (!t)
      return null;
    if (t instanceof HTMLElement && t.dataset.sentryComponent)
      return t.dataset.sentryComponent;
    t = t.parentNode;
  }
  return null;
}
const ie = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, lt = "Sentry Logger ", le = [
  "debug",
  "info",
  "warn",
  "error",
  "log",
  "assert",
  "trace"
], fe = {};
function Ce(e) {
  if (!("console" in _))
    return e();
  const t = _.console, n = {}, r = Object.keys(fe);
  r.forEach((s) => {
    const i = fe[s];
    n[s] = t[s], t[s] = i;
  });
  try {
    return e();
  } finally {
    r.forEach((s) => {
      t[s] = n[s];
    });
  }
}
function ft() {
  let e = !1;
  const t = {
    enable: () => {
      e = !0;
    },
    disable: () => {
      e = !1;
    },
    isEnabled: () => e
  };
  return ie ? le.forEach((n) => {
    t[n] = (...r) => {
      e && Ce(() => {
        _.console[n](`${lt}[${n}]:`, ...r);
      });
    };
  }) : le.forEach((n) => {
    t[n] = () => {
    };
  }), t;
}
const b = ft();
function An(e, t, n) {
  if (!(t in e))
    return;
  const r = e[t], s = n(r);
  typeof s == "function" && dt(s, r), e[t] = s;
}
function Ae(e, t, n) {
  try {
    Object.defineProperty(e, t, {
      // enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
      value: n,
      writable: !0,
      configurable: !0
    });
  } catch {
    ie && b.log(`Failed to add non-enumerable property "${t}" to object`, e);
  }
}
function dt(e, t) {
  try {
    const n = t.prototype || {};
    e.prototype = t.prototype = n, Ae(e, "__sentry_original__", t);
  } catch {
  }
}
function kn(e) {
  return e.__sentry_original__;
}
function Rn(e) {
  return Object.keys(e).map((t) => `${encodeURIComponent(t)}=${encodeURIComponent(e[t])}`).join("&");
}
function ke(e) {
  if (Ze(e))
    return {
      message: e.message,
      name: e.name,
      stack: e.stack,
      ...ge(e)
    };
  if (et(e)) {
    const t = {
      type: e.type,
      target: de(e.target),
      currentTarget: de(e.currentTarget),
      ...ge(e)
    };
    return typeof CustomEvent < "u" && H(e, CustomEvent) && (t.detail = e.detail), t;
  } else
    return e;
}
function de(e) {
  try {
    return tt(e) ? ct(e) : Object.prototype.toString.call(e);
  } catch {
    return "<unknown>";
  }
}
function ge(e) {
  if (typeof e == "object" && e !== null) {
    const t = {};
    for (const n in e)
      Object.prototype.hasOwnProperty.call(e, n) && (t[n] = e[n]);
    return t;
  } else
    return {};
}
function Pn(e, t = 40) {
  const n = Object.keys(ke(e));
  if (n.sort(), !n.length)
    return "[object has no keys]";
  if (n[0].length >= t)
    return P(n[0], t);
  for (let r = n.length; r > 0; r--) {
    const s = n.slice(0, r).join(", ");
    if (!(s.length > t))
      return r === n.length ? s : P(s, t);
  }
  return "";
}
function T(e) {
  return q(e, /* @__PURE__ */ new Map());
}
function q(e, t) {
  if (gt(e)) {
    const n = t.get(e);
    if (n !== void 0)
      return n;
    const r = {};
    t.set(e, r);
    for (const s of Object.keys(e))
      typeof e[s] < "u" && (r[s] = q(e[s], t));
    return r;
  }
  if (Array.isArray(e)) {
    const n = t.get(e);
    if (n !== void 0)
      return n;
    const r = [];
    return t.set(e, r), e.forEach((s) => {
      r.push(q(s, t));
    }), r;
  }
  return e;
}
function gt(e) {
  if (!re(e))
    return !1;
  try {
    const t = Object.getPrototypeOf(e).constructor.name;
    return !t || t === "Object";
  } catch {
    return !0;
  }
}
const Re = 50, pe = /\(error: (.*)\)/, he = /captureMessage|captureException/;
function pt(...e) {
  const t = e.sort((n, r) => n[0] - r[0]).map((n) => n[1]);
  return (n, r = 0) => {
    const s = [], i = n.split(`
`);
    for (let o = r; o < i.length; o++) {
      const a = i[o];
      if (a.length > 1024)
        continue;
      const c = pe.test(a) ? a.replace(pe, "$1") : a;
      if (!c.match(/\S*Error: /)) {
        for (const u of t) {
          const l = u(c);
          if (l) {
            s.push(l);
            break;
          }
        }
        if (s.length >= Re)
          break;
      }
    }
    return ht(s);
  };
}
function Fn(e) {
  return Array.isArray(e) ? pt(...e) : e;
}
function ht(e) {
  if (!e.length)
    return [];
  const t = Array.from(e);
  return /sentryWrapped/.test(t[t.length - 1].function || "") && t.pop(), t.reverse(), he.test(t[t.length - 1].function || "") && (t.pop(), he.test(t[t.length - 1].function || "") && t.pop()), t.slice(0, Re).map((n) => ({
    ...n,
    filename: n.filename || t[t.length - 1].filename,
    function: n.function || "?"
  }));
}
const K = "<anonymous>";
function _t(e) {
  try {
    return !e || typeof e != "function" ? K : e.name || K;
  } catch {
    return K;
  }
}
function d() {
  const e = _, t = e.crypto || e.msCrypto;
  let n = () => Math.random() * 16;
  try {
    if (t && t.randomUUID)
      return t.randomUUID().replace(/-/g, "");
    t && t.getRandomValues && (n = () => {
      const r = new Uint8Array(1);
      return t.getRandomValues(r), r[0];
    });
  } catch {
  }
  return ("10000000100040008000" + 1e11).replace(
    /[018]/g,
    (r) => (
      // eslint-disable-next-line no-bitwise
      (r ^ (n() & 15) >> r / 4).toString(16)
    )
  );
}
function Pe(e) {
  return e.exception && e.exception.values ? e.exception.values[0] : void 0;
}
function Ln(e) {
  const { message: t, event_id: n } = e;
  if (t)
    return t;
  const r = Pe(e);
  return r ? r.type && r.value ? `${r.type}: ${r.value}` : r.type || r.value || n || "<unknown>" : n || "<unknown>";
}
function $n(e, t, n) {
  const r = e.exception = e.exception || {}, s = r.values = r.values || [], i = s[0] = s[0] || {};
  i.value || (i.value = t || ""), i.type || (i.type = "Error");
}
function mt(e, t) {
  const n = Pe(e);
  if (!n)
    return;
  const r = { type: "generic", handled: !0 }, s = n.mechanism;
  if (n.mechanism = { ...r, ...s, ...t }, t && "data" in t) {
    const i = { ...s && s.data, ...t.data };
    n.mechanism.data = i;
  }
}
function Un(e) {
  if (e && e.__sentry_captured__)
    return !0;
  try {
    Ae(e, "__sentry_captured__", !0);
  } catch {
  }
  return !1;
}
function yt(e) {
  return Array.isArray(e) ? e : [e];
}
function bt() {
  const e = typeof WeakSet == "function", t = e ? /* @__PURE__ */ new WeakSet() : [];
  function n(s) {
    if (e)
      return t.has(s) ? !0 : (t.add(s), !1);
    for (let i = 0; i < t.length; i++)
      if (t[i] === s)
        return !0;
    return t.push(s), !1;
  }
  function r(s) {
    if (e)
      t.delete(s);
    else
      for (let i = 0; i < t.length; i++)
        if (t[i] === s) {
          t.splice(i, 1);
          break;
        }
  }
  return [n, r];
}
function x(e, t = 100, n = 1 / 0) {
  try {
    return ee("", e, t, n);
  } catch (r) {
    return { ERROR: `**non-serializable** (${r})` };
  }
}
function St(e, t = 3, n = 100 * 1024) {
  const r = x(e, t);
  return xt(r) > n ? St(e, t - 1, n) : r;
}
function ee(e, t, n = 1 / 0, r = 1 / 0, s = bt()) {
  const [i, o] = s;
  if (t == null || // this matches null and undefined -> eqeq not eqeqeq
  ["number", "boolean", "string"].includes(typeof t) && !st(t))
    return t;
  const a = Dt(e, t);
  if (!a.startsWith("[object "))
    return a;
  if (t.__sentry_skip_normalization__)
    return t;
  const c = typeof t.__sentry_override_normalization_depth__ == "number" ? t.__sentry_override_normalization_depth__ : n;
  if (c === 0)
    return a.replace("object ", "");
  if (i(t))
    return "[Circular ~]";
  const u = t;
  if (u && typeof u.toJSON == "function")
    try {
      const p = u.toJSON();
      return ee("", p, c - 1, r, s);
    } catch {
    }
  const l = Array.isArray(t) ? [] : {};
  let f = 0;
  const g = ke(t);
  for (const p in g) {
    if (!Object.prototype.hasOwnProperty.call(g, p))
      continue;
    if (f >= r) {
      l[p] = "[MaxProperties ~]";
      break;
    }
    const M = g[p];
    l[p] = ee(p, M, c - 1, r, s), f++;
  }
  return o(t), l;
}
function Dt(e, t) {
  try {
    if (e === "domain" && t && typeof t == "object" && t._events)
      return "[Domain]";
    if (e === "domainEmitter")
      return "[DomainEmitter]";
    if (typeof global < "u" && t === global)
      return "[Global]";
    if (typeof window < "u" && t === window)
      return "[Window]";
    if (typeof document < "u" && t === document)
      return "[Document]";
    if (Ne(t))
      return "[VueViewModel]";
    if (rt(t))
      return "[SyntheticEvent]";
    if (typeof t == "number" && t !== t)
      return "[NaN]";
    if (typeof t == "function")
      return `[Function: ${_t(t)}]`;
    if (typeof t == "symbol")
      return `[${String(t)}]`;
    if (typeof t == "bigint")
      return `[BigInt: ${String(t)}]`;
    const n = wt(t);
    return /^HTML(\w*)Element$/.test(n) ? `[HTMLElement: ${n}]` : `[object ${n}]`;
  } catch (n) {
    return `**non-serializable** (${n})`;
  }
}
function wt(e) {
  const t = Object.getPrototypeOf(e);
  return t ? t.constructor.name : "null prototype";
}
function Et(e) {
  return ~-encodeURI(e).split(/%..|./).length;
}
function xt(e) {
  return Et(JSON.stringify(e));
}
var S;
(function(e) {
  e[e.PENDING = 0] = "PENDING";
  const n = 1;
  e[e.RESOLVED = n] = "RESOLVED";
  const r = 2;
  e[e.REJECTED = r] = "REJECTED";
})(S || (S = {}));
function Bn(e) {
  return new y((t) => {
    t(e);
  });
}
function Gn(e) {
  return new y((t, n) => {
    n(e);
  });
}
class y {
  constructor(t) {
    y.prototype.__init.call(this), y.prototype.__init2.call(this), y.prototype.__init3.call(this), y.prototype.__init4.call(this), this._state = S.PENDING, this._handlers = [];
    try {
      t(this._resolve, this._reject);
    } catch (n) {
      this._reject(n);
    }
  }
  /** JSDoc */
  then(t, n) {
    return new y((r, s) => {
      this._handlers.push([
        !1,
        (i) => {
          if (!t)
            r(i);
          else
            try {
              r(t(i));
            } catch (o) {
              s(o);
            }
        },
        (i) => {
          if (!n)
            s(i);
          else
            try {
              r(n(i));
            } catch (o) {
              s(o);
            }
        }
      ]), this._executeHandlers();
    });
  }
  /** JSDoc */
  catch(t) {
    return this.then((n) => n, t);
  }
  /** JSDoc */
  finally(t) {
    return new y((n, r) => {
      let s, i;
      return this.then(
        (o) => {
          i = !1, s = o, t && t();
        },
        (o) => {
          i = !0, s = o, t && t();
        }
      ).then(() => {
        if (i) {
          r(s);
          return;
        }
        n(s);
      });
    });
  }
  /** JSDoc */
  __init() {
    this._resolve = (t) => {
      this._setResult(S.RESOLVED, t);
    };
  }
  /** JSDoc */
  __init2() {
    this._reject = (t) => {
      this._setResult(S.REJECTED, t);
    };
  }
  /** JSDoc */
  __init3() {
    this._setResult = (t, n) => {
      if (this._state === S.PENDING) {
        if (se(n)) {
          n.then(this._resolve, this._reject);
          return;
        }
        this._state = t, this._value = n, this._executeHandlers();
      }
    };
  }
  /** JSDoc */
  __init4() {
    this._executeHandlers = () => {
      if (this._state === S.PENDING)
        return;
      const t = this._handlers.slice();
      this._handlers = [], t.forEach((n) => {
        n[0] || (this._state === S.RESOLVED && n[1](this._value), this._state === S.REJECTED && n[2](this._value), n[0] = !0);
      });
    };
  }
}
const Fe = 1e3;
function z() {
  return Date.now() / Fe;
}
function Tt() {
  const { performance: e } = _;
  if (!e || !e.now)
    return z;
  const t = Date.now() - e.now(), n = e.timeOrigin == null ? t : e.timeOrigin;
  return () => (n + e.now()) / Fe;
}
const oe = Tt(), jn = (() => {
  const { performance: e } = _;
  if (!e || !e.now)
    return;
  const t = 3600 * 1e3, n = e.now(), r = Date.now(), s = e.timeOrigin ? Math.abs(e.timeOrigin + n - r) : t, i = s < t, o = e.timing && e.timing.navigationStart, c = typeof o == "number" ? Math.abs(o + n - r) : t, u = c < t;
  return i || u ? s <= c ? e.timeOrigin : o : r;
})(), Yn = "baggage", Le = "sentry-", Mt = /^sentry-/, It = 8192;
function vt(e) {
  if (!G(e) && !Array.isArray(e))
    return;
  let t = {};
  if (Array.isArray(e))
    t = e.reduce((r, s) => {
      const i = _e(s);
      for (const o of Object.keys(i))
        r[o] = i[o];
      return r;
    }, {});
  else {
    if (!e)
      return;
    t = _e(e);
  }
  const n = Object.entries(t).reduce((r, [s, i]) => {
    if (s.match(Mt)) {
      const o = s.slice(Le.length);
      r[o] = i;
    }
    return r;
  }, {});
  if (Object.keys(n).length > 0)
    return n;
}
function Hn(e) {
  if (!e)
    return;
  const t = Object.entries(e).reduce(
    (n, [r, s]) => (s && (n[`${Le}${r}`] = s), n),
    {}
  );
  return Nt(t);
}
function _e(e) {
  return e.split(",").map((t) => t.split("=").map((n) => decodeURIComponent(n.trim()))).reduce((t, [n, r]) => (t[n] = r, t), {});
}
function Nt(e) {
  if (Object.keys(e).length !== 0)
    return Object.entries(e).reduce((t, [n, r], s) => {
      const i = `${encodeURIComponent(n)}=${encodeURIComponent(r)}`, o = s === 0 ? i : `${t},${i}`;
      return o.length > It ? (ie && b.warn(
        `Not adding key: ${n} with val: ${r} to baggage header due to exceeding baggage size limits.`
      ), t) : o;
    }, "");
}
const Ot = new RegExp(
  "^[ \\t]*([0-9a-f]{32})?-?([0-9a-f]{16})?-?([01])?[ \\t]*$"
  // whitespace
);
function Ct(e) {
  if (!e)
    return;
  const t = e.match(Ot);
  if (!t)
    return;
  let n;
  return t[3] === "1" ? n = !0 : t[3] === "0" && (n = !1), {
    traceId: t[1],
    parentSampled: n,
    parentSpanId: t[2]
  };
}
function zn(e, t) {
  const n = Ct(e), r = vt(t), { traceId: s, parentSpanId: i, parentSampled: o } = n || {};
  return n ? {
    traceId: s || d(),
    parentSpanId: i || d().substring(16),
    spanId: d().substring(16),
    sampled: o,
    dsc: r || {}
    // If we have traceparent data but no DSC it means we are not head of trace and we must freeze it
  } : {
    traceId: s || d(),
    spanId: d().substring(16)
  };
}
function At(e = d(), t = d().substring(16), n) {
  let r = "";
  return n !== void 0 && (r = n ? "-1" : "-0"), `${e}-${t}${r}`;
}
const B = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, W = "production";
function ae() {
  return Oe("globalEventProcessors", () => []);
}
function Wn(e) {
  ae().push(e);
}
function j(e, t, n, r = 0) {
  return new y((s, i) => {
    const o = e[r];
    if (t === null || typeof o != "function")
      s(t);
    else {
      const a = o({ ...t }, n);
      B && o.id && a === null && b.log(`Event processor "${o.id}" dropped event`), se(a) ? a.then((c) => j(e, c, n, r + 1).then(s)).then(null, i) : j(e, a, n, r + 1).then(s).then(null, i);
    }
  });
}
function $e(e) {
  const t = oe(), n = {
    sid: d(),
    init: !0,
    timestamp: t,
    started: t,
    duration: 0,
    status: "ok",
    errors: 0,
    ignoreDuration: !1,
    toJSON: () => kt(n)
  };
  return e && L(n, e), n;
}
function L(e, t = {}) {
  if (t.user && (!e.ipAddress && t.user.ip_address && (e.ipAddress = t.user.ip_address), !e.did && !t.did && (e.did = t.user.id || t.user.email || t.user.username)), e.timestamp = t.timestamp || oe(), t.abnormal_mechanism && (e.abnormal_mechanism = t.abnormal_mechanism), t.ignoreDuration && (e.ignoreDuration = t.ignoreDuration), t.sid && (e.sid = t.sid.length === 32 ? t.sid : d()), t.init !== void 0 && (e.init = t.init), !e.did && t.did && (e.did = `${t.did}`), typeof t.started == "number" && (e.started = t.started), e.ignoreDuration)
    e.duration = void 0;
  else if (typeof t.duration == "number")
    e.duration = t.duration;
  else {
    const n = e.timestamp - e.started;
    e.duration = n >= 0 ? n : 0;
  }
  t.release && (e.release = t.release), t.environment && (e.environment = t.environment), !e.ipAddress && t.ipAddress && (e.ipAddress = t.ipAddress), !e.userAgent && t.userAgent && (e.userAgent = t.userAgent), typeof t.errors == "number" && (e.errors = t.errors), t.status && (e.status = t.status);
}
function Ue(e, t) {
  let n = {};
  e.status === "ok" && (n = { status: "exited" }), L(e, n);
}
function kt(e) {
  return T({
    sid: `${e.sid}`,
    init: e.init,
    // Make sure that sec is converted to ms for date constructor
    started: new Date(e.started * 1e3).toISOString(),
    timestamp: new Date(e.timestamp * 1e3).toISOString(),
    status: e.status,
    errors: e.errors,
    did: typeof e.did == "number" || typeof e.did == "string" ? `${e.did}` : void 0,
    duration: e.duration,
    abnormal_mechanism: e.abnormal_mechanism,
    attrs: {
      release: e.release,
      environment: e.environment,
      ip_address: e.ipAddress,
      user_agent: e.userAgent
    }
  });
}
const Jn = 0, Rt = 1;
function Pt(e) {
  const { spanId: t, traceId: n } = e.spanContext(), { data: r, op: s, parent_span_id: i, status: o, tags: a, origin: c } = F(e);
  return T({
    data: r,
    op: s,
    parent_span_id: i,
    span_id: t,
    status: o,
    tags: a,
    trace_id: n,
    origin: c
  });
}
function Vn(e) {
  const { traceId: t, spanId: n } = e.spanContext(), r = Be(e);
  return At(t, n, r);
}
function Kn(e) {
  return typeof e == "number" ? me(e) : Array.isArray(e) ? e[0] + e[1] / 1e9 : e instanceof Date ? me(e.getTime()) : oe();
}
function me(e) {
  return e > 9999999999 ? e / 1e3 : e;
}
function F(e) {
  return Ft(e) ? e.getSpanJSON() : typeof e.toJSON == "function" ? e.toJSON() : {};
}
function Ft(e) {
  return typeof e.getSpanJSON == "function";
}
function Be(e) {
  const { traceFlags: t } = e.spanContext();
  return !!(t & Rt);
}
function Xn(e, t, n, r, s, i) {
  const { normalizeDepth: o = 3, normalizeMaxBreadth: a = 1e3 } = e, c = {
    ...t,
    event_id: t.event_id || n.event_id || d(),
    timestamp: t.timestamp || z()
  }, u = n.integrations || e.integrations.map((m) => m.name);
  Lt(c, e), Bt(c, u), t.type === void 0 && $t(c, e.stackParser);
  const l = jt(r, n.captureContext);
  n.mechanism && mt(c, n.mechanism);
  const f = s && s.getEventProcessors ? s.getEventProcessors() : [], g = nn().getScopeData();
  if (i) {
    const m = i.getScopeData();
    be(g, m);
  }
  if (l) {
    const m = l.getScopeData();
    be(g, m);
  }
  const p = [...n.attachments || [], ...g.attachments];
  p.length && (n.attachments = p), He(c, g);
  const M = [
    ...f,
    // eslint-disable-next-line deprecation/deprecation
    ...ae(),
    // Run scope event processors _after_ all other processors
    ...g.eventProcessors
  ];
  return j(M, c, n).then((m) => (m && Ut(m), typeof o == "number" && o > 0 ? Gt(m, o, a) : m));
}
function Lt(e, t) {
  const { environment: n, release: r, dist: s, maxValueLength: i = 250 } = t;
  "environment" in e || (e.environment = "environment" in t ? n : W), e.release === void 0 && r !== void 0 && (e.release = r), e.dist === void 0 && s !== void 0 && (e.dist = s), e.message && (e.message = P(e.message, i));
  const o = e.exception && e.exception.values && e.exception.values[0];
  o && o.value && (o.value = P(o.value, i));
  const a = e.request;
  a && a.url && (a.url = P(a.url, i));
}
const ye = /* @__PURE__ */ new WeakMap();
function $t(e, t) {
  const n = _._sentryDebugIds;
  if (!n)
    return;
  let r;
  const s = ye.get(t);
  s ? r = s : (r = /* @__PURE__ */ new Map(), ye.set(t, r));
  const i = Object.keys(n).reduce((o, a) => {
    let c;
    const u = r.get(a);
    u ? c = u : (c = t(a), r.set(a, c));
    for (let l = c.length - 1; l >= 0; l--) {
      const f = c[l];
      if (f.filename) {
        o[f.filename] = n[a];
        break;
      }
    }
    return o;
  }, {});
  try {
    e.exception.values.forEach((o) => {
      o.stacktrace.frames.forEach((a) => {
        a.filename && (a.debug_id = i[a.filename]);
      });
    });
  } catch {
  }
}
function Ut(e) {
  const t = {};
  try {
    e.exception.values.forEach((r) => {
      r.stacktrace.frames.forEach((s) => {
        s.debug_id && (s.abs_path ? t[s.abs_path] = s.debug_id : s.filename && (t[s.filename] = s.debug_id), delete s.debug_id);
      });
    });
  } catch {
  }
  if (Object.keys(t).length === 0)
    return;
  e.debug_meta = e.debug_meta || {}, e.debug_meta.images = e.debug_meta.images || [];
  const n = e.debug_meta.images;
  Object.keys(t).forEach((r) => {
    n.push({
      type: "sourcemap",
      code_file: r,
      debug_id: t[r]
    });
  });
}
function Bt(e, t) {
  t.length > 0 && (e.sdk = e.sdk || {}, e.sdk.integrations = [...e.sdk.integrations || [], ...t]);
}
function Gt(e, t, n) {
  if (!e)
    return null;
  const r = {
    ...e,
    ...e.breadcrumbs && {
      breadcrumbs: e.breadcrumbs.map((s) => ({
        ...s,
        ...s.data && {
          data: x(s.data, t, n)
        }
      }))
    },
    ...e.user && {
      user: x(e.user, t, n)
    },
    ...e.contexts && {
      contexts: x(e.contexts, t, n)
    },
    ...e.extra && {
      extra: x(e.extra, t, n)
    }
  };
  return e.contexts && e.contexts.trace && r.contexts && (r.contexts.trace = e.contexts.trace, e.contexts.trace.data && (r.contexts.trace.data = x(e.contexts.trace.data, t, n))), e.spans && (r.spans = e.spans.map((s) => {
    const i = F(s).data;
    return i && (s.data = x(i, t, n)), s;
  })), r;
}
function jt(e, t) {
  if (!t)
    return e;
  const n = e ? e.clone() : new D();
  return n.update(t), n;
}
function Yt(e) {
  if (e)
    return Ht(e) ? { captureContext: e } : Wt(e) ? {
      captureContext: e
    } : e;
}
function Ht(e) {
  return e instanceof D || typeof e == "function";
}
const zt = [
  "user",
  "level",
  "extra",
  "contexts",
  "tags",
  "fingerprint",
  "requestSession",
  "propagationContext"
];
function Wt(e) {
  return Object.keys(e).some((t) => zt.includes(t));
}
function Jt(e, t) {
  return w().captureException(e, Yt(t));
}
function Qn(e, t) {
  return w().captureEvent(e, t);
}
function Zn(e, t) {
  w().addBreadcrumb(e, t);
}
function qn(e, t) {
  w().setTag(e, t);
}
function er(e) {
  w().setUser(e);
}
function tr(...e) {
  const t = w();
  if (e.length === 2) {
    const [n, r] = e;
    return n ? t.withScope(() => (t.getStackTop().scope = n, r(n))) : t.withScope(r);
  }
  return t.withScope(e[0]);
}
function ce() {
  return w().getClient();
}
function J() {
  return w().getScope();
}
function nr(e) {
  const t = ce(), n = ue(), r = J(), { release: s, environment: i = W } = t && t.getOptions() || {}, { userAgent: o } = _.navigator || {}, a = $e({
    release: s,
    environment: i,
    user: r.getUser() || n.getUser(),
    ...o && { userAgent: o },
    ...e
  }), c = n.getSession();
  return c && c.status === "ok" && L(c, { status: "exited" }), Ge(), n.setSession(a), r.setSession(a), a;
}
function Ge() {
  const e = ue(), t = J(), n = t.getSession() || e.getSession();
  n && Ue(n), je(), e.setSession(), t.setSession();
}
function je() {
  const e = ue(), t = J(), n = ce(), r = t.getSession() || e.getSession();
  r && n && n.captureSession && n.captureSession(r);
}
function rr(e = !1) {
  if (e) {
    Ge();
    return;
  }
  je();
}
function Ye(e) {
  return e.transaction;
}
function Vt(e, t, n) {
  const r = t.getOptions(), { publicKey: s } = t.getDsn() || {}, { segment: i } = n && n.getUser() || {}, o = T({
    environment: r.environment || W,
    release: r.release,
    user_segment: i,
    public_key: s,
    trace_id: e
  });
  return t.emit && t.emit("createDsc", o), o;
}
function Kt(e) {
  const t = ce();
  if (!t)
    return {};
  const n = Vt(F(e).trace_id || "", t, J()), r = Ye(e);
  if (!r)
    return n;
  const s = r && r._frozenDynamicSamplingContext;
  if (s)
    return s;
  const { sampleRate: i, source: o } = r.metadata;
  i != null && (n.sample_rate = `${i}`);
  const a = F(r);
  return o && o !== "url" && (n.transaction = a.description), n.sampled = String(Be(r)), t.emit && t.emit("createDsc", n), n;
}
function He(e, t) {
  const { fingerprint: n, span: r, breadcrumbs: s, sdkProcessingMetadata: i } = t;
  Xt(e, t), r && qt(e, r), en(e, n), Qt(e, s), Zt(e, i);
}
function be(e, t) {
  const {
    extra: n,
    tags: r,
    user: s,
    contexts: i,
    level: o,
    sdkProcessingMetadata: a,
    breadcrumbs: c,
    fingerprint: u,
    eventProcessors: l,
    attachments: f,
    propagationContext: g,
    // eslint-disable-next-line deprecation/deprecation
    transactionName: p,
    span: M
  } = t;
  k(e, "extra", n), k(e, "tags", r), k(e, "user", s), k(e, "contexts", i), k(e, "sdkProcessingMetadata", a), o && (e.level = o), p && (e.transactionName = p), M && (e.span = M), c.length && (e.breadcrumbs = [...e.breadcrumbs, ...c]), u.length && (e.fingerprint = [...e.fingerprint, ...u]), l.length && (e.eventProcessors = [...e.eventProcessors, ...l]), f.length && (e.attachments = [...e.attachments, ...f]), e.propagationContext = { ...e.propagationContext, ...g };
}
function k(e, t, n) {
  if (n && Object.keys(n).length) {
    e[t] = { ...e[t] };
    for (const r in n)
      Object.prototype.hasOwnProperty.call(n, r) && (e[t][r] = n[r]);
  }
}
function Xt(e, t) {
  const {
    extra: n,
    tags: r,
    user: s,
    contexts: i,
    level: o,
    // eslint-disable-next-line deprecation/deprecation
    transactionName: a
  } = t, c = T(n);
  c && Object.keys(c).length && (e.extra = { ...c, ...e.extra });
  const u = T(r);
  u && Object.keys(u).length && (e.tags = { ...u, ...e.tags });
  const l = T(s);
  l && Object.keys(l).length && (e.user = { ...l, ...e.user });
  const f = T(i);
  f && Object.keys(f).length && (e.contexts = { ...f, ...e.contexts }), o && (e.level = o), a && (e.transaction = a);
}
function Qt(e, t) {
  const n = [...e.breadcrumbs || [], ...t];
  e.breadcrumbs = n.length ? n : void 0;
}
function Zt(e, t) {
  e.sdkProcessingMetadata = {
    ...e.sdkProcessingMetadata,
    ...t
  };
}
function qt(e, t) {
  e.contexts = { trace: Pt(t), ...e.contexts };
  const n = Ye(t);
  if (n) {
    e.sdkProcessingMetadata = {
      dynamicSamplingContext: Kt(t),
      ...e.sdkProcessingMetadata
    };
    const r = F(n).description;
    r && (e.tags = { transaction: r, ...e.tags });
  }
}
function en(e, t) {
  e.fingerprint = e.fingerprint ? yt(e.fingerprint) : [], t && (e.fingerprint = e.fingerprint.concat(t)), e.fingerprint && !e.fingerprint.length && delete e.fingerprint;
}
const tn = 100;
let X;
class D {
  /** Flag if notifying is happening. */
  /** Callback for client to receive scope changes. */
  /** Callback list that will be called after {@link applyToEvent}. */
  /** Array of breadcrumbs. */
  /** User */
  /** Tags */
  /** Extra */
  /** Contexts */
  /** Attachments */
  /** Propagation Context for distributed tracing */
  /**
   * A place to stash data which is needed at some point in the SDK's event processing pipeline but which shouldn't get
   * sent to Sentry
   */
  /** Fingerprint */
  /** Severity */
  // eslint-disable-next-line deprecation/deprecation
  /**
   * Transaction Name
   */
  /** Span */
  /** Session */
  /** Request Mode Session Status */
  /** The client on this scope */
  // NOTE: Any field which gets added here should get added not only to the constructor but also to the `clone` method.
  constructor() {
    this._notifyingListeners = !1, this._scopeListeners = [], this._eventProcessors = [], this._breadcrumbs = [], this._attachments = [], this._user = {}, this._tags = {}, this._extra = {}, this._contexts = {}, this._sdkProcessingMetadata = {}, this._propagationContext = Se();
  }
  /**
   * Inherit values from the parent scope.
   * @deprecated Use `scope.clone()` and `new Scope()` instead.
   */
  static clone(t) {
    return t ? t.clone() : new D();
  }
  /**
   * Clone this scope instance.
   */
  clone() {
    const t = new D();
    return t._breadcrumbs = [...this._breadcrumbs], t._tags = { ...this._tags }, t._extra = { ...this._extra }, t._contexts = { ...this._contexts }, t._user = this._user, t._level = this._level, t._span = this._span, t._session = this._session, t._transactionName = this._transactionName, t._fingerprint = this._fingerprint, t._eventProcessors = [...this._eventProcessors], t._requestSession = this._requestSession, t._attachments = [...this._attachments], t._sdkProcessingMetadata = { ...this._sdkProcessingMetadata }, t._propagationContext = { ...this._propagationContext }, t._client = this._client, t;
  }
  /** Update the client on the scope. */
  setClient(t) {
    this._client = t;
  }
  /**
   * Get the client assigned to this scope.
   *
   * It is generally recommended to use the global function `Sentry.getClient()` instead, unless you know what you are doing.
   */
  getClient() {
    return this._client;
  }
  /**
   * Add internal on change listener. Used for sub SDKs that need to store the scope.
   * @hidden
   */
  addScopeListener(t) {
    this._scopeListeners.push(t);
  }
  /**
   * @inheritDoc
   */
  addEventProcessor(t) {
    return this._eventProcessors.push(t), this;
  }
  /**
   * @inheritDoc
   */
  setUser(t) {
    return this._user = t || {
      email: void 0,
      id: void 0,
      ip_address: void 0,
      segment: void 0,
      username: void 0
    }, this._session && L(this._session, { user: t }), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getUser() {
    return this._user;
  }
  /**
   * @inheritDoc
   */
  getRequestSession() {
    return this._requestSession;
  }
  /**
   * @inheritDoc
   */
  setRequestSession(t) {
    return this._requestSession = t, this;
  }
  /**
   * @inheritDoc
   */
  setTags(t) {
    return this._tags = {
      ...this._tags,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTag(t, n) {
    return this._tags = { ...this._tags, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtras(t) {
    return this._extra = {
      ...this._extra,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtra(t, n) {
    return this._extra = { ...this._extra, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setFingerprint(t) {
    return this._fingerprint = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setLevel(t) {
    return this._level = t, this._notifyScopeListeners(), this;
  }
  /**
   * Sets the transaction name on the scope for future events.
   */
  setTransactionName(t) {
    return this._transactionName = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setContext(t, n) {
    return n === null ? delete this._contexts[t] : this._contexts[t] = n, this._notifyScopeListeners(), this;
  }
  /**
   * Sets the Span on the scope.
   * @param span Span
   * @deprecated Instead of setting a span on a scope, use `startSpan()`/`startSpanManual()` instead.
   */
  setSpan(t) {
    return this._span = t, this._notifyScopeListeners(), this;
  }
  /**
   * Returns the `Span` if there is one.
   * @deprecated Use `getActiveSpan()` instead.
   */
  getSpan() {
    return this._span;
  }
  /**
   * Returns the `Transaction` attached to the scope (if there is one).
   * @deprecated You should not rely on the transaction, but just use `startSpan()` APIs instead.
   */
  getTransaction() {
    const t = this._span;
    return t && t.transaction;
  }
  /**
   * @inheritDoc
   */
  setSession(t) {
    return t ? this._session = t : delete this._session, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getSession() {
    return this._session;
  }
  /**
   * @inheritDoc
   */
  update(t) {
    if (!t)
      return this;
    const n = typeof t == "function" ? t(this) : t;
    if (n instanceof D) {
      const r = n.getScopeData();
      this._tags = { ...this._tags, ...r.tags }, this._extra = { ...this._extra, ...r.extra }, this._contexts = { ...this._contexts, ...r.contexts }, r.user && Object.keys(r.user).length && (this._user = r.user), r.level && (this._level = r.level), r.fingerprint.length && (this._fingerprint = r.fingerprint), n.getRequestSession() && (this._requestSession = n.getRequestSession()), r.propagationContext && (this._propagationContext = r.propagationContext);
    } else if (re(n)) {
      const r = t;
      this._tags = { ...this._tags, ...r.tags }, this._extra = { ...this._extra, ...r.extra }, this._contexts = { ...this._contexts, ...r.contexts }, r.user && (this._user = r.user), r.level && (this._level = r.level), r.fingerprint && (this._fingerprint = r.fingerprint), r.requestSession && (this._requestSession = r.requestSession), r.propagationContext && (this._propagationContext = r.propagationContext);
    }
    return this;
  }
  /**
   * @inheritDoc
   */
  clear() {
    return this._breadcrumbs = [], this._tags = {}, this._extra = {}, this._user = {}, this._contexts = {}, this._level = void 0, this._transactionName = void 0, this._fingerprint = void 0, this._requestSession = void 0, this._span = void 0, this._session = void 0, this._notifyScopeListeners(), this._attachments = [], this._propagationContext = Se(), this;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(t, n) {
    const r = typeof n == "number" ? n : tn;
    if (r <= 0)
      return this;
    const s = {
      timestamp: z(),
      ...t
    }, i = this._breadcrumbs;
    return i.push(s), this._breadcrumbs = i.length > r ? i.slice(-r) : i, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getLastBreadcrumb() {
    return this._breadcrumbs[this._breadcrumbs.length - 1];
  }
  /**
   * @inheritDoc
   */
  clearBreadcrumbs() {
    return this._breadcrumbs = [], this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addAttachment(t) {
    return this._attachments.push(t), this;
  }
  /**
   * @inheritDoc
   * @deprecated Use `getScopeData()` instead.
   */
  getAttachments() {
    return this.getScopeData().attachments;
  }
  /**
   * @inheritDoc
   */
  clearAttachments() {
    return this._attachments = [], this;
  }
  /** @inheritDoc */
  getScopeData() {
    const {
      _breadcrumbs: t,
      _attachments: n,
      _contexts: r,
      _tags: s,
      _extra: i,
      _user: o,
      _level: a,
      _fingerprint: c,
      _eventProcessors: u,
      _propagationContext: l,
      _sdkProcessingMetadata: f,
      _transactionName: g,
      _span: p
    } = this;
    return {
      breadcrumbs: t,
      attachments: n,
      contexts: r,
      tags: s,
      extra: i,
      user: o,
      level: a,
      fingerprint: c || [],
      eventProcessors: u,
      propagationContext: l,
      sdkProcessingMetadata: f,
      transactionName: g,
      span: p
    };
  }
  /**
   * Applies data from the scope to the event and runs all event processors on it.
   *
   * @param event Event
   * @param hint Object containing additional information about the original exception, for use by the event processors.
   * @hidden
   * @deprecated Use `applyScopeDataToEvent()` directly
   */
  applyToEvent(t, n = {}, r = []) {
    He(t, this.getScopeData());
    const s = [
      ...r,
      // eslint-disable-next-line deprecation/deprecation
      ...ae(),
      ...this._eventProcessors
    ];
    return j(s, t, n);
  }
  /**
   * Add data which will be accessible during event processing but won't get sent to Sentry
   */
  setSDKProcessingMetadata(t) {
    return this._sdkProcessingMetadata = { ...this._sdkProcessingMetadata, ...t }, this;
  }
  /**
   * @inheritDoc
   */
  setPropagationContext(t) {
    return this._propagationContext = t, this;
  }
  /**
   * @inheritDoc
   */
  getPropagationContext() {
    return this._propagationContext;
  }
  /**
   * Capture an exception for this scope.
   *
   * @param exception The exception to capture.
   * @param hint Optinal additional data to attach to the Sentry event.
   * @returns the id of the captured Sentry event.
   */
  captureException(t, n) {
    const r = n && n.event_id ? n.event_id : d();
    if (!this._client)
      return b.warn("No client configured on scope - will not capture exception!"), r;
    const s = new Error("Sentry syntheticException");
    return this._client.captureException(
      t,
      {
        originalException: t,
        syntheticException: s,
        ...n,
        event_id: r
      },
      this
    ), r;
  }
  /**
   * Capture a message for this scope.
   *
   * @param message The message to capture.
   * @param level An optional severity level to report the message with.
   * @param hint Optional additional data to attach to the Sentry event.
   * @returns the id of the captured message.
   */
  captureMessage(t, n, r) {
    const s = r && r.event_id ? r.event_id : d();
    if (!this._client)
      return b.warn("No client configured on scope - will not capture message!"), s;
    const i = new Error(t);
    return this._client.captureMessage(
      t,
      n,
      {
        originalException: t,
        syntheticException: i,
        ...r,
        event_id: s
      },
      this
    ), s;
  }
  /**
   * Captures a manually created event for this scope and sends it to Sentry.
   *
   * @param exception The event to capture.
   * @param hint Optional additional data to attach to the Sentry event.
   * @returns the id of the captured event.
   */
  captureEvent(t, n) {
    const r = n && n.event_id ? n.event_id : d();
    return this._client ? (this._client.captureEvent(t, { ...n, event_id: r }, this), r) : (b.warn("No client configured on scope - will not capture event!"), r);
  }
  /**
   * This will be called on every set call.
   */
  _notifyScopeListeners() {
    this._notifyingListeners || (this._notifyingListeners = !0, this._scopeListeners.forEach((t) => {
      t(this);
    }), this._notifyingListeners = !1);
  }
}
function nn() {
  return X || (X = new D()), X;
}
function Se() {
  return {
    traceId: d(),
    spanId: d().substring(16)
  };
}
const rn = "7.107.0", ze = parseFloat(rn), sn = 100;
class We {
  /** Is a {@link Layer}[] containing the client and scope */
  /** Contains the last event id of a captured event.  */
  /**
   * Creates a new instance of the hub, will push one {@link Layer} into the
   * internal stack on creation.
   *
   * @param client bound to the hub.
   * @param scope bound to the hub.
   * @param version number, higher number means higher priority.
   *
   * @deprecated Instantiation of Hub objects is deprecated and the constructor will be removed in version 8 of the SDK.
   *
   * If you are currently using the Hub for multi-client use like so:
   *
   * ```
   * // OLD
   * const hub = new Hub();
   * hub.bindClient(client);
   * makeMain(hub)
   * ```
   *
   * instead initialize the client as follows:
   *
   * ```
   * // NEW
   * Sentry.withIsolationScope(() => {
   *    Sentry.setCurrentClient(client);
   *    client.init();
   * });
   * ```
   *
   * If you are using the Hub to capture events like so:
   *
   * ```
   * // OLD
   * const client = new Client();
   * const hub = new Hub(client);
   * hub.captureException()
   * ```
   *
   * instead capture isolated events as follows:
   *
   * ```
   * // NEW
   * const client = new Client();
   * const scope = new Scope();
   * scope.setClient(client);
   * scope.captureException();
   * ```
   */
  constructor(t, n, r, s = ze) {
    this._version = s;
    let i;
    n ? i = n : (i = new D(), i.setClient(t));
    let o;
    r ? o = r : (o = new D(), o.setClient(t)), this._stack = [{ scope: i }], t && this.bindClient(t), this._isolationScope = o;
  }
  /**
   * Checks if this hub's version is older than the given version.
   *
   * @param version A version number to compare to.
   * @return True if the given version is newer; otherwise false.
   *
   * @deprecated This will be removed in v8.
   */
  isOlderThan(t) {
    return this._version < t;
  }
  /**
   * This binds the given client to the current scope.
   * @param client An SDK client (client) instance.
   *
   * @deprecated Use `initAndBind()` directly, or `setCurrentClient()` and/or `client.init()` instead.
   */
  bindClient(t) {
    const n = this.getStackTop();
    n.client = t, n.scope.setClient(t), t && t.setupIntegrations && t.setupIntegrations();
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `withScope` instead.
   */
  pushScope() {
    const t = this.getScope().clone();
    return this.getStack().push({
      // eslint-disable-next-line deprecation/deprecation
      client: this.getClient(),
      scope: t
    }), t;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `withScope` instead.
   */
  popScope() {
    return this.getStack().length <= 1 ? !1 : !!this.getStack().pop();
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.withScope()` instead.
   */
  withScope(t) {
    const n = this.pushScope();
    let r;
    try {
      r = t(n);
    } catch (s) {
      throw this.popScope(), s;
    }
    return se(r) ? r.then(
      (s) => (this.popScope(), s),
      (s) => {
        throw this.popScope(), s;
      }
    ) : (this.popScope(), r);
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.getClient()` instead.
   */
  getClient() {
    return this.getStackTop().client;
  }
  /**
   * Returns the scope of the top stack.
   *
   * @deprecated Use `Sentry.getCurrentScope()` instead.
   */
  getScope() {
    return this.getStackTop().scope;
  }
  /**
   * @deprecated Use `Sentry.getIsolationScope()` instead.
   */
  getIsolationScope() {
    return this._isolationScope;
  }
  /**
   * Returns the scope stack for domains or the process.
   * @deprecated This will be removed in v8.
   */
  getStack() {
    return this._stack;
  }
  /**
   * Returns the topmost scope layer in the order domain > local > process.
   * @deprecated This will be removed in v8.
   */
  getStackTop() {
    return this._stack[this._stack.length - 1];
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.captureException()` instead.
   */
  captureException(t, n) {
    const r = this._lastEventId = n && n.event_id ? n.event_id : d(), s = new Error("Sentry syntheticException");
    return this.getScope().captureException(t, {
      originalException: t,
      syntheticException: s,
      ...n,
      event_id: r
    }), r;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use  `Sentry.captureMessage()` instead.
   */
  captureMessage(t, n, r) {
    const s = this._lastEventId = r && r.event_id ? r.event_id : d(), i = new Error(t);
    return this.getScope().captureMessage(t, n, {
      originalException: t,
      syntheticException: i,
      ...r,
      event_id: s
    }), s;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.captureEvent()` instead.
   */
  captureEvent(t, n) {
    const r = n && n.event_id ? n.event_id : d();
    return t.type || (this._lastEventId = r), this.getScope().captureEvent(t, { ...n, event_id: r }), r;
  }
  /**
   * @inheritDoc
   *
   * @deprecated This will be removed in v8.
   */
  lastEventId() {
    return this._lastEventId;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.addBreadcrumb()` instead.
   */
  addBreadcrumb(t, n) {
    const { scope: r, client: s } = this.getStackTop();
    if (!s) return;
    const { beforeBreadcrumb: i = null, maxBreadcrumbs: o = sn } = s.getOptions && s.getOptions() || {};
    if (o <= 0) return;
    const c = { timestamp: z(), ...t }, u = i ? Ce(() => i(c, n)) : c;
    u !== null && (s.emit && s.emit("beforeAddBreadcrumb", u, n), r.addBreadcrumb(u, o));
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setUser()` instead.
   */
  setUser(t) {
    this.getScope().setUser(t), this.getIsolationScope().setUser(t);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setTags()` instead.
   */
  setTags(t) {
    this.getScope().setTags(t), this.getIsolationScope().setTags(t);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setExtras()` instead.
   */
  setExtras(t) {
    this.getScope().setExtras(t), this.getIsolationScope().setExtras(t);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setTag()` instead.
   */
  setTag(t, n) {
    this.getScope().setTag(t, n), this.getIsolationScope().setTag(t, n);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setExtra()` instead.
   */
  setExtra(t, n) {
    this.getScope().setExtra(t, n), this.getIsolationScope().setExtra(t, n);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setContext()` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setContext(t, n) {
    this.getScope().setContext(t, n), this.getIsolationScope().setContext(t, n);
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `getScope()` directly.
   */
  configureScope(t) {
    const { scope: n, client: r } = this.getStackTop();
    r && t(n);
  }
  /**
   * @inheritDoc
   */
  run(t) {
    const n = De(this);
    try {
      t(this);
    } finally {
      De(n);
    }
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.getClient().getIntegrationByName()` instead.
   */
  getIntegration(t) {
    const n = this.getClient();
    if (!n) return null;
    try {
      return n.getIntegration(t);
    } catch {
      return B && b.warn(`Cannot retrieve integration ${t.id} from the current Hub`), null;
    }
  }
  /**
   * Starts a new `Transaction` and returns it. This is the entry point to manual tracing instrumentation.
   *
   * A tree structure can be built by adding child spans to the transaction, and child spans to other spans. To start a
   * new child span within the transaction or any span, call the respective `.startChild()` method.
   *
   * Every child span must be finished before the transaction is finished, otherwise the unfinished spans are discarded.
   *
   * The transaction must be finished with a call to its `.end()` method, at which point the transaction with all its
   * finished child spans will be sent to Sentry.
   *
   * @param context Properties of the new `Transaction`.
   * @param customSamplingContext Information given to the transaction sampling function (along with context-dependent
   * default values). See {@link Options.tracesSampler}.
   *
   * @returns The transaction which was just started
   *
   * @deprecated Use `startSpan()`, `startSpanManual()` or `startInactiveSpan()` instead.
   */
  startTransaction(t, n) {
    const r = this._callExtensionMethod("startTransaction", t, n);
    return B && !r && (this.getClient() ? b.warn(`Tracing extension 'startTransaction' has not been added. Call 'addTracingExtensions' before calling 'init':
Sentry.addTracingExtensions();
Sentry.init({...});
`) : b.warn(
      "Tracing extension 'startTransaction' is missing. You should 'init' the SDK before calling 'startTransaction'"
    )), r;
  }
  /**
   * @inheritDoc
   * @deprecated Use `spanToTraceHeader()` instead.
   */
  traceHeaders() {
    return this._callExtensionMethod("traceHeaders");
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use top level `captureSession` instead.
   */
  captureSession(t = !1) {
    if (t)
      return this.endSession();
    this._sendSessionUpdate();
  }
  /**
   * @inheritDoc
   * @deprecated Use top level `endSession` instead.
   */
  endSession() {
    const n = this.getStackTop().scope, r = n.getSession();
    r && Ue(r), this._sendSessionUpdate(), n.setSession();
  }
  /**
   * @inheritDoc
   * @deprecated Use top level `startSession` instead.
   */
  startSession(t) {
    const { scope: n, client: r } = this.getStackTop(), { release: s, environment: i = W } = r && r.getOptions() || {}, { userAgent: o } = _.navigator || {}, a = $e({
      release: s,
      environment: i,
      user: n.getUser(),
      ...o && { userAgent: o },
      ...t
    }), c = n.getSession && n.getSession();
    return c && c.status === "ok" && L(c, { status: "exited" }), this.endSession(), n.setSession(a), a;
  }
  /**
   * Returns if default PII should be sent to Sentry and propagated in ourgoing requests
   * when Tracing is used.
   *
   * @deprecated Use top-level `getClient().getOptions().sendDefaultPii` instead. This function
   * only unnecessarily increased API surface but only wrapped accessing the option.
   */
  shouldSendDefaultPii() {
    const t = this.getClient(), n = t && t.getOptions();
    return !!(n && n.sendDefaultPii);
  }
  /**
   * Sends the current Session on the scope
   */
  _sendSessionUpdate() {
    const { scope: t, client: n } = this.getStackTop(), r = t.getSession();
    r && n && n.captureSession && n.captureSession(r);
  }
  /**
   * Calls global extension method and binding current instance to the function call
   */
  // @ts-expect-error Function lacks ending return statement and return type does not include 'undefined'. ts(2366)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _callExtensionMethod(t, ...n) {
    const s = V().__SENTRY__;
    if (s && s.extensions && typeof s.extensions[t] == "function")
      return s.extensions[t].apply(this, n);
    B && b.warn(`Extension method ${t} couldn't be found, doing nothing.`);
  }
}
function V() {
  return _.__SENTRY__ = _.__SENTRY__ || {
    extensions: {},
    hub: void 0
  }, _;
}
function De(e) {
  const t = V(), n = te(t);
  return Je(t, e), n;
}
function w() {
  const e = V();
  if (e.__SENTRY__ && e.__SENTRY__.acs) {
    const t = e.__SENTRY__.acs.getCurrentHub();
    if (t)
      return t;
  }
  return on(e);
}
function ue() {
  return w().getIsolationScope();
}
function on(e = V()) {
  return (!an(e) || // eslint-disable-next-line deprecation/deprecation
  te(e).isOlderThan(ze)) && Je(e, new We()), te(e);
}
function an(e) {
  return !!(e && e.__SENTRY__ && e.__SENTRY__.hub);
}
function te(e) {
  return Oe("hub", () => new We(), e);
}
function Je(e, t) {
  if (!e) return !1;
  const n = e.__SENTRY__ = e.__SENTRY__ || {};
  return n.hub = t, !0;
}
const sr = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
var Me;
const I = (Me = process.env) == null ? void 0 : Me.REACT_APP_TENANT_ENV;
var Ie;
const ir = (Ie = process.env) == null ? void 0 : Ie.PUBLIC_URL, Ve = {
  isQa: I === "qa",
  isStaging: I === "staging",
  isSandbox: I === "sandbox",
  isProduction: I === "production",
  isQaOrStaging: [
    "qa",
    "staging"
  ].includes(I),
  isStagingOrSandbox: [
    "staging",
    "sandbox"
  ].includes(I)
}, cn = [
  [
    100,
    0,
    0
  ],
  [
    50,
    50,
    0
  ],
  [
    33,
    33,
    34
  ]
], or = (e) => e ? `${e[0].toUpperCase()}${e.slice(1).toLowerCase()}` : "", U = (e) => {
  const t = -e.getTimezoneOffset(), n = t >= 0 ? "+" : "-", r = (s) => (s < 10 ? "0" : "") + s;
  return e.getFullYear() + "-" + r(e.getMonth() + 1) + "-" + r(e.getDate()) + "T" + r(e.getHours()) + ":" + r(e.getMinutes()) + ":" + r(e.getSeconds()) + n + r(Math.floor(Math.abs(t) / 60)) + ":" + r(Math.abs(t) % 60);
}, un = (e, t) => {
  if (e instanceof Date)
    switch (t) {
      case "yyyy-mm-dd":
        return Object.prototype.toString.call(e) === "[object Date]" ? U(e).split("T")[0] : U(/* @__PURE__ */ new Date()).split("T")[0];
      case "dd-MMM-yyyy":
        if (Object.prototype.toString.call(e) === "[object Date]") {
          const n = new Intl.DateTimeFormat("en", {
            year: "numeric"
          }).format(e), r = new Intl.DateTimeFormat("en", {
            month: "short"
          }).format(e);
          return `${new Intl.DateTimeFormat("en", {
            day: "2-digit"
          }).format(e)}-${r}-${n}`;
        } else {
          const n = /* @__PURE__ */ new Date(), r = new Intl.DateTimeFormat("en", {
            year: "numeric"
          }).format(n), s = new Intl.DateTimeFormat("en", {
            month: "short"
          }).format(n);
          return `${new Intl.DateTimeFormat("en", {
            day: "2-digit"
          }).format(n)}-${s}-${r}`;
        }
      case "dd-MM-yyyy":
        return e ? Object.prototype.toString.call(e) === "[object Date]" ? e.toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata"
        }).replaceAll("/", "-") : new Date(e).toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata"
        }).replaceAll("/", "-") : void 0;
      default:
        return Object.prototype.toString.call(e) === "[object Date]" ? U(e).split("T")[0] : U(/* @__PURE__ */ new Date()).split("T")[0];
    }
  else
    return e;
}, ar = (e, t) => {
  var n;
  if (e)
    return t ? (n = e.find((r) => r.ansi_code === t)) == null ? void 0 : n.name : void 0;
}, cr = (e) => typeof e == "object" && !Array.isArray(e) && e !== null ? Object.keys(e).length === 0 : !0;
function we(e = "") {
  if (!e || e === void 0)
    return;
  const t = e.split(" ");
  return t.length ? t.filter((s) => s).map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(" ") : e.charAt(0).toUpperCase() + e.substring(1).toLowerCase();
}
const ur = (e) => new Promise((t) => setTimeout(t, e)), Ke = [
  "Dhfl",
  "Lic",
  "Icici",
  "Sbi",
  "Boi",
  "Axa",
  "Elss",
  "Idfc",
  "Hdfc",
  "L&t",
  "Dsp",
  "Uti"
], Ee = (e, t = 0) => {
  const n = e;
  return e ? n.toLocaleString("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: t,
    style: "currency",
    currency: "INR"
  }).split("₹")[1] : 0;
}, lr = (e) => {
  if (e) {
    e = e.replace("-", ""), e = e.split(" ").map((t) => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()).join(" ");
    for (const t of Ke)
      e = e.replace(t, t.toUpperCase());
    e = e.trim();
  }
  return e;
}, fr = (e) => (e && (e = e.split("_").map((t) => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()).join(" "), e = e.replace("Div", "Dividend"), e = e.trim()), e), ln = (e) => !(Array.isArray(e) && e.length), dr = (e) => e, gr = (e) => {
  let t, n;
  return (r) => (r !== t && (t = r, n = e(r)), n);
}, pr = () => {
  const e = new Uint32Array(8);
  window.crypto.getRandomValues(e);
  let t = "";
  for (let n = 0; n < e.length; n++)
    t += (n < 2 || n > 5 ? "" : "-") + e[n].toString(16).slice(-4);
  return t;
}, hr = (e) => {
  const n = e.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"), r = decodeURIComponent(window.atob(n).split("").map((s) => "%" + ("00" + s.charCodeAt(0).toString(16)).slice(-2)).join(""));
  return JSON.parse(r);
};
function _r(e, t) {
  if (!e || !t) return 0;
  const n = (e.toString().split(".")[1] || "").length, r = (t.toString().split(".")[1] || "").length, s = n > r ? n : r, i = parseInt(parseFloat(e).toFixed(s).replace(".", "")), o = parseInt(parseFloat(t).toFixed(s).replace(".", ""));
  return i % o / Math.pow(10, s);
}
const fn = (e) => {
  const t = Number(e);
  return t ? t.toLocaleString("en-IN", {
    // maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR"
  }).split("₹")[1] : "";
}, dn = (e) => e.replace(/,/g, ""), mr = (e) => fn(Number(dn(e))), yr = (e) => {
  const t = [];
  return e.forEach((n) => {
    switch (n) {
      case "netbanking":
        t.push({
          option: "Netbanking",
          icon: "Netbanking",
          value: "netbanking",
          label: "Netbanking"
        });
        break;
      case "upi":
        t.push({
          option: "UPI",
          icon: "Upi",
          value: "upi",
          label: "UPI"
        });
        break;
      case "salary":
        t.push({
          option: "Netbanking",
          icon: "Netbanking",
          value: "salary",
          label: "Salary"
        });
        break;
      case "mandate":
        t.push({
          option: "mandate",
          icon: "Emandate",
          value: "mandate",
          label: "Mandate"
        });
        break;
    }
  }), t;
}, v = (e) => {
  if (e === null) return "-";
  if (e > 3 && e < 21) return `${e}th`;
  switch (e % 10) {
    case 1:
      return `${e}st`;
    case 2:
      return `${e}nd`;
    case 3:
      return `${e}rd`;
    default:
      return `${e}th`;
  }
}, br = (e, t, n = "Select start date") => {
  if (e !== null) {
    const r = new Date(e), s = r.getDate(), i = r.toLocaleString("default", {
      month: "long"
    }), o = r.toLocaleString("en-us", {
      weekday: "long"
    }), a = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    return t === "daily" ? v(s) + " onwards" : t === "monthly" ? v(s) + " of every month" : t === "quarterly" || t === "yearly" || t === "half_yearly" ? v(s) + ` of the ${i}` : t === "day_in_a_week" ? `Every ${v(a.indexOf(o))} day of week` : t === "day_in_a_fortnight" ? `Every alternate ${v(a.indexOf(o))} day of week` : `${v(s)} of the ${i}`;
  } else
    return "";
}, Sr = (e, t, n = null) => {
  if (ln(t))
    return !1;
  if (n === "day_in_a_week" || n === "day_in_a_fortnight") {
    const r = new Date(e).toLocaleString("en-us", {
      weekday: "long"
    });
    return r === "Saturday" || r === "Sunday";
  } else return !t.includes(e.getDate());
}, Dr = (e, t, n) => {
  const r = n == null ? void 0 : n.isNotArrayOfObjects, s = (n == null ? void 0 : n.customProperty) || "value";
  return e.findIndex((i) => {
    if (r)
      return i === t;
    if (s.includes(".")) {
      const o = s.split(".");
      let a;
      for (const c of o)
        a ? a = a[c] : a = i[c];
      return a === t;
    }
    return i[s] === t;
  });
}, wr = (e, t) => t.getDate() === e.getDate(), Er = (e, t = "systematic") => {
  let n = [];
  return t === "nonSystematic" && (e = {
    daily: {},
    monthly: {},
    quarterly: {},
    day_in_a_fortnight: {},
    day_in_a_week: {},
    four_times_a_month: {},
    twice_a_month: {},
    half_yearly: {},
    yearly: {}
  }), Object.keys(e).forEach((r) => {
    switch (r) {
      case "monthly":
        n.push({
          label: "Monthly",
          value: "monthly",
          id: 0
        });
        break;
      case "quarterly":
        n.push({
          label: "Quarterly",
          value: "quarterly",
          id: 6
        });
        break;
      case "daily":
        n.push({
          label: "Daily",
          value: "daily",
          id: 1
        });
        break;
      case "day_in_a_fortnight":
        n.push({
          label: "Day in a fortnight",
          value: "day_in_a_fortnight",
          id: 3
        });
        break;
      case "day_in_a_week":
        n.push({
          label: "Day in a week",
          value: "day_in_a_week",
          id: 2
        });
        break;
      case "four_times_a_month":
        n.push({
          label: "Four times a month",
          value: "four_times_a_month",
          id: 5
        });
        break;
      case "twice_a_month":
        n.push({
          label: "Twice a month",
          value: "twice_a_month",
          id: 4
        });
        break;
      case "half_yearly":
        n.push({
          label: "Half yearly",
          value: "half_yearly",
          id: 7
        });
        break;
      case "yearly":
        n.push({
          label: "Yearly",
          value: "yearly",
          id: 8
        });
        break;
    }
  }), n = n.sort((r, s) => r.id - s.id), n;
}, Xe = {
  four_times_a_month: 0.25,
  twice_a_month: 0.5,
  day_in_a_week: 0.2,
  day_in_a_fortnight: 0.4,
  monthly: 1,
  quarterly: 3,
  half_yearly: 6,
  yearly: 12
}, xr = (e) => {
  if (!e)
    return "";
  const t = e.lastIndexOf("@");
  if (t !== -1) {
    const n = e == null ? void 0 : e.substring(0, t), r = e == null ? void 0 : e.substring(t + 1);
    return `${n[0] + "*".repeat((n == null ? void 0 : n.length) - 1)}@${r}`;
  }
  return e;
}, Tr = (e) => {
  if (!e)
    return "";
  const n = "*".repeat(5), r = String(e).slice(-4);
  return `${n}${r}`;
}, Mr = (e) => {
  if (e) {
    const t = document.querySelector(`[data-name=${e}]`);
    t && t.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
  }
}, Ir = (e) => {
  if (Number(e) || Number(e) === 0) {
    let t = Math.floor(e / 60), n = e % 60;
    return t = t < 10 ? `0${t}` : t, n = n < 10 ? `0${n}` : n, `${t}:${n}`;
  }
}, vr = (e) => new RegExp(`[^${e}]`, "g"), Nr = (e) => {
  const t = [
    ...Ke
  ];
  if (e) {
    const n = t.find((r) => e.toLowerCase().includes(r.toLowerCase()));
    return we(n ? e.replace(n, n.toUpperCase()) : e);
  }
  return "";
}, gn = (e, t, n) => !e || !t || !n ? !1 : new Date(e) <= /* @__PURE__ */ new Date() && /* @__PURE__ */ new Date() <= new Date(n), Y = (e, t, n) => {
  const r = new Date(t.getFullYear(), t.getMonth() + 1, t.getDate()), s = new Date(n.getFullYear(), n.getMonth() + 1, n.getDate());
  return r > e && s > e ? {
    nextSalaryDate: r,
    nextCutOffSalaryDate: s
  } : Y(e, r, s);
}, pn = (e, t) => {
  const n = new Date(e), r = n.getDate(), s = new Date(n.getFullYear(), n.getMonth() + 1, 0).getDate(), i = r < t ? t : t + s - r;
  return n.setDate(i), n;
}, xe = (e, t) => (e == null ? void 0 : e.getDate()) === (t == null ? void 0 : t.getDate()) ? !1 : pn(e, t.getDate()).getMonth() !== t.getMonth(), Or = ({ salaryDay: e, salaryCutoffDay: t, purchaseDate: n }) => {
  const r = new Date((/* @__PURE__ */ new Date()).setHours(0, 0, 0, 0)), s = n ? new Date(new Date(n).setHours(0, 0, 0, 0)) : r, i = new Date(s.getFullYear(), s.getMonth(), Number(e)), o = new Date(i.getFullYear(), Number(e) < Number(t) ? i.getMonth() - 1 : i.getMonth(), Number(t));
  if (s >= o) {
    const { nextSalaryDate: a, nextCutOffSalaryDate: c } = Y(s, i, o);
    return {
      orderDate: s,
      scheduled_on: c,
      consideredSalaryDate: a,
      consideredCutOffSalaryDate: c
    };
  } else
    return {
      orderDate: s,
      scheduled_on: o,
      consideredSalaryDate: i,
      consideredCutOffSalaryDate: o
    };
}, Cr = ({ salaryDay: e, salaryCutoffDay: t, NFO: n, purchaseDate: r }) => {
  const s = new Date((/* @__PURE__ */ new Date()).setHours(0, 0, 0, 0)), i = r ? new Date(new Date(r).setHours(0, 0, 0, 0)) : s, o = new Date(i.getFullYear(), i.getMonth(), Number(e)), a = new Date(o.getFullYear(), Number(e) < Number(t) ? o.getMonth() - 1 : o.getMonth(), Number(t));
  if (gn(n == null ? void 0 : n.start, n == null ? void 0 : n.close, n == null ? void 0 : n.reopen)) {
    const { nextSalaryDate: c, nextCutOffSalaryDate: u } = Y(new Date(n == null ? void 0 : n.reopen), o, a), l = u, f = xe(i, l) ? new Date(l.getFullYear(), l.getMonth(), l.getDate() - 2) : null;
    return {
      orderDate: i,
      installment_date: l,
      activate_after: f,
      consideredSalaryDate: c,
      consideredCutOffSalaryDate: u
    };
  } else if (i >= a) {
    const { nextSalaryDate: c, nextCutOffSalaryDate: u } = Y(i, o, a);
    return {
      orderDate: i,
      installment_date: u,
      activate_after: null,
      consideredSalaryDate: c,
      consideredCutOffSalaryDate: u
    };
  } else {
    const c = o, u = xe(i, c) ? new Date(c.getFullYear(), c.getMonth(), c.getDate() - 2) : null;
    return {
      orderDate: i,
      installment_date: c,
      activate_after: u,
      consideredSalaryDate: o,
      consideredCutOffSalaryDate: a
    };
  }
}, Ar = (e) => (e == null ? void 0 : e.requested_activation_date) !== null && // new Date() < new Date(mfpp?.requested_activation_date) &&
(e == null ? void 0 : e.activated_at) === null && (e == null ? void 0 : e.state) === "created", kr = (e) => {
  switch (e.split("_")[0]) {
    default:
      return null;
    case "mfp":
      return "mf_purchase";
    case "mfr":
      return "mf_redemption";
    case "mfs":
      return "mf_switch";
    case "mfpp":
      return "mf_purchase_plan";
    case "mfrp":
      return "mf_redemption_plan";
    case "mfsp":
      return "mf_switch_plan";
    case "invp":
      return "investor_profile";
  }
}, Rr = (e) => {
  switch (e == null ? void 0 : e.split("_")[0]) {
    default:
      return null;
    case "mfp":
      return "Purchase";
    case "mfr":
      return "Withdraw";
    case "mfs":
      return "Switch";
    case "mfpp":
      return "Purchase plan";
    case "mfrp":
      return "Withdraw plan";
    case "mfsp":
      return "Switch plan";
  }
}, Pr = (e) => {
  switch (e == null ? void 0 : e.split("_")[0]) {
    default:
      return null;
    case "mfp":
      return "Purchase";
    case "mfr":
      return "Withdrawal";
    case "mfs":
      return "Switch";
    case "mfpp":
      return "Purchase";
    case "mfrp":
      return "Withdrawal";
    case "mfsp":
      return "Switch";
  }
}, Fr = (e, t, n) => {
  switch (e) {
    case "pending":
      return t === "mf_purchase" ? "Awaiting payment" : "Under process";
    case "cancelled":
      return "Cancelled";
    case "confirmed":
      return "Under process";
    case "submitted":
      return "Under process";
    case "successful":
      return "Successful";
    case "failed":
      return t === "mf_purchase" && n === "payment_failure" ? "Payment failed" : "Failed";
    case "reversed":
      return "Reversed";
    default:
      return "-";
  }
}, Lr = (e, t) => new Date(e).getDate() === new Date(t).getDate() && new Date(e).getMonth() === new Date(t).getMonth() && new Date(e).getFullYear() === new Date(t).getFullYear() ? "isEqual" : new Date(e).getTime() > new Date(t).getTime() ? "isAfter" : new Date(e).getTime() < new Date(t).getTime() ? "isBefore" : "", ne = (e, t) => {
  const n = Number(new Date(e)) - Number(new Date(t));
  return Math.ceil(n / (1e3 * 60 * 60 * 24));
}, hn = (e, t) => {
  let n = (e.getTime() - t.getTime()) / 1e3;
  return n /= 60 * 60 * 24 * 7, Math.round(n);
}, $r = (e, t) => t.getMonth() - e.getMonth() - 1 + 12 * (t.getFullYear() - e.getFullYear()), Ur = (e, t, n) => {
  const r = /* @__PURE__ */ new Date();
  if (e === null && (e = new Date(r.setDate(r.getDate() + 1))), t === "daily")
    return new Date(new Date(e).setDate(e.getDate() - 1 + n));
  if (t === "four_times_a_month" || t === "twice_a_month" || t === "day_in_a_fortnight" || t === "day_in_a_week") {
    let s = 1;
    if (t === "four_times_a_month" || t === "day_in_a_week" ? s = 7 : (t === "twice_a_month" || t === "day_in_a_fortnight") && (s = 14), e.getDate() === 28 && (t === "twice_a_month" || t === "four_times_a_month")) {
      const i = new Date(e.getFullYear(), e.getMonth() + 1, 1);
      return new Date(new Date(i).setDate(i.getDate() + (n - 1) * s));
    } else
      return new Date(new Date(e).setDate(e.getDate() + n * s));
  } else if (t === "monthly" || t === "quarterly" || t === "half_yearly" || t === "yearly")
    return new Date(new Date(e).setMonth(e.getMonth() + Xe[t] * n));
  return /* @__PURE__ */ new Date();
}, Br = (e) => e === "pending" ? "warning" : e === "confirmed" || e === "successful" || e === "submitted" ? "success" : e === "failed" || e === "reversed" || e === "cancelled" ? "error" : "success", Gr = (e, t) => {
  const n = e == null ? void 0 : e.data;
  let r = null;
  return t && (r = n.find((s) => !t[s == null ? void 0 : s.email.split("@")[1]] && (s == null ? void 0 : s.belongs_to) === "self")), r || (n == null ? void 0 : n[0]);
}, jr = (e, t, n, r, s) => {
  let i = (t.getFullYear() - e.getFullYear()) * 12;
  if (i = i - e.getMonth(), i = i + t.getMonth(), n === "daily")
    return ne(t, e) + 1;
  if (n === "four_times_a_month" || n === "twice_a_month") {
    let o = t.getMonth() - e.getMonth();
    o -= 1, o = n === "four_times_a_month" ? o * 4 : o * 2;
    let a = 0, c = 0;
    if (o <= 1 && (o = 0), s === "nonSystematic") {
      let u = [
        e.getDate()
      ];
      const l = r.concat(r);
      if (n === "twice_a_month") {
        const f = l.indexOf(u[0]) + 14;
        u.push(l[f]), e.getDate() === 28 && (u = [
          28,
          1
        ]);
      } else if (n === "four_times_a_month") {
        for (let f = 0; f < 3; f++) {
          const g = l.indexOf(u[f]) + 7;
          u.push(l[g]);
        }
        e.getDate() === 28 && (u = [
          28,
          7,
          14,
          21
        ]);
      }
      return new Date(e).getMonth() <= new Date(t).getMonth() && (u.forEach((f) => {
        f >= new Date(e).getDate() && (a = //case1: same month, check start month's date and end month's date
        new Date(t).getMonth() === new Date(e).getMonth() && f < new Date(t).getDate() || new Date(t).getMonth() > new Date(e).getMonth() ? a + 1 : a);
      }), n === "four_times_a_month" ? a = a > 4 ? 4 : a : a = a > 2 ? 2 : a, new Date(e).getMonth() < new Date(t).getMonth() && (u.forEach((f) => {
        f <= new Date(t).getDate() && c++;
      }), n === "four_times_a_month" ? c = c > 4 ? 4 : c : c = c > 2 ? 2 : c)), a + o + c;
    } else
      return new Date(e).getMonth() <= new Date(t).getMonth() && (r.forEach((u) => {
        u >= new Date(e).getDate() && (a = //case1: same month, check start month's date and end month's date
        new Date(t).getMonth() === new Date(e).getMonth() && u < new Date(t).getDate() || new Date(t).getMonth() > new Date(e).getMonth() ? a + 1 : a);
      }), n === "four_times_a_month" ? a = a > 4 ? 4 : a : a = a > 2 ? 2 : a, new Date(e).getMonth() < new Date(t).getMonth() && (r.forEach((u) => {
        u <= new Date(t).getDate() && c++;
      }), n === "four_times_a_month" ? c = c > 4 ? 4 : c : c = c > 2 ? 2 : c)), a + o + c;
  } else if (n === "day_in_a_week" || n === "day_in_a_fortnight") {
    const o = hn(new Date(t), new Date(e));
    return n === "day_in_a_week" ? o : Math.floor(o / 2);
  } else
    return Math.round(i / Xe[n]);
};
function _n(e, t) {
  let n = 0, r = e.length - 1, s = -1;
  for (; n <= r; ) {
    const i = Math.round((n + r) / 2);
    e[i] <= t ? n = i + 1 : (s = i, r = i - 1);
  }
  return s;
}
const Yr = (e, t) => {
  if (t === "day_in_a_week" || t === "day_in_a_fortnight") {
    const n = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ], r = new Date(e).toLocaleString("en-us", {
      weekday: "long"
    });
    return n.indexOf(r);
  } else
    return e.getDate();
}, Hr = (e) => {
  const t = /* @__PURE__ */ new Date();
  return e === "daily" ? new Date(t.getFullYear() + 3, t.getMonth(), t.getDate()) : e === "day_in_a_week" ? new Date(t.getFullYear() + 15, t.getMonth(), t.getDate()) : e === "day_in_a_fortnight" || e === "twice_a_month" ? new Date(t.getFullYear() + 45, t.getMonth(), t.getDate()) : e === "four_times_a_month" ? new Date(t.getFullYear() + 22, t.getMonth(), t.getDate()) : e === "yearly" ? new Date(2500, 11, 31) : new Date(2099, 11, 31);
}, zr = (e, t) => {
  const n = /* @__PURE__ */ new Date();
  if (e === "daily")
    return new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 2));
  if (e === "day_in_a_week" || e === "day_in_a_fortnight") {
    let r = new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 1));
    return r.toLocaleString("en-us", {
      weekday: "long"
    }) === "Sunday" ? r = new Date(r.setDate(r.getDate() + 1)) : r.toLocaleString("en-us", {
      weekday: "long"
    }) === "Saturday" && (r = new Date(r.setDate(r.getDate() + 2))), r;
  } else {
    if (t.includes(new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 1))))
      return new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 1));
    {
      const r = _n(t, n.getDate());
      return r !== -1 ? new Date((/* @__PURE__ */ new Date()).getFullYear(), (/* @__PURE__ */ new Date()).getMonth(), t[r]) : new Date((/* @__PURE__ */ new Date()).getFullYear(), (/* @__PURE__ */ new Date()).getMonth() + 1, t[0]);
    }
  }
}, Wr = (e) => {
  const t = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28
  ];
  switch (e) {
    case "monthly":
      return t;
    case "day_in_a_week":
      return [
        1,
        2,
        3,
        4,
        5
      ];
    case "daily":
      return [];
    case "quarterly":
      return t;
    case "half_yearly":
      return t;
    case "yearly":
      return t;
    case "day_in_a_fortnight":
      return [
        1,
        2,
        3,
        4,
        5
      ];
    case "twice_a_month":
      return t;
    case "four_times_a_month":
      return t;
    default:
      return t;
  }
}, Jr = (e) => e === null ? {
  minInvestmentAmount: "min_initial_investment",
  maxInvestmentAmount: "max_initial_investment",
  investmentAmountMultiples: "initial_investment_multiples"
} : {
  minInvestmentAmount: "min_additional_investment",
  maxInvestmentAmount: "max_additional_investment",
  investmentAmountMultiples: "additional_investment_multiples"
}, Vr = (e, t) => {
  const r = /* @__PURE__ */ new Date();
  if (r.setDate(r.getDate() + 1), e === null || t === "daily" && (e == null ? void 0 : e.toDateString()) === (r == null ? void 0 : r.toDateString()))
    return null;
  if (ne(e, /* @__PURE__ */ new Date()) > 30 || t === "daily" && t !== "day_in_a_week" && t !== "day_in_a_fortnight") {
    const s = new Date(e).setDate(e.getDate() - 1);
    return new Date(s);
  } else if ((t === "day_in_a_week" || t === "day_in_a_fortnight") && ne(e, /* @__PURE__ */ new Date()) >= 8) {
    const s = new Date(e).setDate(e.getDate() - 1);
    return new Date(s);
  }
  return null;
}, E = (e) => {
  if (!e || typeof e != "string")
    return;
  const t = e.split(" "), n = t.length > 1, r = t[0];
  return n ? r : e;
}, mn = (e) => {
  if (!(!e || typeof e != "string"))
    return "****";
}, N = (e) => {
  if (!(!e || typeof e != "string"))
    return "****";
}, O = (e) => {
  if (!e || typeof e != "string")
    return;
  const t = e.slice(0, 2), n = e.slice(-2);
  return `${t}*****${n}`;
}, Q = (e) => !e || typeof e != "string" ? void 0 : `****${e.slice(-4)}`, Z = (e) => {
  if (!e || typeof e != "string")
    return;
  const t = e.split("@"), n = t[0], r = t[1];
  let i = n.slice(0, 2);
  if (n.length >= 4) {
    const o = n.slice(-2);
    i += `******${o}`;
  } else if (n.length === 3) {
    const o = n.slice(-1);
    i += `******${o}`;
  } else
    i += "******";
  return `${i}@${r}`;
}, R = (e) => !e || typeof e != "string" ? void 0 : `****${e.slice(-4)}`, h = (e) => {
  if (!(!e || typeof e != "string"))
    return "*******";
}, yn = (e) => {
  const t = {
    data: 1,
    status: 1,
    statusText: 1,
    headers: 1,
    config: 1
  };
  for (const n in t)
    if (!Object.prototype.hasOwnProperty.call(e, n))
      return !1;
  return !0;
}, Qe = (e) => {
  const t = {
    name: E,
    date_of_birth: N,
    pan: O,
    account_number: Q,
    email: Z,
    number: R,
    line1: h,
    line2: h,
    line3: h,
    city: h,
    state: h,
    postal_code: h,
    code: h,
    country: h,
    lastName: mn,
    mobile_number: R,
    father_name: E,
    spouse_name: E,
    mother_name: E,
    country_of_birth: h,
    place_of_birth: h,
    line_1: h,
    line_2: h,
    line_3: h,
    pincode: h,
    primary_investor_pan: O,
    second_investor_pan: O,
    third_investor_pan: O,
    bank_account_number: Q,
    beneficiary_account_number: Q,
    mobile: R,
    primary_investor_name: E,
    secondary_investor_name: E,
    third_investor_name: E,
    primary_investor_dob: N,
    secondary_investor_dob: N,
    third_investor_dob: N,
    guardian_name: E,
    guardian_pan: O,
    guardian_dob: N,
    dob: N,
    email_addresses: Z,
    mobile_numbers: R,
    email_address: Z,
    phone_number: R,
    username: O
  }, n = {};
  if (!e)
    return n;
  for (const [r, s] of Object.entries(e))
    s && Array.isArray(s) && r in t ? n[r] = s.map((i) => t[r](i)) : s && typeof s == "object" ? n[r] = Qe(s) : r in t ? n[r] = t[r](s) : n[r] = s;
  return n;
}, Te = (e, t) => {
  Ve.isQaOrStaging || Jt(e, {
    contexts: {
      support_data: t
    }
  });
}, bn = (e) => {
  Ve.isQaOrStaging || (yn(e) ? Te(e == null ? void 0 : e.data, Sn(e)) : Te(e));
}, Sn = (e) => {
  const t = e.config, { Authorization: n, ...r } = t.headers;
  let s;
  try {
    s = JSON.parse(t.data);
  } catch (o) {
    bn(o);
  }
  return {
    data: t.data ? Qe(s) : null,
    params: t.params,
    headers: r
  };
}, Kr = (e) => {
  if (e)
    return Math.abs(Number(/* @__PURE__ */ new Date()) - Number(new Date(e))) > 24 * 60 * 60 * 1e3;
}, Xr = (e) => {
  if (e)
    return Number(/* @__PURE__ */ new Date()) > Number(new Date(e));
}, Qr = (e) => {
  const t = e.length;
  if (!t)
    return [];
  const n = [];
  for (let r = 0; r < e.length; r++)
    n.push({
      id: e[r].id,
      allocationPercentage: cn[t - 1][r]
    });
  return n;
};
function Zr([e, t], n, { changeValue: r }) {
  r(n, e, () => t);
}
function Dn(e, t) {
  var s, i, o;
  let n = {};
  const r = [
    "ipv_video"
  ];
  if (((s = t == null ? void 0 : t.verification) == null ? void 0 : s.status) === "rejected" && ((i = t == null ? void 0 : t.verification) != null && i.details))
    for (const a in (o = t == null ? void 0 : t.verification) == null ? void 0 : o.details)
      a.includes(".") ? r.push(a.split(".")[1]) : r.push(a);
  for (const a in t)
    typeof t[a] == "object" && [
      "mobile",
      "address",
      "bank_account"
    ].includes(a) ? n = {
      ...n,
      ...Dn(e, {
        ...t[a],
        verification: t == null ? void 0 : t.verification
      })
    } : a in e && (r.includes(a) || (n[e[a].initFieldName] = e[a].initFieldValue));
  return n;
}
function qr(e) {
  var r, s, i, o, a, c;
  if (!e)
    return;
  const t = structuredClone(e), n = [
    "ipv_video",
    "geolocation",
    "object",
    "status",
    "created_at",
    "expires_at",
    "otp",
    "requirements",
    "redirect_url",
    "verification",
    "updated_at",
    "esign_required_at",
    "submitted_at",
    "successful_at",
    "rejected_at"
  ];
  if ((r = t == null ? void 0 : t.address) != null && r.proof.includes("iddoc") && !n.includes("address") && n.push("address"), (s = t == null ? void 0 : t.address) != null && s.proof_expiry_date && new Date((i = t == null ? void 0 : t.address) == null ? void 0 : i.proof_expiry_date) < /* @__PURE__ */ new Date() && !n.includes("address") && n.push("address"), ((o = t == null ? void 0 : t.verification) == null ? void 0 : o.status) === "rejected" && ((a = t == null ? void 0 : t.verification) != null && a.details))
    for (const u in (c = t == null ? void 0 : t.verification) == null ? void 0 : c.details)
      if (u.includes(".")) {
        const l = u.split(".")[0];
        !n.includes(l) && n.push(l);
      } else
        !n.includes(u) && n.push(u);
  for (const u in t)
    (n.includes(u) || !t[u]) && delete t[u];
  return t;
}
const es = (e) => e.father_name ? "father" : e.spouse_name ? "spouse" : void 0, ts = (e) => e.father_name ? e.father_name : e.spouse_name ? e.spouse_name : void 0, ns = (e, t) => {
  var r, s, i, o;
  let n = [];
  return e && ((r = t == null ? void 0 : t.groupInvestment) != null && r.allowedEmployerSchemes) && ((s = t == null ? void 0 : t.groupInvestment) != null && s.allowedEmployerSchemes[e]) && ((i = t == null ? void 0 : t.groupInvestment) != null && i.allowedEmployerSchemes[e].length) && (n = (o = t == null ? void 0 : t.groupInvestment) == null ? void 0 : o.allowedEmployerSchemes[e].map((a) => a.isin)), n;
}, rs = (e) => {
  const n = new URLSearchParams(e).get("employer");
  return n ? "employer=" + n : "";
}, ss = (e, t) => {
  const r = [
    {
      value: 1e3,
      symbol: "K"
    },
    {
      value: 1e5,
      symbol: "L"
    },
    {
      value: 1e7,
      symbol: "Cr"
    }
  ].reverse().find((s) => e >= s.value);
  return r ? Ee(e / r.value, t) + r.symbol : Ee(e, t);
}, is = (e = "", t = "", n = "") => [
  e,
  t,
  n
].filter(Boolean).join(", "), os = (e, t) => {
  const n = e == null ? void 0 : e.find((s) => {
    const i = (s == null ? void 0 : s.email) && s.email.split("@")[1];
    return !t[i];
  });
  return (n == null ? void 0 : n.email) || "";
};
function as(e, t) {
  var r;
  const n = [];
  for (let s = 0; s < e.length; ++s)
    ((r = e[s]) == null ? void 0 : r.value) !== t && n.push(s);
  return n;
}
function cs() {
  return window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: "smooth"
  });
}
const us = (e) => {
  var r;
  const t = {}, n = (r = e == null ? void 0 : e.requirements) == null ? void 0 : r.fields_needed;
  return n && [
    "identity_proof",
    "address",
    "bank_account",
    "occupation_type",
    "aadhaar_number",
    "photo",
    "signature",
    "ipv_video",
    "geolocation"
  ].forEach((i) => {
    n.includes(i) || (t[i] = !0);
  }), t;
}, ls = (e, t) => {
  if (!(!e || !t || t < 1))
    return e % t === 0;
};
function fs(e, t) {
  const n = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth"
  ];
  return t === 1 ? "Nominee" : n[e] + " nominee";
}
const ds = {
  isRedirected() {
    return !!sessionStorage.getItem("isRedirected");
  },
  saveRedirectionState() {
    sessionStorage.setItem("isRedirected", "true");
  },
  removeRedirectionState() {
    sessionStorage.removeItem("isRedirected");
  }
}, gs = (e) => {
  switch (e.toLowerCase()) {
    case "netbanking":
      return "Net banking";
    case "upi":
      return "UPI";
    case "emandate":
      return "Mandate";
    default:
      return e;
  }
}, ps = (e) => {
  const t = new Date(new Date(e).getTime() + 18e5);
  return (/* @__PURE__ */ new Date()).getTime() > t.getTime();
};
function hs(e, t) {
  const n = /* @__PURE__ */ new Date();
  return n.setMonth(n.getMonth() - t), new Date(e) < n;
}
const _s = (e) => {
  const t = new Date(e).getFullYear();
  return Number(t) >= 2099 ? "Until you stop" : un(new Date(e), "dd-MMM-yyyy");
}, ms = (e, t) => {
  if (!e || !(t != null && t.length))
    return;
  const n = t == null ? void 0 : t.find((r) => {
    var o, a;
    const s = r == null ? void 0 : r.email, i = (a = (o = s == null ? void 0 : s.split("@")) == null ? void 0 : o[1]) == null ? void 0 : a.toLowerCase();
    return !(e != null && e[i]);
  });
  return n == null ? void 0 : n.email;
}, ys = (e) => {
  var t, n, r, s;
  return ((n = (t = e == null ? void 0 : e.data) == null ? void 0 : t.error) == null ? void 0 : n.status) === 403 && ((s = (r = e == null ? void 0 : e.data) == null ? void 0 : r.error) == null ? void 0 : s.message) === "Kyc Request Expired";
}, bs = (e) => {
  let t = e == null ? void 0 : e.primary_investor_occupation;
  return t != null && t.includes(" ") && (t = t == null ? void 0 : t.replaceAll(" ", "_")), t == null ? void 0 : t.toLowerCase();
}, Ss = (e, t) => {
  if (!t)
    return e;
  try {
    const n = new URL(e), r = t, s = n.hostname.split(".");
    s.unshift(r);
    const i = s.join(".");
    return n.hostname = i, n.toString();
  } catch {
    return e;
  }
};
export {
  ls as $,
  Ee as A,
  ir as B,
  Br as C,
  ps as D,
  Ss as E,
  es as F,
  ts as G,
  as as H,
  qr as I,
  Kr as J,
  Gr as K,
  ss as L,
  v as M,
  _s as N,
  xr as O,
  Ir as P,
  cn as Q,
  yr as R,
  Cr as S,
  Ar as T,
  gs as U,
  hs as V,
  ms as W,
  is as X,
  cs as Y,
  bs as Z,
  dn as _,
  Rr as a,
  re as a$,
  _r as a0,
  Er as a1,
  Xe as a2,
  Hr as a3,
  br as a4,
  Sr as a5,
  Ur as a6,
  jr as a7,
  Vr as a8,
  Yr as a9,
  z as aA,
  w as aB,
  B as aC,
  ce as aD,
  J as aE,
  ue as aF,
  Kt as aG,
  Be as aH,
  Kn as aI,
  oe as aJ,
  Rt as aK,
  Jn as aL,
  Ye as aM,
  F as aN,
  Vn as aO,
  Pt as aP,
  st as aQ,
  V as aR,
  Rn as aS,
  yt as aT,
  Wn as aU,
  Un as aV,
  Mn as aW,
  L as aX,
  Xn as aY,
  Vt as aZ,
  se as a_,
  zr as aa,
  rs as ab,
  Wr as ac,
  Or as ad,
  Jr as ae,
  wr as af,
  Qr as ag,
  H as ah,
  P as ai,
  Ce as aj,
  ie as ak,
  b as al,
  _t as am,
  _ as an,
  le as ao,
  An as ap,
  fe as aq,
  Ae as ar,
  d as as,
  ot as at,
  G as au,
  Gn as av,
  y as aw,
  Bn as ax,
  T as ay,
  x as az,
  Pr as b,
  qe as b0,
  rn as b1,
  Ln as b2,
  vn as b3,
  kn as b4,
  jn as b5,
  ct as b6,
  Cn as b7,
  At as b8,
  Hn as b9,
  yn as bA,
  Sn as bB,
  hr as bC,
  ur as bD,
  pr as bE,
  mr as bF,
  Tr as bG,
  Lr as bH,
  ne as bI,
  $r as bJ,
  hn as bK,
  _n as bL,
  os as bM,
  us as bN,
  fs as bO,
  sr as bP,
  Yn as ba,
  zn as bb,
  On as bc,
  dt as bd,
  tr as be,
  $n as bf,
  mt as bg,
  Jt as bh,
  En as bi,
  xn as bj,
  Tn as bk,
  Ze as bl,
  et as bm,
  St as bn,
  Pn as bo,
  pt as bp,
  Zn as bq,
  In as br,
  Qn as bs,
  Nn as bt,
  Fn as bu,
  nr as bv,
  rr as bw,
  I as bx,
  qn as by,
  er as bz,
  kr as c,
  ln as d,
  ar as e,
  we as f,
  ns as g,
  lr as h,
  cr as i,
  or as j,
  un as k,
  fr as l,
  vr as m,
  Xr as n,
  Dr as o,
  Dn as p,
  Zr as q,
  ds as r,
  Mr as s,
  Ve as t,
  gr as u,
  Nr as v,
  dr as w,
  ys as x,
  Fr as y,
  fn as z
};
