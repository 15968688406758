const f = async (e, i) => {
  var s, o;
  const n = e == null ? void 0 : e.username;
  if (!n)
    return {
      isMigratedUser: !1
    };
  if ((s = e.annexure) != null && s.isonboarded && ((o = e.annexure) == null ? void 0 : o.isonboarded) === "true")
    return {
      isMigratedUser: !1
    };
  const { data: t } = await i.fpClient.mf_investment_accounts().fetchAll({
    investor: n.toUpperCase()
  });
  if (!t.length)
    return {
      isMigratedUser: !1
    };
  const a = t[0], { data: r } = await i.fpClient.mf_folios().fetchAll({
    mf_investment_account: a == null ? void 0 : a.id
  });
  return r.length ? {
    isMigratedUser: !0,
    migratedMfiaList: t,
    migratedFolioList: r
  } : {
    isMigratedUser: !1
  };
};
export {
  f as c
};
