import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as fe, useState as q, useEffect as Ve, useRef as He, useLayoutEffect as Ot, useMemo as Ue, useCallback as Mt, memo as Lt } from "react";
import { a as rt, P as nt, D as Oe, e as X, T as ge, M as P, B as Me, k as We, i as $t, j as _t, l as mt, s as zt, m as Zt, R as Xt, S as Wt, c as ft } from "./index.esm-CqWfms5D.js";
import { w as Je, x as me, W as Ht, o as pe, p as jt, X as et, q as Jt, m as Ee, s as at, r as Qt, k as ht, Y as vt, u as Gt, f as Yt, e as wt, B as Kt, Z as kt } from "./utils-C2gHLhxV.js";
import { F as Ie } from "./provider-DKLaKIVD.js";
import { r as M } from "./sentry-CstOwvcR.js";
import { u as Ft } from "./useConfiguration-B-lyaBB9.js";
import { i as n } from "./index-CrDCxF0S.js";
import { AppContext as it } from "./AppContext.js";
import { B as Dt } from "./BankAccountAddition-BHWGDVl9.js";
import { R as lt, F as W } from "./react-final-form.es-CUo9SHJd.js";
import { f as le, b as er, r as G, p as tr, i as xt, j as pt, q as rr, l as gt, t as nr, u as ar, w as ir, x as lr } from "./validators-BNinzCrT.js";
import { o as bt } from "./redirection-Dil_BQsn.js";
import { ErrorPage as or } from "./ErrorPage.js";
const Fr = ({ user: o, onCompletion: w, folioDetails: re, isGroupInvestment: T = !1, isPartner: J, kycDetails: f, isKycCompliant: a, isKycOnly: U }) => {
  var he;
  const { fpapi: O } = fe(Ie), { tenantConfig: V } = Ft(), { AppState: { tenant: c } } = fe(it), [d, N] = q(null), [S, I] = q(void 0), [F, H] = q(!1), [C, A] = q(null), j = Je(o == null ? void 0 : o.annexure), Re = !a && ((he = V == null ? void 0 : V.app_constants) == null ? void 0 : he.cancelled_cheque_required);
  Ve(() => {
    o && (async () => {
      var E;
      try {
        H(!0), A(null);
        const [B, R] = await Promise.all([
          O.fpClient.investor_profiles().fetch(o == null ? void 0 : o.profile),
          O.fpClient.bank_accounts().fetchAll({
            profile: o == null ? void 0 : o.profile
          })
        ]), K = (E = R == null ? void 0 : R.data) == null ? void 0 : E[0];
        K && I(K), N(B);
      } catch (B) {
        window.debug.error(B), M(B), A(n._({
          id: "J56bGZ"
        }));
      } finally {
        H(!1);
      }
    })();
  }, [
    o == null ? void 0 : o.profile
  ]);
  async function Y(E) {
    if (J && !(E != null && E.cancelled_cheque))
      return;
    const B = {
      bank_account: {
        account_holder_name: f == null ? void 0 : f.name,
        account_number: E == null ? void 0 : E.account_number,
        ifsc_code: E == null ? void 0 : E.ifsc_code,
        proof: E == null ? void 0 : E.cancelled_cheque
      }
    };
    return O.fpClient.kyc_requests().update(f == null ? void 0 : f.id, B);
  }
  async function Ae(E) {
    const B = o.id, R = {
      ...j,
      completed_step: "banking_details"
    };
    E && Object.assign(R, E);
    const K = await O.updateUser(B, R, null, c);
    if (K)
      return K;
    throw new Error(K);
  }
  const be = async ({ success: E, bankAccount: B }) => {
    E || (window.debug.error("ERROR UPDATING BANK ACCOUNT DETAILS"), A(n._({
      id: "qMcu4/"
    })));
    try {
      if (H(!0), A(null), U) {
        const R = await Y(B);
        w({
          updatedKycDetails: R
        });
      } else if (a)
        await Ae(J ? {
          partner_completed_step: "banking_details"
        } : void 0), w();
      else {
        const R = await Y(B);
        await Ae(J ? {
          completed_step: R ? "banking_details" : "financial_details",
          partner_completed_step: "contact_details"
        } : void 0), w({
          updatedKycDetails: R
        });
      }
    } catch (R) {
      window.debug.error(R), M(R), me(R) && window.location.reload(), A(n._({
        id: "qMcu4/"
      }));
    } finally {
      H(!1);
    }
  }, Be = async () => {
    try {
      H(!0), A(null);
      const E = o.id, B = o == null ? void 0 : o.annexure;
      J ? B.partner_completed_step = "personal_details" : B.completed_step = "personal_details", await O.updateUser(E, B, null, c), w();
    } catch (E) {
      window.debug.error(E), M(E), A(n._({
        id: "qMcu4/"
      }));
    } finally {
      H(!1);
    }
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      F && /* @__PURE__ */ e.jsx(rt, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(Dt, {
        investorProfile: d,
        onCompletion: be,
        isPartner: J,
        kycDetails: f,
        needsCancelledCheque: Re,
        isGroupInvestment: T,
        formInitialValues: S,
        handleGoBack: U ? void 0 : Be,
        formError: C,
        folioDetails: re
      })
    ]
  });
}, dr = () => {
  const { fpapi: o } = fe(Ie), w = (f) => f.sort((a, U) => Number(new Date(U.updated_at)) - Number(new Date(a.updated_at)));
  function re(f, a) {
    return (f == null ? void 0 : f.cancelled_cheque) && (a == null ? void 0 : a.status) === "completed" && (a == null ? void 0 : a.reason) === "digital_verification" && (a == null ? void 0 : a.confidence) === "zero";
  }
  function T(f, a) {
    return !(f != null && f.cancelled_cheque) && // no cancelled cheque
    (a == null ? void 0 : a.status) === "completed" && // verification status is complete
    (a == null ? void 0 : a.reason) === "digital_verification" && // reason is digital_verification
    (a == null ? void 0 : a.confidence) === "zero";
  }
  async function J(f) {
    if (f) {
      const a = [], U = [], O = [], V = [], c = [], d = [], N = f == null ? void 0 : f.map((I) => {
        const F = I.identifier ? String(I.identifier) : String(I.id);
        return o.fpClient.bank_account_verifications().fetchAll({
          bank_accounts: [
            F
          ]
        });
      });
      return (await Promise.allSettled(N)).forEach((I, F) => {
        var H;
        if (I.status === "fulfilled") {
          const C = {
            ...f[F]
          }, A = (H = I == null ? void 0 : I.value) == null ? void 0 : H.data[0];
          A ? A.status === "completed" ? re(C, A) ? O.push(C) : T(C, A) ? V.push(C) : a.push(C) : A.status === "pending" ? U.push(C) : A.status === "failed" && c.push(C) : d.push(C);
        } else if (I.status === "rejected")
          throw window.debug.error(I), I;
      }), {
        COMPLETE: [
          ...w(a)
        ],
        PENDING: [
          ...w(U)
        ],
        MANUAL_VERIFICATION_PENDING: [
          ...w(O)
        ],
        COLLECT_CANCELLED_CHEQUE: [
          ...w(V)
        ],
        FAILED: [
          ...w(c)
        ],
        NOVERIFICATION: [
          ...w(d)
        ]
      };
    } else
      throw new Error(n._({
        id: "VhBqMw"
      }));
  }
  return {
    getBankAccountsByVerificationStatus: J
  };
}, Cr = ({ user: o, onCompletion: w, folioDetails: re, kycCheckData: T, isPartner: J, isGroupInvestment: f, kycDetails: a, isKycCompliant: U, isKycOnly: O }) => {
  var ut;
  const V = He(null), c = o == null ? void 0 : o.id, d = Je(o == null ? void 0 : o.annexure), N = !O && f, { tenantConfig: S } = Ft(), I = (ut = S == null ? void 0 : S.groupInvestment) == null ? void 0 : ut.allowedEmailDomains, { fpapi: F } = fe(Ie), { AppState: { tenant: H } } = fe(it), [C, A] = q(null), [j, Re] = q(null), [Y, Ae] = q(null), [be, Be] = q(null), [he, E] = q(null), [B, R] = q(null), [K, L] = q(null), [Qe, $] = q(!0), [Le, je] = q(!1), [Ge, $e] = q(!1), [v, _] = q(null), i = {}, { getBankAccountsByVerificationStatus: g } = dr(), [z, se] = q({
    address_proof_back: null,
    address_proof_front: null
  }), Z = He({
    address_proof_back: !1,
    address_proof_front: !1
  }), oe = {}, Pe = {
    saveAddressProofSelection(r) {
      sessionStorage.setItem("address_proof", r);
    },
    getAddressProofSelection() {
      return sessionStorage.getItem("address_proof");
    },
    deleteAddressProofSelection() {
      sessionStorage.removeItem("address_proof");
    }
  }, Ye = () => {
    var t, s;
    let r = null;
    if (T) {
      const u = (t = T == null ? void 0 : T.entity_details) == null ? void 0 : t.correspondence_address, h = et(u == null ? void 0 : u.line_1, u == null ? void 0 : u.line_2, u == null ? void 0 : u.line_3);
      r = {
        // current addresses
        current_pincode: u != null && u.pincode ? u == null ? void 0 : u.pincode : null,
        current_residence_line1: h,
        email: (s = T == null ? void 0 : T.entity_details) == null ? void 0 : s.email
      };
    }
    return r;
  };
  Ve(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const ze = () => {
    je(!1);
  }, ce = () => {
    je(!1), $e(!0);
  };
  async function Ze() {
    if (a) {
      Qt.removeRedirectionState();
      const { data: r } = await F.fpClient.identity_documents().fetchAll({
        kyc_request: a == null ? void 0 : a.id
      }), t = r != null && r.length ? r[0] : null;
      return t && _(t), t;
    }
  }
  Ve(() => {
    (async () => {
      $(!0);
      try {
        await Ze();
      } catch (r) {
        window.debug.error(r), M(r), L(n._({
          id: "P/x4dd"
        })), $(!1);
        return;
      }
    })();
  }, [
    a
  ]), Ot(() => {
    (async () => {
      var r;
      try {
        $(!0);
        let t, s, u;
        if (o) {
          const x = await F.fpClient.investor_profiles().fetch(o == null ? void 0 : o.profile);
          t = await F.fpClient.email_addresses().fetchAll({
            profile: x == null ? void 0 : x.id
          }), s = await F.fpClient.phone_numbers().fetchAll({
            profile: x == null ? void 0 : x.id
          }), u = await F.fpClient.addresses().fetchAll({
            profile: x == null ? void 0 : x.id
          });
        }
        const { countries: h } = await F.fpClient.master_data().fetchCountries();
        if (Nt(a)) {
          const x = [
            dt(a.address.proof),
            dt(a.address.proof_back)
          ], [p, b] = await Promise.all(x);
          Be(p == null ? void 0 : p.file), E(b == null ? void 0 : b.file), se({
            address_proof_back: b == null ? void 0 : b.fileObj,
            address_proof_front: p == null ? void 0 : p.fileObj
          });
        }
        A(t == null ? void 0 : t.data), Re(s == null ? void 0 : s.data[0]), Ae((r = u == null ? void 0 : u.data) != null && r.length ? u == null ? void 0 : u.data : null), R(h);
      } catch (t) {
        window.debug.error(t), L(n._({
          id: "J56bGZ"
        })), M(t);
      } finally {
        $(!1);
      }
    })();
  }, [
    F == null ? void 0 : F.fpClient,
    o == null ? void 0 : o.profile
  ]);
  const ot = {
    "image/jpeg": 1,
    "image/png": 1,
    "image/jpg": 1,
    "application/pdf": 1
  }, yt = Ue(() => ({
    driving_licence: 1,
    passport: 1
  }), []), Se = Ue(() => [
    {
      label: n._({
        id: "mzsu4o"
      }),
      value: "aadhaar"
    },
    {
      label: n._({
        id: "nk8msg"
      }),
      value: "driving_licence"
    },
    {
      label: n._({
        id: "gFyr5x"
      }),
      value: "passport"
    },
    {
      label: n._({
        id: "Lp31ma"
      }),
      value: "voter_id"
    }
  ], []), Nt = (r) => [
    "proof",
    "proof_back"
  ].every((s) => {
    var u;
    return (u = r == null ? void 0 : r.address) == null ? void 0 : u[s];
  }), dt = async (r) => {
    const t = await F.fpClient.files().fetch(r), s = t.url, h = await (await fetch(s)).blob();
    return {
      file: new File([
        h
      ], t == null ? void 0 : t.filename, {
        type: h.type
      }),
      fileObj: t
    };
  }, de = Ue(() => {
    var h, x, p, b, Q, ne, ue, l, y, ae, we, Fe, D, ee, Ce, ye, qe, ve, te;
    const r = Ye();
    let t = {
      //perm_addr_is_corres_addr: "Yes",
      email: f ? Ht(I, C) : (h = C == null ? void 0 : C[0]) == null ? void 0 : h.email,
      phone: j == null ? void 0 : j.number,
      current_pincode: null,
      current_residence_line1: null,
      current_residence_line2: null,
      current_residence_line3: null,
      permanent_pincode: null,
      permanent_residence_line1: null,
      permanent_residence_line2: null,
      permanent_residence_line3: null
    };
    if (O && (t.email = a == null ? void 0 : a.email, t.phone = (x = a == null ? void 0 : a.mobile) == null ? void 0 : x.number), r && (t.current_pincode = (r == null ? void 0 : r.current_pincode) || t.current_pincode, t.current_residence_line1 = (r == null ? void 0 : r.current_residence_line1) || t.current_residence_line1, t.current_residence_line2 = (r == null ? void 0 : r.current_residence_line2) || t.current_residence_line2, t.current_residence_line3 = (r == null ? void 0 : r.current_residence_line3) || t.current_residence_line3, t.permanent_pincode = (r == null ? void 0 : r.permanent_pincode) || t.permanent_pincode, t.permanent_residence_line1 = (r == null ? void 0 : r.permanent_residence_line1) || t.permanent_residence_line1, t.permanent_residence_line2 = (r == null ? void 0 : r.permanent_residence_line2) || t.permanent_residence_line2, t.permanent_residence_line3 = (r == null ? void 0 : r.permanent_residence_line3) || t.permanent_residence_line3, t.email = r.email || t.email), a) {
      const m = {
        email: {
          initFieldName: "email",
          initFieldValue: (f ? null : a == null ? void 0 : a.email) || (t == null ? void 0 : t.email)
        },
        number: {
          initFieldName: "phone",
          initFieldValue: ((p = a == null ? void 0 : a.mobile) == null ? void 0 : p.number) || (t == null ? void 0 : t.phone)
        },
        pincode: {
          initFieldName: "current_pincode",
          initFieldValue: ((b = a == null ? void 0 : a.address) == null ? void 0 : b.pincode) || (t == null ? void 0 : t.current_pincode)
        },
        line_1: {
          initFieldName: "current_residence_line1",
          initFieldValue: ((Q = a == null ? void 0 : a.address) == null ? void 0 : Q.line_1) || (t == null ? void 0 : t.current_residence_line1)
        },
        line_2: {
          initFieldName: "current_residence_line2",
          initFieldValue: ((ne = a == null ? void 0 : a.address) == null ? void 0 : ne.line_2) || (t == null ? void 0 : t.current_residence_line2)
        },
        line_3: {
          initFieldName: "current_residence_line3",
          initFieldValue: ((ue = a == null ? void 0 : a.address) == null ? void 0 : ue.line_3) || (t == null ? void 0 : t.current_residence_line3)
        },
        proof_type: {
          initFieldName: "address_proof_type",
          initFieldValue: Se[pe(Se, (l = a == null ? void 0 : a.address) == null ? void 0 : l.proof_type)]
        },
        proof_number: {
          initFieldName: "address_proof_number",
          initFieldValue: (y = a == null ? void 0 : a.address) == null ? void 0 : y.proof_number
        },
        proof_issue_date: {
          initFieldName: "address_proof_issue_date",
          initFieldValue: (ae = a == null ? void 0 : a.address) == null ? void 0 : ae.proof_issue_date
        },
        proof_expiry_date: {
          initFieldName: "address_proof_expiry_date",
          initFieldValue: (we = a == null ? void 0 : a.address) == null ? void 0 : we.proof_expiry_date
        }
      };
      ((Fe = a == null ? void 0 : a.address) == null ? void 0 : Fe.proof_type) !== "aadhaar" && (m.aadhaar_number = {
        initFieldName: "aadhaar_number",
        initFieldValue: (a == null ? void 0 : a.aadhaar_number) || null
      });
      const ie = jt(m, a);
      t.address_proof_type !== "aadhaar" && be && he && (t.address_proof_front = be, t.address_proof_back = he), t = {
        ...t,
        ...ie
      };
    }
    const s = Y != null && Y.length ? Y[(Y == null ? void 0 : Y.length) - 1] : null;
    if (s && !O && (t.current_pincode = (s == null ? void 0 : s.postal_code) || (t == null ? void 0 : t.current_pincode), t.current_residence_line1 = (s == null ? void 0 : s.line1) || (t == null ? void 0 : t.current_residence_line1), t.current_residence_line2 = (s == null ? void 0 : s.line2) || (t == null ? void 0 : t.current_residence_line2), t.current_residence_line3 = (s == null ? void 0 : s.line3) || (t == null ? void 0 : t.current_residence_line3)), v && (Pe.getAddressProofSelection() === "aadhaar" || [
      null,
      void 0,
      "aadhaar"
    ].includes((D = a == null ? void 0 : a.address) == null ? void 0 : D.proof_type))) {
      const ie = ((ee = v == null ? void 0 : v.fetch) == null ? void 0 : ee.status) === "successful" && ((Ce = v == null ? void 0 : v.data) == null ? void 0 : Ce.pincode) || "", Ne = et((ye = v == null ? void 0 : v.data) == null ? void 0 : ye.line_1, (qe = v == null ? void 0 : v.data) == null ? void 0 : qe.line_2, (ve = v == null ? void 0 : v.data) == null ? void 0 : ve.line_3);
      t.address_proof_type = Se[pe(Se, "aadhaar")], t.current_pincode = ie, t.current_residence_line1 = Ne, N && (C != null && C.length) && (t.email = (te = C == null ? void 0 : C[0]) == null ? void 0 : te.email);
    }
    return t;
  }, [
    C,
    j == null ? void 0 : j.number,
    Se,
    a,
    v,
    he,
    be
  ]), Et = async (r) => {
    var u;
    const t = [], s = a.pan;
    for (let h = 0; h < r.length; h++) {
      const x = r[h].purpose;
      try {
        const p = r[h].value, b = (u = p == null ? void 0 : p.type) == null ? void 0 : u.split("/")[1], Q = new FormData();
        Q.append("file", p, `${s}_${x}.${b}`), Q.append("purpose", x), t.push(F.fpClient.files().create(Q));
      } catch (p) {
        window.debug.error(p), t.push(Promise.reject(p)), M(p);
      }
    }
    return t;
  }, At = (r) => {
    const t = {
      address_proof_front: "",
      address_proof_back: ""
    };
    for (let s = 0; s < r.length; s++)
      r[s].status === "fulfilled" ? t[`${r[s].value.purpose}`] = r[s].value.id : r[s].reason;
    return t;
  };
  async function Bt(r) {
    var h;
    const t = await Et(r), s = await Promise.allSettled(t), u = At(s);
    if ((h = Object.keys(i)) != null && h.length)
      throw i;
    return u;
  }
  async function Xe(r) {
    return F.fpClient.kyc_requests().update(a.id, r);
  }
  async function Pt(r) {
    return F.fpClient.master_data().fetchPincode(r);
  }
  function st(r) {
    const { profile: t, line1: s, line2: u, line3: h, city: x, state: p, postal_code: b, country: Q } = r ?? {};
    return `${t ?? ""}${s ?? ""}${u ?? ""}${h ?? ""}${x ?? ""}${p ?? ""}${b ?? ""}${Q ?? ""}`.toLowerCase();
  }
  async function Ke(r) {
    if (!(r != null && r.state)) {
      const u = await Pt(r.postal_code);
      r.state = u == null ? void 0 : u.state_name;
    }
    const t = st(r), s = Y == null ? void 0 : Y.find((u) => st(u) === t);
    return s || F.fpClient.addresses().create(r);
  }
  async function ct(r) {
    if (r && (C != null && C.length)) {
      const t = C == null ? void 0 : C.find((u) => (u == null ? void 0 : u.email) === r);
      return t || F.fpClient.email_addresses().create({
        profile: o == null ? void 0 : o.profile,
        email: r,
        belongs_to: "self"
      });
    } else
      return;
  }
  async function ke(r) {
    const t = {
      ...d,
      completed_step: "contact_details",
      ...r
    }, s = await F.updateUser(c, t, null, H);
    if (s)
      return s;
    throw new Error(s);
  }
  async function St() {
    var u, h, x, p;
    const r = await Ze(), t = (u = r == null ? void 0 : r.fetch) == null ? void 0 : u.status;
    if (!(t === "successful" || t === "pending")) {
      const b = await F.fpClient.identity_documents().create({
        kyc_request: a == null ? void 0 : a.id,
        type: "aadhaar",
        postback_url: `${window.location.origin + Kt}/redirection`
      }), Q = (h = b == null ? void 0 : b.fetch) == null ? void 0 : h.status, ne = (x = b == null ? void 0 : b.fetch) == null ? void 0 : x.redirect_url;
      if (_(b), Q === "pending" && ne)
        je(!0), bt(ne, "kyc-identity-document", ze, ce);
      else
        throw b;
    } else if (t === "pending") {
      const b = (p = r == null ? void 0 : r.fetch) == null ? void 0 : p.redirect_url;
      je(!0), bt(b, "kyc-identity-document", ze, ce);
    }
  }
  const Tt = async (r) => {
    var t, s;
    $(!0);
    try {
      if (N && !await ct(r))
        throw new Error("Error updating email");
      try {
        await St();
      } catch (u) {
        L((s = (t = u == null ? void 0 : u.data) == null ? void 0 : t.error) == null ? void 0 : s.message), vt();
      }
    } catch {
      L(n._({
        id: "PsUTl7"
      })), vt();
    }
    $(!1);
  }, De = async (r, t, s, u) => {
    var y, ae;
    const h = r == null ? void 0 : r.profile, x = Je(r == null ? void 0 : r.annexure), p = await F.fpClient.bank_accounts().fetchAll({
      profile: h
    }), b = await g(p.data), Q = (ae = (y = b == null ? void 0 : b.COMPLETE) == null ? void 0 : y[0]) == null ? void 0 : ae.id, ne = (x == null ? void 0 : x.verified_bac) || Q, ue = {
      primary_investor: h,
      holding_pattern: "single",
      folio_defaults: {
        communication_email_address: t,
        communication_mobile_number: s,
        communication_address: u,
        payout_bank_account: ne || null
      }
    }, l = await Vt(r);
    if (l)
      return await F.fpClient.mf_investment_accounts().update({
        id: l == null ? void 0 : l.id,
        folio_defaults: ue.folio_defaults
      });
  }, Vt = async (r) => {
    var p;
    const t = (p = r == null ? void 0 : r.username) == null ? void 0 : p.toUpperCase(), s = r == null ? void 0 : r.profile;
    let u;
    const h = await F.fpClient.mf_investment_accounts().fetchAll({
      investor: t
    }), x = h == null ? void 0 : h.data;
    return x != null && x.length && (u = x.find((b) => b.primary_investor === s)), u;
  }, It = async ({ email: r, current_residence_line1: t, current_residence_line2: s, current_residence_line3: u, current_pincode: h, current_location: x, address_proof_type: p, address_proof_number: b, address_proof_issue_date: Q, address_proof_expiry_date: ne, address_proof_front: ue, address_proof_back: l, aadhaar_number: y }) => {
    var Ce, ye, qe;
    const [ae, we, Fe] = (x == null ? void 0 : x.split(", ")) || [], D = {
      profile: o == null ? void 0 : o.profile,
      city: ae,
      state: we,
      postal_code: h,
      country: Fe,
      line1: t || null,
      line2: s || null,
      line3: u || null
    }, ee = await ct(r);
    //! Add try catch
    if (U)
      try {
        $(!0);
        const ve = await Ke(D);
        await De(o, ee == null ? void 0 : ee.id, j == null ? void 0 : j.id, ve.id), await ke({
          partner_completed_step: "contact_details"
        }), w();
      } catch (ve) {
        window.debug.error(ve), L(n._({
          id: "qMcu4/"
        })), M(ve);
      } finally {
        $(!1);
      }
    else if ((p == null ? void 0 : p.value) === "aadhaar")
      try {
        if ($(!0), ((Ce = v == null ? void 0 : v.fetch) == null ? void 0 : Ce.status) === "successful") {
          const { data: m, id: ie } = v, Ne = {
            email: r,
            aadhaar_number: m == null ? void 0 : m.number.slice(-4),
            address: {
              proof_type: "aadhaar",
              proof: ie,
              line_1: m == null ? void 0 : m.line_1,
              line_2: m == null ? void 0 : m.line_2,
              line_3: m == null ? void 0 : m.line_3,
              city: m == null ? void 0 : m.city,
              pincode: m == null ? void 0 : m.pincode,
              country: m == null ? void 0 : m.country
            }
          };
          if (O)
            try {
              const _e = await Xe(Ne);
              w({
                updatedKycDetails: _e
              });
            } catch (_e) {
              window.debug.error(_e), $(!1), L(n._({
                id: "qMcu4/"
              })), M(_e), me(_e) && window.location.reload();
              return;
            }
          else {
            const _e = {
              profile: o == null ? void 0 : o.profile,
              city: m == null ? void 0 : m.city,
              state: null,
              postal_code: m == null ? void 0 : m.pincode,
              country: m == null ? void 0 : m.country.toUpperCase(),
              line1: m == null ? void 0 : m.line_1,
              line2: (m == null ? void 0 : m.line_2) || null,
              line3: (m == null ? void 0 : m.line_3) || null
            };
            try {
              const xe = [
                Xe(Ne),
                Ke(_e)
              ], [k, Te] = await Promise.all(xe);
              await De(o, ee == null ? void 0 : ee.id, j == null ? void 0 : j.id, Te.id), await ke(), w({
                updatedKycDetails: k
              });
            } catch (xe) {
              window.debug.error(xe), $(!1), L(n._({
                id: "qMcu4/"
              })), M(xe), me(xe) && window.location.reload();
              return;
            }
          }
        } else
          throw new Error("identity document is not ready");
      } catch (te) {
        window.debug.error(te), L(n._({
          id: "qMcu4/"
        })), M(te), me(te) && window.location.reload();
      } finally {
        $(!1);
      }
    else
      try {
        $(!0);
        const te = [
          {
            purpose: "address_proof_front",
            value: ue
          },
          {
            purpose: "address_proof_back",
            value: l
          }
        ].filter((k) => {
          var Te;
          return !((Te = Z == null ? void 0 : Z.current) != null && Te[k.purpose]);
        }), m = await Bt(te), ie = {
          address_proof_front: (ye = z == null ? void 0 : z.address_proof_front) == null ? void 0 : ye.id,
          address_proof_back: (qe = z == null ? void 0 : z.address_proof_back) == null ? void 0 : qe.id
        }, Ne = [
          "voter_id",
          "aadhaar"
        ], _e = {
          line_1: D.line1,
          line_2: D.line2,
          line_3: D.line3,
          city: D.city,
          pincode: D.postal_code,
          country: D.country.toLowerCase(),
          proof: (m == null ? void 0 : m.address_proof_front) || (ie == null ? void 0 : ie.address_proof_front),
          proof_back: (m == null ? void 0 : m.address_proof_back) || (ie == null ? void 0 : ie.address_proof_back),
          proof_type: p.value,
          proof_number: b,
          proof_issue_date: Ne.includes(p.value) ? null : ht(Q, "yyyy-mm-dd"),
          proof_expiry_date: Ne.includes(p.value) ? null : ht(ne, "yyyy-mm-dd")
        }, xe = {
          email: r,
          aadhaar_number: y,
          address: _e
        };
        if (O)
          try {
            const k = await Xe(xe);
            w({
              updatedKycDetails: k
            });
          } catch (k) {
            window.debug.error(k), L(n._({
              id: "qMcu4/"
            })), M(k), me(k) && window.location.reload();
          }
        else
          try {
            const k = [
              Xe(xe),
              Ke(D)
            ], [Te, Ut] = await Promise.all(k);
            await De(o, ee == null ? void 0 : ee.id, j == null ? void 0 : j.id, Ut.id), await ke(), w({
              updatedKycDetails: Te
            });
          } catch (k) {
            window.debug.error(k), L(n._({
              id: "qMcu4/"
            })), M(k), me(k) && window.location.reload();
          }
      } catch (te) {
        window.debug.error(te), L(n._({
          id: "tWA+Oc"
        })), M(te), me(te) && window.location.reload();
      } finally {
        $(!1);
      }
    Pe.deleteAddressProofSelection();
  }, Rt = (r) => {
    var s, u;
    const t = (u = (s = r == null ? void 0 : r.values) == null ? void 0 : s.address_proof_type) == null ? void 0 : u.label;
    return U ? /* @__PURE__ */ e.jsx(tt, {
      countries: B,
      addressType: "current",
      formRef: V,
      kycCheckData: T,
      kycDetails: a,
      addressProofTypeLabel: "Communication address"
    }) : oe.address ? null : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(X, {
            message: n._({
              id: "FBMtAv"
            })
          }),
          /* @__PURE__ */ e.jsx(W, {
            name: "address_proof_type",
            validate: G,
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            validateFields: [],
            children: (h) => {
              var x, p, b, Q, ne, ue;
              return /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(We, {
                    name: h.input.name,
                    value: h.input.value,
                    onChange: (l) => {
                      var ae, we, Fe, D;
                      L(""), Pe.saveAddressProofSelection((l == null ? void 0 : l.value) ?? "");
                      const y = () => {
                        r.form.mutators.mutateField("current_pincode", void 0), r.form.mutators.mutateField("current_residence_line1", void 0), r.form.mutators.mutateField("current_residence_line2", void 0), r.form.mutators.mutateField("current_residence_line3", void 0), r.form.mutators.mutateField("address_proof_number", void 0), r.form.mutators.mutateField("address_proof_issue_date", void 0), r.form.mutators.mutateField("address_proof_expiry_date", void 0), r.form.mutators.mutateField("address_proof_front", void 0), r.form.mutators.mutateField("address_proof_back", void 0), r.form.mutators.mutateField("aadhaar_number", void 0), V.current.change("current_location", void 0);
                      };
                      if ((l == null ? void 0 : l.value) === "aadhaar" && v) {
                        const ee = et((ae = v == null ? void 0 : v.data) == null ? void 0 : ae.line_1, (we = v == null ? void 0 : v.data) == null ? void 0 : we.line_2, (Fe = v == null ? void 0 : v.data) == null ? void 0 : Fe.line_3), Ce = ((D = v == null ? void 0 : v.data) == null ? void 0 : D.pincode) || (de == null ? void 0 : de.current_pincode), ye = ee || (de == null ? void 0 : de.current_pincode);
                        r.form.mutators.mutateField("current_pincode", Ce), r.form.mutators.mutateField("current_residence_line1", ye);
                      } else
                        y();
                      h.input.onChange(l);
                    },
                    options: Se,
                    status: h.meta.error && h.meta.touched ? "error" : void 0,
                    placeholder: "Select address proof type"
                  }),
                  (x = h.input.value) != null && x.value ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx($t, {
                    variant: "note",
                    message: "Provide communication address along with proof documents"
                  }),
                  h.meta.error && h.meta.touched && /* @__PURE__ */ e.jsx(P, {
                    type: "error",
                    variant: "field",
                    title: h.meta.error
                  }),
                  ((p = h.input.value) == null ? void 0 : p.value) !== "aadhaar" ? /* @__PURE__ */ e.jsx(e.Fragment, {
                    children: /* @__PURE__ */ e.jsxs("div", {
                      className: "mt-8",
                      children: [
                        /* @__PURE__ */ e.jsx(tt, {
                          countries: B,
                          addressType: "current",
                          formRef: V,
                          kycCheckData: T,
                          kycDetails: a,
                          addressProofTypeLabel: t
                        }),
                        /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            oe.address ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8",
                              children: [
                                /* @__PURE__ */ e.jsx(X, {
                                  message: n._({
                                    id: "YYnvo5",
                                    values: {
                                      addressProofTypeLabel: t
                                    }
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(W, {
                                  name: "address_proof_number",
                                  parse: (l) => l.toUpperCase(),
                                  validate: le(G, tr((b = h.input.value) == null ? void 0 : b.value, n._({
                                    id: "UqiTgL"
                                  }))),
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0
                                  },
                                  validateFields: [],
                                  children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                    children: [
                                      /* @__PURE__ */ e.jsx(ge, {
                                        id: l.input.name,
                                        autoComplete: "off",
                                        name: l.input.name,
                                        value: l.input.value,
                                        onChange: l.input.onChange,
                                        onBlur: () => {
                                          l.input.onChange(l.input.value.trim());
                                        },
                                        onInput: (y) => {
                                          y.target.value = y.target.value.replace(Ee("A-Za-z0-9 \\-"), "");
                                        },
                                        maxLength: 16,
                                        status: l.meta.error && l.meta.touched ? "error" : void 0
                                      }),
                                      l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(P, {
                                        type: "error",
                                        variant: "field",
                                        title: l.meta.error
                                      })
                                    ]
                                  })
                                })
                              ]
                            }) : null,
                            oe.address ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8",
                              children: [
                                /* @__PURE__ */ e.jsx(X, {
                                  message: n._({
                                    id: "poOg/l",
                                    values: {
                                      addressProofTypeLabel: t
                                    }
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(Oe, {
                                  type: "labelDesc",
                                  text: n._({
                                    id: "S+MkRj"
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(W, {
                                  name: "address_proof_front",
                                  validate: le(G, pt(5, n._({
                                    id: "Jw6xX2"
                                  })), xt(ot, n._({
                                    id: "ULNkDS"
                                  }))),
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0,
                                    pristine: !0
                                  },
                                  validateFields: [],
                                  children: (l) => {
                                    var y;
                                    return Z.current = {
                                      ...Z.current,
                                      address_proof_front: ((y = l == null ? void 0 : l.meta) == null ? void 0 : y.pristine) ?? !1
                                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                                      children: [
                                        /* @__PURE__ */ e.jsx(_t, {
                                          inputName: "address_proof_front",
                                          customClass: "customClassName",
                                          handleFile: l.input.onChange,
                                          value: l.input.value
                                        }),
                                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx(P, {
                                          type: "error",
                                          variant: "field",
                                          title: l.meta.error
                                        }) : /* @__PURE__ */ e.jsx(P, {
                                          type: "helper",
                                          variant: "field",
                                          title: n._({
                                            id: "OIjqUC"
                                          })
                                        })
                                      ]
                                    });
                                  }
                                })
                              ]
                            }) : null,
                            oe.address ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8",
                              children: [
                                /* @__PURE__ */ e.jsx(X, {
                                  message: n._({
                                    id: "MQgLMg",
                                    values: {
                                      addressProofTypeLabel: t
                                    }
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(Oe, {
                                  type: "labelDesc",
                                  text: n._({
                                    id: "kbnQeU"
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(W, {
                                  name: "address_proof_back",
                                  validate: le(G, pt(5, n._({
                                    id: "Jw6xX2"
                                  })), xt(ot, n._({
                                    id: "ULNkDS"
                                  }))),
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0,
                                    pristine: !0
                                  },
                                  validateFields: [],
                                  children: (l) => {
                                    var y;
                                    return Z.current = {
                                      ...Z.current,
                                      address_proof_back: ((y = l == null ? void 0 : l.meta) == null ? void 0 : y.pristine) ?? !1
                                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                                      children: [
                                        /* @__PURE__ */ e.jsx(_t, {
                                          inputName: "address_proof_back",
                                          customClass: "customClassName",
                                          handleFile: l.input.onChange,
                                          value: l.input.value
                                        }),
                                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx(P, {
                                          type: "error",
                                          variant: "field",
                                          title: l.meta.error
                                        }) : /* @__PURE__ */ e.jsx(P, {
                                          type: "helper",
                                          variant: "field",
                                          title: n._({
                                            id: "OIjqUC"
                                          })
                                        })
                                      ]
                                    });
                                  }
                                })
                              ]
                            }) : null,
                            yt[(Q = h.input.value) == null ? void 0 : Q.value] && /* @__PURE__ */ e.jsxs(e.Fragment, {
                              children: [
                                oe.address ? null : /* @__PURE__ */ e.jsxs("div", {
                                  className: "mb-8",
                                  children: [
                                    /* @__PURE__ */ e.jsx(X, {
                                      message: n._({
                                        id: "cLP1gb",
                                        values: {
                                          addressProofTypeLabel: t
                                        }
                                      })
                                    }),
                                    /* @__PURE__ */ e.jsx(W, {
                                      name: "address_proof_issue_date",
                                      validate: le(G, gt, rr(n._({
                                        id: "DsBANV"
                                      }))),
                                      subscription: {
                                        touched: !0,
                                        error: !0,
                                        value: !0
                                      },
                                      validateFields: [],
                                      children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                        children: [
                                          /* @__PURE__ */ e.jsx(mt, {
                                            name: l.input.name,
                                            variant: "keyboardInputDOB",
                                            format: "dd-MMM-yyyy",
                                            placeholder: n._({
                                              id: "cJxwfA"
                                            }),
                                            selectedDate: l.input.value || null,
                                            error: l.meta.error && l.meta.touched,
                                            helperText: null,
                                            onKeyDown: (y) => y.preventDefault(),
                                            handleDateChange: (y, ae) => l.input.onChange(y)
                                          }),
                                          l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(P, {
                                            type: "error",
                                            variant: "field",
                                            title: l.meta.error
                                          })
                                        ]
                                      })
                                    })
                                  ]
                                }),
                                oe.address ? null : /* @__PURE__ */ e.jsxs("div", {
                                  className: "mb-8",
                                  children: [
                                    /* @__PURE__ */ e.jsx(X, {
                                      message: n._({
                                        id: "t2Bz9f",
                                        values: {
                                          addressProofTypeLabel: t
                                        }
                                      })
                                    }),
                                    /* @__PURE__ */ e.jsx(W, {
                                      name: "address_proof_expiry_date",
                                      validate: le(G, gt, nr(n._({
                                        id: "p11m02"
                                      }))),
                                      subscription: {
                                        touched: !0,
                                        error: !0,
                                        value: !0
                                      },
                                      validateFields: [],
                                      children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                        children: [
                                          /* @__PURE__ */ e.jsx(mt, {
                                            name: l.input.name,
                                            variant: "keyboardInputDOB",
                                            format: "dd-MMM-yyyy",
                                            placeholder: n._({
                                              id: "Rr5/yP"
                                            }),
                                            selectedDate: l.input.value || null,
                                            disablePast: !0,
                                            disableFuture: !1,
                                            error: l.meta.error && l.meta.touched,
                                            helperText: null,
                                            onKeyDown: (y) => y.preventDefault(),
                                            handleDateChange: (y, ae) => l.input.onChange(y)
                                          }),
                                          l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(P, {
                                            type: "error",
                                            variant: "field",
                                            title: l.meta.error
                                          })
                                        ]
                                      })
                                    })
                                  ]
                                })
                              ]
                            }),
                            oe.aadhaar_number ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8 mt-8",
                              children: [
                                /* @__PURE__ */ e.jsx(X, {
                                  message: "Last 4 digits of Aadhaar number"
                                }),
                                /* @__PURE__ */ e.jsx(W, {
                                  name: "aadhaar_number",
                                  validate: G,
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0
                                  },
                                  validateFields: [],
                                  children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                    children: [
                                      /* @__PURE__ */ e.jsx(ge, {
                                        id: l.input.name,
                                        autoComplete: "off",
                                        name: l.input.name,
                                        value: l.input.value,
                                        onChange: l.input.onChange,
                                        placeholder: "Enter the last 4 digits of your aadhaar number",
                                        onInput: (y) => {
                                          y.target.value = y.target.value.replace(Ee("0-9"), "");
                                        },
                                        onBlur: () => {
                                          l.input.onChange(l.input.value.trim());
                                        },
                                        maxLength: 4,
                                        status: l.meta.error && l.meta.touched ? "error" : void 0
                                      }),
                                      l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(P, {
                                        type: "error",
                                        variant: "field",
                                        title: l.meta.error
                                      })
                                    ]
                                  })
                                })
                              ]
                            }) : null
                          ]
                        })
                      ]
                    })
                  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsxs("div", {
                        className: "border-neutral-10 dark:border-neutral-dark-10 bg-neutral-5 dark:bg-neutral-dark-5 relative mb-4 rounded-b-md border border-solid px-4 py-4",
                        style: {
                          top: "-4px"
                        },
                        children: [
                          /* @__PURE__ */ e.jsx("p", {
                            className: "text-neutral-60 text-size-12 mb-2",
                            children: ((ne = v == null ? void 0 : v.fetch) == null ? void 0 : ne.status) !== "successful" ? n._({
                              id: "SmHTo1"
                            }) : n._({
                              id: "EGxm1V"
                            })
                          }),
                          ((ue = v == null ? void 0 : v.fetch) == null ? void 0 : ue.status) !== "successful" ? /* @__PURE__ */ e.jsx("div", {
                            className: "text-center",
                            children: /* @__PURE__ */ e.jsx(Me, {
                              onClick: () => {
                                var l;
                                Tt((l = r.values) == null ? void 0 : l.email);
                              },
                              variant: "text",
                              type: "button",
                              children: "Allow"
                            })
                          }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                        ]
                      }),
                      /* @__PURE__ */ e.jsx(tt, {
                        countries: B,
                        addressType: "current",
                        formRef: V,
                        kycCheckData: T,
                        isPoaAadhaar: !0,
                        formInitialValues: de,
                        kycDetails: a,
                        addressProofTypeLabel: t
                      })
                    ]
                  })
                ]
              });
            }
          })
        ]
      })
    });
  }, qt = async () => {
    try {
      $(!0);
      const r = o.id, t = o == null ? void 0 : o.annexure;
      J ? t.partner_completed_step = "financial_details" : t.completed_step = "financial_details", await F.updateUser(r, t, null, H), w();
    } catch (r) {
      window.debug.error(r), L(n._({
        id: "qMcu4/"
      })), M(r);
    } finally {
      $(!1);
    }
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(nt, {
        title: n._({
          id: "EWh4lo"
        }),
        icon: O ? null : "Back",
        onClick: qt
      }),
      /* @__PURE__ */ e.jsx(Oe, {
        type: "text",
        text: J ? n._({
          id: "JK4xlJ"
        }) : n._({
          id: "h7ZLlY"
        })
      }),
      (Qe || Le) && /* @__PURE__ */ e.jsx(rt, {
        variant: "fullpage"
      }),
      Ge ? /* @__PURE__ */ e.jsx(or, {
        title: n._({
          id: "pqPqrF"
        }),
        description: K,
        showAction: !0,
        actionText: "Retry",
        actionHandler: () => {
          $e(!1);
        }
      }) : /* @__PURE__ */ e.jsx(lt, {
        onSubmit: It,
        mutators: {
          mutateField: Jt
        },
        validate: (r) => {
          var s;
          const t = {};
          return ((s = r == null ? void 0 : r.address_proof_type) == null ? void 0 : s.value) !== "aadhaar" && (r.current_pincode ? r.perm_addr_is_corres_addr === "No" && !r.permanent_pincode && (t.permanent_pincode = n._({
            id: "7rkFUv"
          })) : t.current_pincode = n._({
            id: "7rkFUv"
          })), t;
        },
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        initialValues: de,
        children: (r) => (V.current = r.form, /* @__PURE__ */ e.jsxs("form", {
          onSubmit: r.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-3",
              children: [
                /* @__PURE__ */ e.jsx(X, {
                  message: n._({
                    id: "0VsDgp"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "mb-5",
                  children: /* @__PURE__ */ e.jsx(W, {
                    name: "phone",
                    initialValue: de.phone,
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(ge, {
                          id: t.input.name,
                          autoComplete: "off",
                          name: t.input.name,
                          value: t.input.value,
                          disabled: !0
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(P, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-5",
              children: [
                /* @__PURE__ */ e.jsx(X, {
                  message: n._({
                    id: "oyjr24"
                  })
                }),
                /* @__PURE__ */ e.jsx(W, {
                  name: "email",
                  initialValue: de.email,
                  validate: le(G, er(n._({
                    id: "kI4wAK"
                  }))),
                  validateFields: [],
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ge, {
                        id: t.input.name,
                        autoComplete: "off",
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: () => {
                          t.input.onChange(t.input.value.trim());
                        },
                        onInput: (s) => {
                          s.target.value = s.target.value.replace(Ee("A-Za-z0-9._\\-@+"), "");
                        },
                        placeholder: "Enter email address",
                        maxLength: 50,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        disabled: !f,
                        autoFocus: !!f
                      }),
                      t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      }) : f ? /* @__PURE__ */ e.jsx(P, {
                        type: "helper",
                        variant: "field",
                        title: n._({
                          id: "Xu9u8c"
                        })
                      }) : null
                    ]
                  })
                })
              ]
            }),
            Rt(r),
            K && /* @__PURE__ */ e.jsx(P, {
              type: "error",
              variant: "field",
              title: K
            }),
            /* @__PURE__ */ e.jsx(Me, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const t = Object.keys(r.errors);
                t.length && at(t[0]);
              },
              customClass: "mb-12",
              children: n._({
                id: "AtXdm9"
              })
            })
          ]
        }))
      })
    ]
  });
}, tt = ({ countries: o, addressType: w, formRef: re, isPoaAadhaar: T = !1, kycDetails: J, addressProofTypeLabel: f }) => {
  const { fpapi: a } = fe(Ie), U = {}, O = Gt(async (c) => {
    if (!c)
      return n._({
        id: "7rkFUv"
      });
    if (!/^[1-9]\d{5}$/.test(c))
      return n._({
        id: "FufyC1"
      });
    try {
      const d = await a.fpClient.master_data().fetchPincode(c);
      re.current.change(`${w}_location`, Yt(`${d.city}, ${d.state_name}`) + `, ${d.country_ansi_code}`);
      return;
    } catch (d) {
      return M(d), n._({
        id: "X83W5/"
      });
    }
  }), V = (c) => {
    const [d, N, S] = c.split(", ");
    return `${d}, ${N}, ${wt(o, S)}`;
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mt-2"
          }),
          U.address ? null : f ? /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsx(X, {
                message: n._({
                  id: "PLMqte"
                })
              }),
              /* @__PURE__ */ e.jsx(W, {
                name: `${w}_pincode`,
                validate: O,
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      id: c.input.name,
                      autoComplete: "off",
                      name: c.input.name,
                      value: c.input.value,
                      onChange: c.input.onChange,
                      disabled: T,
                      placeholder: n._({
                        id: "OBXszf"
                      }),
                      onBlur: () => {
                        c.input.onChange(c.input.value.trim());
                      },
                      onInput: (d) => {
                        d.target.value = d.target.value.replace(Ee("0-9"), "");
                      },
                      maxLength: 6,
                      status: c.meta.error && c.meta.touched ? "error" : void 0,
                      autoFocus: !0
                    }),
                    c.meta.error && c.meta.touched ? /* @__PURE__ */ e.jsx(P, {
                      type: "error",
                      variant: "field",
                      title: c.meta.error
                    }) : /* @__PURE__ */ e.jsx(W, {
                      name: `${w}_location`,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (d) => /* @__PURE__ */ e.jsx(e.Fragment, {
                        children: d.input.value && /* @__PURE__ */ e.jsx(P, {
                          type: "success",
                          variant: "field",
                          title: V(d.input.value)
                        })
                      })
                    })
                  ]
                })
              })
            ]
          }) : null
        ]
      }),
      U.address ? null : f ? /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(X, {
            message: n._({
              id: "Z2IJgc"
            })
          }),
          /* @__PURE__ */ e.jsx(W, {
            name: `${w}_residence_line1`,
            validate: le(G, ir(n._({
              id: "jJT4qV"
            })), ar(n._({
              id: "wCWfoc"
            }), 150)),
            validateFields: [],
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(zt, {
                  id: c.input.name,
                  autoComplete: "off",
                  name: c.input.name,
                  value: c.input.value,
                  onChange: c.input.onChange,
                  disabled: T,
                  maxLength: 150,
                  onBlur: () => {
                    c.input.onChange(c.input.value.trim());
                  },
                  onInput: (d) => {
                    d.target.value = d.target.value.replace(Ee("A-Za-z0-9 #&/\\-.,"), "");
                  },
                  // placeholder={`Enter ${addressType} residence address`}
                  placeholder: n._({
                    id: "8Ueoht"
                  }),
                  status: c.meta.error && c.meta.touched ? "error" : void 0
                }),
                c.meta.error && c.meta.touched && /* @__PURE__ */ e.jsx(P, {
                  type: "error",
                  variant: "field",
                  title: c.meta.error
                })
              ]
            })
          })
        ]
      }) : null
    ]
  });
}, yr = ({ user: o, onCompletion: w, folioDetails: re, isGroupInvestment: T, isPartner: J, kycDetails: f, isKycCompliant: a, isKycOnly: U }) => {
  const O = He(null), { fpapi: V } = fe(Ie), { AppState: { tenant: c } } = fe(it), [d, N] = q("initial"), [S, I] = q([]), [F, H] = q(null), [C, A] = q(!1), [j, Re] = q(null), [Y, Ae] = q(!1), be = {};
  Ve(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const Be = (_) => [
    "second_tax_residency",
    "third_tax_residency",
    "fourth_tax_residency"
  ].some((g) => _[g]), he = (_, i) => {
    const g = [
      "second_tax_residency",
      "third_tax_residency",
      "fourth_tax_residency"
    ], z = [];
    return g.forEach((se) => {
      const Z = _[se];
      Z && z.push({
        trc_taxid_type: Z.taxid_type,
        trc_taxid_number: Z.taxid_number,
        trc_ansi_code: Z.country,
        trc_country_name: wt(i, Z.country)
      });
    }), z;
  };
  Ve(() => {
    (async () => {
      try {
        if (A(!0), o) {
          const _ = await V.fpClient.investor_profiles().fetch(o.profile);
          if (Be(_)) {
            const { countries: i } = await V.fpClient.master_data().fetchCountries(), g = he(_, i);
            Ae(!0), I(g);
          }
          Re(_);
        }
      } catch (_) {
        window.debug.error(_), H(n._({
          id: "J56bGZ"
        })), M(_);
      } finally {
        A(!1);
      }
    })();
  }, []);
  const B = Mt(() => {
    let _;
    const i = [
      {
        label: n._({
          id: "f0uGjY"
        }),
        value: {
          investorProfileValue: "government_service",
          kycValue: "government_sector"
        }
      },
      {
        label: n._({
          id: "Ex+hfX"
        }),
        value: {
          investorProfileValue: "private_sector_service",
          kycValue: "private_sector"
        }
      },
      {
        label: n._({
          id: "Cnq3xZ"
        }),
        value: {
          investorProfileValue: "professional",
          kycValue: "professional"
        }
      },
      {
        label: n._({
          id: "wlgrv+"
        }),
        value: {
          investorProfileValue: "public_sector_service",
          kycValue: "public_sector"
        }
      }
    ], g = [
      {
        label: n._({
          id: "knxqgB"
        }),
        value: {
          investorProfileValue: "business",
          kycValue: "business"
        }
      },
      {
        label: n._({
          id: "SOPYUU"
        }),
        value: {
          investorProfileValue: "retired",
          kycValue: "retired"
        }
      },
      {
        label: n._({
          id: "Asz58H"
        }),
        value: {
          investorProfileValue: "house_wife",
          kycValue: "housewife"
        }
      },
      {
        label: n._({
          id: "PKTltW"
        }),
        value: {
          investorProfileValue: "student",
          kycValue: "student"
        }
      },
      {
        label: n._({
          id: "a3VEdD"
        }),
        value: {
          investorProfileValue: "others",
          kycValue: "others"
        }
      },
      ...i
    ], z = [
      {
        label: n._({
          id: "RkF9W+"
        }),
        value: {
          investorProfileValue: "agriculture",
          kycValue: "others"
        }
      },
      {
        label: n._({
          id: "SHDdgK"
        }),
        value: {
          investorProfileValue: "doctor",
          kycValue: "others"
        }
      },
      {
        label: n._({
          id: "R31w0n"
        }),
        value: {
          investorProfileValue: "forex_dealer",
          kycValue: "others"
        }
      },
      {
        label: n._({
          id: "WkXkt/"
        }),
        value: {
          investorProfileValue: "service",
          kycValue: "others"
        }
      },
      ...g
    ];
    return U ? _ = g : T ? _ = i : _ = z, _.sort((se, Z) => se.label === "Others" ? 1 : se.label < Z.label ? -1 : 1);
  }, [])(), R = [
    {
      label: n._({
        id: "C4v9uw"
      }),
      value: "ancestral_property"
    },
    {
      label: n._({
        id: "knxqgB"
      }),
      value: "business"
    },
    {
      label: n._({
        id: "vPOn7T"
      }),
      value: "gift"
    },
    {
      label: n._({
        id: "hbAj8o"
      }),
      value: "prize_money"
    },
    {
      label: n._({
        id: "pkrzlZ"
      }),
      value: "rental_income"
    },
    {
      label: n._({
        id: "i1r+jC"
      }),
      value: "royalty"
    },
    {
      label: n._({
        id: "fnpTrQ"
      }),
      value: "salary"
    },
    {
      label: n._({
        id: "a3VEdD"
      }),
      value: "others"
    }
  ], K = [
    {
      label: n._({
        id: "O0dUYL"
      }),
      value: "upto_1lakh"
    },
    {
      label: n._({
        id: "XHQhN/"
      }),
      value: "above_1lakh_upto_5lakh"
    },
    {
      label: n._({
        id: "VtWV5E"
      }),
      value: "above_5lakh_upto_10lakh"
    },
    {
      label: n._({
        id: "HZjZLV"
      }),
      value: "above_10lakh_upto_25lakh"
    },
    {
      label: n._({
        id: "o0UHku"
      }),
      value: "above_25lakh_upto_1cr"
    },
    {
      label: n._({
        id: "TRwS3/"
      }),
      value: "above_1cr"
    }
  ], L = Ue(() => {
    let _ = {
      occupation: null,
      source_of_wealth: T ? R[6] : null,
      income_slab: null,
      no_other_tax_residences: null
    };
    if (re && (_.occupation = B[pe(B, kt(re), {
      customProperty: "value.investorProfileValue"
    })]), f) {
      const i = {
        occupation: {
          initFieldName: "occupation",
          initFieldValue: B[pe(B, f == null ? void 0 : f.occupation, {
            customProperty: "value.kycValue"
          })]
        }
      }, g = jt(i, f);
      _ = {
        ..._,
        ...g
      };
    }
    if (j) {
      const i = {
        occupation: B[pe(B, j == null ? void 0 : j.occupation, {
          customProperty: "value.investorProfileValue"
        })],
        source_of_wealth: R[pe(R, j == null ? void 0 : j.source_of_wealth)],
        income_slab: K[pe(K, j == null ? void 0 : j.income_slab)],
        no_other_tax_residences: j.first_tax_residency && (Be(j) ? "Yes" : "No")
      };
      for (const g in i)
        i[g] && (_[g] = i[g]);
    }
    return _;
  }, [
    j,
    re
  ]), Qe = (_) => {
    I([
      ...S,
      _
    ]), N("initial");
  };
  async function $({ occupation: _, source_of_wealth: i, income_slab: g, no_other_tax_residences: z }) {
    var oe, Pe;
    const se = z === "Yes", Z = {
      id: o == null ? void 0 : o.profile,
      occupation: (oe = _ == null ? void 0 : _.value) == null ? void 0 : oe.investorProfileValue,
      source_of_wealth: i == null ? void 0 : i.value,
      income_slab: g == null ? void 0 : g.value,
      use_default_tax_residences: !se
    };
    if (se && S.length) {
      const Ye = (Pe = o == null ? void 0 : o.username) == null ? void 0 : Pe.toUpperCase();
      Z.first_tax_residency = {
        country: "IN",
        taxid_type: "pan",
        taxid_number: Ye
      };
      const ze = {
        1: "second_tax_residency",
        2: "third_tax_residency",
        3: "fourth_tax_residency"
      };
      S.forEach((ce, Ze) => {
        Z[`${ze[Ze + 1]}`] = {
          country: ce == null ? void 0 : ce.trc_ansi_code,
          // taxid_type: residency?.trc_taxid_type?.value,
          taxid_type: "others",
          taxid_number: ce == null ? void 0 : ce.trc_taxid_number
        };
      });
    }
    return V.fpClient.investor_profiles().update(Z);
  }
  async function Le() {
    const _ = o.id, g = {
      ...Je(o == null ? void 0 : o.annexure),
      completed_step: "financial_details"
    };
    J && (g.partner_completed_step = "financial_details");
    const z = await V.updateUser(_, g, null, c);
    if (z)
      return z;
    throw new Error(z);
  }
  async function je({ occupation: _ }) {
    var g;
    const i = {
      occupation_type: (g = _ == null ? void 0 : _.value) == null ? void 0 : g.kycValue
    };
    return V.fpClient.kyc_requests().update(f == null ? void 0 : f.id, i);
  }
  const Ge = async (_) => {
    if (U)
      try {
        A(!0);
        const i = await je(_);
        w({
          updatedKycDetails: i
        });
      } catch (i) {
        H(n._({
          id: "cuvfTX"
        })), window.debug.error(i), M(i), me(i) && window.location.reload();
      } finally {
        A(!1);
      }
    else if (a)
      try {
        A(!0), await $(_), await Le(), w();
      } catch (i) {
        H(n._({
          id: "cuvfTX"
        })), window.debug.error(i), M(i);
      } finally {
        A(!1);
      }
    else
      try {
        A(!0);
        const i = [
          je(_),
          $(_)
        ], [g] = await Promise.all(i);
        await Le(), w({
          updatedKycDetails: g
        });
      } catch (i) {
        window.debug.error(i), H(n._({
          id: "cuvfTX"
        })), M(i), me(i) && window.location.reload();
      } finally {
        A(!1);
      }
  }, $e = async () => {
    try {
      A(!0);
      const _ = o.id, i = o == null ? void 0 : o.annexure;
      J ? delete i.partner_completed_step : delete i.completed_step, await V.updateUser(_, i, null, c), w();
    } catch (_) {
      window.debug.error(_), H(n._({
        id: "cuvfTX"
      })), M(_);
    } finally {
      A(!1);
    }
  }, v = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(nt, {
        title: n._({
          id: "AIr9uY"
        }),
        icon: U ? null : "Back",
        onClick: $e
      }),
      /* @__PURE__ */ e.jsx(Oe, {
        type: "text",
        text: J ? n._({
          id: "lc5Dv2"
        }) : n._({
          id: "zzvZc5"
        })
      }),
      /* @__PURE__ */ e.jsx(lt, {
        onSubmit: Ge,
        validate: (_) => {
          const i = {};
          return _.no_other_tax_residences === "Yes" && !S.length && (i.no_other_tax_residences = n._({
            id: "hC3Gqy"
          })), i;
        },
        initialValues: L,
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (_) => (O.current = _.form, /* @__PURE__ */ e.jsxs("form", {
          onSubmit: _.handleSubmit,
          children: [
            be.occupation_type ? null : /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(X, {
                  message: n._({
                    id: "jHZRka"
                  })
                }),
                /* @__PURE__ */ e.jsx(W, {
                  name: "occupation",
                  validate: G,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(We, {
                        name: i.input.name,
                        value: i.input.value,
                        onChange: (g) => i.input.onChange(g),
                        options: B,
                        status: i.meta.error && i.meta.touched ? "error" : void 0
                      }),
                      i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: i.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            U ? null : /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(X, {
                      message: n._({
                        id: "LxJDmf"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(W, {
                      name: "source_of_wealth",
                      validate: G,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validateFields: [],
                      children: (i) => {
                        var g;
                        return /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            T ? /* @__PURE__ */ e.jsx(ge, {
                              id: i.input.name,
                              autoComplete: "off",
                              name: i.input.name,
                              value: (g = L.source_of_wealth) == null ? void 0 : g.label,
                              disabled: !0
                            }) : /* @__PURE__ */ e.jsx(We, {
                              name: i.input.name,
                              value: i.input.value,
                              onChange: (z) => i.input.onChange(z),
                              options: R,
                              status: i.meta.error && i.meta.touched ? "error" : void 0
                            }),
                            i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(P, {
                              type: "error",
                              variant: "field",
                              title: i.meta.error
                            })
                          ]
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(X, {
                      message: n._({
                        id: "eO0CVp"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(W, {
                      name: "income_slab",
                      validate: le(G),
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validateFields: [],
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(We, {
                            name: i.input.name,
                            value: i.input.value,
                            onChange: i.input.onChange,
                            options: K,
                            status: i.meta.error && i.meta.touched ? "error" : void 0
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(P, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(X, {
                      message: n._({
                        id: "Iz0gdi"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(W, {
                      name: "no_other_tax_residences",
                      validate: G,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(Xt, {
                            name: i.input.name,
                            value: L == null ? void 0 : L.no_other_tax_residences,
                            checked: pe([
                              "Yes",
                              "No"
                            ], i.input.value, {
                              isNotArrayOfObjects: !0
                            }),
                            onChange: (g) => {
                              i.input.onChange(g.target.value);
                            },
                            group: [
                              n._({
                                id: "dhWZU+"
                              }),
                              n._({
                                id: "AYH7yb"
                              })
                            ],
                            disabled: Y ? i.input.value === "Yes" ? [
                              1
                            ] : [] : []
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(P, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                _.form.getState().values.no_other_tax_residences === "Yes" && /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(X, {
                      message: n._({
                        id: "SjWaBE"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-4",
                      children: /* @__PURE__ */ e.jsx(P, {
                        type: "important",
                        variant: "flash",
                        title: n._({
                          id: "csmDQB"
                        })
                      })
                    }),
                    S.map((i, g) => /* @__PURE__ */ e.jsx(Wt, {
                      children: /* @__PURE__ */ e.jsxs("div", {
                        className: "-mr-2 -ml-2 flex flex-wrap",
                        children: [
                          /* @__PURE__ */ e.jsx("div", {
                            className: "basis-1/2 pr-2 pl-2",
                            children: /* @__PURE__ */ e.jsx(ft, {
                              label: n._({
                                id: "UcmoRi"
                              }),
                              value: i == null ? void 0 : i.trc_country_name
                            })
                          }),
                          /* @__PURE__ */ e.jsx("div", {
                            className: "basis-1/2 pr-2 pl-2",
                            children: /* @__PURE__ */ e.jsx(ft, {
                              label: n._({
                                id: "2QDHp6"
                              }),
                              value: i == null ? void 0 : i.trc_taxid_number
                            })
                          })
                        ]
                      })
                    }, g)),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-4",
                      children: /* @__PURE__ */ e.jsx(Me, {
                        variant: S.length >= 3 ? "disabled" : "secondary",
                        fullwidth: !0,
                        onClick: (i) => {
                          i.preventDefault(), i.stopPropagation(), N("AddTRC");
                        },
                        children: n._({
                          id: "X6nmUX"
                        })
                      })
                    })
                  ]
                })
              ]
            }),
            F && /* @__PURE__ */ e.jsx(P, {
              type: "error",
              variant: "field",
              title: F
            }),
            /* @__PURE__ */ e.jsx(Me, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const i = Object.keys(_.errors);
                i.length && at(i[0]);
              },
              customClass: "mb-12",
              children: n._({
                id: "AtXdm9"
              })
            })
          ]
        }))
      })
    ]
  });
  return C ? /* @__PURE__ */ e.jsx(rt, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx("div", {
        style: {
          display: d === "initial" ? "block" : "none"
        },
        children: v()
      }),
      U ? null : /* @__PURE__ */ e.jsx("div", {
        style: {
          display: d !== "initial" ? "block" : "none"
        },
        children: /* @__PURE__ */ e.jsx(Ct, {
          taxResidenceCountries: S,
          handleAddTaxResidenceDetails: Qe,
          handleBack: () => N("initial")
        })
      })
    ]
  });
}, Ct = /* @__PURE__ */ Lt(({ handleAddTaxResidenceDetails: o, isPartner: w, handleBack: re, taxResidenceCountries: T }) => {
  const { fpapi: J } = fe(Ie), [f, a] = q([]), U = He(null), O = Ue(() => ({
    trc_country: null
  }), []), V = (c) => {
    var d, N;
    U.current.reset(), o({
      trc_taxid_type: c == null ? void 0 : c.trc_taxid_type,
      trc_taxid_number: c == null ? void 0 : c.trc_taxid_number,
      trc_ansi_code: (d = c == null ? void 0 : c.trc_country) == null ? void 0 : d.trc_ansi_code,
      trc_country_name: (N = c == null ? void 0 : c.trc_country) == null ? void 0 : N.trc_country_name
    });
  };
  return Ve(() => {
    (async () => {
      try {
        const { countries: c } = await J.fpClient.master_data().fetchCountries();
        if (c.length) {
          const d = [], N = [];
          c.forEach((S) => {
            if (S.ansi_code !== "IN") {
              const I = {
                id: S.ansi_code,
                trc_ansi_code: S.ansi_code,
                trc_country_name: S.name
              };
              S.ansi_code === "US" ? N[0] = I : S.ansi_code === "GB" ? N[1] = I : S.ansi_code === "AE" ? N[2] = I : d.push(I);
            }
          }), a([
            ...N,
            ...d
          ]);
        }
      } catch (c) {
        window.debug.error(c), M(c);
      }
    })();
  }, []), /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(nt, {
        title: n._({
          id: "AIr9uY"
        }),
        icon: "Back",
        onClick: re
      }),
      /* @__PURE__ */ e.jsx(Oe, {
        type: "text",
        text: w ? n._({
          id: "lc5Dv2"
        }) : n._({
          id: "zzvZc5"
        })
      }),
      /* @__PURE__ */ e.jsx(lt, {
        onSubmit: V,
        validate: (c) => ({}),
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        initialValues: O,
        children: (c) => (U.current = c.form, /* @__PURE__ */ e.jsxs("form", {
          onSubmit: c.handleSubmit,
          children: [
            !!f.length && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(X, {
                  message: n._({
                    id: "UcmoRi"
                  })
                }),
                /* @__PURE__ */ e.jsx(W, {
                  name: "trc_country",
                  validate: le(G, lr(T, n._({
                    id: "7/wU9f"
                  }))),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Zt, {
                        value: d.input.value,
                        options: f,
                        onChange: (N) => d.input.onChange(N),
                        searchAttribute: "trc_country_name",
                        variant: "autocomplete",
                        placeholder: n._({
                          id: "Y88q9i"
                        })
                      }),
                      d.meta.error && d.meta.touched && /* @__PURE__ */ e.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(X, {
                  message: n._({
                    id: "DZCqsJ"
                  })
                }),
                /* @__PURE__ */ e.jsx(W, {
                  name: "trc_taxid_type",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: le(G),
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ge, {
                        id: d.input.name,
                        autoComplete: "off",
                        name: d.input.name,
                        onChange: d.input.onChange,
                        onBlur: () => {
                          d.input.onChange(d.input.value.trim());
                        },
                        onInput: (N) => {
                          N.target.value = N.target.value.replace(Ee("A-Za-z "), "");
                        },
                        value: d.input.value,
                        placeholder: n._({
                          id: "Ny7pME"
                        }),
                        maxLength: 25,
                        status: d.meta.error && d.meta.touched ? "error" : void 0
                      }),
                      d.meta.error && d.meta.touched && /* @__PURE__ */ e.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(X, {
                  message: n._({
                    id: "2QDHp6"
                  })
                }),
                /* @__PURE__ */ e.jsx(W, {
                  name: "trc_taxid_number",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: le(G),
                  parse: (d) => d.toUpperCase(),
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ge, {
                        id: d.input.name,
                        autoComplete: "off",
                        name: d.input.name,
                        onChange: d.input.onChange,
                        onBlur: () => {
                          d.input.onChange(d.input.value.trim());
                        },
                        onInput: (N) => {
                          N.target.value = N.target.value.replace(Ee("A-Za-z0-9 /\\-"), "");
                        },
                        value: d.input.value,
                        placeholder: n._({
                          id: "uu7iMg"
                        }),
                        status: d.meta.error && d.meta.touched ? "error" : void 0,
                        maxLength: 25
                      }),
                      d.meta.error && d.meta.touched && /* @__PURE__ */ e.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(Me, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const d = Object.keys(c.errors);
                d.length && at(d[0]);
              },
              customClass: "mb-8",
              children: n._({
                id: "AtXdm9"
              })
            })
          ]
        }))
      })
    ]
  });
});
Ct.displayName = "AddTaxResidenceDetails";
export {
  Fr as B,
  Cr as C,
  yr as F
};
