import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as f, useState as n, useEffect as P } from "react";
import { AppContext as D } from "./AppContext.js";
import { F as G } from "./provider-DKLaKIVD.js";
import { LoginContext as M } from "./LoginContext.js";
import { P as H, a as Z } from "./index.esm-CqWfms5D.js";
import { i as o } from "./index-CrDCxF0S.js";
import { r as q } from "./sentry-CstOwvcR.js";
import { W as z } from "./Welcome-Bz7XIwKh.js";
import { ErrorPage as _ } from "./ErrorPage.js";
import { B as N } from "./BankAccountListing-CcLcjdff.js";
import { A as Q } from "./Auth-CJPsuV-i.js";
function le() {
  const { fpapi: i } = f(G), { loggedInUser: t } = f(M), { AppState: { showWelcomePage: d, tenant: b }, setShowWelcomePage: j } = f(D), [k, v] = n(null), [F, m] = n(x), [U, a] = n(!1), [p, E] = n(!1), [S, I] = n(""), y = [
    {
      step: 1,
      content: o._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: o._({
        id: "ElbsPp"
      })
    },
    {
      step: 3,
      content: o._({
        id: "r6nqxS"
      })
    }
  ], u = () => {
    (async () => {
      var g, A, h, w, C;
      if (i && t)
        try {
          a(!0);
          const { data: c } = await i.fpClient.custom_users().listAll({
            username: t.profile.preferred_username
          }, b), l = c[0];
          if (((g = l == null ? void 0 : l.annexure) == null ? void 0 : g.isonboarded) === "true") {
            const R = i.getInvestorProfile(), T = ((h = (A = t == null ? void 0 : t.profile) == null ? void 0 : A.preferred_username) == null ? void 0 : h.toUpperCase()) ?? "", W = i.fpClient.mf_investment_accounts().fetchAll({
              investor: T
            }), [r, s] = await Promise.all([
              R,
              W
            ]);
            ((w = s == null ? void 0 : s.data) == null ? void 0 : w.length) && ((C = s == null ? void 0 : s.data) == null ? void 0 : C.find((B) => B.primary_investor === (r == null ? void 0 : r.id))) && (r == null ? void 0 : r.pan) && (v(r), E(!0));
          }
        } catch (c) {
          I("Unable to fetch details"), q(c);
        } finally {
          a(!1);
        }
    })();
  };
  P(() => {
    u();
  }, [
    t
  ]), P(() => {
    m(x());
  }, [
    d
  ]);
  function x() {
    return d ? "welcome" : "bankAccount";
  }
  function L() {
    j(!1), a(!1), m("bankAccount");
  }
  const O = () => F === "welcome" ? /* @__PURE__ */ e.jsx(z, {
    onAck: L,
    welcomeSteps: y
  }) : U ? /* @__PURE__ */ e.jsx(Z, {
    variant: "fullpage"
  }) : S ? /* @__PURE__ */ e.jsx(_, {
    title: o._({
      id: "Qk4uVC"
    }),
    description: o._({
      id: "sD5oGo"
    }),
    showDetails: !1,
    showAction: !0,
    actionText: "Retry",
    actionHandler: () => {
      u();
    }
  }) : t && p ? /* @__PURE__ */ e.jsx(N, {
    investorProfile: k
  }) : t && !p ? /* @__PURE__ */ e.jsx(_, {
    title: o._({
      id: "4LZdlC"
    }),
    description: o._({
      id: "3GilYU"
    }),
    showDetails: !1,
    customClass: "mt-5"
  }) : /* @__PURE__ */ e.jsx(Q, {
    redirectURL: window.location.href,
    allowRegistration: !1,
    showTestPan: {
      onboarded: !0
    }
  });
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(H, {
        title: o._({
          id: "ozHvvf"
        })
      }),
      O()
    ]
  });
}
export {
  le as ManageBankAccounts
};
