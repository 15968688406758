const w = (...t) => (e) => t.reduce((r, s) => r || s(e), void 0), o = (t) => {
  const e = /* @__PURE__ */ new Date();
  return e.setFullYear(e.getFullYear() - 18), new Date(t) <= e;
}, d = (t) => {
  if (t) {
    const e = new Date(t);
    return Object.prototype.toString.call(e) === "[object Date]" ? !isNaN(e) : !1;
  } else
    return !1;
}, z = (t) => !!/[0-9]/.test(t), c = (t) => /^[A-Za-z0-9._%+-]{1,64}@(?:[A-Za-z0-9-]{1,63}\.){1,125}[A-Za-z]{2,63}$/.test(t), v = (t) => /^[6-9]\d{9}$/.test(t), D = (t) => t ? void 0 : "This input is required", f = (t) => (e) => (e == null ? void 0 : e.length) >= t ? void 0 : `Please use atleast ${t} characters`, g = (t) => (e) => (e == null ? void 0 : e.length) <= t ? void 0 : `Please use a maximum of ${t} characters`, h = (t) => (e) => e > t ? void 0 : `Minimum value should be more than ${t}`, m = (t) => (e) => e <= t ? void 0 : `Maximum value should be less than ${t}`, $ = (t) => (e) => /^([a-zA-Z]( [a-zA-Z]+)*){2,}$/.test(e) ? void 0 : t, Z = (t) => (e) => /^[1-9]\d{5}$/.test(e) ? void 0 : t, P = (t) => (e) => /^[0-9]+$/.test(e) ? void 0 : t, b = (t) => (e) => c(e) ? void 0 : t, N = (t) => (e) => v(e) ? void 0 : t, C = (t) => (e) => o(e) ? void 0 : t, x = (t) => (e) => d(e) ? void 0 : t, _ = (t) => {
  if (!t || new Date(t).toString() === "Invalid Date")
    return "Invalid Date";
}, L = (t) => (e) => /^[-.,\\/#&\w\s]*$/.test(e) ? void 0 : t, u = (t) => (e) => /^([a-zA-Z]){3}([0-9]){7}?$/g.test(e) ? void 0 : t, l = (t) => (e) => /^(([A-Z]{2}[0-9]{2})[ ]?|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/g.test(e) ? void 0 : t, A = (t) => (e) => /^[A-PR-WY][1-9]\d\d{4}[1-9]$/gi.test(e) ? void 0 : t, S = (t, e) => (r) => {
  switch (t) {
    case "voter_id":
      return u(e)(r);
    case "driving_licence":
      return l(e)(r);
    case "passport":
      return A(e)(r);
    default:
      return () => e;
  }
}, E = (t) => (e) => {
  if (!(e && new Date(e) > /* @__PURE__ */ new Date()))
    return t;
}, M = (t) => {
  const e = /* @__PURE__ */ new Date();
  return e.setDate(e.getDate() - 1), (r) => {
    if (!(r && new Date(r) < e))
      return t;
  };
}, y = (t, e) => (r) => {
  if (r.type === "application/pdf" && r.size / Math.pow(10, 6) > t)
    return e;
}, F = (t, e) => (r) => {
  if (r)
    return t[r == null ? void 0 : r.type] ? void 0 : e;
}, I = (t) => (e) => /^[a-zA-Z]{3}[pcPC][a-zA-Z][0-9]{4}[a-zA-Z]$/.test(e) ? void 0 : t, j = (t) => (e) => /^[a-zA-Z]{3}[Cc][a-zA-Z][0-9]{4}[a-zA-Z]$/.test(e) ? void 0 : t, q = ({ data: t, property: e, customMsg: r }) => (s) => Array.isArray(t) ? t.find((a) => e ? String(a[e]).toLowerCase() === ((s == null ? void 0 : s.value) || s).toLowerCase() : String(a).toLowerCase() === s.toLowerCase()) ? r : void 0 : "Validation failed. Invalid argument passed.", R = (t, e, r) => (s) => {
  var n, i;
  if (!t)
    return;
  const a = (i = (n = s == null ? void 0 : s.split("@")) == null ? void 0 : n[1]) == null ? void 0 : i.toLowerCase();
  return e[a] ? void 0 : r;
}, p = (t) => Object.prototype.toString.call(t) === "[object String]", U = (t, e) => (r) => {
  if (!(!r || !p))
    return (r == null ? void 0 : r.length) <= e ? void 0 : t;
}, Y = (t) => ({
  male: !0,
  female: !0,
  transgender: !0
})[t], G = (t, e) => (r) => {
  if ((r == null ? void 0 : r.size) / Math.pow(10, 6) > t)
    return e;
}, k = (t) => (e) => /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)[a-zA-Z0-9-]{1,}(\.[a-zA-Z0-9-]{1,})+(\/[a-zA-Z0-9-]{2,})*$/.test(e) ? void 0 : t, O = () => {
}, B = (t) => (e) => /^E\d{6}$/i.test(e) ? void 0 : t, H = (t) => (e) => /^(ARN-?\d{6}|INA\d{9})$/i.test(e) ? void 0 : t, T = (t, e) => (r) => t.find((s) => {
  var a, n;
  return ((a = s == null ? void 0 : s.trc_country_name) == null ? void 0 : a.toLowerCase()) === ((n = r == null ? void 0 : r.trc_country_name) == null ? void 0 : n.toLowerCase());
}) ? e : void 0;
function W(t) {
  return t ? new RegExp("^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$", "i").test(t) : !1;
}
export {
  j as A,
  k as B,
  z as C,
  H as D,
  B as E,
  W as F,
  m as G,
  h as H,
  N as a,
  b,
  P as c,
  $ as d,
  x as e,
  w as f,
  g,
  f as h,
  F as i,
  G as j,
  C as k,
  _ as l,
  Y as m,
  y as n,
  q as o,
  S as p,
  M as q,
  D as r,
  O as s,
  E as t,
  U as u,
  Z as v,
  L as w,
  T as x,
  R as y,
  I as z
};
