import { j as i } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as n, useContext as os, useRef as Is, useMemo as ks, useEffect as ms } from "react";
import { S as ls, c as d, B as ss, D as Cs, a as es, e as b, k as Ls, T as xs, M as J, l as Ds, F as vs, b as Ms, u as As, R as Fs, O as qs } from "./index.esm-CqWfms5D.js";
import { F as ts } from "./provider-DKLaKIVD.js";
import { f as P, r } from "./validators-BNinzCrT.js";
import { u as is } from "./useConfiguration-B-lyaBB9.js";
import { A as T, ac as Es, f as _s, a0 as $s, z as ps, _ as us, m as ds, a5 as Us, a as hs, M as Ss, i as Rs, h as Vs, k as ns, a2 as Ns, b as Bs, c as Ts } from "./utils-C2gHLhxV.js";
import { R as gs, F as O } from "./react-final-form.es-CUo9SHJd.js";
import { r as Y } from "./sentry-CstOwvcR.js";
import { i as o } from "./index-CrDCxF0S.js";
import { m as as, M as Ks } from "./MandateListing-DpfjvUeo.js";
import { ErrorPage as ys } from "./ErrorPage.js";
import { P as fs } from "./Product-DeVtlxIM.js";
import { S as ws } from "./StatusMessage-BUdCbwjZ.js";
function Xs({ mandate: s, handleMandateModalChange: N }) {
  var H;
  const { banks: y, tenantConfig: K } = is(), L = K == null ? void 0 : K.fallbackLogo;
  return /* @__PURE__ */ i.jsx(i.Fragment, {
    children: s && s.bank ? /* @__PURE__ */ i.jsx(ls, {
      children: /* @__PURE__ */ i.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
        children: [
          /* @__PURE__ */ i.jsx("div", {
            className: "mb-3 w-full pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(d, {
              label: "Bank",
              value: s ? `${s.bank.bank_name} - ****${s.bank.number.slice(-4)}` : "-",
              logo: y[(H = s.bank.ifsc) == null ? void 0 : H.slice(0, 4)] || L
            })
          }),
          /* @__PURE__ */ i.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(d, {
              label: "Amount limit",
              value: s ? `₹${T(s.mandate_limit)}` : "-"
            })
          }),
          /* @__PURE__ */ i.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(d, {
              label: "Authorization",
              value: s ? as[s.mandate_status].value : "-",
              icon: s ? as[s.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ i.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(ss, {
              variant: "text",
              onClick: N,
              children: `${o._({
                id: "ShFidp"
              })}`
            })
          })
        ]
      })
    }) : /* @__PURE__ */ i.jsx(Cs, {
      type: "borderedNotes",
      text: "Setup mandate",
      children: /* @__PURE__ */ i.jsx(ss, {
        variant: "text",
        onClick: N,
        children: `${o._({
          id: "JW7jyx"
        })}`
      })
    })
  });
}
function si({ currentPlanObject: s, editField: N, handleEditCompletion: y }) {
  var u;
  const [K, L] = n(!1), [H, U] = n("initial"), { tenantConfig: M, amcs: k } = is(), A = M == null ? void 0 : M.fallbackLogo, I = M == null ? void 0 : M.showSchemeLogo, [x, w] = n({
    name: "",
    investment_option: "",
    minInitialAmount: 0,
    maxInitialAmount: 0,
    schemeLogo: ""
  }), { fpapi: R } = os(ts), [q, Z] = n({}), C = ((u = M == null ? void 0 : M.app_constants) == null ? void 0 : u.non_systematic_min_installments) || 3, g = /* @__PURE__ */ new Date(), [_, E] = n(null), [h, v] = n(null), [F, G] = n(!1), c = Is(null), p = {
    amount: {
      label: o._({
        id: "Dh9Eyz"
      }),
      value: "amount"
    },
    payment_method: {
      label: o._({
        id: "1sWe0g"
      }),
      value: "payment_method"
    },
    installment_day: {
      label: o._({
        id: "tnH8DU"
      }),
      value: "installment_day"
    },
    no_of_installment: {
      label: o._({
        id: "6wLMYD"
      }),
      value: "number_of_installments"
    }
  };
  s != null && s.systematic && (delete p.installment_day, delete p.no_of_installment), (s == null ? void 0 : s.object) !== "mf_purchase_plan" && delete p.payment_method, (s == null ? void 0 : s.frequency) === "daily" && p != null && p.installment_day && delete p.installment_day, N != null && N.length && Object.keys(p).forEach((m) => {
    N != null && N.includes(m) || delete p[m];
  });
  const X = ks(() => ({
    edit_options: p[Object.keys(p)[0]],
    edit_start_date: new Date(g).setDate(g.getDate() + 2),
    edit_max_start_date: new Date(g).setDate(g.getDate() + 30),
    edit_allowed_dates: Es(s == null ? void 0 : s.frequency).filter((m) => m !== (s == null ? void 0 : s.installment_day)),
    mandate: _
  }), [
    _
  ]);
  ms(() => {
    t(), $();
  }, [
    s
  ]);
  const t = async () => {
    const m = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    if (m) {
      let a;
      try {
        a = await R.fpClient.master_data().fetchScheme(m);
      } catch (W) {
        window.debug.error(W), Y(W);
      }
      let l;
      (s == null ? void 0 : s.object) === "mf_purchase_plan" ? l = a == null ? void 0 : a.sip_frequency_specific_data : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? l = a == null ? void 0 : a.swp_frequency_specific_data : (s == null ? void 0 : s.object) === "mf_switch_plan" && (l = a == null ? void 0 : a.stp_frequency_specific_data), Z(l);
      const D = I ? {
        name: a == null ? void 0 : a.name,
        investment_option: a == null ? void 0 : a.investment_option,
        minInitialAmount: a == null ? void 0 : a.min_initial_investment,
        maxInitialAmount: a == null ? void 0 : a.max_initial_investment,
        schemeLogo: k[a == null ? void 0 : a.amc_id] || A
      } : {
        name: a == null ? void 0 : a.name,
        investment_option: a == null ? void 0 : a.investment_option,
        minInitialAmount: a == null ? void 0 : a.min_initial_investment,
        maxInitialAmount: a == null ? void 0 : a.max_initial_investment
      };
      w(D);
    }
  }, $ = async () => {
    if ((s == null ? void 0 : s.object) === "mf_purchase_plan")
      try {
        L(!0);
        const m = await R.getInvestorProfile();
        v(m);
        const a = await R.fpClient.bank_accounts().fetchAll({
          profile: m == null ? void 0 : m.id
        }), l = a.data.map((S) => S.old_id), W = (await R.fpClient.mandates().fetchAll({
          bank_account_id: l.join(","),
          size: 100
        })).mandates;
        if (W.sort((S, e) => Number(new Date(e.created_at)) - Number(new Date(S.created_at))), W.length > 0) {
          a.data.forEach((f) => {
            W.forEach((Q) => {
              Q.bank_account_id === f.old_id && (Q.bank = {
                bank_name: f.bank_name,
                number: f.account_number,
                ifsc: f.ifsc_code
              });
            });
          });
          const S = [], e = [];
          if (W.forEach((f) => {
            f.mandate_status === "CREATED" || f.mandate_status === "SUBMITTED" ? S.push(f) : f.mandate_status === "APPROVED" && e.push(f);
          }), (s == null ? void 0 : s.payment_method) === "mandate" && (s != null && s.payment_source)) {
            const f = W.find((Q) => Q.id.toString() === s.payment_source);
            E(f);
          }
          L(!1);
        } else
          L(!1);
      } catch (m) {
        window.debug.error(m), Y(m);
      }
  }, z = async ({ editType: m, amount: a, installment_day: l, installments: D, mandate: W }) => {
    L(!0);
    try {
      let S = {}, e = {}, f = {};
      m.value === "amount" ? f = {
        id: s == null ? void 0 : s.id,
        amount: a
      } : m.value === "installment_day" ? f = {
        id: s == null ? void 0 : s.id,
        installment_day: new Date(l).getDate()
      } : m.value === "number_of_installments" ? f = {
        id: s == null ? void 0 : s.id,
        number_of_installments: D
      } : m.value === "payment_method" && (f = {
        id: s == null ? void 0 : s.id,
        payment_method: "mandate",
        payment_source: W.id
      }), (s == null ? void 0 : s.object) === "mf_purchase_plan" ? S = await R.fpClient.mf_purchase_plans().update(f) : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? S = await R.fpClient.mf_redemption_plans().update(f) : (s == null ? void 0 : s.object) === "mf_switch_plan" && (S = await R.fpClient.mf_switch_plans().update(f)), m.value === "amount" ? e = s.amount ? {
        ...S,
        previousAmount: s.amount
      } : {
        ...S,
        previousUnits: s.units
      } : m.value === "installment_day" ? e = {
        ...S,
        previousInstallmentDay: s.installment_day
      } : m.value === "number_of_installments" ? e = {
        ...S,
        previousInstallments: s.number_of_installments
      } : m.value === "payment_method" && (e = {
        ...S,
        previousPaymentMethod: s.payment_method || "not_setup",
        previouosPaymentSource: s.payment_source
      }), y(e), L(!1);
    } catch (S) {
      L(!1), U("error"), Y(S);
    }
  };
  function j() {
    return /* @__PURE__ */ i.jsx(Ms, {
      variant: "fullPage",
      title: "Select mandate",
      isOpen: F,
      toggleModal: () => {
        G(!1);
      },
      contentHeightMax: !0,
      children: /* @__PURE__ */ i.jsx(Ks, {
        investorProfile: h,
        handleMandateSelection: (m) => {
          G(!1), c.current.input.onChange(m);
        }
      })
    });
  }
  function V() {
    var m;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(fs, {
          scheme: x
        }),
        /* @__PURE__ */ i.jsx(ls, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: s != null && s.amount ? o._({
                    id: "7sZGpa"
                  }) : o._({
                    id: "2pa2q1"
                  }),
                  value: s != null && s.amount ? `₹ ${T(s == null ? void 0 : s.amount, 2)}` : s != null && s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (m = _s(s.frequency)) == null ? void 0 : m.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(gs, {
          onSubmit: z,
          validate: (a) => {
            const l = {};
            return s != null && s.systematic && a.amount && a.amount > q[s == null ? void 0 : s.frequency].max_installment_amount && (l.amount = o._({
              id: "5K7M8h",
              values: {
                0: T(q[s == null ? void 0 : s.frequency].max_installment_amount)
              }
            })), s != null && s.systematic && a.amount && a.amount < q[s == null ? void 0 : s.frequency].min_installment_amount && (l.amount = o._({
              id: "AiK/vj",
              values: {
                0: T(q[s == null ? void 0 : s.frequency].min_installment_amount)
              }
            })), s != null && s.systematic && a.amount && q[s == null ? void 0 : s.frequency].amount_multiples && $s(a.amount.toString(), q[s == null ? void 0 : s.frequency].amount_multiples.toString()) !== 0 && (l.amount = o._({
              id: "aXWCv/",
              values: {
                0: q[s == null ? void 0 : s.frequency].amount_multiples
              }
            })), !(s != null && s.systematic) && a.amount && a.amount > (x == null ? void 0 : x.maxInitialAmount) && (l.amount = o._({
              id: "5K7M8h",
              values: {
                0: T(x == null ? void 0 : x.maxInitialAmount)
              }
            })), !(s != null && s.systematic) && a.amount && a.amount < (x == null ? void 0 : x.minInitialAmount) && (l.amount = o._({
              id: "AiK/vj",
              values: {
                0: T(x == null ? void 0 : x.minInitialAmount)
              }
            })), !(s != null && s.systematic) && a.installments && a.installments < C && (l.installments = o._({
              id: "0GGsEN",
              values: {
                nonSipMinimumInstallments: C
              }
            })), !(s != null && s.systematic) && a.installments && a.installments > 1200 && (l.installments = o._({
              id: "j7AzBN"
            })), (s == null ? void 0 : s.object) === "mf_purchase_plan" && (s != null && s.payment_source) && a.amount && a.amount > (_ == null ? void 0 : _.mandate_limit) && (l.amount = o._({
              id: "E5Y5Dh",
              values: {
                0: T(_ == null ? void 0 : _.mandate_limit)
              }
            })), (s == null ? void 0 : s.object) === "mf_purchase_plan" && _ && a.mandate && a.mandate.id === _.id && (l.mandate = o._({
              id: "Cp20Hl"
            })), l;
          },
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (a) => {
            var l, D, W, S;
            return /* @__PURE__ */ i.jsxs("form", {
              id: "editOrderForm",
              onSubmit: a.handleSubmit,
              children: [
                /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(b, {
                      message: o._({
                        id: "5TUqdC"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(O, {
                      name: "editType",
                      initialValue: X.edit_options,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: P(r),
                      children: (e) => /* @__PURE__ */ i.jsx(Ls, {
                        name: e.input.name,
                        value: e.input.value,
                        onChange: (f) => {
                          e.input.onChange(f);
                        },
                        options: Object.values(p)
                      })
                    })
                  ]
                }),
                ((l = a.values.editType) == null ? void 0 : l.value) === "amount" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(b, {
                      message: o._({
                        id: "OFH0cI"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(O, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: P(r),
                      children: (e) => {
                        var f;
                        return /* @__PURE__ */ i.jsxs(i.Fragment, {
                          children: [
                            /* @__PURE__ */ i.jsx(xs, {
                              id: e.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: e.input.name,
                              value: ps(e.input.value),
                              onChange: (Q) => e.input.onChange(us(Q.target.value)),
                              onInput: (Q) => {
                                Q.target.value = Q.target.value.replace(ds("0-9"), "");
                              },
                              placeholder: o._({
                                id: "fK0Zpc"
                              }),
                              prefix: "₹",
                              status: e.meta.error && e.meta.touched ? "error" : void 0,
                              autoFocus: !0
                            }),
                            e.meta.error && e.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                              className: "mb-2 mt-2",
                              children: /* @__PURE__ */ i.jsx(J, {
                                type: "error",
                                variant: "field",
                                title: e.meta.error
                              })
                            }) : /* @__PURE__ */ i.jsx(J, {
                              type: "helper",
                              variant: "field",
                              title: o._({
                                id: "9v2zaG",
                                values: {
                                  0: s != null && s.systematic ? `${T(Number((f = q[s == null ? void 0 : s.frequency]) == null ? void 0 : f.min_installment_amount))}` : `${T(Number(x == null ? void 0 : x.minInitialAmount))}`
                                }
                              })
                            })
                          ]
                        });
                      }
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((D = a.values.editType) == null ? void 0 : D.value) === "payment_method" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(b, {
                      message: o._({
                        id: "lFCwug"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(O, {
                      name: "mandate",
                      initialValue: X.mandate,
                      validate: P(r),
                      children: (e) => (c.current = e, /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(Xs, {
                            mandate: e.input.value,
                            handleMandateModalChange: (f) => {
                              f.preventDefault(), G(!F);
                            }
                          }),
                          e.meta.error && e.meta.touched && /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(J, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          })
                        ]
                      }))
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((W = a.values.editType) == null ? void 0 : W.value) === "installment_day" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(b, {
                      message: o._({
                        id: "VXfUoN"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(O, {
                      name: "installment_day",
                      initialValue: X.edit_start_date,
                      validate: P(r),
                      children: (e) => /* @__PURE__ */ i.jsx(i.Fragment, {
                        children: /* @__PURE__ */ i.jsx(Ds, {
                          variant: "keyboardInputDOB",
                          onKeyDown: (f) => f.preventDefault(),
                          disableFuture: !1,
                          minDate: new Date(X.edit_start_date),
                          maxDate: new Date(X.edit_max_start_date),
                          shouldDisableDate: (f) => Us(f, X.edit_allowed_dates),
                          name: e.input.name,
                          value: e.input.value,
                          format: "dd-MMM-yyyy",
                          selectedDate: e.input.value || null,
                          error: e.meta.error && e.meta.touched,
                          views: [
                            "date"
                          ],
                          handleDateChange: (f) => {
                            e.input.onChange(f);
                          }
                        })
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((S = a.values.editType) == null ? void 0 : S.value) === "number_of_installments" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(b, {
                      message: o._({
                        id: "kGmYAl"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(O, {
                      name: "installments",
                      validate: P(r),
                      children: (e) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(xs, {
                            id: e.input.name,
                            autoComplete: "off",
                            type: "text",
                            name: e.input.name,
                            value: ps(e.input.value),
                            onChange: (f) => e.input.onChange(us(f.target.value)),
                            onInput: (f) => {
                              f.target.value = f.target.value.replace(ds("0-9"), "");
                            },
                            placeholder: o._({
                              id: "3Oj1cs"
                            }),
                            status: e.meta.error && e.meta.touched ? "error" : void 0
                          }),
                          e.meta.error && e.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(J, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          }) : /* @__PURE__ */ i.jsx(J, {
                            type: "helper",
                            variant: "field",
                            title: o._({
                              id: "C40jmw",
                              values: {
                                0: T(Number(C))
                              }
                            })
                          })
                        ]
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                /* @__PURE__ */ i.jsx(vs, {
                  children: /* @__PURE__ */ i.jsx("div", {
                    className: "mb-4 pt-1",
                    children: /* @__PURE__ */ i.jsx(ss, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: o._({
                        id: "H/E6fZ"
                      })
                    })
                  })
                })
              ]
            });
          }
        }),
        F && j(),
        /* @__PURE__ */ i.jsx(J, {
          type: "important",
          variant: "spot",
          content: o._({
            id: "24gA27"
          }),
          customClass: "mb-5"
        })
      ]
    });
  }
  function B() {
    switch (H) {
      default:
        return V();
      case "initial":
        return V();
      case "error":
        return /* @__PURE__ */ i.jsx(ys, {
          title: o._({
            id: "rz8jSA"
          }),
          description: o._({
            id: "RxIw8D"
          })
        });
    }
  }
  return K ? /* @__PURE__ */ i.jsx(es, {
    variant: "fullpage"
  }) : B();
}
function ii({ editOrder: s, handleGoToPlansList: N }) {
  const y = s == null ? void 0 : s.state.toLowerCase(), [K, L] = n(""), [H, U] = n(""), [M, k] = n(""), { fpapi: A } = os(ts), { tenantConfig: I, amcs: x, banks: w } = is(), R = I == null ? void 0 : I.fallbackLogo, q = I == null ? void 0 : I.showSchemeLogo, [Z, C] = n({}), [g, _] = n(!1), [E, h] = n(null), [v, F] = n(null), G = async () => {
    _(!0);
    const t = (s == null ? void 0 : s.scheme) || (s == null ? void 0 : s.switch_in_scheme);
    A.fpClient.master_data().fetchScheme(t).then(($) => {
      c($);
    }).catch(($) => {
      window.debug.log($), Y($);
    });
  };
  function c(t) {
    if (!t)
      C([]);
    else {
      const $ = q ? {
        ...t,
        schemeLogo: x[t == null ? void 0 : t.amc_id] || R
      } : {
        ...t
      };
      C($);
    }
    _(!1);
  }
  ms(() => {
    y === "active" && (L(`${hs(s == null ? void 0 : s.id)} updated`), U("successInfo")), k(o._({
      id: "d9gGIK"
    })), G(), p();
  }, []);
  const p = async () => {
    if (s != null && s.previousPaymentMethod)
      try {
        _(!0);
        const t = [], $ = A.fpClient.mandates().fetch(s == null ? void 0 : s.payment_source);
        if (t.push($), s != null && s.previouosPaymentSource && (s == null ? void 0 : s.previousPaymentMethod) === "mandate") {
          const B = A.fpClient.mandates().fetch(s.previouosPaymentSource);
          t.push(B);
        }
        const z = await A.getInvestorProfile(), j = await A.fpClient.bank_accounts().fetchAll({
          profile: z == null ? void 0 : z.id
        }), V = await Promise.all(t);
        j.data.forEach((B) => {
          V[0].bank_account_id === B.old_id && (V[0].bank = {
            bank_name: B.bank_name,
            number: B.account_number,
            ifsc: B.ifsc_code
          }), V[1] && V[1].bank_account_id === B.old_id && (V[1].bank = {
            bank_name: B.bank_name,
            number: B.account_number,
            ifsc: B.ifsc_code
          });
        }), F(V[0]), V.length > 1 && h(V[1]), _(!1);
      } catch (t) {
        window.debug.error(t), Y(t);
      }
  };
  function X() {
    var t, $, z, j, V, B;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(fs, {
          scheme: Z
        }),
        /* @__PURE__ */ i.jsx(ws, {
          title: K,
          description: `Your ${(t = hs(s == null ? void 0 : s.id)) == null ? void 0 : t.toLowerCase()} reference is ${s == null ? void 0 : s.id}`,
          iconName: H
        }),
        /* @__PURE__ */ i.jsxs(As, {
          children: [
            /* @__PURE__ */ i.jsxs("div", {
              className: "flex flex-wrap items-center -mr-2 -ml-2 px-4 pt-2",
              children: [
                !(s != null && s.previousAmount) && !(s != null && s.previousUnits) ? /* @__PURE__ */ i.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "KqeLJ1"
                    }),
                    value: s != null && s.amount ? `₹ ${T(s == null ? void 0 : s.amount, 2)}` : "-"
                  })
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousAmount || s != null && s.previousUnits ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: s != null && s.previousAmount ? o._({
                          id: "aoh6Eh"
                        }) : o._({
                          id: "ATWL+3"
                        }),
                        value: s != null && s.previousAmount ? `₹ ${T(s == null ? void 0 : s.previousAmount, 2)}` : s.previousUnits ? s.previousUnits : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: s != null && s.amount ? o._({
                          id: "bjwBd3"
                        }) : o._({
                          id: "uQVwS2"
                        }),
                        value: s != null && s.amount ? `₹ ${T(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousInstallmentDay ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "3+LOee"
                        }),
                        value: s != null && s.previousInstallmentDay ? Ss(s == null ? void 0 : s.previousInstallmentDay) : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "VXfUoN"
                        }),
                        value: s != null && s.installment_day ? Ss(s == null ? void 0 : s.installment_day) : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousInstallments ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "CClCym"
                        }),
                        value: s != null && s.previousInstallments ? s == null ? void 0 : s.previousInstallments : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "5q9LAN"
                        }),
                        value: s != null && s.number_of_installments ? s == null ? void 0 : s.number_of_installments : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                /* @__PURE__ */ i.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "UMXPdU"
                    }),
                    value: s && s.frequency ? ($ = _s(s.frequency)) == null ? void 0 : $.replace(/_/g, " ") : "-"
                  })
                })
              ]
            }),
            E ? /* @__PURE__ */ i.jsxs("section", {
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "QU/PK0"
                    }),
                    background: !0,
                    value: `${E.bank.bank_name} - ****${E.bank.number.slice(-4)}`,
                    logo: w[E.bank.ifsc.slice(0, 4)]
                  })
                }),
                /* @__PURE__ */ i.jsxs("div", {
                  className: "-mr-2 -ml-2 px-4 pt-2 flex flex-wrap items-center",
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "5zmJqL"
                        }),
                        value: `₹ ${ps(E.mandate_limit)}`
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "RdQmLp"
                        }),
                        value: (z = as[E.mandate_status]) == null ? void 0 : z.value,
                        icon: (j = as[E.mandate_status]) == null ? void 0 : j.icon,
                        iconPlacement: "left"
                      })
                    })
                  ]
                })
              ]
            }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
            v ? /* @__PURE__ */ i.jsxs("section", {
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "40IXuE"
                    }),
                    background: !0,
                    value: `${v.bank.bank_name} - ****${v.bank.number.slice(-4)}`,
                    logo: w[v.bank.ifsc.slice(0, 4)]
                  })
                }),
                /* @__PURE__ */ i.jsxs("div", {
                  className: "-mr-2 -ml-2 px-4 pt-2 flex flex-wrap items-center",
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "SSgIhw"
                        }),
                        value: `₹ ${ps(v.mandate_limit)}`
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "xtmxre"
                        }),
                        value: (V = as[v.mandate_status]) == null ? void 0 : V.value,
                        icon: (B = as[v.mandate_status]) == null ? void 0 : B.icon,
                        iconPlacement: "left"
                      })
                    })
                  ]
                })
              ]
            }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
          ]
        }),
        /* @__PURE__ */ i.jsx(J, {
          type: "important",
          variant: "spot",
          content: o._({
            id: "24gA27"
          }),
          customClass: "mb-5"
        }),
        N && /* @__PURE__ */ i.jsx(ss, {
          fullwidth: !0,
          onClick: N,
          children: M
        })
      ]
    });
  }
  return g ? /* @__PURE__ */ i.jsx(es, {
    variant: "fullpage"
  }) : X();
}
function ai({ currentPlanObject: s, handlePauseCompletion: N }) {
  const { tenantConfig: y, amcs: K } = is(), L = y == null ? void 0 : y.fallbackLogo, H = y == null ? void 0 : y.showSchemeLogo, { fpapi: U } = os(ts), [M, k] = n(!1), A = /* @__PURE__ */ new Date(), [I, x] = n({}), [w, R] = n({}), [q, Z] = n("initial"), [C, g] = n([]), [_, E] = n(0), h = ks(() => ({
    pause_plan_instl_type: "installments",
    pause_plan_start_date: C != null && C.includes(new Date((/* @__PURE__ */ new Date()).setDate(A.getDate() + 2)).toDateString()) ? null : new Date((/* @__PURE__ */ new Date()).setDate(A.getDate() + 2))
  }), [
    C
  ]);
  ms(() => {
    X(), c();
  }, [
    s
  ]);
  function v(u, m) {
    const a = new Date(m);
    return a.setDate(a.getDate() + u), a;
  }
  function F(u, m) {
    const a = [];
    let l = new Date(u);
    for (; l.valueOf() <= new Date(m).valueOf(); )
      a.push(new Date(l).toDateString()), l = new Date(v(1, l));
    return a;
  }
  const G = (u) => u || (s == null ? void 0 : s.end_date), c = async () => {
    k(!0);
    try {
      (await U.fpClient.skip_instructions().fetchAll((s == null ? void 0 : s.object) + "s", s == null ? void 0 : s.id)).data.map((a) => {
        const l = F(new Date(a.from_date), new Date(G(a.to_date)));
        g((D) => D.concat(l)), E((D) => D + Number(a.remaining_installments) + Number(a.skipped_installments));
      }), k(!1);
    } catch {
      Z("error"), k(!1);
    }
  }, p = () => {
    let u = 1;
    const m = s.start_date ? new Date(s.start_date) : /* @__PURE__ */ new Date();
    return s != null && s.systematic || (u = $()), new Date(m == null ? void 0 : m.getFullYear(), (m == null ? void 0 : m.getMonth()) + Ns[s == null ? void 0 : s.frequency] * u, m == null ? void 0 : m.getDate());
  }, X = async () => {
    k(!0);
    const u = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    let m;
    try {
      m = await U.fpClient.master_data().fetchScheme(u);
    } catch (D) {
      window.debug.error(D), Y(D);
    }
    let a = {};
    if ((s == null ? void 0 : s.object) === "mf_switch_plan")
      try {
        a = await U.fpClient.master_data().fetchScheme(s == null ? void 0 : s.switch_out_scheme), R(a);
      } catch (D) {
        window.debug.error(D), Y(D);
      }
    const l = H ? {
      name: m == null ? void 0 : m.name,
      investment_option: m == null ? void 0 : m.investment_option,
      schemeLogo: K[m == null ? void 0 : m.amc_id] || L
    } : {
      name: m == null ? void 0 : m.name,
      investment_option: m == null ? void 0 : m.investment_option
    };
    x(l), k(!1);
  }, t = async ({ installments: u, pause_plan_end_date: m, pause_plan_instl_type: a }) => {
    let l;
    const D = new Date(s == null ? void 0 : s.next_installment_date);
    a === "installments" ? (l = new Date(D.setMonth(D.getMonth() + (u - 1) * Ns[s == null ? void 0 : s.frequency])), l = ns(new Date(l), "yyyy-mm-dd")) : a === "fixed" && (l = ns(new Date(m), "yyyy-mm-dd"));
    const W = ns(new Date((/* @__PURE__ */ new Date()).setDate(A.getDate() + 1)), "yyyy-mm-dd");
    try {
      k(!0);
      const S = await U.fpClient.skip_instructions().create(s.object + "s", s.id, {
        from: W,
        to: l
      });
      N(S), k(!1);
    } catch (S) {
      k(!1), Z("error"), Y(S);
    }
  }, $ = () => Math.floor((s == null ? void 0 : s.remaining_installments) / 2) > 10 ? 10 : Math.floor((s == null ? void 0 : s.remaining_installments) / 2), z = () => _ >= 2 ? 0 : 2 - _, j = (u) => !!(C != null && C.includes(new Date(u).toDateString()));
  function V() {
    var u;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(fs, {
          scheme: I
        }),
        /* @__PURE__ */ i.jsx(ls, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              Rs(w) ? /* @__PURE__ */ i.jsx(i.Fragment, {}) : /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-full ",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "6Xqu8f"
                  }),
                  logo: H && (w != null && w.amc_id) ? K[w == null ? void 0 : w.amc_id] || L : "",
                  value: Vs(w.name)
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: s != null && s.amount ? o._({
                    id: "KqeLJ1"
                  }) : o._({
                    id: "6ssn5V"
                  }),
                  value: s != null && s.amount ? `₹ ${T(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (u = _s(s.frequency)) == null ? void 0 : u.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(J, {
          type: "important",
          variant: "spot",
          content: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "uVHufo",
              values: {
                0: ns(new Date(s == null ? void 0 : s.next_installment_date), "dd-MMM-yyyy")
              }
            })
          }),
          customClass: "mb-5"
        }),
        /* @__PURE__ */ i.jsx(gs, {
          onSubmit: t,
          validate: (m) => {
            const a = {};
            m.installments <= 0 && (a.installments = o._({
              id: "L9XIrm",
              values: {
                0: z()
              }
            })), s != null && s.remaining_installments && m.installments > z() && (s != null && s.systematic) && (a.installments = o._({
              id: "B29U8p",
              values: {
                0: z()
              }
            })), s != null && s.remaining_installments && m.installments > $() && !(s != null && s.systematic) && (a.installments = o._({
              id: "B29U8p",
              values: {
                0: $()
              }
            }));
            const l = h.pause_plan_start_date ? h.pause_plan_start_date : new Date((/* @__PURE__ */ new Date()).setDate(A.getDate() + 2));
            return (new Date(m.pause_plan_end_date).valueOf() < new Date(l).valueOf() || new Date(m.pause_plan_end_date).valueOf() > new Date(p()).valueOf()) && (s != null && s.systematic) && (a.pause_plan_end_date = o._({
              id: "B29U8p",
              values: {
                0: z()
              }
            })), (new Date(m.pause_plan_end_date).valueOf() < new Date(l).valueOf() || new Date(m.pause_plan_end_date).valueOf() > new Date(p()).valueOf()) && !(s != null && s.systematic) && (a.pause_plan_end_date = o._({
              id: "B29U8p",
              values: {
                0: $()
              }
            })), a;
          },
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (m) => /* @__PURE__ */ i.jsxs("form", {
            onSubmit: m.handleSubmit,
            children: [
              /* @__PURE__ */ i.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ i.jsx(b, {
                    message: o._({
                      id: "pgRnHV"
                    })
                  }),
                  /* @__PURE__ */ i.jsx(O, {
                    type: "radio",
                    name: "pause_plan_instl_type",
                    initialValue: h.pause_plan_instl_type,
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: P(r),
                    children: (a) => /* @__PURE__ */ i.jsx(i.Fragment, {
                      children: /* @__PURE__ */ i.jsx(Fs, {
                        name: a.input.name,
                        group: [
                          {
                            label: o._({
                              id: "iHHiiF"
                            }),
                            value: "installments"
                          },
                          {
                            label: o._({
                              id: "cE8shf"
                            }),
                            value: "fixed"
                          }
                        ],
                        size: "sm",
                        onChange: (l, D) => {
                          a.input.onChange(D.value);
                        },
                        disabled: []
                      })
                    })
                  })
                ]
              }),
              m.values.pause_plan_instl_type === "installments" && /* @__PURE__ */ i.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ i.jsx(b, {
                    message: o._({
                      id: "ZS3Gyi"
                    })
                  }),
                  /* @__PURE__ */ i.jsx(O, {
                    name: "installments",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: P(r),
                    children: (a) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                      children: [
                        /* @__PURE__ */ i.jsx(xs, {
                          id: a.input.name,
                          autoComplete: "off",
                          name: a.input.name,
                          onChange: a.input.onChange,
                          placeholder: o._({
                            id: "eEWdWP"
                          }),
                          status: a.meta.error && a.meta.touched ? "error" : void 0,
                          onInput: (l) => {
                            l.target.value = l.target.value.replace(ds("0-9"), "");
                          },
                          autoFocus: !0
                        }),
                        a.meta.error && a.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ i.jsx(J, {
                            type: "error",
                            variant: "field",
                            title: a.meta.error
                          })
                        }) : s != null && s.systematic ? /* @__PURE__ */ i.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ i.jsx(J, {
                            type: "helper",
                            variant: "field",
                            title: `Maximum installments that can be skipped is ${z()}`
                          })
                        }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
                      ]
                    })
                  })
                ]
              }),
              m.values.pause_plan_instl_type === "fixed" && /* @__PURE__ */ i.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ i.jsx(b, {
                    message: o._({
                      id: "BrsP9r"
                    })
                  }),
                  /* @__PURE__ */ i.jsx(O, {
                    name: "pause_plan_end_date",
                    initialValue: h.pause_plan_start_date,
                    allowNull: !0,
                    validate: P(r),
                    children: (a) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                      children: [
                        /* @__PURE__ */ i.jsx(Ds, {
                          variant: "keyboardInputDOB",
                          onKeyDown: (l) => l.preventDefault(),
                          disableFuture: !1,
                          name: a.input.name,
                          value: a.input.value,
                          format: "dd-MMM-yyyy",
                          minDate: h.pause_plan_start_date ?? new Date((/* @__PURE__ */ new Date()).setDate(A.getDate() + 2)),
                          maxDate: p(),
                          placeholder: h.pause_plan_start_date ? "" : "Select skip end date",
                          selectedDate: a.input.value,
                          shouldDisableDate: j,
                          error: a.meta.error && a.meta.touched,
                          views: [
                            "date"
                          ],
                          handleDateChange: (l) => {
                            a.input.onChange(l);
                          }
                        }),
                        a.meta.error && a.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ i.jsx(J, {
                            type: "error",
                            variant: "field",
                            title: a.meta.error
                          })
                        }) : s != null && s.systematic ? /* @__PURE__ */ i.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ i.jsx(J, {
                            type: "helper",
                            variant: "field",
                            title: `Maximum installments that can be skipped is ${z()}`
                          })
                        }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ i.jsx(vs, {
                children: /* @__PURE__ */ i.jsx("div", {
                  className: "mb-4 pt-1",
                  children: /* @__PURE__ */ i.jsx(ss, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: o._({
                      id: "H/E6fZ"
                    })
                  })
                })
              })
            ]
          })
        })
      ]
    });
  }
  function B() {
    switch (q) {
      default:
        return V();
      case "initial":
        return V();
      case "error":
        return /* @__PURE__ */ i.jsx(ys, {
          title: o._({
            id: "fgTdCR"
          }),
          description: o._({
            id: "QEKMeg"
          })
        });
    }
  }
  return M ? /* @__PURE__ */ i.jsx(es, {
    variant: "fullpage"
  }) : B();
}
function oi({ pauseOrder: s, handleGoToPlansList: N }) {
  const y = s == null ? void 0 : s.state.toLowerCase(), [K, L] = n(""), [H, U] = n(""), [M, k] = n(""), [A, I] = n(""), { fpapi: x } = os(ts), { tenantConfig: w, amcs: R } = is(), q = w == null ? void 0 : w.fallbackLogo, Z = w == null ? void 0 : w.showSchemeLogo, [C, g] = n({}), [_, E] = n(!1), [h, v] = n({}), F = async () => {
    E(!0);
    let p = "";
    const X = Ts(s == null ? void 0 : s.plan);
    if (X === "mf_purchase_plan") {
      const t = await x.fpClient.mf_purchase_plans().fetch(s == null ? void 0 : s.plan);
      v(t), p = t == null ? void 0 : t.scheme;
    } else if (X === "mf_redemption_plan") {
      const t = await x.fpClient.mf_redemption_plans().fetch(s == null ? void 0 : s.plan);
      v(t), p = t == null ? void 0 : t.scheme;
    } else if (X === "mf_switch_plan") {
      const t = await x.fpClient.mf_switch_plans().fetch(s == null ? void 0 : s.plan);
      v(t), p = t == null ? void 0 : t.switch_in_scheme;
    }
    x.fpClient.master_data().fetchScheme(p).then((t) => {
      G(t);
    }).catch((t) => {
      window.debug.log(t), Y(t);
    });
  };
  function G(p) {
    if (!p)
      g({});
    else {
      const X = Z ? {
        ...p,
        schemeLogo: R[p == null ? void 0 : p.amc_id] || q
      } : {
        ...p
      };
      g(X);
    }
    E(!1);
  }
  ms(() => {
    (y === "active" || y === "pending") && (L(`${Bs(s == null ? void 0 : s.plan)} plan skipped`), U(o._({
      id: "xz7+py",
      values: {
        0: s == null ? void 0 : s.id
      }
    })), I(o._({
      id: "d9gGIK"
    })), k("successInfo")), F();
  }, []);
  function c() {
    var p;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(fs, {
          scheme: C
        }),
        /* @__PURE__ */ i.jsxs(ws, {
          title: K,
          description: H,
          iconName: M,
          children: [
            /* @__PURE__ */ i.jsx(ls, {
              children: /* @__PURE__ */ i.jsxs("div", {
                className: "flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ i.jsx("div", {
                    className: "w-1/2",
                    children: /* @__PURE__ */ i.jsx(d, {
                      label: h != null && h.amount ? o._({
                        id: "KqeLJ1"
                      }) : o._({
                        id: "6ssn5V"
                      }),
                      value: h != null && h.amount ? `₹ ${T(h == null ? void 0 : h.amount, 2)}` : h.units ? h.units : "-"
                    })
                  }),
                  /* @__PURE__ */ i.jsx("div", {
                    className: "w-1/2",
                    children: /* @__PURE__ */ i.jsx(d, {
                      label: o._({
                        id: "UMXPdU"
                      }),
                      value: h && h.frequency ? (p = _s(h.frequency)) == null ? void 0 : p.replace(/_/g, " ") : "-"
                    })
                  })
                ]
              })
            }),
            /* @__PURE__ */ i.jsx(qs, {
              tableData: [
                {
                  label: o._({
                    id: "iFYyXY"
                  }),
                  value: s != null && s.to_date ? `${ns(new Date(s == null ? void 0 : s.to_date), "dd-MMM-yyyy")}` : "-"
                },
                {
                  label: o._({
                    id: "ZS3Gyi"
                  }),
                  value: s != null && s.remaining_installments ? `${s == null ? void 0 : s.remaining_installments}` : "-"
                }
              ]
            })
          ]
        }),
        N && /* @__PURE__ */ i.jsx(ss, {
          fullwidth: !0,
          onClick: N,
          children: A
        })
      ]
    });
  }
  return _ ? /* @__PURE__ */ i.jsx(es, {
    variant: "fullpage"
  }) : c();
}
function mi({ currentPlanObject: s, handleStopCompletion: N }) {
  const { tenantConfig: y, amcs: K } = is(), L = y == null ? void 0 : y.fallbackLogo, H = y == null ? void 0 : y.showSchemeLogo, { fpapi: U } = os(ts), [M, k] = n(!1), [A, I] = n({}), [x, w] = n("initial");
  ms(() => {
    R();
  }, [
    s
  ]);
  const R = async () => {
    k(!0);
    const g = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    let _;
    try {
      _ = await U.fpClient.master_data().fetchScheme(g);
    } catch (h) {
      window.debug.error(h), Y(h);
    }
    const E = H ? {
      name: _ == null ? void 0 : _.name,
      investment_option: _ == null ? void 0 : _.investment_option,
      schemeLogo: K[_ == null ? void 0 : _.amc_id] || L
    } : {
      name: _ == null ? void 0 : _.name,
      investment_option: _ == null ? void 0 : _.investment_option
    };
    I(E), k(!1);
  }, q = async () => {
    k(!0);
    try {
      let g = {};
      (s == null ? void 0 : s.object) === "mf_purchase_plan" ? g = await U.fpClient.mf_purchase_plans().cancel(s == null ? void 0 : s.id) : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? g = await U.fpClient.mf_redemption_plans().cancel(s == null ? void 0 : s.id) : (s == null ? void 0 : s.object) === "mf_switch_plan" && (g = await U.fpClient.mf_switch_plans().cancel(s == null ? void 0 : s.id)), k(!1), N(g);
    } catch (g) {
      k(!1), w("error"), Y(g);
    }
  };
  function Z() {
    var g;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(fs, {
          scheme: A
        }),
        /* @__PURE__ */ i.jsx(ls, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: s != null && s.amount ? o._({
                    id: "KqeLJ1"
                  }) : o._({
                    id: "6ssn5V"
                  }),
                  value: s != null && s.amount ? `₹ ${T(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (g = _s(s.frequency)) == null ? void 0 : g.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(J, {
          type: "alert",
          variant: "flash",
          title: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "qULvXd",
              values: {
                0: s != null && s.object ? s == null ? void 0 : s.object.split("_")[1] : ""
              }
            })
          }),
          customClass: "mb-5"
        }),
        /* @__PURE__ */ i.jsx(gs, {
          onSubmit: q,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (_) => /* @__PURE__ */ i.jsx("form", {
            onSubmit: _.handleSubmit,
            children: /* @__PURE__ */ i.jsx(vs, {
              children: /* @__PURE__ */ i.jsx("div", {
                className: "mb-4 pt-1",
                children: /* @__PURE__ */ i.jsx(ss, {
                  variant: "primary",
                  type: "submit",
                  fullwidth: !0,
                  children: o._({
                    id: "H/E6fZ"
                  })
                })
              })
            })
          })
        })
      ]
    });
  }
  function C() {
    switch (x) {
      default:
        return Z();
      case "initial":
        return Z();
      case "error":
        return /* @__PURE__ */ i.jsx(ys, {
          title: o._({
            id: "sxmdml"
          }),
          description: o._({
            id: "QEKMeg"
          })
        });
    }
  }
  return M ? /* @__PURE__ */ i.jsx(es, {
    variant: "fullpage"
  }) : C();
}
function li({ currentPlanObject: s, handleGoToPlansList: N }) {
  const y = s == null ? void 0 : s.state.toLowerCase(), [K, L] = n(""), [H, U] = n(""), [M, k] = n(""), { fpapi: A } = os(ts), { tenantConfig: I, amcs: x } = is(), w = I == null ? void 0 : I.fallbackLogo, R = I == null ? void 0 : I.showSchemeLogo, [q, Z] = n({}), [C, g] = n(!1), _ = async () => {
    g(!0);
    const v = (s == null ? void 0 : s.scheme) || (s == null ? void 0 : s.switch_in_scheme);
    A.fpClient.master_data().fetchScheme(v).then((F) => {
      E(F);
    }).catch((F) => {
      window.debug.log(F), Y(F);
    });
  };
  function E(v) {
    if (!v)
      Z([]);
    else {
      const F = R ? {
        ...v,
        schemeLogo: x[v == null ? void 0 : v.amc_id] || w
      } : {
        ...v
      };
      Z(F);
    }
    g(!1);
  }
  ms(() => {
    (y === "failed" || y === "cancelled") && (L(o._({
      id: "2i30Zx",
      values: {
        0: hs(s == null ? void 0 : s.id)
      }
    })), k(o._({
      id: "9jt2i+"
    })), U("successInfo")), _();
  }, []);
  function h() {
    var v, F;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(fs, {
          scheme: q
        }),
        /* @__PURE__ */ i.jsx(ws, {
          title: K,
          description: `Your ${(v = hs(s == null ? void 0 : s.id)) == null ? void 0 : v.toLowerCase()} reference is ${s == null ? void 0 : s.id}`,
          iconName: H,
          children: /* @__PURE__ */ i.jsx(ls, {
            children: /* @__PURE__ */ i.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  className: "w-1/2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: s != null && s.amount ? o._({
                      id: "KqeLJ1"
                    }) : o._({
                      id: "6ssn5V"
                    }),
                    value: s != null && s.amount ? `₹ ${T(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                  })
                }),
                /* @__PURE__ */ i.jsx("div", {
                  className: "w-1/2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "UMXPdU"
                    }),
                    value: s && s.frequency ? (F = _s(s.frequency)) == null ? void 0 : F.replace(/_/g, " ") : "-"
                  })
                })
              ]
            })
          })
        }),
        /* @__PURE__ */ i.jsx(J, {
          type: "important",
          variant: "spot",
          content: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "wxJxUZ"
            })
          }),
          customClass: "mb-5"
        }),
        N && /* @__PURE__ */ i.jsx(ss, {
          fullwidth: !0,
          onClick: N,
          children: M
        })
      ]
    });
  }
  return C ? /* @__PURE__ */ i.jsx(es, {
    variant: "fullpage"
  }) : h();
}
export {
  ii as E,
  oi as P,
  li as S,
  mi as a,
  ai as b,
  si as c
};
