import { j as a } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as g } from "react";
import { O as p, M as x, B as f, b as C } from "./index.esm-CqWfms5D.js";
import { t as b, k as c } from "./utils-C2gHLhxV.js";
import { i as u } from "./index-CrDCxF0S.js";
import { S as T } from "./StatusMessage-BUdCbwjZ.js";
const m = {
  aadhaar_number: "Last 4 digits of Aadhaar number",
  pan: "PAN",
  name: "Name as per PAN",
  father_name: "Father's name in full",
  spouse_name: "Spouse's name in full",
  mother_name: "Mother's name in full",
  email: "Email address",
  "mobile.isd": "Mobile number code",
  "mobile.number": "Mobile Number",
  signature: "Signature",
  photo: "Photo",
  gender: "Gender",
  date_of_birth: "Date of birth",
  marital_status: "Marital status",
  country_of_birth: "Country of birth",
  "address.city": "City",
  "address.proof": "Address proof (Front)",
  "address.line_1": "Line 1",
  "address.line_2": "Line 2",
  "address.line_3": "Line 3",
  "address.country": "Country",
  "address.pincode": "Pin code",
  "address.proof_back": "Address proof (Back)",
  "address.proof_type": "Address proof type",
  "address.proof_number": "Address proof number",
  "address.proof_issue_date": "Address proof issue date",
  "address.proof_expiry_date": "Address proof expiry date",
  identity_proof: "PAN card proof document",
  residential_status: "Resident status",
  occupation_type: "Occupation",
  otp: "Verification code for the IPV video",
  "geolocation.latitude": "Geolocation latitude",
  "geolocation.longitude": "Geolocation longitude",
  "bank_account.account_holder_name": "Bank account holder name",
  "bank_account.account_number": "Account number",
  "bank_account.ifsc_code": "IFSC",
  "bank_account.proof": "Cancelled cheque"
}, S = {
  pending: "Your KYC request has been created. It will be submitted when all the required fields are provided.",
  esign_required: "Investor details document needs to be eSigned for the KYC request to be processed further.",
  submitted: "Your KYC request has been submitted for further processing.",
  successful: "Your KYC request has been successfully processed.",
  rejected: "Your KYC request has been rejected.",
  expired: "Your KYC request has expired."
}, Y = {
  pending: "Alert",
  esign_required: "Alert",
  submitted: "successInfo",
  successful: "successInfo",
  rejected: "Error",
  expired: "Error"
}, v = {
  pending: "Awaiting details",
  esign_required: "Awaiting e-sign",
  submitted: "Verification in progress",
  successful: "Initial verification complete",
  rejected: "Verification failed",
  expired: "Expired"
}, _ = {
  pending: "Complete KYC",
  esign_required: "Complete e-sign",
  submitted: "",
  successful: "",
  rejected: "Resubmit KYC",
  expired: "Resubmit KYC"
}, K = {
  pending: "handlePendingAction",
  esign_required: "handleEsignAction",
  submitted: "",
  successful: "handleSuccessfulAction",
  rejected: "handleRejectedAction",
  expired: "handleExpiredAction"
}, A = (e) => {
  const s = c(new Date(e == null ? void 0 : e.expires_at), "dd-MMM-yyyy"), r = e.status;
  return {
    pending: `All details required for a KYC request must be completed within 5 days of creation. You can complete this request before ${s}.`,
    esign_required: `All details required for a KYC request must be completed within 5 days of creation. You can e-sign this request before ${s}.`,
    submitted: "Your KYC request will be verified within 3 business days from the date of e-sign completion.",
    successful: "Further verification of your KYC request will be completed within 2 business days.",
    rejected: "Your KYC request verification has failed due to below reasons.",
    expired: "Your KYC request verification has expired as all details were not provided within 5 calender days. Please create a new request."
  }[r];
}, I = {
  pending: "important",
  esign_required: "important",
  submitted: "important",
  successful: "important",
  rejected: "important",
  expired: "important"
}, E = (e, s) => {
  var n, l;
  const r = e.status, i = `Your KYC request reference is ${e == null ? void 0 : e.id}`, o = {};
  if (r === "rejected") {
    let t = (n = e == null ? void 0 : e.verification) == null ? void 0 : n.details_verbose;
    if (b.isStagingOrSandbox && (t = {
      pan: {
        code: "data_mismatch",
        reason: "Invalid pan"
      },
      signature: {
        code: "data_missing",
        reason: "Signature not available"
      },
      photo: {
        code: "data_missing",
        reason: "Photo not available"
      },
      country_of_birth: {
        code: "data_missing",
        reason: "Country of birth not available"
      },
      "bank_account.proof": {
        code: "data_missing",
        reason: "Cancelled cheque not valid"
      },
      occupation_type: {
        code: "data_missing",
        reason: "Occupation not available"
      },
      "address.proof": {
        code: "document_invalid",
        reason: "Proof of address not valid; Translation into english is required"
      }
    }), t)
      for (const d in t)
        Object.prototype.hasOwnProperty.call(t, d) && m[d] && (o[m[d]] = (l = t[d]) == null ? void 0 : l.reason);
  }
  return {
    title: v[r],
    description: i,
    icon: Y[r],
    subtitle: S[r],
    actionButton: {
      shouldRender: !!_[r] && s[r],
      text: _[r],
      handler: K[r]
    },
    helpText: A(e),
    helpTextType: I[r],
    rejectedStatusDetailsVerbose: o
  };
};
function F({ kycRequestObject: e, handlePendingAction: s, handleEsignAction: r, handleRejectedAction: i, handleExpiredAction: o }) {
  const [n, l] = g(!1), t = E(e, {
    pending: !!s,
    esign_required: !!r,
    submitted: !1,
    successful: !1,
    rejected: !!i,
    expired: !!o
  }), d = () => {
    switch (t.actionButton.handler) {
      case "handlePendingAction": {
        s && s();
        break;
      }
      case "handleEsignAction": {
        r && r();
        break;
      }
      case "handleRejectedAction": {
        i && i();
        break;
      }
      case "handleExpiredAction": {
        o && o();
        break;
      }
    }
  };
  return /* @__PURE__ */ a.jsx(a.Fragment, {
    children: e && t ? /* @__PURE__ */ a.jsxs(a.Fragment, {
      children: [
        /* @__PURE__ */ a.jsx(T, {
          title: t.title,
          description: t.description,
          iconName: t.icon,
          children: /* @__PURE__ */ a.jsx(p, {
            // title={statusDetails.subtitle}
            tableData: [
              {
                label: u._({
                  id: "m9snku"
                }),
                value: e == null ? void 0 : e.pan
              },
              {
                label: u._({
                  id: "82aa3s"
                }),
                value: e != null && e.created_at ? c(new Date(e == null ? void 0 : e.created_at), "dd-MMM-yyyy") : "-"
              }
            ]
          })
        }),
        t.helpText && t.helpTextType ? /* @__PURE__ */ a.jsx(x, {
          type: t.helpTextType,
          variant: "flash",
          title: t.helpText,
          customClass: "mb-5"
        }) : /* @__PURE__ */ a.jsx(a.Fragment, {}),
        /* @__PURE__ */ a.jsx(h, {
          rejectedStatusDetails: t.rejectedStatusDetailsVerbose,
          limitDisplay: 5
        }),
        Object.keys(t.rejectedStatusDetailsVerbose).length > 5 && /* @__PURE__ */ a.jsx("div", {
          className: "flex justify-end",
          children: /* @__PURE__ */ a.jsx(f, {
            type: "button",
            variant: "text",
            fullwidth: !0,
            customClass: "mt-1 text-size-14 font-semibold",
            onClick: () => {
              l(!0);
            },
            children: u._({
              id: "UZDSDj"
            })
          })
        }),
        n && /* @__PURE__ */ a.jsx(C, {
          variant: "fullPage",
          title: "KYC rejection details",
          isOpen: n,
          contentHeightMax: !0,
          toggleModal: () => {
            l(!1);
          },
          children: /* @__PURE__ */ a.jsx(h, {
            kycRequestObject: e,
            rejectedStatusDetails: t.rejectedStatusDetailsVerbose
          })
        }),
        t.actionButton.shouldRender ? /* @__PURE__ */ a.jsx(f, {
          variant: "primary",
          fullwidth: !0,
          onClick: d,
          customClass: "mt-4",
          children: t.actionButton.text
        }) : /* @__PURE__ */ a.jsx(a.Fragment, {})
      ]
    }) : /* @__PURE__ */ a.jsx(a.Fragment, {})
  });
}
function h({ kycRequestObject: e, rejectedStatusDetails: s, limitDisplay: r }) {
  const i = r ? Object.keys(s).slice(0, r) : Object.keys(s), o = i.map((n) => ({
    label: n,
    value: s[n],
    colSpan: "basis-full"
  }));
  return /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      e && /* @__PURE__ */ a.jsx(p, {
        // title={statusDetails.subtitle}
        tableData: [
          {
            label: u._({
              id: "m9snku"
            }),
            value: e == null ? void 0 : e.pan
          },
          {
            label: u._({
              id: "82aa3s"
            }),
            value: e != null && e.created_at ? c(new Date(e == null ? void 0 : e.created_at), "dd-MMM-yyyy") : "-"
          }
        ]
      }),
      i.length ? /* @__PURE__ */ a.jsx(p, {
        view: "error",
        tableData: o
      }) : /* @__PURE__ */ a.jsx(a.Fragment, {})
    ]
  });
}
export {
  F as K
};
