import { j as l } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as Y, useState as j, useEffect as $, useRef as Se } from "react";
import { F as fe } from "./provider-DKLaKIVD.js";
import { S as ie, c as B, a as ue, d as he, i as ye, M as be, F as Ce, B as ce, b as Le } from "./index.esm-CqWfms5D.js";
import { r as q } from "./sentry-CstOwvcR.js";
import { U as Fe, i as N, a as te, h as Oe, z as Ee, y as Ne, C as De, k as Te, E as Me, B as ve, D as Ie } from "./utils-C2gHLhxV.js";
import { D as Re } from "./DetailsCard-5b62SM_P.js";
import { u as se } from "./useConfiguration-B-lyaBB9.js";
import { i as g } from "./index-CrDCxF0S.js";
import { u as Ae } from "./useScrollToBottomRef-CqDzM0dm.js";
import { AppContext as Be } from "./AppContext.js";
import { ErrorPage as le } from "./ErrorPage.js";
const de = ({ isMFSD: F, bankDetails: s, paymentDetails: C }) => {
  const { tenantConfig: D, banks: f } = se(), b = D == null ? void 0 : D.fallbackLogo;
  return (() => {
    var O, U;
    return /* @__PURE__ */ l.jsx(ie, {
      header: {
        title: /* @__PURE__ */ l.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: g._({
            id: "hdSbNW"
          })
        })
      },
      children: /* @__PURE__ */ l.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          F ? /* @__PURE__ */ l.jsx(l.Fragment, {}) : /* @__PURE__ */ l.jsx("div", {
            className: "mb-3 w-full",
            children: /* @__PURE__ */ l.jsx(B, {
              label: g._({
                id: "/dl6Or"
              }),
              value: `${s == null ? void 0 : s.bank_name} (*****${(O = s == null ? void 0 : s.account_number) == null ? void 0 : O.slice(-4)})`,
              logo: f[(U = s == null ? void 0 : s.ifsc) == null ? void 0 : U.slice(0, 4)] || b
            })
          }),
          /* @__PURE__ */ l.jsx("div", {
            className: "mb-3 w-1/2",
            children: /* @__PURE__ */ l.jsx(B, {
              label: g._({
                id: "lFCwug"
              }),
              value: F ? "Salary" : Fe(C == null ? void 0 : C.method)
            })
          })
        ]
      })
    });
  })();
}, Ue = ({ transactionId: F, investor: s, isGroupInvestment: C, isPartner: D, handlePayment: f }) => {
  const { fpapi: b } = Y(fe), [X, O] = j({}), [U, H] = j({}), [J, z] = j({}), [V, S] = j(!1), [h, R] = j({}), [Z, v] = j({}), [m, p] = j({}), A = !C && f && !N(h) && (h == null ? void 0 : h.status) === "APPROVED";
  $(() => {
    (async () => {
      S(!0);
      try {
        let d;
        if (F) {
          const I = te(F);
          I === "Purchase" ? d = await b.fpClient.mf_purchases().fetch(F) : I === "Withdraw" ? d = await b.fpClient.mf_redemptions().fetch(F) : d = await b.fpClient.mf_switches().fetch(F), p(d);
        }
        const w = (d == null ? void 0 : d.object) === "mf_switch" ? d == null ? void 0 : d.switch_in_scheme : d == null ? void 0 : d.scheme, c = await b.fpClient.master_data().fetchScheme(w);
        if (H(c), (d == null ? void 0 : d.object) === "mf_switch") {
          const I = await b.fpClient.master_data().fetchScheme(d == null ? void 0 : d.switch_out_scheme);
          z(I);
        }
        S(!1);
      } catch (d) {
        window.debug.error(d), S(!1), q(d);
      }
    })();
  }, []);
  const K = async (d) => {
    S(!0);
    try {
      const w = await b.fpClient.payments().fetchAll({
        amc_order_ids: d
      });
      N(w == null ? void 0 : w.payments[0]) || R(w == null ? void 0 : w.payments[0]), S(!1);
    } catch (w) {
      window.debug.error(w), S(!1), q(w);
    }
  };
  return $(() => {
    (m == null ? void 0 : m.object) === "mf_purchase" && K(m == null ? void 0 : m.old_id);
  }, [
    m
  ]), $(() => {
    (async () => {
      if (s != null && s.id && (m != null && m.id)) {
        if (C) {
          S(!0);
          try {
            const d = await b.fpClient.mf_settlement_details().fetchAll({
              mf_purchase: m == null ? void 0 : m.id
            });
            N(d) || v(d), S(!1);
          } catch (d) {
            window.debug.error(d), S(!1), q(d);
          }
        } else if (!C && !N(h)) {
          S(!0);
          try {
            const d = h, { data: w } = await b.fpClient.bank_accounts().fetchAll({
              profile: s == null ? void 0 : s.id
            }), c = w.find((I) => I.old_id === (d == null ? void 0 : d.from_bank_account_id));
            O({
              bank_name: c == null ? void 0 : c.bank_name,
              account_number: c == null ? void 0 : c.account_number,
              ifsc: c == null ? void 0 : c.ifsc_code
            }), S(!1);
          } catch (d) {
            window.debug.error(d), S(!1), q(d);
          }
        }
      }
    })();
  }, [
    s == null ? void 0 : s.id,
    m == null ? void 0 : m.id,
    h,
    C
  ]), V ? /* @__PURE__ */ l.jsx(ue, {
    variant: "fullpage"
  }) : /* @__PURE__ */ l.jsxs("div", {
    children: [
      N(m) ? /* @__PURE__ */ l.jsx(l.Fragment, {}) : /* @__PURE__ */ l.jsx(Re, {
        order: m,
        isInstallmentListing: !1,
        handlePayment: f,
        scheme: U,
        switchOutScheme: J,
        isGroupInvestment: C,
        isPartner: D,
        paymentDetails: h
      }),
      N(Z) ? null : /* @__PURE__ */ l.jsx(de, {
        isMFSD: !0
      }),
      A ? /* @__PURE__ */ l.jsx(de, {
        isMFSD: !1,
        bankDetails: X,
        paymentDetails: h
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {})
    ]
  });
};
function ke({ isGroupInvestment: F, investor: s, mfInvestmentAccount: C, isPartner: D, searchParams: f }) {
  const { fpapi: b } = Y(fe), { amcs: X, tenantConfig: O } = se(), { AppState: { tenant: U } } = Y(Be), H = O == null ? void 0 : O.fallbackLogo, J = O == null ? void 0 : O.showSchemeLogo, [z, V] = j("initial"), [S, h] = j(!0), [R, Z] = j([]), [v, m] = j([]), [p, A] = j({}), [K, d] = j(!1), w = Se(null), [c, I] = j(null), k = Ae(c), [oe, G] = j(!1), [Q, me] = j(10);
  $(() => {
    z === "initial" && P();
  }, [
    z
  ]), $(() => {
    k.hasScrolledToBottom && pe();
  }, [
    k.hasScrolledToBottom
  ]), $(() => {
    var e;
    (e = w == null ? void 0 : w.current) == null || e.scrollIntoView({
      behavior: "instant",
      block: "center",
      inline: "center"
    });
  }, [
    K,
    S
  ]);
  const _e = async (e) => {
    const u = [];
    for (const o of e)
      try {
        const i = await b.fpClient.master_data().fetchScheme(o), t = J ? {
          ...i,
          schemeLogo: X[i == null ? void 0 : i.amc_id] || H
        } : {
          ...i
        };
        u.push(t);
      } catch (i) {
        window.debug.error(i), q(i);
      }
    return u;
  }, r = (e, u) => u == null ? void 0 : u.filter((i) => {
    let t, L, y;
    return e != null && e.folio_number && i.folio_number !== (e == null ? void 0 : e.folio_number) ? t = !1 : t = !0, e != null && e.isin && (i.scheme !== (e == null ? void 0 : e.isin) || i.plan) ? L = !1 : L = !0, e != null && e.status && (e == null ? void 0 : e.status.split(",").indexOf("failed")) != -1 && i.state === "failed" && i.failure_code !== "payment_failure" ? y = !1 : y = !0, t && L && y;
  }), n = async (e, u, o, i) => {
    const t = structuredClone(i);
    try {
      u = (await b.fpClient.payments().fetchAll({
        amc_order_ids: e.join()
      })).payments;
      let y = [];
      const T = await _e(o);
      return y = structuredClone(T), t.forEach((E) => {
        const _ = y.find((M) => M.isin === E.scheme);
        if (_ && (E.schemeDetails = _), u) {
          const M = u.filter((x) => {
            var W;
            return ((W = x == null ? void 0 : x.amc_order_ids) == null ? void 0 : W[0]) === (E == null ? void 0 : E.old_id);
          });
          E.paymentDetails = M;
        }
      }), t;
    } catch {
      V("error"), h(!1);
    }
  }, pe = async () => {
    if (Q >= v.length)
      return;
    G(!0);
    let e;
    if (C.id, f != null && f.status && f.status, v != null && v.length) {
      let u = v;
      if (f != null && f.folio_number || f != null && f.status || f != null && f.isin) {
        const _ = r(f, u);
        u = structuredClone(_);
      }
      const o = u.sort((_, M) => new Date(M == null ? void 0 : M.created_at).valueOf() - new Date(_ == null ? void 0 : _.created_at).valueOf()), i = Q + 10, t = o == null ? void 0 : o.slice(Q, i), L = structuredClone(t);
      me(i);
      const y = /* @__PURE__ */ new Set(), T = [];
      t.forEach((_) => {
        y.add(_ == null ? void 0 : _.scheme), T.push(_ == null ? void 0 : _.old_id);
      });
      const E = await n(T, e, y, t);
      N(p) ? A(L[0]) : A(p), Z((_) => _.concat(E)), G(!1);
    }
  }, P = async () => {
    var i;
    if (N(C)) {
      V("no_listing"), h(!1);
      return;
    }
    h(!0);
    let e, u;
    const o = {
      mf_investment_account: C.id
    };
    f != null && f.status && (o.states = f.status);
    try {
      e = await b.fpClient.mf_purchases().fetchAll(o), m(e == null ? void 0 : e.data);
    } catch (t) {
      window.debug.error(t), V("no_listing"), h(!1), q(t);
      return;
    }
    if ((i = e == null ? void 0 : e.data) != null && i.length) {
      let t = e.data;
      if (f != null && f.folio_number || f != null && f.status || f != null && f.isin) {
        const x = r(f, t);
        t = structuredClone(x);
      }
      const L = t.sort((x, W) => new Date(W == null ? void 0 : W.created_at).valueOf() - new Date(x == null ? void 0 : x.created_at).valueOf()), y = L == null ? void 0 : L.slice(0, Q), T = structuredClone(y), E = /* @__PURE__ */ new Set(), _ = [];
      y.forEach((x) => {
        E.add(x == null ? void 0 : x.scheme), _.push(x == null ? void 0 : x.old_id);
      });
      const M = await n(_, u, E, y);
      N(p) ? A(T[0]) : A(p), Z(M);
    } else
      V("no_listing");
    h(!1);
  }, we = (e) => {
    var T;
    const u = [
      "SUCCESS",
      "INITIATED",
      "APPROVED",
      "SUBMITTED",
      "PENDING"
    ], o = [
      "FAILED",
      "TIMEDOUT"
    ];
    let i = !1, t = !1;
    (T = e == null ? void 0 : e.paymentDetails) != null && T.length && (u.includes(e == null ? void 0 : e.paymentDetails[0].status) && (i = !0), o.includes(e == null ? void 0 : e.paymentDetails[0].status) && (t = !0));
    const L = (e == null ? void 0 : e.state) === "pending" && !i, y = (e == null ? void 0 : e.state) === "failed" && (e == null ? void 0 : e.failure_code) === "payment_failure" && !i && t;
    return {
      isPaymentPending: L,
      isPaymentRetry: y
    };
  }, a = (e, u) => {
    if (D)
      window.open(Me(window.location.origin + "/payment?order_id=" + e.id, U), "_blank", "noopener=true");
    else {
      const o = window.location.origin + ve + "/payment?order_id=" + e.id;
      window.location.href = o;
    }
  }, ge = (e) => {
    A(v[e.target.value]);
  }, xe = (e) => {
    var u;
    if (!F && !(e != null && e.plan) && (e == null ? void 0 : e.object) === "mf_purchase") {
      const { isPaymentPending: o, isPaymentRetry: i } = we(e);
      if (D && (o || i))
        return {
          buttonText: g._({
            id: "D90FBQ"
          })
        };
      if (o)
        return {
          buttonText: g._({
            id: "mtFRI7"
          })
        };
      if (i)
        return Ie((u = e == null ? void 0 : e.paymentDetails[0]) == null ? void 0 : u.created_at) ? {
          buttonText: g._({
            id: "vgDUPm"
          })
        } : {
          title: /* @__PURE__ */ l.jsx(l.Fragment, {
            children: /* @__PURE__ */ l.jsx("div", {
              className: "text-center",
              children: g._({
                id: "7XOaxV"
              })
            })
          })
        };
    }
  }, ee = () => /* @__PURE__ */ l.jsxs(l.Fragment, {
    children: [
      R.length ? /* @__PURE__ */ l.jsx(he, {
        variant: "selectable",
        onChange: ge,
        selected: (R == null ? void 0 : R.findIndex((e) => e.id === (p == null ? void 0 : p.id))) || 0,
        children: R.map((e) => {
          var u, o, i, t;
          return /* @__PURE__ */ l.jsx(ie, {
            footer: xe(e),
            onClick: () => a(e),
            innerRef: I,
            children: /* @__PURE__ */ l.jsxs("div", {
              className: "flex flex-wrap py-2",
              ref: (e == null ? void 0 : e.id) === (p == null ? void 0 : p.id) ? w : null,
              children: [
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-full",
                  children: /* @__PURE__ */ l.jsx(B, {
                    label: g._({
                      id: "XTJZw8"
                    }),
                    value: N(e == null ? void 0 : e.schemeDetails) ? "-" : Oe((u = e == null ? void 0 : e.schemeDetails) == null ? void 0 : u.name),
                    logo: J ? X[(o = e == null ? void 0 : e.schemeDetails) == null ? void 0 : o.amc_id] || H : ""
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(B, {
                    label: g._({
                      id: "KqeLJ1"
                    }),
                    value: e != null && e.amount ? `₹ ${Ee(e == null ? void 0 : e.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(B, {
                    label: g._({
                      id: "e7LnWq"
                    }),
                    value: e != null && e.state ? /* @__PURE__ */ l.jsx(ye, {
                      variant: "outline",
                      message: `${(e == null ? void 0 : e.state) === "pending" && ((t = (i = e == null ? void 0 : e.paymentDetails) == null ? void 0 : i[0]) == null ? void 0 : t.status) === "INITIATED" ? "Payment processing" : Ne(e == null ? void 0 : e.state, e == null ? void 0 : e.object, e == null ? void 0 : e.failure_code)}`,
                      status: De(e == null ? void 0 : e.state)
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(B, {
                    label: g._({
                      id: "ItmT+q"
                    }),
                    value: e != null && e.created_at ? Te(new Date(e == null ? void 0 : e.created_at), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(B, {
                    label: g._({
                      id: "cXcPd1"
                    }),
                    value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
                  })
                })
              ]
            })
          }, e == null ? void 0 : e.id);
        })
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {}),
      oe ? /* @__PURE__ */ l.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ l.jsx(be, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {}),
      p ? /* @__PURE__ */ l.jsx(Ce, {
        children: /* @__PURE__ */ l.jsx(ce, {
          variant: "primary",
          fullwidth: !0,
          onClick: (e) => {
            e.preventDefault(), d(!0);
          },
          children: g._({
            id: "f9pW3a"
          })
        })
      }) : null,
      /* @__PURE__ */ l.jsx(Le, {
        variant: "fullPage",
        contentHeightMax: !0,
        title: g._({
          id: "cHu8/t",
          values: {
            0: te(p == null ? void 0 : p.id)
          }
        }),
        isOpen: K,
        toggleModal: () => {
          P(), d(!1);
        },
        children: /* @__PURE__ */ l.jsx(Ue, {
          transactionId: p == null ? void 0 : p.id,
          isGroupInvestment: F,
          investor: s,
          handlePayment: a,
          isPartner: D
        })
      })
    ]
  });
  function je() {
    switch (z) {
      case "initial":
        return ee();
      case "no_listing":
        return /* @__PURE__ */ l.jsx(le, {
          title: g._({
            id: "b9eV+m"
          }),
          description: g._({
            id: "TYsDi/"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "error":
        return /* @__PURE__ */ l.jsx(le, {
          title: g._({
            id: "w7WdjZ"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return ee();
    }
  }
  return S ? /* @__PURE__ */ l.jsx(ue, {
    variant: "fullpage"
  }) : je();
}
export {
  Ue as O,
  ke as P
};
