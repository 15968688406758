import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as a, useContext as A, useEffect as O, useLayoutEffect as K } from "react";
import { a as X, D as M, S as U, c as l, P as Q } from "./index.esm-CqWfms5D.js";
import { AppContext as V } from "./AppContext.js";
import { F as B } from "./provider-DKLaKIVD.js";
import { LoginContext as q } from "./LoginContext.js";
import { W as G } from "./Welcome-Bz7XIwKh.js";
import { i as Y, L as j, h as Z } from "./utils-C2gHLhxV.js";
import { i as s } from "./index-CrDCxF0S.js";
import { r as k } from "./sentry-CstOwvcR.js";
import { u as R } from "./useConfiguration-B-lyaBB9.js";
import { ErrorPage as H } from "./ErrorPage.js";
import { A as C } from "./Auth-CJPsuV-i.js";
function ee({ investorProfile: g, mfInvestmentAccount: p }) {
  const [L, u] = a(!1), [N, f] = a("initial"), [n, I] = a([]), [t, F] = a(null), [c, W] = a([]), [o, y] = a(null), [v, E] = a(null), { tenantConfig: d, amcs: P } = R(), b = d == null ? void 0 : d.fallbackLogo, z = d == null ? void 0 : d.showSchemeLogo, { fpapi: h } = A(B);
  O(() => {
    async function i() {
      try {
        if (Y(p)) {
          f("no_portfolio");
          return;
        }
        u(!0);
        const x = p.id, _ = await h.fpClient.investor_reports().fetchInvestmentAccountWiseReturns({
          mf_investment_account: x
        });
        if (_.data.rows[0][1] === 0) {
          u(!1), f("no_portfolio");
          return;
        }
        const w = await h.fpClient.investor_reports().fetchSchemewiseReturns({
          mf_investment_account: x
        }), S = {};
        _.data.columns.forEach((m, $) => {
          S[m] = $;
        });
        const r = {};
        w.data.columns.forEach((m, $) => {
          r[m] = $;
        }), await T(w, r), F(S), I(_.data.rows[0]), W(w.data.rows), y(r), u(!1);
      } catch (x) {
        window.debug.error(x), u(!1), f("error"), k(x);
      }
    }
    i();
  }, [
    h,
    g
  ]);
  async function T(i, x) {
    const _ = {}, w = i.data.rows.map((r) => r[x.isin]), S = [];
    w.forEach((r) => {
      const m = h.fpClient.master_data().fetchScheme(r);
      S.push(m);
    }), Promise.all(S).then((r) => {
      r.forEach((m) => {
        _[m.isin] = {
          ...m
        };
      }), E(_);
    }).catch((r) => {
      window.debug.error(r), u(!1), f("error"), k(r);
    });
  }
  function D() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(M, {
          type: "text",
          text: s._({
            id: "eW8IR1"
          })
        }),
        /* @__PURE__ */ e.jsx(U, {
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "ez01Pf"
                  }),
                  value: n[t == null ? void 0 : t.invested_amount] !== null ? `₹ ${j(n[t == null ? void 0 : t.invested_amount], 2)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "aoh6Eh"
                  }),
                  value: n[t == null ? void 0 : t.current_value] !== null ? `₹ ${j(n[t == null ? void 0 : t.current_value], 2)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "fI+F9X"
                  }),
                  value: n[t == null ? void 0 : t.unrealized_gain] !== null ? `₹ ${j(n[t == null ? void 0 : t.unrealized_gain], 2)}` : "-",
                  icon: n[t == null ? void 0 : t.unrealized_gain] === 0 ? "" : n[t == null ? void 0 : t.unrealized_gain] > 0 ? "TrendingUp" : "TrendingDown"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2 text-green-700",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "QFsROw"
                  }),
                  value: n[t == null ? void 0 : t.absolute_return] !== null ? `${n[t == null ? void 0 : t.absolute_return]} %` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "tzJCPh"
                  }),
                  value: n[t == null ? void 0 : t.cagr] !== null ? `${n[t == null ? void 0 : t.cagr]} %` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "Rt2L/z"
                  }),
                  value: n[t == null ? void 0 : t.xirr] !== null ? `${n[t == null ? void 0 : t.xirr]} %` : "-"
                })
              })
            ]
          })
        }),
        c.length ? /* @__PURE__ */ e.jsx(M, {
          type: "text",
          text: s._({
            id: "pIA/jh"
          }),
          customClass: "mb-4"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
        c.length ? c.map((i) => /* @__PURE__ */ e.jsx(U, {
          customClass: "mb-6",
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-full pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  value: Z(i[o.scheme_name]),
                  logo: z && v ? P[v[i[o.isin]].amc_id] || b : ""
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "ez01Pf"
                  }),
                  value: `₹ ${j(i[o.invested_amount], 2)}`
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "S0BC0N"
                  }),
                  value: `₹ ${j(i[o.current_value], 2)}`
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(l, {
                  label: s._({
                    id: "fI+F9X"
                  }),
                  value: `₹ ${j(i[o.unrealized_gain], 2)}`,
                  icon: i[o.unrealized_gain] === 0 ? "" : i[o.unrealized_gain] > 0 ? "TrendingUp" : "TrendingDown"
                })
              })
            ]
          })
        }, i[o.isin])) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      ]
    });
  }
  function J() {
    switch (N) {
      case "initial":
        return D();
      case "error":
        return /* @__PURE__ */ e.jsx(H, {
          title: s._({
            id: "KnE1fM"
          }),
          description: s._({
            id: "uHsknt"
          }),
          showAction: !0,
          actionText: s._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            f("initial");
          }
        });
      case "no_portfolio":
        return /* @__PURE__ */ e.jsx(H, {
          title: s._({
            id: "/aXNoC"
          }),
          description: s._({
            id: "aojshS"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return D();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: L ? /* @__PURE__ */ e.jsx(X, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: J()
    })
  });
}
function fe() {
  const { fpapi: g } = A(B), { loggedInUser: p } = A(q), { AppState: { showWelcomePage: L, tenant: u }, setShowWelcomePage: N } = A(V), [f, n] = a({}), [I, t] = a(v), [F, c] = a(!0), [W, o] = a({}), y = [
    {
      step: 1,
      content: "Login"
    },
    {
      step: 2,
      content: "View portfolio"
    }
  ];
  K(() => {
    g && p ? (c(!0), g.fetchUser(p.profile.preferred_username, u).then((P) => {
      if (P[0].annexure.isonboarded && P[0].annexure.isonboarded === "false") {
        c(!1);
        return;
      }
      g.getInvestorProfile().then((b) => {
        b && g.fpClient.mf_investment_accounts().fetchAll({
          investor: b.pan
        }).then((z) => {
          const h = z.data.filter((T) => T.primary_investor === p.profile.fp_identifier);
          if (!h.length) {
            c(!1);
            return;
          }
          n(b), o(h[0]), c(!1);
        });
      });
    })) : c(!1);
  }, []), O(() => {
    t(v());
  }, [
    L
  ]);
  function v() {
    return L ? "welcome" : "portfolio";
  }
  function E() {
    N(!1), t("portfolio");
  }
  function d() {
    return I === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(G, {
        onAck: E,
        welcomeSteps: y
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: F ? /* @__PURE__ */ e.jsx(X, {
        variant: "fullpage"
      }) : p ? /* @__PURE__ */ e.jsx(ee, {
        investorProfile: f,
        mfInvestmentAccount: W
      }) : /* @__PURE__ */ e.jsx(C, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Q, {
        title: "Portfolio"
      }),
      d()
    ]
  });
}
export {
  fe as Portfolio
};
