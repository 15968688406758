var y = function(c, r) {
  return y = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(t, n) {
    t.__proto__ = n;
  } || function(t, n) {
    for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (t[o] = n[o]);
  }, y(c, r);
};
function h(c, r) {
  if (typeof r != "function" && r !== null)
    throw new TypeError("Class extends value " + String(r) + " is not a constructor or null");
  y(c, r);
  function t() {
    this.constructor = c;
  }
  c.prototype = r === null ? Object.create(r) : (t.prototype = r.prototype, new t());
}
var _ = function() {
  return _ = Object.assign || function(r) {
    for (var t, n = 1, o = arguments.length; n < o; n++) {
      t = arguments[n];
      for (var e in t) Object.prototype.hasOwnProperty.call(t, e) && (r[e] = t[e]);
    }
    return r;
  }, _.apply(this, arguments);
};
function b(c, r) {
  var t = {};
  for (var n in c) Object.prototype.hasOwnProperty.call(c, n) && r.indexOf(n) < 0 && (t[n] = c[n]);
  if (c != null && typeof Object.getOwnPropertySymbols == "function")
    for (var o = 0, n = Object.getOwnPropertySymbols(c); o < n.length; o++)
      r.indexOf(n[o]) < 0 && Object.prototype.propertyIsEnumerable.call(c, n[o]) && (t[n[o]] = c[n[o]]);
  return t;
}
function w(c, r, t, n) {
  var o = arguments.length, e = o < 3 ? r : n === null ? n = Object.getOwnPropertyDescriptor(r, t) : n, i;
  if (typeof Reflect == "object" && typeof Reflect.decorate == "function") e = Reflect.decorate(c, r, t, n);
  else for (var f = c.length - 1; f >= 0; f--) (i = c[f]) && (e = (o < 3 ? i(e) : o > 3 ? i(r, t, e) : i(r, t)) || e);
  return o > 3 && e && Object.defineProperty(r, t, e), e;
}
function v(c, r, t, n) {
  function o(e) {
    return e instanceof t ? e : new t(function(i) {
      i(e);
    });
  }
  return new (t || (t = Promise))(function(e, i) {
    function f(l) {
      try {
        a(n.next(l));
      } catch (s) {
        i(s);
      }
    }
    function u(l) {
      try {
        a(n.throw(l));
      } catch (s) {
        i(s);
      }
    }
    function a(l) {
      l.done ? e(l.value) : o(l.value).then(f, u);
    }
    a((n = n.apply(c, [])).next());
  });
}
function O(c, r) {
  var t = { label: 0, sent: function() {
    if (e[0] & 1) throw e[1];
    return e[1];
  }, trys: [], ops: [] }, n, o, e, i;
  return i = { next: f(0), throw: f(1), return: f(2) }, typeof Symbol == "function" && (i[Symbol.iterator] = function() {
    return this;
  }), i;
  function f(a) {
    return function(l) {
      return u([a, l]);
    };
  }
  function u(a) {
    if (n) throw new TypeError("Generator is already executing.");
    for (; t; ) try {
      if (n = 1, o && (e = a[0] & 2 ? o.return : a[0] ? o.throw || ((e = o.return) && e.call(o), 0) : o.next) && !(e = e.call(o, a[1])).done) return e;
      switch (o = 0, e && (a = [a[0] & 2, e.value]), a[0]) {
        case 0:
        case 1:
          e = a;
          break;
        case 4:
          return t.label++, { value: a[1], done: !1 };
        case 5:
          t.label++, o = a[1], a = [0];
          continue;
        case 7:
          a = t.ops.pop(), t.trys.pop();
          continue;
        default:
          if (e = t.trys, !(e = e.length > 0 && e[e.length - 1]) && (a[0] === 6 || a[0] === 2)) {
            t = 0;
            continue;
          }
          if (a[0] === 3 && (!e || a[1] > e[0] && a[1] < e[3])) {
            t.label = a[1];
            break;
          }
          if (a[0] === 6 && t.label < e[1]) {
            t.label = e[1], e = a;
            break;
          }
          if (e && t.label < e[2]) {
            t.label = e[2], t.ops.push(a);
            break;
          }
          e[2] && t.ops.pop(), t.trys.pop();
          continue;
      }
      a = r.call(c, t);
    } catch (l) {
      a = [6, l], o = 0;
    } finally {
      n = e = 0;
    }
    if (a[0] & 5) throw a[1];
    return { value: a[0] ? a[1] : void 0, done: !0 };
  }
}
function g(c, r, t) {
  if (t || arguments.length === 2) for (var n = 0, o = r.length, e; n < o; n++)
    (e || !(n in r)) && (e || (e = Array.prototype.slice.call(r, 0, n)), e[n] = r[n]);
  return c.concat(e || Array.prototype.slice.call(r));
}
var p = { exports: {} };
typeof Object.create == "function" ? p.exports = function(r, t) {
  t && (r.super_ = t, r.prototype = Object.create(t.prototype, {
    constructor: {
      value: r,
      enumerable: !1,
      writable: !0,
      configurable: !0
    }
  }));
} : p.exports = function(r, t) {
  if (t) {
    r.super_ = t;
    var n = function() {
    };
    n.prototype = t.prototype, r.prototype = new n(), r.prototype.constructor = r;
  }
};
var x = p.exports;
export {
  v as _,
  O as a,
  _ as b,
  h as c,
  g as d,
  b as e,
  w as f,
  x as i
};
