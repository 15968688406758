import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as n, useState as s, useEffect as l } from "react";
import { P, a as S } from "./index.esm-CqWfms5D.js";
import { AppContext as A } from "./AppContext.js";
import { F as C } from "./provider-DKLaKIVD.js";
import { LoginContext as L } from "./LoginContext.js";
import { i } from "./index-CrDCxF0S.js";
import { r as F } from "./sentry-CstOwvcR.js";
import { W as I } from "./Welcome-Bz7XIwKh.js";
import { H as v } from "./Holdings-Bzl8QuFg.js";
import { A as W } from "./Auth-CJPsuV-i.js";
function X() {
  const { fpapi: o } = n(C), { loggedInUser: c } = n(L), { AppState: { showWelcomePage: a }, setShowWelcomePage: p } = n(A), [g, m] = s(f), [d, r] = s(!0), [u, x] = s({}), w = [
    {
      step: 1,
      content: i._({
        id: "bwiJz/"
      })
    },
    {
      step: 2,
      content: i._({
        id: "X7wgSI"
      })
    }
  ];
  l(() => {
    o ? o.getInvestorProfile().then((t) => {
      t && x(t), r(!1);
    }).catch((t) => {
      r(!1), F(t);
    }) : r(!1);
  }, [
    o
  ]), l(() => {
    m(f());
  }, [
    a
  ]);
  function f() {
    return a ? "welcome" : "statement";
  }
  function h() {
    p(!1), m("statement");
  }
  function j() {
    return g === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(I, {
        onAck: h,
        welcomeSteps: w
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: d ? /* @__PURE__ */ e.jsx(S, {
        variant: "fullpage"
      }) : c ? /* @__PURE__ */ e.jsx(v, {
        investorProfile: u
      }) : /* @__PURE__ */ e.jsx(W, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(P, {
        title: i._({
          id: "JwUPeL"
        })
      }),
      j()
    ]
  });
}
export {
  X as Statement
};
