import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { B as e } from "./index.esm-CqWfms5D.js";
import { L as i } from "./LanguageSelector-DnUt2tOq.js";
import { i as a } from "./index-CrDCxF0S.js";
function x({ onAck: r, welcomeSteps: s }) {
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx("ol", {
        className: "text-neutral dark:text-neutral-dark-110 font-opensans list-decimal px-4 font-semibold",
        children: s.map((n) => {
          const o = n.content;
          return /* @__PURE__ */ t.jsx("li", {
            children: o
          }, n.step);
        })
      }),
      /* @__PURE__ */ t.jsx(i, {}),
      /* @__PURE__ */ t.jsx(e, {
        onClick: r,
        fullwidth: !0,
        customClass: "mb-8",
        children: a._({
          id: "2iVSoY"
        })
      })
    ]
  });
}
export {
  x as W
};
