import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as o, useContext as b, useEffect as F } from "react";
import { R as v, F as w } from "./react-final-form.es-CUo9SHJd.js";
import { a as S, P as y, e as L, T as _, M as E, B as P, f as I, h as T } from "./index.esm-CqWfms5D.js";
import { m as A, s as B, t as k, n as R } from "./utils-C2gHLhxV.js";
import { F as M } from "./provider-DKLaKIVD.js";
import { C as N } from "./CopyButton-D0B5OZZ9.js";
import { r as U } from "./sentry-CstOwvcR.js";
import { i as n } from "./index-CrDCxF0S.js";
import { L as q } from "./LanguageSelector-DnUt2tOq.js";
function Q({ onLogin: l, context: f }) {
  const [g] = o(null), [c, u] = o(""), [h, i] = o(!1), { fpapi: x } = b(M), s = "ARN-260421";
  F(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  async function p({ license_code: a }) {
    try {
      i(!0);
      const e = await x.fpClient.partners().search({
        code: a
      }), r = e == null ? void 0 : e.data[0];
      if (R(r == null ? void 0 : r.expiry_date)) {
        u(n._({
          id: "k1BPaW"
        })), i(!1);
        return;
      }
      sessionStorage.setItem("loggedInPartner", r.id), l && l(r), window.location.href = f;
    } catch (e) {
      i(!1), u(n._({
        id: "HCqoTF"
      })), window.debug.error(e), U(e);
    }
  }
  function j(a, e) {
    e.fields.license_code.change(a.length && a[0]);
  }
  function C() {
    switch (g) {
      default:
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(y, {
              title: n._({
                id: "i0KbTB"
              })
            }),
            /* @__PURE__ */ t.jsx(L, {
              message: n._({
                id: "IXKUc3"
              })
            }),
            /* @__PURE__ */ t.jsx(v, {
              onSubmit: p,
              validate: (a) => {
                const e = {};
                return a.license_code || (e.license_code = n._({
                  id: "c0D/2W"
                })), e;
              },
              mutators: {
                prepopulatePAN: j
              },
              children: (a) => /* @__PURE__ */ t.jsxs("form", {
                onSubmit: a.handleSubmit,
                children: [
                  /* @__PURE__ */ t.jsx("div", {
                    className: "mb-8",
                    children: /* @__PURE__ */ t.jsx(w, {
                      name: "license_code",
                      parse: (e) => e.toUpperCase(),
                      children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                        children: [
                          /* @__PURE__ */ t.jsx(_, {
                            id: e.input.name,
                            autoComplete: "off",
                            name: e.input.name,
                            value: e.input.value,
                            onChange: (r) => {
                              const m = r.target.selectionStart, d = r.target;
                              window.requestAnimationFrame(() => {
                                d.selectionStart = m, d.selectionEnd = m;
                              }), e.input.onChange(r.target.value);
                            },
                            onBlur: () => {
                              e.input.onChange(e.input.value.trim());
                            },
                            onInput: (r) => {
                              r.target.value = r.target.value.replace(A("A-Za-z0-9"), "");
                            },
                            placeholder: n._({
                              id: "dxkrdf"
                            }),
                            status: e.meta.error && e.meta.touched ? "error" : void 0,
                            maxLength: 10,
                            autoFocus: !0
                          }),
                          (e.meta.error && e.meta.touched || c) && /* @__PURE__ */ t.jsx(E, {
                            type: "error",
                            variant: "field",
                            title: c || e.meta.error
                          })
                        ]
                      })
                    })
                  }),
                  /* @__PURE__ */ t.jsx(q, {}),
                  /* @__PURE__ */ t.jsx(P, {
                    type: "submit",
                    variant: "primary",
                    fullwidth: !0,
                    onClick: () => {
                      const e = Object.keys(a.errors);
                      e.length && B(e[0]);
                    },
                    children: "Continue"
                  }),
                  k.isStagingOrSandbox ? /* @__PURE__ */ t.jsx(t.Fragment, {
                    children: /* @__PURE__ */ t.jsx(I, {
                      title: "Use below username to sign into sandbox",
                      customClass: "mt-4",
                      children: /* @__PURE__ */ t.jsx(T, {
                        title: "Registered distributor username",
                        label: s,
                        customClass: "mb-3",
                        children: /* @__PURE__ */ t.jsx(N, {
                          value: s,
                          onClick: () => a.form.mutators.prepopulatePAN(s)
                        })
                      })
                    })
                  }) : null
                ]
              })
            })
          ]
        });
    }
  }
  return h ? /* @__PURE__ */ t.jsx(S, {
    variant: "fullpage"
  }) : C();
}
export {
  Q as P
};
