import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as Y, useState as b, useEffect as q } from "react";
import { i as a } from "./index-CrDCxF0S.js";
import { a as J, S as W, c as x, P as z } from "./index.esm-CqWfms5D.js";
import { F as X } from "./provider-DKLaKIVD.js";
import { O as Z } from "./OrderStatus-C3VoFfP1.js";
import { u as Q, O as V } from "./OtpForm-B6uHV89C.js";
import { u as $ } from "./useConfiguration-B-lyaBB9.js";
import { i as k, A as B, f as O, k as U, M as P, N as ss } from "./utils-C2gHLhxV.js";
import { r as H } from "./sentry-CstOwvcR.js";
import { ErrorPage as K } from "./ErrorPage.js";
import { S as G } from "./StatusMessage-BUdCbwjZ.js";
import { P as D } from "./Product-DeVtlxIM.js";
function xs({ investorProfile: F, showSchemeDetails: j = !0, id: f, onSuccess: h, action: p }) {
  const { fpapi: d } = Y(X), [N, n] = b(!1), [S, _] = b(""), [e, y] = b(null), [t, L] = b([]), { tenantConfig: l, amcs: M } = $(), w = l == null ? void 0 : l.fallbackLogo, T = l == null ? void 0 : l.showSchemeLogo, { collectedAcquisition: m, initiateSendOTP: R } = Q();
  q(() => {
    v();
  }, []);
  const I = (r) => {
    const i = T ? {
      ...r,
      schemeLogo: M[r == null ? void 0 : r.amc_id] || w
    } : {
      ...r
    };
    L(i);
  }, v = async () => {
    const r = f == null ? void 0 : f.split(",");
    n(!0);
    try {
      const i = await d.fpClient.mf_redemptions().fetch(f), g = await d.fpClient.master_data().fetchScheme(i.scheme);
      I(g), y(i), i.state === "pending" ? _("consent") : _("consent_not_required");
      const u = {
        given_for_object: i == null ? void 0 : i.object,
        action: p,
        given_for: r
      };
      await R(u), n(!1);
    } catch (i) {
      _("error"), window.debug.log(i, "error"), n(!1);
    }
  }, C = async () => {
    var r, i, g;
    try {
      n(!0);
      const u = await d.fpClient.mf_redemptions().update({
        id: e == null ? void 0 : e.id,
        state: "confirmed",
        consent: {
          email: (r = m == null ? void 0 : m.acquisition_details) == null ? void 0 : r.email,
          isd_code: (i = m == null ? void 0 : m.acquisition_details) == null ? void 0 : i.isd,
          mobile: (g = m == null ? void 0 : m.acquisition_details) == null ? void 0 : g.mobile_number
        }
      });
      y(u), h ? h(u) : _("success"), n(!1);
    } catch (u) {
      n(!1), _("error"), H(u);
    }
  }, E = () => /* @__PURE__ */ s.jsx(s.Fragment, {
    children: /* @__PURE__ */ s.jsx(W, {
      children: /* @__PURE__ */ s.jsxs("div", {
        className: "flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "w-1/2",
            children: /* @__PURE__ */ s.jsx(x, {
              label: e && e.amount ? a._({
                id: "uzz6vZ"
              }) : a._({
                id: "7P4OtI"
              }),
              value: e && (e != null && e.amount) ? `₹ ${B(e == null ? void 0 : e.amount, 2)}` : e && e.units ? e.units : "-"
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "w-1/2",
            children: /* @__PURE__ */ s.jsx(x, {
              label: a._({
                id: "cXcPd1"
              }),
              value: e && (e != null && e.folio_number) ? e.folio_number : "New folio"
            })
          })
        ]
      })
    })
  }), c = () => /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(z, {
        title: a._({
          id: "YCamr4"
        })
      }),
      /* @__PURE__ */ s.jsx(D, {
        scheme: t
      })
    ]
  });
  return N ? /* @__PURE__ */ s.jsx(J, {
    variant: "fullpage"
  }) : (() => {
    switch (S) {
      case "consent":
        return /* @__PURE__ */ s.jsxs(s.Fragment, {
          children: [
            j && c(),
            E(),
            /* @__PURE__ */ s.jsx(V, {
              handleSubmit: C,
              pageTitle: a._({
                id: "s/ORP1"
              }),
              investorProfile: F,
              collectAcquisition: m,
              action: p
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ s.jsxs(s.Fragment, {
          children: [
            j && c(),
            E(),
            /* @__PURE__ */ s.jsx(G, {
              title: a._({
                id: "AY1MwY"
              }),
              description: a._({
                id: "9JLnsx"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ s.jsx(K, {
          title: a._({
            id: "kCTQHV"
          }),
          description: a._({
            id: "oahseM"
          }),
          showDetails: !1
        });
      case "success":
        return !k(t) && !k(e) ? /* @__PURE__ */ s.jsxs(s.Fragment, {
          children: [
            j && c(),
            /* @__PURE__ */ s.jsx(Z, {
              scheme: t,
              mf_order: e
            })
          ]
        }) : /* @__PURE__ */ s.jsx(s.Fragment, {});
      default:
        return /* @__PURE__ */ s.jsx(s.Fragment, {});
    }
  })();
}
function js({ investorProfile: F, id: j, showSchemeDetails: f = !0, onSuccess: h, action: p }) {
  const { fpapi: d } = Y(X), [N, n] = b(!1), [S, _] = b(""), { collectedAcquisition: e, initiateSendOTP: y } = Q(), [t, L] = b({}), { tenantConfig: l, amcs: M } = $(), [w, T] = b([]);
  q(() => {
    m();
  }, []);
  const m = async () => {
    const c = j == null ? void 0 : j.split(",");
    try {
      n(!0);
      const o = await d.fpClient.mf_redemption_plans().fetch(j);
      if (f) {
        const i = await d.fpClient.master_data().fetchScheme(o == null ? void 0 : o.scheme);
        R(i);
      }
      L(o);
      const r = {
        given_for_object: o == null ? void 0 : o.object,
        action: p,
        given_for: c
      };
      await y(r), _("consent"), n(!1);
    } catch (o) {
      n(!1), _("error"), H(o);
    }
  }, R = (c) => {
    const o = l != null && l.showSchemeLogo ? {
      ...c,
      schemeLogo: M[c == null ? void 0 : c.amc_id] || (l == null ? void 0 : l.fallbackLogo)
    } : {
      ...c
    };
    T(o);
  }, I = async () => {
    var c, o, r, i, g, u;
    if (h) {
      n(!0);
      try {
        await d.fpClient.mf_redemption_plans().update({
          id: t == null ? void 0 : t.id,
          consent: {
            email: (c = e == null ? void 0 : e.acquisition_details) == null ? void 0 : c.email,
            isd_code: (o = e == null ? void 0 : e.acquisition_details) == null ? void 0 : o.isd,
            mobile: (r = e == null ? void 0 : e.acquisition_details) == null ? void 0 : r.mobile_number
          }
        }), h(t), n(!1);
      } catch {
        _("error"), n(!1);
      }
    } else {
      n(!0);
      try {
        await d.fpClient.mf_redemption_plans().update({
          id: t == null ? void 0 : t.id,
          consent: {
            email: (i = e == null ? void 0 : e.acquisition_details) == null ? void 0 : i.email,
            isd_code: (g = e == null ? void 0 : e.acquisition_details) == null ? void 0 : g.isd,
            mobile: (u = e == null ? void 0 : e.acquisition_details) == null ? void 0 : u.mobile_number
          }
        }), _("success"), n(!1);
      } catch {
        _("error"), n(!1);
      }
    }
  }, v = () => /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(z, {
        title: a._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ s.jsx(D, {
        scheme: w
      })
    ]
  }), C = () => {
    var c;
    return /* @__PURE__ */ s.jsx("div", {
      style: {
        marginTop: "-20px"
      },
      children: /* @__PURE__ */ s.jsx(W, {
        customClass: "mb-8",
        children: /* @__PURE__ */ s.jsxs("div", {
          className: "-mr-2 -ml-2 flex flex-wrap items-center",
          children: [
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(x, {
                label: a._({
                  id: "vOD7hD"
                }),
                value: t != null && t.amount ? `₹ ${B(t == null ? void 0 : t.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(x, {
                label: a._({
                  id: "UMXPdU"
                }),
                value: t != null && t.frequency ? (c = O(t == null ? void 0 : t.frequency)) == null ? void 0 : c.replace(/_/g, " ") : "-"
              })
            }),
            t != null && t.requested_activation_date && !(t != null && t.start_date) ? /* @__PURE__ */ s.jsxs(s.Fragment, {
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(x, {
                    label: a._({
                      id: "+KWVFJ"
                    }),
                    value: t != null && t.requested_activation_date ? U(new Date(t == null ? void 0 : t.requested_activation_date), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(x, {
                    label: a._({
                      id: "cki4uJ"
                    }),
                    value: t != null && t.installment_day ? P(t == null ? void 0 : t.installment_day) : "-"
                  })
                })
              ]
            }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
              children: [
                t != null && t.start_date ? /* @__PURE__ */ s.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(x, {
                    label: a._({
                      id: "NdlT2Q"
                    }),
                    value: t != null && t.start_date ? U(new Date(t == null ? void 0 : t.start_date), "dd-MMM-yyyy") : "-"
                  })
                }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
                t != null && t.end_date ? /* @__PURE__ */ s.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(x, {
                    label: a._({
                      id: "gqWUiZ"
                    }),
                    value: t != null && t.end_date ? ss(t == null ? void 0 : t.end_date) : "-"
                  })
                }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
              ]
            }),
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(x, {
                label: a._({
                  id: "cXcPd1"
                }),
                value: t != null && t.folio_number ? t == null ? void 0 : t.folio_number : "New folio"
              })
            })
          ]
        })
      })
    });
  };
  return N ? /* @__PURE__ */ s.jsx(J, {
    variant: "fullpage"
  }) : (() => {
    switch (S) {
      case "consent":
        return /* @__PURE__ */ s.jsxs(s.Fragment, {
          children: [
            f && v(),
            C(),
            /* @__PURE__ */ s.jsx(V, {
              handleSubmit: I,
              pageTitle: a._({
                id: "s/ORP1"
              }),
              investorProfile: F,
              collectAcquisition: e
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ s.jsxs(s.Fragment, {
          children: [
            f && v(),
            C(),
            /* @__PURE__ */ s.jsx(G, {
              title: a._({
                id: "AY1MwY"
              }),
              description: a._({
                id: "9JLnsx"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ s.jsx(K, {
          title: a._({
            id: "w7WdjZ"
          }),
          description: a._({
            id: "O8W8Ev"
          }),
          showDetails: !1
        });
      case "success":
        return /* @__PURE__ */ s.jsxs(s.Fragment, {
          children: [
            f && v(),
            /* @__PURE__ */ s.jsx(Z, {
              scheme: w,
              mf_order: t
            })
          ]
        });
      default:
        return /* @__PURE__ */ s.jsx(s.Fragment, {});
    }
  })();
}
export {
  js as R,
  xs as a
};
