import { useRef as s, useEffect as n } from "react";
function o(r, t) {
  const e = s();
  n(() => {
    e.current = r;
  }, [
    r
  ]), n(() => {
    function u() {
      e.current();
    }
    if (t !== null) {
      const c = setInterval(u, t);
      return () => clearInterval(c);
    }
  }, [
    t
  ]);
}
export {
  o as u
};
