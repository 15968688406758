import { j as p } from "./jsx-runtime-C8rcB1Lr.js";
import { createContext as c, useReducer as m, useEffect as u } from "react";
const y = /* @__PURE__ */ c({}), S = (o, e) => {
  switch (e.type) {
    case "SHOW_WELCOME_STAGE":
      return {
        ...o,
        showWelcomePage: !0
      };
    case "DISABLE_WELCOME_STAGE":
      return {
        ...o,
        showWelcomePage: !1
      };
    case "ADD_EMPLOYER":
      return {
        ...o,
        employer: {
          ...e.payload
        }
      };
    case "SET_USERNAME":
      return {
        ...o,
        username: e.payload.username
      };
    case "SET_EMPLOYER_BEING_ONBOARDED":
      return {
        ...o,
        employerBeingOnboarded: e.payload.employerName
      };
    case "SET_TENANT":
      return {
        ...o,
        tenant: e.payload.tenant
      };
  }
  throw Error(`Unknown action: ${e.type}`);
};
function _() {
  const o = {
    employer: null,
    showWelcomePage: !0,
    username: void 0,
    tenant: ""
  }, e = sessionStorage.getItem("showWelcomePage");
  return e !== null && (o.showWelcomePage = e === "true"), o;
}
const A = ({ children: o, tenant: e = "" }) => {
  const [s, t] = m(S, null, _), a = (r) => {
    sessionStorage.setItem("showWelcomePage", r), t({
      type: r ? "SHOW_WELCOME_STAGE" : "DISABLE_WELCOME_STAGE",
      payload: {}
    });
  }, E = (r) => {
    t({
      type: "ADD_EMPLOYER",
      payload: {
        ...r
      }
    });
  }, n = (r) => {
    t({
      type: "SET_USERNAME",
      payload: {
        username: r
      }
    });
  }, l = (r) => {
    t({
      type: "SET_EMPLOYER_BEING_ONBOARDED",
      payload: {
        employerName: r
      }
    });
  };
  return u(() => {
    e && t({
      type: "SET_TENANT",
      payload: {
        tenant: e
      }
    });
  }, [
    e
  ]), /* @__PURE__ */ p.jsx(y.Provider, {
    value: {
      AppState: s,
      setShowWelcomePage: a,
      setEmployer: E,
      setUsername: n,
      setEmployerBeingOnboarded: l
    },
    children: o
  });
};
export {
  y as AppContext,
  A as AppProvider
};
