import { useState as i, useRef as l, useEffect as u } from "react";
const a = (s) => {
  const [o, r] = i(!1), n = {
    threshold: 1,
    trackVisibility: !0,
    delay: 100
  }, c = l(new IntersectionObserver((e) => {
    const t = e[0];
    t.isIntersecting && t.isVisible ? r(!0) : r(!1);
  }, n));
  return u(() => {
    const e = s, t = c.current;
    return e && t && t.observe(e), () => {
      e && t.unobserve(e);
    };
  }, [
    s
  ]), {
    hasScrolledToBottom: o
  };
};
export {
  a as u
};
