import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as ne, useState as n, useEffect as le, useCallback as ie, useLayoutEffect as xe } from "react";
import { a as re, c as o, M as be, S as Q, D as je, d as pe, F as ge, B as I, b as G } from "./index.esm-CqWfms5D.js";
import { F as oe } from "./provider-DKLaKIVD.js";
import { r as ve } from "./sentry-CstOwvcR.js";
import { i } from "./index-CrDCxF0S.js";
import { i as _e, d as Z, k as we, h as ce, z as Se, A as D, f as ae } from "./utils-C2gHLhxV.js";
import { u as de } from "./useConfiguration-B-lyaBB9.js";
import { FolioDetails as Ne } from "./FolioDetails.js";
import { u as ye } from "./useScrollToBottomRef-CqDzM0dm.js";
import { ErrorPage as K } from "./ErrorPage.js";
function Ce({ folio_number: N }) {
  const { fpapi: c } = ne(oe), [y, W] = n(!1), [t, X] = n({}), [f, C] = n({}), [M, T] = n({}), [p, L] = n("initial"), { amcs: E, tenantConfig: O } = de(), J = O == null ? void 0 : O.fallbackLogo, [P, U] = n(null), A = ye(P), [F, $] = n(!1), [_, Y] = n(10);
  le(() => {
    (async () => {
      if (!N) {
        L("error");
        return;
      }
      W(!0);
      try {
        const s = await c.fpClient.other_reports().transaction_list({
          folio_number: N
        });
        if (!_e(s.data) && !Z(s.data.rows)) {
          X(s.data);
          const a = {};
          a.columns = s.data.columns, a.rows = s.data.rows.slice(0, _), h(a), C(a);
        }
        W(!1);
      } catch {
        L("error"), W(!1);
      }
    })();
  }, []);
  const h = async (s) => {
    const a = /* @__PURE__ */ new Set();
    s == null || s.rows.forEach((r) => {
      var u;
      a.add(r[(u = s == null ? void 0 : s.columns) == null ? void 0 : u.indexOf("isin")]);
    });
    const m = [];
    a.forEach((r) => {
      const u = c.fpClient.master_data().fetchScheme(r);
      m.push(u);
    }), Promise.all(m).then((r) => {
      T(r);
    });
  };
  le(() => {
    var s, a;
    if (A.hasScrolledToBottom && ((s = f == null ? void 0 : f.rows) == null ? void 0 : s.length) < ((a = t == null ? void 0 : t.rows) == null ? void 0 : a.length)) {
      const m = _ + 10;
      $(!0);
      const r = {};
      r.columns = t.columns, r.rows = f.rows.concat(t.rows.slice(_, m)), h(r), C(r), Y((u) => u + 10), $(!1);
    }
  }, [
    A.hasScrolledToBottom
  ]);
  const w = (s) => {
    if (Z(M))
      return "";
    {
      const a = M.filter((m) => {
        var r;
        return m.isin === s[(r = t == null ? void 0 : t.columns) == null ? void 0 : r.indexOf("isin")];
      });
      return Z(a) ? "" : a[0];
    }
  }, B = (s) => s === "redemption" ? "Withdrawal" : s === "switch_in" ? "Switch in" : s === "switch_out" ? "Switch out" : "Purchase", q = ({ transaction: s }) => {
    var a, m, r, u, V, z, k, H;
    return /* @__PURE__ */ e.jsx(Q, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ e.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: i._({
            id: "moFZxg",
            values: {
              0: we(new Date(s[(a = t == null ? void 0 : t.columns) == null ? void 0 : a.indexOf("traded_on")]), "dd-MMM-yyyy")
            }
          })
        })
      },
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 w-full pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: i._({
                id: "XTJZw8"
              }),
              value: w(s) ? ce(w(s).name) : "-",
              logo: w(s) ? E[w(s).amc_id] || J : ""
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: i._({
                id: "8kF3Wn"
              }),
              value: B(s[(m = t == null ? void 0 : t.columns) == null ? void 0 : m.indexOf("type")]) ?? "-"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: i._({
                id: "KqeLJ1"
              }),
              value: s[(r = t == null ? void 0 : t.columns) == null ? void 0 : r.indexOf("amount")] ? `₹ ${Se(s[(u = t == null ? void 0 : t.columns) == null ? void 0 : u.indexOf("amount")])}` : "-"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: i._({
                id: "6ssn5V"
              }),
              value: s[(V = t == null ? void 0 : t.columns) == null ? void 0 : V.indexOf("units")] ? D(s[(z = t == null ? void 0 : t.columns) == null ? void 0 : z.indexOf("units")], 2) : "-"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: i._({
                id: "tagv+z",
                values: {
                  0: B(s[(k = t == null ? void 0 : t.columns) == null ? void 0 : k.indexOf("type")])
                }
              }),
              value: `₹ ${D(s[(H = t == null ? void 0 : t.columns) == null ? void 0 : H.indexOf("traded_at")], 2)}` ?? "-"
            })
          })
        ]
      })
    });
  }, g = () => {
    var s;
    return Z(t == null ? void 0 : t.rows) ? /* @__PURE__ */ e.jsx(K, {
      title: i._({
        id: "WOxLnB"
      }),
      description: i._({
        id: "meKOVc"
      })
    }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(o, {
          label: i._({
            id: "cXcPd1"
          }),
          value: N,
          background: !0,
          customClass: "mb-2"
        }),
        (s = f == null ? void 0 : f.rows) == null ? void 0 : s.map((a, m) => /* @__PURE__ */ e.jsx("div", {
          ref: U,
          children: /* @__PURE__ */ e.jsx(q, {
            transaction: a
          })
        }, m)),
        F ? /* @__PURE__ */ e.jsx("div", {
          className: "flex justify-center",
          children: /* @__PURE__ */ e.jsx(be, {
            type: "important",
            variant: "field",
            title: "Loading..",
            loader: !0,
            customClass: "mb-5 "
          })
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      ]
    });
  };
  function v() {
    switch (p) {
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "initial":
        return g();
      case "error":
        return /* @__PURE__ */ e.jsx(K, {
          title: i._({
            id: "w7WdjZ"
          })
        });
    }
  }
  return y ? /* @__PURE__ */ e.jsx(re, {
    variant: "fullpage"
  }) : v();
}
function ze({ investorProfile: N, scheme_details: c, amc_code: y, amcId: W, isPartner: t, allSelectable: X, description: f, handleFolioSelection: C, handleFolioCreation: M, workflow: T }) {
  const [p, L] = n([]), [E, O] = n([]), [J, P] = n(!1), [U, A] = n("initial"), [F, $] = n(null), [_, Y] = n(!1), [h, w] = n({
    index: 0,
    obj: null
  }), [B, q] = n(null), { fpapi: g } = ne(oe), { tenantConfig: v, amcs: s } = de(), a = v == null ? void 0 : v.fallbackLogo, m = v == null ? void 0 : v.showSchemeLogo, [r, u] = n(""), [V, z] = n(!1), [k, H] = n(!1), R = ie((l, x) => {
    const b = [];
    l.folios.forEach((d) => {
      d.schemes.forEach((te) => {
        te.isin === c.isin && (b[d.folio_number] = te);
      });
    }), $(b);
    const S = [], j = [];
    return x.data.forEach((d) => {
      b[d.number] && (X || b[d.number].holdings.redeemable_units > 0 ? S.push(d) : j.push(d));
    }), {
      foliosWithSelect: S,
      foliosWithoutSelect: j
    };
  }, [
    c
  ]), ee = ie((l) => {
    const x = [];
    return l.data.forEach((b) => {
      b.amc === y && x.push(b);
    }), {
      foliosWithSelect: x,
      foliosWithoutSelect: []
    };
  }, [
    y
  ]);
  xe(() => {
    async function l() {
      try {
        P(!0);
        const x = await g.fpClient.mf_investment_accounts().fetchAll({
          investor: N.pan
        }), b = await g.fpClient.investor_reports().fetchHoldings({
          investment_account_id: x.data[0].old_id
        }), S = await g.fpClient.mf_folios().fetchAll({
          mf_investment_account: x.data[0].id
        });
        let j = {};
        if (c) {
          const d = await g.fpClient.master_data().fetchScheme(c.isin);
          q(d), j = R(b, S);
        } else if (y) {
          j = ee(S);
          const d = await g.fpClient.master_data().fetchAmcs(W);
          u(d == null ? void 0 : d.name);
        } else
          j = {
            foliosWithSelect: S.data,
            foliosWithoutSelect: []
          };
        w({
          index: 0,
          obj: j.foliosWithSelect[0]
        }), L(j.foliosWithSelect), O(j.foliosWithoutSelect), P(!1);
      } catch (x) {
        window.debug.error(x), P(!1), A("error"), ve(x);
      }
    }
    l();
  }, [
    y,
    ee,
    R,
    g,
    N,
    c
  ]);
  function me() {
    return /* @__PURE__ */ e.jsx(G, {
      variant: "fullPage",
      title: i._({
        id: "Fuk8S8"
      }),
      isOpen: _,
      contentHeightMax: !0,
      toggleModal: () => {
        Y(!1), A("initial");
      },
      children: /* @__PURE__ */ e.jsx(Ne, {
        folio: h.obj,
        isPartner: t
      })
    });
  }
  function ue() {
    var l;
    return /* @__PURE__ */ e.jsx(G, {
      variant: "fullPage",
      title: i._({
        id: "tsYSsE"
      }),
      isOpen: k,
      contentHeightMax: !0,
      toggleModal: () => {
        H(!1);
      },
      children: /* @__PURE__ */ e.jsx(Ce, {
        folio_number: (l = h == null ? void 0 : h.obj) == null ? void 0 : l.number
      })
    });
  }
  function fe() {
    return /* @__PURE__ */ e.jsxs(G, {
      title: i._({
        id: "ZymVOR"
      }),
      isOpen: V,
      contentHeightMax: !0,
      toggleModal: () => {
        z(!1);
      },
      children: [
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ e.jsx(I, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (l) => {
                l.preventDefault(), H(!k);
              },
              customClass: "flex-1",
              children: i._({
                id: "BJQWc+"
              })
            }),
            k && ue()
          ]
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-6",
          children: [
            /* @__PURE__ */ e.jsx(I, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (l) => {
                l.preventDefault(), Y(!_);
              },
              customClass: "flex-1",
              children: i._({
                id: "wuVzYo"
              })
            }),
            _ && me()
          ]
        })
      ]
    });
  }
  function se() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(je, {
          type: "text",
          text: f ? i._({
            id: "syaVTG",
            values: {
              description: f
            }
          }) : i._({
            id: "xbRrYE"
          })
        }),
        c && B && /* @__PURE__ */ e.jsx("div", {
          children: /* @__PURE__ */ e.jsx(o, {
            label: i._({
              id: "x4tqge"
            }),
            value: ce(c.name),
            background: !0,
            customClass: "mb-2",
            logo: m ? s[B.amc_id] || a : ""
          })
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-44",
          children: [
            /* @__PURE__ */ e.jsx(pe, {
              onChange: (l) => {
                w({
                  index: l.target.value,
                  obj: p[l.target.value]
                });
              },
              variant: "selectable",
              selected: h.index,
              children: p.length ? p.map((l) => /* @__PURE__ */ e.jsx(Q, {
                children: /* @__PURE__ */ e.jsxs("div", {
                  className: "flex flex-wrap items-center",
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: i._({
                          id: "cXcPd1"
                        }),
                        value: l.number
                      })
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: i._({
                          id: "+Thrcm"
                        }),
                        value: ae(l.holding_pattern)
                      })
                    }),
                    c && /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: i._({
                          id: "ez01Pf"
                        }),
                        value: `₹ ${D(F[l.number].invested_value.amount, 2)}`
                      })
                    }),
                    c && /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: i._({
                          id: "//hmdU"
                        }),
                        value: `₹ ${D(F[l.number].market_value.redeemable_amount, 2)}`
                      })
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: i._({
                          id: "kYuKEz"
                        }),
                        value: l.dp_id ? "Demat" : "Physical"
                      })
                    })
                  ]
                })
              }, l.number)) : /* @__PURE__ */ e.jsx(e.Fragment, {})
            }),
            E.length ? E.map((l) => /* @__PURE__ */ e.jsx(Q, {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-mr-2 -ml-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: i._({
                        id: "cXcPd1"
                      }),
                      value: l.number
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: i._({
                        id: "+Thrcm"
                      }),
                      value: ae(l.holding_pattern)
                    })
                  }),
                  c && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: i._({
                        id: "ez01Pf"
                      }),
                      value: `₹ ${D(F[l.number].invested_value.amount, 2)}`
                    })
                  }),
                  c && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: i._({
                        id: "//hmdU"
                      }),
                      value: `₹ ${D(F[l.number].market_value.redeemable_amount, 2)}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: i._({
                        id: "kYuKEz"
                      }),
                      value: l.dp_id ? "Demat" : "Physical"
                    })
                  })
                ]
              })
            }, l.number)) : /* @__PURE__ */ e.jsx(e.Fragment, {})
          ]
        }),
        !p.length && !E.length && /* @__PURE__ */ e.jsx(K, {
          title: i._({
            id: "0i/U4g"
          }),
          description: i._({
            id: "jVCje2"
          })
        }),
        /* @__PURE__ */ e.jsxs(ge, {
          children: [
            C ? /* @__PURE__ */ e.jsx("div", {
              className: "mb-4",
              children: /* @__PURE__ */ e.jsx(I, {
                variant: p.length ? "primary" : "disabled",
                fullwidth: !0,
                onClick: (l) => {
                  l.preventDefault(), C && C(h.obj);
                },
                children: i._({
                  id: "H/E6fZ"
                })
              })
            }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
            M ? /* @__PURE__ */ e.jsx("div", {
              className: "mb-4",
              children: /* @__PURE__ */ e.jsx(I, {
                variant: "secondary",
                fullwidth: !0,
                onClick: (l) => {
                  l.preventDefault(), M && M(h.obj);
                },
                text: "Create new folio",
                children: i._({
                  id: "+fCelk"
                })
              })
            }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
            /* @__PURE__ */ e.jsx(I, {
              variant: p.length ? "secondary" : "disabled",
              fullwidth: !0,
              onClick: (l) => {
                l.preventDefault(), z(!V);
              },
              children: i._({
                id: "f9pW3a"
              })
            })
          ]
        }),
        fe()
      ]
    });
  }
  function he() {
    switch (U) {
      case "initial":
        return se();
      case "error":
        return /* @__PURE__ */ e.jsx(K, {
          title: i._({
            id: "x1yMIE"
          }),
          description: i._({
            id: "RxIw8D"
          })
        });
      default:
        return se();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: J ? /* @__PURE__ */ e.jsx(re, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: he()
    })
  });
}
export {
  ze as F
};
