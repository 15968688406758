import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as _, useContext as q, useEffect as Y, useMemo as Z } from "react";
import { a as P, P as O, D as G, e as N, T, M as w, B as U, c as oe, q as de, b as ue, f as ce, h as me, O as he, l as $ } from "./index.esm-CqWfms5D.js";
import { R as H, F as I } from "./react-final-form.es-CUo9SHJd.js";
import { f as L, A as ne, r as M, a as ye, b as ge, l as ee } from "./validators-BNinzCrT.js";
import { m as W, s as J, t as xe, o as be, a4 as te, i as ae } from "./utils-C2gHLhxV.js";
import { F as z } from "./provider-DKLaKIVD.js";
import { i as a } from "./index-CrDCxF0S.js";
import { AppContext as fe } from "./AppContext.js";
import { u as je } from "./useAuth-BzI6Z8Il.js";
import { r as X } from "./sentry-CstOwvcR.js";
import { C as _e } from "./CopyButton-D0B5OZZ9.js";
import { L as pe } from "./LanguageSelector-DnUt2tOq.js";
import { S as Ce } from "./StatusMessage-BUdCbwjZ.js";
import { W as ve } from "./Welcome-Bz7XIwKh.js";
import { B as De } from "./BankAccountListing-CcLcjdff.js";
import { B as we } from "./BankAccountAddition-BHWGDVl9.js";
function Be(o) {
  const [d, b] = _(!1), [g, x] = _(null), [s, h] = _({
    pan: o.pan,
    name: "",
    email: "",
    mobile: "",
    id: ""
  }), { fpapi: l } = q(z);
  Y(() => {
    j();
  }, []);
  const c = async (r) => {
    var m;
    const t = await ((m = l == null ? void 0 : l.employerClient) == null ? void 0 : m.phone_numbers().fetchAll({
      profile: r
    }));
    return t == null ? void 0 : t.data;
  }, y = async (r) => {
    var m;
    const t = await ((m = l == null ? void 0 : l.employerClient) == null ? void 0 : m.email_addresses().fetchAll({
      profile: r
    }));
    return t == null ? void 0 : t.data;
  }, j = async () => {
    var r;
    try {
      b(!0);
      const t = await ((r = l == null ? void 0 : l.employerClient) == null ? void 0 : r.investor_profiles().fetchAll({
        pan: o.pan
      })), m = t == null ? void 0 : t.data;
      if (m.length > 0) {
        const i = m[0].name, F = m[0].id;
        let V = "", S = "";
        const [D, k] = await Promise.all([
          y(F),
          c(F)
        ]);
        D && D.length > 0 && (V = D[0].email), k && k.length > 0 && (S = k[0].number), h({
          ...s,
          name: i,
          id: F,
          email: V,
          mobile: S
        });
      }
    } catch {
      x(a._({
        id: "R9GMY/"
      }));
    } finally {
      b(!1);
    }
  }, u = async (r) => {
    var t, m, i, F, V;
    b(!0);
    try {
      let S = s.id, D = s.email, k = s.mobile;
      if (s.name.length === 0 && (S = (await ((t = l == null ? void 0 : l.employerClient) == null ? void 0 : t.investor_profiles().create({
        pan: r.companyPan,
        name: r.companyName,
        type: "non_individual",
        tax_status: "private_limited"
      }))).id), s.email.length === 0) {
        const A = await ((m = l == null ? void 0 : l.employerClient) == null ? void 0 : m.email_addresses().create({
          profile: S,
          email: r.companyEmail,
          belongs_to: "self"
        }));
        D = A == null ? void 0 : A.email;
      }
      if (s.mobile.length === 0) {
        const A = await ((i = l == null ? void 0 : l.employerClient) == null ? void 0 : i.phone_numbers().create({
          profile: S,
          number: r.companyMobile,
          isd: "91",
          belongs_to: "self"
        }));
        k = A == null ? void 0 : A.number;
      }
      o.associateEmployer && await ((F = l == null ? void 0 : l.internalClient) == null ? void 0 : F.employer_details().create({
        corporateIdentifier: S
      })), S && D.length > 0 && k.length > 0 && await ((V = l == null ? void 0 : l.internalClient) == null ? void 0 : V.employer_details().updateAssociatedPartner({
        lastOnboardingStep: "registration"
      })), o.onCompletion();
    } catch {
      j(), x(a._({
        id: "auW6JP"
      }));
    }
    b(!1);
  }, n = Z(() => ({
    companyName: s.name,
    companyPan: s.pan,
    companyEmail: s.email,
    companyMobile: s.mobile
  }), [
    s
  ]);
  return d ? /* @__PURE__ */ e.jsx(P, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(O, {
        title: a._({
          id: "Ye4ZSx"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ e.jsx(G, {
        type: "text",
        text: a._({
          id: "150Ori"
        })
      }),
      /* @__PURE__ */ e.jsx(H, {
        onSubmit: u,
        subscription: {
          submitting: !0,
          values: !0,
          pristine: !0,
          errors: !0,
          touched: !0
        },
        initialValues: n,
        children: (r) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: r.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "cTvHFL"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "companyPan",
                  initialValue: n.companyPan,
                  validate: L(M, ne(a._({
                    id: "2yWWDq"
                  }))),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(T, {
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: () => {
                          t.input.onChange(t.input.value.trim()), t.input.onBlur();
                        },
                        onInput: (m) => {
                          m.target.value = m.target.value.replace(W("A-Za-z0-9"), "").toUpperCase();
                        },
                        placeholder: a._({
                          id: "JLphUO"
                        }),
                        maxLength: 10,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        disabled: s.pan.length > 0
                      }),
                      t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "mIfztF"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "companyName",
                  initialValue: n.companyName,
                  validate: L(M),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(T, {
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: () => {
                          t.input.onChange(t.input.value.trim()), t.input.onBlur();
                        },
                        onInput: (m) => {
                          m.target.value = m.target.value.replace(W("A-Za-z "), "");
                        },
                        placeholder: a._({
                          id: "P9eBLz"
                        }),
                        maxLength: 70,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        disabled: s.name.length > 0
                      }),
                      t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "EMm2kB"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "companyMobile",
                  initialValue: n.companyMobile,
                  validate: L(M, ye(a._({
                    id: "/nB6Q9"
                  }))),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(T, {
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: t.input.onBlur,
                        onInput: (m) => {
                          m.target.value = m.target.value.replace(W("0-9"), "").toUpperCase();
                        },
                        placeholder: a._({
                          id: "ROg+52"
                        }),
                        maxLength: 10,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        disabled: s.mobile.length > 0
                      }),
                      t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "GJrVBj"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "companyEmail",
                  initialValue: n.companyEmail,
                  validate: L(M, ge(a._({
                    id: "1Vnbjy"
                  }))),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(T, {
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: t.input.onBlur,
                        onInput: (m) => {
                          m.target.value = m.target.value.replace(W("A-Za-z0-9._\\-@+"), "");
                        },
                        placeholder: a._({
                          id: "alra1l"
                        }),
                        maxLength: 50,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        disabled: s.email.length > 0
                      }),
                      t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            g && /* @__PURE__ */ e.jsx(w, {
              type: "error",
              variant: "field",
              title: g
            }),
            /* @__PURE__ */ e.jsx(U, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                if (r.errors) {
                  const t = Object.keys(r.errors);
                  t.length && J(t[0]);
                }
              },
              customClass: "mb-8",
              children: a._({
                id: "2iVSoY"
              })
            })
          ]
        })
      })
    ]
  });
}
const Fe = (o, d) => (o == null ? void 0 : o.cancelled_cheque) && (d == null ? void 0 : d.status) === "completed" && (d == null ? void 0 : d.reason) === "digital_verification" && (d == null ? void 0 : d.confidence) === "zero", Se = (o, d) => !(o != null && o.cancelled_cheque) && // no cancelled cheque
(d == null ? void 0 : d.status) === "completed" && // verification status is complete
(d == null ? void 0 : d.reason) === "digital_verification" && // reason is digital_verification
(d == null ? void 0 : d.confidence) === "zero", Ae = async (o, d) => {
  let b = [], g = [], x = [], s = [];
  if (o) {
    const h = o.map((l) => d.fpClient.bank_account_verifications().fetchAll({
      bank_accounts: [
        l.id
      ]
    }));
    await Promise.allSettled(h).then((l) => {
      l.forEach((y, j) => {
        if (y.status === "fulfilled") {
          const { data: u } = y.value, n = u[0], r = {
            ...o[j]
          };
          n ? (r.verification = n, n.status === "completed" ? Fe(r, n) || Se(r, n) ? x.push(r) : s.push(r) : n.status === "pending" ? x.push(r) : n.status === "failed" && g.push(r)) : b.push(r);
        } else y.status === "rejected" && window.debug.log(y);
      });
      const c = (y) => y.sort((j, u) => Number(new Date(u.updated_at)) - Number(new Date(j.updated_at)));
      g = c(g), s = c(s), x = c(x), b = c(b);
    }).catch((l) => {
      window.debug.log(l), X(l);
    });
  }
  return {
    failed: [
      ...g
    ],
    pending: [
      ...x
    ],
    complete: [
      ...s
    ],
    noVerification: [
      ...b
    ]
  };
};
function ke(o) {
  const [d, b] = _(!1), [g, x] = _(!1), [s, h] = _(!1), [l, c] = _(null), { fpapi: y } = q(z), j = async () => {
    var u;
    b(!0);
    try {
      await ((u = y == null ? void 0 : y.internalClient) == null ? void 0 : u.employer_details().updateAssociatedPartner({
        lastOnboardingStep: "employer_declaration"
      })), o.onCompletion();
    } catch {
      c(a._({
        id: "dMnEVb"
      }));
    }
    b(!1);
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      d && /* @__PURE__ */ e.jsx(P, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(O, {
        title: a._({
          id: "EiD36s"
        }),
        customClass: "mb-3",
        semiBold: !0
      }),
      /* @__PURE__ */ e.jsx(O, {
        variant: "sub",
        title: a._({
          id: "qcR7Gv"
        })
      }),
      /* @__PURE__ */ e.jsx(oe, {
        border: !0,
        label: o.employer.name,
        customClass: "mb-6"
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "dark:text-neutral-40 mb-6 leading-relaxed",
        children: [
          /* @__PURE__ */ e.jsx("p", {
            className: "pb-2",
            children: a._({
              id: "+wEdfc"
            })
          }),
          /* @__PURE__ */ e.jsx("p", {
            className: "pb-2",
            children: a._({
              id: "WQRjRt"
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs(de, {
        id: "terms-id",
        checked: g,
        onChange: (u) => {
          u.stopPropagation(), x(!g);
        },
        customClass: "mb-3",
        children: [
          a._({
            id: "9nnBWQ"
          }),
          /* @__PURE__ */ e.jsx("a", {
            onClick: (u) => {
              u.preventDefault(), u.stopPropagation(), h(!0);
            },
            className: "text-size-14",
            children: a._({
              id: "wt7bu+"
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsx(ue, {
        variant: "fullPage",
        title: a._({
          id: "4yYTaY"
        }),
        isOpen: s,
        toggleModal: h,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx("div", {
            children: /* @__PURE__ */ e.jsxs("div", {
              className: "dark:text-neutral-40 leading-relaxed",
              children: [
                /* @__PURE__ */ e.jsx("p", {
                  className: "pb-2",
                  children: a._({
                    id: "Kgj7HF"
                  })
                }),
                /* @__PURE__ */ e.jsx("p", {
                  className: "pb-2",
                  children: a._({
                    id: "1lrkXw"
                  })
                }),
                /* @__PURE__ */ e.jsx("p", {
                  className: "pb-2",
                  children: a._({
                    id: "xTR2uF"
                  })
                })
              ]
            })
          })
        })
      }),
      l && /* @__PURE__ */ e.jsx(w, {
        type: "error",
        variant: "field",
        title: l
      }),
      /* @__PURE__ */ e.jsx(U, {
        variant: g ? "primary" : "disabled",
        onClick: j,
        type: "button",
        fullwidth: !0,
        children: a._({
          id: "2iVSoY"
        })
      })
    ]
  });
}
function Ee(o) {
  const [d, b] = _(!1), g = "XXXCX3751X", x = async (l) => {
    b(!0), o.onCompletion(l.companyPan), b(!1);
  }, s = Z(() => ({
    companyPan: ""
  }), []);
  function h(l, c) {
    c.fields.companyPan.change(l.length && l[0]);
  }
  return d ? /* @__PURE__ */ e.jsx(P, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(O, {
        title: a._({
          id: "ysBmvQ"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ e.jsx(N, {
        message: a._({
          id: "bdIK08"
        })
      }),
      /* @__PURE__ */ e.jsx(H, {
        onSubmit: x,
        subscription: {
          submitting: !0,
          values: !0,
          pristine: !0,
          errors: !0,
          touched: !0
        },
        initialValues: s,
        mutators: {
          prepopulatePAN: h
        },
        children: (l) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: l.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-8",
              children: /* @__PURE__ */ e.jsx(I, {
                name: "companyPan",
                initialValue: s.companyPan,
                validate: L(M, ne(a._({
                  id: "2yWWDq"
                }))),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validateFields: [],
                children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(T, {
                      name: c.input.name,
                      value: c.input.value,
                      onChange: c.input.onChange,
                      onBlur: () => {
                        c.input.onChange(c.input.value.trim()), c.input.onBlur();
                      },
                      onInput: (y) => {
                        y.target.value = y.target.value.replace(W("A-Za-z0-9"), "").toUpperCase();
                      },
                      placeholder: a._({
                        id: "JLphUO"
                      }),
                      maxLength: 10,
                      status: c.meta.error && c.meta.touched ? "error" : void 0
                    }),
                    c.meta.error && c.meta.touched && /* @__PURE__ */ e.jsx(w, {
                      type: "error",
                      variant: "field",
                      title: c.meta.error
                    })
                  ]
                })
              })
            }),
            /* @__PURE__ */ e.jsx(pe, {}),
            /* @__PURE__ */ e.jsx(U, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                if (l.errors) {
                  const c = Object.keys(l.errors);
                  c.length && J(c[0]);
                }
              },
              customClass: "mb-8",
              children: a._({
                id: "2iVSoY"
              })
            }),
            xe.isStagingOrSandbox ? /* @__PURE__ */ e.jsx(e.Fragment, {
              children: /* @__PURE__ */ e.jsx(ce, {
                title: a._({
                  id: "2FU6mE"
                }),
                customClass: "mt-4",
                children: /* @__PURE__ */ e.jsx(me, {
                  title: a._({
                    id: "Yl0u0v"
                  }),
                  label: /* @__PURE__ */ e.jsxs("span", {
                    children: [
                      /* @__PURE__ */ e.jsx("span", {
                        className: "text-neutral-40",
                        children: "XXX"
                      }),
                      g.slice(3, 4),
                      /* @__PURE__ */ e.jsx("span", {
                        className: "text-neutral-40",
                        children: "X"
                      }),
                      g.slice(5, 9),
                      /* @__PURE__ */ e.jsx("span", {
                        className: "text-neutral-40",
                        children: "X"
                      })
                    ]
                  }),
                  customClass: "mb-3",
                  children: /* @__PURE__ */ e.jsx(_e, {
                    value: g,
                    onClick: () => l.form.mutators.prepopulatePAN(g)
                  })
                })
              })
            }) : null
          ]
        })
      })
    ]
  });
}
function Ne(o) {
  var y, j, u;
  const [d, b] = _(!1), [g, x] = _({
    email: "",
    mobile: ""
  }), { fpapi: s } = q(z);
  Y(() => {
    h();
  }, [
    o.employerDetails
  ]);
  const h = async () => {
    try {
      if (b(!0), o.employerDetails) {
        const [n, r] = await Promise.all([
          c(o.employerDetails.id),
          l(o.employerDetails.id)
        ]);
        x({
          email: n[0].email,
          mobile: r[0].number
        });
      }
    } catch (n) {
      window.debug.error(n), X(n);
    } finally {
      b(!1);
    }
  }, l = async (n) => {
    var t;
    const r = await ((t = s == null ? void 0 : s.employerClient) == null ? void 0 : t.phone_numbers().fetchAll({
      profile: n
    }));
    return r == null ? void 0 : r.data;
  }, c = async (n) => {
    var t;
    const r = await ((t = s == null ? void 0 : s.employerClient) == null ? void 0 : t.email_addresses().fetchAll({
      profile: n
    }));
    return r == null ? void 0 : r.data;
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      d && /* @__PURE__ */ e.jsx(P, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(Ce, {
        title: a._({
          id: "TM85c7"
        }),
        description: a._({
          id: "McZk47",
          values: {
            0: (y = o.employerDetails) == null ? void 0 : y.id
          }
        }),
        iconName: "successInfo",
        children: /* @__PURE__ */ e.jsx(he, {
          tableData: [
            {
              label: a._({
                id: "uR3xiz"
              }),
              value: (j = o.employerDetails) == null ? void 0 : j.name
            },
            {
              label: a._({
                id: "m9snku"
              }),
              value: (u = o.employerDetails) == null ? void 0 : u.pan
            },
            {
              label: a._({
                id: "oyjr24"
              }),
              value: g.email
            },
            {
              label: a._({
                id: "0VsDgp"
              }),
              value: g.mobile
            }
          ]
        })
      }),
      /* @__PURE__ */ e.jsx(w, {
        type: "important",
        variant: "flash",
        title: a._({
          id: "XwuULh"
        }),
        customClass: "mb-5"
      })
    ]
  });
}
function Ie(o) {
  const [d, b] = _(!1), [g, x] = _(null), { fpapi: s } = q(z), h = /* @__PURE__ */ new Date(), l = new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() + 32)), c = async (u) => {
    var n;
    b(!0);
    try {
      await ((n = s == null ? void 0 : s.internalClient) == null ? void 0 : n.employer_details().updateAssociatedPartner({
        salaryDay: u.salaryDay.getDate(),
        orderCutoffDay: u.orderCutoffDay.getDate(),
        lastOnboardingStep: "salary_processing_details"
      })), o.onCompletion();
    } catch {
      x(a._({
        id: "dMnEVb"
      }));
    }
    b(!1);
  }, y = Z(() => ({
    salaryDay: "",
    orderCutoffDay: "",
    frequency: "monthly"
  }), []), j = [
    {
      label: a._({
        id: "UmLzT3"
      }),
      value: "monthly"
    }
  ];
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(O, {
        title: a._({
          id: "nvGvIW"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ e.jsx(G, {
        type: "text",
        text: a._({
          id: "5r8AeA"
        })
      }),
      d && /* @__PURE__ */ e.jsx(P, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(H, {
        onSubmit: c,
        subscription: {
          submitting: !0,
          values: !0,
          pristine: !0,
          errors: !0,
          touched: !0
        },
        initialValues: y,
        children: (u) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: u.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "5BY6Sp"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "frequency",
                  initialValue: y.frequency,
                  validate: M,
                  validateFields: [],
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(T, {
                        name: n.input.name,
                        value: j[be(j, "monthly")].label,
                        disabled: !0,
                        id: n.input.name,
                        autoComplete: "off"
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "7l0p8q"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "salaryDay",
                  initialValue: y.salaryDay,
                  validate: L(M, ee),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx($, {
                        name: n.input.name,
                        variant: "default",
                        placeholder: a._({
                          id: "OhX6/t"
                        }),
                        format: "dd-MMM-yyyy",
                        selectedDate: n.input.value || null,
                        minDate: h,
                        maxDate: l,
                        initialFocusedDate: h,
                        error: n.meta.error && n.meta.touched,
                        helperText: null,
                        onKeyDown: (r) => r.preventDefault(),
                        handleDateChange: (r) => n.input.onChange(r),
                        labelFunc: (r) => te(r, "monthly", "")
                      }),
                      /* @__PURE__ */ e.jsx(w, {
                        type: "helper",
                        variant: "field",
                        title: a._({
                          id: "J+Z8xe"
                        })
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(N, {
                  message: a._({
                    id: "kVMJFH"
                  })
                }),
                /* @__PURE__ */ e.jsx(I, {
                  name: "orderCutoffDay",
                  initialValue: y.orderCutoffDay,
                  validate: L(M, ee),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx($, {
                        name: n.input.name,
                        variant: "default",
                        placeholder: a._({
                          id: "kVivtZ"
                        }),
                        format: "dd-MMM-yyyy",
                        selectedDate: n.input.value || null,
                        minDate: h,
                        maxDate: l,
                        initialFocusedDate: h,
                        error: n.meta.error && n.meta.touched,
                        helperText: null,
                        onKeyDown: (r) => r.preventDefault(),
                        handleDateChange: (r) => n.input.onChange(r),
                        labelFunc: (r) => te(r, "monthly", "")
                      }),
                      /* @__PURE__ */ e.jsx(w, {
                        type: "helper",
                        variant: "field",
                        title: a._({
                          id: "Wu6NSG"
                        })
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(w, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            g && /* @__PURE__ */ e.jsx(w, {
              type: "error",
              variant: "field",
              title: g
            }),
            /* @__PURE__ */ e.jsx(U, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                if (u.errors) {
                  const n = Object.keys(u.errors);
                  n.length && J(n[0]);
                }
              },
              customClass: "mb-8",
              children: a._({
                id: "AtXdm9"
              })
            })
          ]
        })
      })
    ]
  });
}
function Qe() {
  var Q, K;
  const { AppState: { showWelcomePage: o }, setEmployerBeingOnboarded: d, setShowWelcomePage: b } = q(fe), [g, x] = _("login"), [s, h] = _(!0), [l, c] = _(!0), [y, j] = _(""), [u, n] = _(null), [r, t] = _(null), m = [
    "registration",
    "bank_details",
    "salary_processing_details",
    "employer_declaration"
  ], { fpapi: i } = q(z), F = je();
  Y(() => {
    var p, f, C, v;
    F.isLoading || (!F.isAuthenticated && o === !1 ? F.login() : o === !1 && ((f = (p = i == null ? void 0 : i.internalClient) == null ? void 0 : p.api) != null && f.accessToken) && ((v = (C = i == null ? void 0 : i.internalClient) == null ? void 0 : C.api) == null ? void 0 : v.accessToken.length) > 0 ? D() : o === !0 && h(!1));
  }, [
    F.isAuthenticated,
    F.isLoading,
    o,
    (K = (Q = i == null ? void 0 : i.internalClient) == null ? void 0 : Q.api) == null ? void 0 : K.accessToken
  ]);
  const V = (p) => {
    if (p) {
      const f = m.indexOf(p);
      return f === -1 ? m[0] : f === m.length - 1 ? "complete" : m[f + 1];
    } else
      return m[0];
  }, S = () => {
    b(!1), h(!0);
  }, D = async () => {
    var p, f, C;
    try {
      h(!0);
      const v = await ((p = i == null ? void 0 : i.internalClient) == null ? void 0 : p.employer_details().fetchAssociated());
      if (ae(v))
        t(null), j(""), n(null), x("login");
      else {
        if (ae(u)) {
          const B = await ((f = i == null ? void 0 : i.employerClient) == null ? void 0 : f.investor_profiles().fetch(v.corporateIdentifier));
          j((B == null ? void 0 : B.pan) || ""), n(B), d(B == null ? void 0 : B.name);
        }
        const E = V(v.lastOnboardingStep);
        if (E === "complete" && !v.onboardingComplete) {
          const B = await A(v.corporateIdentifier), { complete: R } = await Ae(B, i);
          R.length > 0 ? (await ((C = i == null ? void 0 : i.internalClient) == null ? void 0 : C.employer_details().updateAssociatedPartner({
            onboardingComplete: !0
          })), x(E)) : x("employer_bank_list");
        } else
          x(E);
        t(v);
      }
    } catch (v) {
      window.debug.error(v), X(v), h(!1);
    } finally {
      h(!1);
    }
  }, k = [
    {
      step: 1,
      content: a._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: a._({
        id: "idaBI9"
      })
    },
    {
      step: 3,
      content: a._({
        id: "urqQMu"
      })
    }
  ], A = async (p) => {
    var C;
    const f = await ((C = i == null ? void 0 : i.employerClient) == null ? void 0 : C.bank_accounts().fetchAll({
      profile: p
    }));
    return f == null ? void 0 : f.data;
  }, re = async ({ success: p }) => {
    var f;
    if (p) {
      try {
        h(!0), await ((f = i == null ? void 0 : i.internalClient) == null ? void 0 : f.employer_details().updateAssociatedPartner({
          lastOnboardingStep: "bank_details"
        }));
      } catch (C) {
        window.debug.error(C), X(C);
      } finally {
        h(!1);
      }
      D();
    }
  }, le = async (p) => {
    var f;
    if (p) {
      try {
        h(!0), await ((f = i == null ? void 0 : i.internalClient) == null ? void 0 : f.employer_details().updateAssociatedPartner({
          onboardingComplete: !0
        }));
      } catch (C) {
        window.debug.error(C), X(C);
      } finally {
        h(!1);
      }
      D();
    }
  }, ie = async (p) => {
    var f, C, v;
    try {
      h(!0);
      const E = await ((f = i == null ? void 0 : i.employerClient) == null ? void 0 : f.investor_profiles().fetchAll({
        pan: p
      })), B = E == null ? void 0 : E.data;
      if (j(p), B.length === 0)
        x("registration"), h(!1);
      else {
        await ((C = i == null ? void 0 : i.internalClient) == null ? void 0 : C.employer_details().create({
          corporateIdentifier: B[0].id
        }));
        const R = await ((v = i == null ? void 0 : i.employerClient) == null ? void 0 : v.investor_profiles().fetch(B[0].id));
        n(R), D();
      }
    } catch {
      x("login"), h(!1);
    }
  }, se = () => {
    switch (g) {
      case "login":
        return /* @__PURE__ */ e.jsx(Ee, {
          onCompletion: ie
        });
      case "registration":
        return /* @__PURE__ */ e.jsx(Be, {
          onCompletion: D,
          pan: y,
          associateEmployer: r === null || (r == null ? void 0 : r.corporateIdentifier) === null || (r == null ? void 0 : r.corporateIdentifier.length) === 0
        });
      case "bank_details":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(O, {
                  title: a._({
                    id: "q4PFMU"
                  }),
                  semiBold: !0
                }),
                /* @__PURE__ */ e.jsx(G, {
                  type: "text",
                  text: a._({
                    id: "tt5aNG"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(we, {
              hideTitle: !0,
              onCompletion: re,
              investorProfile: u,
              bankAccountType: "current"
            })
          ]
        });
      case "salary_processing_details":
        return u && /* @__PURE__ */ e.jsx(Ie, {
          onCompletion: D,
          employer: u
        });
      case "employer_declaration":
        return u && /* @__PURE__ */ e.jsx(ke, {
          onCompletion: D,
          employer: u
        });
      case "employer_bank_list":
        return /* @__PURE__ */ e.jsx(De, {
          investorProfile: u,
          handleBankSelection: le
        });
      case "complete":
        return /* @__PURE__ */ e.jsx(Ne, {
          employerDetails: u
        });
      default:
        return /* @__PURE__ */ e.jsx(P, {
          variant: "fullpage"
        });
    }
  };
  return s ? /* @__PURE__ */ e.jsx(P, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      l && /* @__PURE__ */ e.jsx(O, {
        title: a._({
          id: "ivl6OM"
        }),
        customClass: "mb-6"
      }),
      o ? /* @__PURE__ */ e.jsx(ve, {
        onAck: S,
        welcomeSteps: k
      }) : se()
    ]
  });
}
export {
  Qe as EmployerOnboarding
};
