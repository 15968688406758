import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as f, useState as m, useEffect as x } from "react";
import { i as l } from "./index-CrDCxF0S.js";
import { a as h } from "./index.esm-CqWfms5D.js";
import { F as C } from "./provider-DKLaKIVD.js";
import { LoginContext as j } from "./LoginContext.js";
import { r as g } from "./sentry-CstOwvcR.js";
import { A as P } from "./Auth-CJPsuV-i.js";
import { ErrorPage as _ } from "./ErrorPage.js";
import { S as w, a as v } from "./SwitchPlanConsent-kUlk0ucU.js";
import { R, a as S } from "./RedemptionPlanConsent-BhmHdgnT.js";
import { P as L, a as A } from "./PurchasePlanConsent-B_WNjB3i.js";
function B({ object_type: o, action: r, id: s }) {
  const { loggedInUser: p } = f(j), [c, i] = m(!1), [t, u] = m(null), { fpapi: a } = f(C);
  x(() => {
    a && a.getInvestorProfile().then((n) => {
      n && u(n), i(!1);
    }).catch((n) => {
      i(!1), g(n);
    });
  }, [
    a
  ]);
  const d = () => {
    switch (o || (o = "invalid_id"), o) {
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "mf_purchase":
        return /* @__PURE__ */ e.jsx(A, {
          investorProfile: t,
          action: r,
          id: s
        });
      case "mf_redemption":
        return /* @__PURE__ */ e.jsx(S, {
          investorProfile: t,
          action: r,
          id: s
        });
      case "mf_switch":
        return /* @__PURE__ */ e.jsx(v, {
          investorProfile: t,
          action: r,
          id: s
        });
      case "mf_purchase_plan":
        return /* @__PURE__ */ e.jsx(L, {
          investorProfile: t,
          action: r,
          id: s
        });
      case "mf_redemption_plan":
        return /* @__PURE__ */ e.jsx(R, {
          investorProfile: t,
          action: r,
          id: s
        });
      case "mf_switch_plan":
        return /* @__PURE__ */ e.jsx(w, {
          investorProfile: t,
          action: r,
          id: s
        });
      case "invalid_id":
        return /* @__PURE__ */ e.jsx(_, {
          title: l._({
            id: "ZdjdxA"
          }),
          description: l._({
            id: "Sh6ppf"
          }),
          showDetails: !1
        });
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: c ? /* @__PURE__ */ e.jsx(h, {
      variant: "fullpage"
    }) : p ? d() : /* @__PURE__ */ e.jsx(P, {
      redirectURL: window.location.href,
      allowRegistration: !1,
      showTestPan: {
        onboarded: !0,
        kycCompliant: !1,
        kycNonCompliant: !1
      }
    })
  });
}
export {
  B as Consent
};
