import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { useCallback as l } from "react";
import { P as d } from "./index.esm-CqWfms5D.js";
import { i as r } from "./index-CrDCxF0S.js";
function j({ disableBackButton: i = !1, pageTitle: e, content: n }) {
  const a = l(() => {
    i || window.history.back();
  }, []);
  return /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(d, {
        title: e || r._({
          id: "7u8l4U"
        }),
        icon: i ? "" : "Back",
        onClick: a,
        customClass: "mb-4"
      }),
      /* @__PURE__ */ s.jsx("div", {
        children: n ? /* @__PURE__ */ s.jsx(s.Fragment, {
          children: n
        }) : /* @__PURE__ */ s.jsx(t, {})
      })
    ]
  });
}
const t = () => /* @__PURE__ */ s.jsxs("div", {
  className: "dark:text-neutral-40 leading-relaxed",
  children: [
    /* @__PURE__ */ s.jsx("p", {
      className: "pb-2",
      children: r._({
        id: "Kgj7HF"
      })
    }),
    /* @__PURE__ */ s.jsx("p", {
      className: "pb-2",
      children: r._({
        id: "1lrkXw"
      })
    }),
    /* @__PURE__ */ s.jsx("p", {
      className: "pb-2",
      children: r._({
        id: "xTR2uF"
      })
    })
  ]
});
export {
  j as T
};
