import o, { createContext as n } from "react";
const e = n(void 0), i = () => {
  const t = o.useContext(e);
  if (!t)
    throw new Error("useConfiguration must be used within an <ConfigurationProvider/>");
  return t;
};
export {
  e as C,
  i as u
};
