import { useContext } from "react";
import { LoginContext } from "fpapps/LoginContext";
import { FpApiContext } from "fpapps/FpApiContext";
import { Icon, Dropdown } from "pixel";

export default function InvestorLogout() {
  const { loggedInUser, loggedInPartner } = useContext(LoginContext);
  const { fpapi } = useContext(FpApiContext);

  return Boolean(loggedInUser) | Boolean(loggedInPartner) ? (
    <li className="flex items-center pl-4">
      <Dropdown
        variant="withAvatar"
        placement="bottomRight"
        dropdownOptions={[
          {
            id: "1",
            label: "Logout",
            clicked: () => {
              sessionStorage.setItem("showWelcomePage", false);
              sessionStorage.removeItem("loggedInPartner");
              const postLogoutUrl = window.location.pathname + window.location.search;
              fpapi.userAuthService.initiateLogout(postLogoutUrl).then((res) => {
                window.location.href = res.userState;
              });
            },
          },
        ]}
      >
        <Icon name="Avatar" />
      </Dropdown>
    </li>
  ) : null;
}
