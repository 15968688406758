import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { i as d } from "./index-CrDCxF0S.js";
import { a as X, P, D as Q, e as v, j as ta, M as N, B as W, w as me, O as ia, S as fe, c as ne, p as ce, i as ra, C as de, F as $e, k as Ie, q as he, T as y, R as da } from "./index.esm-CqWfms5D.js";
import { useContext as $, useState as O, useRef as la, useEffect as ue, useLayoutEffect as sa, useMemo as ea } from "react";
import { AppContext as ca } from "./AppContext.js";
import { u as ua } from "./useAuth-BzI6Z8Il.js";
import { F as te } from "./provider-DKLaKIVD.js";
import { R as ie, F as E } from "./react-final-form.es-CUo9SHJd.js";
import { f as S, i as oa, j as ma, r as j, g as G, h as U, D as _a, a as ha, b as fa, E as Ia, F as Xe, c as xa, G as Na, H as Ca } from "./validators-BNinzCrT.js";
import { r as ee } from "./sentry-CstOwvcR.js";
import { S as ga } from "./StatusMessage-BUdCbwjZ.js";
import { s as aa, m as H, d as _e, o as Aa, f as va } from "./utils-C2gHLhxV.js";
import { W as Ea } from "./Welcome-Bz7XIwKh.js";
const Ze = {
  "text/csv": 1,
  "application/csv": 1
}, ya = ({ onComplete: m }) => {
  var a, t;
  const { fpapi: x } = $(te), [C, f] = O(!1), [u, _] = O(""), [s, h] = O(null), r = la({
    amc_config: !1
  }), c = s == null ? void 0 : s.pg, n = (c == null ? void 0 : c.status) === "NOT_CONFIGURED", g = ((a = c == null ? void 0 : c.razorpay) == null ? void 0 : a.status) === "CONFIGURED", F = ((t = c == null ? void 0 : c.billdesk) == null ? void 0 : t.status) === "CONFIGURED";
  ue(() => {
    (async () => {
      var l;
      try {
        f(!0);
        const o = await x.fpClient.tenant_management().fetch();
        if (((l = o == null ? void 0 : o.oms) == null ? void 0 : l.status) === "NOT_CONFIGURED") {
          m({
            completedStep: "AMC_CONFIGURATION",
            skip: !0
          });
          return;
        }
        h(o);
      } catch (o) {
        window.debug.error(o, "Error"), ee(o);
      } finally {
        f(!1);
      }
    })();
  }, []);
  const D = () => {
    const l = {
      razorpay: "https://drive.google.com/file/d/1sgwamOp5HEN5NqDDw788G1eNNWxLF9Bj/view?usp=sharing",
      billdesk: "https://drive.google.com/file/d/1ag_m-Fc5a3yOL_xN9F-R4idLRbyHBsJz/view?usp=sharing",
      no_pg: "https://drive.google.com/file/d/1MmaPnH3MvKvH-QMQyPI-RBrw8UlfbOyR/view?usp=sharing"
    };
    return n ? l.no_pg : g ? l.razorpay : F ? l.billdesk : "#";
  }, p = () => n ? d._({
    id: "EsAFjx"
  }) : d._({
    id: "9TbC40"
  }), A = async (l) => {
    try {
      f(!0);
      const o = new FormData();
      o.append("file", l.amc_config);
      const I = [
        x.fpClient.tenant_management().configureAmcsOMS(o)
      ];
      g && I.push(x.fpClient.tenant_management().configureAmcsPG(o)), await Promise.all(I), m({
        completedStep: "AMC_CONFIGURATION",
        data: {},
        skip: !1
      });
    } catch (o) {
      window.debug.error(o, "Error"), _("Error updating details");
    } finally {
      f(!1);
    }
  };
  return C ? /* @__PURE__ */ e.jsx(X, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(P, {
        title: d._({
          id: "+a+0uK"
        }),
        customClass: "mb-2"
      }),
      /* @__PURE__ */ e.jsx(Q, {
        type: "text",
        text: d._({
          id: "hr/Er1"
        })
      }),
      s ? /* @__PURE__ */ e.jsx(ie, {
        onSubmit: A,
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (l) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: l.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(v, {
                  message: d._({
                    id: "+rZrZx"
                  })
                }),
                /* @__PURE__ */ e.jsx(E, {
                  name: "amc_config",
                  validate: S(j, ma(5, "File size should be less than 5 MB"), oa(Ze, "Format not supported")),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0,
                    pristine: !0
                  },
                  validateFields: [],
                  children: (o) => {
                    var I, b;
                    return r.current = {
                      ...r.current,
                      amc_config: ((I = o == null ? void 0 : o.meta) == null ? void 0 : I.pristine) ?? !1
                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(ta, {
                          inputName: "amc_config",
                          customClass: "customClassName",
                          value: o.input.value,
                          handleFile: o.input.onChange,
                          accept: (b = Object.keys(Ze)) == null ? void 0 : b.join(", ")
                        }),
                        o.meta.error && o.meta.touched ? /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: o.meta.error
                        }) : /* @__PURE__ */ e.jsx(N, {
                          type: "helper",
                          variant: "field",
                          title: d._({
                            id: "mXExY4"
                          })
                        })
                      ]
                    });
                  }
                })
              ]
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-4",
              children: /* @__PURE__ */ e.jsx("a", {
                href: D(),
                className: "text-size-14",
                target: "_blank",
                rel: "noreferrer",
                children: p()
              })
            }),
            u ? /* @__PURE__ */ e.jsx(N, {
              type: "error",
              variant: "field",
              title: u,
              customClass: "mb-5"
            }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
            /* @__PURE__ */ e.jsx(W, {
              type: "submit",
              fullwidth: !0,
              customClass: "mb-2 mt-4",
              children: d._({
                id: "2iVSoY"
              })
            })
          ]
        })
      }) : null
    ]
  });
}, Je = async (m, x) => {
  const f = await (await fetch(m, x)).blob();
  return new File([
    f
  ], x == null ? void 0 : x.fileName, {
    type: f.type
  });
}, qe = async ({ fpapi: m, pan: x, file: C, filePurpose: f }) => {
  const u = new FormData(), _ = C.type;
  return u.append("file", C, `${x}_${f}.${_}`), u.append("purpose", f), m.fpClient.files().create(u);
}, xe = {
  1: {
    amc_id: 1,
    fund_name: "AXIS LONG TERM EQUITY FUND REGULAR GROWTH",
    isin: "INF846K01131"
  },
  2: {
    amc_id: 2,
    fund_name: "BARODA BNP PARIBAS SHORT DURATION FUND REGULAR MONTHLY IDCW REINVESTMENT",
    isin: "INF955L01146"
  },
  3: {
    amc_id: 3,
    fund_name: "ADITYA BIRLA SUN LIFE EQUITY ADVANTAGE FUND - IDCW PAYOUT-REGULAR PLAN",
    isin: "INF209K01157"
  },
  4: {
    amc_id: 4,
    fund_name: "BARODA BNP PARIBAS ELSS FUND IDCW PAYOUT",
    isin: "INF251K01993"
  },
  5: {
    amc_id: 5,
    fund_name: "BANK OF INDIA TAX ADVANTAGE FUND - REGULAR PLAN - IDCW PAYOUT",
    isin: "INF761K01173"
  },
  6: {
    amc_id: 6,
    fund_name: "CANARA ROBECO EMERGING EQUITIES REGULAR - IDCW REINVESTMENT",
    isin: "INF760K01183"
  },
  7: {
    amc_id: 7,
    fund_name: "PGIM INDIA LIQUID FUND REGULAR PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF223J01BO9"
  },
  8: {
    amc_id: 8,
    fund_name: "DSP EQUITY AND BOND FUND - IDCW REINVESTMENT",
    isin: "INF740K01326"
  },
  9: {
    amc_id: 9,
    fund_name: "EDELWEISS GOVERNMENT SECURITIES FUND - REGULAR PLAN - ANNUAL IDCW PAYOUT",
    isin: "INF843K01HL5"
  },
  11: {
    amc_id: 11,
    fund_name: "FRANKLIN INDIA DEBT HYBRID FUND - PLAN A - GROWTH",
    isin: "INF090I01EA7"
  },
  13: {
    amc_id: 13,
    fund_name: "HDFC OVERNIGHT FUND REGULAR PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF179KB1IF8"
  },
  14: {
    amc_id: 14,
    fund_name: "HSBC CORPORATE BOND FUND-REGULAR-QUARTERLY IDCW PAYOUT",
    isin: "INF917K01AG2"
  },
  15: {
    amc_id: 15,
    fund_name: "ICICI PRUDENTIAL LONG TERM EQUITY FUND (TAX SAVING) - IDCW PAYOUT",
    isin: "INF109K01472"
  },
  16: {
    amc_id: 16,
    fund_name: "IDBI Hybrid Equity FundRegular Plan-IDCW",
    isin: "INF397L01JD3"
  },
  17: {
    amc_id: 17,
    fund_name: "BANDHAN GOVERNMENT SECURITIES FUND - INVESTMENT PLAN-REGULAR-IDCW REINVESTMENT",
    isin: "INF194K01EK2"
  },
  18: {
    amc_id: 18,
    fund_name: "GROWW LIQUID FUND - EXISTING PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF666M01022"
  },
  20: {
    amc_id: 20,
    fund_name: "JM EQUITY HYBRID FUND - IDCW PAYOUT OPTION",
    isin: "INF192K01528"
  },
  21: {
    amc_id: 21,
    fund_name: "KOTAK BOND FUND - GROWTH",
    isin: "INF174K01EM0"
  },
  23: {
    amc_id: 23,
    fund_name: "LIC AGGRESSIVE HYBRID FUND - REGULAR PLAN - IDCW - IDCW PAYOUT",
    isin: "INF767K01022"
  },
  24: {
    amc_id: 24,
    fund_name: "MIRAE ASSET SAVINGS FUND - REGULAR PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF769K01986"
  },
  25: {
    amc_id: 25,
    fund_name: "MOTILAL OSWAL ULTRA SHORT TERM FUND - REGULAR-DAILY IDCW REINVESTMENT OPTION",
    isin: "INF247L01270"
  },
  27: {
    amc_id: 27,
    fund_name: "PARAG PARIKH FLEXI CAP FUND- REGULAR PLAN GROWTH",
    isin: "INF879O01019"
  },
  28: {
    amc_id: 28,
    fund_name: "SUNDARAM AGGRESSIVE HYBRID FUND - REGULAR PLAN MONTHLY IDCW PAYOUT",
    isin: "INF173K01CI4"
  },
  29: {
    amc_id: 29,
    fund_name: "QUANTUM EQUITY FUND OF FUNDS - REGULAR PLAN IDCW PAYOUT OPTION",
    isin: "INF082J01283"
  },
  30: {
    amc_id: 30,
    fund_name: "NIPPON INDIA PHARMA FUND - IDCW REINVESTMENT OPTION",
    isin: "INF204K01943"
  },
  31: {
    amc_id: 31,
    fund_name: "INVESCO INDIA CREDIT RISK FUND - REGULAR PLAN GROWTH",
    isin: "INF205K01I34"
  },
  33: {
    amc_id: 33,
    fund_name: "SBI BANKING AND PSU FUND REGULAR DAILY IDCW REINVESTMENT",
    isin: "INF200K01U58"
  },
  34: {
    amc_id: 34,
    fund_name: "SHRIRAM HYBRID EQUITY FUND REGULAR - GROWTH",
    isin: "INF680P01018"
  },
  35: {
    amc_id: 35,
    fund_name: "SUNDARAM DEBT ORIENTED HYBRID FUND REGULAR QUARTERLY IDCW REINVESTMENT",
    isin: "INF903J01HG9"
  },
  36: {
    amc_id: 36,
    fund_name: "TATA TREASURY ADVANTAGE FUND REGULAR PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF277K01LZ8"
  },
  37: {
    amc_id: 37,
    fund_name: "TAURUS INFRASTRUCTURE FUND IDCW REINVESTMENT",
    isin: "INF044D01880"
  },
  38: {
    amc_id: 38,
    fund_name: "UNION TAX SAVER (ELSS) FUND - REGULAR PLAN IDCW PAYOUT",
    isin: "INF582M01328"
  },
  39: {
    amc_id: 39,
    fund_name: "UTI ELSS TAX SAVER FUND -REGULAR PLAM-IDCW PAYOUT",
    isin: "INF789F01521"
  },
  40: {
    amc_id: 40,
    fund_name: "MAHINDRA MANULIFE LIQUID FUND - REGULAR - DAILY IDCW REINVESTMENT",
    isin: "INF174V01028"
  },
  41: {
    amc_id: 41,
    fund_name: "NAVI NIFTY BANK INDEX FUND - REGULAR PLAN GROWTH",
    isin: "INF959L01FU2"
  },
  42: {
    amc_id: 42,
    fund_name: "360 ONE FOCUSED EQUITY FUND REGULAR PLAN- IDCW PAYOUT",
    isin: "INF579M01886"
  },
  43: {
    amc_id: 43,
    fund_name: "ITI ARBITRAGE FUND REGULAR PLAN IDCW PAYOUT",
    isin: "INF00XX01424"
  },
  44: {
    amc_id: 44,
    fund_name: "QUANT ABSOLUTE FUND REGULAR PLAN - IDCW PAYOUT",
    isin: "INF966L01242"
  },
  45: {
    amc_id: 45,
    fund_name: "TRUSTMF BANKING AND PSU DEBT FUND - REGULAR PLAN - ANNUALLY IDCW PAYOUT",
    isin: "INF0GCD01099"
  },
  46: {
    amc_id: 46,
    fund_name: "WOC Ultra Short Term Fund Regular - Growth",
    isin: "INF03VN01282"
  },
  47: {
    amc_id: 47,
    fund_name: "NJ BALANCED ADVANTAGE FUND REGULAR PLAN - IDCW PAYOUT",
    isin: "INF0J8L01016"
  },
  48: {
    amc_id: 48,
    fund_name: "SAMCO FLEXI CAP FUND - REGULAR PLAN - GROWTH",
    isin: "INF0K1H01016"
  },
  49: {
    amc_id: 49,
    fund_name: "BAJAJ FINSERV LIQUID FUND - REGULAR PLAN GROWTH",
    isin: "INF0QA701094"
  },
  50: {
    amc_id: 50,
    fund_name: "HELIOS OVERNIGHT MUTUAL FUND-REGULAR DAILY PLAN-IDCW REINVESTMENT",
    isin: "INF0R8701103"
  }
}, Ne = {
  1: {
    amc_id: 1,
    fund_name: "AXIS OVERNIGHT FUND DIRECT PLAN - GROWTH",
    isin: "INF846K01N65"
  },
  2: {
    amc_id: 2,
    fund_name: "BARODA BNP PARIBAS EQUITY SAVINGS FUND - DIRECT PLAN IDCW PAYOUT",
    isin: "INF955L01IM1"
  },
  3: {
    amc_id: 3,
    fund_name: "ADITYA BIRLA SUNLIFE LIFE PHARMA AND HEALTHCARE FUND DIRECT IDCW PAYOUT",
    isin: "INF209KB1O33"
  },
  4: {
    amc_id: 4,
    fund_name: "BARODA BNP PARIBAS FUNDS AQUA FUND OF FUND DIRECT IDCW PAYOUT",
    isin: "INF251K01QU3"
  },
  5: {
    amc_id: 5,
    fund_name: "BANK OF INDIA FLEXI CAP FUND DIRECT PLAN - IDCW PAYOUT",
    isin: "INF761K01FE8"
  },
  6: {
    amc_id: 6,
    fund_name: "CANARA ROBECO FOCUSED EQUITY FUND DIRECT IDCW PAYOUT",
    isin: "INF760K01JS2"
  },
  7: {
    amc_id: 7,
    fund_name: "PGIM INDIA BALANCED ADVANTAGE FUND - DIRECT PLAN - IDCW PAYOUT",
    isin: "INF663L01V56"
  },
  8: {
    amc_id: 8,
    fund_name: "DSP Small Cap Fund - Direct Plan - Growth",
    isin: "INF740K01QD1"
  },
  9: {
    amc_id: 9,
    fund_name: "EDELWEISS BALANCED ADVANTAGE FUND - DIRECT PLAN QUARTERLY IDCW PAYOUT",
    isin: "INF754K01BR4"
  },
  11: {
    amc_id: 11,
    fund_name: "FRANKLIN INDIA OVERNIGHT FUND - DIRECT DAILY IDCW REINVESTMENT",
    isin: "INF090I01ST7"
  },
  13: {
    amc_id: 13,
    fund_name: "HDFC BANKING AND PSU DEBT FUND - DIRECT PLAN- IDCW PAYOUT OPTION",
    isin: "INF179KA1JA8"
  },
  14: {
    amc_id: 14,
    fund_name: "HSBC LARGE & MID CAP FUND-DIRECT PLAN IDCW PAYOUT",
    isin: "INF336L01NW3"
  },
  15: {
    amc_id: 15,
    fund_name: "ICICI PRUDENTIAL LONG TERM EQUITY FUND (TAX SAVING) - DIRECT PLAN - IDCW PAYOUT",
    isin: "INF109K01Y23"
  },
  16: {
    amc_id: 16,
    fund_name: "LIC HEALTHCARE FUND - DIRECT PLAN - IDCW - PAYOUT",
    isin: "INF397L01LH0"
  },
  17: {
    amc_id: 17,
    fund_name: "BANDHAN HYBRID EQUITY FUND DIRECT PLAN-IDCW PAYOUT",
    isin: "INF194KA1U80"
  },
  18: {
    amc_id: 18,
    fund_name: "GROWW OVERNIGHT FUND DIRECT PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF666M01GM6"
  },
  20: {
    amc_id: 20,
    fund_name: "JM OVERNIGHT FUND - DIRECT - DAILY IDCW REINVESTMENT OPTION",
    isin: "INF192K01LY2"
  },
  21: {
    amc_id: 21,
    fund_name: "KOTAK FLOATING RATE FUND - DIRECT PLAN - GROWTH",
    isin: "INF174KA1EC0"
  },
  23: {
    amc_id: 23,
    fund_name: "LIC MF BALANCED ADVANTAGE FUND DIRECT PLAN-IDCW PAYOUT",
    isin: "INF767K01QZ7"
  },
  24: {
    amc_id: 24,
    fund_name: "MIRAE ASSET FOCUSED FUND - DIRECT PLAN - GROWTH",
    isin: "INF769K01EU0"
  },
  25: {
    amc_id: 25,
    fund_name: "MOTILAL OSWAL ULTRA SHORT TERM FUND - DIRECT-DAILY IDCW REINVESTMENT OPTION",
    isin: "INF247L01346"
  },
  27: {
    amc_id: 27,
    fund_name: "PARAG PARIKH TAX SAVER FUND - DIRECT PLAN-GROWTH",
    isin: "INF879O01100"
  },
  28: {
    amc_id: 28,
    fund_name: "SUNDARAM ARBITRAGE FUND  - DIRECT PLAN MONTHLY IDCW PAYOUT",
    isin: "INF173K01NC4"
  },
  29: {
    amc_id: 29,
    fund_name: "QUANTUM INDIA ESG EQUITY FUND DIRECT PLAN - GROWTH",
    isin: "INF082J01382"
  },
  30: {
    amc_id: 30,
    fund_name: "NIPPON INDIA VISION FUND DIRECT PLAN GROWTH PLAN GROWTH",
    isin: "INF204KB1Y16"
  },
  31: {
    amc_id: 31,
    fund_name: "INVESCO INDIA EQUITY SAVINGS FUND - DIRECT GROWTH",
    isin: "INF205KA1049"
  },
  33: {
    amc_id: 33,
    fund_name: "SBI EQUITY MINIMUM VARIANCE FUND - DIRECT PLAN - IDCW PAYOUT",
    isin: "INF200KA1A97"
  },
  34: {
    amc_id: 34,
    fund_name: "SHRIRAM LONG TERM EQUITY FUND - DIRECT PLAN - GROWTH",
    isin: "INF680P01158"
  },
  35: {
    amc_id: 35,
    fund_name: "SUNDARAM OVERNIGHT FUND DIRECT DAILY IDCW REINVESTMENT",
    isin: "INF903JA1HJ9"
  },
  36: {
    amc_id: 36,
    fund_name: "TATA OVERNIGHT FUND - DIRECT PLAN - DAILY IDCW REINVESTMENT",
    isin: "INF277K019T3"
  },
  37: {
    amc_id: 37,
    fund_name: "TAURUS LARGECAP EQUITY FUND - DIRECT PLAN IDCW PAYOUT",
    isin: "INF044D01BY1"
  },
  38: {
    amc_id: 38,
    fund_name: "Union Arbitrage Fund Direct Plan - Growth",
    isin: "INF582M01ES7"
  },
  39: {
    amc_id: 39,
    fund_name: "UTI BANKING AND PSU DEBT FUND-DIRECT MONTHLY DIVIDEND PLAN-IDCW PAYOUT",
    isin: "INF789FC1HC5"
  },
  40: {
    amc_id: 40,
    fund_name: "MAHINDRA MANULIFE AGGRESSIVE HYBRID FUND - DIRECT - IDCW PAYOUT",
    isin: "INF174V01796"
  },
  41: {
    amc_id: 41,
    fund_name: "NAVI NIFTY 50 INDEX FUND DIRECT PLAN GROWTH",
    isin: "INF959L01FP2"
  },
  42: {
    amc_id: 42,
    fund_name: "360 ONE DYNAMIC BOND FUND DIRECT PLAN IDCW PAYOUT- QUARTERLY",
    isin: "INF579M01290"
  },
  43: {
    amc_id: 43,
    fund_name: "ITI ARBITRAGE FUND DIRECT PLAN IDCW PAYOUT",
    isin: "INF00XX01457"
  },
  44: {
    amc_id: 44,
    fund_name: "QUANT ABSOLUTE FUND DIRECT PLAN - IDCW PAYOUT",
    isin: "INF966L01531"
  },
  45: {
    amc_id: 45,
    fund_name: "TRUSTMF BANKING AND PSU DEBT FUND - DIRECT PLAN - ANNUALLY IDCW PAYOUT",
    isin: "INF0GCD01180"
  },
  46: {
    amc_id: 46,
    fund_name: "WOC Ultra Short Term Fund Direct - Growth",
    isin: "INF03VN01209"
  },
  47: {
    amc_id: 47,
    fund_name: "NJ BALANCED ADVANTAGE FUND DIRECT PLAN - IDCW PAYOUT",
    isin: "INF0J8L01032"
  },
  48: {
    amc_id: 48,
    fund_name: "SAMCO FLEXI CAP FUND - DIRECT PLAN - GROWTH",
    isin: "INF0K1H01024"
  },
  49: {
    amc_id: 49,
    fund_name: "Bajaj FLEXI CAP FUND - DIRECT PLAN - GROWTH",
    isin: "CYB0K1H01024"
  },
  50: {
    amc_id: 50,
    fund_name: "HELIOS OVERNIGHT MUTUAL FUND-DIRECT PLAN-GROWTH",
    isin: "INF0R8701079"
  },
  51: {
    amc_id: 51,
    fund_name: "ZERODHA ELSS TAX SAVER NIFTY LARGEMIDCAP 250 INDEX FUND DIRECT PLAN GROWTH",
    isin: "INF0R8F01026"
  }
};
function na() {
  return "***P*3753*".split("").map((x) => x === "*" ? String.fromCharCode(Math.random() * 25 + 65) : x).join("");
}
const ja = async ({ fpapi: m, pan: x }) => {
  var C, f;
  try {
    return {
      status: "SUCCESS",
      data: await m.fpClient.kyc_checks().create({
        pan: x.toUpperCase()
      })
    };
  } catch (u) {
    return window.debug.error(u), {
      status: "FAILED",
      reason: (f = (C = u == null ? void 0 : u.data) == null ? void 0 : C.error) == null ? void 0 : f.message,
      error: u
    };
  }
}, ba = async ({ fpapi: m, pan: x }) => {
  var C, f;
  try {
    const [u, _] = await Promise.all([
      Je("/images/sample.png", {
        fileName: "sample_image"
      }),
      Je("/videos/sample.mp4", {
        fileName: "sample_video"
      })
    ]), [s, h] = await Promise.all([
      qe({
        fpapi: m,
        pan: x,
        file: u,
        filePurpose: "test_tms_service"
      }),
      qe({
        fpapi: m,
        pan: x,
        file: _,
        filePurpose: "test_tms_service"
      })
    ]);
    return {
      status: "SUCCESS",
      data: {
        imageRes: s,
        videoRes: h
      }
    };
  } catch (u) {
    return window.debug.error(u), {
      status: "FAILED",
      reason: (f = (C = u == null ? void 0 : u.data) == null ? void 0 : C.error) == null ? void 0 : f.message,
      error: u
    };
  }
}, Fa = async ({ fpapi: m }) => {
  var x, C;
  try {
    const u = {
      type: "individual",
      tax_status: "resident_individual",
      pan: na().toUpperCase(),
      name: "Rani Gupta"
    }, _ = await m.fpClient.investor_profiles().create(u), s = {
      profile: _.id,
      primary_account_holder_name: _.name,
      account_number: "123451199",
      type: "savings",
      ifsc_code: "ICIC0000001"
    }, h = {
      profile: _.id,
      isd: "91",
      number: "8160597103",
      belongs_to: "self"
    }, r = {
      profile: _.id,
      email: "rani@gmail.com",
      belongs_to: "self"
    }, c = [
      m.fpClient.bank_accounts().create(s),
      m.fpClient.phone_numbers().create(h),
      m.fpClient.email_addresses().create(r)
    ], [n] = await Promise.all(c);
    return {
      status: "SUCCESS",
      data: await m.fpClient.bank_account_verifications().create({
        bank_account: n == null ? void 0 : n.id
      })
    };
  } catch (f) {
    return window.debug.error(f), {
      status: "FAILED",
      reason: (C = (x = f == null ? void 0 : f.data) == null ? void 0 : x.error) == null ? void 0 : C.message,
      error: f
    };
  }
}, ka = async ({ fpapi: m, pan: x }) => {
  var C, f;
  try {
    const u = {
      type: "individual",
      tax_status: "resident_individual",
      name: "Rani Gupta",
      date_of_birth: "1980-10-19",
      gender: "female",
      occupation: "business",
      pan: x.toUpperCase(),
      country_of_birth: "IN",
      place_of_birth: "IN",
      use_default_tax_residences: !0,
      source_of_wealth: "business",
      income_slab: "upto_1lakh",
      pep_details: "not_applicable",
      ip_address: "192.92.12.39"
    }, _ = await m.fpClient.investor_profiles().create(u), s = {
      profile: _.id,
      primary_account_holder_name: _.name,
      account_number: "123451199",
      type: "savings",
      ifsc_code: "ICIC0000001"
    }, h = {
      profile: _.id,
      line1: "E/&02, Opp SBI ATM, 12th Main Road South, Chennai",
      country: "IN",
      postal_code: "600001"
    }, r = {
      profile: _.id,
      isd: "91",
      number: "8160597103",
      belongs_to: "self"
    }, c = {
      profile: _.id,
      email: "rani@gmail.com",
      belongs_to: "self"
    }, n = {
      profile: _.id,
      name: "Rajesh Gupta",
      date_of_birth: "1972-02-29",
      relationship: "father"
    }, g = [
      m.fpClient.bank_accounts().create(s),
      m.fpClient.addresses().create(h),
      m.fpClient.phone_numbers().create(r),
      m.fpClient.email_addresses().create(c),
      m.fpClient.related_parties().create(n)
    ], [F, D, p, A, i] = await Promise.all(g), a = {
      primary_investor: _.id,
      holding_pattern: "single",
      folio_defaults: {
        communication_email_address: A.id,
        communication_mobile_number: p.id,
        communication_address: D.id,
        payout_bank_account: F.id,
        nominee1: i.id,
        nominee1_allocation_percentage: 100
      }
    };
    return {
      status: "SUCCESS",
      data: {
        mfia: await m.fpClient.mf_investment_accounts().create(a),
        bankAccount: F
      }
    };
  } catch (u) {
    return window.debug.error(u), {
      status: "FAILED",
      reason: (f = (C = u == null ? void 0 : u.data) == null ? void 0 : C.error) == null ? void 0 : f.message,
      error: u
    };
  }
}, Da = async ({ fpapi: m, pan: x, imageId: C, videoId: f }) => {
  var u, _;
  try {
    const s = {
      aadhaar_number: "1210",
      pan: x.toUpperCase(),
      name: "Rani Gupta",
      father_name: "Rajesh Gupta",
      spouse_name: null,
      mother_name: "Neha Gupta",
      email: "rani@gmail.com",
      mobile: {
        isd: "+91",
        number: "8160597103"
      },
      signature: C,
      photo: C,
      gender: "female",
      date_of_birth: "1980-10-19",
      marital_status: "unmarried",
      country_of_birth: "in",
      address: {
        city: "Chennai",
        proof: C,
        line_1: "E/&02, Opp SBI ATM, 12th Main Road South",
        country: "in",
        pincode: "600001",
        proof_back: C,
        proof_type: "passport",
        proof_number: "A2096457",
        proof_issue_date: "2004-10-20",
        proof_expiry_date: "2030-10-20"
      },
      identity_proof: C,
      residential_status: "resident_individual",
      occupation_type: "business",
      geolocation: {
        latitude: 12.354,
        longitude: 77.453
      },
      bank_account: {
        account_holder_name: "Rani Gupta",
        account_number: "123451199",
        ifsc_code: "ICIC0000001",
        proof: C
      },
      ipv_video: f
    };
    return {
      status: "SUCCESS",
      data: await m.fpClient.kyc_requests().create(s)
    };
  } catch (s) {
    return window.debug.error(s), {
      status: "FAILED",
      reason: (_ = (u = s == null ? void 0 : s.data) == null ? void 0 : u.error) == null ? void 0 : _.message,
      error: s
    };
  }
}, Sa = async ({ fpapi: m, mfiaObj: x, scheme: C }) => {
  var f, u;
  try {
    const _ = x.id, s = await m.fpClient.mf_purchases().create({
      mf_investment_account: _,
      scheme: C,
      amount: 15e3
    });
    return await m.fpClient.mf_purchases().update({
      id: s == null ? void 0 : s.id,
      // state: "confirmed",
      consent: {
        email: "rani@gmail.com",
        isd_code: "91",
        mobile: "8160597103"
      }
    }), {
      status: "SUCCESS",
      data: s
    };
  } catch (_) {
    return window.debug.error(_), {
      status: "FAILED",
      reason: (u = (f = _ == null ? void 0 : _.data) == null ? void 0 : f.error) == null ? void 0 : u.message,
      error: _
    };
  }
}, La = async ({ fpapi: m, mf_purchase: x, oldBankAccountId: C }) => {
  var f, u;
  try {
    return {
      status: "SUCCESS",
      data: await m.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          x == null ? void 0 : x.old_id
        ],
        method: "NETBANKING",
        bank_account_id: C,
        payment_postback_url: "https://fintechprimitives.com/",
        provider_name: "RAZORPAY"
      })
    };
  } catch (_) {
    return window.debug.error(_), {
      status: "FAILED",
      reason: (u = (f = _ == null ? void 0 : _.data) == null ? void 0 : f.error) == null ? void 0 : u.message,
      error: _
    };
  }
};
async function Ua(m, x) {
  var g, F, D, p, A, i, a;
  const f = Object.keys(x).filter((t) => {
    var l;
    return ((l = x == null ? void 0 : x[t]) == null ? void 0 : l.status) === "CONFIGURED";
  }), u = {}, _ = na();
  if (f.includes("kyc_check")) {
    const t = await ja({
      fpapi: m,
      pan: _
    });
    if (t.status === "FAILED") {
      const { status: l, reason: o, error: I } = t;
      u.kyc_check = {
        status: l,
        reason: o,
        error: I
      };
    } else {
      const { status: l, data: o } = t;
      u.kyc_check = {
        status: l,
        data: o
      };
    }
  }
  let s, h;
  if (f.includes("files")) {
    const t = await ba({
      fpapi: m,
      pan: _
    });
    if (t.status === "FAILED") {
      const { status: l, reason: o, error: I } = t;
      u.files = {
        status: l,
        reason: o,
        error: I
      };
    } else {
      const { status: l, data: o } = t;
      s = o == null ? void 0 : o.imageRes.id, h = o == null ? void 0 : o.videoRes.id, u.files = {
        status: l,
        data: o
      };
    }
  }
  if (f.includes("verification")) {
    const t = await Fa({
      fpapi: m
    });
    if (t.status === "FAILED") {
      const { status: l, reason: o, error: I } = t;
      u.verification = {
        status: l,
        reason: o,
        error: I
      };
    } else {
      const { status: l, data: o } = t;
      u.verification = {
        status: l,
        data: o
      };
    }
  }
  let r, c;
  if (f.includes("onboarding")) {
    const t = await ka({
      fpapi: m,
      pan: _
    });
    if (t.status === "FAILED") {
      const { status: l, reason: o, error: I } = t;
      u.onboarding = {
        status: l,
        reason: o,
        error: I
      };
    } else {
      const { status: l, data: o } = t;
      r = o == null ? void 0 : o.mfia, c = o == null ? void 0 : o.bankAccount, u.onboarding = {
        status: l,
        data: o
      };
    }
  }
  if (f.includes("kyc_verification"))
    if (!s || !h)
      u.kyc_verification = {
        status: "FAILED",
        reason: "Proof file/s absent",
        error: null
      };
    else {
      const t = await Da({
        fpapi: m,
        pan: _,
        imageId: s,
        videoId: h
      });
      if (t.status === "FAILED") {
        const { status: l, reason: o, error: I } = t;
        u.kyc_verification = {
          status: l,
          reason: o,
          error: I
        };
      } else {
        const { status: l, data: o } = t;
        u.kyc_verification = {
          status: l,
          data: o
        };
      }
    }
  let n;
  if (f.includes("oms"))
    if (r) {
      const t = String((D = (F = (g = x.oms) == null ? void 0 : g.amcs) == null ? void 0 : F.data) == null ? void 0 : D.data[0].id), o = !!((A = (p = x == null ? void 0 : x.oms) == null ? void 0 : p.data) != null && A.ria_code) ? (i = Ne == null ? void 0 : Ne[t]) == null ? void 0 : i.isin : (a = xe == null ? void 0 : xe[t]) == null ? void 0 : a.isin, I = await Sa({
        fpapi: m,
        mfiaObj: r,
        scheme: o
      });
      if (I.status === "FAILED") {
        const { status: b, reason: L, error: Y } = I;
        u.oms = {
          status: b,
          reason: L,
          error: Y
        }, u.backpressure = {
          status: b,
          reason: L,
          error: Y
        }, u.account_mf = {
          status: b,
          reason: L,
          error: Y
        };
      } else {
        const { status: b, data: L } = I;
        n = L, u.oms = {
          status: b,
          data: L
        }, u.backpressure = {
          status: b
        }, u.account_mf = {
          status: b
        };
      }
    } else {
      const t = "FAILED", l = "MFIA object not present";
      u.oms = {
        status: t,
        reason: l,
        error: null
      }, u.backpressure = {
        status: t,
        reason: l,
        error: null
      }, u.account_mf = {
        status: t,
        reason: l,
        error: null
      };
    }
  if (f.includes("pg"))
    if (!c || !n)
      u.pg = {
        status: "FAILED",
        reason: "One of MF purchase, bank account object not present",
        error: null
      };
    else {
      const t = await La({
        fpapi: m,
        mfiaId: r == null ? void 0 : r.id,
        mfiaOldId: Number(r == null ? void 0 : r.old_id) || void 0,
        oldBankAccountId: c == null ? void 0 : c.old_id,
        mf_purchase: n
      });
      if (t.status === "FAILED") {
        const { status: l, reason: o, error: I } = t;
        u.pg = {
          status: l,
          reason: o,
          error: I
        };
      } else {
        const { status: l, data: o } = t;
        u.pg = {
          status: l,
          data: o
        };
      }
    }
  return u;
}
function Oa() {
  const { fpapi: m } = $(te), [x, C] = O(!1), [f, u] = O(null), [_, s] = O(null), [h, r] = O(null);
  sa(() => {
    (async () => {
      try {
        C(!0);
        const a = await m.fpClient.tenant_management().fetch();
        r(a);
      } catch (a) {
        window.debug.error(a), ee(a);
      } finally {
        C(!1);
      }
    })();
  }, []);
  const c = (a) => {
    var V, K, B, k, M, T;
    const t = [
      "kyc_check",
      "kyc_verification",
      "files"
    ], l = t.filter((R) => {
      var w;
      return ((w = h == null ? void 0 : h[R]) == null ? void 0 : w.status) === "CONFIGURED";
    });
    if (t.some((R) => {
      var w;
      return ((w = h == null ? void 0 : h[R]) == null ? void 0 : w.status) === "FAILED";
    }))
      return {
        status: "SKIPPED",
        icon: void 0,
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      };
    if ([
      "kyc_check",
      "kyc_verification"
    ].every((R) => {
      var w;
      return ((w = h == null ? void 0 : h[R]) == null ? void 0 : w.status) === "NOT_CONFIGURED";
    }))
      return {
        status: "SKIPPED",
        icon: void 0,
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      };
    const b = l.length === 1 && l[0] === "kyc_check", L = l.length === 2 && l.includes("kyc_verification") && l.includes("files"), Y = l.length === 3;
    if (b)
      return ((V = a == null ? void 0 : a.kyc_check) == null ? void 0 : V.status) === "SUCCESS" ? {
        status: "SUCCESS",
        icon: "success",
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      } : {
        status: "FAILED",
        icon: "error",
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      };
    if (L) {
      const R = (K = a == null ? void 0 : a.kyc_verification) == null ? void 0 : K.status, w = (B = a == null ? void 0 : a.files) == null ? void 0 : B.status;
      return [
        R,
        w
      ].every((J) => J === "SUCCESS") ? {
        status: "SUCCESS",
        icon: "success",
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      } : {
        status: "FAILED",
        icon: "error",
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      };
    }
    if (Y) {
      const R = (k = a == null ? void 0 : a.kyc_check) == null ? void 0 : k.status, w = (M = a == null ? void 0 : a.kyc_verification) == null ? void 0 : M.status, Z = (T = a == null ? void 0 : a.files) == null ? void 0 : T.status;
      return [
        R,
        w,
        Z
      ].every((ae) => ae === "SUCCESS") ? {
        status: "SUCCESS",
        icon: "success",
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      } : {
        status: "FAILED",
        icon: "error",
        services: {
          kyc_check: a == null ? void 0 : a.kyc_check,
          kyc_verification: a == null ? void 0 : a.kyc_verification,
          files: a == null ? void 0 : a.files
        }
      };
    }
    return {
      status: "FAILED",
      icon: void 0,
      services: {
        kyc_check: a == null ? void 0 : a.kyc_check,
        kyc_verification: a == null ? void 0 : a.kyc_verification,
        files: a == null ? void 0 : a.files
      }
    };
  }, n = (a) => {
    var l, o, I;
    return ((l = h == null ? void 0 : h.verification) == null ? void 0 : l.status) === "FAILED" ? {
      status: "SKIPPED",
      icon: void 0,
      services: {
        verification: a == null ? void 0 : a.verification
      }
    } : ((o = h == null ? void 0 : h.verification) == null ? void 0 : o.status) === "NOT_CONFIGURED" ? {
      status: "SKIPPED",
      icon: void 0,
      services: {
        verification: a == null ? void 0 : a.verification
      }
    } : ((I = a == null ? void 0 : a.verification) == null ? void 0 : I.status) === "SUCCESS" ? {
      status: "SUCCESS",
      icon: "success",
      services: {
        verification: a == null ? void 0 : a.verification
      }
    } : {
      status: "FAILED",
      icon: "error",
      services: {
        verification: a == null ? void 0 : a.verification
      }
    };
  }, g = (a) => {
    var L, Y, V, K, B;
    const t = [
      "oms",
      "account_mf",
      "onboarding",
      "backpressure"
    ];
    return [
      ...t,
      "files"
    ].some((k) => {
      var M;
      return ((M = h == null ? void 0 : h[k]) == null ? void 0 : M.status) === "FAILED";
    }) ? {
      status: "SKIPPED",
      icon: void 0,
      services: {
        oms: a == null ? void 0 : a.oms,
        account_mf: a == null ? void 0 : a.account_mf,
        onboarding: a == null ? void 0 : a.onboarding,
        backpressure: a == null ? void 0 : a.backpressure
      }
    } : t.every((k) => {
      var M;
      return ((M = h == null ? void 0 : h[k]) == null ? void 0 : M.status) === "NOT_CONFIGURED";
    }) ? {
      status: "SKIPPED",
      icon: void 0,
      services: {
        oms: a == null ? void 0 : a.oms,
        account_mf: a == null ? void 0 : a.account_mf,
        onboarding: a == null ? void 0 : a.onboarding,
        backpressure: a == null ? void 0 : a.backpressure
      }
    } : [
      (L = a == null ? void 0 : a.oms) == null ? void 0 : L.status,
      (Y = a == null ? void 0 : a.account_mf) == null ? void 0 : Y.status,
      (V = a == null ? void 0 : a.onboarding) == null ? void 0 : V.status,
      (K = a == null ? void 0 : a.backpressure) == null ? void 0 : K.status,
      (B = a == null ? void 0 : a.files) == null ? void 0 : B.status
    ].every((k) => k === "SUCCESS") ? {
      status: "SUCCESS",
      icon: "success",
      services: {
        oms: a == null ? void 0 : a.oms,
        account_mf: a == null ? void 0 : a.account_mf,
        onboarding: a == null ? void 0 : a.onboarding,
        backpressure: a == null ? void 0 : a.backpressure
      }
    } : {
      status: "FAILED",
      icon: "error",
      services: {
        oms: a == null ? void 0 : a.oms,
        account_mf: a == null ? void 0 : a.account_mf,
        onboarding: a == null ? void 0 : a.onboarding,
        backpressure: a == null ? void 0 : a.backpressure
      }
    };
  }, F = (a) => {
    var l, o, I;
    return ((l = h == null ? void 0 : h.pg) == null ? void 0 : l.status) === "FAILED" ? {
      status: "SKIPPED",
      icon: void 0,
      services: {
        pg: a == null ? void 0 : a.pg
      }
    } : ((o = h == null ? void 0 : h.pg) == null ? void 0 : o.status) === "NOT_CONFIGURED" ? {
      status: "SKIPPED",
      icon: void 0,
      services: {
        pg: a == null ? void 0 : a.pg
      }
    } : ((I = a == null ? void 0 : a.pg) == null ? void 0 : I.status) === "SUCCESS" ? {
      status: "SUCCESS",
      icon: "success",
      services: {
        pg: a == null ? void 0 : a.pg
      }
    } : {
      status: "FAILED",
      icon: "error",
      services: {
        pg: a == null ? void 0 : a.pg
      }
    };
  }, D = async () => {
    try {
      if (C(!0), h) {
        const a = await Ua(m, h);
        s({
          KYC: c(a),
          VERIFICATION: n(a),
          ORDER: g(a),
          PG: F(a)
        });
      } else
        u(d._({
          id: "z7XXKW"
        }));
    } catch (a) {
      u(d._({
        id: "1Y0lw6"
      })), ee(a), window.debug.error(a);
    } finally {
      C(!1);
    }
  }, p = () => {
    D();
  }, A = () => {
    var o, I, b, L, Y, V, K, B, k, M, T, R, w, Z, J, ae, le, se, q, oe, re, ge, Ae, ve, Ee, ye, je, be, Fe, ke, De, Se, Le, Ue, Oe, pe, Ge, Ye, Me, we, Ve, Ke, Be, Pe, Re, We, He, ze, Te, Qe;
    const a = _;
    if (!(((o = a == null ? void 0 : a.KYC) == null ? void 0 : o.status) === "FAILED" || ((I = a == null ? void 0 : a.ORDER) == null ? void 0 : I.status) === "FAILED" || ((b = a == null ? void 0 : a.VERIFICATION) == null ? void 0 : b.status) === "FAILED" || ((L = a == null ? void 0 : a.PG) == null ? void 0 : L.status) === "FAILED") || !a)
      return /* @__PURE__ */ e.jsx(e.Fragment, {});
    const l = [];
    if (((Y = a == null ? void 0 : a.KYC) == null ? void 0 : Y.status) === "FAILED") {
      const z = [];
      ((B = (K = (V = a == null ? void 0 : a.KYC) == null ? void 0 : V.services) == null ? void 0 : K.kyc_check) == null ? void 0 : B.status) === "FAILED" && z.push(((T = (M = (k = a == null ? void 0 : a.KYC) == null ? void 0 : k.services) == null ? void 0 : M.kyc_check) == null ? void 0 : T.reason) ?? ""), ((Z = (w = (R = a == null ? void 0 : a.KYC) == null ? void 0 : R.services) == null ? void 0 : w.kyc_verification) == null ? void 0 : Z.status) === "FAILED" && z.push(((le = (ae = (J = a == null ? void 0 : a.KYC) == null ? void 0 : J.services) == null ? void 0 : ae.kyc_verification) == null ? void 0 : le.reason) ?? ""), ((oe = (q = (se = a == null ? void 0 : a.KYC) == null ? void 0 : se.services) == null ? void 0 : q.files) == null ? void 0 : oe.status) === "FAILED" && z.push(((Ae = (ge = (re = a == null ? void 0 : a.KYC) == null ? void 0 : re.services) == null ? void 0 : ge.files) == null ? void 0 : Ae.reason) ?? ""), l.push({
        label: "KYC services",
        value: z,
        colSpan: "basis-full"
      });
    }
    if (((ve = a == null ? void 0 : a.VERIFICATION) == null ? void 0 : ve.status) === "FAILED") {
      const z = [];
      ((je = (ye = (Ee = a == null ? void 0 : a.VERIFICATION) == null ? void 0 : Ee.services) == null ? void 0 : ye.verification) == null ? void 0 : je.status) === "FAILED" && z.push(((ke = (Fe = (be = a == null ? void 0 : a.VERIFICATION) == null ? void 0 : be.services) == null ? void 0 : Fe.verification) == null ? void 0 : ke.reason) ?? ""), l.push({
        label: "Verification services",
        value: z,
        colSpan: "basis-full"
      });
    }
    if (((De = a == null ? void 0 : a.ORDER) == null ? void 0 : De.status) === "FAILED") {
      const z = [];
      ((Ue = (Le = (Se = a == null ? void 0 : a.ORDER) == null ? void 0 : Se.services) == null ? void 0 : Le.oms) == null ? void 0 : Ue.status) === "FAILED" && z.push(((Ge = (pe = (Oe = a == null ? void 0 : a.ORDER) == null ? void 0 : Oe.services) == null ? void 0 : pe.oms) == null ? void 0 : Ge.reason) ?? ""), ((we = (Me = (Ye = a == null ? void 0 : a.ORDER) == null ? void 0 : Ye.services) == null ? void 0 : Me.files) == null ? void 0 : we.status) === "FAILED" && z.push(((Be = (Ke = (Ve = a == null ? void 0 : a.ORDER) == null ? void 0 : Ve.services) == null ? void 0 : Ke.files) == null ? void 0 : Be.reason) ?? ""), l.push({
        label: "Order management services",
        value: z,
        colSpan: "basis-full"
      });
    }
    if (((Pe = a == null ? void 0 : a.PG) == null ? void 0 : Pe.status) === "FAILED") {
      const z = [];
      ((He = (We = (Re = a == null ? void 0 : a.PG) == null ? void 0 : Re.services) == null ? void 0 : We.pg) == null ? void 0 : He.status) === "FAILED" && z.push(((Qe = (Te = (ze = a == null ? void 0 : a.PG) == null ? void 0 : ze.services) == null ? void 0 : Te.pg) == null ? void 0 : Qe.reason) ?? ""), l.push({
        label: "Payment gatway services",
        value: z,
        colSpan: "basis-full"
      });
    }
    return /* @__PURE__ */ e.jsx(ia, {
      view: "error",
      tableData: l,
      customClass: "mt-2"
    });
  };
  return x ? /* @__PURE__ */ e.jsx(X, {
    variant: "fullpage"
  }) : (() => {
    var a, t, l, o;
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ga, {
          title: "FP account updated successfully",
          iconName: "successInfo"
        }),
        /* @__PURE__ */ e.jsx(me, {
          variant: "plus",
          title: d._({
            id: "3hZhOx"
          }),
          subtitle: d._({
            id: "QByFlv"
          }),
          status: (a = _ == null ? void 0 : _.KYC) == null ? void 0 : a.icon
        }),
        /* @__PURE__ */ e.jsx(me, {
          variant: "plus",
          title: d._({
            id: "wzhOnZ"
          }),
          subtitle: d._({
            id: "cAFMZR"
          }),
          status: (t = _ == null ? void 0 : _.VERIFICATION) == null ? void 0 : t.icon
        }),
        /* @__PURE__ */ e.jsx(me, {
          variant: "plus",
          title: d._({
            id: "myv7nR"
          }),
          subtitle: d._({
            id: "PAzkZ0"
          }),
          status: (l = _ == null ? void 0 : _.ORDER) == null ? void 0 : l.icon
        }),
        /* @__PURE__ */ e.jsx(me, {
          variant: "plus",
          title: d._({
            id: "ckzWyQ"
          }),
          subtitle: d._({
            id: "ZKlmVl"
          }),
          status: (o = _ == null ? void 0 : _.PG) == null ? void 0 : o.icon
        }),
        A(),
        f ? /* @__PURE__ */ e.jsx(N, {
          type: "error",
          variant: "field",
          title: f,
          customClass: "mb-5"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
        /* @__PURE__ */ e.jsx(W, {
          fullwidth: !0,
          customClass: "mb-2 mt-4",
          onClick: p,
          children: d._({
            id: "01kNvz"
          })
        })
      ]
    });
  })();
}
const pa = ({ kraList: m, error: x, handleAddKraDetails: C, handleContinue: f, handleSkip: u }) => {
  const _ = {
    KYC_CHECK: "KYC check",
    INVESTOR_PROFILE: "Investor profile",
    VIDEO_KYC: "Video KYC"
  }, s = !m.length;
  return /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "flex justify-between items-start",
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            children: [
              /* @__PURE__ */ e.jsx(P, {
                title: d._({
                  id: "f2rxBB"
                })
              }),
              /* @__PURE__ */ e.jsx(Q, {
                type: "text",
                text: /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    "Mutuals funds are regulated by SEBI and it mandates all the financial intermediaries to ensure verification of identity of an investor before accepting any investments.",
                    " ",
                    /* @__PURE__ */ e.jsx("a", {
                      href: "https://docs.fintechprimitives.com/identity/overview/",
                      className: "pl-1 text-size-14",
                      target: "_blank",
                      rel: "noreferrer",
                      children: "Learn more"
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(W, {
            variant: "text",
            onClick: u,
            children: d._({
              id: "PwQ/Gn"
            })
          })
        ]
      }),
      m.length ? /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          /* @__PURE__ */ e.jsx("div", {
            style: {
              marginBottom: "1rem"
            },
            children: /* @__PURE__ */ e.jsx(P, {
              title: d._({
                id: "elyqNy"
              }),
              semiBold: !0
            })
          }),
          m.map((h) => /* @__PURE__ */ e.jsxs(fe, {
            header: {
              title: /* @__PURE__ */ e.jsx("span", {
                className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
                children: d._({
                  id: "VoDlpm",
                  values: {
                    0: h.kra.label
                  }
                })
              })
            },
            customClass: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(ne, {
                label: d._({
                  id: "PvxPPl"
                })
              }),
              /* @__PURE__ */ e.jsx(ce, {
                customClass: "mb-2",
                children: h.services.map((r) => /* @__PURE__ */ e.jsx(ra, {
                  variant: "pill",
                  message: _[r],
                  customClass: "mr-2"
                }, _[r]))
              }),
              /* @__PURE__ */ e.jsxs(ce, {
                children: [
                  h.username ? /* @__PURE__ */ e.jsx(de, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(ne, {
                      label: d._({
                        id: "xm76zg"
                      }),
                      value: h.username
                    })
                  }) : null,
                  h.password ? /* @__PURE__ */ e.jsx(de, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(ne, {
                      label: d._({
                        id: "rvnOw5"
                      }),
                      value: h.password
                    })
                  }) : null,
                  h.pos_code ? /* @__PURE__ */ e.jsx(de, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(ne, {
                      label: d._({
                        id: "AM0J9j"
                      }),
                      value: h.pos_code
                    })
                  }) : null
                ]
              })
            ]
          }, h.kra.value))
        ]
      }) : null,
      /* @__PURE__ */ e.jsxs($e, {
        children: [
          x && /* @__PURE__ */ e.jsx(N, {
            type: "error",
            variant: "field",
            title: x,
            customClass: "mb-1"
          }),
          /* @__PURE__ */ e.jsx(W, {
            variant: m.length ? "disabled" : "secondary",
            fullwidth: !0,
            onClick: C,
            customClass: "mb-4",
            children: d._({
              id: "RzeOF/"
            })
          }),
          /* @__PURE__ */ e.jsx(W, {
            variant: s ? "disabled" : "primary",
            fullwidth: !0,
            onClick: f,
            children: d._({
              id: "2iVSoY"
            })
          })
        ]
      })
    ]
  });
}, Ga = ({ handleGoBack: m, onComplete: x }) => {
  ue(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const C = [
    {
      label: "CAMS",
      value: "cams"
    },
    {
      label: "CVL",
      value: "cvl"
    },
    {
      label: "Cybrilla",
      value: "ipru"
    }
  ], f = ([s], h, { changeValue: r }) => {
    var n;
    const c = structuredClone((n = h.formState.values) == null ? void 0 : n.services);
    (c == null ? void 0 : c.indexOf(s)) > -1 && (c.splice(c.indexOf(s), 1), r(h, "services", () => c));
  }, u = ([s, h], r, { changeValue: c }) => {
    c(r, s, () => h);
  }, _ = (s) => (s == null ? void 0 : s.value) !== "ipru";
  return /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsx(P, {
        title: d._({
          id: "elyqNy"
        }),
        icon: "Back",
        onClick: m
      }),
      /* @__PURE__ */ e.jsx(Q, {
        type: "text",
        text: d._({
          id: "/qfgsW"
        })
      }),
      /* @__PURE__ */ e.jsx(ie, {
        onSubmit: x,
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0,
          touched: !0
        },
        mutators: {
          uncheckService: f,
          removeCreds: u
        },
        validate: (s) => {
          var r;
          const h = {};
          return (r = s == null ? void 0 : s.services) != null && r.length || (h.services = "This input is required"), h;
        },
        children: (s) => {
          var h, r, c;
          return /* @__PURE__ */ e.jsxs("form", {
            onSubmit: s.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "tSVjbB"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "kra",
                    validate: j,
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(Ie, {
                          name: n.input.name,
                          value: n.input.value,
                          onChange: (g) => {
                            n.input.onChange(g), s.form.mutators.removeCreds("username", null), s.form.mutators.removeCreds("password", null), s.form.mutators.removeCreds("pos_code", null);
                          },
                          options: C,
                          placeholder: d._({
                            id: "m5WZXC"
                          }),
                          status: n.meta.error && n.meta.touched ? "error" : void 0
                        }),
                        n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: n.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                "data-name": "services",
                children: [
                  /* @__PURE__ */ e.jsx(P, {
                    semiBold: !0,
                    title: d._({
                      id: "UZB0q+"
                    }),
                    customClass: "mb-6"
                  }),
                  /* @__PURE__ */ e.jsxs("section", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "NPi5Of"
                        }),
                        customClass: "pb-2"
                      }),
                      /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2",
                        children: /* @__PURE__ */ e.jsx(E, {
                          name: "services",
                          component: "input",
                          type: "checkbox",
                          value: "KYC_CHECK",
                          subscription: {
                            touched: !0,
                            error: !0,
                            value: !0
                          },
                          children: (n) => /* @__PURE__ */ e.jsx(he, {
                            id: "KYC_CHECK",
                            checked: n.input.checked,
                            onChange: (g) => {
                              var F;
                              n.input.onChange(g), !g.target.checked && ((F = s.values.services) != null && F.includes("INVESTOR_PROFILE")) && s.form.mutators.uncheckService("INVESTOR_PROFILE");
                            },
                            children: /* @__PURE__ */ e.jsx("p", {
                              className: "font-medium",
                              children: d._({
                                id: "sMT5/k"
                              })
                            })
                          })
                        })
                      }),
                      /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2",
                        children: /* @__PURE__ */ e.jsx(E, {
                          name: "services",
                          component: "input",
                          type: "checkbox",
                          value: "INVESTOR_PROFILE",
                          subscription: {
                            touched: !0,
                            error: !0,
                            value: !0
                          },
                          children: (n) => {
                            var g;
                            return /* @__PURE__ */ e.jsx(he, {
                              id: "INVESTOR_PROFILE",
                              checked: n.input.checked,
                              onChange: n.input.onChange,
                              disabled: !((g = s.values.services) != null && g.includes("KYC_CHECK")),
                              children: /* @__PURE__ */ e.jsx("p", {
                                className: "font-medium",
                                children: d._({
                                  id: "IHGaBc"
                                })
                              })
                            });
                          }
                        })
                      }),
                      /* @__PURE__ */ e.jsx(N, {
                        type: "helper",
                        variant: "field",
                        title: "Investor details collected at the time of KYC verification"
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("section", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "woJxdX"
                        }),
                        customClass: "pb-2"
                      }),
                      /* @__PURE__ */ e.jsx("div", {
                        className: "mb-4",
                        children: /* @__PURE__ */ e.jsx(E, {
                          name: "services",
                          component: "input",
                          type: "checkbox",
                          value: "VIDEO_KYC",
                          subscription: {
                            touched: !0,
                            error: !0,
                            value: !0
                          },
                          children: (n) => /* @__PURE__ */ e.jsx(he, {
                            id: "VIDEO_KYC",
                            checked: n.input.checked,
                            onChange: n.input.onChange,
                            children: /* @__PURE__ */ e.jsx("p", {
                              className: "font-medium",
                              children: d._({
                                id: "ysGgu5"
                              })
                            })
                          })
                        })
                      })
                    ]
                  }),
                  ((h = s.errors) == null ? void 0 : h.services) && ((r = s.touched) == null ? void 0 : r.services) && /* @__PURE__ */ e.jsx(N, {
                    type: "error",
                    variant: "field",
                    title: (c = s.errors) == null ? void 0 : c.services
                  })
                ]
              }),
              _(s.values.kra) ? /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(P, {
                    semiBold: !0,
                    title: d._({
                      id: "TAHxkc"
                    }),
                    customClass: "mb-6"
                  }),
                  /* @__PURE__ */ e.jsx(Q, {
                    type: "text",
                    text: d._({
                      id: "sUsNAe"
                    })
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-6",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: "Username"
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "username",
                        validate: S(j, U(4), G(50)),
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              id: n.input.name,
                              autoComplete: "off",
                              name: n.input.name,
                              value: n.input.value,
                              onChange: n.input.onChange,
                              onBlur: () => {
                                n.input.onChange(n.input.value.trim());
                              },
                              maxLength: 50,
                              status: n.meta.error && n.meta.touched ? "error" : void 0
                            }),
                            n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: n.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-6",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: "Password"
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "password",
                        validate: S(j, U(4), G(50)),
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              id: n.input.name,
                              autoComplete: "off",
                              name: n.input.name,
                              value: n.input.value,
                              onChange: n.input.onChange,
                              onBlur: () => {
                                n.input.onChange(n.input.value.trim());
                              },
                              maxLength: 50,
                              status: n.meta.error && n.meta.touched ? "error" : void 0
                            }),
                            n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: n.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-6",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: "POS code"
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "pos_code",
                        validate: S(j, U(4), G(120)),
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              id: n.input.name,
                              autoComplete: "off",
                              name: n.input.name,
                              value: n.input.value,
                              onChange: n.input.onChange,
                              onBlur: () => {
                                n.input.onChange(n.input.value.trim());
                              },
                              maxLength: 70,
                              status: n.meta.error && n.meta.touched ? "error" : void 0
                            }),
                            n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: n.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  })
                ]
              }) : null,
              /* @__PURE__ */ e.jsx(W, {
                type: "submit",
                fullwidth: !0,
                onClick: () => {
                  const n = Object.keys(s.errors ?? {});
                  n.length && aa(n[0]);
                },
                customClass: "mb-8",
                children: d._({
                  id: "AtXdm9"
                })
              })
            ]
          });
        }
      })
    ]
  });
}, Ya = ({ onComplete: m }) => {
  const { fpapi: x } = $(te), [C, f] = O("KRA_LISTING"), [u, _] = O([]), [s, h] = O(null), [r, c] = O(!1), n = (a) => {
    const t = a.services;
    if (!t.includes("KYC_CHECK"))
      return null;
    let l = {
      kra: a.kra.value,
      is_solicit: t.includes("INVESTOR_PROFILE")
    };
    return a.kra.value === "ipru" || (l = {
      ...l,
      username: a.username,
      password: a.password,
      pos_code: a.pos_code
    }), l;
  }, g = (a) => {
    if (!a.services.includes("VIDEO_KYC"))
      return null;
    const l = a.kra.value === "ipru";
    let o = {
      use_default_configuration: l
    };
    return l || (o = {
      ...o,
      username: a.username,
      password: a.password,
      kra: a.kra.value === "cvl" ? "CVL" : a.kra.value
    }), o;
  }, F = (a) => {
    const t = [];
    return a.includes("KYC_CHECK") && t.push("KYC_CHECK"), a.includes("VIDEO_KYC") && (t.push("KYC_VERIFICATION"), t.push("FILES")), t;
  }, D = async () => {
    try {
      c(!0);
      const a = u[0], t = F(a == null ? void 0 : a.services), l = n(a), o = g(a), I = {
        services: t
      };
      if (l && (I.kyc_check = l), o && (I.kyc_verification = o), I.services.length) {
        const b = await x.fpClient.tenant_management().configure(I);
        m == null || m({
          completedStep: "KYC_SERVICE",
          skip: !1,
          data: {
            tenantConfiguration: {
              respose: b
            }
          }
        });
      } else
        m == null || m({
          completedStep: "KYC_SERVICE",
          skip: !1
        });
    } catch (a) {
      window.debug.error(a), h(d._({
        id: "qMcu4/"
      })), ee(a);
    } finally {
      c(!1);
    }
  }, p = () => {
    m == null || m({
      completedStep: "KYC_SERVICE",
      skip: !0
    });
  }, A = (a) => {
    _((t) => [
      ...t,
      a
    ]), f("KRA_LISTING");
  };
  return r ? /* @__PURE__ */ e.jsx(X, {
    variant: "fullpage"
  }) : (() => {
    switch (C) {
      case "KRA_LISTING":
        return /* @__PURE__ */ e.jsx(pa, {
          kraList: u,
          handleAddKraDetails: () => f("ADD_KRA"),
          handleContinue: D,
          handleSkip: p,
          error: s
        });
      case "ADD_KRA":
        return /* @__PURE__ */ e.jsx(Ga, {
          handleGoBack: () => f("KRA_LISTING"),
          onComplete: A
        });
      default:
        return /* @__PURE__ */ e.jsx(X, {
          variant: "fullpage"
        });
    }
  })();
}, Ma = ({ onComplete: m, handleGoBack: x, addedRtaObject: C }) => {
  const f = [
    {
      label: "CAMS",
      value: "cams"
    },
    {
      label: "KFINTECH",
      value: "kfin"
    }
  ], [u, _] = O(null), s = (c, n) => {
    c.rta_type && delete c.rta_type, m(c), n == null || n.restart();
  };
  function h([c, n], g, { changeValue: F }) {
    F(g, c, () => n);
  }
  const r = (c, n) => (g) => {
    const F = g == null ? void 0 : g.value, D = {
      cams: "cams_broker_code",
      kfin: "karvy_broker_code"
    };
    return c.find((A) => A[D[F]]) ? n : void 0;
  };
  return /* @__PURE__ */ e.jsx(ie, {
    onSubmit: s,
    mutators: {
      updateRtaFields: h
    },
    subscription: {
      submitting: !0,
      values: !0,
      errors: !0
    },
    children: (c) => {
      var n, g, F, D, p, A, i, a;
      return /* @__PURE__ */ e.jsxs("form", {
        onSubmit: c.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(P, {
                title: d._({
                  id: "q/XGln"
                }),
                icon: x ? "Back" : null,
                onClick: x,
                semiBold: !0
              }),
              /* @__PURE__ */ e.jsx(Q, {
                type: "text",
                text: d._({
                  id: "IsjnD7"
                })
              }),
              /* @__PURE__ */ e.jsx(v, {
                message: `${d._({
                  id: "mLzyxB"
                })}`
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "rta_type",
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: S(j, r(C, d._({
                  id: "xyldcj"
                }))),
                children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Ie, {
                      name: t.input.name,
                      value: t.input.value,
                      onChange: (l) => {
                        var o, I, b, L, Y, V, K, B, k, M, T, R, w, Z;
                        (l == null ? void 0 : l.value) === "cams" ? ((I = (o = c == null ? void 0 : c.form) == null ? void 0 : o.mutators) == null || I.updateRtaFields("karvy_user_code", ""), (L = (b = c == null ? void 0 : c.form) == null ? void 0 : b.mutators) == null || L.updateRtaFields("karvy_broker_code", ""), (V = (Y = c == null ? void 0 : c.form) == null ? void 0 : Y.mutators) == null || V.updateRtaFields("karvy_fundsnet_userid", "")) : ((B = (K = c == null ? void 0 : c.form) == null ? void 0 : K.mutators) == null || B.updateRtaFields("cams_user_code", ""), (M = (k = c == null ? void 0 : c.form) == null ? void 0 : k.mutators) == null || M.updateRtaFields("cams_broker_code", ""), (R = (T = c == null ? void 0 : c.form) == null ? void 0 : T.mutators) == null || R.updateRtaFields("cams_fundsnet_userid", ""), (Z = (w = c == null ? void 0 : c.form) == null ? void 0 : w.mutators) == null || Z.updateRtaFields("cams_fundsnet_password", "")), _(null), t.input.onChange(l);
                      },
                      placeholder: d._({
                        id: "m5WZXC"
                      }),
                      options: f
                    }),
                    t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: t.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          ((g = (n = c == null ? void 0 : c.values) == null ? void 0 : n.rta_type) == null ? void 0 : g.value) === "cams" || ((D = (F = c == null ? void 0 : c.values) == null ? void 0 : F.rta_type) == null ? void 0 : D.value) === "kfin" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsx(P, {
                semiBold: !0,
                title: d._({
                  id: "TAHxkc"
                }),
                customClass: "mb-6"
              }),
              /* @__PURE__ */ e.jsx(Q, {
                type: "text",
                text: d._({
                  id: "sUsNAe"
                })
              })
            ]
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
          ((A = (p = c == null ? void 0 : c.values) == null ? void 0 : p.rta_type) == null ? void 0 : A.value) === "cams" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "zIr47t"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "cams_user_code",
                    validate: S(j, U(4), G(150)),
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "j73iMT"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "cams_broker_code",
                    validate: S(j, U(4), G(150)),
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "7fdWUz"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "cams_fundsnet_userid",
                    validate: (t) => {
                      if (t)
                        return U(4)(t);
                    },
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "qr9b31"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "cams_fundsnet_password",
                    validate: (t) => {
                      if (t)
                        return U(4)(t);
                    },
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              })
            ]
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
          ((a = (i = c == null ? void 0 : c.values) == null ? void 0 : i.rta_type) == null ? void 0 : a.value) === "kfin" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "zIr47t"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "karvy_user_code",
                    validate: S(j, U(4), G(150)),
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "j73iMT"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "karvy_broker_code",
                    validate: S(j, U(4), G(150)),
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "hUiBua"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "karvy_fundsnet_userid",
                    validate: (t) => {
                      if (t)
                        return U(4)(t);
                    },
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: d._({
                      id: "GdY2n0"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "karvy_fundsnet_password",
                    validate: (t) => {
                      if (t)
                        return U(4)(t);
                    },
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(y, {
                          name: t.input.name,
                          value: t.input.value,
                          onChange: (l) => t.input.onChange(l.target.value),
                          id: t.input.name,
                          autoComplete: "off"
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                ]
              })
            ]
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
          u && /* @__PURE__ */ e.jsx(N, {
            type: "error",
            variant: "field",
            title: u
          }),
          /* @__PURE__ */ e.jsx(W, {
            type: "submit",
            fullwidth: !0,
            customClass: "mb-2 mt-4",
            children: d._({
              id: "AtXdm9"
            })
          })
        ]
      });
    }
  });
}, wa = ({ onComplete: m }) => {
  const { fpapi: x } = $(te), [C, f] = O("initial"), [u, _] = O([]), [s, h] = O(null), [r, c] = O(!1), n = () => {
    m == null || m({
      completedStep: "ORDER_SERVICE",
      skip: !0
    });
  }, g = async (A) => {
    var l, o;
    c(!0);
    const i = u == null ? void 0 : u.filter((I) => I == null ? void 0 : I.cams_broker_code), a = u == null ? void 0 : u.filter((I) => I == null ? void 0 : I.karvy_user_code);
    !_e(i) && !_e(a) ? (A.cams_broker_code = i[0].cams_broker_code, A.cams_fundsnet_password = i[0].cams_fundsnet_password, A.cams_fundsnet_userid = i[0].cams_fundsnet_userid, A.cams_user_code = i[0].cams_user_code, A.karvy_broker_code = a[0].karvy_broker_code, A.karvy_fundsnet_password = a[0].karvy_fundsnet_password, A.karvy_fundsnet_userid = a[0].karvy_fundsnet_userid, A.karvy_user_code = a[0].karvy_user_code) : _e(i) ? _e(a) || (A.karvy_broker_code = a[0].karvy_broker_code, A.karvy_fundsnet_password = a[0].karvy_fundsnet_password, A.karvy_fundsnet_userid = a[0].karvy_fundsnet_userid, A.karvy_user_code = a[0].karvy_user_code) : (A.cams_broker_code = i[0].cams_broker_code, A.cams_fundsnet_password = i[0].cams_fundsnet_password, A.cams_fundsnet_userid = i[0].cams_fundsnet_userid, A.cams_user_code = i[0].cams_user_code);
    const t = {
      ...A,
      license_code: (l = A.license_code) == null ? void 0 : l.toLowerCase(),
      euins: [
        (o = A == null ? void 0 : A.euins) == null ? void 0 : o.toLowerCase()
      ]
    };
    try {
      const I = {
        services: [
          "OMS",
          "BACKPRESSURE",
          "ACCOUNTS_MF",
          "ONBOARDING",
          "FILES"
        ],
        oms: t
      }, b = await x.fpClient.tenant_management().configure(I);
      m == null || m({
        completedStep: "ORDER_SERVICE",
        data: b,
        skip: !1
      }), c(!1);
    } catch (I) {
      window.debug.error(I), h(d._({
        id: "qMcu4/"
      })), ee(I), c(!1);
    }
  }, F = (A) => A != null && A.cams_user_code ? "CAMS" : "KFINTECH", D = () => {
    switch (C) {
      case "initial":
      case "addRTA":
        return p();
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  }, p = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        style: {
          display: C === "initial" ? "block" : "none"
        },
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "flex justify-between items-start",
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                children: [
                  /* @__PURE__ */ e.jsx(P, {
                    title: d._({
                      id: "Z0JshH"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(Q, {
                    type: "text",
                    text: d._({
                      id: "PKxrGf"
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsx(W, {
                variant: "text",
                onClick: n,
                children: d._({
                  id: "PwQ/Gn"
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(P, {
            title: d._({
              id: "zFTegK"
            }),
            semiBold: !0,
            customClass: "mb-2"
          }),
          /* @__PURE__ */ e.jsx(ie, {
            onSubmit: g,
            subscription: {
              submitting: !0,
              values: !0,
              errors: !0
            },
            children: (A) => /* @__PURE__ */ e.jsxs("form", {
              onSubmit: A.handleSubmit,
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-4",
                  children: [
                    /* @__PURE__ */ e.jsx(v, {
                      message: d._({
                        id: "OJ6THD"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(E, {
                      name: "license_code",
                      validate: S(j, U(4), G(12), _a("Invalid license code")),
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(y, {
                            name: i.input.name,
                            value: i.input.value,
                            onChange: (a) => i.input.onChange(a.target.value),
                            placeholder: d._({
                              id: "JCWR06"
                            }),
                            id: i.input.name,
                            autoComplete: "off",
                            onInput: (a) => {
                              a.target.value = a.target.value.replace(H("A-Za-z-0-9"), "");
                            },
                            maxLength: 12,
                            autoFocus: !0
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(N, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-4",
                  children: [
                    /* @__PURE__ */ e.jsx(v, {
                      message: d._({
                        id: "y8WM6P"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(E, {
                      name: "distributor_name",
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(y, {
                            name: i.input.name,
                            value: i.input.value,
                            onChange: (a) => i.input.onChange(a.target.value),
                            id: i.input.name,
                            autoComplete: "off",
                            onInput: (a) => {
                              a.target.value = a.target.value.replace(H("A-Za-z-0-9"), "");
                            },
                            maxLength: 12
                          }),
                          i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx(N, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          }) : /* @__PURE__ */ e.jsx(N, {
                            type: "helper",
                            variant: "field",
                            title: "Optional",
                            customClass: "mb-5"
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(v, {
                      message: "Mobile number"
                    }),
                    /* @__PURE__ */ e.jsx(E, {
                      name: "mobile",
                      validate: (i) => {
                        if (i)
                          return ha("Enter valid 10 digit mobile number")(i);
                      },
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validateFields: [],
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(y, {
                            id: i.input.name,
                            autoComplete: "off",
                            name: i.input.name,
                            value: i.input.value,
                            onChange: i.input.onChange,
                            onBlur: () => {
                              i.input.onChange(i.input.value.trim());
                            },
                            onInput: (a) => {
                              a.target.value = a.target.value.replace(H("0-9"), "");
                            },
                            maxLength: 10,
                            status: i.meta.error && i.meta.touched ? "error" : void 0
                          }),
                          i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx(N, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          }) : /* @__PURE__ */ e.jsx(N, {
                            type: "helper",
                            variant: "field",
                            title: "Optional",
                            customClass: "mb-5"
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(v, {
                      message: d._({
                        id: "GJrVBj"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(E, {
                      name: "email",
                      validate: (i) => {
                        if (i)
                          return fa("Enter valid email")(i);
                      },
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(y, {
                            id: i.input.name,
                            autoComplete: "off",
                            name: i.input.name,
                            value: i.input.value,
                            onChange: i.input.onChange,
                            onBlur: () => {
                              i.input.onChange(i.input.value.trim());
                            },
                            onInput: (a) => {
                              a.target.value = a.target.value.replace(H("A-Za-z0-9._\\-@+"), "");
                            },
                            maxLength: 50,
                            status: i.meta.error && i.meta.touched ? "error" : void 0
                          }),
                          i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx(N, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          }) : /* @__PURE__ */ e.jsx(N, {
                            type: "helper",
                            variant: "field",
                            title: "Optional",
                            customClass: "mb-5"
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-4",
                  children: [
                    /* @__PURE__ */ e.jsx(v, {
                      message: d._({
                        id: "9YCydF"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(E, {
                      name: "euins",
                      validate: S(j, Ia("Invalid EUIN")),
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(y, {
                            name: i.input.name,
                            value: i.input.value,
                            onChange: (a) => i.input.onChange(a.target.value),
                            onInput: (a) => {
                              a.target.value = a.target.value.replace(H("A-Za-z0-9"), "");
                            },
                            id: i.input.name,
                            autoComplete: "off",
                            maxLength: 7
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(N, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    }),
                    /* @__PURE__ */ e.jsx(N, {
                      type: "helper",
                      variant: "field",
                      title: "This EUIN will be added to all MF buy/sell orders by default",
                      customClass: "mb-5"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-4",
                  children: [
                    /* @__PURE__ */ e.jsx(v, {
                      message: d._({
                        id: "BaZcSd"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(E, {
                      name: "location",
                      validate: S(j, U(3), G(60)),
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(y, {
                            name: i.input.name,
                            value: i.input.value,
                            onChange: (a) => i.input.onChange(a.target.value),
                            onInput: (a) => {
                              a.target.value = a.target.value.replace(H("A-Za-z "), "");
                            },
                            id: i.input.name,
                            autoComplete: "off",
                            maxLength: 60
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(N, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                u != null && u.length ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      style: {
                        marginBottom: "1rem"
                      },
                      children: /* @__PURE__ */ e.jsx(P, {
                        title: d._({
                          id: "q/XGln"
                        }),
                        semiBold: !0
                      })
                    }),
                    /* @__PURE__ */ e.jsx(v, {
                      message: d._({
                        id: "tSVjbB"
                      })
                    }),
                    u == null ? void 0 : u.map((i, a) => {
                      var t;
                      return /* @__PURE__ */ e.jsx(fe, {
                        header: {
                          title: /* @__PURE__ */ e.jsx("span", {
                            className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
                            children: d._({
                              id: "VoDlpm",
                              values: {
                                0: F(i)
                              }
                            })
                          })
                        },
                        customClass: "mb-4",
                        children: /* @__PURE__ */ e.jsx(ce, {
                          children: (t = Object.keys(i)) == null ? void 0 : t.map((l, o) => {
                            if (i[l])
                              return /* @__PURE__ */ e.jsx(de, {
                                span: 6,
                                children: /* @__PURE__ */ e.jsx(ne, {
                                  label: d._({
                                    id: "UMs2dC"
                                  }),
                                  value: i[l]
                                })
                              }, o);
                          })
                        })
                      }, a);
                    })
                  ]
                }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
                s && /* @__PURE__ */ e.jsx(N, {
                  type: "error",
                  variant: "field",
                  title: s
                }),
                /* @__PURE__ */ e.jsx(W, {
                  variant: (u == null ? void 0 : u.length) >= 2 ? "disabled" : "secondary",
                  fullwidth: !0,
                  type: "button",
                  customClass: "mt-4",
                  onClick: () => f("addRTA"),
                  children: d._({
                    id: "5nTbHP"
                  })
                }),
                /* @__PURE__ */ e.jsx(W, {
                  type: "submit",
                  fullwidth: !0,
                  customClass: "mb-2 mt-4",
                  children: d._({
                    id: "2iVSoY"
                  })
                })
              ]
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsx("div", {
        style: {
          display: C === "addRTA" ? "block" : "none"
        },
        children: /* @__PURE__ */ e.jsx(Ma, {
          handleGoBack: () => f("initial"),
          onComplete: (A) => {
            _((i) => [
              ...i,
              A
            ]), f("initial");
          },
          addedRtaObject: u
        })
      })
    ]
  });
  return r ? /* @__PURE__ */ e.jsx(X, {
    variant: "fullpage"
  }) : D();
}, Va = ({ onComplete: m, handleGoBack: x }) => {
  const C = [
    {
      label: "Billdesk",
      value: "billdesk"
    },
    {
      label: "Razorpay",
      value: "razorpay"
    }
  ], f = [
    {
      label: "Yes",
      value: "true"
    },
    {
      label: "No",
      value: "false"
    }
  ], u = (r) => {
    var g, F;
    let c = {
      billdesk_is_creds_v1: !0,
      billdesk_merchant_id: !0,
      billdesk_client_id: !0,
      billdesk_upi_bank_id: !0,
      billdesk_checksum_key: !0,
      razorpay_non_tpv_api_key: !0,
      razorpay_non_tpv_key_secret: !0,
      razorpay_tpv_api_key: !0,
      razorpay_tpv_key_secret: !0,
      razorpay_enable_transfer: !0
    };
    const n = (g = r == null ? void 0 : r.payment_provider) == null ? void 0 : g.value;
    return n === "billdesk" ? (c = {
      ...c,
      billdesk_is_creds_v1: !1,
      billdesk_merchant_id: !1,
      billdesk_client_id: !1
    }, ((F = r == null ? void 0 : r.billdesk_is_creds_v1) == null ? void 0 : F.value) === "true" && (c = {
      ...c,
      billdesk_upi_bank_id: !1,
      billdesk_checksum_key: !1
    })) : n === "razorpay" && (c = {
      ...c,
      razorpay_non_tpv_api_key: !1,
      razorpay_non_tpv_key_secret: !1,
      razorpay_tpv_api_key: !1,
      razorpay_tpv_key_secret: !1,
      razorpay_enable_transfer: !1
    }), c;
  }, _ = ea(() => ({
    legal_name: "",
    display_name: "",
    payment_postback_url: "",
    emandate_postback_url: "",
    payment_provider: null,
    billdesk_is_creds_v1: {
      label: "No",
      value: "false"
    },
    billdesk_merchant_id: "",
    billdesk_client_id: "",
    billdesk_upi_bank_id: "",
    billdesk_checksum_key: "",
    razorpay_non_tpv_api_key: "",
    razorpay_non_tpv_key_secret: "",
    razorpay_tpv_api_key: "",
    razorpay_tpv_key_secret: "",
    razorpay_enable_transfer: !1,
    services: [
      "PG"
    ]
  }), []), s = (r, c) => {
    var n;
    r != null && r.billdesk_is_creds_v1 && delete r.billdesk_is_creds_v1, r.provider = (n = r == null ? void 0 : r.payment_provider) == null ? void 0 : n.value, r != null && r.payment_provider && delete r.payment_provider, m(r), c == null || c.restart();
  };
  function h([r, c], n, { changeValue: g }) {
    g(n, r, () => c);
  }
  return /* @__PURE__ */ e.jsx(ie, {
    onSubmit: s,
    mutators: {
      updatePaymentProviderFields: h
    },
    subscription: {
      submitting: !0,
      values: !0,
      errors: !0
    },
    children: (r) => {
      const c = u(r.values);
      return /* @__PURE__ */ e.jsxs("form", {
        onSubmit: r.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(P, {
                title: d._({
                  id: "sCgeKC"
                }),
                icon: x ? "Back" : null,
                onClick: x,
                semiBold: !0
              }),
              /* @__PURE__ */ e.jsx(Q, {
                type: "text",
                text: d._({
                  id: "SWRdxU"
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: `${d._({
                  id: "mLzyxB"
                })}`
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "payment_provider",
                validate: j,
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validateFields: [],
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Ie, {
                      name: n.input.name,
                      value: n.input.value,
                      onChange: (g) => {
                        var F, D, p, A, i, a, t, l, o, I, b, L, Y, V, K, B, k, M;
                        g.value === "razorpay" ? ((D = (F = r == null ? void 0 : r.form) == null ? void 0 : F.mutators) == null || D.updatePaymentProviderFields("billdesk_merchant_id", ""), (A = (p = r == null ? void 0 : r.form) == null ? void 0 : p.mutators) == null || A.updatePaymentProviderFields("billdesk_client_id", ""), (a = (i = r == null ? void 0 : r.form) == null ? void 0 : i.mutators) == null || a.updatePaymentProviderFields("billdesk_upi_bank_id", ""), (l = (t = r == null ? void 0 : r.form) == null ? void 0 : t.mutators) == null || l.updatePaymentProviderFields("billdesk_upi_bank_id", ""), (I = (o = r == null ? void 0 : r.form) == null ? void 0 : o.mutators) == null || I.updatePaymentProviderFields("billdesk_checksum_key", "")) : ((L = (b = r == null ? void 0 : r.form) == null ? void 0 : b.mutators) == null || L.updatePaymentProviderFields("razorpay_non_tpv_api_key", ""), (V = (Y = r == null ? void 0 : r.form) == null ? void 0 : Y.mutators) == null || V.updatePaymentProviderFields("razorpay_non_tpv_key_secret", ""), (B = (K = r == null ? void 0 : r.form) == null ? void 0 : K.mutators) == null || B.updatePaymentProviderFields("razorpay_tpv_api_key", ""), (M = (k = r == null ? void 0 : r.form) == null ? void 0 : k.mutators) == null || M.updatePaymentProviderFields("razorpay_tpv_key_secret", "")), n.input.onChange(g);
                      },
                      placeholder: "Select servie provider",
                      options: C,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.billdesk_is_creds_v1 ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "Are you using v1 credentials?"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "billdesk_is_creds_v1",
                validate: j,
                initialValue: f[1],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => {
                  var g;
                  return /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(da, {
                        name: n.input.name,
                        size: "sm",
                        checked: Aa(f, ((g = _ == null ? void 0 : _.billdesk_is_creds_v1) == null ? void 0 : g.value) ?? ""),
                        onChange: (F, D) => n.input.onChange(D),
                        group: f
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  });
                }
              })
            ]
          }),
          c.billdesk_merchant_id ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "Merchant ID"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "billdesk_merchant_id",
                validate: S(j, U(2), G(200)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.billdesk_client_id ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "Client ID"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "billdesk_client_id",
                validate: S(j, U(2), G(200)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.billdesk_upi_bank_id ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "UPI bank ID"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "billdesk_upi_bank_id",
                validate: S(j, U(2), G(200)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.billdesk_checksum_key ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "Checksum key"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "billdesk_checksum_key",
                validate: S(j, U(2), G(200)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.razorpay_non_tpv_api_key ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "Non TPV API key"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "razorpay_non_tpv_api_key",
                validate: S(j, U(5), G(256)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      onInput: (g) => {
                        g.target.value = g.target.value.replace(H("A-Za-z0-9"), "");
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.razorpay_non_tpv_key_secret ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "Non TPV API key secret"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "razorpay_non_tpv_key_secret",
                validate: S(j, U(2), G(200)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.razorpay_tpv_api_key ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "TPV API key"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "razorpay_tpv_api_key",
                validate: S(j, U(5), G(256)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      onInput: (g) => {
                        g.target.value = g.target.value.replace(H("A-Za-z0-9"), "");
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.razorpay_tpv_key_secret ? null : /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                message: "TPV API key secret"
              }),
              /* @__PURE__ */ e.jsx(E, {
                name: "razorpay_tpv_key_secret",
                validate: S(j, U(2), G(200)),
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(y, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: n.input.onChange,
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      maxLength: 200,
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          c.razorpay_enable_transfer ? null : /* @__PURE__ */ e.jsx(e.Fragment, {}),
          /* @__PURE__ */ e.jsx(W, {
            type: "submit",
            fullwidth: !0,
            customClass: "mb-2 mt-4",
            children: d._({
              id: "AtXdm9"
            })
          })
        ]
      });
    }
  });
}, Ka = ({ onComplete: m }) => {
  const { fpapi: x } = $(te), [C, f] = O(null), [u, _] = O("LOADING"), [s, h] = O([]), [r, c] = O(!0);
  ue(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const n = (i) => [
    "onboarding",
    "oms",
    "backpressure",
    "account_mf",
    "files"
  ].every((l) => {
    var o;
    return ((o = i == null ? void 0 : i[l]) == null ? void 0 : o.status) === "CONFIGURED";
  });
  ue(() => {
    (async () => {
      try {
        _("LOADING");
        const i = await x.fpClient.tenant_management().fetch();
        n(i) || c(!1);
      } catch (i) {
        window.debug.error(i), ee(i), f("Error updating details");
      } finally {
        _("PAYMENT_SERVICE_FORM");
      }
    })();
  }, []);
  const g = ea(() => ({
    legal_name: "",
    display_name: "",
    payment_postback_url: "",
    emandate_postback_url: "",
    payment_provider: null,
    billdesk_is_creds_v1: {
      label: "No",
      value: "false"
    },
    billdesk_merchant_id: "",
    billdesk_client_id: "",
    billdesk_upi_bank_id: "",
    billdesk_checksum_key: "",
    razorpay_non_tpv_api_key: "",
    razorpay_non_tpv_key_secret: "",
    razorpay_tpv_api_key: "",
    razorpay_tpv_key_secret: "",
    razorpay_enable_transfer: !1,
    services: [
      "PG"
    ]
  }), []), F = () => {
    m == null || m({
      completedStep: "PAYMENT_SERVICE",
      skip: !0
    });
  }, D = async (i) => {
    var a, t, l, o, I, b, L, Y, V, K, B;
    try {
      _("LOADING");
      const k = s && ((a = s[0]) == null ? void 0 : a.provider);
      if (!k) {
        f("Payment provider is required");
        return;
      }
      const M = {
        services: [
          "PG"
        ],
        pg: {
          legal_name: (i == null ? void 0 : i.legal_name) || "",
          display_name: (i == null ? void 0 : i.display_name) || "",
          payment_postback_url: (i == null ? void 0 : i.payment_postback_url) || "",
          emandate_postback_url: (i == null ? void 0 : i.emandate_postback_url) || "",
          provider: ((t = s[0]) == null ? void 0 : t.provider) || "billdesk",
          billdesk: {
            merchant_id: k === "billdesk" && ((l = s[0]) == null ? void 0 : l.billdesk_merchant_id) || "",
            client_id: k === "billdesk" && ((o = s[0]) == null ? void 0 : o.billdesk_client_id) || "",
            upi_bank_id: k === "billdesk" && ((I = s[0]) == null ? void 0 : I.billdesk_upi_bank_id) || "",
            checksum_key: k === "billdesk" && ((b = s[0]) == null ? void 0 : b.billdesk_checksum_key) || ""
          },
          razorpay: {
            non_tpv_api_key: k === "razorpay" && ((L = s[0]) == null ? void 0 : L.razorpay_non_tpv_api_key) || "",
            non_tpv_key_secret: k === "razorpay" && ((Y = s[0]) == null ? void 0 : Y.razorpay_non_tpv_key_secret) || "",
            tpv_api_key: k === "razorpay" && ((V = s[0]) == null ? void 0 : V.razorpay_tpv_api_key) || "",
            tpv_key_secret: k === "razorpay" && ((K = s[0]) == null ? void 0 : K.razorpay_tpv_key_secret) || "",
            enable_transfer: k === "razorpay" && ((B = s[0]) == null ? void 0 : B.razorpay_enable_transfer) || !1
          }
        }
      }, T = await x.fpClient.tenant_management().configure(M);
      m == null || m({
        completedStep: "PAYMENT_SERVICE",
        skip: !1,
        data: {
          tenantConfiguration: {
            respose: T
          }
        }
      });
    } catch (k) {
      window.debug.error(k), f("Error updating details"), ee(k);
    } finally {
      _("PAYMENT_SERVICE_FORM");
    }
  }, p = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "flex justify-between items-start",
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            children: [
              /* @__PURE__ */ e.jsx(P, {
                title: d._({
                  id: "B98+RV"
                })
              }),
              /* @__PURE__ */ e.jsx(Q, {
                type: "text",
                text: /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    "Tell us about your payment provider to collect online payments from investors",
                    " ",
                    /* @__PURE__ */ e.jsx("a", {
                      href: "https://docs.fintechprimitives.com/going-live/checklist/",
                      className: "pl-1 text-size-14",
                      target: "_blank",
                      rel: "noreferrer",
                      children: "Learn more"
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(W, {
            variant: "text",
            onClick: F,
            children: d._({
              id: "PwQ/Gn"
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsx(ie, {
        onSubmit: D,
        initialValues: g,
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0,
          touched: !0
        },
        validate: (i) => {
          const a = {};
          return Xe(i.payment_postback_url) || (a.payment_postback_url = d._({
            id: "Tbxfkl"
          })), Xe(i.emandate_postback_url) || (a.emandate_postback_url = d._({
            id: "Tbxfkl"
          })), a;
        },
        children: (i) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: i.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(v, {
                  message: "Legal name"
                }),
                /* @__PURE__ */ e.jsx(E, {
                  name: "legal_name",
                  validate: S(j, U(2), G(200)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(y, {
                        id: a.input.name,
                        autoComplete: "off",
                        name: a.input.name,
                        value: a.input.value,
                        onChange: a.input.onChange,
                        onBlur: () => {
                          a.input.onChange(a.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(H("A-Za-z "), "");
                        },
                        maxLength: 200,
                        status: a.meta.error && a.meta.touched ? "error" : void 0
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(v, {
                  message: "Display name"
                }),
                /* @__PURE__ */ e.jsx(E, {
                  name: "display_name",
                  validate: S(j, U(2), G(200)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(y, {
                        id: a.input.name,
                        autoComplete: "off",
                        name: a.input.name,
                        value: a.input.value,
                        onChange: a.input.onChange,
                        onBlur: () => {
                          a.input.onChange(a.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(H("A-Za-z "), "");
                        },
                        maxLength: 200,
                        status: a.meta.error && a.meta.touched ? "error" : void 0
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(v, {
                  message: "Payment postback URL"
                }),
                /* @__PURE__ */ e.jsx(E, {
                  name: "payment_postback_url",
                  validate: S(j, U(2), G(200)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(y, {
                        id: a.input.name,
                        autoComplete: "off",
                        name: a.input.name,
                        value: a.input.value,
                        onChange: a.input.onChange,
                        onBlur: () => {
                          a.input.onChange(a.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(H("A-Za-z0-9._\\-@+//=%~#:"), "");
                        },
                        maxLength: 200,
                        status: a.meta.error && a.meta.touched ? "error" : void 0
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(v, {
                  message: "E-mandate postback URL"
                }),
                /* @__PURE__ */ e.jsx(E, {
                  name: "emandate_postback_url",
                  validate: S(j, U(2), G(200)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(y, {
                        id: a.input.name,
                        autoComplete: "off",
                        name: a.input.name,
                        value: a.input.value,
                        onChange: a.input.onChange,
                        onBlur: () => {
                          a.input.onChange(a.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(H("A-Za-z0-9._\\-@+//=%~#:"), "");
                        },
                        maxLength: 200,
                        status: a.meta.error && a.meta.touched ? "error" : void 0
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.length ? /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  style: {
                    marginBottom: "1rem"
                  },
                  children: /* @__PURE__ */ e.jsx(P, {
                    title: d._({
                      id: "sCgeKC"
                    }),
                    semiBold: !0
                  })
                }),
                /* @__PURE__ */ e.jsx(v, {
                  message: d._({
                    id: "tSVjbB"
                  })
                }),
                s == null ? void 0 : s.map((a, t) => {
                  var l;
                  return /* @__PURE__ */ e.jsx(fe, {
                    header: {
                      title: /* @__PURE__ */ e.jsx("span", {
                        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
                        children: d._({
                          id: "nxyLIL",
                          values: {
                            0: va(a == null ? void 0 : a.provider)
                          }
                        })
                      })
                    },
                    customClass: "mb-4",
                    children: /* @__PURE__ */ e.jsx(ce, {
                      children: (l = Object.keys(a)) == null ? void 0 : l.map((o, I) => {
                        if (a[o] && o !== "provider")
                          return /* @__PURE__ */ e.jsx(de, {
                            span: 6,
                            children: /* @__PURE__ */ e.jsx(ne, {
                              label: d._({
                                id: "UMs2dC"
                              }),
                              value: a[o]
                            })
                          }, I);
                      })
                    })
                  }, t);
                })
              ]
            }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
            C && /* @__PURE__ */ e.jsx(N, {
              type: "error",
              variant: "field",
              title: C
            }),
            /* @__PURE__ */ e.jsx(W, {
              type: "button",
              variant: s != null && s.length ? "disabled" : "secondary",
              fullwidth: !0,
              customClass: "mb-4",
              onClick: () => {
                _("ADD_PAYMENT_PROVIDER"), f(null);
              },
              children: d._({
                id: "8r2GbL"
              })
            }),
            /* @__PURE__ */ e.jsx(W, {
              type: "submit",
              variant: r ? "primary" : "disabled",
              fullwidth: !0,
              onClick: () => {
                const a = Object.keys(i.errors ?? {});
                a.length && aa(a[0]);
              },
              customClass: "mb-8",
              children: d._({
                id: "2iVSoY"
              })
            })
          ]
        })
      })
    ]
  });
  return (() => {
    switch (u) {
      case "LOADING":
        return /* @__PURE__ */ e.jsx(X, {
          variant: "fullpage"
        });
      case "PAYMENT_SERVICE_FORM":
      case "ADD_PAYMENT_PROVIDER":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx("div", {
              style: {
                display: u === "PAYMENT_SERVICE_FORM" ? "block" : "none"
              },
              children: p()
            }),
            /* @__PURE__ */ e.jsx("div", {
              style: {
                display: u === "ADD_PAYMENT_PROVIDER" ? "block" : "none"
              },
              children: /* @__PURE__ */ e.jsx(Va, {
                handleGoBack: () => _("PAYMENT_SERVICE_FORM"),
                onComplete: (i) => {
                  h((a) => [
                    ...a,
                    i
                  ]), _("PAYMENT_SERVICE_FORM");
                }
              })
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(X, {
          variant: "fullpage"
        });
    }
  })();
}, Ba = ({ handleGoBack: m, onComplete: x }) => {
  const C = [
    {
      label: "Razorpay",
      value: "razor_pay"
    },
    {
      label: "Camspay",
      value: "cams_pay"
    },
    {
      label: "Cybrilla",
      value: "default"
    }
  ], f = (s) => {
    x(s);
  }, u = (s, h) => {
    h != null && h.fields.amount && h.fields.amount.change(s[0]);
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        children: [
          /* @__PURE__ */ e.jsx(P, {
            title: d._({
              id: "tSz4kY"
            }),
            icon: m ? "Back" : null,
            onClick: m,
            semiBold: !0
          }),
          /* @__PURE__ */ e.jsx(Q, {
            type: "text",
            text: /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                "Add service provider details to verify investor bank accounts",
                " ",
                /* @__PURE__ */ e.jsx("a", {
                  href: "https://docs.fintechprimitives.com/identity/verification/perform-bank-account-verification/",
                  className: "pl-1 text-size-14",
                  target: "_blank",
                  rel: "noreferrer",
                  children: "Learn more"
                })
              ]
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsx(ie, {
        onSubmit: f,
        mutators: {
          updateAmountField: u
        },
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (s) => {
          var h, r, c, n, g, F, D, p, A, i;
          return /* @__PURE__ */ e.jsxs("form", {
            onSubmit: s.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8 ",
                children: [
                  /* @__PURE__ */ e.jsx(v, {
                    message: `${d._({
                      id: "mLzyxB"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(E, {
                    name: "pg_credential",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: S(j),
                    children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(Ie, {
                          name: a.input.name,
                          value: a.input.value,
                          onChange: (t) => {
                            s.form.mutators.updateAmountField(""), a.input.onChange(t);
                          },
                          placeholder: d._({
                            id: "m5WZXC"
                          }),
                          options: C
                        }),
                        a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx(N, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                      ]
                    })
                  })
                ]
              }),
              ((r = (h = s == null ? void 0 : s.values) == null ? void 0 : h.pg_credential) == null ? void 0 : r.value) === "default" ? /* @__PURE__ */ e.jsx(N, {
                type: "helper",
                variant: "field",
                title: d._({
                  id: "2UbL3S"
                }),
                customClass: "mb-5"
              }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
              ((n = (c = s == null ? void 0 : s.values) == null ? void 0 : c.pg_credential) == null ? void 0 : n.value) === "razor_pay" || ((F = (g = s == null ? void 0 : s.values) == null ? void 0 : g.pg_credential) == null ? void 0 : F.value) === "cams_pay" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(P, {
                    semiBold: !0,
                    title: d._({
                      id: "TAHxkc"
                    }),
                    customClass: "mb-6"
                  }),
                  /* @__PURE__ */ e.jsx(Q, {
                    type: "text",
                    text: d._({
                      id: "lhzBNF"
                    })
                  })
                ]
              }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
              ((p = (D = s == null ? void 0 : s.values) == null ? void 0 : D.pg_credential) == null ? void 0 : p.value) === "razor_pay" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "VgY9NE"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "api_key_id",
                        validate: S(j, U(5), G(256)),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              onInput: (t) => {
                                t.target.value = t.target.value.replace(H("A-Za-z0-9"), "");
                              },
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "brALIL"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "api_key_secret",
                        validate: S(j, U(5), G(256)),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "+Y8YJk"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "account_number",
                        validate: S(j, U(7), G(16), xa(d._({
                          id: "q17d/W"
                        }))),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              onInput: (t) => {
                                t.target.value = t.target.value.replace(H("0-9"), "");
                              },
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "KqeLJ1"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "amount",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              onInput: (t) => {
                                t.target.value = t.target.value.replace(H("0-9"), "");
                              },
                              prefix: "₹",
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "e4fj4E"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "webhook_secret",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  })
                ]
              }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
              ((i = (A = s == null ? void 0 : s.values) == null ? void 0 : A.pg_credential) == null ? void 0 : i.value) === "cams_pay" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "ClDLSp"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "source",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "pfTfzS"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "amc_code",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "rvnOw5"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "password",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "noJgfb"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "penny_drop",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "81IDsW"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "golden_master",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "ImA2td"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "application_id",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "lp2JZ/"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "match_percentage",
                        validate: S(j, Ca(0), Na(100)),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              onInput: (t) => {
                                t.target.value = t.target.value.replace(H("0-9"), "");
                              },
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-4",
                    children: [
                      /* @__PURE__ */ e.jsx(v, {
                        message: d._({
                          id: "KqeLJ1"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(E, {
                        name: "amount",
                        validate: j,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(y, {
                              name: a.input.name,
                              value: a.input.value,
                              onChange: (t) => a.input.onChange(t.target.value),
                              onInput: (t) => {
                                t.target.value = t.target.value.replace(H("0-9"), "");
                              },
                              prefix: "₹",
                              id: a.input.name,
                              autoComplete: "off"
                            }),
                            a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(N, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  })
                ]
              }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
              /* @__PURE__ */ e.jsx(W, {
                type: "submit",
                fullwidth: !0,
                customClass: "mb-2 mt-4",
                children: d._({
                  id: "AtXdm9"
                })
              })
            ]
          });
        }
      })
    ]
  });
}, Pa = ({ onComplete: m }) => {
  const { fpapi: x } = $(te), [C, f] = O(null), [u, _] = O(!1), [s, h] = O("initial"), [r, c] = O([]), [n, g] = O(!1), F = () => {
    m == null || m({
      completedStep: "VERIFICATION_SERVICE",
      skip: !0
    });
  }, D = async () => {
    var a, t, l, o, I, b, L, Y, V, K, B, k, M, T, R, w, Z, J, ae, le, se;
    if (n) {
      m == null || m({
        completedStep: "VERIFICATION_SERVICE",
        skip: !0
      });
      return;
    }
    _(!0);
    const i = {};
    i.type = "bank_account", i.provider = (t = (a = r[0]) == null ? void 0 : a.pg_credential) == null ? void 0 : t.value;
    try {
      if (((o = (l = r[0]) == null ? void 0 : l.pg_credential) == null ? void 0 : o.value) === "razor_pay") {
        const re = {
          api_key_id: (I = r[0]) == null ? void 0 : I.api_key_id,
          api_key_secret: (b = r[0]) == null ? void 0 : b.api_key_secret,
          account_number: (L = r[0]) == null ? void 0 : L.account_number,
          amount: (Y = r[0]) == null ? void 0 : Y.amount,
          webhook_secret: (V = r[0]) == null ? void 0 : V.webhook_secret
        };
        i.razorpay = re;
      } else if (((B = (K = r[0]) == null ? void 0 : K.pg_credential) == null ? void 0 : B.value) === "cams_pay") {
        const re = {
          source: (k = r[0]) == null ? void 0 : k.source,
          amc_code: (M = r[0]) == null ? void 0 : M.amc_code,
          password: (T = r[0]) == null ? void 0 : T.password,
          penny_drop: (R = r[0]) == null ? void 0 : R.penny_drop,
          golden_master: (w = r[0]) == null ? void 0 : w.golden_master,
          application_id: (Z = r[0]) == null ? void 0 : Z.application_id,
          match_percentage: (J = r[0]) == null ? void 0 : J.match_percentage,
          amount: (ae = r[0]) == null ? void 0 : ae.amount
        };
        i.camspay = re;
      }
      const q = {
        services: [
          "VERIFICATION"
        ],
        verification: i
      };
      ((se = (le = r[0]) == null ? void 0 : le.pg_credential) == null ? void 0 : se.value) === "default" && delete q.verification;
      const oe = await x.fpClient.tenant_management().configure(q);
      m == null || m({
        completedStep: "VERIFICATION_SERVICE",
        skip: !1,
        data: {
          tenantConfiguration: {
            respose: oe
          }
        }
      }), _(!1);
    } catch (q) {
      window.debug.error(q), f("Error updating details"), ee(q), _(!1);
    }
  }, p = () => n ? !1 : !(r != null && r.length), A = () => {
    switch (s) {
      case "initial":
      case "addPG":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              style: {
                display: s === "initial" ? "block" : "none"
              },
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "flex justify-between items-start",
                  children: [
                    /* @__PURE__ */ e.jsxs("div", {
                      children: [
                        /* @__PURE__ */ e.jsx(P, {
                          title: d._({
                            id: "GSCt9l"
                          })
                        }),
                        /* @__PURE__ */ e.jsx(Q, {
                          type: "text",
                          text: d._({
                            id: "y2Eq3e"
                          })
                        })
                      ]
                    }),
                    /* @__PURE__ */ e.jsx(W, {
                      variant: "text",
                      onClick: F,
                      children: d._({
                        id: "PwQ/Gn"
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(P, {
                  title: d._({
                    id: "tSz4kY"
                  }),
                  semiBold: !0
                }),
                /* @__PURE__ */ e.jsx(Q, {
                  type: "text",
                  text: /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      "SEBI mandates that the bank account from which payment is made for investment must belong to the investor.",
                      " ",
                      /* @__PURE__ */ e.jsx("a", {
                        href: "https://docs.fintechprimitives.com/identity/verification/perform-bank-account-verification/",
                        className: "pl-1 text-size-14",
                        target: "_blank",
                        rel: "noreferrer",
                        children: "Learn more"
                      })
                    ]
                  })
                }),
                r != null && r.length ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      style: {
                        marginBottom: "1rem"
                      },
                      children: /* @__PURE__ */ e.jsx(P, {
                        title: d._({
                          id: "E+4PA3"
                        }),
                        semiBold: !0
                      })
                    }),
                    r == null ? void 0 : r.map((i, a) => {
                      var t, l, o, I;
                      return /* @__PURE__ */ e.jsx(fe, {
                        customClass: "mb-4",
                        header: {
                          title: /* @__PURE__ */ e.jsx("span", {
                            className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
                            children: (t = i == null ? void 0 : i.pg_credential) == null ? void 0 : t.label
                          })
                        },
                        cardSpacing: ((l = i == null ? void 0 : i.pg_credential) == null ? void 0 : l.label) !== "Cybrilla",
                        children: /* @__PURE__ */ e.jsx(ce, {
                          children: (I = (o = Object.keys(i)) == null ? void 0 : o.filter((b) => !!(typeof i[b] != "object" && i[b]))) == null ? void 0 : I.map((b, L) => /* @__PURE__ */ e.jsx(de, {
                            span: 6,
                            children: /* @__PURE__ */ e.jsx(ne, {
                              label: d._({
                                id: "UMs2dC"
                              }),
                              value: `${b === "amount" ? "₹" : ""} ${i[b]}`
                            })
                          }, L))
                        })
                      }, a);
                    })
                  ]
                }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx(W, {
                      variant: r && (r == null ? void 0 : r.length) >= 1 ? "disabled" : "secondary",
                      fullwidth: !0,
                      type: "button",
                      customClass: "mt-4",
                      onClick: () => h("addPG"),
                      children: d._({
                        id: "4DuaTk"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(he, {
                      id: "terms-id",
                      checked: n,
                      onChange: (i) => {
                        i.stopPropagation(), g(!n), !n && c([]);
                      },
                      customClass: "mt-2",
                      children: d._({
                        id: "xge71U"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(N, {
                      type: "helper",
                      variant: "field",
                      title: "FP assumes that you will verify bank account before making payments"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs($e, {
                  children: [
                    C && /* @__PURE__ */ e.jsx(N, {
                      type: "error",
                      variant: "field",
                      title: C
                    }),
                    /* @__PURE__ */ e.jsx(W, {
                      variant: p() ? "disabled" : "primary",
                      fullwidth: !0,
                      customClass: "mt-4",
                      onClick: D,
                      children: d._({
                        id: "2iVSoY"
                      })
                    })
                  ]
                })
              ]
            }),
            /* @__PURE__ */ e.jsx("div", {
              style: {
                display: s === "addPG" ? "block" : "none"
              },
              children: /* @__PURE__ */ e.jsx(Ba, {
                handleGoBack: () => h("initial"),
                onComplete: (i) => {
                  c((a) => [
                    ...a,
                    i
                  ]), h("initial");
                }
              })
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: u ? /* @__PURE__ */ e.jsx(X, {
      variant: "fullpage"
    }) : A()
  });
}, Ce = [
  "KYC_SERVICE",
  "VERIFICATION_SERVICE",
  "ORDER_SERVICE",
  "PAYMENT_SERVICE",
  "AMC_CONFIGURATION",
  "SUMMARY"
];
function Ra() {
  const [m, x] = O(!1), [C, f] = O("KYC_SERVICE"), u = (h) => {
    const r = Ce.indexOf(h);
    return r === -1 ? Ce[0] : Ce[r + 1];
  }, _ = ({ completedStep: h, skip: r, data: c }) => {
    const n = u(h);
    if (h === "ORDER_SERVICE" && r) {
      f("SUMMARY");
      return;
    }
    f(n);
  }, s = () => {
    switch (C) {
      case "KYC_SERVICE":
        return /* @__PURE__ */ e.jsx(Ya, {
          onComplete: _
        });
      case "VERIFICATION_SERVICE":
        return /* @__PURE__ */ e.jsx(Pa, {
          onComplete: _
        });
      case "ORDER_SERVICE":
        return /* @__PURE__ */ e.jsx(wa, {
          onComplete: _
        });
      case "PAYMENT_SERVICE":
        return /* @__PURE__ */ e.jsx(Ka, {
          onComplete: _
        });
      case "AMC_CONFIGURATION":
        return /* @__PURE__ */ e.jsx(ya, {
          onComplete: _
        });
      case "SUMMARY":
        return /* @__PURE__ */ e.jsx(Oa, {});
      default:
        return /* @__PURE__ */ e.jsx(X, {
          variant: "fullpage"
        });
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: m ? /* @__PURE__ */ e.jsx(X, {
      variant: "fullpage"
    }) : s()
  });
}
function tn() {
  const { AppState: { showWelcomePage: m }, setShowWelcomePage: x } = $(ca), { isFpapiLoading: C } = $(te), f = ua(), [u, _] = O("LOADING"), s = () => {
    x(!1), _("LOADING");
  }, h = [
    {
      step: 1,
      content: d._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: d._({
        id: "GLGAFu"
      })
    },
    {
      step: 3,
      content: d._({
        id: "hHnCwH"
      })
    },
    {
      step: 4,
      content: d._({
        id: "tCIUvb"
      })
    },
    {
      step: 5,
      content: d._({
        id: "Ehx02m"
      })
    },
    {
      step: 6,
      content: d._({
        id: "Y4ilpS"
      })
    }
  ];
  return ue(() => {
    if (m)
      return _("WELCOME");
    f.isLoading || (f.isAuthenticated ? C || _("TMS_CONFIGURE") : f.login());
  }, [
    f.isAuthenticated,
    f.isLoading,
    m,
    C
  ]), (() => {
    switch (u) {
      case "TMS_CONFIGURE":
        return /* @__PURE__ */ e.jsx(Ra, {});
      case "LOADING":
        return /* @__PURE__ */ e.jsx(X, {
          variant: "fullpage"
        });
      case "WELCOME":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(P, {
              title: d._({
                id: "0U7S/G"
              }),
              customClass: "mb-6"
            }),
            /* @__PURE__ */ e.jsx(Ea, {
              onAck: s,
              welcomeSteps: h
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(X, {
          variant: "fullpage"
        });
    }
  })();
}
export {
  tn as TenantManagementServiceConfigure
};
