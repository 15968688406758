import { j as n } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as ue, useState as E, useEffect as oe, useLayoutEffect as pe, Children as xe } from "react";
import { D as he, S as A, c as o, a as ye } from "./index.esm-CqWfms5D.js";
import { u as be } from "./useConfiguration-B-lyaBB9.js";
import { F as ge } from "./provider-DKLaKIVD.js";
import { r as Y } from "./sentry-CstOwvcR.js";
import { d as z, i as L, e as f, f as p, h as ke, j as Ne, k as Ce } from "./utils-C2gHLhxV.js";
import { i as r } from "./index-CrDCxF0S.js";
function fe({ folio: w, investorProfile: a, investment_accounts: F }) {
  var M;
  const { fpapi: g } = ue(ge), { tenantConfig: D, banks: q, amcs: H } = be(), T = D == null ? void 0 : D.fallbackLogo, [j, W] = E([]), [e, K] = E(null), [S, Z] = E([]), _ = F == null ? void 0 : F[0];
  oe(() => {
    (async () => {
      var c;
      try {
        const t = await g.fpClient.master_data().fetchAmcs();
        if (!z(t == null ? void 0 : t.amcs) && !L(w)) {
          const i = (c = t == null ? void 0 : t.amcs) == null ? void 0 : c.filter((x) => x.amc_code === w.amc);
          !z(i) && Z(i[0]);
        }
      } catch (t) {
        window.debug.log(t, "Error");
      }
    })();
  }, [
    w,
    a
  ]);
  const Q = (c) => {
    const t = [];
    for (const i in c)
      if (c[i]) {
        const x = c[i].toString(), y = Ne(x);
        t.push(` ${y.trim()}`);
      }
    return t.join();
  }, U = {
    upto_1lakh: "0 - 1 Lakh",
    above_1lakh_upto_5lakh: "1 - 5 Lakhs",
    above_5lakh_upto_10lakh: "5 - 10 Lakhs",
    above_10lakh_upto_25lakh: "10 - 25 Lakhs",
    above_25lakh_upto_1cr: "25 Lakhs - 1 Crore",
    above_1cr: "Above 1 Crore"
  }, X = (c) => c === "pep_exposed" ? "Yes" : c === "pep_related" ? "Related" : "No", v = async () => {
    var c, t;
    if ((c = _ == null ? void 0 : _.folio_defaults) != null && c.payout_bank_account)
      return g.fpClient.bank_accounts().fetch((t = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : t.payout_bank_account);
    {
      const { data: i } = await g.fpClient.bank_accounts().fetchAll({
        profile: a == null ? void 0 : a.id
      });
      return i[(i == null ? void 0 : i.length) - 1];
    }
  }, O = async () => {
    var c, t, i, x, y, k;
    try {
      const b = ((c = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : c.communication_email_address) && g.fpClient.email_addresses().fetch((t = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : t.communication_email_address), N = ((i = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : i.communication_mobile_number) && g.fpClient.phone_numbers().fetch((x = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : x.communication_mobile_number), h = ((y = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : y.communication_address) && g.fpClient.addresses().fetch((k = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : k.communication_address), d = (a == null ? void 0 : a.id) && g.fpClient.related_parties().fetchAll({
        profile: a == null ? void 0 : a.id
      }), u = v(), l = await Promise.all([
        b,
        N,
        h,
        d,
        u
      ]);
      return {
        email: l[0],
        phone: l[1],
        address: l[2],
        nominees: l[3].data,
        bankAccount: l[4]
      };
    } catch (b) {
      return b;
    }
  };
  pe(() => {
    (async () => {
      var c, t, i, x, y, k, b, N;
      try {
        const { countries: h } = await g.fpClient.master_data().fetchCountries();
        W(h);
        let d;
        if (w)
          d = {
            ...w
          };
        else if (a) {
          const { email: u, address: l, phone: C, nominees: s, bankAccount: m } = await O();
          d = {
            id: null,
            amc: null,
            number: null,
            primary_investor_pan: _ == null ? void 0 : _.primary_investor_pan,
            secondary_investor_pan: null,
            third_investor_pan: null,
            holding_pattern: _ == null ? void 0 : _.holding_pattern,
            primary_investor_name: a == null ? void 0 : a.name,
            secondary_investor_name: null,
            third_investor_name: null,
            primary_investor_dob: a == null ? void 0 : a.date_of_birth,
            secondary_investor_dob: null,
            third_investor_dob: null,
            primary_investor_gender: a == null ? void 0 : a.gender,
            secondary_investor_gender: null,
            third_investor_gender: null,
            primary_investor_tax_status: "individual",
            primary_investor_occupation: a == null ? void 0 : a.occupation,
            guardian_name: null,
            guardian_gender: null,
            guardian_pan: null,
            guardian_dob: null,
            guardian_relationship: null,
            nominee1: s != null && s[0] ? {
              name: s[0].name,
              dob: s[0].date_of_birth,
              relationship: s[0].relationship,
              guardian: s[0].guardian_name,
              guardian_relationship: null
            } : null,
            nominee1_allocation_percentage: s != null && s.length ? 100 / (s == null ? void 0 : s.length) : null,
            nominee2: s != null && s[1] ? {
              name: s[1].name,
              dob: s[1].date_of_birth,
              relationship: s[1].relationship,
              guardian: s[1].guardian_name,
              guardian_relationship: null
            } : null,
            nominee2_allocation_percentage: s != null && s.length ? 100 / (s == null ? void 0 : s.length) : null,
            nominee3: s != null && s[2] ? {
              name: s[2].name,
              dob: s[2].date_of_birth,
              relationship: s[2].relationship,
              guardian: s[2].guardian_name,
              guardian_relationship: null
            } : null,
            nominee3_allocation_percentage: s != null && s.length ? 100 / (s == null ? void 0 : s.length) : null,
            payout_details: [
              {
                scheme_code: null,
                bank_account: m ? {
                  name: m == null ? void 0 : m.primary_account_holder_name,
                  number: m == null ? void 0 : m.account_number,
                  account_type: m == null ? void 0 : m.type,
                  ifsc: m == null ? void 0 : m.ifsc_code,
                  bank_name: m == null ? void 0 : m.bank_name
                } : {}
              }
            ],
            contact_details: [
              {
                address: {
                  line1: l == null ? void 0 : l.line1,
                  line2: l == null ? void 0 : l.line2,
                  line3: l == null ? void 0 : l.line3,
                  city: l == null ? void 0 : l.city,
                  state: l == null ? void 0 : l.state,
                  postal_code: l == null ? void 0 : l.postal_code,
                  country_name: f(h, l == null ? void 0 : l.country),
                  country_ansi_code: l == null ? void 0 : l.country
                },
                email: u == null ? void 0 : u.email,
                mobile: C == null ? void 0 : C.number,
                type: "correspondence"
              }
            ],
            no_other_tax_residences: ((c = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : c.country) === "IN",
            email_addresses: [
              u == null ? void 0 : u.email
            ],
            mobile_numbers: [
              C == null ? void 0 : C.number
            ],
            country_of_birth: f(h, l == null ? void 0 : l.country),
            primary_investor_residential_status: "Resident Indian",
            source_of_wealth: p(a == null ? void 0 : a.source_of_wealth),
            income: U[a == null ? void 0 : a.income_slab]
          };
        }
        if (!((i = (t = d == null ? void 0 : d.payout_details[0]) == null ? void 0 : t.bank_account) != null && i.bank_name))
          try {
            const u = ((y = (x = d == null ? void 0 : d.payout_details[0]) == null ? void 0 : x.bank_account) == null ? void 0 : y.ifsc) && await g.fpClient.master_data().fetchIfscCodes((b = (k = d == null ? void 0 : d.payout_details[0]) == null ? void 0 : k.bank_account) == null ? void 0 : b.ifsc), l = {
              ...d == null ? void 0 : d.payout_details[0],
              bank_account: {
                ...(N = d == null ? void 0 : d.payout_details[0]) == null ? void 0 : N.bank_account,
                bank_name: u == null ? void 0 : u.bank_name
              }
            };
            d = {
              ...d,
              payout_details: [
                {
                  ...l
                }
              ]
            };
          } catch (u) {
            Y(u);
          }
        K(d);
      } catch (h) {
        window.debug.error(h), Y(h);
      }
    })();
  }, [
    w,
    a
  ]);
  const P = () => {
    const c = {}, t = {
      PAN: e && e.primary_investor_pan,
      Name: p(e == null ? void 0 : e.primary_investor_name),
      "Date of birth": e != null && e.primary_investor_dob ? Ce(new Date(e == null ? void 0 : e.primary_investor_dob), "dd-MMM-yyyy") : null,
      Gender: p(e == null ? void 0 : e.primary_investor_gender),
      "Country of birth": e == null ? void 0 : e.country_of_birth,
      "Are you politically exposed?": a ? X(a == null ? void 0 : a.pep_details) : void 0,
      "Resident status": e == null ? void 0 : e.primary_investor_residential_status,
      "Second investor name": p(e.secondary_investor_name),
      "Second investor PAN": e.secondary_investor_pan,
      "Third investor name": p(e.third_investor_name),
      "Third investor PAN": e.third_investor_pan
    };
    for (const i in t)
      t[i] && (c[i] = t[i]);
    return c;
  }, ee = () => {
    const c = {}, t = {
      "Tax status": p(e.primary_investor_tax_status),
      Occupation: p(e == null ? void 0 : e.primary_investor_occupation),
      "Source of wealth": p(e == null ? void 0 : e.source_of_wealth),
      Income: e == null ? void 0 : e.income
    };
    for (const i in t)
      t[i] && (c[i] = t[i]);
    return c;
  }, ne = () => {
    var i, x, y, k, b, N;
    const c = {}, t = {
      "First country of residence": f(j, (i = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : i.country),
      "First tax identification number": (x = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : x.taxid_number,
      "Second country of residence": f(j, (y = a == null ? void 0 : a.second_tax_residency) == null ? void 0 : y.country),
      "Second tax identification number": (k = a == null ? void 0 : a.second_tax_residency) == null ? void 0 : k.taxid_number,
      "Third country of residence": f(j, (b = a == null ? void 0 : a.third_tax_residency) == null ? void 0 : b.country),
      "Third tax identification number": (N = a == null ? void 0 : a.third_tax_residency) == null ? void 0 : N.taxid_number
    };
    for (const h in t)
      t[h] && (c[h] = t[h]);
    return c;
  }, ae = {
    Name: p(e == null ? void 0 : e.guardian_name),
    Relationship: p(e == null ? void 0 : e.guardian_relationship),
    "Date of birth": e == null ? void 0 : e.guardian_dob,
    Gender: p(e == null ? void 0 : e.guardian_gender),
    PAN: e == null ? void 0 : e.guardian_pan
  }, se = () => /* @__PURE__ */ n.jsx(I, {
    title: r._({
      id: "5S80EC"
    }),
    details: P()
  }), le = () => /* @__PURE__ */ n.jsx(I, {
    title: r._({
      id: "Ipj2tP"
    }),
    details: {
      "Mode of holding": p(e == null ? void 0 : e.holding_pattern) || null
    }
  }), te = () => /* @__PURE__ */ n.jsx(I, {
    title: r._({
      id: "Cu4OzE"
    }),
    details: ee()
  }), ce = () => /* @__PURE__ */ n.jsx(I, {
    title: r._({
      id: "3aeTAD"
    }),
    details: ne()
  }), ie = () => {
    var c, t, i, x, y, k, b, N, h, d, u, l, C, s, m, R, $, J, B, G, V;
    return !L((c = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : c.bank_account) && ((i = (t = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : t.bank_account) != null && i.bank_name) ? /* @__PURE__ */ n.jsx(A, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ n.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: a ? r._({
            id: "6ZvJaV"
          }) : r._({
            id: "inuwv2"
          })
        })
      },
      children: /* @__PURE__ */ n.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "TkdqW3"
              }),
              value: `${(y = (x = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : x.bank_account) == null ? void 0 : y.bank_name} -
    ****${(N = (b = (k = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : k.bank_account) == null ? void 0 : b.number) == null ? void 0 : N.slice(-4)}`,
              logo: (d = (h = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : h.bank_account) != null && d.ifsc && q[(C = (l = (u = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : u.bank_account) == null ? void 0 : l.ifsc) == null ? void 0 : C.slice(0, 4)] || T
            })
          }),
          /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "JzJ/t2"
              }),
              value: (m = (s = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : s.bank_account) != null && m.account_type ? p(($ = (R = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : R.bank_account) == null ? void 0 : $.account_type) : "-"
            })
          }),
          /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: "IFSC",
              value: (B = (J = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : J.bank_account) != null && B.ifsc ? (V = (G = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : G.bank_account) == null ? void 0 : V.ifsc : "-"
            })
          })
        ]
      })
    }) : null;
  }, re = () => {
    var c, t, i, x, y, k, b, N, h, d, u, l, C, s, m, R;
    return !z(e.mobile_numbers) && e.mobile_numbers[0] || !z(e == null ? void 0 : e.contact_details) && ((t = (c = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : c.address) != null && t.line1) || !z(e.email_addresses) && e.email_addresses[0] ? /* @__PURE__ */ n.jsx(A, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ n.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: "Contact details"
        })
      },
      children: /* @__PURE__ */ n.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          !z(e.mobile_numbers) && e.mobile_numbers[0] ? /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "0VsDgp"
              }),
              value: e.mobile_numbers[0]
            })
          }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
          !z(e == null ? void 0 : e.contact_details) && ((x = (i = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : i.address) != null && x.line1) ? /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "FBMtAv"
              }),
              value: Q({
                addressLine1: (k = (y = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : y.address) == null ? void 0 : k.line1,
                addressLine2: (N = (b = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : b.address) == null ? void 0 : N.line2,
                addressLine3: (d = (h = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : h.address) == null ? void 0 : d.line3,
                state: (l = (u = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : u.address) == null ? void 0 : l.state,
                country: (s = (C = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : C.address) == null ? void 0 : s.country_name,
                postal_code: (R = (m = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : m.address) == null ? void 0 : R.postal_code
              })
            })
          }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
          !z(e.email_addresses) && e.email_addresses[0] ? /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "oyjr24"
              }),
              value: e.email_addresses[0]
            })
          }) : /* @__PURE__ */ n.jsx(n.Fragment, {})
        ]
      })
    }) : /* @__PURE__ */ n.jsx(n.Fragment, {});
  }, _e = () => /* @__PURE__ */ n.jsx(A, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ n.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: r._({
          id: "EPAVJA"
        })
      })
    },
    children: e.nominee1 ? /* @__PURE__ */ n.jsxs(n.Fragment, {
      children: [
        /* @__PURE__ */ n.jsxs("section", {
          className: "mb-2",
          children: [
            /* @__PURE__ */ n.jsx("h4", {
              className: "bg-primary-20 text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color rounded-md border border-solid px-1 pb-1",
              children: r._({
                id: "rDqvmv"
              })
            }),
            /* @__PURE__ */ n.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "uR3xiz"
                    }),
                    value: e.nominee1 ? p(e.nominee1.name) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "pI9t3O"
                    }),
                    value: e.nominee1 ? p(e.nominee1.relationship) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "92x2v3"
                    }),
                    value: e.nominee1_allocation_percentage ? `${Math.floor(e.nominee1_allocation_percentage)}` : "-"
                  })
                })
              ]
            })
          ]
        }),
        e.nominee2 && /* @__PURE__ */ n.jsxs("section", {
          className: "mb-2",
          children: [
            /* @__PURE__ */ n.jsx("h4", {
              className: "bg-primary-20 text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color rounded-md border border-solid px-1 pb-1",
              children: r._({
                id: "uuNdFd"
              })
            }),
            /* @__PURE__ */ n.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "uR3xiz"
                    }),
                    value: e.nominee2 ? p(e.nominee2.name) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "pI9t3O"
                    }),
                    value: e.nominee2 ? p(e.nominee2.relationship) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "92x2v3"
                    }),
                    value: e.nominee2_allocation_percentage ? `${Math.floor(e.nominee2_allocation_percentage)}` : "-"
                  })
                })
              ]
            })
          ]
        }),
        e.nominee3 && /* @__PURE__ */ n.jsxs("section", {
          className: "mb-2",
          children: [
            /* @__PURE__ */ n.jsx("h4", {
              className: "bg-primary-20 text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color rounded-md border border-solid px-1 pb-1",
              children: r._({
                id: "lb72I6"
              })
            }),
            /* @__PURE__ */ n.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3  basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "uR3xiz"
                    }),
                    value: e.nominee3 ? p(e.nominee3.name) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "YPSxBA"
                    }),
                    value: e.nominee3 ? p(e.nominee3.relationship) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "92x2v3"
                    }),
                    value: e.nominee3_allocation_percentage ? `${Math.ceil(e.nominee3_allocation_percentage)}` : "-"
                  })
                })
              ]
            })
          ]
        })
      ]
    }) : /* @__PURE__ */ n.jsx("p", {
      className: "text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color px-1 pb-1",
      children: r._({
        id: "mks8ng"
      })
    })
  }), de = () => /* @__PURE__ */ n.jsx(A, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ n.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: "Demat account"
      })
    },
    children: /* @__PURE__ */ n.jsxs("div", {
      className: "-mr-2 -ml-2 flex flex-wrap items-center",
      children: [
        /* @__PURE__ */ n.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ n.jsx(o, {
            label: r._({
              id: "e3lc0/"
            }),
            value: e != null && e.dp_id ? e == null ? void 0 : e.dp_id : "-"
          })
        }),
        /* @__PURE__ */ n.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ n.jsx(o, {
            label: r._({
              id: "JRKgov"
            }),
            value: e != null && e.client_id ? e == null ? void 0 : e.client_id : "-"
          })
        })
      ]
    })
  }), me = () => /* @__PURE__ */ n.jsx(I, {
    title: r._({
      id: "R2NauH"
    }),
    details: ae
  });
  return e ? /* @__PURE__ */ n.jsxs("div", {
    className: "mb-8",
    children: [
      /* @__PURE__ */ n.jsx(he, {
        type: "text",
        text: w ? "" : r._({
          id: "xnP1PY"
        })
      }),
      w || !L(S) ? /* @__PURE__ */ n.jsx(A, {
        customClass: "mb-4",
        children: /* @__PURE__ */ n.jsxs("div", {
          className: "-mr-2 -ml-2 flex flex-wrap items-center",
          children: [
            w ? /* @__PURE__ */ n.jsx("div", {
              className: "mb-3 basis-full pr-2 pl-2",
              children: /* @__PURE__ */ n.jsx(o, {
                label: r._({
                  id: "0c+2Wu"
                }),
                value: e.number
              })
            }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
            L(S) ? /* @__PURE__ */ n.jsx(n.Fragment, {}) : /* @__PURE__ */ n.jsx("div", {
              className: "mb-3 basis-full pr-2 pl-2",
              children: /* @__PURE__ */ n.jsx(o, {
                label: r._({
                  id: "bisISz"
                }),
                value: ke(S == null ? void 0 : S.name),
                logo: H[S == null ? void 0 : S.amc_id] || T
              })
            })
          ]
        })
      }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
      se(),
      le(),
      te(),
      a && ((a == null ? void 0 : a.first_tax_residency) || (a == null ? void 0 : a.second_tax_residency) || (a == null ? void 0 : a.third_tax_residency)) && ((M = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : M.country) !== "IN" && ce(),
      ie(),
      re(),
      _e(),
      (e == null ? void 0 : e.guardian_pan) && me(),
      e != null && e.dp_id || e != null && e.client_id ? de() : /* @__PURE__ */ n.jsx(n.Fragment, {})
    ]
  }) : /* @__PURE__ */ n.jsx(ye, {
    variant: "fullpage"
  });
}
const I = ({ title: w, details: a }) => /* @__PURE__ */ n.jsx(A, {
  customClass: "mb-4",
  header: {
    title: /* @__PURE__ */ n.jsx("span", {
      className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
      children: w
    })
  },
  children: /* @__PURE__ */ n.jsx("div", {
    className: "-mr-2 -ml-2 flex flex flex-wrap items-center",
    children: xe.toArray(Object.keys(a).map((F) => Array.isArray(a[F]) ? a[F].map((g) => {
      const D = {
        ...g
      };
      return D == null || delete D.title, // eslint-disable-next-line react/jsx-key
      /* @__PURE__ */ n.jsx(I, {
        title: g == null ? void 0 : g.title,
        details: D
      });
    }) : /* @__PURE__ */ n.jsx("div", {
      className: "mb-3 basis-1/2 pr-2 pl-2",
      children: /* @__PURE__ */ n.jsx(o, {
        label: F,
        value: a[F] ? a[F] : "-"
      })
    })))
  })
});
export {
  fe as FolioDetails
};
