import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as O, useState as u, useEffect as P } from "react";
import { a as U, S as Oe, c as E, P as R, B as Fe } from "./index.esm-CqWfms5D.js";
import { AppContext as re } from "./AppContext.js";
import { u as V } from "./useConfiguration-B-lyaBB9.js";
import { F as W } from "./provider-DKLaKIVD.js";
import { i } from "./index-CrDCxF0S.js";
import { r as y } from "./sentry-CstOwvcR.js";
import { O as Ae, I as Ee } from "./Investment-DfJ4DgdT.js";
import { i as D, h as Me, l as He, A as Te, w as Ke, V as Ue, J as $e, B as ie, d as Je } from "./utils-C2gHLhxV.js";
import { u as Pe, A as We } from "./Auth-CJPsuV-i.js";
import { LoginContext as Be } from "./LoginContext.js";
import { c as Re } from "./checkMigratedUser-D-TLMNz-.js";
import { p as Ve } from "./partiallyOnboardMigratedUser-CSHFHhW4.js";
import { O as Xe } from "./Onboard-B4_fp4ld.js";
import { P as Qe } from "./PartnerLogin-D3m53h1t.js";
import { K as qe } from "./KycCheckStatus-VYtx9hRY.js";
import { ErrorPage as $ } from "./ErrorPage.js";
import { O as ze } from "./OtpForm-B6uHV89C.js";
import { O as Ge } from "./OrderStatus-C3VoFfP1.js";
import { P as ae } from "./Product-DeVtlxIM.js";
import { W as Ye } from "./Welcome-Bz7XIwKh.js";
import { FundList as Ze } from "./FundList.js";
function et({ createdOrder: m, orderRequest: k, investorProfile: _, handleOrderOTPConfirmation: x }) {
  const { fpapi: w } = O(W), [L, r] = u(!1), C = async () => {
    r(!0);
    try {
      const a = await w.fpClient.email_addresses().fetchAll({
        profile: _ == null ? void 0 : _.id
      }), l = a == null ? void 0 : a.data[0], s = await w.fpClient.phone_numbers().fetchAll({
        profile: _ == null ? void 0 : _.id
      }), o = s == null ? void 0 : s.data[0];
      return r(!1), {
        emailData: l,
        phoneData: o
      };
    } catch (a) {
      window.debug.log(a), y(a);
    }
  }, b = async () => {
    const a = D(m) ? k : m, { emailData: l, phoneData: s } = await C();
    if (r(!0), (a == null ? void 0 : a.object) === "mf_redemption")
      try {
        const o = await w.fpClient.mf_redemptions().update({
          id: a.id,
          state: "confirmed",
          consent: {
            email: l.email,
            isd_code: s.isd,
            mobile: s.number
          }
        });
        x(o);
      } catch (o) {
        window.debug.log(o), y(o);
      }
    else if ((a == null ? void 0 : a.object) === "mf_switch")
      try {
        const o = await w.fpClient.mf_switches().update({
          id: a.id,
          state: "confirmed",
          consent: {
            email: l.email,
            isd_code: s.isd,
            mobile: s.number
          }
        });
        x(o);
      } catch (o) {
        window.debug.log(o), y(o);
      }
    else if ((a == null ? void 0 : a.object) === "mf_switch_plan") {
      let o = {
        ...k
      };
      delete o.object, o = {
        ...o,
        consent: {
          email: l.email,
          isd_code: s.isd,
          mobile: s.number
        }
      };
      try {
        const f = await w.fpClient.mf_switch_plans().create(o);
        x(f);
      } catch (f) {
        window.debug.log(f), y(f);
      }
    } else if ((a == null ? void 0 : a.object) === "mf_redemption_plan") {
      let o = {
        ...k
      };
      delete o.object, o = {
        ...o,
        consent: {
          email: l.email,
          isd_code: s.isd,
          mobile: s.number
        }
      };
      try {
        const f = await w.fpClient.mf_redemption_plans().create(o);
        x(f);
      } catch (f) {
        window.debug.log(f), y(f);
      }
    }
    r(!1);
  };
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: L ? /* @__PURE__ */ t.jsx(U, {
      variant: "fullpage"
    }) : /* @__PURE__ */ t.jsx(ze, {
      handleSubmit: b,
      pageTitle: i._({
        id: "s/ORP1"
      }),
      investorProfile: _
    })
  });
}
function tt({ createdOrder: m, orderRequest: k, investorProfile: _, onConfirmation: x }) {
  const { tenantConfig: w, amcs: L } = V(), r = w == null ? void 0 : w.fallbackLogo, C = w == null ? void 0 : w.showSchemeLogo, [b, a] = u(!1), [l, s] = u(null), [o, f] = u("initial"), { fpapi: N } = O(W), h = D(m) ? k : m, M = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  P(() => {
    async function S() {
      a(!0);
      try {
        const v = await N.fpClient.master_data().fetchScheme(h.switch_out_scheme), T = C ? {
          ...v,
          schemeLogo: L[v == null ? void 0 : v.amc_id] || r
        } : {
          ...v
        };
        s(T), f("initial"), a(!1);
      } catch (v) {
        f("error"), y(v);
      }
    }
    S();
  }, [
    N,
    m,
    k,
    h,
    C,
    L,
    r
  ]);
  const I = async (S) => {
    x(S);
  };
  return b ? /* @__PURE__ */ t.jsx(U, {
    variant: "fullpage"
  }) : (() => {
    switch (o) {
      case "initial":
        return /* @__PURE__ */ t.jsxs("div", {
          style: {
            marginTop: "-20px"
          },
          children: [
            /* @__PURE__ */ t.jsx(Oe, {
              children: /* @__PURE__ */ t.jsxs("div", {
                className: "-ml-2 -mr-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ t.jsx("div", {
                    className: "mb-3 basis-full pl-2 pr-2",
                    children: /* @__PURE__ */ t.jsx(E, {
                      label: i._({
                        id: "6Xqu8f"
                      }),
                      value: l && /* @__PURE__ */ t.jsxs("div", {
                        className: "flex",
                        children: [
                          (l == null ? void 0 : l.schemeLogo) && /* @__PURE__ */ t.jsx("img", {
                            src: l == null ? void 0 : l.schemeLogo,
                            alt: "logo",
                            className: M
                          }),
                          /* @__PURE__ */ t.jsx("div", {
                            className: "flex flex-col",
                            children: /* @__PURE__ */ t.jsx("div", {
                              children: `${Me(l.name)}`
                            })
                          })
                        ]
                      })
                    })
                  }),
                  /* @__PURE__ */ t.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ t.jsx(E, {
                      label: i._({
                        id: "fezFD7"
                      }),
                      value: He(l == null ? void 0 : l.investment_option)
                    })
                  }),
                  h.amount && /* @__PURE__ */ t.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ t.jsx(E, {
                      label: i._({
                        id: "KqeLJ1"
                      }),
                      value: h.amount ? `₹ ${Te(h.amount, 2)}` : "-"
                    })
                  }),
                  h.units && /* @__PURE__ */ t.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ t.jsx(E, {
                      label: i._({
                        id: "6ssn5V"
                      }),
                      value: h.units ? h.units : "-"
                    })
                  }),
                  h.folio_number && /* @__PURE__ */ t.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ t.jsx(E, {
                      label: i._({
                        id: "cXcPd1"
                      }),
                      value: h.folio_number ? h.folio_number : "-"
                    })
                  }),
                  !h.amount && !h.units && /* @__PURE__ */ t.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ t.jsx(E, {
                      label: i._({
                        id: "RBIA5y"
                      }),
                      value: "Switch All"
                    })
                  })
                ]
              })
            }),
            /* @__PURE__ */ t.jsx(et, {
              investorProfile: _,
              createdOrder: m,
              orderRequest: k,
              handleOrderOTPConfirmation: I
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ t.jsx($, {
          title: i._({
            id: "R9c5Jl"
          }),
          description: i._({
            id: "UFXd5p"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "order_error":
        return /* @__PURE__ */ t.jsx($, {
          title: i._({
            id: "SOfRak"
          }),
          description: i._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: i._({
            id: "wy5cp6"
          }),
          actionHandler: () => f("initial")
        });
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
    }
  })();
}
function nt({ scheme: m, switch_out: k, isGroupInvestment: _, isPartner: x }) {
  var Z, ee, te, ne;
  const { AppState: { tenant: w } } = O(re), [L, r] = u(() => sessionStorage.getItem("investment_workflow_stage") || "order"), [C, b] = u({}), [a, l] = u({}), [s, o] = u(() => {
    const n = sessionStorage.getItem("investment_workflow_order_id");
    return n ? JSON.parse(n) : null;
  }), [f, N] = u(null), [h, M] = u(null), [I, H] = u(null), [S, v] = u(null), [T, p] = u(!1), [F, K] = u("purchase"), { analytics: ce } = Pe(), { fpapi: g } = O(W), { loggedInPartner: X } = O(Be), { tenantConfig: A } = V(), Q = (Z = A == null ? void 0 : A.groupInvestment) == null ? void 0 : Z.allowedEmailDomains, [q, le] = u(null), z = ((ee = f == null ? void 0 : f.annexure) == null ? void 0 : ee.isonboarded) === "true", de = ((te = A == null ? void 0 : A.app_constants) == null ? void 0 : te.kyc_refetch_interval_in_months) || 3, [me, G] = u(!1), [fe, he] = u(null), [pe, ue] = u(!1), [_e, we] = u(!1);
  P(() => {
    (async () => x && await ge())();
  }, [
    h
  ]);
  const ge = async () => {
    if (!X)
      p(!0);
    else if (h) {
      if (g.fetchUser(h, w).then((n) => {
        g.fpClient.investor_profiles().fetchAll({
          pan: h
        }).then((e) => {
          const d = e == null ? void 0 : e.data[0];
          d ? (Re(n == null ? void 0 : n[0], g).then(async (c) => {
            ue(c.isMigratedUser), c.isMigratedUser && (await Ve(g, n[0], c == null ? void 0 : c.migratedMfiaList, c == null ? void 0 : c.migratedFolioList), Y(n == null ? void 0 : n[0]).then((j) => {
              j != null && j.status && we(!0);
            }));
          }), g.fpClient.mf_investment_accounts().fetchAll({
            investor: d.pan
          }).then((c) => {
            H(d), v(c.data), N(n[0]), be(c == null ? void 0 : c.data[0]), ce.identify(d.id), _ && g.fpClient.email_addresses().fetchAll({
              profile: d == null ? void 0 : d.id
            }).then((j) => {
              var se;
              if (!(Q ? (se = j == null ? void 0 : j.data) == null ? void 0 : se.some((B) => {
                var oe;
                return Q[(oe = B == null ? void 0 : B.email) == null ? void 0 : oe.split("@")[1]];
              }) : !1)) {
                r("invalid_email_domain");
                return;
              }
            });
          })) : p(!0);
        }).catch((e) => {
          window.debug.error(e), y(e);
        }).finally(() => {
          p(!0);
        });
      }), !m.active || !m.purchase_allowed && !m.sip_allowed) {
        r("purchase_not_allowed");
        return;
      }
      await xe();
    } else
      p(!0);
  };
  async function xe() {
    if (!s)
      return;
    const n = s.object;
    if (n === "mf_purchase")
      try {
        const e = await g.fpClient.mf_purchases().fetch(s == null ? void 0 : s.id);
        b(e);
      } catch (e) {
        window.debug.log(e), y(e);
      }
    else if (n === "mf_switch")
      try {
        const e = await g.fpClient.mf_switches().fetch(s == null ? void 0 : s.id);
        b(e), K("switch");
      } catch (e) {
        window.debug.log(e), y(e);
      }
    else if (n === "mf_purchase_plan")
      try {
        const e = await g.fpClient.mf_purchase_plans().fetch(s == null ? void 0 : s.id);
        b(e);
      } catch (e) {
        window.debug.log(e), y(e);
      }
    else if (n === "mf_switch_plan")
      try {
        const e = await g.fpClient.mf_switch_plans().fetch(s == null ? void 0 : s.id);
        b(e);
      } catch (e) {
        window.debug.error(e), y(e);
      }
  }
  const be = async (n) => {
    try {
      const e = await g.fpClient.investor_reports().fetchHoldings({
        investment_account_id: n == null ? void 0 : n.old_id
      });
      he(e);
    } catch (e) {
      window.debug.log(e, "Error");
    }
  };
  async function je(n, e, d) {
    if (G(d), !d || !d.orderId) {
      r("finished"), b(e), sessionStorage.setItem("investment_workflow_stage", "finished"), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
        ...n,
        object: e.object
      }));
      return;
    }
    p(!1);
    try {
      const c = await g.fpClient.mf_purchases().fetch(d.orderId);
      sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
        id: c.id,
        old_id: c.old_id,
        object: c.object
      })), b(e);
    } catch (c) {
      window.debug.log(c), y(c);
    }
    r("finished"), sessionStorage.setItem("investment_workflow_stage", "finished"), p(!0);
  }
  function ye(n) {
    const { mf_order: e, isGenerateFirstInstallment: d, shouldOnboardMigratedUser: c } = n, j = {
      id: e.id,
      old_id: e.old_id
    };
    if (o(j), c) {
      b(e), r("onboard_migrated_user");
      return;
    }
    e.object === "mf_purchase" && (b(e), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...j,
      object: e.object
    })), r("finished"), sessionStorage.setItem("investment_workflow_stage", "finished")), (e.object === "mf_switch" || e.object === "mf_switch_plan") && (b(e), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...j,
      object: e.object
    })), r("finished"), sessionStorage.setItem("investment_workflow_stage", "finished")), e.object === "mf_purchase_plan" && (G(d), je(j, e, d));
  }
  function ke() {
    const n = sessionStorage.getItem("investment_workflow_order_id"), e = n ? JSON.parse(n) : null;
    sessionStorage.removeItem("investment_workflow_stage"), sessionStorage.removeItem("investment_workflow_order_id"), b({}), l({}), r("order"), e.object === "mf_purchase" || e.object === "mf_purchase_plan" ? K("purchase") : K("switch");
  }
  const Se = (n, e, d) => {
    r("order"), N(d), H(n), v(e), p(!0);
  }, ve = (n) => {
    const e = {
      id: n.id,
      old_id: n.old_id
    };
    o(e), b(n), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...e,
      object: n.object
    })), r("finished"), sessionStorage.setItem("investment_workflow_stage", "finished");
  }, Ce = () => {
    var n;
    (n = g == null ? void 0 : g.userAuthService) == null || n.initiateLogout(window.location.href).then((e) => {
      window.location.href = e.userState;
    });
  }, Y = async (n) => {
    const e = Ke(n == null ? void 0 : n.annexure);
    if (e != null && e.kycCheckId) {
      const d = await g.fpClient.kyc_checks().fetch(e == null ? void 0 : e.kycCheckId);
      return d.status && !Ue(d.updated_at, de) ? d : $e(d.updated_at) ? await g.fpClient.kyc_checks().refetch(e == null ? void 0 : e.kycCheckId) : d;
    } else {
      const d = n == null ? void 0 : n.id, c = await g.fpClient.kyc_checks().create({
        pan: n == null ? void 0 : n.username.toUpperCase()
      }), j = {
        ...e,
        kycCheckId: c.id
      }, J = await g.updateUser(d, j, null, w);
      if (J)
        return c;
      throw J;
    }
  }, Le = () => ((!pe || z) && (async () => {
    const n = await Y(f);
    if (!n.status) {
      le(n), r("kyc_non_compliant");
      return;
    }
  })(), !D(m) && !Je(S) && /* @__PURE__ */ t.jsx(Ae, {
    scheme: m,
    switch_out: k,
    investment_account_id: S[0].id,
    investment_accounts: S,
    defaultInvestmentType: F,
    investorProfile: I,
    isGroupInvestment: _,
    onSuccess: ye,
    isPartner: x,
    user: f,
    holdings: fe
  }));
  function Ne() {
    switch (L) {
      default:
        return;
      case "order":
        return Le();
      case "finished":
        return !D(m) && !D(C) && /* @__PURE__ */ t.jsx(Ge, {
          investorProfile: I,
          scheme: m,
          mf_order: C,
          isGroupInvestment: _,
          handleInvestAgain: ke,
          isPartner: x,
          isFotOrder: me
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ t.jsx($, {
          title: i._({
            id: "9VGPlF"
          }),
          description: i._({
            id: "INP47b"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: i._({
            id: "L2gqm2"
          })
        });
      case "switch_confirmation":
        return !D(m) && (!D(C) || !D(a)) && /* @__PURE__ */ t.jsx(tt, {
          createdOrder: C,
          orderRequest: a,
          investorProfile: I,
          onConfirmation: ve
        });
      case "invalid_email_domain":
        return /* @__PURE__ */ t.jsx($, {
          title: i._({
            id: "N98vRu"
          }),
          description: i._({
            id: "y8Ur5q"
          }),
          showDetails: !1,
          actionText: i._({
            id: "+2LBoP"
          }),
          showAction: !0,
          actionHandler: Ce
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ t.jsx(st, {
          onContinue: () => {
            window.location.href = `${window.location.origin + ie}/onboarding?isPartner=${x}&redirectURL=${encodeURIComponent(`${window.location.origin + ie}/assisted-checkout/?isin=${m.isin}&isPartner=${x}`)}`;
          }
        });
      case "kyc_non_compliant":
        return q && /* @__PURE__ */ t.jsx(qe, {
          kycCheckObject: q,
          customMessage: {
            unverified: i._({
              id: "V2UMyt"
            })
          }
        });
    }
  }
  const Ie = ({ pan: n }) => {
    x && (p(!1), M(n));
  }, De = _e || I && z && ((ne = S == null ? void 0 : S[0]) == null ? void 0 : ne.primary_investor);
  return x ? /* @__PURE__ */ t.jsx(t.Fragment, {
    children: T ? X ? h ? De ? /* @__PURE__ */ t.jsx(t.Fragment, {
      children: Ne()
    }) : f && /* @__PURE__ */ t.jsx(Xe, {
      user: f,
      isGroupInvestment: _,
      setUser: N,
      onBoardingComplete: Se,
      isPartner: x
    }) : /* @__PURE__ */ t.jsx(We, {
      onPreviewData: Ie,
      allowRegistration: !0,
      isPartner: x,
      isGroupInvestment: _,
      redirectURL: window.location.href,
      showTestPan: {
        onboarded: !0,
        kycCompliant: !0,
        kycNonCompliant: !0
      }
    }) : /* @__PURE__ */ t.jsx(Qe, {
      context: `${window.location.pathname}${window.location.search}`
    }) : /* @__PURE__ */ t.jsx(U, {
      variant: "fullpage"
    })
  }) : /* @__PURE__ */ t.jsx(U, {
    variant: "fullpage"
  });
}
const st = ({ onContinue: m }) => /* @__PURE__ */ t.jsxs("div", {
  children: [
    /* @__PURE__ */ t.jsx(R, {
      title: i._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ t.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ t.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ t.jsx(Fe, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => m(),
        children: "Continue"
      })
    })
  ]
});
function Nt({ isin: m, switch_out: k, isPartner: _ }) {
  const { fpapi: x } = O(W), { AppState: { showWelcomePage: w }, setShowWelcomePage: L } = O(re), { tenantConfig: r, amcs: C } = V(), b = r == null ? void 0 : r.fallbackLogo, a = r == null ? void 0 : r.showSchemeLogo, [l, s] = u(""), [o, f] = u(), [N, h] = u(!1), M = _ ? [
    {
      step: 1,
      content: i._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: i._({
        id: "fCWxIr"
      })
    },
    {
      step: 3,
      content: i._({
        id: "v4fm2D"
      })
    },
    {
      step: 4,
      content: i._({
        id: "Nuos0E"
      })
    }
  ] : [
    {
      step: 1,
      content: i._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: i._({
        id: "v4fm2D"
      })
    },
    {
      step: 3,
      content: i._({
        id: "nKQKlx"
      })
    }
  ];
  P(() => {
    m && (h(!0), x.semiPublicFpClient.master_data().fetchScheme(m).then((p) => {
      I(p);
    }).catch((p) => {
      h(!1), window.debug.error(p), y(p);
    }));
  }, [
    m
  ]);
  function I(p) {
    if (!p)
      s("schemeNotFound");
    else {
      const F = a ? {
        ...p,
        schemeLogo: C[p == null ? void 0 : p.amc_id] || b
      } : {
        ...p
      };
      f(F);
    }
    h(!1);
  }
  P(() => {
    s(H());
  }, [
    w
  ]);
  function H() {
    return w ? "welcome" : m ? "investment" : "fund_list";
  }
  function S() {
    L(!1), s("investment");
  }
  function v(p) {
    const F = new URLSearchParams(window.location.search);
    F.set("isin", p);
    const K = F.toString();
    window.location.href = window.location.origin + window.location.pathname + `?${K}`;
  }
  function T() {
    if (l === "welcome")
      return /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          /* @__PURE__ */ t.jsx(R, {
            title: o ? i._({
              id: "Qx20fI"
            }) : i._({
              id: "FhpR0a"
            })
          }),
          /* @__PURE__ */ t.jsx(ae, {
            scheme: o
          }),
          /* @__PURE__ */ t.jsx(Ye, {
            onAck: S,
            welcomeSteps: M
          })
        ]
      });
    if (l === "fund_list")
      return /* @__PURE__ */ t.jsx(Ze, {
        onFundSelection: v
      });
    if (l === "investment")
      return /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          /* @__PURE__ */ t.jsx(R, {
            title: i._({
              id: "Qx20fI"
            })
          }),
          /* @__PURE__ */ t.jsx(ae, {
            scheme: o
          }),
          _ ? /* @__PURE__ */ t.jsx(nt, {
            scheme: o,
            switch_out: k,
            isPartner: _
          }) : /* @__PURE__ */ t.jsx(Ee, {
            scheme: o,
            switch_out: k
          })
        ]
      });
    if (l === "schemeNotFound")
      return /* @__PURE__ */ t.jsx($, {
        title: i._({
          id: "/+1l7D"
        }),
        description: i._({
          id: "H8AXWD"
        }),
        showDetails: !1,
        showAction: !1
      });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: N ? /* @__PURE__ */ t.jsx(U, {
      variant: "fullpage"
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: T()
    })
  });
}
export {
  Nt as Checkout
};
