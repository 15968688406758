import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as b, useState as i, useEffect as I } from "react";
import { AppContext as st } from "./AppContext.js";
import { F as it } from "./provider-DKLaKIVD.js";
import { LoginContext as rt } from "./LoginContext.js";
import { P as p, a as at, b as lt, D as ct } from "./index.esm-CqWfms5D.js";
import { u as dt } from "./useConfiguration-B-lyaBB9.js";
import { r as M } from "./sentry-CstOwvcR.js";
import { r as mt } from "./MandateListing-DpfjvUeo.js";
import { i as L, E as pt, B as ft } from "./utils-C2gHLhxV.js";
import { i as n } from "./index-CrDCxF0S.js";
import { o as ut } from "./redirection-Dil_BQsn.js";
import { W as ht } from "./Welcome-Bz7XIwKh.js";
import { A as gt } from "./Auth-CJPsuV-i.js";
import { P as _t } from "./PartnerLogin-D3m53h1t.js";
import { P as xt, a as Pt } from "./PlansListing-C2awMAEW.js";
import { ErrorPage as jt } from "./ErrorPage.js";
import { E as wt, P as yt, S as St } from "./StopPlanStatus-Bp1JyTUu.js";
function Ut({ isGroupInvestment: k, mandate_id: W, isPartner: P }) {
  var O;
  const { loggedInPartner: z } = b(rt), { AppState: { showWelcomePage: A, tenant: B }, setShowWelcomePage: R } = b(st), { fpapi: a } = b(it), [j, N] = i({}), [T, D] = i(F), [U, f] = i(!1), [c, E] = i({}), [G, l] = i("initial"), [v, w] = i(!1), [J, K] = i({}), [h, g] = i(!1), [q, H] = i({}), [u, $] = i(null), V = ({ pan: e }) => {
    $(e);
  }, Y = [
    {
      step: 1,
      content: n._({
        id: "1ddmub"
      })
    },
    {
      step: 2,
      content: n._({
        id: "2l2YJx"
      })
    },
    {
      step: 3,
      content: n._({
        id: "05nr9e"
      })
    }
  ], { tenantConfig: d } = dt();
  (O = d == null ? void 0 : d.groupInvestment) == null || O.allowedEmailDomains, I(() => {
    (async () => {
      if (a && u)
        try {
          f(!0);
          const e = a.fpClient.investor_profiles().fetchAll({
            pan: u
          }), o = a.fpClient.mf_investment_accounts().fetchAll({
            investor: u
          }), [r, s] = await Promise.all([
            e,
            o
          ]), _ = r == null ? void 0 : r.data[0], x = s == null ? void 0 : s.data[0];
          if (!x || !_) {
            f(!1);
            return;
          }
          N(_), H(x);
        } catch (e) {
          window.debug.error(e), M(e);
        } finally {
          f(!1);
        }
      else
        f(!1);
    })();
  }, [
    u
  ]);
  const Z = () => {
    l("initial"), g(!h);
  }, Q = () => {
    l("initial"), g(!h);
  }, X = async (e, o) => {
    var r;
    if ((o == null ? void 0 : o.provider_name) === "BILLDESK")
      ut(e == null ? void 0 : e.token_url, "mandate-billdesk", () => Z(), () => Q());
    else
      try {
        const { emailData: s, phoneData: _ } = await a.fetchEmailAndPhone(j), x = {
          amount: 0,
          currency: "INR",
          method: "emandate",
          bank: "ICIC",
          email: s.email,
          contact: _.number,
          order_id: (r = e.sdk_options) == null ? void 0 : r.order_id,
          customer_id: e.sdk_options.customer_id
        }, S = mt(e.sdk_options.key);
        S.createPayment(x), S.on("payment.success", function(m) {
          a.submitPayment(e.sdk_options.callback_url, m.razorpay_payment_id, m.razorpay_order_id, m.razorpay_signature).then(() => {
            l("initial"), g(!h);
          });
        }), S.on("payment.error", function(m) {
          a.submitPaymentError(e.sdk_options.callback_url, "error", m.error.code, m.error.description).then(() => {
            l("initial"), g(!h);
          });
        });
      } catch (s) {
        window.debug.log(s.message), M(s);
      }
  };
  function C(e) {
    var r;
    if (P) {
      window.open(pt(window.location.origin + `/mandates?id=${e == null ? void 0 : e.id}`, B), "_blank", "noopener=true");
      return;
    }
    f(!0);
    const o = {
      mandate_id: e == null ? void 0 : e.id
    };
    ((r = d == null ? void 0 : d.app_constants) == null ? void 0 : r.paymentGateway) === "BILLDESK" && (o.payment_postback_url = `${window.location.origin + ft}/redirection`), a.fpClient.mandates().authorize(o).then(async (s) => {
      X(s, e);
    });
  }
  const y = () => {
    l("initial");
  }, tt = () => /* @__PURE__ */ t.jsx(lt, {
    variant: "fullPage",
    title: n._({
      id: "eVF0Ns"
    }),
    contentHeightMax: !0,
    isOpen: v,
    toggleModal: () => {
      w(!1);
    },
    children: /* @__PURE__ */ t.jsx(xt, {
      investorProfile: j,
      currentPlanObject: J,
      handleAuthorize: C,
      onModifySuccess: (e, o) => {
        w(!1), E(e), l(o);
      },
      isGroupInvestment: k,
      isPartner: P
    })
  });
  function et() {
    switch (G) {
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "initial":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "2yU70/"
              })
            }),
            /* @__PURE__ */ t.jsx(ct, {
              type: "text",
              text: n._({
                id: "t9eDJH"
              })
            }),
            /* @__PURE__ */ t.jsx(Pt, {
              investorProfile: j,
              isGroupInvestment: k,
              mandate_id: W,
              mfInvestmentAccount: q,
              onModifySuccess: (e, o) => {
                E(e), l(o);
              },
              togglePlanDetailsModal: (e) => {
                w(!v), K(e);
              },
              handleAuthorize: C,
              isPartner: P
            })
          ]
        });
      case "stop_finished":
        return !L(c) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "RGK6hg"
              })
            }),
            /* @__PURE__ */ t.jsx(St, {
              currentPlanObject: c,
              handleGoToPlansList: y
            })
          ]
        });
      case "pause_finished":
        return !L(c) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "qqCDUS"
              })
            }),
            /* @__PURE__ */ t.jsx(yt, {
              pauseOrder: c,
              handleGoToPlansList: y
            })
          ]
        });
      case "edit_finished":
        return !L(c) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "LBzlW2"
              })
            }),
            /* @__PURE__ */ t.jsx(wt, {
              editOrder: c,
              handleGoToPlansList: y
            })
          ]
        });
      case "invalid_email_domain":
        return /* @__PURE__ */ t.jsx(jt, {
          title: n._({
            id: "ISwZlJ"
          }),
          description: n._({
            id: "lwG6FE"
          }),
          showDetails: !1
        });
    }
  }
  I(() => {
    D(F());
  }, [
    A
  ]);
  function F() {
    return A ? "welcome" : "plansList";
  }
  function nt() {
    R(!1), D("plansList");
  }
  function ot() {
    return T === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(p, {
          title: n._({
            id: "SynB8s"
          })
        }),
        /* @__PURE__ */ t.jsx(ht, {
          onAck: nt,
          welcomeSteps: Y
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx("div", {
        className: "mb-20",
        children: U ? /* @__PURE__ */ t.jsx(at, {
          variant: "fullpage"
        }) : z ? u ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            et(),
            tt()
          ]
        }) : /* @__PURE__ */ t.jsx(gt, {
          onPreviewData: V,
          allowRegistration: !1,
          isPartner: !0,
          redirectURL: window.location.href,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !1,
            kycNonCompliant: !1
          }
        }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "SynB8s"
              })
            }),
            /* @__PURE__ */ t.jsx(_t, {
              context: window.location.href
            })
          ]
        })
      })
    });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: ot()
  });
}
export {
  Ut as AssistedPlansList
};
