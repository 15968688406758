import { j as l } from "./jsx-runtime-C8rcB1Lr.js";
import { a as se, d as de, S as ie, c as j, i as ue, M as he, F as me, B as fe, b as ce, r as ve } from "./index.esm-CqWfms5D.js";
import { O as te, P as ee } from "./PurchaseOrderList-BWv-QB2o.js";
import { useContext as re, useState as t, useEffect as I } from "react";
import { F as oe } from "./provider-DKLaKIVD.js";
import { i as J, h as n, z as ge, y as we, C as xe, k as _e, a as je } from "./utils-C2gHLhxV.js";
import { u as pe } from "./useConfiguration-B-lyaBB9.js";
import { r as X } from "./sentry-CstOwvcR.js";
import { i as f } from "./index-CrDCxF0S.js";
import { u as Se } from "./useScrollToBottomRef-CqDzM0dm.js";
import { ErrorPage as Le } from "./ErrorPage.js";
function le({ isGroupInvestment: p, investor: v, mfInvestmentAccount: x }) {
  const { fpapi: O } = re(oe), { amcs: c, tenantConfig: g } = pe(), T = g == null ? void 0 : g.fallbackLogo, M = g == null ? void 0 : g.showSchemeLogo, [B, y] = t("initial"), [H, b] = t(!0), [N, E] = t([]), [_, V] = t([]), [w, C] = t(null), [K, R] = t(!1), [U, z] = t(null), F = Se(U), [A, W] = t(!1), [D, Z] = t(10);
  I(() => {
    Y();
  }, []), I(() => {
    F.hasScrolledToBottom && Q();
  }, [
    F.hasScrolledToBottom
  ]);
  const k = async (e) => {
    const m = [];
    for (const u of e)
      try {
        const s = await O.fpClient.master_data().fetchScheme(u), h = M ? {
          ...s,
          schemeLogo: c[s == null ? void 0 : s.amc_id] || T
        } : {
          ...s
        };
        m.push(h);
      } catch (s) {
        window.debug.error(s), X(s);
      }
    return m;
  }, Q = async () => {
    if (!(D >= _.length) && (W(!0), _.length)) {
      const e = _ == null ? void 0 : _.sort((d, r) => new Date(r == null ? void 0 : r.created_at).valueOf() - new Date(d == null ? void 0 : d.created_at).valueOf()), m = D + 10, u = e == null ? void 0 : e.slice(D, m), s = structuredClone(u);
      Z(m);
      const h = /* @__PURE__ */ new Set();
      u.forEach((d) => h.add(d == null ? void 0 : d.scheme));
      let S = [];
      const L = await k(h);
      S = structuredClone(L), u.forEach((d) => {
        const r = S.find((i) => i.isin === d.scheme);
        r && (d.schemeDetails = r);
      }), C(s[0]), E((d) => d.concat(u)), W(!1);
    }
  }, Y = async () => {
    var m, u;
    if (J(x)) {
      y("no_listing"), b(!1);
      return;
    }
    b(!0);
    let e;
    try {
      e = await O.fpClient.mf_redemptions().fetchAll({
        mf_investment_account: x.id
      }), V(e == null ? void 0 : e.data);
    } catch (s) {
      window.debug.error(s), y("no_listing"), b(!1), X(s);
      return;
    }
    if ((m = e == null ? void 0 : e.data) != null && m.length) {
      const s = (u = e == null ? void 0 : e.data) == null ? void 0 : u.sort((i, o) => new Date(o == null ? void 0 : o.created_at).valueOf() - new Date(i == null ? void 0 : i.created_at).valueOf()), h = s == null ? void 0 : s.slice(0, D), S = structuredClone(h), L = /* @__PURE__ */ new Set();
      h.forEach((i) => L.add(i == null ? void 0 : i.scheme));
      let d = [];
      const r = await k(L);
      d = structuredClone(r), h.forEach((i) => {
        const o = d.find(($) => $.isin === i.scheme);
        o && (i.schemeDetails = o);
      }), C(S[0]), E(h);
    } else
      y("no_listing");
    b(!1);
  }, G = (e) => {
    C(_[e.target.value]);
  }, q = () => /* @__PURE__ */ l.jsxs(l.Fragment, {
    children: [
      N ? /* @__PURE__ */ l.jsx(de, {
        variant: "selectable",
        onChange: G,
        selected: 0,
        children: N.map((e) => {
          var m, u;
          return /* @__PURE__ */ l.jsx(ie, {
            innerRef: z,
            children: /* @__PURE__ */ l.jsxs("div", {
              className: "flex flex-wrap py-2",
              children: [
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-full",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "XTJZw8"
                    }),
                    value: J(e == null ? void 0 : e.schemeDetails) ? "-" : n((m = e == null ? void 0 : e.schemeDetails) == null ? void 0 : m.name),
                    logo: M ? c[(u = e == null ? void 0 : e.schemeDetails) == null ? void 0 : u.amc_id] || T : ""
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: e != null && e.amount ? f._({
                      id: "KqeLJ1"
                    }) : f._({
                      id: "6ssn5V"
                    }),
                    value: e != null && e.amount ? `₹ ${ge(e.amount)}` : e != null && e.units ? e == null ? void 0 : e.units : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "e7LnWq"
                    }),
                    value: e != null && e.state ? /* @__PURE__ */ l.jsx(ue, {
                      variant: "outline",
                      message: `${we(e == null ? void 0 : e.state, e == null ? void 0 : e.object)}`,
                      status: xe(e == null ? void 0 : e.state)
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "1Jcjg6"
                    }),
                    value: e != null && e.created_at ? _e(new Date(e == null ? void 0 : e.created_at), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "cXcPd1"
                    }),
                    value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
                  })
                })
              ]
            })
          }, e == null ? void 0 : e.id);
        })
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {}),
      A ? /* @__PURE__ */ l.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ l.jsx(he, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {}),
      w ? /* @__PURE__ */ l.jsx(me, {
        children: /* @__PURE__ */ l.jsx(fe, {
          variant: "primary",
          fullwidth: !0,
          onClick: (e) => {
            e.preventDefault(), R(!0);
          },
          children: f._({
            id: "f9pW3a"
          })
        })
      }) : null,
      /* @__PURE__ */ l.jsx(ce, {
        variant: "fullPage",
        contentHeightMax: !0,
        title: `${je(w == null ? void 0 : w.id)} order details`,
        isOpen: K,
        toggleModal: () => {
          R(!1);
        },
        children: /* @__PURE__ */ l.jsx(te, {
          transactionId: w == null ? void 0 : w.id,
          isGroupInvestment: p,
          investor: v
        })
      })
    ]
  });
  function P() {
    switch (B) {
      default:
        return q();
      case "initial":
        return q();
      case "no_listing":
        return /* @__PURE__ */ l.jsx(Le, {
          title: f._({
            id: "Dc4+I+"
          }),
          description: f._({
            id: "TrVJ7E"
          }),
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return H ? /* @__PURE__ */ l.jsx(se, {
    variant: "fullpage"
  }) : P();
}
function be({ isGroupInvestment: p, investor: v, mfInvestmentAccount: x }) {
  const { fpapi: O } = re(oe), { amcs: c, tenantConfig: g } = pe(), T = g == null ? void 0 : g.fallbackLogo, M = g == null ? void 0 : g.showSchemeLogo, [B, y] = t("initial"), [H, b] = t(!0), [N, E] = t([]), [_, V] = t([]), [w, C] = t(null), [K, R] = t(!1), [U, z] = t(null), F = Se(U), [A, W] = t(!1), [D, Z] = t(10);
  I(() => {
    Y();
  }, []), I(() => {
    F.hasScrolledToBottom && Q();
  }, [
    F.hasScrolledToBottom
  ]);
  const k = async (e) => {
    const m = [];
    for (const u of e)
      try {
        const s = await O.fpClient.master_data().fetchScheme(u), h = M ? {
          ...s,
          schemeLogo: c[s == null ? void 0 : s.amc_id] || T
        } : {
          ...s
        };
        m.push(h);
      } catch (s) {
        window.debug.error(s), X(s);
      }
    return m;
  }, Q = async () => {
    if (!(D >= _.length) && (W(!0), _.length)) {
      const e = _.sort((d, r) => new Date(r == null ? void 0 : r.created_at).valueOf() - new Date(d == null ? void 0 : d.created_at).valueOf()), m = D + 10, u = e == null ? void 0 : e.slice(D, m), s = structuredClone(u);
      Z(m);
      const h = /* @__PURE__ */ new Set();
      u.forEach((d) => h.add(d == null ? void 0 : d.switch_in_scheme)), u.forEach((d) => h.add(d == null ? void 0 : d.switch_out_scheme));
      let S = [];
      const L = await k(h);
      S = structuredClone(L), u.forEach((d) => {
        const r = S.find((o) => o.isin === d.switch_in_scheme), i = S.find((o) => o.isin === d.switch_out_scheme);
        r && (d.schemeDetails = r), i && (d.switchOutSchemeDetails = i);
      }), C(s[0]), E((d) => d.concat(u)), W(!1);
    }
  }, Y = async () => {
    var m, u;
    if (J(x)) {
      y("no_listing"), b(!1);
      return;
    }
    b(!0);
    let e;
    try {
      e = await O.fpClient.mf_switches().fetchAll({
        mf_investment_account: x.id
      }), V(e == null ? void 0 : e.data);
    } catch (s) {
      window.debug.error(s), y("no_listing"), b(!1), X(s);
      return;
    }
    if ((m = e == null ? void 0 : e.data) != null && m.length) {
      const s = (u = e == null ? void 0 : e.data) == null ? void 0 : u.sort((i, o) => new Date(o == null ? void 0 : o.created_at).valueOf() - new Date(i == null ? void 0 : i.created_at).valueOf()), h = s == null ? void 0 : s.slice(0, D), S = structuredClone(h), L = /* @__PURE__ */ new Set();
      h.forEach((i) => L.add(i == null ? void 0 : i.switch_in_scheme)), h.forEach((i) => L.add(i == null ? void 0 : i.switch_out_scheme));
      let d = [];
      const r = await k(L);
      d = structuredClone(r), h.forEach((i) => {
        const o = d.find((a) => a.isin === i.switch_in_scheme), $ = d.find((a) => a.isin === i.switch_out_scheme);
        o && (i.schemeDetails = o), $ && (i.switchOutSchemeDetails = $);
      }), C(S[0]), E(h);
    } else
      y("no_listing");
    b(!1);
  }, G = (e) => {
    C(_[e.target.value]);
  }, q = () => /* @__PURE__ */ l.jsxs(l.Fragment, {
    children: [
      N.length ? /* @__PURE__ */ l.jsx(de, {
        variant: "selectable",
        onChange: G,
        selected: 0,
        children: N.map((e) => {
          var m, u, s, h;
          return /* @__PURE__ */ l.jsx(ie, {
            innerRef: z,
            children: /* @__PURE__ */ l.jsxs("div", {
              className: "flex flex-wrap py-2",
              children: [
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-full",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "6Xqu8f"
                    }),
                    value: J(e == null ? void 0 : e.switchOutSchemeDetails) ? "-" : n((m = e == null ? void 0 : e.switchOutSchemeDetails) == null ? void 0 : m.name),
                    logo: M ? c[(u = e == null ? void 0 : e.switchOutSchemeDetails) == null ? void 0 : u.amc_id] || T : ""
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-full",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "qAf0Wv"
                    }),
                    value: J(e == null ? void 0 : e.schemeDetails) ? "-" : n((s = e == null ? void 0 : e.schemeDetails) == null ? void 0 : s.name),
                    logo: M ? c[(h = e == null ? void 0 : e.schemeDetails) == null ? void 0 : h.amc_id] || T : ""
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: e != null && e.amount ? f._({
                      id: "KqeLJ1"
                    }) : f._({
                      id: "6ssn5V"
                    }),
                    value: e != null && e.amount ? `₹ ${ge(e == null ? void 0 : e.amount)}` : e != null && e.units ? e == null ? void 0 : e.units : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "e7LnWq"
                    }),
                    value: e != null && e.state ? /* @__PURE__ */ l.jsx(ue, {
                      variant: "outline",
                      message: `${we(e == null ? void 0 : e.state, e == null ? void 0 : e.object)}`,
                      status: xe(e == null ? void 0 : e.state)
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "lJ+6AR"
                    }),
                    value: e != null && e.created_at ? _e(new Date(e == null ? void 0 : e.created_at), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ l.jsx("div", {
                  className: "mb-3 basis-1/2",
                  children: /* @__PURE__ */ l.jsx(j, {
                    label: f._({
                      id: "cXcPd1"
                    }),
                    value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
                  })
                })
              ]
            })
          }, e == null ? void 0 : e.id);
        })
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {}),
      A ? /* @__PURE__ */ l.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ l.jsx(he, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ l.jsx(l.Fragment, {}),
      w ? /* @__PURE__ */ l.jsx(me, {
        children: /* @__PURE__ */ l.jsx(fe, {
          variant: "primary",
          fullwidth: !0,
          onClick: (e) => {
            e.preventDefault(), R(!0);
          },
          children: f._({
            id: "f9pW3a"
          })
        })
      }) : null,
      /* @__PURE__ */ l.jsx(ce, {
        variant: "fullPage",
        contentHeightMax: !0,
        title: `${je(w == null ? void 0 : w.id)} order details`,
        isOpen: K,
        toggleModal: () => {
          R(!1);
        },
        children: /* @__PURE__ */ l.jsx(te, {
          transactionId: w == null ? void 0 : w.id,
          isGroupInvestment: p,
          investor: v
        })
      })
    ]
  });
  function P() {
    switch (B) {
      default:
        return q();
      case "initial":
        return q();
      case "no_listing":
        return /* @__PURE__ */ l.jsx(Le, {
          title: f._({
            id: "tw7tew"
          }),
          description: f._({
            id: "Dsmjl5"
          }),
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return H ? /* @__PURE__ */ l.jsx(se, {
    variant: "fullpage"
  }) : P();
}
function ke({ isGroupInvestment: p, investorProfile: v, mfInvestmentAccount: x, isPartner: O, searchParams: c }) {
  const g = {
    purchase: {
      title: "Purchase",
      content: /* @__PURE__ */ l.jsx(ee, {
        isGroupInvestment: p,
        investor: v,
        mfInvestmentAccount: x,
        isPartner: O,
        searchParams: c
      })
    },
    withdraw: {
      title: "Withdrawal",
      content: /* @__PURE__ */ l.jsx(le, {
        isGroupInvestment: p,
        investor: v,
        mfInvestmentAccount: x,
        searchParams: c
      })
    },
    switch: {
      title: "Switch",
      content: /* @__PURE__ */ l.jsx(be, {
        isGroupInvestment: p,
        investor: v,
        mfInvestmentAccount: x,
        searchParams: c
      })
    }
  };
  return /* @__PURE__ */ l.jsx(ve, {
    selected: 0,
    group: p ? [
      {
        title: "Purchase",
        content: /* @__PURE__ */ l.jsx(ee, {
          isGroupInvestment: p,
          investor: v,
          mfInvestmentAccount: x,
          searchParams: c
        })
      },
      {
        title: "Withdrawal",
        content: /* @__PURE__ */ l.jsx(le, {
          isGroupInvestment: p,
          investor: v,
          mfInvestmentAccount: x,
          searchParams: c
        })
      }
    ] : c != null && c.type ? [
      g[c == null ? void 0 : c.type]
    ] : Object.values(g)
  });
}
export {
  ke as O
};
