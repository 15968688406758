import { useContext } from "react";

import { ConfigurationContext } from "fpapps/ConfigurationContext";
import { NotFoundPage } from "fpapps/NotFoundPage";

function LandingPage() {
  const { tenantConfig } = useContext(ConfigurationContext);
  const {
    paymentOrderId,
    statusOfPurchaseOrder,
    statusOfRedemptionOrder,
    statusOfGroupPurchaseOrder,
    mandateId,
  } = tenantConfig?.app_constants?.landingPageOrderIds;

  const test_isin = tenantConfig?.test_isin;
  const test_employer = tenantConfig?.groupInvestment?.test_employer;
  const disabledLinks = `cursor-not-allowed text-neutral-60 dark:text-neutral-40 text-size-base`;

  const TENANT_ENV = process.env?.REACT_APP_TENANT_ENV;
  const isProduction = TENANT_ENV === "production";

  return isProduction ? (
    <NotFoundPage />
  ) : (
    <>
      <h3 className="mb-2">Digital Native Experiences</h3>
      <section className="mb-5">
        <h4 className="pb-2">Investor Workflows</h4>
        <p>
          <a href={`/checkout/?isin=${test_isin}&mtm_campaign=staging`}>Invest</a>
        </p>
        <p>
          <a href={`/profile-details`}>Investor Profile</a>
        </p>
        <p>
          <a href={`/withdraw`}>Withdraw</a>
        </p>
        <p>
          <a
            href={`/group-checkout?isin=${test_isin}&employer=${test_employer}&mtm_campaign=staging`}
          >
            Group Checkout
          </a>
        </p>
        <p>
          <a href={`/payment?order_id=${paymentOrderId}`}>Make payment for an investment order</a>
        </p>
        <p>
          <a href="/statement/">Account Statement</a>
        </p>
        <p>
          <a href="/portfolio/">Portfolio</a>
        </p>
        <p>
          <a href="/bank-accounts/">Manage Bank Accounts</a>
        </p>
        <p>
          <a href="/nominees/"> Manage Nominees</a>
        </p>
        <p>
          <a href="/mandates/">Manage Mandates</a>
        </p>
        <p>
          <a href={`/status/purchase?id=${statusOfPurchaseOrder}`}>Status of a purchase order</a>{" "}
        </p>
        <p>
          <a href={`/status/withdrawal?id=${statusOfRedemptionOrder}`}>
            Status of a withdrawal order
          </a>{" "}
        </p>
        <p>
          <a href={`/status/group-investment/purchase?id=${statusOfGroupPurchaseOrder}`}>
            Status of a group investment purchase order
          </a>{" "}
        </p>
        <p>
          <a href={`/status/mandate?id=${mandateId}`}>Status of a mandate</a>{" "}
        </p>
        <p>
          <a href="/folios/">Folios list w/ details</a>{" "}
        </p>
        <p>
          <a href="/plans-list"> List of all SIPs/STPs/SWPs</a>
        </p>
        <p>
          <a href="/order-history">Order history</a>
        </p>
        <p>
          <a href="/kyc">KYC</a>
        </p>
        <p className={disabledLinks}>Add/Change Nominee</p>
      </section>
      {tenantConfig?.invoke_partner_flow && (
        <section className="mb-5">
          <h4 className="pb-2">Partner Workflows</h4>
          <p>
            <a href={`/assisted-checkout?isin=${test_isin}&mtm_campaign=staging`}>
              Assisted purchase
            </a>
          </p>
          <p>
            <a href={`/assisted-withdraw?isin=${test_isin}&mtm_campaign=staging`}>
              Assisted withdraw
            </a>
          </p>
          <p>
            <a href={`/assisted-portfolio?isPartner=true&mtm_campaign=staging`}>
              Partner portfolio
            </a>
          </p>
          <p>
            <a href={`/assisted-profile-details`}>Assisted Investor Profile</a>
          </p>
          <p>
            <a href={`/assisted-mandates?isPartner=true&mtm_campaign=staging`}>Assisted mandates</a>
          </p>
          <p>
            <a href={`/assisted-order-history?isPartner=true&mtm_campaign=staging`}>
              Assisted order history
            </a>
          </p>
          <p>
            <a href={`/assisted-plans-list?isPartner=true&mtm_campaign=staging`}>
              Assisted plans list
            </a>
          </p>

          <p className={disabledLinks}>Send performance report</p>
        </section>
      )}

      <section className="mb-5">
        <h4 className="pb-2">Tenant (Ops Team) Workflows</h4>
        <p className={disabledLinks}>Get License</p>
        <p className={disabledLinks}>AMC Empanelment</p>
        <p className={disabledLinks}>Starmf signup</p>
        <p className={disabledLinks}>RTA signup</p>
        <p>
          <a href="/order-failure-codes">Add order failure code</a>
        </p>
        <p>
          <a href={"/onboard/customer"}>Onboard new customer</a>
        </p>
        <p>
          <a href={"/fp-account"}>Manage FP account</a>
        </p>
        <p>
          <a href={"/notification-webhook"}>Manage FP Notifications</a>
        </p>
      </section>

      <section className="mb-5">
        <h4 className="pb-2">Employer Workflows</h4>
        <p>
          <a href={"/employer-onboarding"}>Onboard employer</a>
        </p>
      </section>

      <section className="mb-5">
        <h4 className="pb-2">Tenant (Marketing Team) Workflows</h4>
        <p className={disabledLinks}>Start a marketing campaign</p>
        <p className={disabledLinks}>Review Campaign Impact</p>
      </section>

      <section className="mb-5">
        <h4 className="pb-2">Tenant (Investor Support Team) Workflows</h4>
        <p className={disabledLinks}>Investor Overview</p>
        <p className={disabledLinks}>Order Overview</p>
      </section>
    </>
  );
}

export default LandingPage;
