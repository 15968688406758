import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as i, useState as r, useEffect as p } from "react";
import { AppContext as S } from "./AppContext.js";
import { F as k } from "./provider-DKLaKIVD.js";
import { LoginContext as W } from "./LoginContext.js";
import { P as F, a as y } from "./index.esm-CqWfms5D.js";
import { i as o } from "./index-CrDCxF0S.js";
import { W as L } from "./Welcome-Bz7XIwKh.js";
import { A as R } from "./Auth-CJPsuV-i.js";
import { M as v } from "./MandateListing-DpfjvUeo.js";
import { P as I } from "./PartnerLogin-D3m53h1t.js";
function Q() {
  const { fpapi: a } = i(k), { loggedInPartner: d } = i(W), { AppState: { showWelcomePage: l }, setShowWelcomePage: u } = i(S), [g, c] = r(f), [w, n] = r(!1), [s, x] = r(null), [P, h] = r(null), j = [
    {
      step: 1,
      content: o._({
        id: "wtcwqs"
      })
    },
    {
      step: 2,
      content: o._({
        id: "i1ucdK"
      })
    },
    {
      step: 3,
      content: o._({
        id: "d4wYsU"
      })
    },
    {
      step: 4,
      content: o._({
        id: "xU5OS9"
      })
    },
    {
      step: 5,
      content: o._({
        id: "ltQaxv"
      })
    },
    {
      step: 6,
      content: o._({
        id: "k4RKPZ"
      })
    }
  ];
  p(() => {
    c(f());
  }, [
    l
  ]), p(() => {
    a && s && (n(!0), a.fpClient.investor_profiles().fetchAll({
      pan: s
    }).then((e) => {
      const m = e == null ? void 0 : e.data[0];
      if (!m) {
        n(!1);
        return;
      }
      h(m), n(!1);
    }).catch((e) => {
      window.debug.error(e), n(!1);
    }));
  }, [
    s
  ]);
  function f() {
    return l ? "welcome" : "mandate";
  }
  function A(e) {
    u(!1), c("mandate");
  }
  const C = ({ pan: e }) => {
    x(e);
  };
  function _() {
    return g === "welcome" ? /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx(L, {
        onAck: A,
        welcomeSteps: j
      })
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: w ? /* @__PURE__ */ t.jsx(y, {
        variant: "fullpage"
      }) : d ? s ? /* @__PURE__ */ t.jsx(v, {
        status: [
          ""
        ],
        limit: null,
        isPartner: !0,
        investorProfile: P
      }) : /* @__PURE__ */ t.jsx(R, {
        onPreviewData: C,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0,
          kycCompliant: !1,
          kycNonCompliant: !1
        },
        isPartner: !0,
        redirectURL: window.location.href
      }) : /* @__PURE__ */ t.jsx(I, {
        context: window.location.href
      })
    });
  }
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(F, {
        title: o._({
          id: "pyiBwy"
        })
      }),
      _()
    ]
  });
}
export {
  Q as AssistedMandates
};
