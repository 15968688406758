import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { createContext as l, useState as n, useEffect as c } from "react";
const i = /* @__PURE__ */ l({}), v = ({ children: t }) => {
  const [e, o] = n({
    label: "English",
    value: "en"
  });
  c(() => {
    localStorage.setItem("locale", JSON.stringify(e));
  }, [
    e
  ]);
  const r = (a) => {
    o(a);
  };
  return /* @__PURE__ */ s.jsx(i.Provider, {
    value: {
      locale: e,
      updateLocale: r
    },
    children: t
  }, e == null ? void 0 : e.value);
};
export {
  i as LocaleProviderContext,
  v as LocalizationProvider
};
