import { j as B } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as j, useRef as fe, useEffect as V, useMemo as we } from "react";
import { C as me } from "./core-C1RIj6f4.js";
import { g as L, t as Se, a as ve } from "./index.esm-CqWfms5D.js";
import { a as ye, b as be, r as ke, o as Ee, A as Re } from "./jwt-decode.esm-DQGXhP4o.js";
import { r as G } from "./sentry-CstOwvcR.js";
var Te = ye();
const xe = /* @__PURE__ */ L(Te);
var Ie = be();
const Q = /* @__PURE__ */ L(Ie);
var ee = { exports: {} };
(function(e, t) {
  (function(s, i) {
    e.exports = i(ke());
  })(Se, function(s) {
    return s.enc.Utf8;
  });
})(ee);
var Ue = ee.exports;
const Ce = /* @__PURE__ */ L(Ue);
var Pe = {
  debug: () => {
  },
  info: () => {
  },
  warn: () => {
  },
  error: () => {
  }
}, v, y, A = /* @__PURE__ */ ((e) => (e[e.NONE = 0] = "NONE", e[e.ERROR = 1] = "ERROR", e[e.WARN = 2] = "WARN", e[e.INFO = 3] = "INFO", e[e.DEBUG = 4] = "DEBUG", e))(A || {});
((e) => {
  function t() {
    v = 3, y = Pe;
  }
  e.reset = t;
  function s(r) {
    if (!(0 <= r && r <= 4))
      throw new Error("Invalid log level");
    v = r;
  }
  e.setLevel = s;
  function i(r) {
    y = r;
  }
  e.setLogger = i;
})(A || (A = {}));
var l = class {
  constructor(e) {
    this._name = e;
  }
  debug(...e) {
    v >= 4 && y.debug(l._format(this._name, this._method), ...e);
  }
  info(...e) {
    v >= 3 && y.info(l._format(this._name, this._method), ...e);
  }
  warn(...e) {
    v >= 2 && y.warn(l._format(this._name, this._method), ...e);
  }
  error(...e) {
    v >= 1 && y.error(l._format(this._name, this._method), ...e);
  }
  throw(e) {
    throw this.error(e), e;
  }
  create(e) {
    const t = Object.create(this);
    return t._method = e, t.debug("begin"), t;
  }
  static createStatic(e, t) {
    const s = new l(`${e}.${t}`);
    return s.debug("begin"), s;
  }
  static _format(e, t) {
    const s = `[${e}]`;
    return t ? `${s} ${t}:` : s;
  }
  // helpers for static class methods
  static debug(e, ...t) {
    v >= 4 && y.debug(l._format(e), ...t);
  }
  static info(e, ...t) {
    v >= 3 && y.info(l._format(e), ...t);
  }
  static warn(e, ...t) {
    v >= 2 && y.warn(l._format(e), ...t);
  }
  static error(e, ...t) {
    v >= 1 && y.error(l._format(e), ...t);
  }
};
A.reset();
var Oe = "10000000-1000-4000-8000-100000000000", b = class {
  static _randomWord() {
    return me.lib.WordArray.random(1).words[0];
  }
  /**
   * Generates RFC4122 version 4 guid
   */
  static generateUUIDv4() {
    return Oe.replace(
      /[018]/g,
      (t) => (+t ^ b._randomWord() & 15 >> +t / 4).toString(16)
    ).replace(/-/g, "");
  }
  /**
   * PKCE: Generate a code verifier
   */
  static generateCodeVerifier() {
    return b.generateUUIDv4() + b.generateUUIDv4() + b.generateUUIDv4();
  }
  /**
   * PKCE: Generate a code challenge
   */
  static generateCodeChallenge(e) {
    try {
      const t = xe(e);
      return Q.stringify(t).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    } catch (t) {
      throw l.error("CryptoUtils.generateCodeChallenge", t), t;
    }
  }
  /**
   * Generates a base64-encoded string for a basic auth header
   */
  static generateBasicAuth(e, t) {
    const s = Ce.parse([e, t].join(":"));
    return Q.stringify(s);
  }
}, E = class {
  constructor(e) {
    this._name = e, this._logger = new l(`Event('${this._name}')`), this._callbacks = [];
  }
  addHandler(e) {
    return this._callbacks.push(e), () => this.removeHandler(e);
  }
  removeHandler(e) {
    const t = this._callbacks.lastIndexOf(e);
    t >= 0 && this._callbacks.splice(t, 1);
  }
  raise(...e) {
    this._logger.debug("raise:", ...e);
    for (const t of this._callbacks)
      t(...e);
  }
}, W = class {
  // IMPORTANT: doesn't validate the token
  static decode(e) {
    try {
      return Ee(e);
    } catch (t) {
      throw l.error("JwtUtils.decode", t), t;
    }
  }
}, X = class {
  /**
   * Populates a map of window features with a placement centered in front of
   * the current window. If no explicit width is given, a default value is
   * binned into [800, 720, 600, 480, 360] based on the current window's width.
   */
  static center({ ...e }) {
    var t, s, i;
    return e.width == null && (e.width = (t = [800, 720, 600, 480].find((r) => r <= window.outerWidth / 1.618)) != null ? t : 360), (s = e.left) != null || (e.left = Math.max(0, Math.round(window.screenX + (window.outerWidth - e.width) / 2))), e.height != null && ((i = e.top) != null || (e.top = Math.max(0, Math.round(window.screenY + (window.outerHeight - e.height) / 2)))), e;
  }
  static serialize(e) {
    return Object.entries(e).filter(([, t]) => t != null).map(([t, s]) => `${t}=${typeof s != "boolean" ? s : s ? "yes" : "no"}`).join(",");
  }
}, p = class extends E {
  constructor() {
    super(...arguments), this._logger = new l(`Timer('${this._name}')`), this._timerHandle = null, this._expiration = 0, this._callback = () => {
      const e = this._expiration - p.getEpochTime();
      this._logger.debug("timer completes in", e), this._expiration <= p.getEpochTime() && (this.cancel(), super.raise());
    };
  }
  // get the time
  static getEpochTime() {
    return Math.floor(Date.now() / 1e3);
  }
  init(e) {
    const t = this._logger.create("init");
    e = Math.max(Math.floor(e), 1);
    const s = p.getEpochTime() + e;
    if (this.expiration === s && this._timerHandle) {
      t.debug("skipping since already initialized for expiration at", this.expiration);
      return;
    }
    this.cancel(), t.debug("using duration", e), this._expiration = s;
    const i = Math.min(e, 5);
    this._timerHandle = setInterval(this._callback, i * 1e3);
  }
  get expiration() {
    return this._expiration;
  }
  cancel() {
    this._logger.create("cancel"), this._timerHandle && (clearInterval(this._timerHandle), this._timerHandle = null);
  }
}, F = class {
  static readParams(e, t = "query") {
    if (!e)
      throw new TypeError("Invalid URL");
    const i = new URL(e, "http://127.0.0.1")[t === "fragment" ? "hash" : "search"];
    return new URLSearchParams(i.slice(1));
  }
}, x = class extends Error {
  constructor(e, t) {
    var s, i, r;
    if (super(e.error_description || e.error || ""), this.form = t, this.name = "ErrorResponse", !e.error)
      throw l.error("ErrorResponse", "No error passed"), new Error("No error passed");
    this.error = e.error, this.error_description = (s = e.error_description) != null ? s : null, this.error_uri = (i = e.error_uri) != null ? i : null, this.state = e.userState, this.session_state = (r = e.session_state) != null ? r : null;
  }
}, D = class extends Error {
  constructor(e) {
    super(e), this.name = "ErrorTimeout";
  }
}, Ae = class {
  constructor(e) {
    this._logger = new l("AccessTokenEvents"), this._expiringTimer = new p("Access token expiring"), this._expiredTimer = new p("Access token expired"), this._expiringNotificationTimeInSeconds = e.expiringNotificationTimeInSeconds;
  }
  load(e) {
    const t = this._logger.create("load");
    if (e.access_token && e.expires_in !== void 0) {
      const s = e.expires_in;
      if (t.debug("access token present, remaining duration:", s), s > 0) {
        let r = s - this._expiringNotificationTimeInSeconds;
        r <= 0 && (r = 1), t.debug("registering expiring timer, raising in", r, "seconds"), this._expiringTimer.init(r);
      } else
        t.debug("canceling existing expiring timer because we're past expiration."), this._expiringTimer.cancel();
      const i = s + 1;
      t.debug("registering expired timer, raising in", i, "seconds"), this._expiredTimer.init(i);
    } else
      this._expiringTimer.cancel(), this._expiredTimer.cancel();
  }
  unload() {
    this._logger.debug("unload: canceling existing access token timers"), this._expiringTimer.cancel(), this._expiredTimer.cancel();
  }
  /**
   * Add callback: Raised prior to the access token expiring.
   */
  addAccessTokenExpiring(e) {
    return this._expiringTimer.addHandler(e);
  }
  /**
   * Remove callback: Raised prior to the access token expiring.
   */
  removeAccessTokenExpiring(e) {
    this._expiringTimer.removeHandler(e);
  }
  /**
   * Add callback: Raised after the access token has expired.
   */
  addAccessTokenExpired(e) {
    return this._expiredTimer.addHandler(e);
  }
  /**
   * Remove callback: Raised after the access token has expired.
   */
  removeAccessTokenExpired(e) {
    this._expiredTimer.removeHandler(e);
  }
}, qe = class {
  constructor(e, t, s, i, r) {
    this._callback = e, this._client_id = t, this._intervalInSeconds = i, this._stopOnError = r, this._logger = new l("CheckSessionIFrame"), this._timer = null, this._session_state = null, this._message = (o) => {
      o.origin === this._frame_origin && o.source === this._frame.contentWindow && (o.data === "error" ? (this._logger.error("error message from check session op iframe"), this._stopOnError && this.stop()) : o.data === "changed" ? (this._logger.debug("changed message from check session op iframe"), this.stop(), this._callback()) : this._logger.debug(o.data + " message from check session op iframe"));
    };
    const n = new URL(s);
    this._frame_origin = n.origin, this._frame = window.document.createElement("iframe"), this._frame.style.visibility = "hidden", this._frame.style.position = "fixed", this._frame.style.left = "-1000px", this._frame.style.top = "0", this._frame.width = "0", this._frame.height = "0", this._frame.src = n.href;
  }
  load() {
    return new Promise((e) => {
      this._frame.onload = () => {
        e();
      }, window.document.body.appendChild(this._frame), window.addEventListener("message", this._message, !1);
    });
  }
  start(e) {
    if (this._session_state === e)
      return;
    this._logger.create("start"), this.stop(), this._session_state = e;
    const t = () => {
      !this._frame.contentWindow || !this._session_state || this._frame.contentWindow.postMessage(this._client_id + " " + this._session_state, this._frame_origin);
    };
    t(), this._timer = setInterval(t, this._intervalInSeconds * 1e3);
  }
  stop() {
    this._logger.create("stop"), this._session_state = null, this._timer && (clearInterval(this._timer), this._timer = null);
  }
}, te = class {
  constructor() {
    this._logger = new l("InMemoryWebStorage"), this._data = {};
  }
  clear() {
    this._logger.create("clear"), this._data = {};
  }
  getItem(e) {
    return this._logger.create(`getItem('${e}')`), this._data[e];
  }
  setItem(e, t) {
    this._logger.create(`setItem('${e}')`), this._data[e] = t;
  }
  removeItem(e) {
    this._logger.create(`removeItem('${e}')`), delete this._data[e];
  }
  get length() {
    return Object.getOwnPropertyNames(this._data).length;
  }
  key(e) {
    return Object.getOwnPropertyNames(this._data)[e];
  }
}, J = class {
  constructor(e = [], t = null, s = {}) {
    this._jwtHandler = t, this._extraHeaders = s, this._logger = new l("JsonService"), this._contentTypes = [], this._contentTypes.push(...e, "application/json"), t && this._contentTypes.push("application/jwt");
  }
  async fetchWithTimeout(e, t = {}) {
    const { timeoutInSeconds: s, ...i } = t;
    if (!s)
      return await fetch(e, i);
    const r = new AbortController(), n = setTimeout(() => r.abort(), s * 1e3);
    try {
      return await fetch(e, {
        ...t,
        signal: r.signal
      });
    } catch (o) {
      throw o instanceof DOMException && o.name === "AbortError" ? new D("Network timed out") : o;
    } finally {
      clearTimeout(n);
    }
  }
  async getJson(e, {
    token: t,
    credentials: s
  } = {}) {
    const i = this._logger.create("getJson"), r = {
      Accept: this._contentTypes.join(", ")
    };
    t && (i.debug("token passed, setting Authorization header"), r.Authorization = "Bearer " + t), this.appendExtraHeaders(r);
    let n;
    try {
      i.debug("url:", e), n = await this.fetchWithTimeout(e, { method: "GET", headers: r, credentials: s });
    } catch (c) {
      throw i.error("Network Error"), c;
    }
    i.debug("HTTP response received, status", n.status);
    const o = n.headers.get("Content-Type");
    if (o && !this._contentTypes.find((c) => o.startsWith(c)) && i.throw(new Error(`Invalid response Content-Type: ${o ?? "undefined"}, from URL: ${e}`)), n.ok && this._jwtHandler && (o != null && o.startsWith("application/jwt")))
      return await this._jwtHandler(await n.text());
    let a;
    try {
      a = await n.json();
    } catch (c) {
      throw i.error("Error parsing JSON response", c), n.ok ? c : new Error(`${n.statusText} (${n.status})`);
    }
    if (!n.ok)
      throw i.error("Error from server:", a), a.error ? new x(a) : new Error(`${n.statusText} (${n.status}): ${JSON.stringify(a)}`);
    return a;
  }
  async postForm(e, {
    body: t,
    basicAuth: s,
    timeoutInSeconds: i,
    initCredentials: r
  }) {
    const n = this._logger.create("postForm"), o = {
      Accept: this._contentTypes.join(", "),
      "Content-Type": "application/x-www-form-urlencoded"
    };
    s !== void 0 && (o.Authorization = "Basic " + s), this.appendExtraHeaders(o);
    let a;
    try {
      n.debug("url:", e), a = await this.fetchWithTimeout(e, { method: "POST", headers: o, body: t, timeoutInSeconds: i, credentials: r });
    } catch (d) {
      throw n.error("Network error"), d;
    }
    n.debug("HTTP response received, status", a.status);
    const c = a.headers.get("Content-Type");
    if (c && !this._contentTypes.find((d) => c.startsWith(d)))
      throw new Error(`Invalid response Content-Type: ${c ?? "undefined"}, from URL: ${e}`);
    const g = await a.text();
    let h = {};
    if (g)
      try {
        h = JSON.parse(g);
      } catch (d) {
        throw n.error("Error parsing JSON response", d), a.ok ? d : new Error(`${a.statusText} (${a.status})`);
      }
    if (!a.ok)
      throw n.error("Error from server:", h), h.error ? new x(h, t) : new Error(`${a.statusText} (${a.status}): ${JSON.stringify(h)}`);
    return h;
  }
  appendExtraHeaders(e) {
    const t = this._logger.create("appendExtraHeaders"), s = Object.keys(this._extraHeaders), i = [
      "authorization",
      "accept",
      "content-type"
    ];
    s.length !== 0 && s.forEach((r) => {
      if (i.includes(r.toLocaleLowerCase())) {
        t.warn("Protected header could not be overridden", r, i);
        return;
      }
      const n = typeof this._extraHeaders[r] == "function" ? this._extraHeaders[r]() : this._extraHeaders[r];
      n && n !== "" && (e[r] = n);
    });
  }
}, Ne = class {
  constructor(e) {
    this._settings = e, this._logger = new l("MetadataService"), this._signingKeys = null, this._metadata = null, this._metadataUrl = this._settings.metadataUrl, this._jsonService = new J(
      ["application/jwk-set+json"],
      null,
      this._settings.extraHeaders
    ), this._settings.signingKeys && (this._logger.debug("using signingKeys from settings"), this._signingKeys = this._settings.signingKeys), this._settings.metadata && (this._logger.debug("using metadata from settings"), this._metadata = this._settings.metadata), this._settings.fetchRequestCredentials && (this._logger.debug("using fetchRequestCredentials from settings"), this._fetchRequestCredentials = this._settings.fetchRequestCredentials);
  }
  resetSigningKeys() {
    this._signingKeys = null;
  }
  async getMetadata() {
    const e = this._logger.create("getMetadata");
    if (this._metadata)
      return e.debug("using cached values"), this._metadata;
    if (!this._metadataUrl)
      throw e.throw(new Error("No authority or metadataUrl configured on settings")), null;
    e.debug("getting metadata from", this._metadataUrl);
    const t = await this._jsonService.getJson(this._metadataUrl, { credentials: this._fetchRequestCredentials });
    return e.debug("merging remote JSON with seed metadata"), this._metadata = Object.assign({}, this._settings.metadataSeed, t), this._metadata;
  }
  getIssuer() {
    return this._getMetadataProperty("issuer");
  }
  getAuthorizationEndpoint() {
    return this._getMetadataProperty("authorization_endpoint");
  }
  getUserInfoEndpoint() {
    return this._getMetadataProperty("userinfo_endpoint");
  }
  getTokenEndpoint(e = !0) {
    return this._getMetadataProperty("token_endpoint", e);
  }
  getCheckSessionIframe() {
    return this._getMetadataProperty("check_session_iframe", !0);
  }
  getEndSessionEndpoint() {
    return this._getMetadataProperty("end_session_endpoint", !0);
  }
  getRevocationEndpoint(e = !0) {
    return this._getMetadataProperty("revocation_endpoint", e);
  }
  getKeysEndpoint(e = !0) {
    return this._getMetadataProperty("jwks_uri", e);
  }
  async _getMetadataProperty(e, t = !1) {
    const s = this._logger.create(`_getMetadataProperty('${e}')`), i = await this.getMetadata();
    if (s.debug("resolved"), i[e] === void 0) {
      if (t === !0) {
        s.warn("Metadata does not contain optional property");
        return;
      }
      s.throw(new Error("Metadata does not contain property " + e));
    }
    return i[e];
  }
  async getSigningKeys() {
    const e = this._logger.create("getSigningKeys");
    if (this._signingKeys)
      return e.debug("returning signingKeys from cache"), this._signingKeys;
    const t = await this.getKeysEndpoint(!1);
    e.debug("got jwks_uri", t);
    const s = await this._jsonService.getJson(t);
    if (e.debug("got key set", s), !Array.isArray(s.keys))
      throw e.throw(new Error("Missing keys on keyset")), null;
    return this._signingKeys = s.keys, this._signingKeys;
  }
}, se = class {
  constructor({
    prefix: e = "oidc.",
    store: t = localStorage
  } = {}) {
    this._logger = new l("WebStorageStateStore"), this._store = t, this._prefix = e;
  }
  async set(e, t) {
    this._logger.create(`set('${e}')`), e = this._prefix + e, await this._store.setItem(e, t);
  }
  async get(e) {
    return this._logger.create(`get('${e}')`), e = this._prefix + e, await this._store.getItem(e);
  }
  async remove(e) {
    this._logger.create(`remove('${e}')`), e = this._prefix + e;
    const t = await this._store.getItem(e);
    return await this._store.removeItem(e), t;
  }
  async getAllKeys() {
    this._logger.create("getAllKeys");
    const e = await this._store.length, t = [];
    for (let s = 0; s < e; s++) {
      const i = await this._store.key(s);
      i && i.indexOf(this._prefix) === 0 && t.push(i.substr(this._prefix.length));
    }
    return t;
  }
}, Me = "code", je = "openid", He = "client_secret_post", We = "query", Fe = 60 * 15, $e = 60 * 5, ie = class {
  constructor({
    // metadata related
    authority: e,
    metadataUrl: t,
    metadata: s,
    signingKeys: i,
    metadataSeed: r,
    // client related
    client_id: n,
    client_secret: o,
    response_type: a = Me,
    scope: c = je,
    redirect_uri: g,
    post_logout_redirect_uri: h,
    client_authentication: d = He,
    // optional protocol
    prompt: _,
    display: m,
    max_age: S,
    ui_locales: f,
    acr_values: R,
    resource: u,
    response_mode: k = We,
    // behavior flags
    filterProtocolClaims: w = !0,
    loadUserInfo: P = !1,
    staleStateAgeInSeconds: I = Fe,
    clockSkewInSeconds: U = $e,
    userInfoJwtIssuer: T = "OP",
    mergeClaims: q = !1,
    disablePKCE: le = !1,
    // other behavior
    stateStore: z,
    refreshTokenCredentials: N,
    revokeTokenAdditionalContentTypes: de,
    fetchRequestCredentials: M,
    refreshTokenAllowedScope: ge,
    // extra
    extraQueryParams: he = {},
    extraTokenParams: ue = {},
    extraHeaders: _e = {}
  }) {
    if (this.authority = e, t ? this.metadataUrl = t : (this.metadataUrl = e, e && (this.metadataUrl.endsWith("/") || (this.metadataUrl += "/"), this.metadataUrl += ".well-known/openid-configuration")), this.metadata = s, this.metadataSeed = r, this.signingKeys = i, this.client_id = n, this.client_secret = o, this.response_type = a, this.scope = c, this.redirect_uri = g, this.post_logout_redirect_uri = h, this.client_authentication = d, this.prompt = _, this.display = m, this.max_age = S, this.ui_locales = f, this.acr_values = R, this.resource = u, this.response_mode = k, this.filterProtocolClaims = w ?? !0, this.loadUserInfo = !!P, this.staleStateAgeInSeconds = I, this.clockSkewInSeconds = U, this.userInfoJwtIssuer = T, this.mergeClaims = !!q, this.disablePKCE = !!le, this.revokeTokenAdditionalContentTypes = de, M && N && console.warn("Both fetchRequestCredentials and refreshTokenCredentials is set. Only fetchRequestCredentials will be used."), this.fetchRequestCredentials = M || N || "same-origin", z)
      this.stateStore = z;
    else {
      const pe = typeof window < "u" ? window.localStorage : new te();
      this.stateStore = new se({ store: pe });
    }
    this.refreshTokenAllowedScope = ge, this.extraQueryParams = he, this.extraTokenParams = ue, this.extraHeaders = _e;
  }
}, Le = class {
  constructor(e, t) {
    this._settings = e, this._metadataService = t, this._logger = new l("UserInfoService"), this._getClaimsFromJwt = async (s) => {
      const i = this._logger.create("_getClaimsFromJwt");
      try {
        const r = W.decode(s);
        return i.debug("JWT decoding successful"), r;
      } catch (r) {
        throw i.error("Error parsing JWT response"), r;
      }
    }, this._jsonService = new J(
      void 0,
      this._getClaimsFromJwt,
      this._settings.extraHeaders
    );
  }
  async getClaims(e) {
    const t = this._logger.create("getClaims");
    e || this._logger.throw(new Error("No token passed"));
    const s = await this._metadataService.getUserInfoEndpoint();
    t.debug("got userinfo url", s);
    const i = await this._jsonService.getJson(s, {
      token: e,
      credentials: this._settings.fetchRequestCredentials
    });
    return t.debug("got claims", i), i;
  }
}, re = class {
  constructor(e, t) {
    this._settings = e, this._metadataService = t, this._logger = new l("TokenClient"), this._jsonService = new J(
      this._settings.revokeTokenAdditionalContentTypes,
      null,
      this._settings.extraHeaders
    );
  }
  /**
   * Exchange code.
   *
   * @see https://www.rfc-editor.org/rfc/rfc6749#section-4.1.3
   */
  async exchangeCode({
    grant_type: e = "authorization_code",
    redirect_uri: t = this._settings.redirect_uri,
    client_id: s = this._settings.client_id,
    client_secret: i = this._settings.client_secret,
    ...r
  }) {
    const n = this._logger.create("exchangeCode");
    s || n.throw(new Error("A client_id is required")), t || n.throw(new Error("A redirect_uri is required")), r.code || n.throw(new Error("A code is required"));
    const o = new URLSearchParams({ grant_type: e, redirect_uri: t });
    for (const [h, d] of Object.entries(r))
      d != null && o.set(h, d);
    let a;
    switch (this._settings.client_authentication) {
      case "client_secret_basic":
        if (!i)
          throw n.throw(new Error("A client_secret is required")), null;
        a = b.generateBasicAuth(s, i);
        break;
      case "client_secret_post":
        o.append("client_id", s), i && o.append("client_secret", i);
        break;
    }
    const c = await this._metadataService.getTokenEndpoint(!1);
    n.debug("got token endpoint");
    const g = await this._jsonService.postForm(c, { body: o, basicAuth: a, initCredentials: this._settings.fetchRequestCredentials });
    return n.debug("got response"), g;
  }
  /**
   * Exchange credentials.
   *
   * @see https://www.rfc-editor.org/rfc/rfc6749#section-4.3.2
   */
  async exchangeCredentials({
    grant_type: e = "password",
    client_id: t = this._settings.client_id,
    client_secret: s = this._settings.client_secret,
    scope: i = this._settings.scope,
    ...r
  }) {
    const n = this._logger.create("exchangeCredentials");
    t || n.throw(new Error("A client_id is required"));
    const o = new URLSearchParams({ grant_type: e, scope: i });
    for (const [h, d] of Object.entries(r))
      d != null && o.set(h, d);
    let a;
    switch (this._settings.client_authentication) {
      case "client_secret_basic":
        if (!s)
          throw n.throw(new Error("A client_secret is required")), null;
        a = b.generateBasicAuth(t, s);
        break;
      case "client_secret_post":
        o.append("client_id", t), s && o.append("client_secret", s);
        break;
    }
    const c = await this._metadataService.getTokenEndpoint(!1);
    n.debug("got token endpoint");
    const g = await this._jsonService.postForm(c, { body: o, basicAuth: a, initCredentials: this._settings.fetchRequestCredentials });
    return n.debug("got response"), g;
  }
  /**
   * Exchange a refresh token.
   *
   * @see https://www.rfc-editor.org/rfc/rfc6749#section-6
   */
  async exchangeRefreshToken({
    grant_type: e = "refresh_token",
    client_id: t = this._settings.client_id,
    client_secret: s = this._settings.client_secret,
    timeoutInSeconds: i,
    ...r
  }) {
    const n = this._logger.create("exchangeRefreshToken");
    t || n.throw(new Error("A client_id is required")), r.refresh_token || n.throw(new Error("A refresh_token is required"));
    const o = new URLSearchParams({ grant_type: e });
    for (const [h, d] of Object.entries(r))
      d != null && o.set(h, d);
    let a;
    switch (this._settings.client_authentication) {
      case "client_secret_basic":
        if (!s)
          throw n.throw(new Error("A client_secret is required")), null;
        a = b.generateBasicAuth(t, s);
        break;
      case "client_secret_post":
        o.append("client_id", t), s && o.append("client_secret", s);
        break;
    }
    const c = await this._metadataService.getTokenEndpoint(!1);
    n.debug("got token endpoint");
    const g = await this._jsonService.postForm(c, { body: o, basicAuth: a, timeoutInSeconds: i, initCredentials: this._settings.fetchRequestCredentials });
    return n.debug("got response"), g;
  }
  /**
   * Revoke an access or refresh token.
   *
   * @see https://datatracker.ietf.org/doc/html/rfc7009#section-2.1
   */
  async revoke(e) {
    var t;
    const s = this._logger.create("revoke");
    e.token || s.throw(new Error("A token is required"));
    const i = await this._metadataService.getRevocationEndpoint(!1);
    s.debug(`got revocation endpoint, revoking ${(t = e.token_type_hint) != null ? t : "default token type"}`);
    const r = new URLSearchParams();
    for (const [n, o] of Object.entries(e))
      o != null && r.set(n, o);
    r.set("client_id", this._settings.client_id), this._settings.client_secret && r.set("client_secret", this._settings.client_secret), await this._jsonService.postForm(i, { body: r }), s.debug("got response");
  }
}, De = class {
  constructor(e, t, s) {
    this._settings = e, this._metadataService = t, this._claimsService = s, this._logger = new l("ResponseValidator"), this._userInfoService = new Le(this._settings, this._metadataService), this._tokenClient = new re(this._settings, this._metadataService);
  }
  async validateSigninResponse(e, t) {
    const s = this._logger.create("validateSigninResponse");
    this._processSigninState(e, t), s.debug("state processed"), await this._processCode(e, t), s.debug("code processed"), e.isOpenId && this._validateIdTokenAttributes(e), s.debug("tokens validated"), await this._processClaims(e, t == null ? void 0 : t.skipUserInfo, e.isOpenId), s.debug("claims processed");
  }
  async validateCredentialsResponse(e, t) {
    const s = this._logger.create("validateCredentialsResponse");
    e.isOpenId && this._validateIdTokenAttributes(e), s.debug("tokens validated"), await this._processClaims(e, t, e.isOpenId), s.debug("claims processed");
  }
  async validateRefreshResponse(e, t) {
    var s, i;
    const r = this._logger.create("validateRefreshResponse");
    e.userState = t.data, (s = e.session_state) != null || (e.session_state = t.session_state), (i = e.scope) != null || (e.scope = t.scope), e.isOpenId && e.id_token && (this._validateIdTokenAttributes(e, t.id_token), r.debug("ID Token validated")), e.id_token || (e.id_token = t.id_token, e.profile = t.profile);
    const n = e.isOpenId && !!e.id_token;
    await this._processClaims(e, !1, n), r.debug("claims processed");
  }
  validateSignoutResponse(e, t) {
    const s = this._logger.create("validateSignoutResponse");
    if (t.id !== e.state && s.throw(new Error("State does not match")), s.debug("state validated"), e.userState = t.data, e.error)
      throw s.warn("Response was error", e.error), new x(e);
  }
  _processSigninState(e, t) {
    var s;
    const i = this._logger.create("_processSigninState");
    if (t.id !== e.state && i.throw(new Error("State does not match")), t.client_id || i.throw(new Error("No client_id on state")), t.authority || i.throw(new Error("No authority on state")), this._settings.authority !== t.authority && i.throw(new Error("authority mismatch on settings vs. signin state")), this._settings.client_id && this._settings.client_id !== t.client_id && i.throw(new Error("client_id mismatch on settings vs. signin state")), i.debug("state validated"), e.userState = t.data, (s = e.scope) != null || (e.scope = t.scope), e.error)
      throw i.warn("Response was error", e.error), new x(e);
    t.code_verifier && !e.code && i.throw(new Error("Expected code in response"));
  }
  async _processClaims(e, t = !1, s = !0) {
    const i = this._logger.create("_processClaims");
    if (e.profile = this._claimsService.filterProtocolClaims(e.profile), t || !this._settings.loadUserInfo || !e.access_token) {
      i.debug("not loading user info");
      return;
    }
    i.debug("loading user info");
    const r = await this._userInfoService.getClaims(e.access_token);
    i.debug("user info claims received from user info endpoint"), s && r.sub !== e.profile.sub && i.throw(new Error("subject from UserInfo response does not match subject in ID Token")), e.profile = this._claimsService.mergeClaims(e.profile, this._claimsService.filterProtocolClaims(r)), i.debug("user info claims received, updated profile:", e.profile);
  }
  async _processCode(e, t) {
    const s = this._logger.create("_processCode");
    if (e.code) {
      s.debug("Validating code");
      const i = await this._tokenClient.exchangeCode({
        client_id: t.client_id,
        client_secret: t.client_secret,
        code: e.code,
        redirect_uri: t.redirect_uri,
        code_verifier: t.code_verifier,
        ...t.extraTokenParams
      });
      Object.assign(e, i);
    } else
      s.debug("No code to process");
  }
  _validateIdTokenAttributes(e, t) {
    var s;
    const i = this._logger.create("_validateIdTokenAttributes");
    i.debug("decoding ID Token JWT");
    const r = W.decode((s = e.id_token) != null ? s : "");
    if (r.sub || i.throw(new Error("ID Token is missing a subject claim")), t) {
      const n = W.decode(t);
      r.sub !== n.sub && i.throw(new Error("sub in id_token does not match current sub")), r.auth_time && r.auth_time !== n.auth_time && i.throw(new Error("auth_time in id_token does not match original auth_time")), r.azp && r.azp !== n.azp && i.throw(new Error("azp in id_token does not match original azp")), !r.azp && n.azp && i.throw(new Error("azp not in id_token, but present in original id_token"));
    }
    e.profile = r;
  }
}, C = class {
  constructor(e) {
    this.id = e.id || b.generateUUIDv4(), this.data = e.data, e.created && e.created > 0 ? this.created = e.created : this.created = p.getEpochTime(), this.request_type = e.request_type;
  }
  toStorageString() {
    return new l("State").create("toStorageString"), JSON.stringify({
      id: this.id,
      data: this.data,
      created: this.created,
      request_type: this.request_type
    });
  }
  static fromStorageString(e) {
    return l.createStatic("State", "fromStorageString"), new C(JSON.parse(e));
  }
  static async clearStaleState(e, t) {
    const s = l.createStatic("State", "clearStaleState"), i = p.getEpochTime() - t, r = await e.getAllKeys();
    s.debug("got keys", r);
    for (let n = 0; n < r.length; n++) {
      const o = r[n], a = await e.get(o);
      let c = !1;
      if (a)
        try {
          const g = C.fromStorageString(a);
          s.debug("got item from key:", o, g.created), g.created <= i && (c = !0);
        } catch (g) {
          s.error("Error parsing state for key:", o, g), c = !0;
        }
      else
        s.debug("no item in storage for key:", o), c = !0;
      c && (s.debug("removed item for key:", o), e.remove(o));
    }
  }
}, K = class extends C {
  constructor(e) {
    super(e), e.code_verifier === !0 ? this.code_verifier = b.generateCodeVerifier() : e.code_verifier && (this.code_verifier = e.code_verifier), this.code_verifier && (this.code_challenge = b.generateCodeChallenge(this.code_verifier)), this.authority = e.authority, this.client_id = e.client_id, this.redirect_uri = e.redirect_uri, this.scope = e.scope, this.client_secret = e.client_secret, this.extraTokenParams = e.extraTokenParams, this.response_mode = e.response_mode, this.skipUserInfo = e.skipUserInfo;
  }
  toStorageString() {
    return new l("SigninState").create("toStorageString"), JSON.stringify({
      id: this.id,
      data: this.data,
      created: this.created,
      request_type: this.request_type,
      code_verifier: this.code_verifier,
      authority: this.authority,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      scope: this.scope,
      client_secret: this.client_secret,
      extraTokenParams: this.extraTokenParams,
      response_mode: this.response_mode,
      skipUserInfo: this.skipUserInfo
    });
  }
  static fromStorageString(e) {
    l.createStatic("SigninState", "fromStorageString");
    const t = JSON.parse(e);
    return new K(t);
  }
}, Je = class {
  constructor({
    // mandatory
    url: e,
    authority: t,
    client_id: s,
    redirect_uri: i,
    response_type: r,
    scope: n,
    // optional
    state_data: o,
    response_mode: a,
    request_type: c,
    client_secret: g,
    nonce: h,
    resource: d,
    skipUserInfo: _,
    extraQueryParams: m,
    extraTokenParams: S,
    disablePKCE: f,
    ...R
  }) {
    if (this._logger = new l("SigninRequest"), !e)
      throw this._logger.error("ctor: No url passed"), new Error("url");
    if (!s)
      throw this._logger.error("ctor: No client_id passed"), new Error("client_id");
    if (!i)
      throw this._logger.error("ctor: No redirect_uri passed"), new Error("redirect_uri");
    if (!r)
      throw this._logger.error("ctor: No response_type passed"), new Error("response_type");
    if (!n)
      throw this._logger.error("ctor: No scope passed"), new Error("scope");
    if (!t)
      throw this._logger.error("ctor: No authority passed"), new Error("authority");
    this.state = new K({
      data: o,
      request_type: c,
      code_verifier: !f,
      client_id: s,
      authority: t,
      redirect_uri: i,
      response_mode: a,
      client_secret: g,
      scope: n,
      extraTokenParams: S,
      skipUserInfo: _
    });
    const u = new URL(e);
    u.searchParams.append("client_id", s), u.searchParams.append("redirect_uri", i), u.searchParams.append("response_type", r), u.searchParams.append("scope", n), h && u.searchParams.append("nonce", h), u.searchParams.append("state", this.state.id), this.state.code_challenge && (u.searchParams.append("code_challenge", this.state.code_challenge), u.searchParams.append("code_challenge_method", "S256")), d && (Array.isArray(d) ? d : [d]).forEach((w) => u.searchParams.append("resource", w));
    for (const [k, w] of Object.entries({ response_mode: a, ...R, ...m }))
      w != null && u.searchParams.append(k, w.toString());
    this.url = u.href;
  }
}, Ke = "openid", H = class {
  constructor(e) {
    this.access_token = "", this.token_type = "", this.profile = {}, this.state = e.get("state"), this.session_state = e.get("session_state"), this.error = e.get("error"), this.error_description = e.get("error_description"), this.error_uri = e.get("error_uri"), this.code = e.get("code");
  }
  get expires_in() {
    if (this.expires_at !== void 0)
      return this.expires_at - p.getEpochTime();
  }
  set expires_in(e) {
    typeof e == "string" && (e = Number(e)), e !== void 0 && e >= 0 && (this.expires_at = Math.floor(e) + p.getEpochTime());
  }
  get isOpenId() {
    var e;
    return ((e = this.scope) == null ? void 0 : e.split(" ").includes(Ke)) || !!this.id_token;
  }
}, ze = class {
  constructor({
    url: e,
    state_data: t,
    id_token_hint: s,
    post_logout_redirect_uri: i,
    extraQueryParams: r,
    request_type: n
  }) {
    if (this._logger = new l("SignoutRequest"), !e)
      throw this._logger.error("ctor: No url passed"), new Error("url");
    const o = new URL(e);
    s && o.searchParams.append("id_token_hint", s), i && (o.searchParams.append("post_logout_redirect_uri", i), t && (this.state = new C({ data: t, request_type: n }), o.searchParams.append("state", this.state.id)));
    for (const [a, c] of Object.entries({ ...r }))
      c != null && o.searchParams.append(a, c.toString());
    this.url = o.href;
  }
}, Be = class {
  constructor(e) {
    this.state = e.get("state"), this.error = e.get("error"), this.error_description = e.get("error_description"), this.error_uri = e.get("error_uri");
  }
}, Ve = [
  "nbf",
  "jti",
  "auth_time",
  "nonce",
  "acr",
  "amr",
  "azp",
  "at_hash"
  // https://openid.net/specs/openid-connect-core-1_0.html#CodeIDToken
], Ge = ["sub", "iss", "aud", "exp", "iat"], Qe = class {
  constructor(e) {
    this._settings = e, this._logger = new l("ClaimsService");
  }
  filterProtocolClaims(e) {
    const t = { ...e };
    if (this._settings.filterProtocolClaims) {
      let s;
      Array.isArray(this._settings.filterProtocolClaims) ? s = this._settings.filterProtocolClaims : s = Ve;
      for (const i of s)
        Ge.includes(i) || delete t[i];
    }
    return t;
  }
  mergeClaims(e, t) {
    const s = { ...e };
    for (const [i, r] of Object.entries(t))
      for (const n of Array.isArray(r) ? r : [r]) {
        const o = s[i];
        o ? Array.isArray(o) ? o.includes(n) || o.push(n) : s[i] !== n && (typeof n == "object" && this._settings.mergeClaims ? s[i] = this.mergeClaims(o, n) : s[i] = [o, n]) : s[i] = n;
      }
    return s;
  }
}, Xe = class {
  constructor(e) {
    this._logger = new l("OidcClient"), this.settings = new ie(e), this.metadataService = new Ne(this.settings), this._claimsService = new Qe(this.settings), this._validator = new De(this.settings, this.metadataService, this._claimsService), this._tokenClient = new re(this.settings, this.metadataService);
  }
  async createSigninRequest({
    state: e,
    request: t,
    request_uri: s,
    request_type: i,
    id_token_hint: r,
    login_hint: n,
    skipUserInfo: o,
    nonce: a,
    response_type: c = this.settings.response_type,
    scope: g = this.settings.scope,
    redirect_uri: h = this.settings.redirect_uri,
    prompt: d = this.settings.prompt,
    display: _ = this.settings.display,
    max_age: m = this.settings.max_age,
    ui_locales: S = this.settings.ui_locales,
    acr_values: f = this.settings.acr_values,
    resource: R = this.settings.resource,
    response_mode: u = this.settings.response_mode,
    extraQueryParams: k = this.settings.extraQueryParams,
    extraTokenParams: w = this.settings.extraTokenParams
  }) {
    const P = this._logger.create("createSigninRequest");
    if (c !== "code")
      throw new Error("Only the Authorization Code flow (with PKCE) is supported");
    const I = await this.metadataService.getAuthorizationEndpoint();
    P.debug("Received authorization endpoint", I);
    const U = new Je({
      url: I,
      authority: this.settings.authority,
      client_id: this.settings.client_id,
      redirect_uri: h,
      response_type: c,
      scope: g,
      state_data: e,
      prompt: d,
      display: _,
      max_age: m,
      ui_locales: S,
      id_token_hint: r,
      login_hint: n,
      acr_values: f,
      resource: R,
      request: t,
      request_uri: s,
      extraQueryParams: k,
      extraTokenParams: w,
      request_type: i,
      response_mode: u,
      client_secret: this.settings.client_secret,
      skipUserInfo: o,
      nonce: a,
      disablePKCE: this.settings.disablePKCE
    });
    await this.clearStaleState();
    const T = U.state;
    return await this.settings.stateStore.set(T.id, T.toStorageString()), U;
  }
  async readSigninResponseState(e, t = !1) {
    const s = this._logger.create("readSigninResponseState"), i = new H(F.readParams(e, this.settings.response_mode));
    if (!i.state)
      throw s.throw(new Error("No state in response")), null;
    const r = await this.settings.stateStore[t ? "remove" : "get"](i.state);
    if (!r)
      throw s.throw(new Error("No matching state found in storage")), null;
    return { state: K.fromStorageString(r), response: i };
  }
  async processSigninResponse(e) {
    const t = this._logger.create("processSigninResponse"), { state: s, response: i } = await this.readSigninResponseState(e, !0);
    return t.debug("received state from storage; validating response"), await this._validator.validateSigninResponse(i, s), i;
  }
  async processResourceOwnerPasswordCredentials({
    username: e,
    password: t,
    skipUserInfo: s = !1,
    extraTokenParams: i = {}
  }) {
    const r = await this._tokenClient.exchangeCredentials({ username: e, password: t, ...i }), n = new H(new URLSearchParams());
    return Object.assign(n, r), await this._validator.validateCredentialsResponse(n, s), n;
  }
  async useRefreshToken({
    state: e,
    timeoutInSeconds: t
  }) {
    var s;
    const i = this._logger.create("useRefreshToken");
    let r;
    if (this.settings.refreshTokenAllowedScope === void 0)
      r = e.scope;
    else {
      const a = this.settings.refreshTokenAllowedScope.split(" ");
      r = (((s = e.scope) == null ? void 0 : s.split(" ")) || []).filter((g) => a.includes(g)).join(" ");
    }
    const n = await this._tokenClient.exchangeRefreshToken({
      refresh_token: e.refresh_token,
      // provide the (possible filtered) scope list
      scope: r,
      timeoutInSeconds: t
    }), o = new H(new URLSearchParams());
    return Object.assign(o, n), i.debug("validating response", o), await this._validator.validateRefreshResponse(o, {
      ...e,
      // overide the scope in the state handed over to the validator
      // so it can set the granted scope to the requested scope in case none is included in the response
      scope: r
    }), o;
  }
  async createSignoutRequest({
    state: e,
    id_token_hint: t,
    request_type: s,
    post_logout_redirect_uri: i = this.settings.post_logout_redirect_uri,
    extraQueryParams: r = this.settings.extraQueryParams
  } = {}) {
    const n = this._logger.create("createSignoutRequest"), o = await this.metadataService.getEndSessionEndpoint();
    if (!o)
      throw n.throw(new Error("No end session endpoint")), null;
    n.debug("Received end session endpoint", o);
    const a = new ze({
      url: o,
      id_token_hint: t,
      post_logout_redirect_uri: i,
      state_data: e,
      extraQueryParams: r,
      request_type: s
    });
    await this.clearStaleState();
    const c = a.state;
    return c && (n.debug("Signout request has state to persist"), await this.settings.stateStore.set(c.id, c.toStorageString())), a;
  }
  async readSignoutResponseState(e, t = !1) {
    const s = this._logger.create("readSignoutResponseState"), i = new Be(F.readParams(e, this.settings.response_mode));
    if (!i.state) {
      if (s.debug("No state in response"), i.error)
        throw s.warn("Response was error:", i.error), new x(i);
      return { state: void 0, response: i };
    }
    const r = await this.settings.stateStore[t ? "remove" : "get"](i.state);
    if (!r)
      throw s.throw(new Error("No matching state found in storage")), null;
    return { state: C.fromStorageString(r), response: i };
  }
  async processSignoutResponse(e) {
    const t = this._logger.create("processSignoutResponse"), { state: s, response: i } = await this.readSignoutResponseState(e, !0);
    return s ? (t.debug("Received state from storage; validating response"), this._validator.validateSignoutResponse(i, s)) : t.debug("No state from storage; skipping response validation"), i;
  }
  clearStaleState() {
    return this._logger.create("clearStaleState"), C.clearStaleState(this.settings.stateStore, this.settings.staleStateAgeInSeconds);
  }
  async revokeToken(e, t) {
    return this._logger.create("revokeToken"), await this._tokenClient.revoke({
      token: e,
      token_type_hint: t
    });
  }
}, Ye = class {
  constructor(e) {
    this._userManager = e, this._logger = new l("SessionMonitor"), this._start = async (t) => {
      const s = t.session_state;
      if (!s)
        return;
      const i = this._logger.create("_start");
      if (t.profile ? (this._sub = t.profile.sub, this._sid = t.profile.sid, i.debug("session_state", s, ", sub", this._sub)) : (this._sub = void 0, this._sid = void 0, i.debug("session_state", s, ", anonymous user")), this._checkSessionIFrame) {
        this._checkSessionIFrame.start(s);
        return;
      }
      try {
        const r = await this._userManager.metadataService.getCheckSessionIframe();
        if (r) {
          i.debug("initializing check session iframe");
          const n = this._userManager.settings.client_id, o = this._userManager.settings.checkSessionIntervalInSeconds, a = this._userManager.settings.stopCheckSessionOnError, c = new qe(this._callback, n, r, o, a);
          await c.load(), this._checkSessionIFrame = c, c.start(s);
        } else
          i.warn("no check session iframe found in the metadata");
      } catch (r) {
        i.error("Error from getCheckSessionIframe:", r instanceof Error ? r.message : r);
      }
    }, this._stop = () => {
      const t = this._logger.create("_stop");
      if (this._sub = void 0, this._sid = void 0, this._checkSessionIFrame && this._checkSessionIFrame.stop(), this._userManager.settings.monitorAnonymousSession) {
        const s = setInterval(async () => {
          clearInterval(s);
          try {
            const i = await this._userManager.querySessionStatus();
            if (i) {
              const r = {
                session_state: i.session_state,
                profile: i.sub && i.sid ? {
                  sub: i.sub,
                  sid: i.sid
                } : null
              };
              this._start(r);
            }
          } catch (i) {
            t.error("error from querySessionStatus", i instanceof Error ? i.message : i);
          }
        }, 1e3);
      }
    }, this._callback = async () => {
      const t = this._logger.create("_callback");
      try {
        const s = await this._userManager.querySessionStatus();
        let i = !0;
        s && this._checkSessionIFrame ? s.sub === this._sub ? (i = !1, this._checkSessionIFrame.start(s.session_state), s.sid === this._sid ? t.debug("same sub still logged in at OP, restarting check session iframe; session_state", s.session_state) : (t.debug("same sub still logged in at OP, session state has changed, restarting check session iframe; session_state", s.session_state), this._userManager.events._raiseUserSessionChanged())) : t.debug("different subject signed into OP", s.sub) : t.debug("subject no longer signed into OP"), i ? this._sub ? this._userManager.events._raiseUserSignedOut() : this._userManager.events._raiseUserSignedIn() : t.debug("no change in session detected, no event to raise");
      } catch (s) {
        this._sub && (t.debug("Error calling queryCurrentSigninSession; raising signed out event", s), this._userManager.events._raiseUserSignedOut());
      }
    }, e || this._logger.throw(new Error("No user manager passed")), this._userManager.events.addUserLoaded(this._start), this._userManager.events.addUserUnloaded(this._stop), this._init().catch((t) => {
      this._logger.error(t);
    });
  }
  async _init() {
    this._logger.create("_init");
    const e = await this._userManager.getUser();
    if (e)
      this._start(e);
    else if (this._userManager.settings.monitorAnonymousSession) {
      const t = await this._userManager.querySessionStatus();
      if (t) {
        const s = {
          session_state: t.session_state,
          profile: t.sub && t.sid ? {
            sub: t.sub,
            sid: t.sid
          } : null
        };
        this._start(s);
      }
    }
  }
}, O = class {
  constructor(e) {
    var t;
    this.id_token = e.id_token, this.session_state = (t = e.session_state) != null ? t : null, this.access_token = e.access_token, this.refresh_token = e.refresh_token, this.token_type = e.token_type, this.scope = e.scope, this.profile = e.profile, this.expires_at = e.expires_at, this.state = e.userState;
  }
  /** Computed number of seconds the access token has remaining. */
  get expires_in() {
    if (this.expires_at !== void 0)
      return this.expires_at - p.getEpochTime();
  }
  set expires_in(e) {
    e !== void 0 && (this.expires_at = Math.floor(e) + p.getEpochTime());
  }
  /** Computed value indicating if the access token is expired. */
  get expired() {
    const e = this.expires_in;
    if (e !== void 0)
      return e <= 0;
  }
  /** Array representing the parsed values from the `scope`. */
  get scopes() {
    var e, t;
    return (t = (e = this.scope) == null ? void 0 : e.split(" ")) != null ? t : [];
  }
  toStorageString() {
    return new l("User").create("toStorageString"), JSON.stringify({
      id_token: this.id_token,
      session_state: this.session_state,
      access_token: this.access_token,
      refresh_token: this.refresh_token,
      token_type: this.token_type,
      scope: this.scope,
      profile: this.profile,
      expires_at: this.expires_at
    });
  }
  static fromStorageString(e) {
    return l.createStatic("User", "fromStorageString"), new O(JSON.parse(e));
  }
}, Y = "oidc-client", ne = class {
  constructor() {
    this._abort = new E("Window navigation aborted"), this._disposeHandlers = /* @__PURE__ */ new Set(), this._window = null;
  }
  async navigate(e) {
    const t = this._logger.create("navigate");
    if (!this._window)
      throw new Error("Attempted to navigate on a disposed window");
    t.debug("setting URL in window"), this._window.location.replace(e.url);
    const { url: s, keepOpen: i } = await new Promise((r, n) => {
      const o = (a) => {
        var c;
        const g = a.data, h = (c = e.scriptOrigin) != null ? c : window.location.origin;
        if (!(a.origin !== h || (g == null ? void 0 : g.source) !== Y)) {
          try {
            const d = F.readParams(g.url, e.response_mode).get("state");
            if (d || t.warn("no state found in response url"), a.source !== this._window && d !== e.state)
              return;
          } catch {
            this._dispose(), n(new Error("Invalid response from window"));
          }
          r(g);
        }
      };
      window.addEventListener("message", o, !1), this._disposeHandlers.add(() => window.removeEventListener("message", o, !1)), this._disposeHandlers.add(this._abort.addHandler((a) => {
        this._dispose(), n(a);
      }));
    });
    return t.debug("got response from window"), this._dispose(), i || this.close(), { url: s };
  }
  _dispose() {
    this._logger.create("_dispose");
    for (const e of this._disposeHandlers)
      e();
    this._disposeHandlers.clear();
  }
  static _notifyParent(e, t, s = !1, i = window.location.origin) {
    e.postMessage({
      source: Y,
      url: t,
      keepOpen: s
    }, i);
  }
}, oe = {
  location: !1,
  toolbar: !1,
  height: 640
}, ae = "_blank", Ze = 60, et = 2, ce = 10, tt = class extends ie {
  constructor(e) {
    const {
      popup_redirect_uri: t = e.redirect_uri,
      popup_post_logout_redirect_uri: s = e.post_logout_redirect_uri,
      popupWindowFeatures: i = oe,
      popupWindowTarget: r = ae,
      redirectMethod: n = "assign",
      redirectTarget: o = "self",
      iframeNotifyParentOrigin: a = e.iframeNotifyParentOrigin,
      iframeScriptOrigin: c = e.iframeScriptOrigin,
      silent_redirect_uri: g = e.redirect_uri,
      silentRequestTimeoutInSeconds: h = ce,
      automaticSilentRenew: d = !0,
      validateSubOnSilentRenew: _ = !0,
      includeIdTokenInSilentRenew: m = !1,
      monitorSession: S = !1,
      monitorAnonymousSession: f = !1,
      checkSessionIntervalInSeconds: R = et,
      query_status_response_type: u = "code",
      stopCheckSessionOnError: k = !0,
      revokeTokenTypes: w = ["access_token", "refresh_token"],
      revokeTokensOnSignout: P = !1,
      includeIdTokenInSilentSignout: I = !1,
      accessTokenExpiringNotificationTimeInSeconds: U = Ze,
      userStore: T
    } = e;
    if (super(e), this.popup_redirect_uri = t, this.popup_post_logout_redirect_uri = s, this.popupWindowFeatures = i, this.popupWindowTarget = r, this.redirectMethod = n, this.redirectTarget = o, this.iframeNotifyParentOrigin = a, this.iframeScriptOrigin = c, this.silent_redirect_uri = g, this.silentRequestTimeoutInSeconds = h, this.automaticSilentRenew = d, this.validateSubOnSilentRenew = _, this.includeIdTokenInSilentRenew = m, this.monitorSession = S, this.monitorAnonymousSession = f, this.checkSessionIntervalInSeconds = R, this.stopCheckSessionOnError = k, this.query_status_response_type = u, this.revokeTokenTypes = w, this.revokeTokensOnSignout = P, this.includeIdTokenInSilentSignout = I, this.accessTokenExpiringNotificationTimeInSeconds = U, T)
      this.userStore = T;
    else {
      const q = typeof window < "u" ? window.sessionStorage : new te();
      this.userStore = new se({ store: q });
    }
  }
}, $ = class extends ne {
  constructor({
    silentRequestTimeoutInSeconds: e = ce
  }) {
    super(), this._logger = new l("IFrameWindow"), this._timeoutInSeconds = e, this._frame = $.createHiddenIframe(), this._window = this._frame.contentWindow;
  }
  static createHiddenIframe() {
    const e = window.document.createElement("iframe");
    return e.style.visibility = "hidden", e.style.position = "fixed", e.style.left = "-1000px", e.style.top = "0", e.width = "0", e.height = "0", e.setAttribute("sandbox", "allow-scripts allow-same-origin allow-forms"), window.document.body.appendChild(e), e;
  }
  async navigate(e) {
    this._logger.debug("navigate: Using timeout of:", this._timeoutInSeconds);
    const t = setTimeout(() => this._abort.raise(new D("IFrame timed out without a response")), this._timeoutInSeconds * 1e3);
    return this._disposeHandlers.add(() => clearTimeout(t)), await super.navigate(e);
  }
  close() {
    var e;
    this._frame && (this._frame.parentNode && (this._frame.addEventListener("load", (t) => {
      var s;
      const i = t.target;
      (s = i.parentNode) == null || s.removeChild(i), this._abort.raise(new Error("IFrame removed from DOM"));
    }, !0), (e = this._frame.contentWindow) == null || e.location.replace("about:blank")), this._frame = null), this._window = null;
  }
  static notifyParent(e, t) {
    return super._notifyParent(window.parent, e, !1, t);
  }
}, st = class {
  constructor(e) {
    this._settings = e, this._logger = new l("IFrameNavigator");
  }
  async prepare({
    silentRequestTimeoutInSeconds: e = this._settings.silentRequestTimeoutInSeconds
  }) {
    return new $({ silentRequestTimeoutInSeconds: e });
  }
  async callback(e) {
    this._logger.create("callback"), $.notifyParent(e, this._settings.iframeNotifyParentOrigin);
  }
}, it = 500, Z = class extends ne {
  constructor({
    popupWindowTarget: e = ae,
    popupWindowFeatures: t = {}
  }) {
    super(), this._logger = new l("PopupWindow");
    const s = X.center({ ...oe, ...t });
    this._window = window.open(void 0, e, X.serialize(s));
  }
  async navigate(e) {
    var t;
    (t = this._window) == null || t.focus();
    const s = setInterval(() => {
      (!this._window || this._window.closed) && this._abort.raise(new Error("Popup closed by user"));
    }, it);
    return this._disposeHandlers.add(() => clearInterval(s)), await super.navigate(e);
  }
  close() {
    this._window && (this._window.closed || (this._window.close(), this._abort.raise(new Error("Popup closed")))), this._window = null;
  }
  static notifyOpener(e, t) {
    if (!window.opener)
      throw new Error("No window.opener. Can't complete notification.");
    return super._notifyParent(window.opener, e, t);
  }
}, rt = class {
  constructor(e) {
    this._settings = e, this._logger = new l("PopupNavigator");
  }
  async prepare({
    popupWindowFeatures: e = this._settings.popupWindowFeatures,
    popupWindowTarget: t = this._settings.popupWindowTarget
  }) {
    return new Z({ popupWindowFeatures: e, popupWindowTarget: t });
  }
  async callback(e, t = !1) {
    this._logger.create("callback"), Z.notifyOpener(e, t);
  }
}, nt = class {
  constructor(e) {
    this._settings = e, this._logger = new l("RedirectNavigator");
  }
  async prepare({
    redirectMethod: e = this._settings.redirectMethod,
    redirectTarget: t = this._settings.redirectTarget
  }) {
    var s;
    this._logger.create("prepare");
    let i = window.self;
    t === "top" && (i = (s = window.top) != null ? s : window.self);
    const r = i.location[e].bind(i.location);
    let n;
    return {
      navigate: async (o) => {
        this._logger.create("navigate");
        const a = new Promise((c, g) => {
          n = g;
        });
        return r(o.url), await a;
      },
      close: () => {
        this._logger.create("close"), n == null || n(new Error("Redirect aborted")), i.stop();
      }
    };
  }
}, ot = class extends Ae {
  constructor(e) {
    super({ expiringNotificationTimeInSeconds: e.accessTokenExpiringNotificationTimeInSeconds }), this._logger = new l("UserManagerEvents"), this._userLoaded = new E("User loaded"), this._userUnloaded = new E("User unloaded"), this._silentRenewError = new E("Silent renew error"), this._userSignedIn = new E("User signed in"), this._userSignedOut = new E("User signed out"), this._userSessionChanged = new E("User session changed");
  }
  load(e, t = !0) {
    super.load(e), t && this._userLoaded.raise(e);
  }
  unload() {
    super.unload(), this._userUnloaded.raise();
  }
  /**
   * Add callback: Raised when a user session has been established (or re-established).
   */
  addUserLoaded(e) {
    return this._userLoaded.addHandler(e);
  }
  /**
   * Remove callback: Raised when a user session has been established (or re-established).
   */
  removeUserLoaded(e) {
    return this._userLoaded.removeHandler(e);
  }
  /**
   * Add callback: Raised when a user session has been terminated.
   */
  addUserUnloaded(e) {
    return this._userUnloaded.addHandler(e);
  }
  /**
   * Remove callback: Raised when a user session has been terminated.
   */
  removeUserUnloaded(e) {
    return this._userUnloaded.removeHandler(e);
  }
  /**
   * Add callback: Raised when the automatic silent renew has failed.
   */
  addSilentRenewError(e) {
    return this._silentRenewError.addHandler(e);
  }
  /**
   * Remove callback: Raised when the automatic silent renew has failed.
   */
  removeSilentRenewError(e) {
    return this._silentRenewError.removeHandler(e);
  }
  /**
   * @internal
   */
  _raiseSilentRenewError(e) {
    this._silentRenewError.raise(e);
  }
  /**
   * Add callback: Raised when the user is signed in (when `monitorSession` is set).
   * @see {@link UserManagerSettings.monitorSession}
   */
  addUserSignedIn(e) {
    return this._userSignedIn.addHandler(e);
  }
  /**
   * Remove callback: Raised when the user is signed in (when `monitorSession` is set).
   */
  removeUserSignedIn(e) {
    this._userSignedIn.removeHandler(e);
  }
  /**
   * @internal
   */
  _raiseUserSignedIn() {
    this._userSignedIn.raise();
  }
  /**
   * Add callback: Raised when the user's sign-in status at the OP has changed (when `monitorSession` is set).
   * @see {@link UserManagerSettings.monitorSession}
   */
  addUserSignedOut(e) {
    return this._userSignedOut.addHandler(e);
  }
  /**
   * Remove callback: Raised when the user's sign-in status at the OP has changed (when `monitorSession` is set).
   */
  removeUserSignedOut(e) {
    this._userSignedOut.removeHandler(e);
  }
  /**
   * @internal
   */
  _raiseUserSignedOut() {
    this._userSignedOut.raise();
  }
  /**
   * Add callback: Raised when the user session changed (when `monitorSession` is set).
   * @see {@link UserManagerSettings.monitorSession}
   */
  addUserSessionChanged(e) {
    return this._userSessionChanged.addHandler(e);
  }
  /**
   * Remove callback: Raised when the user session changed (when `monitorSession` is set).
   */
  removeUserSessionChanged(e) {
    this._userSessionChanged.removeHandler(e);
  }
  /**
   * @internal
   */
  _raiseUserSessionChanged() {
    this._userSessionChanged.raise();
  }
}, at = class {
  constructor(e) {
    this._userManager = e, this._logger = new l("SilentRenewService"), this._isStarted = !1, this._retryTimer = new p("Retry Silent Renew"), this._tokenExpiring = async () => {
      const t = this._logger.create("_tokenExpiring");
      try {
        await this._userManager.signinSilent(), t.debug("silent token renewal successful");
      } catch (s) {
        if (s instanceof D) {
          t.warn("ErrorTimeout from signinSilent:", s, "retry in 5s"), this._retryTimer.init(5);
          return;
        }
        t.error("Error from signinSilent:", s), this._userManager.events._raiseSilentRenewError(s);
      }
    };
  }
  async start() {
    const e = this._logger.create("start");
    if (!this._isStarted) {
      this._isStarted = !0, this._userManager.events.addAccessTokenExpiring(this._tokenExpiring), this._retryTimer.addHandler(this._tokenExpiring);
      try {
        await this._userManager.getUser();
      } catch (t) {
        e.error("getUser error", t);
      }
    }
  }
  stop() {
    this._isStarted && (this._retryTimer.cancel(), this._retryTimer.removeHandler(this._tokenExpiring), this._userManager.events.removeAccessTokenExpiring(this._tokenExpiring), this._isStarted = !1);
  }
}, ct = class {
  constructor(e) {
    this.refresh_token = e.refresh_token, this.id_token = e.id_token, this.session_state = e.session_state, this.scope = e.scope, this.profile = e.profile, this.data = e.state;
  }
}, lt = class {
  constructor(e) {
    this._logger = new l("UserManager"), this.settings = new tt(e), this._client = new Xe(e), this._redirectNavigator = new nt(this.settings), this._popupNavigator = new rt(this.settings), this._iframeNavigator = new st(this.settings), this._events = new ot(this.settings), this._silentRenewService = new at(this), this.settings.automaticSilentRenew && this.startSilentRenew(), this._sessionMonitor = null, this.settings.monitorSession && (this._sessionMonitor = new Ye(this));
  }
  /** Returns an object used to register for events raised by the `UserManager`. */
  get events() {
    return this._events;
  }
  /** Returns an object used to access the metadata configuration of the OIDC provider. */
  get metadataService() {
    return this._client.metadataService;
  }
  /**
   * Returns promise to load the `User` object for the currently authenticated user.
   */
  async getUser() {
    const e = this._logger.create("getUser"), t = await this._loadUser();
    return t ? (e.info("user loaded"), this._events.load(t, !1), t) : (e.info("user not found in storage"), null);
  }
  /**
   * Returns promise to remove from any storage the currently authenticated user.
   */
  async removeUser() {
    const e = this._logger.create("removeUser");
    await this.storeUser(null), e.info("user removed from storage"), this._events.unload();
  }
  /**
   * Returns promise to trigger a redirect of the current window to the authorization endpoint.
   */
  async signinRedirect(e = {}) {
    this._logger.create("signinRedirect");
    const {
      redirectMethod: t,
      ...s
    } = e, i = await this._redirectNavigator.prepare({ redirectMethod: t });
    await this._signinStart({
      request_type: "si:r",
      ...s
    }, i);
  }
  /**
   * Returns promise to process response from the authorization endpoint. The result of the promise is the authenticated `User`.
   */
  async signinRedirectCallback(e = window.location.href) {
    const t = this._logger.create("signinRedirectCallback"), s = await this._signinEnd(e);
    return s.profile && s.profile.sub ? t.info("success, signed in subject", s.profile.sub) : t.info("no subject"), s;
  }
  /**
   * Returns promise to process the signin with user/password. The result of the promise is the authenticated `User`.
   *
   * Throws an ErrorResponse in case of wrong authentication.
   */
  async signinResourceOwnerCredentials({
    username: e,
    password: t,
    skipUserInfo: s = !1
  }) {
    const i = this._logger.create("signinResourceOwnerCredential"), r = await this._client.processResourceOwnerPasswordCredentials({ username: e, password: t, skipUserInfo: s, extraTokenParams: this.settings.extraTokenParams });
    i.debug("got signin response");
    const n = await this._buildUser(r);
    return n.profile && n.profile.sub ? i.info("success, signed in subject", n.profile.sub) : i.info("no subject"), n;
  }
  /**
   * Returns promise to trigger a request (via a popup window) to the authorization endpoint. The result of the promise is the authenticated `User`.
   */
  async signinPopup(e = {}) {
    const t = this._logger.create("signinPopup"), {
      popupWindowFeatures: s,
      popupWindowTarget: i,
      ...r
    } = e, n = this.settings.popup_redirect_uri;
    n || t.throw(new Error("No popup_redirect_uri configured"));
    const o = await this._popupNavigator.prepare({ popupWindowFeatures: s, popupWindowTarget: i }), a = await this._signin({
      request_type: "si:p",
      redirect_uri: n,
      display: "popup",
      ...r
    }, o);
    return a && (a.profile && a.profile.sub ? t.info("success, signed in subject", a.profile.sub) : t.info("no subject")), a;
  }
  /**
   * Returns promise to notify the opening window of response from the authorization endpoint.
   */
  async signinPopupCallback(e = window.location.href, t = !1) {
    const s = this._logger.create("signinPopupCallback");
    await this._popupNavigator.callback(e, t), s.info("success");
  }
  /**
   * Returns promise to trigger a silent request (via an iframe) to the authorization endpoint.
   * The result of the promise is the authenticated `User`.
   */
  async signinSilent(e = {}) {
    var t;
    const s = this._logger.create("signinSilent"), {
      silentRequestTimeoutInSeconds: i,
      ...r
    } = e;
    let n = await this._loadUser();
    if (n != null && n.refresh_token) {
      s.debug("using refresh token");
      const g = new ct(n);
      return await this._useRefreshToken(g);
    }
    const o = this.settings.silent_redirect_uri;
    o || s.throw(new Error("No silent_redirect_uri configured"));
    let a;
    n && this.settings.validateSubOnSilentRenew && (s.debug("subject prior to silent renew:", n.profile.sub), a = n.profile.sub);
    const c = await this._iframeNavigator.prepare({ silentRequestTimeoutInSeconds: i });
    return n = await this._signin({
      request_type: "si:s",
      redirect_uri: o,
      prompt: "none",
      id_token_hint: this.settings.includeIdTokenInSilentRenew ? n == null ? void 0 : n.id_token : void 0,
      ...r
    }, c, a), n && ((t = n.profile) != null && t.sub ? s.info("success, signed in subject", n.profile.sub) : s.info("no subject")), n;
  }
  async _useRefreshToken(e) {
    const t = await this._client.useRefreshToken({
      state: e,
      timeoutInSeconds: this.settings.silentRequestTimeoutInSeconds
    }), s = new O({ ...e, ...t });
    return await this.storeUser(s), this._events.load(s), s;
  }
  /**
   * Returns promise to notify the parent window of response from the authorization endpoint.
   */
  async signinSilentCallback(e = window.location.href) {
    const t = this._logger.create("signinSilentCallback");
    await this._iframeNavigator.callback(e), t.info("success");
  }
  async signinCallback(e = window.location.href) {
    const { state: t } = await this._client.readSigninResponseState(e);
    switch (t.request_type) {
      case "si:r":
        return await this.signinRedirectCallback(e);
      case "si:p":
        return await this.signinPopupCallback(e);
      case "si:s":
        return await this.signinSilentCallback(e);
      default:
        throw new Error("invalid response_type in state");
    }
  }
  async signoutCallback(e = window.location.href, t = !1) {
    const { state: s } = await this._client.readSignoutResponseState(e);
    if (s)
      switch (s.request_type) {
        case "so:r":
          await this.signoutRedirectCallback(e);
          break;
        case "so:p":
          await this.signoutPopupCallback(e, t);
          break;
        case "so:s":
          await this.signoutSilentCallback(e);
          break;
        default:
          throw new Error("invalid response_type in state");
      }
  }
  /**
   * Returns promise to query OP for user's current signin status. Returns object with session_state and subject identifier.
   */
  async querySessionStatus(e = {}) {
    const t = this._logger.create("querySessionStatus"), {
      silentRequestTimeoutInSeconds: s,
      ...i
    } = e, r = this.settings.silent_redirect_uri;
    r || t.throw(new Error("No silent_redirect_uri configured"));
    const n = await this._loadUser(), o = await this._iframeNavigator.prepare({ silentRequestTimeoutInSeconds: s }), a = await this._signinStart({
      request_type: "si:s",
      // this acts like a signin silent
      redirect_uri: r,
      prompt: "none",
      id_token_hint: this.settings.includeIdTokenInSilentRenew ? n == null ? void 0 : n.id_token : void 0,
      response_type: this.settings.query_status_response_type,
      scope: "openid",
      skipUserInfo: !0,
      ...i
    }, o);
    try {
      const c = await this._client.processSigninResponse(a.url);
      return t.debug("got signin response"), c.session_state && c.profile.sub ? (t.info("success for subject", c.profile.sub), {
        session_state: c.session_state,
        sub: c.profile.sub,
        sid: c.profile.sid
      }) : (t.info("success, user not authenticated"), null);
    } catch (c) {
      if (this.settings.monitorAnonymousSession && c instanceof x)
        switch (c.error) {
          case "login_required":
          case "consent_required":
          case "interaction_required":
          case "account_selection_required":
            return t.info("success for anonymous user"), {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              session_state: c.session_state
            };
        }
      throw c;
    }
  }
  async _signin(e, t, s) {
    const i = await this._signinStart(e, t);
    return await this._signinEnd(i.url, s);
  }
  async _signinStart(e, t) {
    const s = this._logger.create("_signinStart");
    try {
      const i = await this._client.createSigninRequest(e);
      return s.debug("got signin request"), await t.navigate({
        url: i.url,
        state: i.state.id,
        response_mode: i.state.response_mode,
        scriptOrigin: this.settings.iframeScriptOrigin
      });
    } catch (i) {
      throw s.debug("error after preparing navigator, closing navigator window"), t.close(), i;
    }
  }
  async _signinEnd(e, t) {
    const s = this._logger.create("_signinEnd"), i = await this._client.processSigninResponse(e);
    return s.debug("got signin response"), await this._buildUser(i, t);
  }
  async _buildUser(e, t) {
    const s = this._logger.create("_buildUser"), i = new O(e);
    if (t) {
      if (t !== i.profile.sub)
        throw s.debug("current user does not match user returned from signin. sub from signin:", i.profile.sub), new x({ ...e, error: "login_required" });
      s.debug("current user matches user returned from signin");
    }
    return await this.storeUser(i), s.debug("user stored"), this._events.load(i), i;
  }
  /**
   * Returns promise to trigger a redirect of the current window to the end session endpoint.
   */
  async signoutRedirect(e = {}) {
    const t = this._logger.create("signoutRedirect"), {
      redirectMethod: s,
      ...i
    } = e, r = await this._redirectNavigator.prepare({ redirectMethod: s });
    await this._signoutStart({
      request_type: "so:r",
      post_logout_redirect_uri: this.settings.post_logout_redirect_uri,
      ...i
    }, r), t.info("success");
  }
  /**
   * Returns promise to process response from the end session endpoint.
   */
  async signoutRedirectCallback(e = window.location.href) {
    const t = this._logger.create("signoutRedirectCallback"), s = await this._signoutEnd(e);
    return t.info("success"), s;
  }
  /**
   * Returns promise to trigger a redirect of a popup window window to the end session endpoint.
   */
  async signoutPopup(e = {}) {
    const t = this._logger.create("signoutPopup"), {
      popupWindowFeatures: s,
      popupWindowTarget: i,
      ...r
    } = e, n = this.settings.popup_post_logout_redirect_uri, o = await this._popupNavigator.prepare({ popupWindowFeatures: s, popupWindowTarget: i });
    await this._signout({
      request_type: "so:p",
      post_logout_redirect_uri: n,
      // we're putting a dummy entry in here because we
      // need a unique id from the state for notification
      // to the parent window, which is necessary if we
      // plan to return back to the client after signout
      // and so we can close the popup after signout
      state: n == null ? void 0 : {},
      ...r
    }, o), t.info("success");
  }
  /**
   * Returns promise to process response from the end session endpoint from a popup window.
   */
  async signoutPopupCallback(e = window.location.href, t = !1) {
    const s = this._logger.create("signoutPopupCallback");
    await this._popupNavigator.callback(e, t), s.info("success");
  }
  async _signout(e, t) {
    const s = await this._signoutStart(e, t);
    return await this._signoutEnd(s.url);
  }
  async _signoutStart(e = {}, t) {
    var s;
    const i = this._logger.create("_signoutStart");
    try {
      const r = await this._loadUser();
      i.debug("loaded current user from storage"), this.settings.revokeTokensOnSignout && await this._revokeInternal(r);
      const n = e.id_token_hint || r && r.id_token;
      n && (i.debug("setting id_token_hint in signout request"), e.id_token_hint = n), await this.removeUser(), i.debug("user removed, creating signout request");
      const o = await this._client.createSignoutRequest(e);
      return i.debug("got signout request"), await t.navigate({
        url: o.url,
        state: (s = o.state) == null ? void 0 : s.id
      });
    } catch (r) {
      throw i.debug("error after preparing navigator, closing navigator window"), t.close(), r;
    }
  }
  async _signoutEnd(e) {
    const t = this._logger.create("_signoutEnd"), s = await this._client.processSignoutResponse(e);
    return t.debug("got signout response"), s;
  }
  /**
   * Returns promise to trigger a silent request (via an iframe) to the end session endpoint.
   */
  async signoutSilent(e = {}) {
    var t;
    const s = this._logger.create("signoutSilent"), {
      silentRequestTimeoutInSeconds: i,
      ...r
    } = e, n = this.settings.includeIdTokenInSilentSignout ? (t = await this._loadUser()) == null ? void 0 : t.id_token : void 0, o = this.settings.popup_post_logout_redirect_uri, a = await this._iframeNavigator.prepare({ silentRequestTimeoutInSeconds: i });
    await this._signout({
      request_type: "so:s",
      post_logout_redirect_uri: o,
      id_token_hint: n,
      ...r
    }, a), s.info("success");
  }
  /**
   * Returns promise to notify the parent window of response from the end session endpoint.
   */
  async signoutSilentCallback(e = window.location.href) {
    const t = this._logger.create("signoutSilentCallback");
    await this._iframeNavigator.callback(e), t.info("success");
  }
  async revokeTokens(e) {
    const t = await this._loadUser();
    await this._revokeInternal(t, e);
  }
  async _revokeInternal(e, t = this.settings.revokeTokenTypes) {
    const s = this._logger.create("_revokeInternal");
    if (!e)
      return;
    const i = t.filter((r) => typeof e[r] == "string");
    if (!i.length) {
      s.debug("no need to revoke due to no token(s)");
      return;
    }
    for (const r of i)
      await this._client.revokeToken(
        e[r],
        // eslint-disable-line @typescript-eslint/no-non-null-assertion
        r
      ), s.info(`${r} revoked successfully`), r !== "access_token" && (e[r] = null);
    await this.storeUser(e), s.debug("user stored"), this._events.load(e);
  }
  /**
   * Enables silent renew for the `UserManager`.
   */
  startSilentRenew() {
    this._logger.create("startSilentRenew"), this._silentRenewService.start();
  }
  /**
   * Disables silent renew for the `UserManager`.
   */
  stopSilentRenew() {
    this._silentRenewService.stop();
  }
  get _userStoreKey() {
    return `user:${this.settings.authority}:${this.settings.client_id}`;
  }
  async _loadUser() {
    const e = this._logger.create("_loadUser"), t = await this.settings.userStore.get(this._userStoreKey);
    return t ? (e.debug("user storageString loaded"), O.fromStorageString(t)) : (e.debug("no user storageString"), null);
  }
  async storeUser(e) {
    const t = this._logger.create("storeUser");
    if (e) {
      t.debug("storing user");
      const s = e.toStorageString();
      await this.settings.userStore.set(this._userStoreKey, s);
    } else
      this._logger.debug("removing user"), await this.settings.userStore.remove(this._userStoreKey);
  }
  /**
   * Removes stale state entries in storage for incomplete authorize requests.
   */
  async clearStaleState() {
    await this._client.clearStaleState();
  }
};
const dt = (e = window.location) => {
  let t = new URLSearchParams(e.search);
  return !!((t.get("code") || t.get("error")) && t.get("state") || (t = new URLSearchParams(e.hash.replace("#", "?")), (t.get("code") || t.get("error")) && t.get("state")));
}, wt = (e) => {
  const { children: t, userManagerSettings: s } = e, [i] = j(() => {
    const d = s;
    return new lt(d);
  }), [r, n] = j(null), [o, a] = j(!0), c = fe(!1), g = () => {
    const d = sessionStorage.getItem("redirectPath");
    sessionStorage.removeItem("redirectPath"), window.history.replaceState({}, document.title, d);
  };
  V(() => {
    !i || c.current || (c.current = !0, (async () => {
      try {
        a(!0), dt() && (await i.signinCallback(), g());
      } catch (d) {
        G(d);
      } finally {
        a(!1);
      }
    })());
  }, [
    i
  ]), V(() => {
    if (!i)
      return;
    const d = () => {
      n(null), a(!0);
    }, _ = (f) => {
      G(f), i.signoutRedirect({
        post_logout_redirect_uri: window.location.href
      }), a(!1);
    }, m = () => {
      i.removeUser(), a(!1);
    }, S = async (f) => {
      n(f);
    };
    return i.events.addUserUnloaded(d), i.events.addAccessTokenExpired(m), i.events.addSilentRenewError(_), i.events.addUserLoaded(S), () => {
      i.events.removeUserUnloaded(d), i.events.removeAccessTokenExpired(m), i.events.removeSilentRenewError(_), i.events.addUserLoaded(S);
    };
  }, [
    i
  ]);
  const h = we(() => {
    const d = async () => {
      sessionStorage.setItem("redirectPath", window.location.pathname), await i.signinRedirect();
    }, _ = async () => {
      await i.signoutRedirect({
        post_logout_redirect_uri: window.location.href
      });
    };
    return {
      userManager: i,
      user: r,
      isAuthenticated: !!r,
      accessToken: r ? r == null ? void 0 : r.access_token : null,
      logout: _,
      login: d,
      isLoading: o
    };
  }, [
    i,
    r,
    o
  ]);
  return /* @__PURE__ */ B.jsx(Re.Provider, {
    value: h,
    children: o ? /* @__PURE__ */ B.jsx(ve, {
      variant: "fullpage"
    }) : t
  });
};
export {
  wt as A
};
