import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as Q, useState as L, useEffect as ue, Children as We, useMemo as De, createContext as Xe } from "react";
import { a as fe, M as ee, P as ye, c as $e, q as Pe, b as et, B as he, D as Te, w as tt, e as ie, T as ge, l as nt, R as Me, m as at, k as He, I as st } from "./index.esm-CqWfms5D.js";
import { F as me } from "./provider-DKLaKIVD.js";
import { u as Ae } from "./useConfiguration-B-lyaBB9.js";
import { i } from "./index-CrDCxF0S.js";
import { r as F } from "./sentry-CstOwvcR.js";
import { AppContext as _e } from "./AppContext.js";
import { T as it } from "./Tnc-CLAWBsBN.js";
import { w as pe, ag as rt, E as ot, o as ae, G as lt, F as ct, k as ze, m as we, H as dt, s as ut, x as mt, r as pt, J as _t, I as ft } from "./utils-C2gHLhxV.js";
import { u as ht } from "./Auth-CJPsuV-i.js";
import { B as gt } from "./BankAccountListing-CcLcjdff.js";
import { ErrorPage as Se } from "./ErrorPage.js";
import { u as Fe } from "./useMigratedUser-RRfxTYvK.js";
import { K as yt } from "./KycRequestStatus-CZMgulL-.js";
import bt from "./KycEsign-Bl_gvvkx.js";
import xt from "./KycIpv-CA721Fq9.js";
import wt from "./KycFileUploads-CUYxur9c.js";
import { N as Ct } from "./NominationConsent-jU9JIj8S.js";
import { N as vt } from "./NomineeListing-DEYsB5Sw.js";
import { C as jt, B as St, F as Nt } from "./FinancialDetails-Y9XRaQeE.js";
import { m as Et, f as Ce, g as kt, h as Ut, r as ce, k as It, l as Tt, d as Re } from "./validators-BNinzCrT.js";
import { R as At, F as re } from "./react-final-form.es-CUo9SHJd.js";
const Kt = ({ user: e, onCompletion: r, isPartner: c, isGroupInvestment: o, folioDetails: s }) => {
  const { fpapi: d } = Q(me), { AppState: { tenant: x } } = Q(_e), [v, f] = L(null), [y, w] = L(null), [V, n] = L(!0), [h, b] = L(null), C = (_, u) => (_ == null ? void 0 : _.cancelled_cheque) && (u == null ? void 0 : u.status) === "completed" && (u == null ? void 0 : u.reason) === "digital_verification" && (u == null ? void 0 : u.confidence) === "zero", U = (_, u) => !(_ != null && _.cancelled_cheque) && // no cancelled cheque
  (u == null ? void 0 : u.status) === "completed" && // verification status is complete
  (u == null ? void 0 : u.reason) === "digital_verification" && // reason is digital_verification
  (u == null ? void 0 : u.confidence) === "zero", H = (_) => _.sort((u, q) => Number(new Date(q.updated_at)) - Number(new Date(u.updated_at))), I = async (_) => {
    var u;
    if (_) {
      const q = [], X = [], $ = [], l = [], E = [], z = [], Z = _ == null ? void 0 : _.map((M) => {
        const Y = M.identifier ? String(M.identifier) : String(M.id);
        return d.fpClient.bank_account_verifications().fetchAll({
          bank_accounts: [
            Y
          ]
        });
      });
      return (await Promise.allSettled(Z)).forEach((M, Y) => {
        var J;
        if (M.status === "fulfilled") {
          const B = {
            ..._[Y]
          }, ne = (J = M == null ? void 0 : M.value) == null ? void 0 : J.data[0];
          ne ? ne.status === "completed" ? C(B, ne) ? $.push(B) : U(B, ne) ? l.push(B) : q.push(B) : ne.status === "pending" ? X.push(B) : ne.status === "failed" && E.push(B) : z.push(B);
        } else if (M.status === "rejected")
          throw window.debug.error(M), M;
      }), s && ((u = s == null ? void 0 : s.payout_details) == null || u.forEach((M) => {
        const Y = _ == null ? void 0 : _.find((J) => {
          var B;
          return (J == null ? void 0 : J.account_number) === ((B = M == null ? void 0 : M.bank_account) == null ? void 0 : B.number);
        });
        Y && q.push(Y);
      })), {
        COMPLETE: [
          ...H(q)
        ],
        PENDING: [
          ...H(X)
        ],
        MANUAL_VERIFICATION_PENDING: [
          ...H($)
        ],
        COLLECT_CANCELLED_CHEQUE: [
          ...H(l)
        ],
        FAILED: [
          ...H(E)
        ],
        NOVERIFICATION: [
          ...H(z)
        ]
      };
    } else
      throw new Error(i._({
        id: "VhBqMw"
      }));
  }, N = async () => {
    const _ = await d.fpClient.bank_accounts().fetchAll({
      profile: e == null ? void 0 : e.profile
    }), { COMPLETE: u, PENDING: q, MANUAL_VERIFICATION_PENDING: X, COLLECT_CANCELLED_CHEQUE: $, FAILED: l, NOVERIFICATION: E } = await I(_ == null ? void 0 : _.data);
    return u.length ? {
      stage: "verification_successful",
      lastUpdatedBankAccount: u[0]
    } : q.length ? {
      stage: "verification_pending",
      lastUpdatedBankAccount: q[0]
    } : X.length ? {
      stage: "manual_verification_pending",
      lastUpdatedBankAccount: X[0]
    } : $.length ? {
      stage: "cancelled_cheque_required",
      lastUpdatedBankAccount: $[0]
    } : l.length ? {
      stage: "verification_failed",
      lastUpdatedBankAccount: l[0]
    } : {
      stage: "no_verification",
      lastUpdatedBankAccount: E[0]
    };
  }, D = async (_) => {
    const u = e.id, X = {
      ...pe(e == null ? void 0 : e.annexure),
      completed_step: "bank_verification"
    };
    if (_)
      for (const [l, E] of Object.entries(_))
        E && (X[l] = E);
    const $ = await d.updateUser(u, X, null, x);
    if ($)
      return $;
    throw new Error($);
  }, G = (_) => {
    w(_);
  }, P = async (_) => {
    await D({
      verified_bac: _ == null ? void 0 : _.id
    }), r();
  }, j = async () => await d.fpClient.investor_profiles().fetch(e == null ? void 0 : e.profile), O = async () => {
    const _ = await N();
    switch (_.stage) {
      case "verification_successful": {
        await P(_ == null ? void 0 : _.lastUpdatedBankAccount);
        break;
      }
      case "manual_verification_pending":
      case "verification_pending":
      case "cancelled_cheque_required":
      case "no_verification":
      case "verification_failed": {
        const u = await j();
        f(u), G("bank_account_listing");
        break;
      }
    }
  };
  ue(() => {
    (async () => {
      try {
        await O();
      } catch (_) {
        window.debug.error(_), b(i._({
          id: "DpIV8H"
        })), F(_);
      } finally {
        n(!1);
      }
    })();
  }, []);
  const A = (_) => {
    (async () => {
      try {
        n(!0), await P(_);
      } catch (u) {
        window.debug.error(u), b(i._({
          id: "NKzNR1"
        })), F(u);
      } finally {
        n(!1);
      }
    })();
  }, T = () => o ? i._({
    id: "Ol3oy2"
  }) : c ? i._({
    id: "ZL/hhl"
  }) : i._({
    id: "zUm8oD"
  });
  return V ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : h ? /* @__PURE__ */ t.jsx(Se, {
    title: i._({
      id: "AsbZJy"
    }),
    description: h,
    showAction: !0,
    actionText: i._({
      id: "90uUa5"
    }),
    actionHandler: O
  }) : (() => {
    switch (y) {
      case "bank_account_listing":
        return /* @__PURE__ */ t.jsx(gt, {
          investorProfile: v,
          handleBankSelection: A,
          customPageTitle: i._({
            id: "q4PFMU"
          }),
          customPageSubtitle: T(),
          alertMessage: i._({
            id: "OVTWIa"
          })
        });
      default:
        return /* @__PURE__ */ t.jsx(fe, {
          variant: "fullpage"
        });
    }
  })();
}, Bt = ({ user: e, onCompletion: r, isGroupInvestment: c }) => {
  const { fpapi: o } = Q(me), { AppState: { tenant: s } } = Q(_e), [d, x] = L(null), [v, f] = L(!0), [y, w] = L(null);
  ue(() => {
    (async () => {
      try {
        const h = await o.fpClient.investor_profiles().fetch(e == null ? void 0 : e.profile);
        x(h);
      } catch (h) {
        window.debug.error(h), w("Error fetching investor profile"), F(h);
      } finally {
        f(!1);
      }
    })();
  }, [
    e == null ? void 0 : e.profile
  ]);
  const V = async () => {
    var h;
    try {
      f(!0);
      const b = await o.fetchUser(d.pan, s), U = {
        ...pe((h = b[0]) == null ? void 0 : h.annexure),
        completed_step: "nomination_consent"
      }, H = await o.updateUser(e.id, U, null, s);
      H ? r() : (window.debug.error(H), w("Error updating user"));
    } catch (b) {
      window.debug.error(b), w("Error updating user"), F(b);
    } finally {
      f(!1);
    }
  }, n = async () => {
    try {
      f(!0);
      const h = e.id, b = e == null ? void 0 : e.annexure;
      b.completed_step = "contact_details", await o.updateUser(h, b, null, s), r();
    } catch (h) {
      window.debug.error(h), F(h);
    } finally {
      f(!1);
    }
  };
  return v ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : y ? /* @__PURE__ */ t.jsx(Se, {
    title: "Error",
    description: y
  }) : /* @__PURE__ */ t.jsx(Ct, {
    handleGoBack: n,
    investorProfile: d,
    onSuccess: V,
    isGroupInvestment: c
  });
}, Lt = ({ user: e, onCompletion: r, isPartner: c, isKycCompliant: o, folioDetails: s }) => {
  const { fpapi: d } = Q(me), { AppState: { tenant: x } } = Q(_e), [v, f] = L(null), [y, w] = L(!1), [V, n] = L([]), [h, b] = L(null), C = pe(e == null ? void 0 : e.annexure);
  ue(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const U = async (j) => {
    const O = e.id, A = {
      ...C,
      ...j
    };
    c ? A.partner_completed_step = "nominee_details" : A.completed_step = "nominee_details";
    const T = await d.updateUser(O, A, null, x);
    if (T)
      return T;
    throw T;
  }, H = (j, O) => {
    var T;
    let A = [];
    for (let R = 1; R <= 3; R++) {
      const _ = R, u = O == null ? void 0 : O[`nominee${_}`];
      u && A.push({
        name: u == null ? void 0 : u.name,
        date_of_birth: u == null ? void 0 : u.dob,
        relationship: (T = u == null ? void 0 : u.relationship) == null ? void 0 : T.toLowerCase()
      });
    }
    return j.length && (A = j), A;
  };
  ue(() => {
    (async () => {
      try {
        w(!0);
        const j = await d.fpClient.related_parties().fetchAll({
          profile: e == null ? void 0 : e.profile
        }), O = H(j == null ? void 0 : j.data, s), A = await I(e);
        A && b(A), n(O);
      } catch (j) {
        window.debug.error(j), f(i._({
          id: "cuvfTX"
        })), F(j);
      } finally {
        w(!1);
      }
    })();
  }, []);
  const I = async (j) => {
    var u;
    const O = (u = j == null ? void 0 : j.username) == null ? void 0 : u.toUpperCase(), A = j == null ? void 0 : j.profile;
    let T = null;
    const R = await d.fpClient.mf_investment_accounts().fetchAll({
      investor: O
    }), _ = R == null ? void 0 : R.data;
    return _ != null && _.length && (T = _.find((q) => q.primary_investor === A)), T;
  }, N = async (j) => {
    var T, R, _, u, q, X;
    const O = h, A = rt(j);
    return await d.fpClient.mf_investment_accounts().update({
      id: O == null ? void 0 : O.id,
      folio_defaults: {
        nominee1: ((T = A[0]) == null ? void 0 : T.id) || null,
        nominee1_allocation_percentage: ((R = A[0]) == null ? void 0 : R.allocationPercentage) || null,
        nominee2: ((_ = A[1]) == null ? void 0 : _.id) || null,
        nominee2_allocation_percentage: ((u = A[1]) == null ? void 0 : u.allocationPercentage) || null,
        nominee3: ((q = A[2]) == null ? void 0 : q.id) || null,
        nominee3_allocation_percentage: ((X = A[2]) == null ? void 0 : X.allocationPercentage) || null
      }
    });
  }, D = async ({ skip_nomination: j, nominees: O }) => {
    try {
      if (w(!0), j)
        await U({
          skip_nomination: !0
        }), await N([]), r();
      else {
        const A = [], T = [];
        O.forEach((u) => {
          u != null && u.id || A.push(d.fpClient.related_parties().create({
            profile: e == null ? void 0 : e.profile,
            name: u.name,
            relationship: u.relationship
          }));
        });
        const R = await Promise.allSettled(A), _ = O.filter((u) => u == null ? void 0 : u.id);
        R.forEach((u) => {
          u.status === "rejected" ? T.push(u) : u.status === "fulfilled" && _.push(u.value);
        }), T.length ? (window.debug.error(T), f(i._({
          id: "cuvfTX"
        }))) : (await U(), await N([
          ..._
        ]), r());
      }
    } catch (A) {
      window.debug.error(A), f(i._({
        id: "cuvfTX"
      })), F(A);
    } finally {
      w(!1);
    }
  }, G = async () => {
    try {
      w(!0);
      const j = e.id, O = e == null ? void 0 : e.annexure;
      c ? o ? O.partner_completed_step = "banking_details" : O.partner_completed_step = "financial_details" : O.completed_step = "banking_details", await d.updateUser(j, O, null, x), r();
    } catch (j) {
      window.debug.error(j), f(i._({
        id: "qMcu4/"
      })), F(j);
    } finally {
      w(!1);
    }
  };
  return y ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      v && /* @__PURE__ */ t.jsx(ee, {
        type: "error",
        variant: "field",
        title: v
      }),
      /* @__PURE__ */ t.jsx(vt, {
        handleNomineeSelection: (j) => {
          D(j);
        },
        mfia: h,
        isPartner: c,
        nomineeList: V,
        investorProfile: null,
        handleGoBack: G
      })
    ]
  });
};
function Ot({ onConsent: e, user: r, isKycCompliant: c }) {
  var n, h;
  const { fpapi: o } = Q(me), { AppState: { tenant: s } } = Q(_e), { tenantConfig: d } = Ae(), [x, v] = L(!1), [f, y] = L(!1), w = async () => {
    if (r) {
      const b = +/* @__PURE__ */ new Date(), C = {
        ...r == null ? void 0 : r.annexure,
        employeeDeclarationTimeStamp: b,
        completed_step: "employee_declaration"
      };
      await o.updateUser(r == null ? void 0 : r.id, C, null, s) ? e({
        employeeDeclarationTimeStamp: b
      }) : window.debug.error("Cannot update the details");
    }
  }, V = async () => {
    try {
      if (r) {
        const b = r.id, C = r == null ? void 0 : r.annexure;
        C.completed_step = "contact_details", await o.updateUser(b, C, null, s), e();
      }
    } catch (b) {
      window.debug.error(b), F(b);
    }
  };
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(ye, {
        title: i._({
          id: "mAF+1j"
        }),
        customClass: "mb-3",
        semiBold: !0,
        icon: c ? "Back" : null,
        onClick: V
      }),
      /* @__PURE__ */ t.jsx(ye, {
        variant: "sub",
        title: i._({
          id: "GgxZVs"
        })
      }),
      /* @__PURE__ */ t.jsx($e, {
        border: !0,
        symbol: ((n = d == null ? void 0 : d.groupInvestment) == null ? void 0 : n.groupPartnerLogo) || (d == null ? void 0 : d.fallbackLogo),
        label: (h = d == null ? void 0 : d.groupInvestment) == null ? void 0 : h.groupPartnerName,
        customClass: "mb-6"
      }),
      /* @__PURE__ */ t.jsxs("div", {
        className: "dark:text-neutral-40 mb-6 leading-relaxed",
        children: [
          /* @__PURE__ */ t.jsx("p", {
            className: "pb-2",
            children: i._({
              id: "C95tsa"
            })
          }),
          /* @__PURE__ */ t.jsx("p", {
            className: "pb-2",
            children: i._({
              id: "nOHGAn"
            })
          })
        ]
      }),
      /* @__PURE__ */ t.jsxs(Pe, {
        id: "terms-id",
        checked: x,
        onChange: (b) => {
          b.stopPropagation(), v(!x);
        },
        customClass: "mb-3",
        children: [
          i._({
            id: "9nnBWQ"
          }),
          /* @__PURE__ */ t.jsx("a", {
            onClick: (b) => {
              b.preventDefault(), b.stopPropagation(), y(!0);
            },
            className: "text-size-14",
            children: i._({
              id: "wt7bu+"
            })
          })
        ]
      }),
      /* @__PURE__ */ t.jsx(et, {
        variant: "fullPage",
        title: i._({
          id: "4yYTaY"
        }),
        isOpen: f,
        toggleModal: y,
        contentHeightMax: !0,
        children: /* @__PURE__ */ t.jsx(it, {
          disableBackButton: !0
        })
      }),
      /* @__PURE__ */ t.jsx(he, {
        variant: x ? "primary" : "disabled",
        onClick: w,
        type: "button",
        fullwidth: !0,
        children: i._({
          id: "2iVSoY"
        })
      })
    ]
  });
}
const Mt = ({ handleInvestNow: e, error: r, handleGoBack: c, user: o }) => {
  const { fpapi: s } = Q(me), { AppState: { tenant: d } } = Q(_e), x = () => {
    const f = new URLSearchParams(window.location.search).get("isin"), y = new URL(`${window.location.origin}/profile-details`);
    return f && y.searchParams.set("isin", f), y == null ? void 0 : y.toString();
  }, v = async () => {
    try {
      const f = o.id, y = o == null ? void 0 : o.annexure;
      y.partner_completed_step = "contact_details", y.isonboarded = "false", await s.updateUser(f, y, null, d), c();
    } catch (f) {
      window.debug.error(f), F(f);
    }
  };
  return /* @__PURE__ */ t.jsxs("div", {
    children: [
      /* @__PURE__ */ t.jsx(ye, {
        title: i._({
          id: "vYVuHo"
        }),
        icon: "Back",
        onClick: v
      }),
      /* @__PURE__ */ t.jsx(Te, {
        type: "text",
        text: i._({
          id: "T8tJKY"
        })
      }),
      /* @__PURE__ */ t.jsx(t.Fragment, {
        children: /* @__PURE__ */ t.jsx(he, {
          variant: "secondary",
          onClick: () => {
            window.open(ot(x(), d), "_blank", "noopener=true");
          },
          fullwidth: !0,
          customClass: "mb-2",
          children: i._({
            id: "Aprjso"
          })
        })
      }),
      /* @__PURE__ */ t.jsx(he, {
        variant: "secondary",
        onClick: e,
        fullwidth: !0,
        customClass: "mb-2",
        children: i._({
          id: "4U6CpR"
        })
      }),
      r && /* @__PURE__ */ t.jsx(ee, {
        type: "error",
        variant: "flash",
        title: r,
        customClass: "mb-5"
      })
    ]
  });
}, Ht = ({ title: e, subTitle: r, steps: c, onSummaryComplete: o }) => {
  var f;
  const s = (f = c.find((y) => y.status === "resume")) == null ? void 0 : f.stepValue, d = () => {
    var y;
    return ((y = c == null ? void 0 : c[0]) == null ? void 0 : y.status) === "resume" ? i._({
      id: "ZYprs/"
    }) : i._({
      id: "D6NUdw"
    });
  }, x = (y, w) => {
    if (!(w === 0 && y.displayStatus === "resume"))
      return y.displayStatus;
  }, v = () => We.toArray(c.map((y, w) => y.displayIsHidden ? null : /* @__PURE__ */ t.jsx(tt, {
    status: x(y, w),
    children: /* @__PURE__ */ t.jsx("h5", {
      children: y.displayStepName
    })
  })));
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(ye, {
        title: e
      }),
      /* @__PURE__ */ t.jsx(Te, {
        type: "text",
        text: r
      }),
      /* @__PURE__ */ t.jsx("div", {
        children: v()
      }),
      /* @__PURE__ */ t.jsx(he, {
        fullwidth: !0,
        onClick: () => o(s),
        customClass: "mb-8",
        children: d()
      })
    ]
  });
}, Ke = /* @__PURE__ */ Xe(null), Rt = ({ children: e }) => {
  const [r, c] = L(!1), [o, s] = L(null), d = (f) => {
    s((y) => ({
      ...y,
      ...f
    }));
  }, x = (f) => c(f), v = De(() => ({
    loading: r,
    errors: o,
    handleSetLoading: x,
    handleSetErrors: d
  }), [
    r,
    o
  ]);
  return /* @__PURE__ */ t.jsx(Ke.Provider, {
    value: v,
    children: e
  });
}, qt = i._({
  id: "uR3xiz"
}), Vt = i._({
  id: "HiZNu3"
}), Dt = i._({
  id: "568G4c"
}), zt = i._({
  id: "r3WMz0"
}), Ft = i._({
  id: "KooMEc"
}), Gt = i._({
  id: "s1n7ez"
}), Zt = i._({
  id: "pg+XG6"
}), Qt = i._({
  id: "cvvS9u"
}), Yt = i._({
  id: "KgGQqC"
}), Jt = i._({
  id: "m53GGj"
}), Wt = i._({
  id: "QSdDcn"
}), Xt = i._({
  id: "DwufZH"
}), $t = i._({
  id: "q2sTsF"
}), Pt = i._({
  id: "a7XFrx"
}), en = i._({
  id: "CJG120"
}), Ne = {
  male: i._({
    id: "mhekaN"
  }),
  female: i._({
    id: "MbGkoQ"
  }),
  transgender: i._({
    id: "WRKGRB"
  })
}, Ee = {
  married: i._({
    id: "FWqaip"
  }),
  unmarried: i._({
    id: "BTcVOG"
  }),
  others: i._({
    id: "bzmQKJ"
  })
}, qe = {
  father: i._({
    id: "VAD7Ul"
  }),
  spouse: i._({
    id: "zwY2/g"
  })
}, ke = {
  notApplicable: i._({
    id: "6BjygT"
  }),
  pepRelated: i._({
    id: "0n+8KZ"
  }),
  pepExposed: i._({
    id: "JpLyV/"
  })
}, tn = i._({
  id: "DH1QQr"
}), nn = i._({
  id: "kAQzzC"
}), Ue = i._({
  id: "lEyTi5"
}), an = i._({
  id: "qMcu4/"
}), sn = i._({
  id: "Sto4kq"
}), rn = i._({
  id: "AtXdm9"
}), on = i._({
  id: "Ih9v5T"
}), ln = i._({
  id: "C4aY37"
}), cn = i._({
  id: "n+VFc3"
}), ve = /* @__PURE__ */ new Date();
ve.setFullYear(ve.getFullYear() - 18);
const oe = [
  {
    label: Ne.male,
    value: "male"
  },
  {
    label: Ne.female,
    value: "female"
  },
  {
    label: Ne.transgender,
    value: "transgender"
  }
], Ie = [
  {
    label: Ee.married,
    value: "married"
  },
  {
    label: Ee.unmarried,
    value: "unmarried"
  },
  {
    label: Ee.others,
    value: "others"
  }
], je = [
  {
    label: qe.father,
    value: "father"
  },
  {
    label: qe.spouse,
    value: "spouse"
  }
], be = [
  {
    label: ke.notApplicable,
    value: "not_applicable"
  },
  {
    label: ke.pepRelated,
    value: "pep_related"
  },
  {
    label: ke.pepExposed,
    value: "pep_exposed"
  }
], dn = (e) => {
  const r = [], c = [];
  return e.forEach((o) => {
    const s = {
      id: o.ansi_code,
      ansi_code: o.ansi_code,
      label: o.name
    };
    o.ansi_code === "IN" ? c[0] = s : o.ansi_code === "US" ? c[1] = s : o.ansi_code === "GB" ? c[2] = s : o.ansi_code === "AE" ? c[3] = s : r.push(s);
  }), [
    ...c,
    ...r
  ];
}, un = (e) => () => {
  var o;
  if (!e) return;
  const r = {
    name: e == null ? void 0 : e.primary_investor_name,
    date_of_birth: e != null && e.primary_investor_dob ? new Date(e == null ? void 0 : e.primary_investor_dob) : null,
    gender: oe[ae(oe, ((o = e == null ? void 0 : e.primary_investor_gender) == null ? void 0 : o.toLowerCase()) || "")]
  };
  let c;
  for (c in r)
    r[c] || delete r[c];
  return r;
}, mn = (e, r) => () => {
  if (!e || !r) return;
  const c = e == null ? void 0 : e.entity_details, o = {
    name: c == null ? void 0 : c.name,
    date_of_birth: r.annexure.dob ? new Date(r.annexure.dob) : null,
    gender: c != null && c.gender ? Et(c == null ? void 0 : c.gender) && oe[ae(oe, c == null ? void 0 : c.gender)] : null,
    tax_status: (c == null ? void 0 : c.residential_status) === "resident_individual" ? c == null ? void 0 : c.residential_status : null
  };
  let s;
  for (s in o)
    o[s] || delete o[s];
  return o;
}, pn = (e, r) => () => {
  var s;
  if (!e) return;
  const c = {
    name: e == null ? void 0 : e.name,
    date_of_birth: e == null ? void 0 : e.date_of_birth,
    gender: oe[ae(oe, e == null ? void 0 : e.gender)],
    country_of_birth: r[ae(r, (s = e == null ? void 0 : e.country_of_birth) == null ? void 0 : s.toUpperCase(), {
      customProperty: "ansi_code"
    })],
    relation_name: lt(e),
    relation_type: je[ae(je, ct(e) || "")],
    marital_status: Ie[ae(Ie, e == null ? void 0 : e.marital_status)],
    mother_name: e == null ? void 0 : e.mother_name,
    tax_status: e == null ? void 0 : e.residential_status
  };
  let o;
  for (o in c)
    c[o] || delete c[o];
  return c;
}, _n = (e, r) => () => {
  var s;
  if (!e) return;
  const c = {
    name: e == null ? void 0 : e.name,
    date_of_birth: e != null && e.date_of_birth ? new Date(e == null ? void 0 : e.date_of_birth) : null,
    gender: oe[ae(oe, (e == null ? void 0 : e.gender) || "")],
    country_of_birth: r[ae(r, ((s = e == null ? void 0 : e.country_of_birth) == null ? void 0 : s.toUpperCase()) || "", {
      customProperty: "ansi_code"
    })],
    place_of_birth: (e == null ? void 0 : e.place_of_birth) === "IN" ? "India" : e == null ? void 0 : e.place_of_birth,
    pep_details: be[ae(be, (e == null ? void 0 : e.pep_details) || "")],
    tax_status: e == null ? void 0 : e.tax_status
  };
  let o;
  for (o in c)
    c[o] || delete c[o];
  return c;
}, Ve = (e) => {
  if (e)
    return e instanceof Date ? ze(e, "yyyy-mm-dd") : e;
}, fn = ({ user: e, isKycCompliant: r, isKycOnly: c }) => {
  const { fpapi: o } = Q(me), s = Q(Ke), [d, x] = L(null), [v, f] = L(null), [y, w] = L(null), [V, n] = L(null), [h, b] = L([]), C = s == null ? void 0 : s.handleSetLoading, U = s == null ? void 0 : s.handleSetErrors;
  return ue(() => {
    (async () => {
      var H;
      try {
        C == null || C(!0), U == null || U(null);
        try {
          const { countries: D } = await o.fpClient.master_data().fetchCountries();
          if (D.length) {
            const G = dn(D);
            b(G);
          } else {
            U == null || U({
              fieldErrors: {
                country_of_birth: Ue
              }
            });
            return;
          }
        } catch (D) {
          window.debug.error(D), U == null || U({
            fieldErrors: {
              country_of_birth: Ue
            }
          }), F(D);
          return;
        }
        const I = await o.fpClient.investor_profiles().fetch(e.profile);
        if (x(I), c || !r) {
          const D = [
            o.fpClient.kyc_requests().fetchAll({
              pan: e.username
            }),
            o.fpClient.email_addresses().fetchAll({
              profile: I == null ? void 0 : I.id
            }),
            o.fpClient.phone_numbers().fetchAll({
              profile: I == null ? void 0 : I.id
            })
          ], [G, P, j] = await Promise.all(D);
          f((H = G == null ? void 0 : G.data) == null ? void 0 : H[0]), w(P == null ? void 0 : P.data[0]), n(j == null ? void 0 : j.data[0]);
        }
      } catch (I) {
        window.debug.error(I), U == null || U({
          fullPageError: {
            title: sn
          }
        }), F(I);
      } finally {
        C == null || C(!1);
      }
    })();
  }, []), {
    investorProfile: d,
    kycReq: v,
    emailData: y,
    phoneData: V,
    countries: h
  };
}, hn = (e) => {
  var n;
  const { user: r, kycCheckData: c, kycReq: o, investorProfile: s, countries: d } = e, { migratedFolioList: x } = Fe({
    user: r
  }), { tenantConfig: v } = Ae(), f = (n = v == null ? void 0 : v.data_sources_usage_policy) == null ? void 0 : n.KYC, y = f === "strict" || f === "flexible", w = x == null ? void 0 : x[0];
  return De(() => {
    var U;
    let h = {
      name: null,
      date_of_birth: null,
      gender: null,
      country_of_birth: d.find((H) => H.ansi_code === "IN"),
      place_of_birth: null,
      pep_details: be[ae(be, "not_applicable")],
      tax_status: "resident_individual",
      relation_type: null,
      relation_name: null,
      marital_status: null,
      mother_name: null
    };
    const b = {
      folio: un(w),
      kra: y ? mn(c, r) : () => {
      },
      kyc: pn(o, d),
      invp: _n(s, d)
    };
    let C;
    for (C in b)
      h = {
        ...h,
        ...b[C]() ?? {}
      };
    return (U = r == null ? void 0 : r.annexure) != null && U.dob && (h.date_of_birth = new Date(r.annexure.dob)), h;
  }, [
    s,
    c,
    o,
    d,
    w
  ]);
}, gn = ({ user: e, isKycCompliant: r, isKycOnly: c }) => {
  var v;
  const o = !!((v = e == null ? void 0 : e.annexure) != null && v.dob);
  return c ? {
    name: !1,
    date_of_birth: o,
    gender: !1,
    country_of_birth: !1,
    marital_status: !1,
    relation_name: !1,
    relation_type: !1,
    mother_name: !1,
    place_of_birth: !0,
    pep_details: !0,
    tax_status: !0
  } : r ? {
    name: !1,
    date_of_birth: o,
    gender: !1,
    country_of_birth: !1,
    place_of_birth: !1,
    pep_details: !1,
    tax_status: !1,
    marital_status: !0,
    relation_name: !0,
    relation_type: !0,
    mother_name: !0
  } : {
    name: !1,
    date_of_birth: o,
    gender: !1,
    country_of_birth: !1,
    marital_status: !1,
    relation_name: !1,
    relation_type: !1,
    mother_name: !1,
    place_of_birth: !1,
    pep_details: !1,
    tax_status: !1
  };
}, yn = ({ investorProfile: e, isKycCompliant: r }) => {
  var x;
  const { tenantConfig: c } = Ae(), o = (x = c == null ? void 0 : c.data_sources_usage_policy) == null ? void 0 : x.KYC, d = (o === "strict" || o === "flexible") && r;
  return {
    name: d,
    gender: d,
    tax_status: !0,
    date_of_birth: !!(e != null && e.date_of_birth),
    country_of_birth: !!(e != null && e.country_of_birth),
    place_of_birth: !!(e != null && e.country_of_birth)
  };
}, bn = ({ countriesOptions: e, formInitialValues: r, handleSubmit: c, disabledFields: o, hiddenFields: s, errors: d }) => {
  ue(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const x = d == null ? void 0 : d.formError, v = d == null ? void 0 : d.fieldErrors;
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: /* @__PURE__ */ t.jsx(At, {
      onSubmit: c,
      subscription: {
        submitting: !0,
        values: !0,
        errors: !0
      },
      initialValues: r,
      children: (f) => {
        var y, w, V;
        return /* @__PURE__ */ t.jsxs("form", {
          onSubmit: f.handleSubmit,
          children: [
            s != null && s.name ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: qt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "name",
                  validate: Ce(ce, Ut(4), kt(120)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(ge, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        disabled: o == null ? void 0 : o.name,
                        placeholder: $t,
                        maxLength: 70,
                        status: n.meta.error && n.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.date_of_birth ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Vt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "date_of_birth",
                  validate: Ce(ce, Tt, It(tn)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(nt, {
                        name: n.input.name,
                        variant: "keyboardInputDOB",
                        placeholder: Pt,
                        format: "dd-MMM-yyyy",
                        selectedDate: n.input.value || null,
                        maxDate: ve,
                        initialFocusedDate: ve,
                        error: n.meta.error && n.meta.touched,
                        helperText: null,
                        onKeyDown: (h) => h.preventDefault(),
                        handleDateChange: (h) => n.input.onChange(h),
                        disabled: o == null ? void 0 : o.date_of_birth
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.gender ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Dt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "gender",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => {
                    var h, b, C;
                    return /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(Me, {
                          name: n.input.name,
                          size: "sm",
                          checked: ae(oe, ((h = r == null ? void 0 : r.gender) == null ? void 0 : h.value) || ""),
                          onChange: (U, H) => n.input.onChange(H),
                          group: oe,
                          disabled: o != null && o.gender && ((b = r == null ? void 0 : r.gender) != null && b.value) ? dt(oe, ((C = r == null ? void 0 : r.gender) == null ? void 0 : C.value) || "") : []
                        }),
                        n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                          type: "error",
                          variant: "field",
                          title: n.meta.error
                        })
                      ]
                    });
                  }
                })
              ]
            }),
            s != null && s.country_of_birth ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: zt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "country_of_birth",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(at, {
                        value: n.input.value,
                        //TODO check the types of pixel
                        onChange: (h) => n.input.onChange(h),
                        options: e,
                        searchAttribute: "label",
                        variant: "autocomplete",
                        disabled: o == null ? void 0 : o.country_of_birth
                      }),
                      (n.meta.error && n.meta.touched || (v == null ? void 0 : v.country_of_birth)) && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: v != null && v.country_of_birth ? Ue : n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.marital_status ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Ft
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "marital_status",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(He, {
                        name: n.input.name,
                        value: n.input.value,
                        onChange: (h) => n.input.onChange(h),
                        options: Ie,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.relation_name ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: ((w = (y = f.values) == null ? void 0 : y.marital_status) == null ? void 0 : w.value) === "married" ? Gt : Zt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "relation_name",
                  validate: Ce(ce, Re(nn)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(ge, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        maxLength: 70,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                }),
                ((V = f.values.marital_status) == null ? void 0 : V.value) === "married" && /* @__PURE__ */ t.jsxs("div", {
                  className: "pt-3",
                  children: [
                    /* @__PURE__ */ t.jsx(ie, {
                      message: Wt
                    }),
                    /* @__PURE__ */ t.jsx(re, {
                      type: "radio",
                      name: "relation_type",
                      validate: ce,
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (n) => {
                        var h;
                        return /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Me, {
                              name: n.input.name,
                              size: "sm",
                              value: n.input.value,
                              checked: ae(je, ((h = r == null ? void 0 : r.relation_type) == null ? void 0 : h.value) || ""),
                              onChange: (b, C) => {
                                n.input.onChange(C);
                              },
                              group: je
                            }),
                            n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                              type: "error",
                              variant: "field",
                              title: n.meta.error
                            })
                          ]
                        });
                      }
                    })
                  ]
                })
              ]
            }),
            s != null && s.mother_name ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Xt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "mother_name",
                  validate: Ce(ce, Re("Invalid Name")),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(ge, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        maxLength: 70,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.place_of_birth ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Qt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "place_of_birth",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(ge, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        placeholder: en,
                        maxLength: 60,
                        status: n.meta.error && n.meta.touched ? "error" : void 0,
                        disabled: o == null ? void 0 : o.place_of_birth
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.pep_details ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Yt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "pep_details",
                  validate: ce,
                  validateFields: [],
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(He, {
                        name: n.input.name,
                        value: n.input.value,
                        onChange: (h) => {
                          n.input.onChange(h);
                        },
                        options: be,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(ee, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.tax_status ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Jt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "tax_status",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ t.jsx(t.Fragment, {
                    children: /* @__PURE__ */ t.jsx(ge, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: "Resident Indian",
                      disabled: !0
                    })
                  })
                })
              ]
            }),
            x && /* @__PURE__ */ t.jsx(ee, {
              type: "error",
              variant: "field",
              title: x
            }),
            /* @__PURE__ */ t.jsx(he, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const n = Object.keys(f.errors || {});
                n.length && ut(n[0]);
              },
              customClass: "mb-8",
              children: rn
            })
          ]
        });
      }
    })
  });
}, xn = (e) => {
  var $;
  const { user: r, isPartner: c, isGroupInvestment: o, isKycCompliant: s, isKycOnly: d, onCompletion: x } = e, { fpapi: v } = Q(me), f = Q(Ke), { AppState: { username: y, employer: w, tenant: V }, setUsername: n } = Q(_e), h = f == null ? void 0 : f.loading, b = ($ = f == null ? void 0 : f.errors) == null ? void 0 : $.fullPageError, C = f == null ? void 0 : f.handleSetLoading, U = f == null ? void 0 : f.handleSetErrors, { countries: H, investorProfile: I, kycReq: N, emailData: D, phoneData: G } = fn(e), P = hn({
    ...e,
    investorProfile: I,
    kycReq: N,
    countries: H
  }), j = yn({
    ...e,
    investorProfile: I,
    kycReq: N
  }), O = gn(e), A = (l) => {
    var te, M, Y, J, B, ne;
    const E = {
      name: l.name,
      pan: (te = r == null ? void 0 : r.username) == null ? void 0 : te.toUpperCase(),
      email: D == null ? void 0 : D.email,
      date_of_birth: Ve(l == null ? void 0 : l.date_of_birth),
      mobile: {
        isd: `+${G == null ? void 0 : G.isd}`,
        number: G == null ? void 0 : G.number
      },
      gender: (M = l == null ? void 0 : l.gender) == null ? void 0 : M.value,
      country_of_birth: (Y = l == null ? void 0 : l.country_of_birth) == null ? void 0 : Y.ansi_code,
      residential_status: l == null ? void 0 : l.tax_status,
      marital_status: (J = l == null ? void 0 : l.marital_status) == null ? void 0 : J.value,
      mother_name: l == null ? void 0 : l.mother_name
    }, z = ((B = l == null ? void 0 : l.marital_status) == null ? void 0 : B.value) !== "married", Z = {
      spouse: "spouse_name",
      father: "father_name"
    };
    return z ? E[Z.father] = l == null ? void 0 : l.relation_name : E[Z[(ne = l == null ? void 0 : l.relation_type) == null ? void 0 : ne.value]] = l == null ? void 0 : l.relation_name, N ? v.fpClient.kyc_requests().update(N == null ? void 0 : N.id, E) : v.fpClient.kyc_requests().create(E);
  }, T = (l) => {
    var Z, te, M;
    const E = {
      id: r == null ? void 0 : r.profile,
      name: l == null ? void 0 : l.name,
      date_of_birth: Ve(l == null ? void 0 : l.date_of_birth),
      country_of_birth: (Z = l == null ? void 0 : l.country_of_birth) == null ? void 0 : Z.ansi_code,
      place_of_birth: l == null ? void 0 : l.place_of_birth,
      gender: (te = l == null ? void 0 : l.gender) == null ? void 0 : te.value,
      pep_details: (M = l == null ? void 0 : l.pep_details) == null ? void 0 : M.value,
      tax_status: l == null ? void 0 : l.tax_status
    };
    o && (w != null && w.id) && (E.employer = w == null ? void 0 : w.id);
    const z = {
      tax_status: I == null ? void 0 : I.tax_status,
      date_of_birth: I == null ? void 0 : I.date_of_birth,
      country_of_birth: I == null ? void 0 : I.country_of_birth,
      place_of_birth: I == null ? void 0 : I.place_of_birth,
      employer: I == null ? void 0 : I.employer
    };
    for (const [Y, J] of Object.entries(z))
      J && delete E[Y];
    return v.fpClient.investor_profiles().update(E);
  }, R = async () => {
    const l = r.id, E = {
      ...r == null ? void 0 : r.annexure,
      completed_step: "personal_details"
    };
    c && (E.partner_completed_step = "personal_details");
    const z = await v.updateUser(l, E, null, V);
    if (z)
      return z;
    throw new Error(z);
  }, _ = async (l) => {
    const z = {
      primary_investor: l == null ? void 0 : l.profile,
      holding_pattern: "single"
    };
    return await u(l) ? null : await v.fpClient.mf_investment_accounts().create(z);
  }, u = async (l) => {
    var Y;
    const E = (Y = l == null ? void 0 : l.username) == null ? void 0 : Y.toUpperCase(), z = l == null ? void 0 : l.profile;
    let Z;
    const te = await v.fpClient.mf_investment_accounts().fetchAll({
      investor: E
    }), M = te == null ? void 0 : te.data;
    return M != null && M.length && (Z = M.find((J) => J.primary_investor === z)), Z;
  }, q = async (l) => {
    try {
      if (C == null || C(!0), U == null || U(null), d) {
        const E = await A(l);
        x({
          updatedKycDetails: E
        });
      } else if (s) {
        const E = await T(l);
        await _(r), await R(), !y && n(E == null ? void 0 : E.name), x();
      } else {
        const E = [
          A(l),
          T(l)
        ], [z, Z] = await Promise.all(E);
        await _(r), await R(), n(Z == null ? void 0 : Z.name), x({
          updatedKycDetails: z
        });
      }
    } catch (E) {
      window.debug.error(E), U == null || U({
        formError: an
      }), F(E), mt(E) && window.location.reload();
    } finally {
      C == null || C(!1);
    }
  };
  return h ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : b ? /* @__PURE__ */ t.jsx(Se, {
    title: b.title,
    description: b.description,
    actionText: b.retryText,
    actionHandler: () => window.location.reload()
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(ye, {
        title: on
      }),
      /* @__PURE__ */ t.jsx(Te, {
        type: "text",
        text: c ? ln : cn
      }),
      /* @__PURE__ */ t.jsx(bn, {
        countriesOptions: H,
        formInitialValues: P,
        hiddenFields: O,
        disabledFields: j,
        handleSubmit: q,
        errors: f == null ? void 0 : f.errors
      })
    ]
  });
}, wn = (e) => /* @__PURE__ */ t.jsx(Rt, {
  children: /* @__PURE__ */ t.jsx(xn, {
    ...e
  })
}), Gn = ({ user: e, setUser: r, onBoardingComplete: c, isGroupInvestment: o = !1, isPartner: s }) => {
  const { fpapi: d } = Q(me), { AppState: { tenant: x } } = Q(_e), { fp_campaign_id: v } = ht(), { migratedFolioList: f } = Fe({
    user: e
  }), [y, w] = L(null), [V, n] = L({
    open: !1,
    msg: ""
  }), [h, b] = L(""), [C, U] = L([]), [H, I] = L(null), [N, D] = L(null), [G, P] = L(!1), [j, O] = L([]), [A, T] = L(!1), [R, _] = L(null), u = !!H, q = f == null ? void 0 : f[0], X = async (a) => {
    var S, k;
    const m = a == null ? void 0 : a.profile;
    let g, p;
    if (s)
      g = (S = a == null ? void 0 : a.annexure) == null ? void 0 : S.email_address, p = (k = a == null ? void 0 : a.annexure) == null ? void 0 : k.phone_number;
    else {
      const K = await d.fpClient.custom_users().getUserInfo(x);
      g = K == null ? void 0 : K.email, p = K == null ? void 0 : K.phone_number;
    }
    if (g && p) {
      const { data: K } = await d.fpClient.email_addresses().fetchAll({
        profile: m
      }), { data: W } = await d.fpClient.phone_numbers().fetchAll({
        profile: m
      }), le = K.some((de) => de.email === g), se = W.some((de) => de.number === p);
      le || await d.fpClient.email_addresses().create({
        profile: m,
        email: g,
        belongs_to: "self"
      }), se || await d.fpClient.phone_numbers().create({
        profile: m,
        isd: "91",
        number: p,
        belongs_to: "self"
      });
    }
  }, $ = async (a) => {
    const m = pe(a == null ? void 0 : a.annexure);
    if (m != null && m.kycCheckId) {
      const g = await d.fpClient.kyc_checks().fetch(m == null ? void 0 : m.kycCheckId);
      return _t(g.updated_at) ? await d.fpClient.kyc_checks().refetch(m == null ? void 0 : m.kycCheckId) : g;
    } else {
      const g = a == null ? void 0 : a.id, p = {
        pan: a == null ? void 0 : a.username.toUpperCase()
      };
      if (m != null && m.dob) {
        const W = ze(m == null ? void 0 : m.dob, "yyyy-mm-dd");
        p.date_of_birth = W || "";
      }
      const S = await d.fpClient.kyc_checks().create(p), k = {
        ...m,
        kycCheckId: S.id
      }, K = await d.updateUser(g, k, null, x);
      if (K)
        return S;
      throw K;
    }
  }, l = async (a) => {
    var p;
    const m = await d.fpClient.kyc_requests().fetchAll({
      pan: a
    });
    return (p = m == null ? void 0 : m.data) == null ? void 0 : p[0];
  }, E = () => {
    (async () => {
      try {
        T(!0), await X(e);
        try {
          const a = await $(e);
          if (!a.status && a.action === "disallowed")
            n({
              open: !0,
              msg: i._({
                id: "aRH6E5"
              })
            });
          else {
            const g = {
              unavailable: 1,
              rejected: 1,
              legacy: 1,
              unknown: 1
            }, p = [
              "personal_details",
              "financial_details",
              "banking_details",
              "contact_details",
              "nominee_details",
              "nomination_consent",
              "bank_verification"
            ], S = [
              "personal_details",
              "financial_details",
              "banking_details",
              "contact_details",
              "nominee_details",
              "nomination_consent",
              "kyc_file_uploads",
              "kyc_ipv",
              "kyc_esign",
              "bank_verification"
            ];
            if (o && (p.splice(p.length - 1, 0, "employee_declaration"), S.splice(S.length - 1, 0, "employee_declaration")), !a.status && g[a.reason]) {
              const k = await l(e == null ? void 0 : e.username);
              D(k), U(S);
            } else if (!a.status && !g[a.reason]) {
              n({
                open: !0,
                msg: i._({
                  id: "aRH6E5"
                })
              });
              return;
            } else
              I(a), U(p);
          }
        } catch (a) {
          window.debug.error(a), _({
            title: i._({
              id: "ekpG96"
            }),
            description: i._({
              id: "qy/D1g"
            })
          }), F(a);
        }
      } catch (a) {
        window.debug.error(a), _({
          title: i._({
            id: "AsbZJy"
          }),
          description: i._({
            id: "FUy5qx"
          })
        }), F(a);
      } finally {
        T(!1);
      }
    })();
  }, z = (a) => {
    const m = C.indexOf(a);
    return m === -1 ? C[0] : m === C.length - 1 ? "complete" : C[m + 1];
  };
  ue(() => {
    n({
      open: !1,
      msg: ""
    }), E();
  }, []);
  const Z = (a, m) => {
    const g = {
      personal_details: {
        displayStepName: i._({
          id: "8OIPLE"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "personal_details",
        status: "default"
      },
      financial_details: {
        displayStepName: i._({
          id: "SVi5q9"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "financial_details",
        status: "default"
      },
      banking_details: {
        displayStepName: i._({
          id: "uZr1Gq"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "banking_details",
        status: "default"
      },
      contact_details: {
        displayStepName: i._({
          id: "pQFNyT"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "contact_details",
        status: "default"
      },
      nominee_details: {
        displayStepName: i._({
          id: "s5ouGu"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "nominee_details",
        status: "default"
      },
      nomination_consent: {
        displayStepName: i._({
          id: "3CN1Zg"
        }),
        displayStatus: "default",
        displayIsHidden: !0,
        isSubStepOf: "nominee_details",
        stepValue: "nomination_consent",
        status: "default"
      },
      kyc_file_uploads: {
        displayStepName: i._({
          id: "4yTZPQ"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "kyc_file_uploads",
        status: "default"
      },
      kyc_ipv: {
        displayStepName: i._({
          id: "UMLkVw"
        }),
        displayStatus: "default",
        displayIsHidden: !0,
        isSubStepOf: "kyc_file_uploads",
        stepValue: "kyc_ipv",
        status: "default"
      },
      kyc_esign: {
        displayStepName: i._({
          id: "CFyuuK"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "kyc_esign",
        status: "default"
      },
      employee_declaration: {
        displayStepName: i._({
          id: "Ql7sRy"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "employee_declaration",
        status: "default"
      },
      bank_verification: {
        displayStepName: i._({
          id: "BR53Z/"
        }),
        displayStatus: "default",
        displayIsHidden: !0,
        stepValue: "bank_verification",
        status: "default"
      }
    }, p = [];
    for (const K of a)
      K in g && p.push(g[K]);
    const S = p.findIndex((K) => K.stepValue === m), k = [];
    return p.forEach((K, W) => {
      if (W < S)
        k.push({
          ...K,
          status: "success",
          displayStatus: "success"
        });
      else if (W === S) {
        if (k.push({
          ...K,
          status: "resume",
          displayStatus: "resume"
        }), K.displayIsHidden && K.isSubStepOf) {
          const se = k.findIndex((de) => de.stepValue === K.isSubStepOf);
          se && (k[se] = {
            ...k[se],
            displayStatus: "resume"
          });
        }
      } else
        k.push({
          ...K
        });
    }), k;
  };
  ue(() => {
    if (C.length) {
      T(!0);
      const a = e == null ? void 0 : e.annexure, m = s ? a == null ? void 0 : a.partner_completed_step : a == null ? void 0 : a.completed_step, g = z(m);
      if (s && (a == null ? void 0 : a.partner_completed_step) === "nominee_details") {
        w("partner_actions"), T(!1);
        return;
      }
      g === "complete" ? B() : (P(!pt.isRedirected()), O(Z(C, g)), w(g), T(!1));
    }
  }, [
    C
  ]), ue(() => {
    [
      "rejected",
      "expired"
    ].includes(N == null ? void 0 : N.status) && (P(!1), w(`kyc_${N == null ? void 0 : N.status}`));
  }, [
    N == null ? void 0 : N.status
  ]);
  const te = () => {
    var m;
    const a = window.location.pathname + window.location.search;
    (m = d == null ? void 0 : d.userAuthService) == null || m.initiateLogout(a).then((g) => {
      window.location.href = g.userState;
    });
  }, M = async () => {
    var a;
    if (s && (e != null && e.username)) {
      let m = null, g = null;
      try {
        const [{ data: p }, S] = await Promise.all([
          d.fpClient.mf_investment_accounts().fetchAll({
            investor: e.username.toUpperCase()
          }),
          d.fetchUser(e.username, x)
        ]);
        p != null && p.length && S.length && (m = p[0], g = S[0]);
      } catch (p) {
        window.debug.log(p), F(p);
      } finally {
        const p = ((a = g == null ? void 0 : g.annexure) == null ? void 0 : a.isonboarded) === "true";
        if (m && p) {
          const S = await d.fpClient.investor_profiles().fetch(e == null ? void 0 : e.profile);
          S && c(S, [
            m
          ], g);
        } else
          b(`The investor is not onboarded as per our records. ${H ? "Investor will be required complete nominee consent" : "Investor will be required to complete nominee consent and KYC"} to complete onboarding.`);
      }
    }
  }, Y = async (a) => {
    var K;
    const m = (K = a == null ? void 0 : a.username) == null ? void 0 : K.toUpperCase(), g = a == null ? void 0 : a.profile;
    let p;
    const S = await d.fpClient.mf_investment_accounts().fetchAll({
      investor: m
    }), k = S == null ? void 0 : S.data;
    return k != null && k.length && (p = k.find((W) => W.primary_investor === g)), p;
  }, J = async (a) => {
    const m = a.id, g = a == null ? void 0 : a.profile, p = pe(a == null ? void 0 : a.annexure), S = p == null ? void 0 : p.verified_bac, k = [
      d.fpClient.investor_profiles().fetch(g)
    ], [K] = await Promise.all(k);
    let W;
    const le = await Y(a);
    le && (W = await d.fpClient.mf_investment_accounts().update({
      id: le == null ? void 0 : le.id,
      folio_defaults: {
        payout_bank_account: S || null
      }
    }));
    const se = {
      fp_identifier: g,
      fp_user_type: "investor",
      fp_campaign_id: v,
      isonboarded: !0
    };
    o && (p != null && p.employeeDeclarationTimeStamp) && (se.employeeDeclarationTimeStamp = p == null ? void 0 : p.employeeDeclarationTimeStamp);
    const de = {
      id: m,
      annexure: {
        ...se
      }
    };
    try {
      const xe = await d.fpClient.custom_users().update(de, x);
      return {
        investorProfile: K,
        investmentAccount: W,
        updatedUser: xe
      };
    } catch {
      throw new Error("Failed to update user");
    }
  }, B = (a) => {
    const m = a == null ? void 0 : a.updatedKycDetails;
    if ([
      "rejected",
      "expired"
    ].includes(m == null ? void 0 : m.status)) {
      D(m);
      return;
    }
    (async () => {
      var g;
      try {
        T(!0);
        const p = await d.fetchUser(e.username, x);
        if (p.length) {
          const S = pe((g = p[0]) == null ? void 0 : g.annexure), k = s ? S.partner_completed_step : S.completed_step, K = z(k);
          if (s && S.partner_completed_step === "nominee_details") {
            w("partner_actions");
            return;
          }
          if (K === "complete") {
            const { investorProfile: W, investmentAccount: le, updatedUser: se } = await J(p[0]);
            c(W, [
              le
            ], se);
          } else
            w(K), r(p[0]), m && D(m);
        }
      } catch (p) {
        window.debug.error(p), _({
          title: i._({
            id: "AsbZJy"
          }),
          description: i._({
            id: "bARUyC"
          })
        }), F(p);
      } finally {
        T(!1);
      }
    })();
  }, ne = async (a) => {
    const m = ft(a);
    return d.fpClient.kyc_requests().create(m);
  }, Be = (a, m) => {
    const g = a.indexOf(m);
    if (g !== -1)
      return g - 1 < 0 ? a[0] : a[g - 1];
  }, Le = async (a) => {
    const m = e == null ? void 0 : e.id, p = {
      ...pe(e == null ? void 0 : e.annexure),
      completed_step: Be(C, a)
    };
    if (s && a) {
      const k = Be([
        "personal_details",
        "financial_details",
        "banking_details",
        "nominee_details"
      ], a);
      k && (p.partner_completed_step = k);
    }
    return d.updateUser(m, p, null, x);
  }, Oe = (a) => {
    var m, g;
    if (a) {
      if ([
        "expired",
        "rejected"
      ].includes(a == null ? void 0 : a.status))
        return `kyc_${a == null ? void 0 : a.status}`;
      if ((m = a == null ? void 0 : a.requirements) != null && m.fields_needed.length) {
        const p = (g = a == null ? void 0 : a.requirements) == null ? void 0 : g.fields_needed, de = {
          personal_details: [
            "name",
            "date_of_birth",
            "gender",
            "country_of_birth",
            "gender",
            "residential_status",
            "marital_status",
            "father_name_or_spouse_name",
            "mother_name"
          ],
          financial_details: [
            "occupation_type"
          ],
          banking_details: [
            "bank_account"
          ],
          contact_details: [
            "address",
            "aadhaar_number"
          ],
          kyc_file_uploads: [
            "identity_proof",
            "photo",
            "signature"
          ],
          kyc_ipv: [
            "geolocation",
            "ipv_video"
          ]
        };
        for (const [xe, Ye] of Object.entries(de))
          for (const Je of Ye)
            if (p.includes(Je))
              return xe;
        return;
      } else
        return "kyc_esign";
    } else return "personal_details";
  }, Ge = async () => {
    var a;
    try {
      T(!0);
      const m = await d.fpClient.kyc_requests().fetchAll({
        pan: N == null ? void 0 : N.pan
      }), g = (a = m == null ? void 0 : m.data) == null ? void 0 : a.find((k) => [
        "pending",
        "esign_required",
        "submitted",
        "successful"
      ].includes(k == null ? void 0 : k.status));
      let p;
      g ? p = g : p = await ne(N);
      const S = Oe(p);
      S && [
        "contact_details",
        "kyc_ipv"
      ].includes(S) ? (await Le(S), B({
        updatedKycDetails: p
      })) : B({
        updatedKycDetails: p
      });
    } catch (m) {
      window.debug.error(m), _({
        title: i._({
          id: "41NoE9"
        }),
        description: i._({
          id: "7QMExb"
        })
      }), F(m), T(!1);
    }
  }, Ze = async () => {
    var a;
    try {
      T(!0);
      const m = await d.fpClient.kyc_requests().fetchAll({
        pan: N == null ? void 0 : N.pan
      }), g = (a = m == null ? void 0 : m.data) == null ? void 0 : a.find((k) => [
        "pending",
        "esign_required",
        "submitted",
        "successful"
      ].includes(k == null ? void 0 : k.status));
      let p;
      g ? p = g : p = await ne(N);
      const S = Oe(p);
      S && (await Le(S), B({
        updatedKycDetails: p
      }));
    } catch (m) {
      window.debug.error(m), _({
        title: i._({
          id: "41NoE9"
        }),
        description: i._({
          id: "7QMExb"
        })
      }), F(m), T(!1);
    }
  };
  function Qe() {
    switch (y) {
      case "personal_details":
        return /* @__PURE__ */ t.jsx(wn, {
          user: e,
          onCompletion: B,
          kycCheckData: H,
          isPartner: s,
          isGroupInvestment: o,
          isKycCompliant: u
        });
      case "financial_details":
        return /* @__PURE__ */ t.jsx(Nt, {
          user: e,
          onCompletion: B,
          isGroupInvestment: o,
          isPartner: s,
          isKycCompliant: u,
          kycDetails: N,
          folioDetails: q
        });
      case "banking_details":
        return /* @__PURE__ */ t.jsx(St, {
          user: e,
          onCompletion: B,
          isGroupInvestment: o,
          isPartner: s,
          isKycCompliant: u,
          kycDetails: N,
          folioDetails: q
        });
      case "contact_details":
        return /* @__PURE__ */ t.jsx(jt, {
          user: e,
          onCompletion: B,
          kycCheckData: H,
          isPartner: s,
          isGroupInvestment: o,
          isKycCompliant: u,
          kycDetails: N
        });
      case "nominee_details":
        return /* @__PURE__ */ t.jsx(Lt, {
          user: e,
          onCompletion: B,
          isKycCompliant: u,
          isPartner: s,
          folioDetails: q
        });
      case "nomination_consent":
        return /* @__PURE__ */ t.jsx(Bt, {
          user: e,
          onCompletion: B,
          isGroupInvestment: o
        });
      case "kyc_file_uploads":
        return /* @__PURE__ */ t.jsx(wt, {
          user: e,
          kycDetails: N,
          onCompletion: B
        });
      case "kyc_ipv":
        return /* @__PURE__ */ t.jsx(xt, {
          user: e,
          kycDetails: N,
          onCompletion: B
        });
      case "kyc_esign":
        return /* @__PURE__ */ t.jsx(bt, {
          user: e,
          kycDetails: N,
          onCompletion: B
        });
      case "bank_verification":
        return /* @__PURE__ */ t.jsx(Kt, {
          user: e,
          folioDetails: q,
          isPartner: s,
          isGroupInvestment: o,
          onCompletion: B
        });
      case "employee_declaration":
        return /* @__PURE__ */ t.jsx(Ot, {
          user: e,
          isKycCompliant: u,
          onConsent: () => {
            B();
          }
        });
      case "partner_actions":
        return /* @__PURE__ */ t.jsx(Mt, {
          user: e,
          handleInvestNow: M,
          handleGoBack: B,
          error: h
        });
      case "kyc_rejected":
      case "kyc_expired":
        return /* @__PURE__ */ t.jsx(yt, {
          kycRequestObject: N,
          handleRejectedAction: Ze,
          handleExpiredAction: Ge
        });
      default:
        return;
    }
  }
  return A ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : R ? /* @__PURE__ */ t.jsx(t.Fragment, {
    children: /* @__PURE__ */ t.jsx(Se, {
      title: R == null ? void 0 : R.title,
      description: R == null ? void 0 : R.description,
      showAction: !0,
      actionText: i._({
        id: "90uUa5"
      }),
      actionHandler: () => window.location.reload()
    })
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      !V.open && G ? /* @__PURE__ */ t.jsx(Ht, {
        steps: j,
        onSummaryComplete: (a) => {
          P(!1), w(a);
        },
        title: i._({
          id: "KfaM8Q"
        }),
        subTitle: i._({
          id: "g0UOmR"
        })
      }) : Qe(),
      V.open && /* @__PURE__ */ t.jsx(t.Fragment, {
        children: /* @__PURE__ */ t.jsxs("div", {
          children: [
            /* @__PURE__ */ t.jsx(st, {
              name: "Error",
              className: "mx-auto mb-4 block h-12 w-12"
            }),
            /* @__PURE__ */ t.jsx("h3", {
              className: "text-primary-DN0 text-size-20 font-opensans dark:text-primary-DN110 mb-6 text-center font-semibold",
              children: V.msg
            }),
            /* @__PURE__ */ t.jsx("div", {
              children: /* @__PURE__ */ t.jsx("div", {
                children: /* @__PURE__ */ t.jsx(he, {
                  variant: "secondary",
                  onClick: te,
                  fullwidth: !0,
                  children: i._({
                    id: "4f668r"
                  })
                })
              })
            })
          ]
        })
      })
    ]
  });
};
export {
  Gn as O
};
