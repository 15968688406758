import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as c, useMemo as je, useContext as V, useEffect as oe } from "react";
import { a as ce, e as ae, T as ve, M as W, q as Ae, B as te, k as Je, l as Fe, R as Ke, r as Ne, b as ye, P as be, S as Ee, c as re, D as Le } from "./index.esm-CqWfms5D.js";
import { AppContext as Me } from "./AppContext.js";
import { u as pe } from "./useConfiguration-B-lyaBB9.js";
import { F as ue } from "./provider-DKLaKIVD.js";
import { r as X } from "./sentry-CstOwvcR.js";
import { LoginContext as we } from "./LoginContext.js";
import { i as H, A as J, $ as Xe, z as qe, _ as Pe, m as Ce, a0 as ke, a1 as Ge, a2 as Ye, a3 as et, a4 as tt, a5 as nt, k as ge, a6 as Ue, a7 as Oe, a8 as at, a9 as it, aa as rt, B as Se, ab as he, d as $e } from "./utils-C2gHLhxV.js";
import { i as t } from "./index-CrDCxF0S.js";
import { c as Te } from "./checkMigratedUser-D-TLMNz-.js";
import { p as He } from "./partiallyOnboardMigratedUser-CSHFHhW4.js";
import { H as ze } from "./Holdings-Bzl8QuFg.js";
import { ErrorPage as se } from "./ErrorPage.js";
import { O as We } from "./OrderStatus-C3VoFfP1.js";
import { F as De } from "./FolioListing-DEREzZbs.js";
import { A as Ve } from "./Auth-CJPsuV-i.js";
import { P as st } from "./PartnerLogin-D3m53h1t.js";
import { f as fe, r as _e } from "./validators-BNinzCrT.js";
import { R as Ie, F as ie } from "./react-final-form.es-CUo9SHJd.js";
import { u as Re } from "./useMigratedUser-RRfxTYvK.js";
import { R as ot, a as lt } from "./RedemptionPlanConsent-BhmHdgnT.js";
import { P as Be } from "./Product-DeVtlxIM.js";
import { W as dt } from "./Welcome-Bz7XIwKh.js";
function ct({ scheme_details: i, investment_account_id: a, user: j, holdings: D, selectedFolio: g, isPartner: m, onSuccess: h }) {
  const [I, C] = c(!1), [$, R] = c("initial"), [v, r] = c(!1), b = je(() => E(D), [
    D
  ]), { fpapi: M } = V(ue);
  pe();
  const { loggedInPartner: w, nonPartnerDashboardUser: Q } = V(we), { isMigratedUser: B } = Re({
    user: j
  }), [A, U] = c(!1), K = (s) => {
    var d, x;
    if (B) {
      const f = (d = D == null ? void 0 : D.folios) == null ? void 0 : d.filter((N) => N.folio_number === s), u = f.length ? (x = f[0].schemes) == null ? void 0 : x.find((N) => N.isin === i.isin) : [];
      H(u) ? U(!0) : U(!1);
    }
  };
  oe(() => {
    K(g);
  }, [
    g,
    B
  ]);
  function E(s) {
    if (s) {
      const d = {};
      return s.folios.forEach((x) => {
        x.schemes.forEach((f) => {
          f.isin === i.isin && (d[x.folio_number] = f);
        });
      }), d;
    }
  }
  async function F({ amount: s }) {
    C(!0);
    try {
      const d = {
        mf_investment_account: a,
        scheme: i.isin,
        amount: v ? null : s,
        folio_number: g,
        order_gateway: "rta"
      };
      m && w && !Q && (d.partner = w);
      const x = await M.fpClient.mf_redemptions().create(d);
      C(!1), h({
        createdOrder: x,
        shouldOnboardMigratedUser: A
      });
    } catch (d) {
      C(!1), R("error"), X(d);
    }
  }
  const L = (s) => {
    s.stopPropagation(), r(!v);
  };
  function k() {
    return /* @__PURE__ */ e.jsx(Ie, {
      onSubmit: F,
      validate: (s) => {
        const d = {};
        return v || (s.amount || (d.amount = t._({
          id: "7rkFUv"
        })), s.amount && s.amount < i.min_withdrawal_amount && (d.amount = t._({
          id: "u4n9vI",
          values: {
            0: J(i.min_withdrawal_amount)
          }
        })), s.amount && s.amount > i.max_withdrawal_amount && (d.amount = t._({
          id: "uMQeWd",
          values: {
            0: J(i.max_withdrawal_amount)
          }
        })), s.amount && b && g && s.amount > b[g].market_value.redeemable_amount && (d.amount = t._({
          id: "IJDIUX",
          values: {
            0: J(b[g].market_value.redeemable_amount)
          }
        })), Xe(s.amount, i.withdrawal_multiples) === !1 && (d.amount = t._({
          id: "k9cLeP",
          values: {
            0: J(i.withdrawal_multiples)
          }
        }))), d;
      },
      children: (s) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: s.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "KqeLJ1"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "amount",
                children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ve, {
                      id: d.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: d.input.name,
                      value: v ? "" : qe(d.input.value),
                      onChange: (x) => d.input.onChange(Pe(x.target.value)),
                      onInput: (x) => {
                        x.target.value = x.target.value.replace(Ce("0-9"), "");
                      },
                      placeholder: t._({
                        id: "fkzBBC"
                      }),
                      prefix: "₹",
                      disabled: v,
                      status: d.meta.error && d.meta.touched ? "error" : void 0
                    }),
                    d.meta.error && d.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(W, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    }) : g && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "AfhYy2",
                        values: {
                          0: J(Number(b && b[g].market_value.redeemable_amount))
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(Ae, {
            id: "redeem-all-id",
            checked: v,
            onChange: L,
            children: t._({
              id: "GAUkI4"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: (!g || b && b[g].market_value.redeemable_amount <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function S() {
    switch ($) {
      case "initial":
        return k();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (s) => R("initial")
        });
      default:
        return k();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: I ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: S()
    })
  });
}
function ut({ scheme_details: i, investment_account_id: a, user: j, holdings: D, selectedFolio: g, onSuccess: m, isPartner: h }) {
  const [I, C] = c(!1), [$, R] = c("initial"), [v, r] = c(!1), b = je(() => L(), [
    D
  ]), { fpapi: M } = V(ue);
  pe();
  const { loggedInPartner: w, nonPartnerDashboardUser: Q } = V(we), { isMigratedUser: B, migratedMfiaList: A, migratedFolioList: U } = Re({
    user: j
  }), [K, E] = c(!1), F = (f) => {
    var u, N;
    if (B) {
      const l = (u = D == null ? void 0 : D.folios) == null ? void 0 : u.filter((o) => o.folio_number === f), n = l.length ? (N = l[0].schemes) == null ? void 0 : N.find((o) => o.isin === i.isin) : [];
      H(n) ? E(!0) : E(!1);
    }
  };
  oe(() => {
    F(g);
  }, [
    g,
    B
  ]);
  function L(f) {
    if (D) {
      const u = {};
      return D.folios.forEach((N) => {
        N.schemes.forEach((l) => {
          l.isin === i.isin && (u[N.folio_number] = l);
        });
      }), u;
    }
  }
  async function k({ units: f }) {
    C(!0);
    try {
      const u = {
        mf_investment_account: a,
        scheme: i.isin,
        units: v ? null : f,
        folio_number: g,
        order_gateway: "rta"
      };
      h && w && !Q && (u.partner = w);
      const N = await M.fpClient.mf_redemptions().create(u);
      C(!1), m({
        createdOrder: N,
        shouldOnboardMigratedUser: K
      });
    } catch (u) {
      C(!1), R("error"), X(u);
    }
  }
  const S = (f) => {
    f.stopPropagation(), r(!v);
  }, s = (f, u) => {
    const N = f.target.value;
    ke(N, i.withdrawal_multiples_units) !== 0 ? f.preventDefault() : u.input.onChange(N);
  };
  function d() {
    return /* @__PURE__ */ e.jsx(Ie, {
      onSubmit: k,
      validate: (f) => {
        const u = {};
        return v || (f.units || (u.units = t._({
          id: "7rkFUv"
        })), f.units && f.units < i.min_withdrawal_units && (u.units = t._({
          id: "8Drd8Q",
          values: {
            0: i.min_withdrawal_units
          }
        })), f.units && f.units > i.max_withdrawal_units && (u.units = t._({
          id: "qpXVn6",
          values: {
            0: i.max_withdrawal_units
          }
        })), f.units && b && g && f.units > b[g].holdings.redeemable_units && (u.units = t._({
          id: "XuD/KQ",
          values: {
            0: b[g].holdings.redeemable_units
          }
        })), f.units && ke(f.units, i.withdrawal_multiples_units) !== 0 && (u.units = t._({
          id: "Cj+4Fk",
          values: {
            0: i.withdrawal_multiples_units.toString().split(".")[1].length
          }
        }))), u;
      },
      children: (f) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: f.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "6ssn5V"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "units",
                children: (u) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ve, {
                      id: u.input.name,
                      autoComplete: "off",
                      type: "number",
                      step: "any",
                      name: u.input.name,
                      value: v ? "" : u.input.value,
                      onChange: (N) => s(N, u),
                      placeholder: t._({
                        id: "wRfQ3q"
                      }),
                      disabled: v,
                      status: u.meta.error && u.meta.touched ? "error" : void 0
                    }),
                    u.meta.error && u.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(W, {
                        type: "error",
                        variant: "field",
                        title: u.meta.error
                      })
                    }) : g && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "+fgc+n",
                        values: {
                          0: b ? b[g].holdings.redeemable_units : 0
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(Ae, {
            id: "redeem-all-id",
            checked: v,
            onChange: S,
            children: t._({
              id: "GAUkI4"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: (!g || b && b[g].holdings.redeemable_units <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function x() {
    switch ($) {
      case "initial":
        return d();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (f) => R("initial")
        });
      default:
        return d();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: I ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: x()
    })
  });
}
function mt({ selectedFolio: i, scheme_details: a, investment_account_id: j, onSuccess: D, isPartner: g, investorProfile: m, holdings: h, user: I }) {
  const [C, $] = c("initial"), R = /* @__PURE__ */ new Date(), v = Ge(a == null ? void 0 : a.swp_frequency_specific_data), [r, b] = c(v[0].value), [M, w] = c(!1), { fpapi: Q } = V(ue), { tenantConfig: B } = pe(), { loggedInPartner: A, nonPartnerDashboardUser: U } = V(we);
  je(() => k(), [
    h
  ]);
  const { isMigratedUser: K } = Re({
    user: I
  }), [E, F] = c(!1), L = (l) => {
    var n, o;
    if (K) {
      const O = (n = h == null ? void 0 : h.folios) == null ? void 0 : n.filter((ee) => ee.folio_number === l), G = O.length ? (o = O[0].schemes) == null ? void 0 : o.find((ee) => ee.isin === a.isin) : [];
      H(G) ? F(!0) : F(!1);
    }
  };
  oe(() => {
    L(i);
  }, [
    i,
    K
  ]);
  function k(l) {
    if (h) {
      const n = {};
      return h.folios.forEach((o) => {
        o.schemes.forEach((O) => {
          O.isin === a.isin && (n[o.folio_number] = O);
        });
      }), n;
    }
  }
  const S = je(() => {
    var l;
    return {
      frequency: v[0],
      swp_installment_type: "perpetual",
      swp_start_date: new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + 1)),
      swp_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + (((l = B == null ? void 0 : B.app_constants) == null ? void 0 : l.plan_max_start_days) || 0))),
      swp_end_date: r === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + 2)) : new Date(R.getFullYear(), R.getMonth() + Ye[r] * (a == null ? void 0 : a.swp_frequency_specific_data[r].min_installments), R.getDate() + 1),
      swp_end_max_date: et(r),
      swp_max_date: new Date(2099, 11, 31)
    };
  }, [
    r,
    v,
    a,
    R
  ]);
  async function s({ amount: l, frequency: n, swp_start_date: o, swp_instl_type: O, installments: G, swp_end_date: ee }) {
    O === "perpetual" ? G = 1200 : O === "fixed" && (G = Oe(o, ee, n.value, a == null ? void 0 : a.swp_frequency_specific_data[n.value].dates));
    let Y = at(o, n.value);
    Y !== null && (Y = ge(Y)), await Q.fetchEmailAndPhone(m);
    const le = {
      mf_investment_account: j,
      scheme: a.isin,
      frequency: n.value,
      folio_number: i,
      amount: l,
      systematic: !0,
      installment_day: n.value === "daily" ? null : it(o, n.value),
      activate_after: Y,
      number_of_installments: +G,
      order_gateway: "rta"
    };
    if (g && A) {
      w(!0);
      let Z = {
        ...le
      };
      Z = {
        ...Z
      }, U || (Z.partner = A);
      try {
        const y = await Q.fpClient.mf_redemption_plans().create(Z);
        w(!1), D({
          createdOrder: y,
          shouldOnboardMigratedUser: E
        });
      } catch (y) {
        window.debug.error(y), w(!1), $("error"), X(y);
      }
    } else {
      w(!0);
      try {
        const Z = await Q.fpClient.mf_redemption_plans().create(le);
        D({
          createdOrder: Z,
          shouldOnboardMigratedUser: E
        }), w(!1);
      } catch (Z) {
        window.debug.error(Z), w(!1), $("error"), w(!1), X(Z);
      }
    }
  }
  const d = ([l, n], o, { changeValue: O }) => {
    n[0] = n[0] === null ? S.swp_start_date : n[0], n[0] = Ue(n[0], n[1], a == null ? void 0 : a.swp_frequency_specific_data[n[1]].min_installments), O(o, l, () => n[0]);
  }, x = ([l, n], o, { changeValue: O }) => {
    n[0] !== null && (n[0] = rt(n[1], a == null ? void 0 : a.swp_frequency_specific_data[n[1]].dates)), O(o, l, () => n[0]);
  }, f = (l, n) => Oe(l || S.swp_start_date, n, r, a == null ? void 0 : a.swp_frequency_specific_data[r].dates) <= a.swp_frequency_specific_data[r].min_installments;
  function u() {
    return /* @__PURE__ */ e.jsx(Ie, {
      onSubmit: s,
      mutators: {
        updateSwpEndDate_mutator: d,
        updateSwpStartDate_mutator: x
      },
      validate: (l) => {
        var o;
        const n = {};
        return l.amount || (n.amount = t._({
          id: "7rkFUv"
        })), l.amount && ke(l.amount, (o = a == null ? void 0 : a.swp_frequency_specific_data[r]) == null ? void 0 : o.amount_multiples) !== 0 && (n.amount = t._({
          id: "k9cLeP",
          values: {
            0: a.swp_frequency_specific_data[r].amount_multiples
          }
        })), l.amount && l.amount < (a == null ? void 0 : a.swp_frequency_specific_data[r].min_installment_amount) && (n.amount = t._({
          id: "xegGnh",
          values: {
            0: a.swp_frequency_specific_data[r].min_installment_amount
          }
        })), l.amount && i && l.amount > (a == null ? void 0 : a.swp_frequency_specific_data[r].max_installment_amount) && (n.amount = t._({
          id: "lR8aoB",
          values: {
            0: J(Number(a == null ? void 0 : a.swp_frequency_specific_data[r].max_installment_amount))
          }
        })), l.installments < (a == null ? void 0 : a.swp_frequency_specific_data[r].min_installments) && (n.installments = t._({
          id: "MpguFb",
          values: {
            0: a == null ? void 0 : a.swp_frequency_specific_data[r].min_installments
          }
        })), l.installments > 1200 && (n.installments = t._({
          id: "j7AzBN"
        })), l.swp_end_date < l.swp_start_date && (n.swp_end_date = t._({
          id: "T31Ev8"
        })), n;
      },
      subscription: {
        submitting: !0,
        values: !0
      },
      children: (l) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: l.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "ydIU1z"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "frequency",
                initialValue: S.frequency,
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: fe(_e),
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Je, {
                      name: n.input.name,
                      value: n.input.value,
                      onChange: (o) => {
                        const O = [
                          l.values.swp_start_date,
                          o.value,
                          l.values.swp_end_date
                        ];
                        l.form.mutators.updateSwpStartDate_mutator("swp_start_date", O), l.form.mutators.updateSwpEndDate_mutator("swp_end_date", O), b(o.value), n.input.onChange(o);
                      },
                      options: v
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(W, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    }),
                    n.input.value.value === "daily" && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "pKTj3U"
                      }),
                      customClass: "sm"
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "/DJBcB"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "amount",
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: fe(_e),
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ve, {
                      type: "text",
                      name: n.input.name,
                      value: qe(n.input.value),
                      onChange: (o) => n.input.onChange(Pe(o.target.value)),
                      onInput: (o) => {
                        o.target.value = o.target.value.replace(Ce("0-9"), "");
                      },
                      placeholder: t._({
                        id: "fkzBBC"
                      }),
                      prefix: "₹",
                      autoComplete: "off",
                      status: n.meta.error && n.meta.touched ? "error" : void 0
                    }),
                    n.meta.error && n.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(W, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    }) : i && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "ZdOgV8",
                        values: {
                          0: J(Number(a.swp_frequency_specific_data[r].min_installment_amount), 2)
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "jvN2aB"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "swp_start_date",
                initialValue: null,
                validate: fe(_e),
                allowNull: !0,
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Fe, {
                      variant: "keyboardInputDOB",
                      onKeyDown: (o) => o.preventDefault(),
                      disableFuture: !1,
                      name: n.input.name,
                      value: n.input.value,
                      format: "dd-MMM-yyyy",
                      minDate: S.swp_start_date,
                      maxDate: S.swp_start_max_date,
                      selectedDate: n.input.value || null,
                      error: n.meta.error && n.meta.touched,
                      views: [
                        "date"
                      ],
                      placeholder: "Select start date",
                      labelFunc: (o) => tt(o, r),
                      shouldDisableDate: (o) => nt(o, a == null ? void 0 : a.swp_frequency_specific_data[r].dates, r),
                      handleDateChange: (o) => {
                        f(o, l.values.swp_end_date || S.swp_end_date) && l.form.mutators.updateSwpEndDate_mutator("swp_end_date", [
                          o,
                          r
                        ]), n.input.onChange(o);
                      }
                    }),
                    n.meta.error && n.meta.touched && /* @__PURE__ */ e.jsx(W, {
                      type: "error",
                      variant: "field",
                      title: n.meta.error
                    }),
                    n.input.value && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "GLAJXE",
                        values: {
                          0: ge(n.input.value, "dd-MMM-yyyy")
                        }
                      }),
                      customClass: "sm"
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "G7dHtg"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                type: "radio",
                name: "swp_instl_type",
                initialValue: S.swp_installment_type,
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: fe(_e),
                children: (n) => /* @__PURE__ */ e.jsx(e.Fragment, {
                  children: /* @__PURE__ */ e.jsx(Ke, {
                    name: n.input.name,
                    value: n.input.value,
                    group: [
                      {
                        label: t._({
                          id: "bmk2Z6"
                        }),
                        value: "perpetual"
                      },
                      {
                        label: t._({
                          id: "qF0EYG"
                        }),
                        value: "installments"
                      },
                      {
                        label: t._({
                          id: "kbmFrh"
                        }),
                        value: "fixed"
                      }
                    ],
                    size: "sm",
                    onChange: (o, O) => {
                      n.input.onChange(O.value);
                    }
                  })
                })
              })
            ]
          }),
          l.values.swp_instl_type === "installments" && /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "ZS3Gyi"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "installments",
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validate: fe(_e),
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ve, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      onChange: n.input.onChange,
                      placeholder: t._({
                        id: "M1QsOZ"
                      }),
                      status: n.meta.error && n.meta.touched ? "error" : void 0,
                      onInput: (o) => {
                        o.target.value = o.target.value.replace(Ce("0-9"), "");
                      },
                      inputMode: "numeric"
                    }),
                    n.meta.error && n.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(W, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    }) : i && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "gRc0h2",
                        values: {
                          0: a == null ? void 0 : a.swp_frequency_specific_data[r].min_installments
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          l.values.swp_instl_type === "fixed" && /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ae, {
                message: t._({
                  id: "BrsP9r"
                })
              }),
              /* @__PURE__ */ e.jsx(ie, {
                name: "swp_end_date",
                initialValue: null,
                allowNull: !0,
                children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Fe, {
                      variant: "keyboardInputDOB",
                      onKeyDown: (o) => o.preventDefault(),
                      disableFuture: !1,
                      name: n.input.name,
                      value: n.input.value,
                      format: "dd-MMM-yyyy",
                      minDate: Ue(l.values.swp_start_date, r, a == null ? void 0 : a.swp_frequency_specific_data[r].min_installments),
                      maxDate: S.swp_end_max_date,
                      selectedDate: n.input.value,
                      labelFunc: (o) => o ? ge(o, "dd-MMM-yyyy") : "Select end date",
                      error: n.meta.error && n.meta.touched,
                      views: [
                        "date"
                      ],
                      handleDateChange: (o) => {
                        n.input.onChange(o);
                      }
                    }),
                    n.meta.error && n.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(W, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    }) : i && /* @__PURE__ */ e.jsx(W, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "gRc0h2",
                        values: {
                          0: a == null ? void 0 : a.swp_frequency_specific_data[r].min_installments
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: i ? "primary" : "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function N() {
    switch (C) {
      case "initial":
        return u();
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (l) => {
            b(v[0].value), $("initial");
          }
        });
      default:
        return u();
    }
  }
  return M ? /* @__PURE__ */ e.jsx(ce, {
    variant: "fullpage"
  }) : N();
}
function Qe(i) {
  const { tenantConfig: a } = pe(), j = i.delivery_mode, D = () => /* @__PURE__ */ e.jsx(mt, {
    ...i
  }), g = () => {
    const h = () => /* @__PURE__ */ e.jsx(ct, {
      ...i
    }), I = () => /* @__PURE__ */ e.jsx(ut, {
      ...i
    });
    return j === "Demat" ? I() : /* @__PURE__ */ e.jsx(Ne, {
      variant: "pills",
      group: [
        {
          title: t._({
            id: "9eWti6"
          }),
          content: h()
        },
        {
          title: t._({
            id: "3saf/Z"
          }),
          content: I()
        }
      ]
    });
  }, m = () => {
    var C, $, R, v, r, b;
    const h = (R = ($ = (C = a == null ? void 0 : a.app_constants) == null ? void 0 : C.disabled_orders_mode) == null ? void 0 : $.withdraw) == null ? void 0 : R.one_time, I = (b = (r = (v = a == null ? void 0 : a.app_constants) == null ? void 0 : v.disabled_orders_mode) == null ? void 0 : r.withdraw) == null ? void 0 : b.recurring;
    return j === "Demat" ? [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: g()
      }
    ] : h && I ? [] : h ? [
      {
        title: t._({
          id: "15ZJyn"
        }),
        content: D()
      }
    ] : I ? [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: g()
      }
    ] : [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: g()
      },
      {
        title: t._({
          id: "15ZJyn"
        }),
        content: D()
      }
    ];
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Ne, {
      group: m()
    })
  });
}
function ft({ scheme_details: i, folio: a, isPartner: j }) {
  const { AppState: { tenant: D } } = V(Me), [g, m] = c(() => sessionStorage.getItem("redemption_workflow_stage") || "folio_listing"), [h, I] = c(null), [C, $] = c(null), [R, v] = c(null), [r, b] = c(null), [M, w] = c(null), [Q, B] = c(""), [A, U] = c(!1), [K, E] = c(""), [F, L] = c(() => {
    const _ = sessionStorage.getItem("redemption_workflow_order_id");
    return _ ? JSON.parse(_) : null;
  }), [k, S] = c(!1), [s, d] = c(a), [x, f] = c(null), [u, N] = c(null), { fpapi: l } = V(ue), { loggedInPartner: n } = V(we), [o, O] = c(null);
  oe(() => {
    (async () => j && await G())();
  }, [
    u
  ]);
  async function G() {
    if (!n)
      m("login");
    else if (!u)
      m("investor_pan_input");
    else {
      S(!0);
      let _;
      try {
        const p = await l.fpClient.investor_profiles().fetchAll({
          pan: u
        });
        _ = p == null ? void 0 : p.data[0], I(_);
        const z = await l.fetchUser(_ == null ? void 0 : _.pan, D), de = z == null ? void 0 : z[0], { isMigratedUser: me, migratedMfiaList: xe, migratedFolioList: Ze } = await Te(de, l);
        if (me && await He(l, de, xe, Ze), O(de), !_) {
          S(!1), B(t._({
            id: "Pe37pS"
          })), E(t._({
            id: "S40aCP"
          })), m("error");
          return;
        }
      } catch (p) {
        B(t._({
          id: "BnnSJR"
        })), E(t._({
          id: "NHmBXI"
        })), m("error"), S(!1), X(p);
        return;
      }
      if (H(i)) {
        S(!1), m("holdings");
        return;
      }
      const q = {};
      try {
        const p = await l.fpClient.mf_investment_accounts().fetchAll({
          investor: _.pan
        });
        if (!p.data || !p.data.length) {
          S(!1), B(t._({
            id: "Pe37pS"
          })), E(t._({
            id: "S40aCP"
          })), m("error");
          return;
        }
        $(p.data);
        const z = await l.fpClient.investor_reports().fetchHoldings({
          investment_account_id: p.data[0].old_id
        });
        let de = !1;
        if (z.folios.forEach((me) => {
          me.folio_number === a && (de = !0), me.schemes.forEach((xe) => {
            xe.isin === i.isin && (q[me.folio_number] = xe);
          });
        }), de || (f(null), d(null)), H(q)) {
          S(!1), m("no_holdings");
          return;
        }
        v(z), b(q);
      } catch (p) {
        S(!1), B(t._({
          id: "BnnSJR"
        })), E(t._({
          id: "rAP48C"
        })), m("error"), X(p);
        return;
      }
      if (F != null && F.id) {
        if (F.object === "mf_redemption")
          try {
            const p = await l.fpClient.mf_redemptions().fetch(F == null ? void 0 : F.id);
            w(p), d(p.folio_number);
          } catch (p) {
            window.debug.error(p), X(p);
          }
        else if (F.object === "mf_redemption_plan")
          try {
            const p = await l.fpClient.mf_redemption_plans().fetch(F == null ? void 0 : F.id);
            w(p), d(p.folio_number);
          } catch (p) {
            window.debug.error(p), X(p);
          }
      }
      S(!1);
    }
  }
  function ee(_) {
    const { createdOrder: q, shouldOnboardMigratedUser: p } = _, z = {
      id: q.id,
      object: q.object
    };
    if (sessionStorage.setItem("redemption_workflow_order_id", JSON.stringify(z)), L(z), w(q), p) {
      w(q), m("onboard_migrated_user");
      return;
    }
    sessionStorage.setItem("redemption_workflow_stage", "finished"), m("finished");
  }
  function Y() {
    sessionStorage.removeItem("redemption_workflow_order_id"), sessionStorage.removeItem("redemption_workflow_stage");
    const q = window.location.href.replace("withdraw", "checkout");
    window.location.href = q;
  }
  function le() {
    sessionStorage.removeItem("redemption_workflow_order_id"), sessionStorage.setItem("redemption_workflow_stage", "order");
  }
  function Z({ pan: _ }) {
    j && (m("folio_listing"), N(_));
  }
  const y = () => {
    var _;
    return !H(x) && (x != null && x.dp_id) || !$e(C) && ((_ = C[0].folio_defaults) != null && _.demat_account) ? "Demat" : "Physical";
  };
  function P() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        ne(),
        !H(r) && /* @__PURE__ */ e.jsx(Qe, {
          investorProfile: h,
          scheme_details: i,
          investment_account_id: C && C[0].id,
          delivery_mode: y(),
          holdings: R,
          user: o,
          selectedFolio: s,
          onSuccess: ee,
          isPartner: j
        })
      ]
    });
  }
  function ne() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        s ? /* @__PURE__ */ e.jsxs(Ee, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "t6MpeI"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: s || "Please choose a folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(te, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (_) => {
                    _.preventDefault(), U(!A);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: t._({
                      id: "ez01Pf"
                    }),
                    value: r && s ? `₹ ${J(r[s].invested_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: t._({
                      id: "jlk/lh"
                    }),
                    value: r && s ? r[s].holdings.units : "-"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: t._({
                      id: "aoh6Eh"
                    }),
                    value: r && s ? `₹ ${J(r[s].market_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: r && s ? t._({
                      id: "/glBYd",
                      values: {
                        0: ge(new Date(r[s].nav.as_on), "dd-MMM-yyyy")
                      }
                    }) : t._({
                      id: "iD9R83"
                    }),
                    value: r && s ? `₹ ${J(r[s].nav.value)}` : "-"
                  })
                })
              ]
            })
          ]
        }) : /* @__PURE__ */ e.jsx(Le, {
          type: "borderedNotes",
          text: t._({
            id: "oytUdD"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            onClick: (_) => {
              _.preventDefault(), U(!A);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        h && /* @__PURE__ */ e.jsx(ye, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "845eUq"
          }),
          isOpen: A,
          toggleModal: U,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(De, {
            investorProfile: h,
            scheme_details: i,
            isPartner: j,
            handleFolioSelection: (_) => {
              f(_), d(_.number), U(!A);
            }
          })
        })
      ]
    });
  }
  function T() {
    switch (g) {
      case "order":
        return P();
      case "login":
        return /* @__PURE__ */ e.jsx(st, {
          context: `${window.location.pathname}${window.location.search}`
        });
      case "investor_pan_input":
        return /* @__PURE__ */ e.jsx(Ve, {
          onPreviewData: Z,
          allowRegistration: !0,
          isPartner: j,
          redirectURL: window.location.href,
          showTestPan: {
            onboarded: !0
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: Q,
          description: K
        });
      case "folio_listing":
        return h && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(ye, {
            customClass: "md:max-w-29",
            variant: "fullPage",
            title: t._({
              id: "aBn5c7"
            }),
            isOpen: !0,
            toggleModal: () => {
              m("initial"), U(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(De, {
              investorProfile: h,
              scheme_details: i,
              isPartner: j,
              handleFolioSelection: (_) => {
                U(!1), f(_), d(_.number), m("order");
              }
            })
          })
        });
      case "finished":
        return !H(i) && !H(M) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(We, {
            scheme: i,
            mf_order: M,
            isPartner: j,
            handleInvestAgain: Y,
            handleRetry: le
          })
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "hfWrIS"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "xuG8bK"
          }),
          actionHandler: Y
        });
      case "holdings":
        return /* @__PURE__ */ e.jsx(ze, {
          investorProfile: h,
          onSchemeSelection: (_) => {
            var q;
            return window.location.href = window.location.origin + window.location.pathname + `?isin=${_}${(q = he(window.location.search)) != null && q.length ? "&" + he(window.location.search) : ""}`;
          }
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(_t, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Se}/onboarding?isPartner=${j}&redirectURL=${encodeURIComponent(`${window.location.origin + Se}/assisted-withdraw/?isin=${i.isin}&isPartner=${j}`)}`;
          }
        });
      default:
        return P();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: k ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: T()
    })
  });
}
const _t = ({ onContinue: i }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(be, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => i(),
        children: "Continue"
      })
    })
  ]
});
function gt({ scheme_details: i, folio: a, isGroupInvestment: j }) {
  const { AppState: { tenant: D } } = V(Me), [g, m] = c("folio_listing"), [h, I] = c(null), [C, $] = c(null), [R, v] = c(null), [r, b] = c(null), [M, w] = c(null), [Q, B] = c(""), [A, U] = c(!1), [K, E] = c(""), [F, L] = c(!1), [k, S] = c(a), [s, d] = c(null), { fpapi: x } = V(ue), { loggedInUser: f } = V(we), [u, N] = c(null), n = new URLSearchParams(window.location.search).get("employer");
  oe(() => {
    async function y() {
      if (!f)
        m("login");
      else {
        L(!0);
        let P;
        try {
          P = await x.getInvestorProfile(), I(P);
          const T = await x.fetchUser(f.profile.preferred_username, D), _ = T == null ? void 0 : T[0], { isMigratedUser: q, migratedMfiaList: p, migratedFolioList: z } = await Te(_, x);
          q && await He(x, _, p, z), N(_);
        } catch (T) {
          B(t._({
            id: "XHIfHi"
          })), E(t._({
            id: "ycsXEB"
          })), m("error"), L(!1), X(T);
          return;
        }
        if (H(i)) {
          L(!1), m("holdings");
          return;
        }
        const ne = {};
        try {
          const T = await x.fpClient.mf_investment_accounts().fetchAll({
            investor: P.pan
          });
          if (!T.data || !T.data.length) {
            L(!1), B(t._({
              id: "qmRkny"
            })), E(t._({
              id: "0f0Xqs"
            })), m("error");
            return;
          }
          $(T.data);
          const _ = await x.fpClient.investor_reports().fetchHoldings({
            investment_account_id: T.data[0].old_id
          });
          let q = !1;
          if (_.folios.forEach((p) => {
            p.folio_number === a && (q = !0), p.schemes.forEach((z) => {
              z.isin === i.isin && (ne[p.folio_number] = z);
            });
          }), q || (d(null), S(null)), H(ne)) {
            L(!1), m("no_holdings");
            return;
          }
          v(_), b(ne);
        } catch (T) {
          L(!1), B(t._({
            id: "XHIfHi"
          })), E(t._({
            id: "6p5tt4"
          })), m("error"), X(T);
          return;
        }
        L(!1);
      }
    }
    y();
  }, [
    f,
    x,
    i,
    a
  ]);
  const o = (y) => {
    w(y), m("finished");
  };
  function O(y) {
    const { createdOrder: P, shouldOnboardMigratedUser: ne } = y;
    if (ne) {
      w(P), m("onboard_migrated_user");
      return;
    }
    P.object === "mf_redemption" ? (w(P), m("redeem_confirmation")) : P.object === "mf_redemption_plan" && (w(P), m("redeem_plan_confirmation"));
  }
  function G() {
    const P = he(window.location.search) ? "group-checkout" : "checkout", T = window.location.href.replace("withdraw", P);
    window.location.href = T;
  }
  const ee = () => {
    var y;
    return !H(s) && (s != null && s.dp_id) || !$e(C) && ((y = C[0].folio_defaults) != null && y.demat_account) ? "Demat" : "Physical";
  };
  function Y() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        le(),
        !H(r) && /* @__PURE__ */ e.jsx(Qe, {
          investorProfile: h,
          scheme_details: i,
          investment_account_id: C && C[0].id,
          delivery_mode: ee(),
          holdings: R,
          user: u,
          selectedFolio: k,
          onSuccess: O
        })
      ]
    });
  }
  function le() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        k ? /* @__PURE__ */ e.jsxs(Ee, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "5WmZx5"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: k ?? "Please choose a folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(te, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (y) => {
                    y.preventDefault(), U(!A);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: t._({
                      id: "ez01Pf"
                    }),
                    value: r && k ? `₹ ${J(r[k].invested_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: t._({
                      id: "jlk/lh"
                    }),
                    value: r && k ? r[k].holdings.units : "-"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: t._({
                      id: "aoh6Eh"
                    }),
                    value: r && k ? `₹ ${J(r[k].market_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(re, {
                    label: r && k ? t._({
                      id: "/glBYd",
                      values: {
                        0: ge(new Date(r[k].nav.as_on), "dd-MMM-yyyy")
                      }
                    }) : t._({
                      id: "iD9R83"
                    }),
                    value: r && k ? `₹ ${J(r[k].nav.value)}` : "-"
                  })
                })
              ]
            })
          ]
        }) : /* @__PURE__ */ e.jsx(Le, {
          type: "borderedNotes",
          text: t._({
            id: "oytUdD"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            onClick: (y) => {
              y.preventDefault(), U(!A);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        h && /* @__PURE__ */ e.jsx(ye, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "845eUq"
          }),
          isOpen: A,
          toggleModal: U,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(De, {
            investorProfile: h,
            scheme_details: i,
            handleFolioSelection: (y) => {
              d(y), S(y.number), U(!A);
            }
          })
        })
      ]
    });
  }
  function Z() {
    switch (g) {
      case "order":
        return Y();
      case "login":
        return /* @__PURE__ */ e.jsx(Ve, {
          redirectURL: window.location.href,
          allowRegistration: !1,
          showTestPan: {
            onboarded: !0
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: Q,
          description: K
        });
      case "redeem_confirmation":
        return /* @__PURE__ */ e.jsx(lt, {
          investorProfile: h,
          id: M == null ? void 0 : M.id,
          showSchemeDetails: !1,
          onSuccess: o,
          action: "confirm",
          objectType: M == null ? void 0 : M.object
        });
      case "redeem_plan_confirmation":
        return /* @__PURE__ */ e.jsx(ot, {
          investorProfile: h,
          id: M == null ? void 0 : M.id,
          onSuccess: o,
          showSchemeDetails: !1,
          action: "confirm"
        });
      case "folio_listing":
        return h && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(ye, {
            customClass: "md:max-w-29",
            variant: "fullPage",
            title: t._({
              id: "aBn5c7"
            }),
            isOpen: !0,
            toggleModal: () => {
              m("initial"), U(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(De, {
              investorProfile: h,
              scheme_details: i,
              handleFolioSelection: (y) => {
                U(!1), d(y), S(y.number), m("order");
              },
              workflow: "withdraw"
            })
          })
        });
      case "finished":
        return !H(i) && !H(M) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(We, {
            scheme: i,
            mf_order: M,
            handleInvestAgain: G,
            handleRetry: () => m("order")
          })
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "idb6Q+"
          }),
          description: t._({
            id: "t7nOza"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "xuG8bK"
          }),
          actionHandler: G
        });
      case "holdings":
        return /* @__PURE__ */ e.jsx(ze, {
          investorProfile: h,
          onSchemeSelection: (y) => {
            var P;
            return window.location.href = window.location.origin + window.location.pathname + `?isin=${y}${(P = he(window.location.search)) != null && P.length ? "&" + he(window.location.search) : ""}`;
          }
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(ht, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Se}/onboarding?${j ? `isGroupInvestment=${j}` : ""}${j ? `&employer=${n}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + Se}/${j ? "group-checkout" : "checkout"}/?isin=${i.isin}${j ? `&employer=${n}` : ""}`)}`;
          }
        });
      default:
        return Y();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: F ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: Z()
    })
  });
}
const ht = ({ onContinue: i }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(be, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => i(),
        children: "Continue"
      })
    })
  ]
});
function $t({ isin: i, folio: a, isPartner: j, isGroupInvestment: D }) {
  const { fpapi: g } = V(ue), { AppState: { showWelcomePage: m }, setShowWelcomePage: h } = V(Me), { tenantConfig: I, amcs: C } = pe(), $ = I == null ? void 0 : I.fallbackLogo, R = I == null ? void 0 : I.showSchemeLogo, [v, r] = c("initial"), [b, M] = c(L), [w, Q] = c(null), [B, A] = c(!1), U = j ? [
    {
      step: 1,
      content: t._({
        id: "RkEWr9"
      })
    },
    {
      step: 2,
      content: t._({
        id: "qc1MEz"
      })
    },
    {
      step: 3,
      content: t._({
        id: "4U14ei"
      })
    },
    {
      step: 4,
      content: t._({
        id: "86z+ns"
      })
    },
    {
      step: 5,
      content: t._({
        id: "/QrtaQ"
      })
    }
  ] : [
    {
      step: 1,
      content: t._({
        id: "RkEWr9"
      })
    },
    {
      step: 2,
      content: t._({
        id: "4U14ei"
      })
    },
    {
      step: 3,
      content: t._({
        id: "86z+ns"
      })
    },
    {
      step: 4,
      content: t._({
        id: "/QrtaQ"
      })
    }
  ];
  oe(() => {
    i && (A(!0), g.fpClient.master_data().fetchScheme(i).then((s) => {
      K(s);
    }).catch((s) => {
      window.debug.log(s), A(!1), X(s);
    }));
  }, []);
  function K(s) {
    if (!s)
      r("schemeNotFound");
    else {
      const d = R ? {
        ...s,
        schemeLogo: C[s == null ? void 0 : s.amc_id] || $
      } : {
        ...s
      };
      Q(d);
    }
    A(!1);
  }
  function E() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(be, {
          title: w ? t._({
            id: "YCamr4"
          }) : t._({
            id: "2eWKIz"
          })
        }),
        /* @__PURE__ */ e.jsx(Be, {
          scheme: w
        }),
        j ? /* @__PURE__ */ e.jsx(ft, {
          scheme_details: w,
          folio: a,
          isPartner: j,
          isGroupInvestment: D
        }) : /* @__PURE__ */ e.jsx(gt, {
          scheme_details: w,
          folio: a,
          isGroupInvestment: D
        })
      ]
    });
  }
  function F() {
    switch (v) {
      case "initial":
        return E();
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "1f+uYj"
          }),
          description: t._({
            id: "3MzgnE"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return E();
    }
  }
  oe(() => {
    M(L());
  }, [
    m
  ]);
  function L() {
    return m ? "welcome" : "folio";
  }
  function k() {
    h(!1), M("withdraw");
  }
  function S() {
    return b === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(be, {
          title: w ? t._({
            id: "YCamr4"
          }) : t._({
            id: "2eWKIz"
          })
        }),
        /* @__PURE__ */ e.jsx(Be, {
          scheme: w
        }),
        /* @__PURE__ */ e.jsx(dt, {
          onAck: k,
          welcomeSteps: U
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: B ? /* @__PURE__ */ e.jsx(ce, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: F()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: S()
  });
}
export {
  $t as Withdraw
};
