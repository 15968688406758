import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as ms, useState as f, useEffect as es } from "react";
import { a as xs, S as ts, c as n, M as vs, i as ls, F as ws, B as ss, p as Xs, C as Q, b as fs, d as Es, r as Zs } from "./index.esm-CqWfms5D.js";
import { u as cs } from "./useConfiguration-B-lyaBB9.js";
import { F as ps } from "./provider-DKLaKIVD.js";
import { r as b } from "./sentry-CstOwvcR.js";
import { m as Ls } from "./MandateListing-DpfjvUeo.js";
import { i as G, h as os, f as q, b as ys, E as Vs, B as Js, d as us, l as Cs, z as gs, k as K, M as Ys } from "./utils-C2gHLhxV.js";
import { u as Ss } from "./useScrollToBottomRef-CqDzM0dm.js";
import { D as Qs } from "./DetailsCard-5b62SM_P.js";
import { i } from "./index-CrDCxF0S.js";
import { AppContext as Rs } from "./AppContext.js";
import { ErrorPage as _s } from "./ErrorPage.js";
import { c as Ks, a as Gs, b as Is } from "./StopPlanStatus-Bp1JyTUu.js";
function js({ currentPlanObject: l, isGroupInvestment: e, isPartner: U }) {
  const { fpapi: w } = ms(ps), { AppState: { tenant: L } } = ms(Rs), [u, x] = f(!1), [N, D] = f([]), [I, M] = f("initial"), [p, S] = f({}), { tenantConfig: o, amcs: r } = cs(), g = o == null ? void 0 : o.fallbackLogo, Z = o == null ? void 0 : o.showSchemeLogo, [H, O] = f(null), V = Ss(H), [P, k] = f(!1), [E, J] = f(10), [C, A] = f([]), [j, $] = f({}), [as, y] = f({});
  es(() => {
    if (V.hasScrolledToBottom) {
      k(!0);
      const a = E + 10;
      if ((N == null ? void 0 : N.length) < (C == null ? void 0 : C.length)) {
        const m = C == null ? void 0 : C.slice(E, a);
        m == null || m.map(async (d) => await t(d)), D((d) => d.concat(m));
      }
      J((m) => m + 10);
    }
    k(!1);
  }, [
    V.hasScrolledToBottom
  ]);
  const t = async (a) => {
    try {
      if ((a == null ? void 0 : a.object) === "mf_purchase") {
        const m = await w.fpClient.payments().fetchAll({
          amc_order_ids: a == null ? void 0 : a.old_id
        });
        G(m == null ? void 0 : m.payments[0]) || y(m == null ? void 0 : m.payments[0]);
      }
    } catch (m) {
      window.debug.error(m), b(m);
    }
  }, h = async () => {
    try {
      x(!0);
      let a = [];
      (l == null ? void 0 : l.object) === "mf_purchase_plan" ? await w.fpClient.mf_purchases().fetchAll({
        plan: l == null ? void 0 : l.id
      }).then((d) => {
        d.data.length <= 0 && M("no_installments"), a = d.data;
      }) : (l == null ? void 0 : l.object) === "mf_redemption_plan" ? await w.fpClient.mf_redemptions().fetchAll({
        plan: l == null ? void 0 : l.id
      }).then((d) => {
        d.data.length <= 0 && M("no_installments"), a = d.data;
      }) : (l == null ? void 0 : l.object) === "mf_switch_plan" && await w.fpClient.mf_switches().fetchAll({
        plan: l == null ? void 0 : l.id
      }).then((d) => {
        d.data.length <= 0 && M("no_installments"), a = d.data;
      }), A(a);
      const m = a == null ? void 0 : a.slice(0, E);
      D(m), m == null || m.map(async (d) => await t(d)), x(!1);
    } catch (a) {
      window.debug.error(a), x(!1), b(a);
    }
  }, R = async () => {
    const a = (l == null ? void 0 : l.object) === "mf_switch_plan" ? l == null ? void 0 : l.switch_in_scheme : l == null ? void 0 : l.scheme, m = await w.fpClient.master_data().fetchScheme(a);
    if (S(m), (l == null ? void 0 : l.object) === "mf_switch_plan") {
      const d = await w.fpClient.master_data().fetchScheme(l == null ? void 0 : l.switch_out_scheme);
      $(d);
    }
  }, W = (a, m) => {
    if (U)
      window.open(Vs(window.location.origin + "/payment?order_id=" + a.id, L), "_blank", "noopener=true");
    else {
      const d = window.location.origin + Js + "/payment?order_id=" + a.id;
      window.location.href = d;
    }
  };
  es(() => {
    h(), R();
  }, []);
  function X() {
    var a;
    return (N == null ? void 0 : N.length) > 0 ? /* @__PURE__ */ s.jsxs("div", {
      children: [
        /* @__PURE__ */ s.jsx(ts, {
          customClass: "mb-8",
          children: /* @__PURE__ */ s.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-full pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: i._({
                    id: "UMs2dC"
                  }),
                  value: G(p) ? "-" : os(p == null ? void 0 : p.name),
                  logo: Z ? r[p == null ? void 0 : p.amc_id] || g : ""
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: i._({
                    id: "0c+2Wu"
                  }),
                  value: l != null && l.folio_number ? l == null ? void 0 : l.folio_number : "New folio"
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: i._({
                    id: "UMXPdU"
                  }),
                  value: (a = q(l == null ? void 0 : l.frequency)) == null ? void 0 : a.replace(/_/g, " ")
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-full pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: i._({
                    id: "FVoOCT",
                    values: {
                      0: ys(l == null ? void 0 : l.id)
                    }
                  }),
                  value: l == null ? void 0 : l.id
                })
              })
            ]
          })
        }),
        N.map((m, d) => /* @__PURE__ */ s.jsx("div", {
          ref: O,
          children: /* @__PURE__ */ s.jsx(Qs, {
            order: m,
            currentPlanObject: l,
            isInstallmentListing: !0,
            handlePayment: W,
            scheme: p,
            switchOutScheme: j,
            isGroupInvestment: e,
            isPartner: U,
            paymentDetails: as
          })
        }, m == null ? void 0 : m.id)),
        P ? /* @__PURE__ */ s.jsx("div", {
          className: "flex justify-center",
          children: /* @__PURE__ */ s.jsx(vs, {
            type: "important",
            variant: "field",
            title: "Loading..",
            loader: !0,
            customClass: "mb-5 "
          })
        }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
      ]
    }) : /* @__PURE__ */ s.jsx(s.Fragment, {});
  }
  function F() {
    switch (I) {
      default:
        return X();
      case "initial":
        return X();
      case "no_installments":
        return /* @__PURE__ */ s.jsx(_s, {
          title: i._({
            id: "6hZNkr"
          })
        });
    }
  }
  return u ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : F();
}
function bs({ currentPlanObject: l }) {
  const { fpapi: e } = ms(ps), [U, w] = f(!1), [L, u] = f(""), [x, N] = f([]);
  es(() => {
    D();
  }, [
    l
  ]);
  const D = async () => {
    var o;
    try {
      w(!0);
      const r = await e.fpClient.skip_instructions().fetchAll((l == null ? void 0 : l.object) + "s", l == null ? void 0 : l.id);
      if (us(r == null ? void 0 : r.data)) {
        u("no_skip_history"), w(!1);
        return;
      }
      (o = r == null ? void 0 : r.data) == null || o.sort((g, Z) => new Date(Z.created_at).valueOf() - new Date(g.created_at).valueOf()), N(r == null ? void 0 : r.data), u("initial"), w(!1);
    } catch {
      u("error"), w(!1);
    }
  }, I = (o) => o === "PENDING" ? "warning" : o === "ACTIVE" || o === "COMPLETED" ? "success" : "error", M = ({ skipInstruction: o }) => /* @__PURE__ */ s.jsx(ts, {
    customClass: "mb-20",
    header: {
      title: /* @__PURE__ */ s.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: i._({
          id: "igt8o5"
        })
      })
    },
    children: /* @__PURE__ */ s.jsxs("div", {
      className: "flex flex-wrap py-2",
      children: [
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-1/2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: i._({
              id: "4ewD+v"
            }),
            value: o != null && o.skipped_installments ? o == null ? void 0 : o.skipped_installments : "-"
          })
        }),
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-1/2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: i._({
              id: "v+kUb2"
            }),
            value: o != null && o.remaining_installments ? o == null ? void 0 : o.remaining_installments : "-"
          })
        }),
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-1/2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: i._({
              id: "e7LnWq"
            }),
            value: o.state ? /* @__PURE__ */ s.jsx(ls, {
              variant: "outline",
              message: `${q(o.state)}`,
              status: I(o.state)
            }) : "-"
          })
        })
      ]
    })
  }), p = () => x.map((o) => /* @__PURE__ */ s.jsx(M, {
    skipInstruction: o
  }, o == null ? void 0 : o.id));
  function S() {
    switch (L) {
      default:
        return /* @__PURE__ */ s.jsx(s.Fragment, {});
      case "initial":
        return p();
      case "no_skip_history":
        return /* @__PURE__ */ s.jsx(_s, {
          title: i._({
            id: "HfZlKF"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "error":
        return /* @__PURE__ */ s.jsx(_s, {
          title: i._({
            id: "w7WdjZ"
          }),
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return U ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : S();
}
function ce({ investorProfile: l, currentPlanObject: e, handleAuthorize: U, onModifySuccess: w, isGroupInvestment: L, isPartner: u }) {
  const { tenantConfig: x, banks: N, amcs: D } = cs(), { AppState: { tenant: I } } = ms(Rs), { fpapi: M } = ms(ps), p = x == null ? void 0 : x.fallbackLogo, S = x == null ? void 0 : x.showSchemeLogo, [o, r] = f(!1), [g, Z] = f(!1), [H, O] = f(!1), [V, P] = f(!1), [k, E] = f(!1), [J, C] = f([]), [A, j] = f({}), [$, as] = f({}), [y, t] = f({}), [h, R] = f({}), [W, X] = f(!1), [F, a] = f({}), [m, d] = f(!1), [c, z] = f(!1);
  es(() => {
    B(), (async () => {
      if (L) {
        const _ = await M.fetchUser(l == null ? void 0 : l.pan, I);
        a(_[0]);
      }
    })();
  }, [
    e
  ]);
  const B = async () => {
    if (X(!0), (e == null ? void 0 : e.object) === "mf_purchase_plan" && e != null && e.payment_method && e != null && e.payment_source)
      try {
        const v = await M.fpClient.mandates().fetch(Number(e == null ? void 0 : e.payment_source));
        j(v);
        const rs = (await M.fpClient.bank_accounts().fetchAll({
          profile: l.id
        })).data.filter(($s) => $s.old_id === (v == null ? void 0 : v.bank_account_id))[0];
        as(rs);
      } catch (v) {
        window.debug.error(v), b(v);
      }
    let _;
    if ((e == null ? void 0 : e.object) === "mf_switch_plan") {
      _ = e == null ? void 0 : e.switch_in_scheme;
      try {
        const v = await M.fpClient.master_data().fetchScheme(e == null ? void 0 : e.switch_out_scheme);
        R(v);
      } catch (v) {
        window.debug.error(v), b(v);
      }
    } else
      _ = e == null ? void 0 : e.scheme;
    try {
      const v = await M.fpClient.master_data().fetchScheme(_);
      t(v);
    } catch (v) {
      window.debug.error(v), b(v);
    }
    X(!1);
  }, hs = () => {
    G(A) ? (E(!0), C([
      ...J,
      "payment_method"
    ])) : A.mandate_status === "CREATED" ? U(A) : (E(!0), C([
      ...J,
      "payment_method"
    ]));
  }, Y = () => /* @__PURE__ */ s.jsx(ts, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ s.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: i._({
          id: "YbR7uM"
        })
      })
    },
    children: /* @__PURE__ */ s.jsxs("div", {
      className: "-mr-2 -ml-2 flex flex-wrap items-center",
      children: [
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: i._({
              id: "XTJZw8"
            }),
            value: os(y == null ? void 0 : y.name),
            logo: S ? D[y == null ? void 0 : y.amc_id] || p : ""
          })
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "flex flex-1 flex-wrap py-2",
          children: [
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 flex-1 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "FsNpOH"
                }),
                value: q(y == null ? void 0 : y.plan_type)
              })
            }),
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 flex-1 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "fezFD7"
                }),
                value: Cs(y == null ? void 0 : y.investment_option)
              })
            })
          ]
        })
      ]
    })
  }), T = () => /* @__PURE__ */ s.jsxs(ts, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ s.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: i._({
          id: "HYDz/e"
        })
      })
    },
    children: [
      /* @__PURE__ */ s.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-full pl-2 pr-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "6Xqu8f"
              }),
              value: os(h.name),
              logo: S ? D[h == null ? void 0 : h.amc_id] || p : ""
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "basis-1/2 pl-2 pr-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "FsNpOH"
              }),
              value: q(h.plan_type)
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "fezFD7"
              }),
              value: Cs(h.investment_option)
            })
          })
        ]
      }),
      /* @__PURE__ */ s.jsxs("div", {
        className: "flex flex-wrap py-2",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-full",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "qAf0Wv"
              }),
              value: os(y.name),
              logo: S ? D[y == null ? void 0 : y.amc_id] || p : ""
            })
          }),
          /* @__PURE__ */ s.jsxs("div", {
            className: "flex flex-1 flex-row",
            children: [
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3  flex basis-1/2 pr-2 ",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: i._({
                    id: "FsNpOH"
                  }),
                  value: q(y.plan_type)
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 flex basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: i._({
                    id: "fezFD7"
                  }),
                  value: Cs(y.investment_option)
                })
              })
            ]
          })
        ]
      })
    ]
  }), ds = () => {
    var _;
    return /* @__PURE__ */ s.jsxs(ts, {
      customClass: e.object === "mf_purchase_plan" ? "mb-4" : "",
      header: {
        title: /* @__PURE__ */ s.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: i._({
            id: "Cwt8cU"
          })
        })
      },
      children: [
        /* @__PURE__ */ s.jsxs(Xs, {
          children: [
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "/DJBcB"
                }),
                value: e.amount ? `₹ ${gs(e.amount)}` : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "6kkUMg"
                }),
                value: e.state ? e.state === "failed" || e.state === "cancelled" ? /* @__PURE__ */ s.jsx(ls, {
                  variant: "outline",
                  message: `${q(e.state)}`,
                  status: "error"
                }) : /* @__PURE__ */ s.jsx(ls, {
                  variant: "outline",
                  message: `${q(e.state)}`,
                  status: "success"
                }) : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "UMXPdU"
                }),
                value: e.frequency ? (_ = q(e.frequency)) == null ? void 0 : _.replace(/_/g, " ") : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "cXcPd1"
                }),
                value: e.folio_number ? e.folio_number : "New folio"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "sOfeX/"
                }),
                value: e.id ? e.id : "-"
              })
            }),
            e.next_installment_date ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "AEN2zi"
                }),
                value: e.next_installment_date && e.state !== "cancelled" && e.state !== "completed" ? K(new Date(e.next_installment_date), "dd-MMM-yyyy") : "-"
              })
            }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "a5RnUt"
                }),
                value: e.previous_installment_date ? K(new Date(e.previous_installment_date), "dd-MMM-yyyy") : "-"
              })
            }),
            e != null && e.requested_activation_date && !(e != null && e.start_date) ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "N6p5OV"
                }),
                value: e.requested_activation_date ? K(new Date(e.requested_activation_date), "dd-MMM-yyyy") : "-"
              })
            }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
              children: [
                /* @__PURE__ */ s.jsx(Q, {
                  span: 6,
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "AYywau"
                    }),
                    value: e.start_date ? K(new Date(e.start_date), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx(Q, {
                  span: 6,
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "Hz1n0Q"
                    }),
                    value: e.end_date ? e.number_of_installments === 1200 ? "Until I stop" : K(new Date(e.end_date), "dd-MMM-yyyy") : "-"
                  })
                })
              ]
            }),
            (e == null ? void 0 : e.frequency) !== "daily" ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "cki4uJ"
                }),
                value: e != null && e.installment_day ? Ys(e.installment_day) : "-"
              })
            }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: i._({
                  id: "6aH/4q"
                }),
                value: e.created_at ? K(new Date(e.created_at), "dd-MMM-yyyy") : "-"
              })
            }),
            e.state === "cancelled" && e.state === "completed" ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: e.state === "cancelled" && e.state === "completed" ? `${q(e.state)} on` : "-",
                value: e.state === "completed" ? K(new Date(e.completed_at), "dd-MMM-yyyy") : e.state === "cancelled" ? K(new Date(e.cancelled_at), "dd-MMM-yyyy") : "-"
              })
            }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
          ]
        }),
        e.generate_first_installment_now === "mf_purchase_plan" && /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: i._({
              id: "SRApmm"
            }),
            value: e.generate_first_installment_now ? "Yes" : "No"
          })
        })
      ]
    });
  }, is = (_) => {
    if (!L)
      return _.mandate_status && e.state !== "cancelled" && e.state !== "completed" ? _.mandate_status === "CREATED" && !u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: i._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Authorize mandate"
      } : _.mandate_status === "CREATED" && u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: i._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Send mandate authorization link"
      } : {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: i._({
              id: "QpKNZ9"
            })
          })
        }),
        buttonText: "Update mandate"
      } : G(_) && e.state !== "cancelled" && e.state !== "completed" ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: i._({
              id: "GfCtzd"
            })
          })
        }),
        buttonText: "Setup mandate"
      } : void 0;
  }, ns = () => {
    var _, v, Ms, rs;
    return /* @__PURE__ */ s.jsx(ts, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ s.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: i._({
            id: "1QPbvA"
          })
        })
      },
      footer: is(A),
      onClick: hs,
      children: !G(A) || L ? /* @__PURE__ */ s.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "lFCwug"
              }),
              value: L ? "Salary" : "Mandate"
            })
          }),
          !G($) && !L && /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "/dl6Or"
              }),
              value: $.bank_name ? `${$.bank_name} - ****${$.account_number.slice(-4)}` : "-",
              logo: $.bank_name && N[$.ifsc_code.slice(0, 4)] ? N[$.ifsc_code.slice(0, 4)] : p
            })
          }),
          L ? /* @__PURE__ */ s.jsx(s.Fragment, {}) : /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "Zp6kam"
              }),
              value: A.mandate_limit ? `₹ ${gs(A.mandate_limit)}` : "-"
            })
          }),
          L ? /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "OzNl8x"
              }),
              value: (_ = F == null ? void 0 : F.annexure) != null && _.employeeDeclarationTimeStamp ? "Approved" : "Not approved",
              icon: (v = F == null ? void 0 : F.annexure) != null && v.employeeDeclarationTimeStamp ? "successInfo" : "Error",
              iconPlacement: "left"
            })
          }) : /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: i._({
                id: "OzNl8x"
              }),
              value: A.mandate_status ? (Ms = Ls[A.mandate_status]) == null ? void 0 : Ms.value : "-",
              icon: A.mandate_status ? (rs = Ls[A.mandate_status]) == null ? void 0 : rs.icon : "",
              iconPlacement: "left"
            })
          })
        ]
      }) : /* @__PURE__ */ s.jsx("p", {
        className: "text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color px-1 pb-1",
        children: i._({
          id: "hjAxUg"
        })
      })
    });
  }, Ns = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: i._({
      id: "Z+CGHg",
      values: {
        0: e != null && e.object ? e == null ? void 0 : e.object.split("_")[1] : ""
      }
    }),
    isOpen: o,
    contentHeightMax: !0,
    toggleModal: () => {
      r(!1);
    },
    children: /* @__PURE__ */ s.jsx(Gs, {
      currentPlanObject: e,
      handleStopCompletion: (_) => {
        r(!1), w(_, "stop_finished");
      }
    })
  }), Bs = () => {
    var _;
    return /* @__PURE__ */ s.jsx(fs, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: i._({
        id: "ro5P6t",
        values: {
          0: e != null && e.id ? `${(_ = ys(e == null ? void 0 : e.id)) == null ? void 0 : _.toLowerCase()} plan` : "plan"
        }
      }),
      isOpen: g,
      contentHeightMax: !0,
      toggleModal: () => {
        Z(!1);
      },
      children: /* @__PURE__ */ s.jsx(Is, {
        currentPlanObject: e,
        handlePauseCompletion: (v) => {
          Z(!1), w(v, "pause_finished");
        }
      })
    });
  }, Ds = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: i._({
      id: "K98hiV",
      values: {
        0: e != null && e.object ? e == null ? void 0 : e.object.split("_")[1] : ""
      }
    }),
    isOpen: k,
    contentHeightMax: !0,
    toggleModal: () => {
      E(!1), C([]);
    },
    children: /* @__PURE__ */ s.jsx(Ks, {
      currentPlanObject: e,
      handleEditCompletion: (_) => {
        E(!1), C([]), w(_, "edit_finished");
      },
      editField: J
    })
  }), Us = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: i._({
      id: "YRRAVh",
      values: {
        0: ys(e == null ? void 0 : e.id)
      }
    }),
    isOpen: V,
    contentHeightMax: !0,
    toggleModal: () => {
      P(!1);
    },
    children: /* @__PURE__ */ s.jsx(js, {
      currentPlanObject: e,
      investorProfile: l,
      isGroupInvestment: L,
      isPartner: u
    })
  }), Hs = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: i._({
      id: "KLrVHR"
    }),
    isOpen: c,
    contentHeightMax: !0,
    toggleModal: () => {
      z(!1);
    },
    children: /* @__PURE__ */ s.jsx(bs, {
      currentPlanObject: e
    })
  }), ks = () => (e == null ? void 0 : e.state) === "cancelled" || (e == null ? void 0 : e.state) === "completed" || (e == null ? void 0 : e.state) === "created" || (e == null ? void 0 : e.requested_activation_date) !== null && (e == null ? void 0 : e.next_installment_date) === null ? "disabled" : "secondary", Fs = () => (e == null ? void 0 : e.state) === "cancelled" || (e == null ? void 0 : e.state) === "completed" || (e == null ? void 0 : e.state) === "created" || (e == null ? void 0 : e.frequency) === "daily" || (e == null ? void 0 : e.frequency) === "day_in_a_week" || (e == null ? void 0 : e.frequency) === "four_times_a_month" || (e == null ? void 0 : e.frequency) === "day_in_a_fortnight" || (e == null ? void 0 : e.frequency) === "twice_a_month" || (e == null ? void 0 : e.requested_activation_date) !== null && (e == null ? void 0 : e.next_installment_date) === null ? "disabled" : "secondary", As = () => (e == null ? void 0 : e.state) === "cancelled" || (e == null ? void 0 : e.state) === "completed" || (e == null ? void 0 : e.requested_activation_date) !== null && (e == null ? void 0 : e.next_installment_date) === null ? "disabled" : "secondary", Ws = () => {
    var _;
    return /* @__PURE__ */ s.jsxs(fs, {
      title: i._({
        id: "48jueX",
        values: {
          0: (_ = ys(e == null ? void 0 : e.id)) == null ? void 0 : _.toLowerCase()
        }
      }),
      isOpen: H,
      toggleModal: () => {
        O(!1);
      },
      contentHeightMax: !1,
      children: [
        ks() === "disabled" && Fs() === "disabled" && As() === "disabled" && /* @__PURE__ */ s.jsx(vs, {
          type: "alert",
          variant: "flash",
          title: "You can modify active plans only",
          customClass: "mb-5"
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ s.jsx(ss, {
              variant: ks(),
              fullwidth: !0,
              onClick: (v) => {
                v.preventDefault(), E(!k);
              },
              children: i._({
                id: "k0hcHs"
              })
            }),
            k && Ds()
          ]
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ s.jsx(ss, {
              variant: Fs(),
              fullwidth: !0,
              onClick: (v) => {
                v.preventDefault(), Z(!g);
              },
              children: i._({
                id: "PwQ/Gn"
              })
            }),
            g && Bs()
          ]
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ s.jsx(ss, {
              variant: As(),
              fullwidth: !0,
              onClick: (v) => {
                v.preventDefault(), r(!o);
              },
              children: i._({
                id: "r//qYl"
              })
            }),
            o && Ns()
          ]
        })
      ]
    });
  }, zs = () => /* @__PURE__ */ s.jsxs(fs, {
    title: i._({
      id: "lVWeQb"
    }),
    isOpen: m,
    toggleModal: () => {
      d(!1);
    },
    contentHeightMax: !1,
    children: [
      /* @__PURE__ */ s.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ s.jsx(ss, {
          variant: "secondary",
          fullwidth: !0,
          onClick: (_) => {
            _.preventDefault(), P(!V);
          },
          customClass: "flex-1",
          children: i._({
            id: "uHbvVq"
          })
        })
      }),
      /* @__PURE__ */ s.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ s.jsx(ss, {
          variant: "secondary",
          fullwidth: !0,
          onClick: (_) => {
            _.preventDefault(), z(!c);
          },
          customClass: "flex-1",
          children: i._({
            id: "mi6x4x"
          })
        })
      })
    ]
  });
  return W ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : G(e) ? /* @__PURE__ */ s.jsx(s.Fragment, {}) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      e.object === "mf_switch_plan" ? T() : Y(),
      ds(),
      e.object === "mf_purchase_plan" && ns(),
      H ? Ws() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      m ? zs() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      V ? Us() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      c ? Hs() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      k ? Ds() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      /* @__PURE__ */ s.jsx(ws, {
        customClass: "bottom-0",
        children: /* @__PURE__ */ s.jsxs("div", {
          className: "flex items-center",
          children: [
            u ? /* @__PURE__ */ s.jsx(s.Fragment, {}) : /* @__PURE__ */ s.jsx(ss, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (_) => {
                _.preventDefault(), O(!H);
              },
              customClass: "flex-1 mr-2 ",
              children: i._({
                id: "f4PxoX"
              })
            }),
            /* @__PURE__ */ s.jsx(ss, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (_) => {
                _.preventDefault(), d(!m);
              },
              customClass: "flex-1 mr-2 ",
              children: i._({
                id: "X9BN92"
              })
            })
          ]
        })
      })
    ]
  });
}
function Ts({ investorProfile: l, onModifySuccess: e, togglePlanDetailsModal: U, mfInvestmentAccount: w }) {
  const { fpapi: L } = ms(ps), { amcs: u, tenantConfig: x } = cs(), N = x == null ? void 0 : x.fallbackLogo, D = x == null ? void 0 : x.showSchemeLogo, [I, M] = f(!0), [p, S] = f(null), [o, r] = f([]), [g, Z] = f(null), [H, O] = f(null), V = Ss(H), [P, k] = f(!1), [E, J] = f(10);
  es(() => {
    if (V.hasScrolledToBottom) {
      const t = E + 10;
      A(t), J((h) => h + 10);
    }
  }, [
    V.hasScrolledToBottom
  ]), es(() => {
    j();
  }, [
    l
  ]);
  const C = async (t, h, R) => {
    const W = /* @__PURE__ */ new Set(), X = structuredClone(t), F = [];
    try {
      X == null || X.slice(h, R).forEach((d) => {
        W.add(d.scheme), F.push(d);
      });
      const a = [];
      W.forEach((d) => {
        const c = L.fpClient.master_data().fetchScheme(d);
        a.push(c);
      });
      const m = await Promise.all(a);
      m.length > 0 && (F == null || F.forEach((d) => {
        const c = m.find((z) => z.isin === d.scheme);
        c && (d.amc_id = c.amc_id, d.name = c.name, d.plan_type = c.plan_type, d.investment_option = c.investment_option, d.swp_frequency_specific_data = c.swp_frequency_specific_data);
      }));
    } catch (a) {
      window.debug.log(a, "Error");
    }
    return F;
  }, A = async (t) => {
    if (!(E >= (g == null ? void 0 : g.length)))
      try {
        k(!0);
        const h = structuredClone(g);
        if (us(h)) {
          k(!1);
          return;
        }
        const R = await C(g, E, t);
        r((W) => W.concat(R)), G(p) ? S(g[0]) : S(p), k(!1);
      } catch (h) {
        window.debug.log(h, "Error"), k(!1), b(h);
      }
  }, j = async () => {
    try {
      if (M(!0), G(w)) {
        M(!1);
        return;
      }
      const t = await L.fpClient.mf_redemption_plans().fetchAll({
        mf_investment_account: w.id
      }), h = structuredClone(t.data);
      if (Z(h), !h.length) {
        M(!1);
        return;
      }
      const R = await C(t == null ? void 0 : t.data, 0, E);
      r(R), S(h[0]), M(!1);
    } catch (t) {
      window.debug.error(t), M(!1), b(t);
    }
  }, $ = (t) => {
    S(g[t.target.value]);
  };
  function as(t) {
    if (t.requested_activation_date && t.state === "created")
      return /* @__PURE__ */ s.jsx(s.Fragment, {
        children: /* @__PURE__ */ s.jsx("div", {
          className: "text-center",
          children: i._({
            id: "bpnk88",
            values: {
              0: K(new Date(t.requested_activation_date), "dd-MMM-yyyy")
            }
          })
        })
      });
  }
  const y = () => I ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Es, {
        onChange: $,
        variant: "selectable",
        selected: 0,
        children: o != null && o.length ? o == null ? void 0 : o.map((t) => {
          var h;
          return /* @__PURE__ */ s.jsx(ts, {
            innerRef: O,
            footerNotes: as(t),
            children: /* @__PURE__ */ s.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "XTJZw8"
                    }),
                    value: t.name ? os(t.name) : "-",
                    logo: D ? u[t.amc_id] || N : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "/DJBcB"
                    }),
                    value: t.amount ? `₹ ${gs(t.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "6kkUMg"
                    }),
                    value: t.state ? t.state === "failed" || t.state === "cancelled" ? /* @__PURE__ */ s.jsx(ls, {
                      variant: "outline",
                      message: `${q(t.state)}`,
                      status: "error"
                    }) : /* @__PURE__ */ s.jsx(ls, {
                      variant: "outline",
                      message: `${q(t.state)}`,
                      status: "success"
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "UMXPdU"
                    }),
                    value: t.frequency ? (h = q(t.frequency)) == null ? void 0 : h.replace(/_/g, " ") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "3B8n+e"
                    }),
                    value: t.next_installment_date ? `${K(new Date(t.next_installment_date), "dd-MMM-yyyy")}` : "-"
                  })
                })
              ]
            })
          }, t.id);
        }) : /* @__PURE__ */ s.jsx(_s, {
          title: i._({
            id: "YiwWqS"
          }),
          description: i._({
            id: "MgEzWC"
          })
        })
      }),
      P ? /* @__PURE__ */ s.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ s.jsx(vs, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      p && /* @__PURE__ */ s.jsx(ws, {
        children: /* @__PURE__ */ s.jsx(ss, {
          variant: "primary",
          fullwidth: !0,
          onClick: (t) => {
            t.preventDefault(), U(p);
          },
          children: i._({
            id: "f9pW3a"
          })
        })
      })
    ]
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: y()
  });
}
function Os({ investorProfile: l, onModifySuccess: e, togglePlanDetailsModal: U, mfInvestmentAccount: w }) {
  const [L, u] = f(!0), { amcs: x, tenantConfig: N } = cs(), D = N == null ? void 0 : N.fallbackLogo, I = N == null ? void 0 : N.showSchemeLogo, { fpapi: M } = ms(ps), [p, S] = f(null), [o, r] = f([]), [g, Z] = f(null), [H, O] = f(null), V = Ss(H), [P, k] = f(!1), [E, J] = f(10);
  es(() => {
    if (V.hasScrolledToBottom) {
      const t = E + 10;
      A(t), J((h) => h + 10);
    }
  }, [
    V.hasScrolledToBottom
  ]), es(() => {
    j();
  }, [
    l
  ]);
  const C = async (t, h, R) => {
    const W = /* @__PURE__ */ new Set(), X = structuredClone(t), F = [];
    try {
      X == null || X.slice(h, R).forEach((d) => {
        W.add(d.switch_in_scheme), W.add(d.switch_out_scheme), F.push(d);
      });
      const a = [];
      W.forEach((d) => {
        const c = M.fpClient.master_data().fetchScheme(d);
        a.push(c);
      });
      const m = await Promise.all(a);
      m.length > 0 && F.forEach((d) => {
        const c = m.find((B) => B.isin === d.switch_in_scheme), z = m.find((B) => B.isin === d.switch_out_scheme);
        c && (d.amc_id = c.amc_id, d.name = c.name, d.plan_type = c.plan_type, d.investment_option = c.investment_option, d.stp_frequency_specific_data = c.stp_frequency_specific_data), z && (d.switchOutSchemeAmcId = z.amc_id, d.switchOutSchemeName = z.name, d.switchOutPlanType = z.plan_type, d.switchOutInvestmentOption = z.investment_option, d.switchOutAmcId = c.amc_id);
      });
    } catch (a) {
      window.debug.log(a, "Error");
    }
    return F;
  }, A = async (t) => {
    if (!(E >= (g == null ? void 0 : g.length)))
      try {
        k(!0);
        const h = structuredClone(g);
        if (us(h)) {
          k(!1);
          return;
        }
        const R = await C(g, E, t);
        r((W) => W.concat(R)), G(p) ? S(g[0]) : S(p), k(!1);
      } catch (h) {
        window.debug.log(h, "Error"), k(!1), b(h);
      }
  }, j = async () => {
    try {
      if (u(!0), G(w)) {
        u(!1);
        return;
      }
      const t = await M.fpClient.mf_switch_plans().fetchAll({
        mf_investment_account: w.id
      }), h = structuredClone(t.data);
      if (Z(h), !t.data.length) {
        u(!1);
        return;
      }
      const R = await C(t == null ? void 0 : t.data, 0, E);
      r(R), S(h[0]), u(!1);
    } catch (t) {
      window.debug.error(t), u(!1), b(t);
    }
  }, $ = (t) => {
    S(g[t.target.value]);
  };
  function as(t) {
    if (t.requested_activation_date && t.state === "created")
      return /* @__PURE__ */ s.jsx(s.Fragment, {
        children: /* @__PURE__ */ s.jsx("div", {
          className: "text-center",
          children: i._({
            id: "bpnk88",
            values: {
              0: K(new Date(t.requested_activation_date), "dd-MMM-yyyy")
            }
          })
        })
      });
  }
  const y = () => L ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Es, {
        onChange: $,
        variant: "selectable",
        selected: 0,
        children: o != null && o.length ? o == null ? void 0 : o.map((t) => {
          var h;
          return /* @__PURE__ */ s.jsx(ts, {
            innerRef: O,
            footerNotes: as(t),
            children: /* @__PURE__ */ s.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "6Xqu8f"
                    }),
                    value: t.switchOutSchemeName ? os(t.switchOutSchemeName) : "-",
                    logo: I ? x[t.switchOutSchemeAmcId] || D : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "qAf0Wv"
                    }),
                    value: t.name ? os(t.name) : "-",
                    logo: I ? x[t.amc_id] || D : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "/DJBcB"
                    }),
                    value: t.amount ? `₹ ${gs(t.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "6kkUMg"
                    }),
                    value: t.state ? t.state === "failed" || t.state === "cancelled" ? /* @__PURE__ */ s.jsx(ls, {
                      variant: "outline",
                      message: `${q(t.state)}`,
                      status: "error"
                    }) : /* @__PURE__ */ s.jsx(ls, {
                      variant: "outline",
                      message: `${q(t.state)}`,
                      status: "success"
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "UMXPdU"
                    }),
                    value: t.frequency ? (h = q(t.frequency)) == null ? void 0 : h.replace(/_/g, " ") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "3B8n+e"
                    }),
                    value: t.next_installment_date ? `${K(new Date(t.next_installment_date), "dd-MMM-yyyy")}` : "-"
                  })
                })
              ]
            })
          }, t.id);
        }) : /* @__PURE__ */ s.jsx(_s, {
          title: i._({
            id: "un37FN"
          }),
          description: i._({
            id: "cPbaxR"
          })
        })
      }),
      P ? /* @__PURE__ */ s.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ s.jsx(vs, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      p && /* @__PURE__ */ s.jsx(ws, {
        children: /* @__PURE__ */ s.jsx(ss, {
          variant: "primary",
          fullwidth: !0,
          onClick: (t) => {
            t.preventDefault(), U(p);
          },
          children: i._({
            id: "f9pW3a"
          })
        })
      })
    ]
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: y()
  });
}
function qs({ investorProfile: l, mandate_id: e, togglePlanDetailsModal: U, mfInvestmentAccount: w, handleAuthorize: L, isPartner: u }) {
  const { fpapi: x } = ms(ps), { amcs: N, tenantConfig: D } = cs(), I = D == null ? void 0 : D.fallbackLogo, M = D == null ? void 0 : D.showSchemeLogo, [p, S] = f(!0), [o, r] = f(null), [g, Z] = f([]), [H, O] = f(null), [V, P] = f(null), k = Ss(V), [E, J] = f(!1), [C, A] = f(10), [j, $] = f([]);
  es(() => {
    if (k.hasScrolledToBottom) {
      const a = C + 10;
      as(a), A((m) => m + 10);
    }
  }, [
    k.hasScrolledToBottom
  ]), es(() => {
    t();
  }, [
    l
  ]);
  const as = async (a) => {
    if (!(C >= (H == null ? void 0 : H.length)))
      try {
        J(!0);
        const m = structuredClone(H);
        if (us(m)) {
          J(!1);
          return;
        }
        const d = await y(m, C, a, j);
        Z((c) => c.concat(d)), G(o) ? r(H[0]) : r(o), J(!1);
      } catch (m) {
        window.debug.error(m), J(!1), b(m);
      }
  }, y = async (a, m, d, c) => {
    const z = structuredClone(a);
    let B = [];
    const hs = /* @__PURE__ */ new Set();
    z.slice(m, d).forEach((T) => {
      hs.add(T.scheme);
      const ds = c.find((is) => +(is.id === Number(T.payment_source)));
      ds && (T.mandate = {
        status: ds.mandate_status,
        limit: ds.mandate_limit
      }), B.push(T);
    }), e && (B = B.filter((T) => Number(T.payment_source) === Number(e)));
    const Y = B;
    try {
      const T = [];
      hs.forEach((is) => {
        const ns = x.fpClient.master_data().fetchScheme(is);
        T.push(ns);
      });
      const ds = await Promise.all(T);
      ds.length > 0 && Y.forEach((is) => {
        const ns = ds.find((Ns) => Ns.isin === is.scheme);
        ns && (is.name = ns.name, is.amc_id = ns.amc_id);
      });
    } catch (T) {
      window.debug.log(T, "Error");
    }
    return Y;
  }, t = async () => {
    try {
      if (S(!0), G(w)) {
        S(!1);
        return;
      }
      const a = await x.fpClient.mf_purchase_plans().fetchAll({
        mf_investment_account: w.id
      });
      if (!a.data.length) {
        S(!1);
        return;
      }
      const m = structuredClone(a.data);
      O(m);
      const d = await x.fpClient.bank_accounts().fetchAll({
        profile: l == null ? void 0 : l.id
      }), c = d.data.map((Y) => Y.old_id);
      if (!c.length) {
        S(!1);
        return;
      }
      const z = await x.fpClient.mandates().fetchAll({
        bank_account_id: c.join(","),
        size: 100
      }), B = z == null ? void 0 : z.mandates;
      B.sort((Y, T) => new Date(T.created_at).valueOf() - new Date(Y.created_at).valueOf()), B.length > 0 && (d.data.forEach((Y) => {
        B.forEach((T) => {
          T.bank_account_id === Y.old_id && (T.bank = {
            bank_name: Y.bank_name,
            number: Y.account_number,
            ifsc_code: Y.ifsc_code
          });
        });
      }), $(B));
      const hs = await y(a == null ? void 0 : a.data, 0, C, B);
      Z(hs), r(m[0]), S(!1);
    } catch (a) {
      window.debug.error(a), S(!1), b(a);
    }
  }, h = (a) => {
    r(H[a.target.value]);
  }, R = (a) => {
    if (a.mandate && a.state !== "cancelled" && a.state !== "completed")
      return a.mandate.status === "CREATED" && !u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: i._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Authorize mandate"
      } : a.mandate.status === "CREATED" && u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: i._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Send mandate authorization link"
      } : void 0;
  };
  function W(a) {
    if (a.requested_activation_date && a.state === "created")
      return /* @__PURE__ */ s.jsx(s.Fragment, {
        children: /* @__PURE__ */ s.jsx("div", {
          className: "text-center",
          children: i._({
            id: "bpnk88",
            values: {
              0: K(new Date(a.requested_activation_date), "dd-MMM-yyyy")
            }
          })
        })
      });
  }
  const X = (a) => (j == null ? void 0 : j.filter((d) => Number(d == null ? void 0 : d.id) === Number(a == null ? void 0 : a.payment_source)))[0] ?? {}, F = () => p ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Es, {
        onChange: h,
        variant: "selectable",
        selected: 0,
        children: g.length ? g == null ? void 0 : g.map((a) => {
          var m, d;
          return /* @__PURE__ */ s.jsx(ts, {
            footer: R(a),
            footerNotes: W(a),
            onClick: () => a.mandate.status === "CREATED" && L(X(a)),
            innerRef: P,
            children: /* @__PURE__ */ s.jsxs("div", {
              className: "-ml-2 -mr-2 flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "XTJZw8"
                    }),
                    value: a.name ? os(a.name) : "-",
                    logo: M ? N[a.amc_id] || I : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "/DJBcB"
                    }),
                    value: a.amount ? `₹ ${gs(a.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "6kkUMg"
                    }),
                    value: a.state ? a.state === "failed" || a.state === "cancelled" ? /* @__PURE__ */ s.jsx(ls, {
                      variant: "outline",
                      message: `${q(a.state)}`,
                      status: "error"
                    }) : /* @__PURE__ */ s.jsx(ls, {
                      variant: "outline",
                      message: `${q(a.state)}`,
                      status: "success"
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "UMXPdU"
                    }),
                    value: a != null && a.frequency ? (m = q(a == null ? void 0 : a.frequency)) == null ? void 0 : m.replace(/_/g, " ") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: i._({
                      id: "lFCwug"
                    }),
                    value: l != null && l.employer ? "Salary" : a.payment_method && a.mandate ? q(a.payment_method) : "Not setup",
                    icon: a.payment_method && a.mandate ? (d = Ls[a.mandate.status]) == null ? void 0 : d.icon : "",
                    iconPlacement: "left"
                  })
                })
              ]
            })
          }, a.id);
        }) : /* @__PURE__ */ s.jsx(_s, {
          title: i._({
            id: "ZQXjwm"
          }),
          description: i._({
            id: "dWFyRp"
          })
        })
      }),
      E ? /* @__PURE__ */ s.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ s.jsx(vs, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      o ? /* @__PURE__ */ s.jsx(ws, {
        children: /* @__PURE__ */ s.jsx(ss, {
          variant: "primary",
          fullwidth: !0,
          onClick: (a) => {
            a.preventDefault(), U(o);
          },
          children: i._({
            id: "f9pW3a"
          })
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
    ]
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: F()
  });
}
function pe(l) {
  var u;
  const { tenantConfig: e } = cs(), U = (u = e == null ? void 0 : e.app_constants) == null ? void 0 : u.planList, w = {
    sip: {
      title: i._({
        id: "dmetZq"
      }),
      content: /* @__PURE__ */ s.jsx(qs, {
        ...l
      })
    },
    swp: {
      title: i._({
        id: "tu5nmH"
      }),
      content: /* @__PURE__ */ s.jsx(Ts, {
        ...l
      })
    },
    stp: {
      title: i._({
        id: "+Q/+Mn"
      }),
      content: /* @__PURE__ */ s.jsx(Os, {
        ...l
      })
    }
  }, L = U == null ? void 0 : U.map((x) => w[x]);
  return /* @__PURE__ */ s.jsx(Zs, {
    selected: 0,
    group: !l.isGroupInvestment && !l.mandate_id ? L : [
      {
        title: i._({
          id: "dmetZq"
        }),
        content: /* @__PURE__ */ s.jsx(qs, {
          ...l
        })
      },
      {
        title: i._({
          id: "tu5nmH"
        }),
        content: /* @__PURE__ */ s.jsx(Ts, {
          ...l
        })
      }
    ]
  });
}
export {
  ce as P,
  pe as a
};
