import { j as o } from "./jsx-runtime-C8rcB1Lr.js";
import { i as t } from "./index-CrDCxF0S.js";
import { k as u } from "./index.esm-CqWfms5D.js";
const s = ({ selectedLanguage: r, updateLocale: l }) => {
  const e = [
    {
      label: "English",
      value: "en"
    },
    {
      label: "हिन्दी",
      value: "hi",
      disabled: !0
    },
    {
      label: "தமிழ்",
      value: "tm",
      disabled: !0
    }
  ];
  return /* @__PURE__ */ o.jsx(u, {
    value: r,
    onChange: (a) => {
      l(a), t.activate(a == null ? void 0 : a.value);
    },
    options: e,
    variant: "menuPicker",
    placement: "top"
  });
};
export {
  s as LocaleSelector
};
