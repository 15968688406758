import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { I as l } from "./index.esm-CqWfms5D.js";
function u({ customClass: t, title: e, description: a, iconName: n, children: r }) {
  const o = t ? `${t}` : "";
  return /* @__PURE__ */ s.jsxs("div", {
    className: o,
    children: [
      /* @__PURE__ */ s.jsx(l, {
        name: n,
        className: "mx-auto mb-2 block h-12 w-12"
      }),
      /* @__PURE__ */ s.jsx("h3", {
        className: "text-neutral-dark-0 text-center font-semibold text-size-20 pb-2 font-opensans dark:text-neutral-dark-110",
        children: e
      }),
      /* @__PURE__ */ s.jsx("p", {
        className: "mb-5 text-neutral-60 text-center text-size-14 mt-0",
        children: a
      }),
      /* @__PURE__ */ s.jsx("div", {
        children: r
      })
    ]
  });
}
export {
  u as S
};
