import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as z, useState as y, useMemo as ue, useEffect as Z } from "react";
import { a as ne, P as ee, D as oe, e as W, T as U, M as B, l as de, B as q, f as P, h as J } from "./index.esm-CqWfms5D.js";
import { F as ie } from "./provider-DKLaKIVD.js";
import { r as K } from "./sentry-CstOwvcR.js";
import { AppContext as re } from "./AppContext.js";
import { R as te, F as D } from "./react-final-form.es-CUo9SHJd.js";
import { i as l } from "./index-CrDCxF0S.js";
import { A as me } from "./context-BEDI9sxe.js";
import { u as le } from "./useConfiguration-B-lyaBB9.js";
import { f as Q, a as xe, r as $, y as fe, b as ge, k as he, l as ve, z as se } from "./validators-BNinzCrT.js";
import { k as je, m as T, t as ae, s as ce, P as pe } from "./utils-C2gHLhxV.js";
import { L as _e } from "./LanguageSelector-DnUt2tOq.js";
import { C as H } from "./CopyButton-D0B5OZZ9.js";
const be = () => {
  const { analytics: u, fp_campaign_id: C } = z(me);
  return {
    analytics: u,
    fp_campaign_id: C
  };
}, ye = ({ loginDetails: u, onSuccessfulRegistration: C, isGroupInvestment: L = !1, isPartner: k = !1 }) => {
  var R, Y;
  const { fp_campaign_id: i } = be(), v = z(ie), s = v == null ? void 0 : v.fpapi, { tenantConfig: j } = le(), { AppState: { tenant: S } } = z(re), [I, _] = y(""), [N, A] = y(!1), F = (R = j == null ? void 0 : j.groupInvestment) == null ? void 0 : R.allowedEmailDomains, O = (Y = j == null ? void 0 : j.data_sources_usage_policy) == null ? void 0 : Y.KYC, o = O === "strict" || O === "flexible", b = /* @__PURE__ */ new Date();
  b.setFullYear(b.getFullYear() - 18);
  const X = ue(() => ({
    pan: (u == null ? void 0 : u.loginHint) || "",
    dob: "",
    mobile: "",
    email: ""
  }), []), V = async (w) => {
    var t, m, p, E, h, x, r, f;
    const { pan: a, email: g, mobile: c, dob: n } = w;
    try {
      A(!0);
      const d = {
        username: a,
        pan: a,
        email_address: g,
        phone_number: c,
        annexure: {
          dob: n ? je(new Date(n), "yyyy-mm-dd") : "",
          fp_campaign_id: i,
          // kycCheckId: KYCData?.id,
          completed_step: "registration_details",
          isonboarded: String(!1)
        }
      };
      k && (d.annexure.email_address = g, d.annexure.phone_number = c), await (s == null ? void 0 : s.semiPublicFpClient.custom_users().create(d, S)) ? C({
        user: d
      }) : (window.debug.log("User creation failed"), _(l._({
        id: "44q+Ew"
      })));
    } catch (d) {
      window.debug.error(d), (m = (t = d == null ? void 0 : d.data) == null ? void 0 : t.error) != null && m.errorMessage ? _((E = (p = d == null ? void 0 : d.data) == null ? void 0 : p.error) == null ? void 0 : E.errorMessage) : (x = (h = d == null ? void 0 : d.data) == null ? void 0 : h.error) != null && x.message ? _((f = (r = d == null ? void 0 : d.data) == null ? void 0 : r.error) == null ? void 0 : f.message) : _(l._({
        id: "44q+Ew"
      })), K(d);
    } finally {
      A(!1);
    }
  }, M = (w) => /* @__PURE__ */ e.jsxs("form", {
    onSubmit: w.handleSubmit,
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(W, {
            message: "PAN"
          }),
          /* @__PURE__ */ e.jsx(D, {
            initialValue: X.pan,
            name: "pan",
            children: (a) => /* @__PURE__ */ e.jsx(e.Fragment, {
              children: /* @__PURE__ */ e.jsx(U, {
                id: a.input.name,
                autoComplete: "off",
                name: a.input.name,
                value: a.input.value,
                disabled: !0
              })
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(W, {
            message: "Mobile number"
          }),
          /* @__PURE__ */ e.jsx(D, {
            name: "mobile",
            initialValue: X.mobile,
            validate: Q($, xe("Enter valid 10 digit mobile number")),
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            validateFields: [],
            children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(U, {
                  id: a.input.name,
                  autoComplete: "off",
                  name: a.input.name,
                  value: a.input.value,
                  onChange: a.input.onChange,
                  onBlur: () => {
                    a.input.onChange(a.input.value.trim());
                  },
                  placeholder: l._({
                    id: "NaiXS3"
                  }),
                  onInput: (g) => {
                    g.target.value = g.target.value.replace(T("0-9"), "");
                  },
                  maxLength: 10,
                  status: a.meta.error && a.meta.touched ? "error" : void 0,
                  autoFocus: !0
                }),
                a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(B, {
                  type: "error",
                  variant: "field",
                  title: a.meta.error
                })
              ]
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(W, {
            message: l._({
              id: "GJrVBj"
            })
          }),
          /* @__PURE__ */ e.jsx(D, {
            name: "email",
            initialValue: X.email,
            validate: Q($, ge("Enter valid email"), fe(L, F || {}, "Invalid official email")),
            validateFields: [],
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(U, {
                  id: a.input.name,
                  autoComplete: "off",
                  name: a.input.name,
                  value: a.input.value,
                  onChange: a.input.onChange,
                  onBlur: () => {
                    a.input.onChange(a.input.value.trim());
                  },
                  onInput: (g) => {
                    g.target.value = g.target.value.replace(T("A-Za-z0-9._\\-@+"), "");
                  },
                  placeholder: l._({
                    id: "oNJ6PI"
                  }),
                  maxLength: 50,
                  status: a.meta.error && a.meta.touched ? "error" : void 0
                }),
                a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx(B, {
                  type: "error",
                  variant: "field",
                  title: a.meta.error
                }) : ae.isStagingOrSandbox && L && F && /* @__PURE__ */ e.jsx(B, {
                  type: "helper",
                  variant: "field",
                  title: l._({
                    id: "V7IZw2"
                  })
                })
              ]
            })
          })
        ]
      }),
      o ? /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(W, {
            message: "Date of birth"
          }),
          /* @__PURE__ */ e.jsx(D, {
            name: "dob",
            initialValue: X.dob,
            validate: Q($, ve, he("Age should be above 18")),
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            validateFields: [],
            children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(de, {
                  name: a.input.name,
                  variant: "keyboardInputDOB",
                  placeholder: l._({
                    id: "l33YLP"
                  }),
                  format: "dd-MMM-yyyy",
                  selectedDate: a.input.value || null,
                  maxDate: b,
                  initialFocusedDate: b,
                  error: a.meta.error && a.meta.touched,
                  helperText: null,
                  onKeyDown: (g) => g.preventDefault(),
                  handleDateChange: (g) => a.input.onChange(g)
                }),
                a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(B, {
                  type: "error",
                  variant: "field",
                  title: a.meta.error
                })
              ]
            })
          })
        ]
      }) : null,
      I && /* @__PURE__ */ e.jsx(B, {
        type: "error",
        variant: "flash",
        title: I
      }),
      /* @__PURE__ */ e.jsx(q, {
        text: "Continue",
        type: "submit",
        fullwidth: !0,
        onClick: () => {
          const a = Object.keys(w.errors);
          a.length && ce(a[0]);
        },
        customClass: "mb-8 mt-2",
        children: l._({
          id: "2iVSoY"
        })
      })
    ]
  });
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      N && /* @__PURE__ */ e.jsx(ne, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(ee, {
        title: k ? l._({
          id: "PtDtCh"
        }) : l._({
          id: "sR2H4g"
        })
      }),
      /* @__PURE__ */ e.jsx(oe, {
        type: "text",
        text: k ? l._({
          id: "BdZ0cJ"
        }) : l._({
          id: "tkI5Cu"
        })
      }),
      /* @__PURE__ */ e.jsx(te, {
        onSubmit: V,
        validate: () => ({}),
        children: M
      })
    ]
  });
}, Ce = () => {
  const [u, C] = y(!1), [L, k] = y(0), [i, v] = y(!1);
  return Z(() => {
    if (u) {
      const S = setInterval(() => {
        L > 0 ? k(L - 1) : (clearInterval(S), v(!0));
      }, 1e3);
      return () => clearInterval(S);
    }
  }, [
    u,
    L
  ]), {
    start: (S) => {
      C(!0), k(S), v(!1);
    },
    stop: () => {
      C(!1), v(!0);
    },
    value: L,
    hasStopped: i
  };
};
function Se({ authChallengeData: u, loginDetails: C, isGroupInvestment: L, onSuccessfulAuthChallengeVerification: k, onSuccessfulAuthentication: i }) {
  var E, h;
  const v = Ce(), { fpapi: s } = z(ie), { tenantConfig: j } = le(), [S, I] = y(""), [_, N] = y(!1), [A, F] = y(!1), [O, o] = y({
    session_code: u.session_code,
    client_id: u.client_id,
    execution: u.execution,
    auth_session_id: u.auth_session_id,
    tab_id: u.tab_id
  }), [b, X] = y(""), [V, M] = y(!0), [R, Y] = y(0), w = "123456", a = (E = j == null ? void 0 : j.app_constants) != null && E.otp_cooldown_interval ? j.app_constants.otp_cooldown_interval / 1e3 : 0, g = (h = j == null ? void 0 : j.app_constants) == null ? void 0 : h.otp_maximum_resend_attempts;
  Z(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []), Z(() => {
    N(!0), c();
  }, [
    C.loginHint
  ]), Z(() => {
    v.hasStopped && M(!1);
  }, [
    v.hasStopped
  ]), Z(() => {
    V && v.start(a), !V && !v.hasStopped && v.stop();
  }, [
    V
  ]);
  const c = async () => {
    let x = "";
    const r = u == null ? void 0 : u.email_address;
    u != null && u.challenge && (x = r ? l._({
      id: "iJMWZn",
      values: {
        maskedEmail: r
      }
    }) : ""), L && (x = r ? l._({
      id: "iJMWZn",
      values: {
        maskedEmail: r
      }
    }) : ""), X(x), N(!1);
  };
  function n() {
    var x;
    N(!0), I(""), (x = s == null ? void 0 : s.userAuthService) == null || x.initiateSignIn(C.loginHint, C.context).then((r) => {
      o({
        session_code: r.session_code,
        client_id: r.client_id,
        execution: r.execution,
        auth_session_id: r.auth_session_id,
        tab_id: r.tab_id
      }), M(!0), Y(R + 1), N(!1), k({
        username: C.loginHint,
        nextAuthChallengeData: r
      });
    }).catch((r) => {
      var f, d;
      M(!1), N(!1), ((d = (f = r == null ? void 0 : r.data) == null ? void 0 : f.error) == null ? void 0 : d.message) === "user_temporarily_disabled" && F(!0);
    });
  }
  function t({ otp: x }) {
    var r;
    N(!0), (r = s == null ? void 0 : s.userAuthService) == null || r.getChallenge("otp").verify(O, x).then((f) => {
      var d;
      f.challenge ? (k({
        username: C.loginHint,
        nextAuthChallengeData: f
      }), N(!1)) : (d = s == null ? void 0 : s.userAuthService) == null || d.verifyAuthorizationCode(f.code).then((G) => {
        i({
          authTokenPayload: G
        });
      }).catch(() => {
        I(l._({
          id: "OkdJaJ"
        })), N(!1), M(!1);
      });
    }).catch((f) => {
      var d, G;
      (d = f == null ? void 0 : f.error) != null && d.message ? I((G = f == null ? void 0 : f.error) == null ? void 0 : G.message) : I(l._({
        id: "OkdJaJ"
      })), N(!1), M(!1);
    });
  }
  function m(x, r) {
    r.fields.otp.change(x);
  }
  function p() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ee, {
          title: l._({
            id: "xHuYmx"
          })
        }),
        A && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(B, {
              type: "alert",
              variant: "flash",
              title: l._({
                id: "9q6HTu"
              }),
              customClass: "mb-5"
            }),
            /* @__PURE__ */ e.jsx(q, {
              variant: "primary",
              fullwidth: !0,
              onClick: () => {
                var r;
                const x = window.location.pathname + window.location.search;
                (r = s == null ? void 0 : s.userAuthService) == null || r.initiateLogout(x).then((f) => {
                  window.location.href = f.userState;
                });
              },
              children: "Sign in"
            })
          ]
        }),
        !A && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(oe, {
              type: "text",
              text: b
            }),
            /* @__PURE__ */ e.jsx(te, {
              onSubmit: t,
              mutators: {
                prepopulateOtp: m
              },
              children: (x) => /* @__PURE__ */ e.jsxs("form", {
                onSubmit: x.handleSubmit,
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-8",
                    children: /* @__PURE__ */ e.jsx(D, {
                      name: "otp",
                      validate: Q($),
                      children: (r) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(U, {
                            id: r.input.name,
                            autoComplete: "off",
                            name: r.input.name,
                            value: r.input.value,
                            onChange: (f) => {
                              r.input.onChange(f.target.value);
                            },
                            status: S ? "error" : void 0,
                            placeholder: l._({
                              id: "zVc3EA"
                            }),
                            maxLength: 6,
                            onInput: (f) => {
                              f.target.value = f.target.value.replace(T("0-9"), "");
                            },
                            onBlur: () => {
                              r.input.onChange(r.input.value.trim());
                            },
                            autoFocus: !0
                          }),
                          /* @__PURE__ */ e.jsxs("div", {
                            className: "flex justify-between",
                            children: [
                              /* @__PURE__ */ e.jsxs("div", {
                                children: [
                                  (r.meta.touched && r.meta.error || S) && /* @__PURE__ */ e.jsx(B, {
                                    variant: "field",
                                    type: "error",
                                    title: S || r.meta.error
                                  }),
                                  V && g && R < g && /* @__PURE__ */ e.jsx(B, {
                                    variant: "field",
                                    type: "important",
                                    title: l._({
                                      id: "Z6Q0xB",
                                      values: {
                                        0: pe(v.value)
                                      }
                                    })
                                  })
                                ]
                              }),
                              !V && g && R < g && /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(q, {
                                  customClass: "text-size-14",
                                  variant: "text",
                                  fullwidth: !0,
                                  type: "button",
                                  onClick: (f) => {
                                    f.preventDefault(), f.stopPropagation(), n();
                                  },
                                  children: l._({
                                    id: "+BdH9W"
                                  })
                                })
                              })
                            ]
                          })
                        ]
                      })
                    })
                  }),
                  /* @__PURE__ */ e.jsx(q, {
                    variant: S ? "disabled" : "primary",
                    fullwidth: !0,
                    onClick: () => {
                      const r = Object.keys(x.errors);
                      r.length && (ce(r[0]), M(!1));
                    },
                    children: l._({
                      id: "2iVSoY"
                    })
                  }),
                  g && R >= g && /* @__PURE__ */ e.jsx("div", {
                    className: "flex justify-end",
                    children: /* @__PURE__ */ e.jsx(q, {
                      type: "button",
                      variant: "text",
                      fullwidth: !0,
                      customClass: "mt-4 text-size-14 font-semibold",
                      onClick: () => {
                        window.location.reload();
                      },
                      children: l._({
                        id: "OZ0wo9"
                      })
                    })
                  }),
                  ae.isStagingOrSandbox ? /* @__PURE__ */ e.jsx(P, {
                    customClass: "mt-4",
                    children: /* @__PURE__ */ e.jsx(J, {
                      title: l._({
                        id: "a2WrrL"
                      }),
                      label: w,
                      children: /* @__PURE__ */ e.jsx(H, {
                        value: w,
                        onClick: () => x.form.mutators.prepopulateOtp(w)
                      })
                    })
                  }) : null
                ]
              })
            })
          ]
        })
      ]
    });
  }
  return _ ? /* @__PURE__ */ e.jsx(ne, {
    variant: "fullpage"
  }) : p();
}
function Ae({ redirectURL: u, onPreviewData: C, allowRegistration: L = !1, isGroupInvestment: k = !1, showTestPan: i, isPartner: v, onSuccessfulLoginInitiation: s, onNewUserDetected: j }) {
  var g;
  const { AppState: { tenant: S } } = z(re), [I, _] = y(""), [N, A] = y(!1), { tenantConfig: F } = le(), { fpapi: O } = z(ie), o = k ? (g = F == null ? void 0 : F.groupInvestment) == null ? void 0 : g.testPan : F == null ? void 0 : F.test_pan, b = "XXXPX3751X", X = "XXXPX3753X";
  Z(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  async function V(c, n) {
    var t, m, p, E;
    try {
      if (v) {
        C({
          pan: c
        });
        return;
      }
      const h = await ((t = O == null ? void 0 : O.userAuthService) == null ? void 0 : t.initiateSignIn(c, n));
      A(!1), s({
        username: c,
        authChallengeData: h
      });
    } catch (h) {
      A(!1), ((m = h == null ? void 0 : h.data) == null ? void 0 : m.error) === "user_not_found" ? _(l._({
        id: "S8L13d"
      })) : ((E = (p = h == null ? void 0 : h.data) == null ? void 0 : p.error) == null ? void 0 : E.message) === "user_temporarily_disabled" ? _(l._({
        id: "zsVWX+"
      })) : (_(l._({
        id: "OkdJaJ"
      })), window.debug.error(h)), K(h);
    }
  }
  async function M({ pan: c }) {
    var n;
    try {
      A(!0), await O.semiPublicFpClient.custom_users().search({
        username: c
      }, S), V(c, u);
    } catch (t) {
      (t == null ? void 0 : t.status) === 404 ? (A(!1), L ? j({
        username: c
      }) : _(l._({
        id: "Iz29Fk"
      }))) : (A(!1), _((n = t == null ? void 0 : t.data) == null ? void 0 : n.message), window.debug.error(t), K(t));
    }
  }
  function R(c, n) {
    n.fields.pan.change(c.length && c[0]);
  }
  function Y() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ee, {
          title: l._({
            id: "7YtaRf"
          })
        }),
        /* @__PURE__ */ e.jsx(W, {
          message: l._({
            id: "jSDcEn"
          })
        }),
        /* @__PURE__ */ e.jsx(te, {
          onSubmit: M,
          mutators: {
            prepopulatePAN: R
          },
          children: (c) => /* @__PURE__ */ e.jsxs("form", {
            onSubmit: c.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-8",
                children: /* @__PURE__ */ e.jsx(D, {
                  name: "pan",
                  validate: Q($, se("Enter in a valid format")),
                  parse: (n) => n.toUpperCase(),
                  children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(U, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: (t) => {
                          const m = t.target.selectionStart, p = t.target;
                          window.requestAnimationFrame(() => {
                            p.selectionStart = m, p.selectionEnd = m;
                          }), n.input.onChange(t.target.value);
                        },
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(T("A-Za-z0-9"), "");
                        },
                        placeholder: l._({
                          id: "3SA8Xd"
                        }),
                        status: n.meta.error && n.meta.touched ? "error" : void 0,
                        maxLength: 10,
                        autoFocus: !0
                      }),
                      (n.meta.error && n.meta.touched || I) && /* @__PURE__ */ e.jsx(B, {
                        type: "error",
                        variant: "field",
                        title: I || n.meta.error
                      })
                    ]
                  })
                })
              }),
              /* @__PURE__ */ e.jsx(q, {
                type: "submit",
                variant: "primary",
                fullwidth: !0,
                onClick: () => {
                  Object.keys(c.errors).length;
                },
                children: "Continue"
              }),
              ae.isStagingOrSandbox ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(P, {
                    title: l._({
                      id: "4z3T2w"
                    }),
                    customClass: "mt-4",
                    children: (i == null ? void 0 : i.onboarded) && /* @__PURE__ */ e.jsx(J, {
                      label: o,
                      customClass: "mb-3",
                      children: /* @__PURE__ */ e.jsx(H, {
                        value: o || "",
                        onClick: () => c.form.mutators.prepopulatePAN(o)
                      })
                    })
                  }),
                  i != null && i.kycCompliant || i != null && i.kycNonCompliant ? /* @__PURE__ */ e.jsxs(P, {
                    title: l._({
                      id: "IVpF4V"
                    }),
                    customClass: "mt-4",
                    children: [
                      " ",
                      (i == null ? void 0 : i.kycCompliant) && /* @__PURE__ */ e.jsx(J, {
                        title: "KYC completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            b.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        customClass: "mb-3",
                        children: /* @__PURE__ */ e.jsx(H, {
                          value: b,
                          onClick: () => c.form.mutators.prepopulatePAN(b)
                        })
                      }),
                      (i == null ? void 0 : i.kycNonCompliant) && /* @__PURE__ */ e.jsx(J, {
                        title: "KYC not completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            X.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        children: /* @__PURE__ */ e.jsx(H, {
                          value: X,
                          onClick: () => c.form.mutators.prepopulatePAN(X)
                        })
                      })
                    ]
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                ]
              }) : null
            ]
          })
        })
      ]
    });
  }
  function w() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ee, {
          title: "Get started",
          semiBold: !0
        }),
        /* @__PURE__ */ e.jsx(W, {
          message: l._({
            id: "pNb/19"
          })
        }),
        /* @__PURE__ */ e.jsx(te, {
          onSubmit: M,
          mutators: {
            prepopulatePAN: R
          },
          children: (c) => /* @__PURE__ */ e.jsxs("form", {
            onSubmit: c.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-8",
                children: /* @__PURE__ */ e.jsx(D, {
                  name: "pan",
                  validate: Q($, se("Enter in a valid format")),
                  parse: (n) => n.toUpperCase(),
                  children: (n) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(U, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: (t) => {
                          const m = t.target.selectionStart, p = t.target;
                          window.requestAnimationFrame(() => {
                            p.selectionStart = m, p.selectionEnd = m;
                          }), n.input.onChange(t.target.value);
                        },
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(T("A-Za-z0-9"), "");
                        },
                        placeholder: l._({
                          id: "afY/DE"
                        }),
                        status: n.meta.error && n.meta.touched ? "error" : void 0,
                        maxLength: 10,
                        autoFocus: !0
                      }),
                      (n.meta.error && n.meta.touched || I) && /* @__PURE__ */ e.jsx(B, {
                        type: "error",
                        variant: "field",
                        title: I || n.meta.error
                      })
                    ]
                  })
                })
              }),
              /* @__PURE__ */ e.jsx(_e, {}),
              /* @__PURE__ */ e.jsx(q, {
                type: "submit",
                variant: "primary",
                fullwidth: !0,
                onClick: () => {
                  Object.keys(c.errors).length;
                },
                children: "Continue"
              }),
              ae.isStagingOrSandbox ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(P, {
                    title: l._({
                      id: "4z3T2w"
                    }),
                    customClass: "mt-4",
                    children: (i == null ? void 0 : i.onboarded) && /* @__PURE__ */ e.jsx(J, {
                      label: o,
                      customClass: "mb-3",
                      children: /* @__PURE__ */ e.jsx(H, {
                        value: o || "",
                        onClick: () => c.form.mutators.prepopulatePAN(o)
                      })
                    })
                  }),
                  i != null && i.kycCompliant || i != null && i.kycNonCompliant ? /* @__PURE__ */ e.jsxs(P, {
                    title: l._({
                      id: "IVpF4V"
                    }),
                    customClass: "mt-4",
                    children: [
                      " ",
                      (i == null ? void 0 : i.kycCompliant) && /* @__PURE__ */ e.jsx(J, {
                        title: "KYC completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            b.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        customClass: "mb-3",
                        children: /* @__PURE__ */ e.jsx(H, {
                          value: b,
                          onClick: () => c.form.mutators.prepopulatePAN(b)
                        })
                      }),
                      (i == null ? void 0 : i.kycNonCompliant) && /* @__PURE__ */ e.jsx(J, {
                        title: "KYC not completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            X.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        children: /* @__PURE__ */ e.jsx(H, {
                          value: X,
                          onClick: () => c.form.mutators.prepopulatePAN(X)
                        })
                      })
                    ]
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                ]
              }) : null
            ]
          })
        })
      ]
    });
  }
  function a() {
    return v ? Y() : w();
  }
  return N ? /* @__PURE__ */ e.jsx(ne, {
    variant: "fullpage"
  }) : a();
}
function ze({ redirectURL: u, onPreviewData: C = null, allowRegistration: L = !1, isGroupInvestment: k = !1, isPartner: i = !1, showTestPan: v }) {
  const { fpapi: s, rebuildFpApi: j } = z(ie), { AppState: { tenant: S } } = z(re), [I, _] = y(!1), [N, A] = y("login"), [F, O] = y({
    challenge: null,
    session_code: null,
    execution: null,
    auth_session_id: null,
    tab_id: null
  }), [o, b] = y({
    username: null,
    mobile: null,
    email: null,
    dob: null
  });
  Z(() => {
    s.investor_token && !i && Y();
  }, [
    s
  ]);
  const X = ({ username: t, authChallengeData: m }) => {
    b({
      ...o,
      username: t
    }), O(m), A("challenge");
  }, V = ({ username: t }) => {
    b({
      ...o,
      username: t
    }), A("register");
  }, M = async ({ user: t }) => {
    var m, p;
    b({
      ...o,
      username: (t == null ? void 0 : t.username) || null,
      mobile: (t == null ? void 0 : t.phone_number) || null,
      email: (t == null ? void 0 : t.email_address) || null,
      dob: ((m = t == null ? void 0 : t.annexure) == null ? void 0 : m.dob) || null
    });
    try {
      if (_(!0), i) {
        await a({
          disableAuthFlowComplete: !0
        }), C({
          pan: t == null ? void 0 : t.pan
        });
        return;
      }
      _(!0);
      const E = (s == null ? void 0 : s.userAuthService) && await ((p = s == null ? void 0 : s.userAuthService) == null ? void 0 : p.initiateSignIn(t == null ? void 0 : t.username, u));
      _(!1), O(E), A("challenge");
    } catch (E) {
      _(!1), window.debug.error(E), K(E);
    } finally {
      _(!1);
    }
  }, R = async () => {
    j();
  }, Y = async () => {
    const { profile: t } = await s.userAuthService.getAuthenticatedUser();
    !!((t == null ? void 0 : t.type) === "investor" && (t != null && t.fp_identifier)) ? g() : await a({
      disableAuthFlowComplete: !1
    });
  }, w = ({ username: t, nextAuthChallengeData: m }) => {
    b({
      ...o,
      username: t
    }), O(m), A("challenge");
  }, a = async ({ disableAuthFlowComplete: t = !1 }) => {
    try {
      const { data: m } = await s.fpClient.custom_users().listAll({
        username: o == null ? void 0 : o.username
      }, S), p = m[0], { data: E } = await s.fpClient.investor_profiles().fetchAll({
        pan: o == null ? void 0 : o.username
      });
      let h = E[0];
      if (!h)
        try {
          const x = {
            type: "individual",
            pan: o.username
          };
          h = await s.fpClient.investor_profiles().create(x);
        } catch (x) {
          return K(x), x;
        }
      try {
        await s.updateUser(p.id, p.annexure, h.id, S), !t && g();
      } catch (x) {
        window.debug.error(x), K(x);
      }
    } catch (m) {
      window.debug.log(m);
    }
  }, g = () => {
    var t;
    (t = s == null ? void 0 : s.userAuthService) == null || t.refreshToken().then(() => {
      window.location.href = u;
    }).catch((m) => {
      K(m);
    });
  }, c = () => {
    switch (N) {
      case "login":
        return /* @__PURE__ */ e.jsx(Ae, {
          redirectURL: u,
          onPreviewData: C,
          allowRegistration: L,
          showTestPan: v,
          onSuccessfulLoginInitiation: X,
          onNewUserDetected: V,
          isPartner: i,
          isGroupInvestment: k
        });
      case "register":
        return /* @__PURE__ */ e.jsx(ye, {
          loginDetails: {
            loginHint: o != null && o.username ? o == null ? void 0 : o.username : "",
            context: u
          },
          onSuccessfulRegistration: M,
          isGroupInvestment: k,
          isPartner: i
        });
      case "challenge":
        return n(F == null ? void 0 : F.challenge);
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  }, n = (t) => {
    switch (t) {
      case "sms_otp":
      case "email_otp":
      case "sms_or_email_otp":
        return /* @__PURE__ */ e.jsx(Se, {
          authChallengeData: F,
          loginDetails: {
            loginHint: o.username,
            context: u
          },
          onSuccessfulAuthChallengeVerification: w,
          isGroupInvestment: k,
          onSuccessfulAuthentication: R
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  };
  return I ? /* @__PURE__ */ e.jsx(ne, {
    variant: "fullpage"
  }) : c();
}
export {
  ze as A,
  Ce as a,
  be as u
};
