import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as a, useState as s, useLayoutEffect as C, useEffect as L } from "react";
import { AppContext as E } from "./AppContext.js";
import { F as I } from "./provider-DKLaKIVD.js";
import { LoginContext as y } from "./LoginContext.js";
import { P as F, a as M } from "./index.esm-CqWfms5D.js";
import { r as R } from "./sentry-CstOwvcR.js";
import { i as U } from "./utils-C2gHLhxV.js";
import { i as n } from "./index-CrDCxF0S.js";
import { W } from "./Welcome-Bz7XIwKh.js";
import { M as T } from "./MandateListing-DpfjvUeo.js";
import { ErrorPage as b } from "./ErrorPage.js";
import { A as k } from "./Auth-CJPsuV-i.js";
function V({ mandate_id: p }) {
  const { fpapi: r } = a(I), { loggedInUser: i } = a(y), { AppState: { showWelcomePage: f }, setShowWelcomePage: d } = a(E), [u, m] = s(c), [g, x] = s({}), [h, w] = s({}), [j, o] = s(!1), v = [
    {
      step: 1,
      content: n._({
        id: "wtcwqs"
      })
    },
    {
      step: 2,
      content: n._({
        id: "d4wYsU"
      })
    },
    {
      step: 3,
      content: n._({
        id: "xU5OS9"
      })
    },
    {
      step: 4,
      content: n._({
        id: "ltQaxv"
      })
    },
    {
      step: 5,
      content: n._({
        id: "k4RKPZ"
      })
    }
  ];
  C(() => {
    r && i && (o(!0), r.getInvestorProfile().then((e) => {
      e && (x(e), r.fpClient.mf_investment_accounts().fetchAll({
        investor: e.pan
      }).then((A) => {
        const l = A.data.filter((S) => S.primary_investor === i.profile.fp_identifier);
        if (!l.length) {
          o(!1);
          return;
        }
        w(l[0]), o(!1);
      }));
    }).catch((e) => {
      o(!1), R(e);
    }));
  }, []), L(() => {
    m(c());
  }, [
    f
  ]);
  function c() {
    return f ? "welcome" : "mandate";
  }
  function P(e) {
    d(!1), m("mandate");
  }
  function _() {
    return u === "welcome" ? /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx(W, {
        onAck: P,
        welcomeSteps: v
      })
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: j ? /* @__PURE__ */ t.jsx(M, {
        variant: "fullpage"
      }) : i ? U(h) ? /* @__PURE__ */ t.jsx(b, {
        title: "No mandates found",
        description: "You have not setup any mandates",
        showDetails: !1
      }) : /* @__PURE__ */ t.jsx(T, {
        investorProfile: g,
        mandate_id: p,
        status: [
          ""
        ],
        limit: null,
        isPartner: !1
      }) : /* @__PURE__ */ t.jsx(k, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(F, {
        title: n._({
          id: "4h1gSz"
        })
      }),
      _()
    ]
  });
}
export {
  V as ManageMandates
};
