import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { i as m } from "./index-CrDCxF0S.js";
import { S as W, c as S, a as M, P as T } from "./index.esm-CqWfms5D.js";
import { useContext as j, useState as b, useEffect as L } from "react";
import { AppContext as U } from "./AppContext.js";
import { F as P } from "./provider-DKLaKIVD.js";
import { LoginContext as z } from "./LoginContext.js";
import { r as v } from "./sentry-CstOwvcR.js";
import { ErrorPage as G } from "./ErrorPage.js";
import { O as V } from "./Onboard-B4_fp4ld.js";
import { d as J, i as K, f as k, e as F, j as Z, k as Y } from "./utils-C2gHLhxV.js";
import { u as q } from "./useConfiguration-B-lyaBB9.js";
import { A as B } from "./Auth-CJPsuV-i.js";
import { P as H } from "./PartnerLogin-D3m53h1t.js";
import { W as Q } from "./Welcome-Bz7XIwKh.js";
function D({ title: a, details: i, errorMessage: e = m._({
  id: "9wmKtw"
}) }) {
  const p = (c) => Object.keys(c).map((n) => {
    const d = c[n];
    return typeof d == "object" ? /* @__PURE__ */ t.jsx("div", {
      className: `mb-3 basis-1/2 pr-2 pl-2 ${d.classNames || ""}`,
      children: /* @__PURE__ */ t.jsx(S, {
        label: n,
        value: d.value ? d.value : "-",
        logo: d.logo || ""
      })
    }, n) : /* @__PURE__ */ t.jsx("div", {
      className: "mb-3 basis-1/2 pr-2 pl-2",
      children: /* @__PURE__ */ t.jsx(S, {
        label: n,
        value: d || "-"
      })
    }, n);
  }), u = () => Array.isArray(i) && J(i) || !Array.isArray(i) && K(i) ? /* @__PURE__ */ t.jsx("p", {
    className: "text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color px-1 pb-1",
    children: e
  }) : Array.isArray(i) ? i.map((c, n) => /* @__PURE__ */ t.jsx("div", {
    className: "dark:border-neutral-dark-20 mb-2 flex basis-full flex-wrap border-b last:border-b-0",
    children: p(c)
  }, n)) : p(i);
  return /* @__PURE__ */ t.jsx(W, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ t.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: a
      })
    },
    children: /* @__PURE__ */ t.jsx("div", {
      className: "-mr-2 -ml-2 flex flex flex-wrap items-center",
      children: u()
    })
  });
}
function X({ investorId: a }) {
  const { fpapi: i } = j(P), [e, p] = b(null), { banks: u, tenantConfig: c } = q(), n = c == null ? void 0 : c.fallbackLogo;
  L(() => {
    d();
  }, [
    a
  ]);
  const d = async () => {
    try {
      const l = await i.fpClient.bank_accounts().fetchAll({
        profile: a
      });
      p(l.data);
    } catch (l) {
      window.debug.error(l), v(l);
    }
  }, A = () => e ? e.map((l) => {
    var o;
    return {
      "Bank name": {
        value: `${l.bank_name} - ****${(o = l.account_number) == null ? void 0 : o.slice(-4)}`,
        logo: l.ifsc_code ? u[l.ifsc_code.slice(0, 4)] : n,
        classNames: "basis-full"
      },
      "Account type": l.type ? k(l.type) : "-",
      IFSC: l.ifsc_code || "-"
    };
  }) : {};
  return /* @__PURE__ */ t.jsx(D, {
    title: m._({
      id: "BV1BDN"
    }),
    details: A()
  });
}
function ee({ investorId: a }) {
  const { fpapi: i } = j(P), [e, p] = b(null), [u, c] = b(null), [n, d] = b(null), [A, l] = b([]);
  L(() => {
    g(), o(), f();
  }, [
    a
  ]);
  const g = async () => {
    try {
      const s = await i.fpClient.phone_numbers().fetchAll({
        profile: a
      });
      p(s.data);
    } catch (s) {
      window.debug.error(s), v(s);
    }
  }, o = async () => {
    try {
      const s = await i.fpClient.email_addresses().fetchAll({
        profile: a
      });
      c(s.data);
    } catch (s) {
      window.debug.error(s), v(s);
    }
  }, f = async () => {
    try {
      const s = await i.fpClient.addresses().fetchAll({
        profile: a
      }), { countries: h } = await i.fpClient.master_data().fetchCountries();
      d(s.data), l(h);
    } catch (s) {
      window.debug.error(s), v(s);
    }
  }, w = (s) => {
    const h = [];
    for (const r in s)
      if (s[r]) {
        const _ = s[r].toString(), y = Z(_);
        h.push(` ${y.trim()}`);
      }
    return h.join();
  }, x = () => {
    const s = {};
    if (e)
      for (const [h, r] of e.entries()) {
        let _ = `Phone ${h + 1}`;
        const y = `${r.isd}${r.number}`;
        e.length < 2 && (_ = "Phone"), s[_] = y;
      }
    if (u)
      for (const [h, r] of u.entries()) {
        let _ = `Email ${h + 1}`;
        const y = r.email;
        u.length < 2 && (_ = "Email"), s[_] = {
          value: y,
          classNames: "basis-full"
        };
      }
    if (n)
      for (const [h, r] of n.entries()) {
        let _ = `Address ${h + 1}`;
        const y = w({
          addressLine1: r == null ? void 0 : r.line1,
          addressLine2: r == null ? void 0 : r.line2,
          addressLine3: r == null ? void 0 : r.line3,
          state: r == null ? void 0 : r.state,
          country: F(A, r == null ? void 0 : r.country) || "",
          postal_code: r == null ? void 0 : r.postal_code
        });
        n.length < 2 && (_ = "Address"), s[_] = {
          value: y,
          classNames: "basis-full"
        };
      }
    return s;
  };
  return /* @__PURE__ */ t.jsx(D, {
    title: m._({
      id: "4swDc5"
    }),
    details: x()
  });
}
function te({ investorId: a }) {
  const { fpapi: i } = j(P), [e, p] = b(null);
  L(() => {
    u();
  }, [
    a
  ]);
  const u = async () => {
    try {
      const n = await i.fpClient.related_parties().fetchAll({
        profile: a
      });
      p(n.data);
    } catch (n) {
      window.debug.error(n), v(n);
    }
  }, c = () => e ? e.map((n) => ({
    Name: {
      value: k(n == null ? void 0 : n.name) || "",
      classNames: "basis-full"
    },
    "Relationship with nominee": k(n == null ? void 0 : n.relationship),
    "Allocation(%)": ""
  })) : [];
  return /* @__PURE__ */ t.jsx(D, {
    title: m._({
      id: "6BiOp+"
    }),
    details: c(),
    errorMessage: m._({
      id: "/WgTz8"
    })
  });
}
function se({ investorId: a }) {
  const { fpapi: i } = j(P), [e, p] = b(), [u, c] = b([]);
  L(() => {
    n();
  }, [
    a
  ]);
  const n = async () => {
    try {
      const o = await i.fpClient.investor_profiles().fetch(a), { countries: f } = await i.fpClient.master_data().fetchCountries();
      p(o), c(f);
    } catch (o) {
      window.debug.error(o), v(o);
    }
  }, d = (o) => o === "pep_exposed" ? "Yes" : o === "pep_related" ? "Related" : "No", A = {
    upto_1lakh: "0 - 1 Lakh",
    above_1lakh_upto_5lakh: "1 - 5 Lakhs",
    above_5lakh_upto_10lakh: "5 - 10 Lakhs",
    above_10lakh_upto_25lakh: "10 - 25 Lakhs",
    above_25lakh_upto_1cr: "25 Lakhs - 1 Crore",
    above_1cr: "Above 1 Crore"
  }, l = () => {
    var w;
    const o = {}, f = e && {
      PAN: e.pan,
      Name: k(e.name || ""),
      "Date of birth": e.date_of_birth ? Y(new Date(e.date_of_birth), "dd-MMM-yyyy") : null,
      Gender: k(e.gender || ""),
      "Country of birth": e.country_of_birth ? F(u, e.country_of_birth) : "",
      "Are you politically exposed?": e ? d(e.pep_details || "") : void 0,
      "Resident status": k(((w = e.tax_status) == null ? void 0 : w.split("_").join(" ")) || "")
    };
    for (const x in f)
      if (f) {
        const s = f[x];
        s != null && (o[x] = s);
      }
    return o;
  }, g = () => {
    var w;
    const o = {}, f = e && {
      "Tax Status": k(e.type),
      Occupation: k(e.occupation || ""),
      "Source of wealth": k(((w = e.source_of_wealth) == null ? void 0 : w.split("_").join(" ")) || ""),
      Income: e.income_slab ? A[e.income_slab] : ""
    };
    for (const x in f)
      if (f) {
        const s = f[x];
        s != null && (o[x] = s);
      }
    return o;
  };
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(D, {
        title: m._({
          id: "/s2Blh"
        }),
        details: l()
      }),
      /* @__PURE__ */ t.jsx(D, {
        title: m._({
          id: "aJ7CKR"
        }),
        details: g()
      })
    ]
  });
}
function we({ isPartner: a }) {
  const [i, e] = b(""), { AppState: { showWelcomePage: p, tenant: u }, setShowWelcomePage: c } = j(U), { loggedInUser: n, loggedInPartner: d } = j(z), { fpapi: A } = j(P), [l, g] = b(!1), [o, f] = b(null), [w, x] = b(null);
  L(() => {
    s();
  }, [
    p,
    w
  ]);
  const s = async () => {
    var C;
    if (p)
      e("welcome"), g(!0);
    else if ((a ? d : n) === void 0)
      g(!0), e("login");
    else if (a && !w)
      g(!0), e("assisted_investor_login");
    else
      try {
        const N = a ? w : n.profile.preferred_username, [E] = await Promise.all([
          A.fetchUser(N, u)
        ]), I = E[0], O = ((C = I == null ? void 0 : I.annexure) == null ? void 0 : C.isonboarded) === "true";
        e(O ? "show_profile" : "onboarding"), f(I), g(!0);
      } catch (N) {
        g(!1), e("error_page"), v(N);
      }
  }, h = a ? [
    {
      step: 1,
      content: m._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: m._({
        id: "fEEk23"
      })
    },
    {
      step: 3,
      content: m._({
        id: "BJwMxA"
      })
    }
  ] : [
    {
      step: 1,
      content: m._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: m._({
        id: "BJwMxA"
      })
    }
  ], r = () => {
    c(!1);
  }, _ = (C, R, N) => {
    f(N), e("show_profile");
  }, y = ({ pan: C }) => {
    x(C);
  };
  function $() {
    switch (i) {
      case "welcome":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(T, {
              title: m._({
                id: "4dtw/O"
              })
            }),
            /* @__PURE__ */ t.jsx(Q, {
              onAck: r,
              welcomeSteps: h
            })
          ]
        });
      case "login":
        return a ? /* @__PURE__ */ t.jsx(H, {
          context: `${window.location.pathname}${window.location.search}`
        }) : /* @__PURE__ */ t.jsx(B, {
          redirectURL: `${window.location.pathname}${window.location.search}`,
          allowRegistration: !0,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: !1
        });
      case "assisted_investor_login":
        return /* @__PURE__ */ t.jsx(B, {
          onPreviewData: y,
          allowRegistration: !1,
          isPartner: !0,
          isGroupInvestment: !1,
          redirectURL: `${window.location.pathname}${window.location.search}`,
          showTestPan: {
            onboarded: !0
          }
        });
      case "show_profile":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(se, {
              investorId: o == null ? void 0 : o.profile
            }),
            /* @__PURE__ */ t.jsx(ee, {
              investorId: o == null ? void 0 : o.profile
            }),
            /* @__PURE__ */ t.jsx(X, {
              investorId: o == null ? void 0 : o.profile
            }),
            /* @__PURE__ */ t.jsx(te, {
              investorId: o == null ? void 0 : o.profile
            })
          ]
        });
      case "onboarding":
        return /* @__PURE__ */ t.jsx(V, {
          user: o,
          setUser: f,
          onBoardingComplete: _,
          isPartner: a
        });
      case "error_page":
        return /* @__PURE__ */ t.jsx(G, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
      default:
        return;
    }
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: l ? $() : /* @__PURE__ */ t.jsx(M, {
      variant: "fullpage"
    })
  });
}
export {
  we as InvestorDetails
};
